import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { IonRow } from '@ionic/react'
import { type Identity } from '#tackpay-sdk'
import CardDetailDocument from './CardDetailDocument'
import CardDetails from './CardDetails'
import CardStatus from './CardStatus'

interface Props {
  isLoading: boolean
  error?: any
  identity?: Identity | null
}
export default function CardIdentity({
  isLoading,
  error,
  identity
}: Props): JSX.Element {
  return (
    <Card>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Text color='gray-900' fontWeight='font-semibold' size='text-base'>
          identity.status
        </Text>
        <CardStatus isLoading={isLoading} error={error} identity={identity} />
      </IonRow>
      <Text className='mt-5' color='gray-900'>
        identity.details
      </Text>
      <CardDetails isLoading={isLoading} error={error} identity={identity} />
      <Text className='mt-5' color='gray-900'>
        identity.detailsDocument
      </Text>
      <CardDetailDocument
        isLoading={isLoading}
        error={error}
        identity={identity}
      />
    </Card>
  )
}
