import CoverIcon from '#components/ui/CoverIcon'
import Text from '#components/ui/Text'
import { IonItem } from '@ionic/react'
import { logOutOutline } from 'ionicons/icons'

interface Props {
  color?: string
  withCover?: boolean
}
export default function Logout({
  color,
  withCover = true
}: Props): JSX.Element {
  return (
    <IonItem
      className='cursor-pointer'
      id='logout-menu'
      color={color}
      routerLink='/logout'
      routerDirection='root'
      lines='none'
      detail={false}
    >
      <div
        className={
          withCover
            ? 'flex flex-row ion-justify-content-start ion-align-items-center space-x-6 pl-0 pb-1'
            : ''
        }
      >
        {withCover && (
          <CoverIcon
            iconOptions={{ icon: logOutOutline, className: 'text-gray-600' }}
          />
        )}
        <Text size='text-base' color='gray-900' className='capitalize '>
          logout
        </Text>
      </div>
    </IonItem>
  )
}
