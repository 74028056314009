import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId,
  ListResponse,
  type ListMeta
} from 'sdk/resource'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Jar } from './jars'
import { denormalize } from 'sdk/jsonApi'
import { type User } from './users'

type CommentType = 'comments'
type CommentRel = ResourceRel & { type: CommentType }

type CommentCategory = 'individual' | 'jar'

type PaymentRel = ResourceRel & { type: 'payments' }

interface Comment extends Resource {
  readonly type: CommentType

  description: string
  category: CommentCategory
  customer_name?: string
  payment?: Payment
}

interface CommentCreate extends ResourceCreate {
  description: string
  customer_name?: string
  category: CommentCategory
  payment: PaymentRel
}

class Comments extends ApiResource<Comment> {
  static readonly TYPE: CommentType = 'comments' as const

  async create(
    resource: CommentCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Comment> {
    return await this.resources.create(
      { ...resource, type: Comments.TYPE },
      params,
      options
    )
  }

  async jar(
    jarId: string | Jar,
    number: number,
    size: number
  ): Promise<ListResponse<Comment>> {
    const _jarId = typeof jarId === 'string' ? jarId : jarId.id
    const client = this.resources.clientInstance

    const res = await client.request(
      'get',
      `?jarId=${_jarId}&page[number]=${number}&page[size]=${size}`,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/comments',
        isJsonApi: false
      }
    )

    let data = denormalize(res) as Comment[]
    if (!Array.isArray(data)) data = [data]
    console.log('data SDK', data)
    const meta: ListMeta = {
      pageCount: Number(res.meta?.page_count),
      recordCount: Number(res.meta?.record_count),
      currentPage: number,
      recordsPerPage: size
    }

    return new ListResponse<Comment>(meta, data)
  }

  async user(
    userId: string | User,
    number: number,
    size: number
  ): Promise<ListResponse<Comment>> {
    const _userId = typeof userId === 'string' ? userId : userId.id
    const client = this.resources.clientInstance

    const res = await client.request(
      'get',
      `?userId=${_userId}&page[number]=${number}&page[size]=${size}`,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/comments',
        isJsonApi: false
      }
    )

    let data = denormalize(res) as Comment[]
    if (!Array.isArray(data)) data = [data]
    console.log('data SDK', data)
    const meta: ListMeta = {
      pageCount: Number(res.meta?.page_count),
      recordCount: Number(res.meta?.record_count),
      currentPage: number,
      recordsPerPage: size
    }

    return new ListResponse<Comment>(meta, data)
  }

  isComment(resource: any): resource is Comment {
    return resource.type != null && resource.type === Comments.TYPE
  }

  relationship(id: string | ResourceId | null): CommentRel {
    return id === null || typeof id === 'string'
      ? { id, type: Comments.TYPE }
      : { id: id.id, type: Comments.TYPE }
  }

  type(): CommentType {
    return Comments.TYPE
  }
}

export type { Comment, CommentCreate }
export default Comments
