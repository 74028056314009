import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Button from '#components/ui/Button'
import InputTextArea, {
  type InputTextAreaOnChange
} from '#components/ui/InputTextArea'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useTeamContext } from '#contexts/JarContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { adapterModal, adapterOptionSelect } from '#utils/adapter'
import getErrorMessage from '#utils/getErrorMessage'

import sdk from '#utils/sdk'
import textUtil from '#utils/textUtil'
import { IonRow } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ContactUsForm(): JSX.Element {
  const { jar } = useTeamContext()

  const { company } = useCompanyContext()

  const { t } = useTranslation()

  const [title, setTitle] = useState<string | undefined>()

  const [description, setDescription] = useState<string | undefined>()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('contactUs.loading')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (description == null || title == null) return

    if (textUtil.isEmpty(title)) {
      showToast(undefined, 'contactUs.selectProblem')
      return
    }

    if (textUtil.isEmpty(description)) {
      showToast(undefined, 'contactUs.writeMessage')
      return
    }
    startLoading()
    sdk.contact_us
      .create({
        message: description,
        name: title,
        jar: jar != null ? { id: jar.id, type: 'jars' } : undefined,
        company:
          company != null ? { id: company.id, type: 'companies' } : undefined
      })
      .then((res) => {
        stopLoading()
        showSuccess(undefined, 'contactUs.success')
        setTitle(undefined)
        setDescription(undefined)
      })
      .catch((err) => {
        stopLoading()
        showToast(undefined, getErrorMessage(err))
        console.error(err)
      })
  }

  const handleDescription: InputTextAreaOnChange = (e) => {
    const { value } = e.target
    if (value == null) return
    setDescription(value)
  }

  const handleSelectInputChange: SelectOnChangeEvent = (e) => {
    const { value } = e.target
    if (value == null) return
    setTitle(value)
  }

  // const adapterLabelOption = isDesktop() ? 'fixed' : 'stacked'
  return (
    <form onSubmit={handleSubmit}>
      <BasicSelect
        interface={adapterModal}
        options={{
          childrens: [
            'account',
            'payments',
            'bankOption',
            'suggestion',
            'other'
          ]
        }}
        name='problem'
        placeholder={t('Select problem') ?? ''}
        value={title}
        onIonChange={handleSelectInputChange}
        labelOptions={{
          show: true,
          options: adapterOptionSelect
        }}
      />
      {textUtil.isEmpty(title) ? null : (
        <InputTextArea
          value={description}
          onIonInput={handleDescription}
          rows={15}
          labelOptions={{
            children: 'message'
          }}
        />
      )}
      <IonRow className='w-full mt-12 ion-no-padding'>
        <Button
          className='w-full ion-no-margin'
          type='submit'
          disabled={textUtil.isEmpty(title) || textUtil.isEmpty(description)}
        >
          confirm
        </Button>
      </IonRow>
    </form>
  )
}
