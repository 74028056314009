import CardSmallBalance from '#components/common/CardSmallBalance'
import { useCompanyContext } from '#contexts/CompanyContext'

export default function HomeCompanyBalance(): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  return (
    <CardSmallBalance
      title={company?.name}
      amount={company?.balance?.total?.amount_cents}
      currency={company?.currency ?? company?.balance?.total?.currency}
      loading={isLoading}
      error={error}
    />
  )
}
