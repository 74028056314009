import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import TeamSettingRoutes from './views/[id]/settings/TeamSettingRoutes'
import AddTeamRoutes from './views/add/AddTeamRoutes'
import TeamMemberRoutes from './views/[id]/members/TeamMemberRoutes'
import Jar from './views/home/Team'

export interface TeamRoutesParams extends DashboardRoutesParams {
  jarId: string
}

export interface MemberRoutesParams extends TeamRoutesParams {
  memberId: string
}

export interface TeamPaymentRoutesParams extends TeamRoutesParams {
  paymentId: string
}

export interface TeamTransferRoutesParams extends TeamRoutesParams {
  transferId: string
}

const teamBasePath = '/dashboard/:category/jars'

export default function TeamRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={teamBasePath}>
          <Jar />
        </Route>
        <Route path={`${teamBasePath}/:jarId/settings`}>
          <TeamSettingRoutes />
        </Route>
        <Route path={`${teamBasePath}/add`}>
          <AddTeamRoutes />
        </Route>
        <Route path={`${teamBasePath}/:jarId/members`}>
          <TeamMemberRoutes />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
