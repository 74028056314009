import Input, { type InputOnChange } from '#components/ui/Input'
import Text from '#components/ui/Text'
import { useEffect } from 'react'
import RegistrationCollaboratorButton from './RegistrationCollaboratorButton'
import { useTokenContext } from '#contexts/TokenContext'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import useToast from '#hooks/useToast'
import useLoading from '#hooks/useLoading'
import getErrorMessage from '#utils/getErrorMessage'
import { useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import InputPasswordTest from '#components/ui/InputPasswordTest'

export default function RegistrationCollaboratorForm(): JSX.Element {
  const { token } = useTokenContext()

  const {
    handleRegistration,
    setPersonData,
    setUserData,
    setAddressData,
    userData,
    personData,
    addressData,
    refresh
  } = useRegistrationContext()

  const { showToast } = useToast('warning')

  const { i18n } = useTranslation()

  const { startLoading, stopLoading } = useLoading(
    'registration.collaborator.loading'
  )

  const { push } = useIonRouter()

  const handleInputChanges: InputOnChange = (event) => {
    const { name, value } = event.target
    if (typeof value === 'string') {
      if (name === 'firstName') {
        setPersonData({ first_name: value })
      }
      if (name === 'email') {
        setUserData({ email: value })
      }
      if (name === 'password') {
        setUserData({ password: value })
      }
    }
  }

  useEffect(() => {
    if (token != null && userData?.email == null) {
      setUserData({ email: token?.email })
    }

    if (token != null && userData?.currency == null) {
      setUserData({
        currency:
          token?.company?.currency?.toUpperCase() ??
          token?.company?.user?.currency?.toUpperCase()
      })
    }

    if (token != null && userData?.category == null) {
      setUserData({ category: 'collaborator' })
    }

    if (token != null && userData?.subcategory == null) {
      setUserData({ subcategory: token?.company?.user?.subcategory })
    }

    if (token != null && userData?.token == null) {
      setUserData({ token: token?.value })
    }

    if (token != null && addressData?.country_name == null) {
      setAddressData({
        country_name: token?.company?.user?.person?.address?.country_name
      })
    }
  }, [token, userData, addressData])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setUserData({ lang: i18n.language as any })
    void refresh()
    if (handleRegistration != null) {
      startLoading()
      handleRegistration()
        .then(() => {
          stopLoading()
          push('/dashboard/collaborator/tutorials')
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <form onSubmit={handleSubmit} className='space-y-4'>
      <Text color='gray-900'>registration.collaborator.complete</Text>
      <div>
        <Input
          name='firstName'
          type='text'
          value={personData?.first_name}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'firstName'
          }}
        />
      </div>
      <div>
        <Input
          name='email'
          type='email'
          value={userData?.email}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'email'
          }}
        />
      </div>

      <div>
        <InputPasswordTest
          name='password'
          password={userData?.password}
          onPasswordChange={handleInputChanges}
        />
      </div>
      <div>
        <RegistrationCollaboratorButton />
      </div>
    </form>
  )
}
