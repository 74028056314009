import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Input, { type InputOnChange } from '#components/ui/Input'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import { IonRow } from '@ionic/react'
import { useEffect, useState } from 'react'
import SaveModalDeduction from './SaveModalDeduction'
import { useUserContext } from '#contexts/UserContext'
import useToast from '#hooks/useToast'
import { Percent } from '@phosphor-icons/react'

interface Props {
  percentage: number
  setPercentage: (percentage: number) => void
}

export default function PercentageDeduction({
  percentage,
  setPercentage
}: Props): JSX.Element {
  const { jar, isLoading, error } = useTeamContext()

  const { user } = useUserContext()

  const [showUpdate, setShowUpdate] = useState<boolean>(false)

  const { showToast } = useToast('warning')

  useEffect(() => {
    if (jar?.deduction_setting != null) {
      setPercentage(jar.deduction_setting.percentage)
    }
  }, [jar?.deduction_setting?.percentage])

  if (isLoading) return <Skeleton className='h-10' />

  if (error != null) return <ErrorBox error={error} />

  const handleInputChange: InputOnChange = (e) => {
    const { value } = e.target
    const numberValue = Number(value)
    if (isNaN(numberValue) || numberValue < 0) {
      showToast(undefined, 'deduction.percentage_error')
      setPercentage(0)
      return
    }

    if (numberValue > 50) {
      showToast(undefined, 'deduction.percentage_error')
    }

    setPercentage(numberValue)
  }

  const isNew = (): boolean => {
    return (
      jar?.deduction_setting == null &&
      (jar?.stripe_gateway_account != null ||
        jar?.company?.stripe_gateway_account != null)
    )
  }

  return (
    <IonRow className='space-y-5'>
      <Text color='gray-900' fontWeight='font-medium' size='text-xl'>
        deduction.percentage_title
      </Text>
      <div className='relative w-full'>
        <Input type='text' value={percentage} onIonInput={handleInputChange} />
        <div className='absolute top-1/2 -translate-y-1/2 right-3'>
          <Percent />
        </div>
      </div>
      <SaveModalDeduction
        percentage={percentage}
        show={showUpdate}
        setShow={setShowUpdate}
        person={user?.person}
        address={user?.person?.address}
        type={isNew() ? 'create' : 'update'}
      />
      {isNew() && (
        <Button
          className='w-full'
          onClick={() => {
            setShowUpdate(true)
          }}
          disabled={percentage === 0}
        >
          deduction.save
        </Button>
      )}
    </IonRow>
  )
}
