import {
  type DownloadFileOptions,
  type DownloadFileResult,
  Filesystem,
  type WriteFileOptions,
  type WriteFileResult
} from '@capacitor/filesystem'

interface TParams {
  write: typeof Filesystem.writeFile
  download: typeof Filesystem.downloadFile
}

export default function useFilesystem(): TParams {
  async function getPermission(): Promise<void> {
    const permission = await Filesystem.checkPermissions()
    if (permission.publicStorage !== 'granted') {
      const result = await Filesystem.requestPermissions()
      if (result.publicStorage !== 'granted')
        throw new Error('Permission denied')
    }
  }

  async function write(options: WriteFileOptions): Promise<WriteFileResult> {
    await getPermission()
    return await Filesystem.writeFile(options)
  }

  async function download(
    options: DownloadFileOptions
  ): Promise<DownloadFileResult> {
    await getPermission()
    return await Filesystem.downloadFile(options)
  }

  return {
    write,
    download
  }
}
