import Empty from '#components/ui/Empty'

export default function EmptyStatisticView(): JSX.Element {
  return (
    <Empty
      imgOptions={{
        src: '/assets/img/empty-statistic-view.svg',
        alt: 'emptyStatisticAmount',
        className: 'w-10/12 mx-auto'
      }}
      textOptions={{
        text: 'statisticsPage.view.empty',
        props: {
          color: 'gray-500',
          size: 'text-base',
          className: 'text-center italic mt-10 font-semibold'
        }
      }}
    />
  )
}
