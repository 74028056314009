import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import classNames from 'classnames'
import { useEffect, type ComponentProps } from 'react'
import AuthHeader from './AuthHeader'
import AuthContent from './AuthContent'
import CheckoutContainer from '#contexts/CheckoutContext'
import BackgroundLine from './BackgroundLine'
import useAuthState from '#hooks/useAuthState'
import { SplashScreen } from '@capacitor/splash-screen'
import CookieBot from 'react-cookiebot'
import { useTranslation } from 'react-i18next'
interface AuthLayoutProps {
  /**
   * The content of the layout.
   */
  children: React.ReactNode
  /**
   * The options for the IonPage component.
   */
  pageOptions?: ComponentProps<typeof IonPage>
  /**
   * The options for the AuthContent component.
   */
  contentOptions?: Omit<ComponentProps<typeof IonContent>, 'children'>
  /**
   * The options for the AuthHeader component.
   */
  headerOptions?: ComponentProps<typeof AuthHeader>
  /**
   * The options for the CheckoutContainer component.
   */
  checkoutOptions?: Exclude<
    ComponentProps<typeof CheckoutContainer>,
    'children'
  >
}

export default function AuthLayout(props: AuthLayoutProps): JSX.Element {
  const {
    children,
    pageOptions,
    headerOptions,
    contentOptions,
    checkoutOptions
  } = props

  const { i18n } = useTranslation()

  const { routeInfo, push } = useIonRouter()

  const { getCurrentUser } = useAuthState()

  const checkAuthentiation = async (): Promise<void> => {
    try {
      await SplashScreen.hide()

      await SplashScreen.show({
        autoHide: false
      })

      const user = await getCurrentUser()

      if (user != null) {
        await SplashScreen.hide()
        push(`/dashboard/${user.category}/home`, 'root')
      } else {
        console.log('current user not found')
        await SplashScreen.hide()
      }
    } catch (error) {
      console.log('error', error)
      await SplashScreen.hide()
    }
  }

  useEffect(() => {
    if (routeInfo.pathname === '/login' || routeInfo.pathname === '/') {
      void checkAuthentiation()
    }
  }, [routeInfo.pathname])

  const ionPageBaseClass = 'ion-page'

  const ionPageClass = classNames(pageOptions?.className, ionPageBaseClass)

  const defaultParams = {
    include: [
      'user.checkout_setting',
      'jar.checkout_setting',
      'company.checkout_setting',
      'jar.members.user.person'
    ]
  }

  return (
    <CheckoutContainer
      fetch={checkoutOptions?.fetch ?? false}
      params={checkoutOptions?.params ?? defaultParams}
      id={checkoutOptions?.id ?? undefined}
    >
      <IonPage {...pageOptions} className={ionPageClass}>
        <CookieBot
          domainGroupId='e76111b9-4964-446d-87d8-928474e23326'
          language={i18n.language}
        />
        <IonContent
          {...contentOptions}
          fullscreen={contentOptions?.fullscreen ?? true}
          scrollY={contentOptions?.scrollY ?? true}
        >
          <BackgroundLine />
          <AuthHeader {...headerOptions} />
          <AuthContent {...contentOptions}>{children}</AuthContent>
        </IonContent>
      </IonPage>
    </CheckoutContainer>
  )
}
