import Button from '#components/ui/Button'
import useToast from '#hooks/useToast'
import { type RegistrationCategory } from '#types/registrationCategory'
import { IonRow, useIonRouter } from '@ionic/react'
import { useState } from 'react'
import Modal from '#components/ui/Modal'
import useLoading from '#hooks/useLoading'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import getErrorMessage from '#utils/getErrorMessage'
import TitleModal from './componentsModalTerms/TitleModal'
import SubTitleModal from './componentsModalTerms/SubTitleModal'
import TermsMarketing from './componentsModalTerms/TermsMarketing'
import TermsMarketing3 from './componentsModalTerms/TermsMarketing3'
import { TermsProfilations } from './componentsModalTerms/TermrsProfillations'
import { AcceptedTerms } from './componentsModalTerms/AcceptedTerms'

interface Props {
  /**
   * The category of registration
   */
  category?: RegistrationCategory
  /**
   * show the modal
   */
  show: boolean
  /**
   * Function to set the show
   */
  setShow: (show: boolean) => void
}

type TermsTitle = 'marketing' | 'marketing3' | 'profillation'

export default function RegistrationModalTerms({
  category,
  show,
  setShow
}: Props): JSX.Element {
  const [currentTerms, setCurrentTerms] = useState<TermsTitle | undefined>(
    undefined
  )

  const [accept, setAccept] = useState<boolean>(false)

  const { startLoading, stopLoading } = useLoading('registration.loading')

  const { handleRegistration } = useRegistrationContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleSubmit = (): void => {
    if (!accept) {
      showToast(undefined, 'registration.terms.acceptError')
    } else {
      if (handleRegistration != null) {
        setShow(false)
        startLoading()
        handleRegistration()
          .then((user) => {
            stopLoading()
            push(`/dashboard/${user.category}/tutorials`)
          })
          .catch((error) => {
            stopLoading()
            console.log('error while creating user %o', error)
            showToast(undefined, getErrorMessage(error))
          })
      }
    }
  }

  const showTermInfo = (term: TermsTitle): void => {
    if (currentTerms === term) setCurrentTerms(undefined)
    else setCurrentTerms(term)
  }

  return (
    <Modal isOpen={show} setShowModal={setShow} className='forgot'>
      <div className='p-5 overflow-y-auto'>
        <IonRow className='ion-justify-items-between'>
          <IonRow className='w-full text-center'>
            <TitleModal />
          </IonRow>
          <IonRow className='overflow-y-scroll ion-no-margin ion-no-padding '>
            <SubTitleModal />
            <div className='mt-5 space-y-5'>
              <TermsMarketing
                currentTerms={currentTerms}
                showTermInfo={showTermInfo}
              />
              <TermsMarketing3
                currentTerms={currentTerms}
                showTermInfo={showTermInfo}
              />
              <TermsProfilations
                currentTerms={currentTerms}
                showTermInfo={showTermInfo}
              />
            </div>
          </IonRow>
          <IonRow className='w-full'>
            <AcceptedTerms accept={accept} setAccept={setAccept} />
          </IonRow>
          <IonRow className='w-full'>
            <Button
              className='mt-8 ion-no-padding w-full'
              onClick={handleSubmit}
              data-testid='button-accept'
              color='primary'
              disabled={!accept}
            >
              accept
            </Button>
          </IonRow>
        </IonRow>
      </div>
    </Modal>
  )
}
