import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { type Identity } from '#tackpay-sdk'

interface Props {
  isLoading: boolean
  error?: any
  identity?: Identity | null
}

export default function CardDetailDocument({
  isLoading,
  error,
  identity
}: Props): JSX.Element {
  if (isLoading) return <Skeleton />

  if (error != null) return <ErrorBox error={error} />

  const getIdentityDetailDocumentText = (): string => {
    if (identity == null) return 'identity.noDetails'
    if (
      identity?.documents?.details_code != null &&
      identity?.documents?.details_code !== ''
    )
      return identity?.documents?.details_code
    if (
      identity?.documents?.details != null &&
      identity?.documents?.details !== ''
    )
      return identity?.documents?.details
    return 'identity.noDetails'
  }

  return (
    <div className='pt-5'>
      <Text>{getIdentityDetailDocumentText()}</Text>
    </div>
  )
}
