import AvatarSkeleton from '#components/common/AvatarSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Avatar from '#components/ui/Avatar'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_COMPANY_BIO } from '#constants/bio'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useTokenContext } from '#contexts/TokenContext'
import { IonCol, IonRow } from '@ionic/react'

export default function RegistrationCollaboratorCompanyInfo(): JSX.Element {
  const { isLoading, error, token } = useTokenContext()

  if (isLoading)
    return (
      <>
        <AvatarSkeleton />
        <Skeleton className='w-1/2' />
      </>
    )
  if (error != null) return <ErrorBox error={error} />

  return (
    <IonRow>
      <IonCol className='space-y-5'>
        <Avatar
          imgOptions={{
            src: token?.company?.logo ?? DEFAULT_IMAGES.logoCompany
          }}
          avatarOptions={{
            className: 'w-20 h-20 mx-auto'
          }}
        />
        <Underlined name={token?.company?.name ?? ''} />
        <div>
          <Text
            className='ion-text-center'
            translationOptions={{
              translate:
                token?.company?.description === DEFAULT_COMPANY_BIO
                  ? 'yes'
                  : 'no'
            }}
          >
            {token?.company?.description ?? DEFAULT_COMPANY_BIO}
          </Text>
        </div>
      </IonCol>
      <hr className='w-full my-5' />
    </IonRow>
  )
}
