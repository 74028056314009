import { type UserCategory } from '#tackpay-sdk'
import {
  arrowForwardOutline,
  cloudDownloadOutline,
  downloadOutline
} from 'ionicons/icons'

interface MaterialSetting {
  category: UserCategory
  label: string
  href: string
  icon: string
}

export const getMaterialSettings = (
  category: UserCategory,
  baseUrl: string
): MaterialSetting[] => {
  const MATERIALS: MaterialSetting[] = [
    /** Tipped */
    {
      category: 'tipped',
      label: 'settingMaterial.download',
      href: `${baseUrl}/downloads`,
      icon: cloudDownloadOutline
    },
    // {
    //   category: 'tipped',
    //   label: 'settingMaterial.kit',
    //   href: `${baseUrl}/kits`,
    //   icon: downloadOutline
    // },
    /* {
        category: 'tipped',
       label: 'Associa nuovo TackPay ID',
        href: `${baseUrl}/assigns`,
        icon: arrowForwardOutline
      }, */
    /** Business */
    {
      category: 'business',
      label: 'settingMaterial.download',
      href: `${baseUrl}/downloads`,
      icon: cloudDownloadOutline
    },
    {
      category: 'business',
      label: 'settingMaterial.kit',
      href: `${baseUrl}/kits`,
      icon: downloadOutline
    },
    {
      category: 'business',
      label: 'settingMaterial.assign',
      href: `${baseUrl}/assigns`,
      icon: arrowForwardOutline
    },
    /** Manager */
    {
      category: 'manager',
      label: 'settingMaterial.download',
      href: `${baseUrl}/downloads`,
      icon: cloudDownloadOutline
    },
    {
      category: 'manager',
      label: 'settingMaterial.kit',
      href: `${baseUrl}/kits`,
      icon: downloadOutline
    },
    {
      category: 'manager',
      label: 'settingMaterial.assign',
      href: `${baseUrl}/assigns`,
      icon: arrowForwardOutline
    }
  ]
  // Se è un collaboratore, cambia la categoria in business
  if (category === 'collaborator') category = 'business'
  return MATERIALS.filter((material) => material.category === category)
}
