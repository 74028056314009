import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type BankAccount } from '#tackpay-sdk'
import { IonCol, IonHeader, IonIcon, IonRow } from '@ionic/react'
import { checkmarkDoneCircleOutline } from 'ionicons/icons'

interface Props {
  banckAccount: BankAccount
  bankAccountStore?: BankAccount | null
  handleSelected: (bankAccount: BankAccount) => void
}
export default function BankJarCard({
  banckAccount,
  bankAccountStore,
  handleSelected
}: Props): JSX.Element {
  const handleSelect = (): void => {
    handleSelected(banckAccount)
  }

  return (
    <Card onClick={handleSelect} className='cursor-pointer'>
      <IonHeader>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCol className='block'>
            <Text
              color='gray-900'
              translationOptions={{
                translate: 'no'
              }}
            >
              {banckAccount.name}
            </Text>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              className='mt-2'
            >
              *******{banckAccount.last_account_number}
            </Text>
          </IonCol>
          {banckAccount.id === bankAccountStore?.id && (
            <IonIcon
              src={checkmarkDoneCircleOutline}
              size='large'
              color='primary'
            />
          )}
        </IonRow>
      </IonHeader>
    </Card>
  )
}
