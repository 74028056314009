import ErrorBox from '#components/errors/ErrorBox'
import Chip from '#components/ui/Chip'
import Skeleton from '#components/ui/Skeleton'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  /**
   * className
   */
  className?: string

  /**
   * @default true
   */
  showRole?: boolean
}
export default function TeamTitle({
  className,
  showRole = true
}: Props): JSX.Element {
  const { jar, isLoading, error } = useTeamContext()

  if (isLoading)
    return (
      <>
        <Skeleton animated className='w-1/2 h-6' />
        <Skeleton animated className='w-1/4 h-4' />
      </>
    )

  if (error != null) return <ErrorBox error={error} />

  return showRole ? (
    <IonRow className='ion-justify-content-between ion-align-items-center ion-no-padding ion-no-margin'>
      <IonCol size='9' className='block ion-no-padding ion-no-margin'>
        <IonRow className='ion-no-padding ion-no-margin'>
          <Title hasSubtitle className='truncate mt-0 mb-0 pb-1'>
            {jar?.name}
          </Title>
        </IonRow>
      </IonCol>
      <IonCol size='3' className='ion-no-padding ion-no-margin'>
        <IonRow className='ion-justify-content-end ion-no-padding ion-no-margin ion-align-items-center'>
          <Chip
            color='green-500'
            className='ion-no-margin mt-1 ion-text-center'
            data-testid={jar?.jar_type}
          >
            {jar?.jar_type}
          </Chip>
        </IonRow>
      </IonCol>
    </IonRow>
  ) : (
    <Title hasSubtitle className='truncate'>
      {jar?.name}
    </Title>
  )
}
