import AppLayout from '#components/layouts/app/AppLayout'
import ListLink from '#components/ui/ListLink'
import Title from '#components/ui/Title'
import { getMaterialSettings } from '#constants/material'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import { useParams } from 'react-router'

export default function Materials(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const materials = getMaterialSettings(
    category,
    `/dashboard/${category}/materials`
  )
  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      userProps={{
        fetch: true,
        params: {
          include: !isBusiness(category)
            ? ['person', 'stripe_gateway_account']
            : ['person']
        }
      }}
    >
      <Title>material.title</Title>
      <ListLink items={materials} />
    </AppLayout>
  )
}
