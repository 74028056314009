import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { type UserCategory } from '#tackpay-sdk'
import isDesktop from '#utils/isDesktop'

interface Props {
  category: UserCategory
  showModal: boolean
  setShowModal: () => void
}

export default function ModalAddJar({
  category,
  showModal,
  setShowModal
}: Props): JSX.Element {
  return isDesktop() ? (
    <Modal
      id='addJarDesktop'
      isOpen={showModal}
      setShowModal={setShowModal}
      trigger='open-custom-dialog'
    >
      <div className='ion-padding'>
        <Text
          className='w-full ion-text-center'
          fontWeight='font-semibold'
          color='dark'
        >
          addNewJar
        </Text>
        <div className='pt-10'>
          <Text className='w-full ion-text-center'>addNewJarUpdatePlan</Text>
        </div>
        <div className='pt-10'>
          <Button
            routerLink={`/dashboard/${category}/plans`}
            routerDirection='root'
            className='w-full'
          >
            upgradePlan
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    <Modal
      isOpen={showModal}
      setShowModal={setShowModal}
      initialBreakpoint={0.5}
      breakpoints={[0.5, 0.8, 1]}
    >
      <div className='ion-padding'>
        <Text
          className='w-full ion-text-center'
          fontWeight='font-semibold'
          color='dark'
        >
          addNewJar
        </Text>
        <div className='pt-10'>
          <Text className='w-full ion-text-center'>addNewJarUpdatePlan</Text>
        </div>
        <div className='pt-10'>
          <Button
            routerLink={`/dashboard/${category}/plans`}
            routerDirection='root'
            className='w-full'
          >
            upgradePlan
          </Button>
        </div>
      </div>
    </Modal>
  )
}
