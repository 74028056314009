import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import Toggle from '#components/ui/Toggle'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { IonRow } from '@ionic/react'

export default function CheckoutSettingMember(): JSX.Element {
  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  const handleShowMemberChange = (v: boolean): void => {
    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        show_member: v
      })
    }
  }
  return (
    <div slot='content' className='pt-1'>
      <Text data-testid='subtitle-members' size='text-base'>
        checkoutSetting.members.info
      </Text>
      <IonRow className='ion-justify-content-between ion-align-items-center my-5'>
        <Label capitalize={false}>checkoutSetting.members.toggle</Label>
        <Toggle
          checked={checkoutSetting?.show_member}
          onClick={() => {
            if (checkoutSetting?.show_member != null) {
              handleShowMemberChange(!checkoutSetting.show_member)
            }
          }}
          data-testid='toogle-members'
        />
      </IonRow>
    </div>
  )
}
