import { Redirect } from 'react-router'

const AccessRedirect = ({ location }: any): JSX.Element => {
  const { search } = location
  const redirectTo = `/login${search}`

  return <Redirect to={redirectTo} />
}

export default AccessRedirect
