import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type BranchCompany } from './branchCompanies'
import type { Stripe } from 'stripe'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Jar } from './jars'
import { type Identity } from './identities'

type StripeGatewayAccountType = 'stripe_gateway_accounts'
type StripeGatewayAccountRel = ResourceRel & { type: StripeGatewayAccountType }

type StripeGatewayAccountReferenceCategory = 'user' | 'jar'
interface StripeGatewayAccountCapability extends Stripe.Account.Capabilities {}
type StripeGatewayAccountProfileType = 'company' | 'individual'

type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

interface StripeGatewayAccount extends Resource {
  readonly type: StripeGatewayAccountType
  country: string
  reference: string
  reference_origin: string
  reference_category: StripeGatewayAccountReferenceCategory
  capabilities?: StripeGatewayAccountCapability
  profile_type: StripeGatewayAccountProfileType
  user?: User
  company?: Company
  branch_company?: BranchCompany
  jars?: Jar[]
  identity?: Identity
}

interface StripeGatewayAccountCreate extends ResourceCreate {
  reference_category: StripeGatewayAccountReferenceCategory
  profile_type: StripeGatewayAccountProfileType
  is_association?: boolean
  stripe_gateway_account_id?: string
  ssn?: string
  user?: UserRel
  jar?: JarRel
}

class StripeGatewayAccounts extends ApiResource<StripeGatewayAccount> {
  static readonly TYPE: StripeGatewayAccountType =
    'stripe_gateway_accounts' as const

  async create(
    resource: StripeGatewayAccountCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StripeGatewayAccount> {
    return await this.resources.create<
      StripeGatewayAccountCreate,
      StripeGatewayAccount
    >({ ...resource, type: StripeGatewayAccounts.TYPE }, params, options)
  }

  isStripeGatewayAccount(resource: any): resource is StripeGatewayAccount {
    return resource.type != null && resource.type === StripeGatewayAccounts.TYPE
  }

  relationship(id: string | ResourceId | null): StripeGatewayAccountRel {
    return id === null || typeof id === 'string'
      ? { id, type: StripeGatewayAccounts.TYPE }
      : { id: id.id, type: StripeGatewayAccounts.TYPE }
  }

  type(): StripeGatewayAccountType {
    return StripeGatewayAccounts.TYPE
  }
}

export type {
  StripeGatewayAccount,
  StripeGatewayAccountCreate,
  StripeGatewayAccountProfileType
}
export default StripeGatewayAccounts
