import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { IonImg } from '@ionic/react'

interface Props {
  loading?: boolean
  error?: any
  src: string
  isBlur?: boolean
}

export default function CardQrCodeImage({
  loading = true,
  error = null,
  src,
  isBlur = false
}: Props): JSX.Element {
  if (loading)
    return (
      <Skeleton style={{ width: '90%', height: '15rem' }} className='mx-auto' />
    )
  if (error != null) return <ErrorBox error={error} />

  return (
    <IonImg
      src={src}
      className={`w-11/12 md:w-5/12 mx-auto bg-white ${
        isBlur ? 'blur-md' : ''
      }`}
    />
  )
}
