import ChartBar from '#components/charts/ChartBar'
import { type StatisticAmount } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  showCard?: boolean
  statisticAmount: StatisticAmount
}
export default function StatisticAmountCard({
  showCard = false,
  statisticAmount
}: Props): JSX.Element {
  const { t } = useTranslation()

  const sortedStatisticAmount = statisticAmount.amounts.sort((a, b) => {
    return b.occurence - a.occurence
  })

  const categories = sortedStatisticAmount.map((amount) => {
    return formattedAmount(amount.amount)
  })

  const series = {
    name: 'Number of times',
    data: sortedStatisticAmount.map((amount) => {
      return amount.occurence
    })
  }

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [])

  return showCard ? (
    <IonRow className='rounded-sm border p-7 h-full bg-statistic'>
      <IonCol size='12'>
        <IonRow className='w-full ion-justify-content-between ion-align-items-center'>
          <IonCol size='12'>
            <h4 className='text-xl font-semibold text-black '>
              {t('amounts')}
            </h4>
          </IonCol>
        </IonRow>

        <div className='w-full'>
          <ChartBar categories={categories} series={series} />
        </div>
      </IonCol>
    </IonRow>
  ) : (
    <ChartBar categories={categories} series={series} />
  )
}
