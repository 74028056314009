import { Route } from 'react-router'
import Settings from './views/Settings'
import TermsAndConditions from './views/TermsAndConditions'
import Language from './views/Language'
import ContactUs from './views/ContactUs'
import WithdrawalRoutes from './views/withdrawals/WithdrawalRoute'
import AccountStatement from './views/AccountStatements'
import Identity from './views/Identity'
import ResetPasswordPage from './views/ResetPassword'
import AccesData from './views/AccesData'
import { IonPage, IonRouterOutlet } from '@ionic/react'

const settingBasePath = '/dashboard/:category/settings'

export default function SettingRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={settingBasePath}>
          <Settings />
        </Route>
        <Route exact path={`${settingBasePath}/terms-and-conditions`}>
          <TermsAndConditions />
        </Route>
        <Route exact path={`${settingBasePath}/languages`}>
          <Language />
        </Route>
        <Route exact path={`${settingBasePath}/contact-us`}>
          <ContactUs />
        </Route>
        <Route exact path={`${settingBasePath}/account-statements`}>
          <AccountStatement />
        </Route>
        <Route path={`${settingBasePath}/withdrawals`}>
          <WithdrawalRoutes />
        </Route>
        <Route exact path={`${settingBasePath}/password-reset`}>
          <ResetPasswordPage />
        </Route>
        <Route exact path={`${settingBasePath}/identities`}>
          <Identity />
        </Route>
        <Route exact path={`${settingBasePath}/access/data`}>
          <AccesData />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
