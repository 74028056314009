import ChartMap from '#components/charts/ChartMap'
import ErrorBox from '#components/errors/ErrorBox'
import ModalLoading from '#components/ui/ModalLoading'
import BannerComponents from '#components/ui/BannerComponents'
import Title from '#components/ui/Title'
import StatisticAmountContainer from '#contexts/StatisticAmountContext'
import StatisticBalanceContainer from '#contexts/StatisticBalanceContext'
import StatisticFeedbackContainer from '#contexts/StatisticFeedbackContext'
import StatisticViewContainer from '#contexts/StatisticViewConytext'
import { useUserContext } from '#contexts/UserContext'
import sdk from '#utils/sdk'
import { IonCol, IonRow } from '@ionic/react'
import { lazy, Suspense, useEffect, useState } from 'react'
import MemberInviteHandle from '#components/pages/home/memberInvite/MemberInviteHandle'
// import TableSocials from './UI/TableSocials'

const StatisticAmount = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/amount/StatisticAmount')
)

const StatisticView = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/desktop/StatisticCardDesktop')
)

const StatisticBalance = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/balance/StatisticBalance')
)

const StatisticFeedbackSection = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statisticFeedback/StatisticFeedbackSection')
)

export default function DashboardDesktopTipped(): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  const [countryTips, setCountryTips] = useState<string[]>()

  const handleCountriesTips = (): void => {
    if (user != null) {
      sdk.users
        .metrics_country_tips(user.id)
        .then((res) => {
          console.log('metriche countries', res.countries)
          setCountryTips(res.countries)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    handleCountriesTips()
  }, [])

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  return (
    <>
      <BannerComponents />
      <IonRow className='w-full ion-no-margin ion-no-padding h-screen'>
        <IonCol size='12' className='py-10'>
          <div className='w-full mx-auto mt-14 space-y-5'>
            <IonRow className='w-full ion-no-padding ion-no-margin ion-justify-content-between ion-align-items-center'>
              <IonCol size='9' className='ion-no-padding ion-no-margin'>
                <Title
                  className='p-0 m-0'
                  translationOptions={{
                    translate: 'no'
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow className='w-full ion-no-padding ion-no-margin'>
              <IonCol size='12' className='ion-no-padding ion-no-margin'>
                <StatisticViewContainer>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticView
                      userId={user?.id}
                      currency={user?.currency}
                    />
                  </Suspense>
                </StatisticViewContainer>
              </IonCol>
            </IonRow>

            <IonRow className='w-full ion-justify-content-between'>
              <IonCol size='7' className='ion-no-padding ion-no-margin'>
                <StatisticAmountContainer>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticAmount />
                  </Suspense>
                </StatisticAmountContainer>
              </IonCol>
              <IonCol size='4.8' className='ion-no-padding ion-no-margin'>
                <StatisticBalanceContainer>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticBalance />
                  </Suspense>
                </StatisticBalanceContainer>
              </IonCol>
            </IonRow>
            <IonRow className='w-full ion-no-padding ion-no-margin ion-justify-content-between'>
              <IonCol size='4.8' className='ion-no-padding ion-no-margin'>
                <StatisticFeedbackContainer>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticFeedbackSection />
                  </Suspense>
                </StatisticFeedbackContainer>
              </IonCol>
              <IonCol
                size='7'
                className='ion-no-padding ion-no-margin relative'
              >
                <ChartMap countryTips={countryTips ?? []} />
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
      </IonRow>
      <MemberInviteHandle />
    </>
  )
}
