import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Jar } from './jars'
import { type User } from './users'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Payment } from './payments'
import { type Transfer } from './transfers'

type AccountStatementType = 'account_statements'
type AccountStatementRel = ResourceRel & { type: AccountStatementType }

type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

interface AccountStatement extends Resource {
  readonly type: AccountStatementType
  storage_path?: string
  storage_url?: string
  from_date: string
  to_date: string
  jar?: Jar
  user?: User
  payments?: Payment[]
  transfers?: Transfer[]
}

interface AccountStatementCreate extends ResourceCreate {
  from_date: string
  to_date: string
  is_member?: boolean
  jar?: JarRel
  user?: UserRel
}

class AccountStatements extends ApiResource<AccountStatement> {
  static readonly TYPE: AccountStatementType = 'account_statements' as const

  async create(
    resource: AccountStatementCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<AccountStatement> {
    return await this.resources.create<
      AccountStatementCreate,
      AccountStatement
    >({ ...resource, type: AccountStatements.TYPE }, params, options)
  }

  isAccountStatement(resource: any): resource is AccountStatement {
    return resource.type != null && resource.type === AccountStatements.TYPE
  }

  relationship(id: string | ResourceId | null): AccountStatementRel {
    return id === null || typeof id === 'string'
      ? { id, type: AccountStatements.TYPE }
      : { id: id.id, type: AccountStatements.TYPE }
  }

  type(): AccountStatementType {
    return AccountStatements.TYPE
  }
}

export type { AccountStatement, AccountStatementCreate }
export default AccountStatements
