import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Skeleton from '#components/ui/Skeleton'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type StripeGatewayAccount, type Identity } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { isPlatform } from '@ionic/core'
import { useIonRouter } from '@ionic/react'
import { Browser } from '@capacitor/browser'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  isLoading: boolean
  error?: any
  identity?: Identity | null
  stripeGatewayAccountId?: string | StripeGatewayAccount
}
export default function IdentityButton({
  isLoading,
  error,
  identity,
  stripeGatewayAccountId
}: Props): JSX.Element {
  const queryClient = useQueryClient()

  const { showToast } = useToast('warning')

  const { routeInfo } = useIonRouter()

  const { startLoading, stopLoading } = useLoading('identity.loading')

  if (isLoading) return <Skeleton className='w-full h-10' />

  if (error != null) return <ErrorBox error={error} />

  const handleRefresh = (): void => {
    queryClient
      .refetchQueries({ type: 'active' })
      .then(() => {
        console.log('refetchQueries success')
      })
      .catch((error) => {
        console.log('invalidateQueries error', error)
      })
  }

  const handleVerification = (): void => {
    const returnUrl =
      process.env.REACT_APP_FRONTEND_URL ??
      'https://app.tackpay.net' + routeInfo.pathname

    const _stripeGatewayAccountId =
      (stripeGatewayAccountId as StripeGatewayAccount)?.id ??
      stripeGatewayAccountId

    startLoading()

    sdk.stripe_gateway_identities
      .create({
        return_url: returnUrl,
        url_type: 'oauth',
        stripe_gateway_account: {
          id: _stripeGatewayAccountId,
          type: 'stripe_gateway_accounts'
        }
      })
      .then((res) => {
        stopLoading()
        console.log(res)
        if (isPlatform('hybrid')) {
          Browser.open({ url: res.identity_url })
            .then(() => {
              console.log('Opened browser!')
            })
            .catch((error) => {
              console.log('cannot open the browser', error)
            })

          void Browser.addListener('browserFinished', () => {
            console.log('browserFinished event')
            handleRefresh()
          })
        } else {
          window.location.href = res.identity_url
        }
      })
      .catch((error) => {
        stopLoading()
        showToast(undefined, getErrorMessage(error))
      })
  }

  return (
    <Button
      disabled={isLoading}
      className='w-full ion-no-margin'
      color='primary'
      onClick={handleVerification}
    >
      {identity == null ? 'identity.create' : 'identity.update'}
    </Button>
  )
}
