import {
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type BalanceTransaction } from './balanceTransactions'

type BalanceType = 'balances'
type BalanceRel = ResourceRel & { type: BalanceType }

interface BaseBalance {
  amount_cents: number
  amount_float: number
  amount_formatted: string
  currency: string
}

interface BalanceTronc {
  available: BaseBalance
  pending: BaseBalance
}

interface Balance extends Resource {
  readonly type: BalanceType
  total: BaseBalance
  available: BaseBalance
  pending: BaseBalance
  instant_payout: BaseBalance
  tronc: BalanceTronc
  user?: User
  balance_transactions?: BalanceTransaction[]
}

class Balances extends ApiResource<Balance> {
  static TYPE: BalanceType = 'balances' as const

  static isBalance(resource: any): resource is Balance {
    return resource.type != null && resource.type === Balances.TYPE
  }

  relationship(id: string | ResourceId | null): BalanceRel {
    return id === null || typeof id === 'string'
      ? { id, type: Balances.TYPE }
      : { id: id.id, type: Balances.TYPE }
  }

  type(): BalanceType {
    return Balances.TYPE
  }
}

export type { Balance, BaseBalance }
export default Balances
