import ChartDonut from '#components/charts/ChartDonut'
import { type StatisticFeedback } from '#tackpay-sdk'
import countEqualItems from '#utils/countEqualItem'
import uniqueArray from '#utils/uniqueArray'
import { IonRow } from '@ionic/react'

interface Props {
  title?: string
  statisticFeedback: StatisticFeedback
  showCard?: boolean
}
export default function FeedbackCard({
  title = 'Feedback',
  statisticFeedback,
  showCard = false
}: Props): JSX.Element {
  const MAX_FEEDBACK_VIEWS = 5

  const sortedFeedbacks = statisticFeedback.feedbacks.sort((a, b) => {
    return a.name < b.name ? -1 : 1
  })

  const series = countEqualItems(sortedFeedbacks.map((f) => f.name))

  const labels = uniqueArray(sortedFeedbacks.map((f) => f.name))

  return showCard ? (
    <div className='col-span-12 rounded-lg border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default   sm:px-7.5 xl:col-span-5 p-5'>
      <div className='mb-3 justify-between gap-4 sm:flex'>
        <div>
          <h5 className='text-xl font-semibold text-black '>{title}</h5>
        </div>
        <div>
          <div className='relative z-20 inline-block' />
        </div>
      </div>
      <ChartDonut
        series={series.slice(0, MAX_FEEDBACK_VIEWS)}
        labels={labels.splice(0, MAX_FEEDBACK_VIEWS)}
      />
    </div>
  ) : (
    <IonRow className='ion-justify-content-center ion-align-items-center'>
      <ChartDonut
        series={series.slice(0, MAX_FEEDBACK_VIEWS)}
        labels={labels.splice(0, MAX_FEEDBACK_VIEWS)}
      />
    </IonRow>
  )
}
