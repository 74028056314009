import AppLayout from '#components/layouts/app/AppLayout'
import PaymentContainer from '#contexts/PaymentContext'
import { useParams } from 'react-router'
import { type TeamPaymentRoutesParams } from '../../../../TeamRoutes'
import PaymentIdHandler from '#components/pages/payments/PaymentIdHandler'

export default function TeamPayment(): JSX.Element {
  const { category, jarId, paymentId } = useParams<TeamPaymentRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/payments`
        }
      }}
    >
      <PaymentContainer
        paymentId={paymentId}
        params={{
          include: [
            'jar',
            'user.person',
            'member.user.person',
            'payment_method',
            'fee',
            'checkout_session',
            'feedbacks',
            'comment',
            'deduction'
          ]
        }}
      >
        <PaymentIdHandler />
      </PaymentContainer>
    </AppLayout>
  )
}
