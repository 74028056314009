import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig,
  ListResponse,
  type ListMeta
} from 'sdk/resource'
import { type TackpayId } from './tackpayIds'
import { type User } from './users'
import { type Company } from './companies'
import { type Jar } from './jars'
import { type Member } from './members'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'
import { denormalize } from 'sdk/jsonApi'

type CheckoutSessionType = 'checkout_sessions'
type CheckoutSessionRel = ResourceRel & { type: CheckoutSessionType }

type CheckoutSessionReferenceOrigin = 'qr_code' | 'search' | 'unknown'
type CheckoutSessionStatus =
  | 'created'
  | 'payment_completed'
  | 'review_completed'
  | 'payment_rejected'

interface CheckoutSession extends Resource {
  readonly type: CheckoutSessionType
  start_at: string
  end_at?: string
  reference_origin: CheckoutSessionReferenceOrigin
  status: CheckoutSessionStatus
  ip: string
  tackpay_id?: TackpayId
  user?: User
  company?: Company
  jar?: Jar
  member?: Member
  payment?: Payment
}

interface CheckoutSessionCreate extends ResourceCreate {
  tackpay_id_value: string
  reference_origin: CheckoutSessionReferenceOrigin
}

export interface FeeSimulator {
  covered: boolean
  totalFeeAmount: number
  providerFixed: number
  providerPercentage: number
  providerFeeAmount: number
  platformFixed: number
  platformPercentage: number
  platformFeeAmount: number
  providerVatAmount: number
  isDomestic: boolean
}

class CheckoutSessions extends ApiResource<CheckoutSession> {
  static readonly TYPE: CheckoutSessionType = 'checkout_sessions' as const

  async create(
    resource: CheckoutSessionCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSession> {
    return await this.resources.create<CheckoutSessionCreate, CheckoutSession>(
      {
        ...resource,
        type: CheckoutSessions.TYPE
      },
      params,
      options
    )
  }

  async members(
    jarId: string | ResourceId,
    number: number = 1,
    size: number = 10
  ): Promise<ListResponse<Member>> {
    const _jarId = typeof jarId === 'string' ? jarId : jarId.id
    const client = this.resources.clientInstance

    const res = await client.request(
      'get',
      `${_jarId}/members?page[number]=${number}&page[size]=${size}`,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
        },
        path: '/checkout_sessions',
        isJsonApi: false
      }
    )

    let data = denormalize(res) as Member[]
    if (!Array.isArray(data)) data = [data]
    console.log('data SDK', data)
    const meta: ListMeta = {
      pageCount: Number(res.meta?.page_count),
      recordCount: Number(res.meta?.record_count),
      currentPage: number,
      recordsPerPage: size
    }

    return new ListResponse<Member>(meta, data)
  }

  async fee_simulators(
    amount: number,
    currency: string,
    covered: boolean = false
  ): Promise<FeeSimulator> {
    const client = this.resources.clientInstance

    const res = await client.request(
      'post',
      `fee_simulators`,
      { amount, currency, covered },
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
        },
        path: '/checkout_sessions',
        isJsonApi: false
      }
    )

    return res as FeeSimulator
  }

  isCheckoutSession(resource: any): resource is CheckoutSession {
    return resource.type != null && resource.type === CheckoutSessions.TYPE
  }

  relationship(id: string | ResourceId | null): CheckoutSessionRel {
    return id === null || typeof id === 'string'
      ? { id, type: CheckoutSessions.TYPE }
      : { id: id.id, type: CheckoutSessions.TYPE }
  }

  type(): CheckoutSessionType {
    return CheckoutSessions.TYPE
  }
}
export type { CheckoutSession, CheckoutSessionCreate }
export default CheckoutSessions
