import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { IonItem, IonRow } from '@ionic/react'

interface Props {
  /** The label of the line */
  label: string
  /** The value of the line */
  value: string
  /** Wheter show the loading spinner */
  loading?: boolean
}

export default function CardUserIdentityItemLine({
  label,
  value,
  loading = true
}: Props): JSX.Element {
  return (
    <IonItem className='w-full ion-no-padding ion-no-margin' color='bg-card'>
      <IonRow className='ion-justify-content-between ion-align-items-center w-full py-3'>
        <Text>{label}</Text>
        {loading ? (
          <Skeleton className='w-6' />
        ) : (
          <Text color='gray-900'>{value}</Text>
        )}
      </IonRow>
    </IonItem>
  )
}
