interface Props {
  icon?: () => JSX.Element
  title?: string
  totalValue?: any
  percentageValue?: any
  positive?: boolean
  width?: string
}
export default function CardStatistic({
  icon = () => (
    <svg
      className='fill-green-500 '
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 15.1156C4.19376 15.1156 0.825012 8.61876 0.687512 8.34376C0.584387 8.13751 0.584387 7.86251 0.687512 7.65626C0.825012 7.38126 4.19376 0.918762 11 0.918762C17.8063 0.918762 21.175 7.38126 21.3125 7.65626C21.4156 7.86251 21.4156 8.13751 21.3125 8.34376C21.175 8.61876 17.8063 15.1156 11 15.1156ZM2.26876 8.00001C3.02501 9.27189 5.98126 13.5688 11 13.5688C16.0188 13.5688 18.975 9.27189 19.7313 8.00001C18.975 6.72814 16.0188 2.43126 11 2.43126C5.98126 2.43126 3.02501 6.72814 2.26876 8.00001Z'
        fill=''
      />
      <path
        d='M11 10.9219C9.38438 10.9219 8.07812 9.61562 8.07812 8C8.07812 6.38438 9.38438 5.07812 11 5.07812C12.6156 5.07812 13.9219 6.38438 13.9219 8C13.9219 9.61562 12.6156 10.9219 11 10.9219ZM11 6.625C10.2437 6.625 9.625 7.24375 9.625 8C9.625 8.75625 10.2437 9.375 11 9.375C11.7563 9.375 12.375 8.75625 12.375 8C12.375 7.24375 11.7563 6.625 11 6.625Z'
        fill=''
      />
    </svg>
  ),
  title = 'Statistic title',
  totalValue = 0,
  percentageValue = 0,
  positive = true,
  width = ''
}: Props): JSX.Element {
  return (
    <div
      className={`rounded-sm border py-6 px-7 shadow-default  ${width}`}
      id='bg-card-view'
    >
      <div className='flex h-11 w-11 items-center justify-center rounded-full bg-green-200 '>
        {icon()}
      </div>
      <div className='mt-4 flex items-end justify-between'>
        <div>
          <h4 className='text-title-md font-bold text-black '>{totalValue}</h4>
          <span className='text-sm font-medium'>{title}</span>
        </div>

        <span
          className={`flex items-center gap-1 text-sm font-medium ${
            positive ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {percentageValue}%
          {positive ? (
            <svg
              className='fill-green-500'
              width='10'
              height='11'
              viewBox='0 0 10 11'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z'
                fill=''
              />
            </svg>
          ) : (
            <svg
              className='fill-red-500'
              width='10'
              height='11'
              viewBox='0 0 10 11'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.35716 8.60681L0.908974 5.25431L5.0443e-07 6.13806L5 10.9993L10 6.13806L9.09103 5.25431L5.64284 8.60681L5.64284 1.99926L4.35716 1.99926L4.35716 8.60681Z'
                fill='red'
              />
            </svg>
          )}
        </span>
      </div>
    </div>
  )
}
