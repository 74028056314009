import ErrorBox from '#components/errors/ErrorBox'
import AppLayout from '#components/layouts/app/AppLayout'
import CardIdentity from '#components/pages/identities/CardIdentity'
import CardUserIdentity from '#components/pages/identities/CardUserDetail'
import IdentityButton from '#components/pages/identities/IdentityButton'
import { IdentityForm } from '#components/pages/identities/IdentityForm'
import ModalLoading from '#components/ui/ModalLoading'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { useUserContext } from '#contexts/UserContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { IonRow } from '@ionic/react'
import { useParams } from 'react-router'

function HandleCardIdentity(): JSX.Element {
  const { isLoading, error, user } = useUserContext()

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  if (user?.stripe_gateway_account == null) {
    return <IdentityForm />
  }

  return (
    <>
      <CardUserIdentity />
      <CardIdentity
        isLoading={isLoading}
        error={error}
        identity={user?.stripe_gateway_account?.identity}
      />
      <IonRow className='mt-10 ion-no-padding w-full'>
        <IdentityButton
          isLoading={isLoading}
          error={error}
          identity={user?.stripe_gateway_account?.identity}
          stripeGatewayAccountId={user?.stripe_gateway_account?.id}
        />
      </IonRow>
    </>
  )
}

export default function Identity(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['stripe_gateway_account.identity', 'person.address']
        }
      }}
    >
      <Title hasSubtitle>identity.title</Title>
      <SubTitle>identity.subtitle</SubTitle>
      <div className='space-y-6'>
        <HandleCardIdentity />
      </div>
    </AppLayout>
  )
}
