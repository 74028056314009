import { AUTH_STATE_KEY } from '#hooks/useAuthState'
import axios from 'axios'
import { destroyPreferences } from 'storage/preferences'

/**
 * The Source object contains references to the source of the error,
 * optionally including any of the following members:
 * - pointer: a JSON Pointer [RFC6901] to the associated entity in the request document [e.g. "/data" for a primary data object, or "/data/attributes/title" for a specific attribute].
 * - parameter: a string indicating which URI query parameter caused the error.
 * @see https://jsonapi.org/format/#error-objects
 */
interface HttpSourceExceptionOptions {
  pointer?: string
  parameter?: string
}
/**
 * The Meta object contains non-standard meta-information about the error.
 * @see https://jsonapi.org/format/#error-objects
 */
type HttpMetaExeptionOptions = Record<string, unknown>

/**
 * The Link object represents a link related to the primary data.
 * @see https://jsonapi.org/format/#error-objects
 */
type HttpLinkExceptionOptions = Record<string, unknown>

/**
 * The options object for the HttpException.
 */
interface HttpExceptionOptions {
  detail?: string
  status?: number
  code?: string
  title?: string
  source?: HttpSourceExceptionOptions
  meta?: HttpMetaExeptionOptions
  link?: HttpLinkExceptionOptions
}

enum ErrorType {
  CLIENT = 'client',
  REQUEST = 'request',
  RESPONSE = 'response',
  GENERIC = 'generic',
  CANCEL = 'cancel'
}

/**
 * The HttpException is the base class for all exceptions in the HTTP layer.
 * It is recommended to extend this class when creating custom exceptions.
 * * Important: The format of the exception is compatible with the JSON:API specification.
 */
export class ApiError extends Error {
  cause?: Error | unknown
  detail: string
  status: number
  code?: string
  title?: string
  source?: HttpSourceExceptionOptions
  meta?: HttpMetaExeptionOptions
  link?: HttpLinkExceptionOptions
  errors?: HttpExceptionOptions[]
  type?: ErrorType
  request?: unknown

  constructor(
    options: HttpExceptionOptions | HttpExceptionOptions[] = {},
    type?: ErrorType,
    request?: unknown
  ) {
    super()
    Object.setPrototypeOf(this, new.target.prototype)
    let opt: HttpExceptionOptions
    if (Array.isArray(options) && options.length > 0) {
      this.errors = options
      opt = options[0]
    } else if (!Array.isArray(options)) {
      opt = options
    } else {
      opt = {}
    }
    this.message = opt.detail ?? 'Internal Server Error'
    this.name = this.constructor.name
    this.detail = this.message
    this.status = opt.status ?? 500
    this.code = opt.code
    this.title = opt.title
    this.source = opt.source
    this.meta = opt.meta
    this.link = opt.link
    this.type = type
    this.request = request
  }

  static create(
    options: HttpExceptionOptions | HttpExceptionOptions[] = {}
  ): ApiError {
    return new ApiError(options)
  }

  static isApiError(error: unknown): error is ApiError {
    return (
      error instanceof ApiError ||
      (typeof error === 'object' &&
        error !== null &&
        'detail' in error &&
        'status' in error &&
        'code' in error)
    )
  }
}

export const handleError = (error: Error): never => {
  let apiError = new ApiError({
    detail: error.message,
    status: 500
  })

  // console.log('Error', { ...error })

  if (axios.isAxiosError(error)) {
    if (error.response != null) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      apiError = ApiError.create(error.response.data.errors)
    } else if (error.request != null) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      apiError.type = ErrorType.REQUEST
      apiError.request = error.request
    } else {
      // Something happened in setting up the request that triggered an Error
      apiError.type = ErrorType.CLIENT
    }
  } else if (axios.isCancel(error)) apiError.type = ErrorType.CANCEL
  else apiError.source = error

  if (apiError.status === 401) {
    destroyPreferences(AUTH_STATE_KEY)
  }

  throw apiError
}
export { ErrorType }
export type {
  HttpExceptionOptions,
  HttpLinkExceptionOptions,
  HttpMetaExeptionOptions,
  HttpSourceExceptionOptions
}
