export default function BackgroundLine(): JSX.Element {
  return (
    <div className='md:container-bg'>
      <div className='md:vertical-line-first md:vertical-line-content md:z-10' />
      <div className='md:vertical-line-second md:vertical-line-content md:z-10' />
      <div className='md:vertical-line-third md:vertical-line-content md:z-10' />
      <div className='md:vertical-line-fourth md:vertical-line-content md:z-10' />
      <div className='md:vertical-line-fifth md:vertical-line-content md:z-10' />
      <div className='md:container-bg2' />
      <div className='md:left-first md:absolute' />
      <div className='md:left-second md:absolute md:z-50' />
      <div className='md:left-third md:absolute md:z-50' />
      <div className='md:right-first absolute md:z-50' />
    </div>
  )
}
