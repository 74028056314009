import { IonLabel, IonText } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { type TranslationOptions } from '#types/translate'

interface LabelProps extends ComponentProps<typeof IonLabel> {
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * Capitalize the text.
   * Default: `true`
   */
  capitalize?: boolean
  /**
   * The size of the label.
   * Default: `md`
   */
  size?: 'sm' | 'md' | 'lg'
}

export default function Label(props: LabelProps): JSX.Element {
  const { children, translationOptions, capitalize, size, ...ionLabelProps } =
    props

  const { t } = useTranslation()

  const text =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children

  const capitalizeClass = capitalize === false ? '' : 'capitalize'

  const labelClass = classNames(props.className, capitalizeClass)
  return (
    <IonLabel {...ionLabelProps} className={labelClass}>
      <IonText
        className={classNames('w-full', {
          'text-xl': size === 'lg',
          'text-base': size === 'md',
          'text-sm': size === 'sm',
          '': size == null
        })}
      >
        {text}
      </IonText>
    </IonLabel>
  )
}
