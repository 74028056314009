import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import RegistrationSubcategory from '#components/pages/registrations/RegistrationSubcategory'

export default function RegistrationSubcategoryStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: false,
        backButtonProps: {
          defaultHref: '/registrations'
        }
      }}
    >
      {(category === 'tipped' ||
        category === 'business' ||
        category === 'manager') && (
        <RegistrationSubcategory category={category} />
      )}
    </AuthLayout>
  )
}
