import {
  FirebaseStorage,
  type GetMetadataResult,
  type ListFilesResult
} from '@capacitor-firebase/storage'
import {
  Directory,
  type DownloadFileResult,
  Filesystem
} from '@capacitor/filesystem'
import JSZip from 'jszip'

interface UseFile {
  uploadFile: (destination: string, uri: string) => Promise<string>
  downloadFile: (
    path: string,
    downloadPath: string,
    directory?: Directory
  ) => Promise<DownloadFileResult>
  getDownloadUrl: (path: string) => Promise<string>
  deleteFile: (path: string) => Promise<void>
  listFiles: (path: string) => Promise<ListFilesResult>
  getMetadata: (path: string) => Promise<GetMetadataResult>
  updateMetadata: (path: string, meta: Record<string, any>) => Promise<void>
  downloadFiles: (path: string) => Promise<ListFilesResult>
  toZip: (files: ListFilesResult) => Promise<Blob>
}

export default function useFile(): UseFile {
  const uploadFile = async (
    destination: string,
    uri: string
  ): Promise<string> => {
    return await FirebaseStorage.uploadFile(
      {
        path: destination,
        uri
      },
      (event, error) => {
        if (error != null) throw error
        if (event?.completed === true) return event
      }
    )
  }

  const downloadFile = async (
    path: string,
    downloadPath?: string,
    directory: Directory = Directory.Data
  ): Promise<DownloadFileResult> => {
    console.log('download file from path %s', path)
    const downloadUrl = await FirebaseStorage.getDownloadUrl({
      path
    })

    const fileName =
      downloadPath != null
        ? downloadPath + '/' + path.split('/').pop() ?? 'template.jpg'
        : path.split('/').pop() ?? 'template.jpg'

    const file = await Filesystem.downloadFile({
      url: downloadUrl.downloadUrl,
      path: fileName,
      directory
    })

    return file
  }

  const getDownloadUrl = async (path: string): Promise<string> => {
    const result = await FirebaseStorage.getDownloadUrl({
      path
    })

    return result.downloadUrl
  }

  const deleteFile = async (path: string): Promise<void> => {
    await FirebaseStorage.deleteFile({
      path
    })
  }

  const listFiles = async (path: string): Promise<ListFilesResult> => {
    const files = await FirebaseStorage.listFiles({
      path
    })

    return files
  }

  const getMetadata = async (path: string): Promise<GetMetadataResult> => {
    return await FirebaseStorage.getMetadata({
      path
    })
  }

  const updateMetadata = async (
    path: string,
    meta: Record<string, any>
  ): Promise<void> => {
    await FirebaseStorage.updateMetadata({
      path,
      metadata: meta
    })
  }

  const downloadFiles = async (path: string): Promise<ListFilesResult> => {
    const files = await listFiles(path)
    return files
  }

  const toZip = async (files: ListFilesResult): Promise<Blob> => {
    const zip = new JSZip()

    for (const file of files.items) {
      const item = await FirebaseStorage.getDownloadUrl({
        path: file.path
      })

      const fileItem = await Filesystem.downloadFile({
        url: item.downloadUrl,
        path: file.name
      })

      zip.file(file.name, fileItem.blob ?? new Blob())
    }

    const toSave = await zip.generateAsync({ type: 'blob' })
    return toSave
  }

  return {
    uploadFile,
    downloadFile,
    getDownloadUrl,
    deleteFile,
    listFiles,
    getMetadata,
    updateMetadata,
    downloadFiles,
    toZip
  }
}
