import AppLayout from '#components/layouts/app/AppLayout'
import StatisticFeedbackSection from '#components/pages/statisticFeedback/StatisticFeedbackSection'
import CommentSection from '#components/pages/statisticFeedback/comment/CommentSection'
import Title from '#components/ui/Title'
import StatisticFeedbackContainer from '#contexts/StatisticFeedbackContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function StatisticFeedbacks(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
    >
      <Title>feedbackPage.title</Title>
      <StatisticFeedbackContainer jarId={jarId} fetch>
        <StatisticFeedbackSection />
      </StatisticFeedbackContainer>
      <CommentSection jarId={jarId} />
    </AppLayout>
  )
}
