import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Member } from './members'
import { type Jar } from './jars'
import { type User } from './users'
import { type Company } from './companies'
import { type Collaborator } from './collaborators'
import { type QueryParamsRetrieve } from 'sdk/query'

type TokenType = 'tokens'
type TokenRel = ResourceRel & { type: TokenType }

type TokenCategory =
  | 'confirm_email'
  | 'collaborator_invite'
  | 'password_reset'
  | 'member_invite'
  | 'team_invite'

type UserRel = ResourceRel & { type: 'users' }
type CompanyRel = ResourceRel & { type: 'companies' }
type JarRel = ResourceRel & { type: 'jars' }
type MemberRel = ResourceRel & { type: 'members' }
type CollaboratorRel = ResourceRel & { type: 'collaborators' }

interface Token extends Resource {
  readonly type: TokenType

  value: string
  category: TokenCategory
  consumed: boolean
  consumed_at?: Date
  email?: string
  expires_at: Date
  member?: Member
  jar?: Jar
  user?: User
  company?: Company
  collaborator?: Collaborator
}

interface TokenCreate extends ResourceCreate {
  category: TokenCategory
  email?: string
  user?: UserRel
  company?: CompanyRel
  member?: MemberRel
  jar?: JarRel
  collaborator?: CollaboratorRel
}

class Tokens extends ApiResource<Token> {
  static readonly TYPE: TokenType = 'tokens' as const

  async create(
    resource: TokenCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Token> {
    return await this.resources.create<TokenCreate, Token>(
      {
        ...resource,
        type: Tokens.TYPE
      },
      params,
      options
    )
  }

  isToken(resource: any): resource is Token {
    return resource.type != null && resource.type === Tokens.TYPE
  }

  relationship(id: string | ResourceId | null): TokenRel {
    return id === null || typeof id === 'string'
      ? { id, type: Tokens.TYPE }
      : { id: id.id, type: Tokens.TYPE }
  }

  type(): TokenType {
    return Tokens.TYPE
  }
}

export type { Token, TokenCreate }
export default Tokens
