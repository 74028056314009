import { IonText } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { type TranslationOptions } from '#types/translate'

interface SubTitleProps extends ComponentProps<typeof IonText> {
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * @default
   * lg:text-2xl text-xl
   */
  size?: string
}
export default function SubTitle(props: SubTitleProps): JSX.Element {
  const {
    children,
    translationOptions,
    size = 'lg:text-lg text-xl',
    ...ionTextProps
  } = props

  const { t } = useTranslation()

  const subTitle =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children

  const color = ionTextProps?.color ?? 'gray-500'

  const subtitleBaseClass = 'mb-6 font-medium '

  const subtitleClass = classNames(
    ionTextProps?.className,
    subtitleBaseClass,
    size
  )
  return (
    <IonText {...ionTextProps} color={color}>
      <h2 className={subtitleClass}>{subTitle}</h2>
    </IonText>
  )
}
