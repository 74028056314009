import SelectTeam from '#components/common/TeamSelection'
import Label from '#components/ui/Label'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type Jar, type CollaboratorRole } from '#tackpay-sdk'

interface Props {
  currentTeams: Jar[]
  setCurrentTeams: (jars: Jar[]) => void
  role: CollaboratorRole
  setRole: (role: CollaboratorRole) => void
  email: string
  setEmail: (email: string) => void
}

export default function CollaboratorInviteInput({
  currentTeams,
  setCurrentTeams,
  role,
  setRole,
  email,
  setEmail
}: Props): JSX.Element {
  const { company } = useCompanyContext()

  return (
    <div className='mt-5'>
      <Label>email</Label>
      <div className='relative rounded-md shadow-sm mt-1'>
        <input
          type='email'
          name='email'
          id='email'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          className='block w-full rounded-md border-0 py-3 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6'
          placeholder='Enter email'
        />
        <div className='absolute inset-y-0 right-0 flex items-center border border-gray-500 rounded-md'>
          <select
            id='role'
            name='role'
            value={role}
            onChange={(e) => {
              setRole(e.target.value as CollaboratorRole)
              if (role === 'admin') setCurrentTeams([])
            }}
            className='h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-1  focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm text-gray-900 '
            data-testid='role-selected'
          >
            <option value='admin' className='capitalize'>
              Admin
            </option>
            <option value='manager' className='capitalize'>
              Manager
            </option>
          </select>
        </div>
      </div>
      {role === 'manager' && (
        <div className='mt-5'>
          <SelectTeam
            setCurrentTeam={setCurrentTeams}
            currentTeam={currentTeams}
            jars={company?.jars ?? []}
            multiple
          />
        </div>
      )}
    </div>
  )
}
