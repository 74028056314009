import AppLayout from '#components/layouts/app/AppLayout'
import Plans from '#components/pages/plans/Plans'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'

export default function PlanPage(): JSX.Element {
  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true,
        showNotificationsButton: false
      }}
      companyProps={{
        params: {
          include: ['customer.subscription.subscription_items', 'jars']
        }
      }}
      contentOptions={{
        scrollY: true
      }}
    >
      <Title>planPage.title</Title>
      <SubTitle>planPage.subTitle</SubTitle>
      <Plans />
    </AppLayout>
  )
}
