import { IonCol, IonIcon, IonInput, IonRow } from '@ionic/react'
import { type ComponentProps } from 'react'
import Label from './Label'
import Text from './Text'
import { type TranslationOptions } from '#types/translate'
import classNames from 'classnames'

interface InputProps extends ComponentProps<typeof IonInput> {
  /**
   * The label options.
   */
  labelOptions?: ComponentProps<typeof Label>
  /**
   * The optional label.
   */
  optionalLabel?: React.ReactNode
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * The icon options.
   */
  iconOptions?: ComponentProps<typeof IonIcon>
  /**
   * The test id.
   */
  ['data-testid']?: string
  testId?: string
}

export type InputOnChange = ComponentProps<typeof IonInput>['onIonChange']

export type InputValue = ComponentProps<typeof IonInput>['value']
export default function Input(props: InputProps): JSX.Element {
  const {
    translationOptions,
    labelOptions,
    optionalLabel,
    iconOptions,
    ...ionInputProps
  } = props

  const borderClass =
    'block   border-2 border-gray-200  focus-within:border-gray-400 '
  const inputBaseClass = 'rounded-lg text-gray-900  text-base inputs'

  const inputClassName = classNames(
    ionInputProps?.className,
    borderClass,
    inputBaseClass
  )

  const labelBaseClass = 'block mb-1 text-sm font-medium text-gray-900 '
  const labelClassName = classNames(labelOptions?.className, labelBaseClass)

  const labelPosition = labelOptions?.position ?? 'fixed'

  return (
    <IonCol className='ion-no-margin ion-no-padding' size='12'>
      {labelOptions != null && typeof optionalLabel !== 'undefined' && (
        <IonRow className='ion-no-padding ion-no-margin ion-justify-content-between ion-align-items-center w-full'>
          <IonCol size='6' className='ion-no-margin ion-no-padding'>
            <Label
              position={labelPosition}
              className={`${labelClassName} w-full`}
              capitalize={labelOptions?.capitalize ?? true}
            >
              {labelOptions?.children}
            </Label>
          </IonCol>
          <IonCol size='6' className='ion-no-margin ion-no-padding'>
            <IonRow className='w-full ion-justify-content-end'>
              {optionalLabel}
            </IonRow>
          </IonCol>
        </IonRow>
      )}
      {labelOptions != null && typeof optionalLabel === 'undefined' && (
        <Label
          // position={labelPosition}
          className={`${labelClassName} w-full`}
          capitalize={labelOptions?.capitalize ?? true}
        >
          {labelOptions.children}
        </Label>
      )}
      {iconOptions != null ? (
        <IonInput
          helperText=''
          className={`${inputClassName} z-0 `}
          {...ionInputProps}
        >
          <IonIcon {...iconOptions} />
        </IonInput>
      ) : (
        <IonInput
          {...ionInputProps}
          helperText=''
          className={`${inputClassName} z-0  `}
        />
      )}

      {props?.helperText != null && (
        <Text size='text-xs'>{props.helperText}</Text>
      )}
      {props.errorText != null && (
        <Text size='text-xs' color='text-red-500'>
          {props.errorText}
        </Text>
      )}
    </IonCol>
  )
}
