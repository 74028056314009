import { Route } from 'react-router'
import Materials from './views/Materials'
import MaterialDownload from './views/MetrialDownload'
import { IonPage, IonRouterOutlet } from '@ionic/react'

const basePath = '/dashboard/:category/materials'

export default function MaterialRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={basePath}>
          <Materials />
        </Route>
        <Route exact path={`${basePath}/downloads`}>
          <MaterialDownload />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
