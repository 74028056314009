import AppLayout from '#components/layouts/app/AppLayout'
import Button from '#components/ui/Button'
import ListLink from '#components/ui/ListLink'
import Modal from '#components/ui/Modal'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import Title from '#components/ui/Title'
import { getSetting } from '#constants/setting'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useUserContext } from '#contexts/UserContext'
import useToast from '#hooks/useToast'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type UserCategory } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { isBusiness } from '#utils/isBusiness'
import isCollaborator from '#utils/isCollaborator'
import { isDonation } from '#utils/isDonation'
import sdk from '#utils/sdk'
import {
  IonContent,
  IonHeader,
  IonRow,
  IonToolbar,
  useIonRouter
} from '@ionic/react'
import { useState } from 'react'
import { useParams } from 'react-router'

const HandleSetting = ({
  category
}: {
  category: UserCategory
}): JSX.Element => {
  const { user, isLoading } = useUserContext()

  const { collaborator, isLoading: isCollaboratorLoading } =
    useCollaboratorContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const [showModal, setShowModal] = useState(false)

  const settingList = getSetting(category)

  const handleUserDelete = (): void => {
    if (user != null) {
      sdk.users
        .delete(user.id)
        .then(() => {
          setShowModal(false)
          successToast(undefined, 'userDeleted')
          push('/login')
        })
        .catch((error) => {
          console.log(error)
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  settingList.forEach((setting) => {
    if (
      setting.label === 'setting_dashboard.account_statement' &&
      isDonation(user?.id)
    ) {
      setting.label = 'report_donation'
    }
  })

  if (collaborator != null) {
    if (collaborator.role === 'manager') {
      const indexToRemove = settingList.findIndex((setting) =>
        setting.label.includes('collaborator')
      )
      settingList.splice(indexToRemove, 1)
    }
  }

  if ((isCollaborator(category) && isCollaboratorLoading) || isLoading) {
    return (
      <>
        <Skeleton className='w-full h-10' />
        <Skeleton className='w-full h-10' />
        <Skeleton className='w-full h-10' />
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        setShowModal={setShowModal}
        initialBreakpoint={0.75}
        breakpoints={[0, 0.25, 0.5, 0.75, 0.95]}
      >
        <IonHeader className='px-5'>
          <IonToolbar className='ion-text-center'>
            <Text size='text-xl' fontWeight='font-bold' color='gray-900'>
              deleteAccount
            </Text>
          </IonToolbar>
          <Text className='ion-text-center'>deleteAccountInfo</Text>
        </IonHeader>
        <IonContent className='px-5 pt-10'>
          <IonRow className='ion-justify-content-between ion-text-center mt-16 px-5'>
            <Button
              fill='clear'
              color='gray-900'
              onClick={() => {
                setShowModal(false)
              }}
            >
              cancel
            </Button>
            <Button
              color='danger'
              onClick={() => {
                handleUserDelete()
              }}
            >
              confirm
            </Button>
          </IonRow>
        </IonContent>
      </Modal>
      <ListLink items={settingList} />
      <div className='w-full ion-text-center pt-16'>
        <Text
          className='ion-text-center w-full'
          color='danger'
          onClick={() => {
            setShowModal(true)
          }}
        >
          deleteAccount
        </Text>
      </div>
    </>
  )
}

export default function Settings(): JSX.Element {
  const { category: categoryParams } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{ showMenuButton: true, showBackButton: false }}
      userProps={{
        fetch: true,
        params: {
          include: !isBusiness(categoryParams)
            ? ['person', 'stripe_gateway_account']
            : ['person']
        }
      }}
    >
      <Title>setting</Title>
      <HandleSetting category={categoryParams} />
    </AppLayout>
  )
}
