import AppLayout from '#components/layouts/app/AppLayout'
import TeamTippingRuleSection from '#components/pages/teams/TeamTippingRuleSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function TippingRule(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: ['balance']
        }
      }}
    >
      <Title hasSubtitle>teamPage.tippingRule.title</Title>
      <SubTitle>teamPage.tippingRule.subtitle</SubTitle>
      <TeamTippingRuleSection category={category} />
    </AppLayout>
  )
}
