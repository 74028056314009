import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import { useStatisticBalanceContext } from '#contexts/StatisticBalanceContext'
import isDesktop from '#utils/isDesktop'
import { IonCol, IonRow } from '@ionic/react'
import EmptyStatisticBalance from './EmptyStatisticBalance'
import StatisticBalanceCard from './StatisticBalanceCard'
import { useUserContext } from '#contexts/UserContext'
import { isDonation } from '#utils/isDonation'

interface Props {
  showCard?: boolean
}

export default function StatisticBalance({
  showCard = true
}: Props): JSX.Element {
  const { statisticBalance, error, isLoading } = useStatisticBalanceContext()

  const { user } = useUserContext()

  if (isLoading) return <Skeleton className='h-56' />

  if (error != null) return <ErrorBox error={error} />

  if (statisticBalance == null) {
    if (isDesktop()) {
      return (
        <Card className='h-full ion-align-items-center'>
          <IonRow className='w-full h-full ion-no-margin ion-no-padding ion-align-items-center ion-justify-content-center'>
            <IonCol size='10' className='ion-no-margin ion-no-padding'>
              <EmptyStatisticBalance isDonation={isDonation(user?.id)} />
            </IonCol>
          </IonRow>
        </Card>
      )
    } else {
      return <EmptyStatisticBalance isDonation={isDonation(user?.id)} />
    }
  }

  return (
    <StatisticBalanceCard
      showCard={showCard}
      statisticBalance={statisticBalance}
    />
  )
}
