import AuthLayout from '#components/layouts/auth/AuthLayout'
import ConfirmEmailContent from '#components/pages/confirmEmail/ConfirmEmailContent'
import TokenContainer from '#contexts/TokenContext'
import getQueryParams from '#utils/getQueryParams'
import { useIonRouter } from '@ionic/react'

export default function ConfirmEmail(): JSX.Element {
  const { routeInfo } = useIonRouter()

  const token = getQueryParams(routeInfo.search).get('token') as string

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true
      }}
    >
      <TokenContainer fetch={token != null} value={token}>
        <ConfirmEmailContent />
      </TokenContainer>
    </AuthLayout>
  )
}
