import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Member } from '#tackpay-sdk'
import formattedDate from '#utils/formattedDate'
import { isInactiveCapabilities } from '#utils/isInactiveCapabilities'
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface Props {
  member: Member
}
export default function MemberCardInfo({ member }: Props): JSX.Element {
  const { i18n } = useTranslation()

  const getTackpayId = (): string => {
    if (member?.status === 'invitation_pending') return 'pending'
    return member?.user?.tackpay_id?.value ?? 'none'
  }

  const getCategory = (): string => {
    if (member?.status === 'invitation_pending') return 'pending'
    return member?.user?.subcategory ?? 'none'
  }

  return (
    <Card className='mt-10'>
      <IonCardHeader>
        <IonCardTitle>Info</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className='ion-no-padding'>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <Text className='text-gray-600'>members.subcategory</Text>
            </IonCol>
            <IonCol className='text-right ion-no-margin ion-no-padding'>
              <Text color='dark' translate='no' className='font-semibold'>
                {getCategory()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600' translate='no'>
                Tackpay ID
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {getTackpayId()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <Text className='text-gray-600' translate='no'>
                Jar
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-margin ion-no-padding'>
              <Text color='dark' translate='no' className='font-semibold'>
                {member?.jar?.name}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600' translate='no'>
                status
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {member?.status}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600'>members.requestSend</Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {member?.created_at != null &&
                  formattedDate(member?.created_at, i18n.language)}
              </Text>
            </IonCol>
          </IonRow>
          {isInactiveCapabilities(member?.user?.stripe_gateway_account) && (
            <IonRow className='mt-3'>
              <IonCol className='ion-no-padding ion-no-margin'>
                <Text className='text-gray-600'>
                  members.invalid_capability
                </Text>
              </IonCol>
              <IonCol className='text-right ion-no-padding ion-no-margin'>
                <Text color='dark' translate='no' className='font-semibold'>
                  {member?.user?.stripe_gateway_account?.capabilities
                    ?.transfers ?? 'inactive'}
                </Text>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonCardContent>
    </Card>
  )
}
