import AppLayout from '#components/layouts/app/AppLayout'
import MemberInviteSection from '#components/pages/members/MemberInviteSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import MemberContainer from '#contexts/MemberContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function AddTeamMember(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showCustomIcon: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/members`
        }
      }}
      teamProps={{
        fetch: true
      }}
    >
      <Title hasSubtitle>member.add.title</Title>
      <SubTitle>member.add.subtitle</SubTitle>
      <div className='pb-10'>
        <MemberContainer fetch={false}>
          <MemberInviteSection jarId={jarId} />
        </MemberContainer>
      </div>
    </AppLayout>
  )
}
