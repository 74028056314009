import { IonImg } from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps } from 'react'
import Text from './Text'

interface Props {
  /**
   * Class name for the component
   */
  className?: string
  /**
   * Margin. Default `mt-8`
   */
  margin?: string
  /**
   * Options for the IonImg component
   */
  imgOptions?: ComponentProps<typeof IonImg>
  /**
   * Options for the Text component
   */
  textOptions?: {
    text: string
    props?: Omit<ComponentProps<typeof Text>, 'children'>
  }
}

export default function Empty({
  className,
  margin = 'mt-8',
  textOptions,
  imgOptions
}: Props): JSX.Element {
  const base = 'flex flex-col ion-align-items-center ion-justify-content-center'
  const style = classNames(className, margin, base)

  return (
    <div className={style}>
      {imgOptions != null && <IonImg id='svgImage' {...imgOptions} />}
      {textOptions != null && (
        <Text {...textOptions.props}>{textOptions.text}</Text>
      )}
    </div>
  )
}
