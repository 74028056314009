import Input, { type InputOnChange } from '#components/ui/Input'
import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import Toggle from '#components/ui/Toggle'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow } from '@ionic/react'

export default function CheckoutSettingAmount(): JSX.Element {
  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  if (checkoutSetting == null) return <></>

  const onAmountChange: InputOnChange = (e) => {
    const { name, value } = e.target

    if (typeof value !== 'string') return

    const amount = Number(value.replace(/[^0-9]/g, ''))

    if (setCurrentCheckoutSetting != null) {
      if (name === 'firstAmount') {
        setCurrentCheckoutSetting({
          ...checkoutSetting,
          fast_amounts: [
            amount * 100,
            checkoutSetting.fast_amounts[1],
            checkoutSetting.fast_amounts[2]
          ]
        })
      }

      if (name === 'secondAmount') {
        setCurrentCheckoutSetting({
          ...checkoutSetting,
          fast_amounts: [
            checkoutSetting.fast_amounts[0],
            amount * 100,
            checkoutSetting.fast_amounts[2]
          ]
        })
      }

      if (name === 'thirdAmount') {
        setCurrentCheckoutSetting({
          ...checkoutSetting,
          fast_amounts: [
            checkoutSetting.fast_amounts[0],
            checkoutSetting.fast_amounts[1],
            amount * 100
          ]
        })
      }
    }
  }

  const onShowAmountChange = (show: boolean): void => {
    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        show_custom_amount: show
      })
    }
  }

  const onShowAmountPercentsChange = (show: boolean): void => {
    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        show_percentage_amount: show
      })
    }
  }

  return (
    <div slot='content' className='pt-1 space-y-3'>
      <Text size='text-base' data-testid='title-amounts'>
        checkoutSetting.amountInfo
      </Text>
      <Text
        className='mt-5'
        color='gray-900'
        fontWeight='font-semibold'
        data-testid='subtitle-amounts'
        size='text-base md:text-sm'
      >
        checkoutSetting.suggestAmount
      </Text>
      <IonRow className='space-x-2 ion-justify-content-between ion-align-items-center'>
        <div className='w-1/4'>
          <Input
            name='firstAmount'
            pattern='[0-9]*'
            value={formattedAmount(checkoutSetting.fast_amounts[0])}
            onIonInput={onAmountChange}
            testId='firstAmount'
          />
        </div>
        <div className='w-1/4'>
          <Input
            name='secondAmount'
            pattern='[0-9]*'
            value={formattedAmount(checkoutSetting.fast_amounts[1])}
            onIonInput={onAmountChange}
            testId='secondAmount'
          />
        </div>
        <div className='w-1/4'>
          <Input
            name='thirdAmount'
            pattern='[0-9]*'
            value={formattedAmount(checkoutSetting.fast_amounts[2])}
            onIonInput={onAmountChange}
            testId='thirdAmount'
          />
        </div>
      </IonRow>
      <IonCol className='space-y-3'>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <Label>customAmount</Label>
          <Toggle
            id='custom'
            checked={checkoutSetting?.show_custom_amount}
            onClick={() => {
              onShowAmountChange(!checkoutSetting.show_custom_amount)
            }}
          />
        </IonRow>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <Label>percentsAmount</Label>
          <Toggle
            id='percents'
            checked={checkoutSetting.show_percentage_amount}
            onClick={() => {
              onShowAmountPercentsChange(
                !checkoutSetting.show_percentage_amount
              )
            }}
            data-testid='toggle-percents'
          />
        </IonRow>
      </IonCol>
    </div>
  )
}
