import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { type BalanceDeduction, type Balance } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonIcon, IonRow } from '@ionic/react'
import { hourglassOutline } from 'ionicons/icons'

interface Props {
  /**
   * The balance object
   */
  balance?: Balance | null | BalanceDeduction
  /**
   * The loading state
   */
  loading?: boolean
  /**
   * The error object
   */
  error?: any
}
export default function PayoutBalancePending({
  balance,
  loading = true,
  error
}: Props): JSX.Element {
  const PendingAmountBalance = (): JSX.Element => {
    if (loading) return <Skeleton className='w-10 h-5' />
    if (error != null) return <ErrorBox error={error} />

    return (
      <Text className='text-gray-800'>
        {formattedAmount(
          balance?.pending?.amount_cents,
          balance?.pending?.currency
        )}
      </Text>
    )
  }

  return (
    <div
      className='ion-no-padding ion-no-margin absolute top-52 right-0 shadow rounded-l-2xl'
      id='payout-pending'
    >
      <div className='px-3 py-1'>
        <IonRow className='justify-between ion-align-items-center space-x-6'>
          <IonIcon src={hourglassOutline} color='primary' className='w-6 h-6' />
          <div className='flex flex-col'>
            <Text className='text-xs' data-testid='balance-pending'>
              payout.balance.pending
            </Text>
            <PendingAmountBalance />
          </div>
        </IonRow>
      </div>
    </div>
  )
}
