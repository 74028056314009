const SUBCATEGORIES = [
  'hotel',
  'restaurant',
  'bar',
  'other',
  'barman',
  'waiter',
  'cook',
  'rider',
  'host',
  'driver',
  'pizzeria'
] as const

export type Subcategory = (typeof SUBCATEGORIES)[number]

export interface FeedbackType {
  subcategory: Subcategory
  feedback: string[]
}

export const FEEDBACKS: FeedbackType[] = [
  {
    subcategory: 'hotel',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'responsabile',
      'preparato',
      'preciso',
      'coeso',
      'qualificato',
      'empatico',
      'disponibile',
      'carismatico',
      'super',
      'sorridente',
      'accomodante',
      'professionale'
    ]
  },
  {
    subcategory: 'restaurant',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'responsabile',
      'preparato',
      'preciso',
      'coeso',
      'qualificato',
      'empatico',
      'disponibile',
      'carismatico',
      'super',
      'sorridente',
      'accomodante',
      'professionale'
    ]
  },
  {
    subcategory: 'bar',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'responsabile',
      'preparato',
      'preciso',
      'coeso',
      'qualificato',
      'empatico',
      'disponibile',
      'carismatico',
      'super',
      'sorridente',
      'accomodante',
      'professionale'
    ]
  },
  {
    subcategory: 'barman',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'preparato',
      'responsabile',
      'prudente',
      'sorridente',
      'puntuale',
      'preciso',
      'bravo',
      'super',
      'carismatico',
      'qualificato',
      'simpatico',
      'disponibile',
      'accomodante'
    ]
  },
  {
    subcategory: 'waiter',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'preparato',
      'responsabile',
      'prudente',
      'sorridente',
      'puntuale',
      'preciso',
      'bravo',
      'super',
      'carismatico',
      'qualificato',
      'simpatico',
      'disponibile',
      'accomodante'
    ]
  },
  {
    subcategory: 'cook',
    feedback: [
      'gentile',
      'veloce',
      'abile',
      'geniale',
      'eccellente',
      'artista',
      'bravo',
      'super',
      'originale',
      'qualificato',
      'professionale',
      'preciso'
    ]
  },
  {
    subcategory: 'rider',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'disponibile',
      'responsabile',
      'bravo',
      'super',
      'sorridente'
    ]
  },
  {
    subcategory: 'host',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'empatico',
      'responsabile',
      'accomodante',
      'preparato',
      'carismatico',
      'sorridente',
      'disponibile',
      'super',
      'professionale',
      'preciso',
      'bravo',
      'qualificato'
    ]
  },
  {
    subcategory: 'other',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'empatico',
      'responsabile',
      'accomodante',
      'preparato',
      'carismatico',
      'sorridente',
      'disponibile',
      'super',
      'professionale',
      'preciso',
      'bravo',
      'qualificato'
    ]
  },
  {
    subcategory: 'driver',
    feedback: [
      'gentile',
      'premuroso',
      'empatico',
      'veloce',
      'abile',
      'preparato',
      'responsabile',
      'prudente',
      'sorridente',
      'puntuale',
      'preciso',
      'bravo',
      'super',
      'carismatico',
      'professionale',
      'simpatico',
      'disponibile'
    ]
  },
  {
    subcategory: 'pizzeria',
    feedback: [
      'gentile',
      'premuroso',
      'simpatico',
      'veloce',
      'abile',
      'responsabile',
      'preparato',
      'preciso',
      'coeso',
      'qualificato',
      'empatico',
      'disponibile',
      'carismatico',
      'super',
      'sorridente',
      'accomodante',
      'professionale'
    ]
  }
]
