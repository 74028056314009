import useAuthState from '#hooks/useAuthState'
import { type QueryParamsRetrieve, type StatisticBalance } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

interface StatisticBalanceContextValue {
  statisticBalance?: StatisticBalance | null
  isLoading: boolean
  error?: any
}

const statisticBalanceInitialValue: StatisticBalanceContextValue = {
  statisticBalance: undefined,
  isLoading: false,
  error: null
}

const StatisticBalanceContext = createContext<StatisticBalanceContextValue>(
  statisticBalanceInitialValue
)

export const useStatisticBalanceContext = (): StatisticBalanceContextValue => {
  const context = useContext(StatisticBalanceContext)
  if (context == null) {
    throw new Error(
      'useStatisticBalanceContext must be used within a StatisticBalanceProvider'
    )
  }

  return context
}

interface StatisticBalanceContainerProps {
  children: React.ReactNode
  /**
   * @default true
   */
  fetch?: boolean

  jarId?: string

  params?: QueryParamsRetrieve
}

export default function StatisticBalanceContainer(
  props: StatisticBalanceContainerProps
): JSX.Element {
  const { children, fetch = true, params, jarId } = props

  const { getAuthState } = useAuthState()

  const {
    data: statisticBalance,
    isLoading,
    error
  } = useQuery<StatisticBalance | undefined | null>({
    queryKey: ['statisticBalance', jarId, params],
    queryFn: async () => {
      if (jarId != null) {
        return await sdk.jars.statistic_balance(jarId, params)
      } else {
        const authInfo = await getAuthState()
        return await sdk.users.statistic_balance(authInfo?.userId ?? '', params)
      }
    },
    enabled: fetch
  })

  // // Aggiorniamo i dati utente se la prop "fetch" cambia
  // useEffect(() => {
  //   if (fetch) {
  //     void queryClient.invalidateQueries(['statisticBalance', jarId, params])
  //     setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
  //   }
  // }, [fetch, queryClient])

  const statisticBalanceContextValue: StatisticBalanceContextValue = {
    statisticBalance,
    isLoading,
    error
  }

  return (
    <StatisticBalanceContext.Provider value={statisticBalanceContextValue}>
      {children}
    </StatisticBalanceContext.Provider>
  )
}
