import { type StripeGatewayAccount } from '#tackpay-sdk'

const isInactiveCapabilities = (
  account?: StripeGatewayAccount,
  isTipped = true
): boolean => {
  if (account == null) {
    return isTipped
  }
  return (
    account.capabilities?.transfers !== 'active' ||
    account.capabilities?.card_payments !== 'active'
  )
}

export { isInactiveCapabilities }
