import AppLayout from '#components/layouts/app/AppLayout'
import StatisticHeader from '#components/pages/statistics/StatisticHeader'
import StatisticAmount from '#components/pages/statistics/amount/StatisticAmount'
import StatisticBalance from '#components/pages/statistics/balance/StatisticBalance'
import StatisticView from '#components/pages/statistics/view/StatisticView'
import { type SelectOnChangeEvent } from '#components/ui/BasicSelector'
import {
  STATISTIC_CATEGORIES,
  type StatisticCategory
} from '#constants/statistics'
import StatisticAmountContainer from '#contexts/StatisticAmountContext'
import StatisticBalanceContainer from '#contexts/StatisticBalanceContext'
import StatisticViewContainer from '#contexts/StatisticViewConytext'
import { isBusiness } from '#utils/isBusiness'
import { useState } from 'react'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '../DashboardRoutes'

export default function Statistics(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const [currentCategory, setCurrentCategory] = useState<StatisticCategory>(
    STATISTIC_CATEGORIES[0]
  )

  const handleStatsChange: SelectOnChangeEvent = (e) => {
    const stats = e.target.value
    setCurrentCategory(stats)
  }

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      userProps={{
        fetch: true,
        params: {
          include: !isBusiness(category)
            ? ['person', 'checkout_sessions', 'stripe_gateway_account']
            : ['person']
        }
      }}
    >
      <StatisticHeader
        currentCategory={currentCategory}
        handleStatsChange={handleStatsChange}
      />
      {currentCategory === 'amounts' && (
        <StatisticAmountContainer>
          <StatisticAmount />
        </StatisticAmountContainer>
      )}

      {currentCategory === 'views' && (
        <StatisticViewContainer>
          <StatisticView />
        </StatisticViewContainer>
      )}
      {currentCategory === 'balances' && (
        <StatisticBalanceContainer>
          <StatisticBalance />
        </StatisticBalanceContainer>
      )}
    </AppLayout>
  )
}
