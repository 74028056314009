import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import AppLayout from '#components/layouts/app/AppLayout'
import ProgressBar from '#components/ui/ProgessBar'
import CardCategory from '#components/common/CardCategory'
import { IonCol, useIonRouter } from '@ionic/react'
import { type UserCategory, type TippingRule } from '#tackpay-sdk'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import ButtonStep from '#components/common/ButtonStep'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import { useUserContext } from '#contexts/UserContext'
import { EUROPEAN_COUNTRY_CODES } from '#constants/country'

function HandleTippingRule({
  category
}: {
  category: UserCategory
}): JSX.Element {
  const { user } = useUserContext()

  const { setTeamCreate, teamCreate } = useCreateTeamStore()

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  const handleTippingRule = (tippingRule: TippingRule): void => {
    setTeamCreate({ tipping_rule: tippingRule })
    push(`/dashboard/${category}/jars/add/reviews`)
  }

  const handleNextStep = (): void => {
    if (textUtil.isEmpty(teamCreate?.tipping_rule)) {
      showToast(undefined, 'teamPage.tippingRulesRequired')
      return
    }

    push(`/dashboard/${category}/jars/add/reviews`)
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add/roles`, 'back')
  }

  const isAllTippingRule =
    user?.person?.address != null
      ? EUROPEAN_COUNTRY_CODES.includes(
          user?.person?.address?.country_code.toUpperCase() as any
        )
      : false

  return (
    <>
      <IonCol className='space-y-10 mt-10'>
        {isAllTippingRule && (
          <CardCategory
            cardProps={{
              onClick: (): void => {
                handleTippingRule('equal_split')
              }
            }}
            options={{
              imgOptions: {
                src: '/assets/img/tipping-equal.png',
                alt: 'tippingEqual'
              }
            }}
            title='equal_split'
            description='teamPage.equalSplit.description'
            showCheckIcon={teamCreate?.tipping_rule === 'equal_split'}
          />
        )}
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleTippingRule('individual')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-individual.webp',
              alt: 'tippingIndividual'
            }
          }}
          title='individual'
          description='teamPage.individual.description'
          showCheckIcon={teamCreate?.tipping_rule === 'individual'}
        />
        {isAllTippingRule && (
          <CardCategory
            cardProps={{
              onClick: (): void => {
                handleTippingRule('tronc')
              }
            }}
            options={{
              imgOptions: {
                src: '/assets/img/tipping-individual.webp',
                alt: 'tippingTronc'
              }
            }}
            title='tronc'
            description='teamPage.tronc.description'
            showCheckIcon={teamCreate?.tipping_rule === 'tronc'}
          />
        )}
      </IonCol>
      <ButtonStep handleNext={handleNextStep} handleBack={handleBack} />
    </>
  )
}

export default function AddTeamTippingRule(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add/roles`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
    >
      <ProgressBar value={0.7} text='teamPage.add.chip.tippingRules' />
      <HandleTippingRule category={category} />
    </AppLayout>
  )
}
