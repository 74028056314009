import Text from '#components/ui/Text'
import { IonIcon, IonRow } from '@ionic/react'
import { copyOutline, downloadOutline } from 'ionicons/icons'
import { Clipboard } from '@capacitor/clipboard'
import useToast from '#hooks/useToast'
import { GLOBAL_CONFIG } from 'configs/global'
import { useShare } from '#hooks/useShare'
import isWeb from '#utils/isWeb'
import Skeleton from '#components/ui/Skeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { type TackpayId } from '#tackpay-sdk'
import fileSave from 'file-saver'
import useStorage from '#hooks/useStorage'
import useFilesystem from '#hooks/useFilesystem'

interface Props {
  loading?: boolean
  error?: any
  tackpayId?: TackpayId | null
  textSize?: string
}
export default function CardQrCodeLink({
  loading = true,
  error = null,
  tackpayId,
  textSize
}: Props): JSX.Element {
  const { showToast } = useToast('success')

  const { shareElement } = useShare()

  const { getBlobImage } = useStorage()

  const { download } = useFilesystem()

  const Icon = ({
    icon,
    onClick
  }: {
    icon: string
    onClick?: () => void
  }): JSX.Element => {
    if (loading) return <Skeleton className='w-5' />
    if (error != null) return <ErrorBox error={error} />
    return (
      <IonIcon
        src={icon}
        className='bg-green-100  p-1 rounded-full cursor-pointer'
        onClick={onClick}
      />
    )
  }

  const handleCopy = (): void => {
    Clipboard.write({
      string: `${GLOBAL_CONFIG.checkoutURL}/${tackpayId?.value ?? ''}`
    })
      .then(() => {
        console.log('Copied to clipboard')
        showToast('copiedUrlTitle', 'checkoutUrlCopied')
      })
      .catch((error) => {
        console.error('Unexpected error to copied clipboard %O ', error)
        navigator.clipboard
          .writeText(`${GLOBAL_CONFIG.checkoutURL}/${tackpayId?.value ?? ''}`)
          .then(() => {
            console.log('Copied to clipboard')
            showToast('copiedUrlTitle', 'checkoutUrlCopied')
          })
          .catch((error) => {
            console.error('Unexpected error to copied clipboard %O ', error)
          })
      })
  }

  const handleDownload = (): void => {
    if (isWeb()) {
      getBlobImage(tackpayId?.qr_code_storage_url ?? '')
        .then((blob) => {
          fileSave(blob, 'qr.png')
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else {
      download({
        url: tackpayId?.qr_code_storage_url ?? '',
        path: 'qr.png'
      })
        .then((result) => {
          console.log('downloaded file', result)

          shareElement({
            title: 'Checkout my store',
            url: result.path,
            text: 'Checkout my store',
            dialogTitle: 'Share with friends'
          })
            .then(() => {
              console.log('shared')
            })
            .catch((error) => {
              console.log('error to share', error)
            })
        })
        .catch((error) => {
          console.log('error to download file', error)
        })
    }
  }

  return (
    <div className='space-y-3 mt-8'>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Text size={textSize}>dashboard.link_client</Text>
        <Icon icon={copyOutline} onClick={handleCopy} />
      </IonRow>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Text size={textSize}>dashboard.download_qr</Text>
        <Icon icon={downloadOutline} onClick={handleDownload} />
      </IonRow>
    </div>
  )
}
