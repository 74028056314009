import Modal from '#components/ui/Modal'
import { type Jar } from '#tackpay-sdk'
import PlanModalTeamManyContent from './PlanModalTeamManyContent'
import PlanModalTeamOneContent from './PlanModalTeamOneContent'

interface Props {
  showModal: boolean
  setShowModal: (show: boolean) => void
  type: 'selectOne' | 'selectMany'
  oldQuantity?: number
  newQuantity?: number
  subscriptionItemId?: string
  jars: Jar[]
  customerId: string
}

export default function PlanModalTeam({
  showModal,
  setShowModal,
  type,
  oldQuantity,
  newQuantity,
  subscriptionItemId,
  jars,
  customerId
}: Props): JSX.Element {
  const getOperation = (): 'add' | 'remove' => {
    if (oldQuantity == null || newQuantity == null) return 'add'
    if (oldQuantity < newQuantity) {
      return 'add'
    }
    return 'remove'
  }

  return (
    <Modal isOpen={showModal} setShowModal={setShowModal} id='selectTeamPlan'>
      {type === 'selectMany' && newQuantity != null && (
        <PlanModalTeamManyContent
          operation={getOperation()}
          jars={jars}
          setShowModal={setShowModal}
          subscriptionItemId={subscriptionItemId ?? ''}
          newQuantity={newQuantity}
          customerId={customerId}
          subscriptionQuantity={oldQuantity ?? 1}
        />
      )}
      {type === 'selectOne' && (
        <PlanModalTeamOneContent
          setShowModal={setShowModal}
          jars={jars}
          customerId={customerId}
        />
      )}
    </Modal>
  )
}
