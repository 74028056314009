import AppLayout from '#components/layouts/app/AppLayout'
import ListLink from '#components/ui/ListLink'
import Title from '#components/ui/Title'
import { getMaterialSettings } from '#constants/material'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function TeamMaterial(): JSX.Element {
  const { jarId, category } = useParams<TeamRoutesParams>()

  const materialSettings = getMaterialSettings(
    category,
    `/dashboard/${category}/jars/${jarId}/settings/materials`
  )

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
    >
      <Title>material.title</Title>
      <ListLink items={materialSettings} />
    </AppLayout>
  )
}
