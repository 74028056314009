import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import AddTeamReviewView from '#components/pages/teams/add/AddTeamReviewView'
import CreateTeamContainer from '#contexts/CreateTeamContext'

export default function AddTeamReviews(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  const { teamCreate, isSameCompany, isSameIban } = useCreateTeamStore()

  const backUrl =
    isSameCompany === true
      ? isSameIban === true && teamCreate?.jar_type === 'private'
        ? `/dashboard/${category}/jars/add/ibans`
        : teamCreate?.jar_type === 'shared'
        ? `/dashboard/${category}/jars/add/tipping-rules`
        : `/dashboard/${category}/jars/add/ibans/add`
      : `/dashboard/${category}/jars/add/addresses`

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: backUrl
        }
      }}
    >
      <ProgressBar value={0.9} text='teamPage.add.chip.review' />
      <CreateTeamContainer>
        <AddTeamReviewView category={category} />
      </CreateTeamContainer>
    </AppLayout>
  )
}
