import { type QueryParamsRetrieve, type Payment } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

interface PaymentContextValue {
  payment?: Payment | null
  isLoading: boolean
  error?: any
}

const paymentIntialState: PaymentContextValue = {
  isLoading: false,
  payment: undefined,
  error: undefined
}

const PaymentContext = createContext<PaymentContextValue>(paymentIntialState)

export const usePaymentContext = (): PaymentContextValue => {
  const context = useContext(PaymentContext)
  if (context == null)
    throw new Error(
      'usePaymentContext must be used within a PaymentContextProvider'
    )
  return context
}

interface PaymentContainerProps {
  children: React.ReactNode
  paymentId: string
  /** @default true */
  params?: QueryParamsRetrieve
  fetch?: boolean
}

export default function PaymentContainer(
  props: PaymentContainerProps
): JSX.Element {
  const { children, paymentId, params, fetch = true } = props

  const queryClient = useQueryClient()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: payment,
    isLoading,
    error
  } = useQuery<Payment>({
    queryKey: ['payment', paymentId, params],
    queryFn: async () => {
      return await sdk.payments.retrieve(paymentId, params)
    },
    enabled: !initialFetchComplete && fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  useEffect(() => {
    if (fetch) {
      void queryClient.invalidateQueries({
        queryKey: ['payment', paymentId, params]
      })
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const paymentContextValue: PaymentContextValue = {
    payment,
    isLoading,
    error
  }

  return (
    <PaymentContext.Provider value={paymentContextValue}>
      {children}
    </PaymentContext.Provider>
  )
}
