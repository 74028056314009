import Button from '#components/ui/Button'
import { getSymbolCurrency } from '#constants/country'
import { useTranslation } from 'react-i18next'
import PlanModalTeam from './PlanModalTeam'
import { useState } from 'react'
import { useCompanyContext } from '#contexts/CompanyContext'
import Chip from '#components/ui/Chip'
import { IonRow } from '@ionic/react'

interface Props {
  isActive: boolean
  currency: string
}

export default function PlanFreeCard({
  isActive,
  currency
}: Props): JSX.Element {
  const { t } = useTranslation()

  const { company } = useCompanyContext()

  const [showModal, setShowModal] = useState<boolean>(false)

  const features = [
    {
      title: 'plan_1_feature_1',
      has: true
    },
    {
      title: 'plan_1_feature_2',
      has: true
    },
    {
      title: 'plan_1_feature_3',
      has: true
    },
    {
      title: 'plan_1_feature_4',
      has: true
    },
    {
      title: 'plan_1_feature_5',
      has: true
    }
  ]

  const border = isActive ? 'border-green-400' : 'border-gray-100'

  return (
    <>
      <PlanModalTeam
        showModal={showModal}
        setShowModal={setShowModal}
        jars={company?.jars ?? []}
        type='selectOne'
        customerId={company?.customer?.id ?? ''}
      />
      <div
        className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border  shadow  xl:p-8   ${border}`}
      >
        <IonRow className='w-full ion-align-items-center ion-justify-content-center space-x-5'>
          <div>
            <h3 className='mb-4 text-2xl font-semibold'>{t('plan_1_title')}</h3>
          </div>
          {isActive && (
            <Chip color='white' className='bg-green-300 mb-4'>
              currentPlan
            </Chip>
          )}
        </IonRow>
        <p className='font-light text-gray-500 sm:text-lg '>
          {t('plan_1_subtitle')}
        </p>
        <div className='flex justify-center items-baseline my-8'>
          <span className='mr-2 text-5xl font-extrabold'>
            {getSymbolCurrency(currency)}0
          </span>

          <span className='text-gray-500 '>{`/${t('month')}`}</span>
        </div>

        <ul role='list' className='mb-8 space-y-4 text-left'>
          {features.map((feature) => (
            <li key={feature.title} className='flex items-center space-x-3'>
              {feature.has ? (
                <svg
                  className='flex-shrink-0 w-5 h-5 text-green-500 '
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  className='flex-shrink-0 w-5 h-5 text-gray-500 '
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
              <span>{t(feature.title)}</span>
            </li>
          ))}
        </ul>
        <Button
          onClick={() => {
            setShowModal(true)
          }}
          disabled={isActive}
        >
          plan_1_button
        </Button>
      </div>
    </>
  )
}
