import {
  ApiResource,
  type ResourceRel,
  type Resource,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type Jar } from './jars'

type PayoutRetainFeeType = 'payout_retain_fees'
type PayoutRetainFeeRel = ResourceRel & { type: PayoutRetainFeeType }

interface PayoutRetainFee extends Resource {
  readonly type: PayoutRetainFeeType
  stack_amount_cents: number
  currency: string
  retain: boolean
  user?: User
  jar?: Jar
}

class PayoutRetainFees extends ApiResource<PayoutRetainFee> {
  static readonly TYPE: PayoutRetainFeeType = 'payout_retain_fees' as const

  isPayoutRetainFee(resource: any): resource is PayoutRetainFee {
    return resource.type != null && resource.type === PayoutRetainFees.TYPE
  }

  relationship(id: string | ResourceId | null): PayoutRetainFeeRel {
    return id === null || typeof id === 'string'
      ? { id, type: PayoutRetainFees.TYPE }
      : { id: id.id, type: PayoutRetainFees.TYPE }
  }

  type(): PayoutRetainFeeType {
    return PayoutRetainFees.TYPE
  }
}

export type { PayoutRetainFee }
export default PayoutRetainFees
