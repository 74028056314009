import {
  type User,
  type QueryParamsRetrieve,
  type UserUpdate,
  type UserCreate
} from '#tackpay-sdk'
import { createContext, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  useMutation,
  type UseMutationResult,
  type RefetchOptions,
  type RefetchQueryFilters,
  type QueryObserverResult,
  type UseQueryResult
} from '@tanstack/react-query'
import sdk from '#utils/sdk'
import useAuthState from '#hooks/useAuthState'

type UpdateUserFunction = UseMutationResult<User, unknown, UserUpdate, unknown>

type CreateUserFunction = UseMutationResult<User, unknown, UserCreate, unknown>

type RetrieveUserFunction = UseQueryResult<User, unknown>

interface UserContextValue {
  isLoading: boolean
  error?: any
  user?: User
  editUser?: UpdateUserFunction
  createUser?: CreateUserFunction
  retrieveUser?: RetrieveUserFunction
  refetch?: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<User, unknown>>
}

const initialState: UserContextValue = {
  isLoading: false,
  error: undefined,
  user: undefined,
  editUser: undefined
}

const UserContext = createContext<UserContextValue>(initialState)

export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext)
  if (context == null) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

interface UserContainerProps {
  children: React.ReactNode
  fetch?: boolean
  params?: QueryParamsRetrieve
}

const handleEditUser = async (user: UserUpdate): Promise<User> => {
  return await sdk.users.update(user)
}

const handleCreateUser = async (user: UserCreate): Promise<User> => {
  return await sdk.users.create(user)
}

export default function UserContainer(props: UserContainerProps): JSX.Element {
  const { children, fetch = true, params } = props

  const queryClient = useQueryClient()

  const { getAuthState } = useAuthState()

  const retrieveUser = useQuery<User>({
    queryKey: ['user', params],
    queryFn: async () => {
      const authInfo = await getAuthState()
      return await sdk.users.retrieve(authInfo?.userId ?? '', params)
    },
    enabled: fetch
  })

  const useEditUser = useMutation({
    mutationFn: handleEditUser,
    onMutate: async (user: UserUpdate) => {
      await queryClient.cancelQueries({ queryKey: ['user', user.id] })
      const previousUser = queryClient.getQueryData(['user', user.id])
      queryClient.setQueryData(['user', user.id], user)
      return { previousUser, user }
    },
    onError: (_err, _variables, context: any) => {
      queryClient.setQueryData(['user', context.user.id], context.previousUser)
    },
    onSettled: (user) => {
      void queryClient.invalidateQueries({ queryKey: ['user', user?.id] })
    }
  })

  const useCreateUser = useMutation({
    mutationFn: handleCreateUser
  })

  const userContextValue: UserContextValue = {
    isLoading: retrieveUser.isLoading,
    error: retrieveUser.error,
    user: retrieveUser.data,
    editUser: useEditUser,
    createUser: useCreateUser,
    refetch: retrieveUser.refetch,
    retrieveUser
  }

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  )
}
