import { type ApexOptions } from 'apexcharts'
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

interface ChartThreeState {
  series: number[]
}

interface Props {
  series?: number[]
  labels?: string[]
}

export default function ChartDonut({
  series = [65, 34, 12, 56],
  labels = ['Remote', 'Hybrid', 'Onsite', 'Leave']
}: Props): JSX.Element {
  const { t } = useTranslation()

  const options: ApexOptions = {
    chart: {
      type: 'donut'
    },
    colors: ['#10B981', '#375E83', '#259AE6', '#FFA70B'],
    labels: labels.map((f) => t(`feedback.${f}`)),
    legend: {
      show: true,
      position: 'bottom',
      markers: {
        width: 12,
        height: 12,
        radius: 2
      },
      offsetY: 6
    },

    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          background: 'transparent'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 500
          }
        }
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 450
          }
        }
      }
    ]
  }
  const [state] = useState<ChartThreeState>({
    series
  })

  return (
    <div className='mb-10'>
      <div id='chartThree' className='mx-auto flex justify-center'>
        <ReactApexChart options={options} series={state.series} type='donut' />
      </div>
    </div>
  )
}
