import sdk from '#utils/sdk'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { type PermissionState } from '@capacitor/core'

export default function useFCMToken(): {
  checkPermissions: () => Promise<PermissionState>
  requestPermissions: () => Promise<PermissionState>
  getToken: () => Promise<string | undefined>
  deleteToken: () => Promise<void>
  setTokenOnServer: (subscriberId: string, token: string) => Promise<void>
} {
  const checkPermissions = async (): Promise<PermissionState> => {
    const result = await FirebaseMessaging.checkPermissions()
    return result.receive
  }

  const requestPermissions = async (): Promise<PermissionState> => {
    const result = await FirebaseMessaging.requestPermissions()
    return result.receive
  }

  const getToken = async (): Promise<string | undefined> => {
    const canSupported = await FirebaseMessaging.isSupported()
    if (canSupported.isSupported) {
      const resultPermission = await checkPermissions()
      if (resultPermission === 'granted') {
        await deleteToken()
        const result = await FirebaseMessaging.getToken({
          vapidKey:
            process.env.REACT_APP_VAPID_KEY ??
            'BFehuZLl8Rcd3yA8yuHTCAChbbNEsqqA3qPuhyvYfpctXl35zJr7YmSkLDpv5srN1sSV1DMaofmFZJJCoDBmiac'
          // 'BJ6kYXVzssKGx5nwKCbx7FyUDci2qMDsPFKdq_3BB_-xOYgMMEYuByiNnQ3LQ3kfNdlwTbLQ10lKcD0LQUl5X-I'
        })
        return result.token
      } else {
        const result = await FirebaseMessaging.requestPermissions()
        if (result.receive === 'granted') {
          const result = await FirebaseMessaging.getToken({
            vapidKey:
              process.env.REACT_APP_VAPID_KEY ??
              'BFehuZLl8Rcd3yA8yuHTCAChbbNEsqqA3qPuhyvYfpctXl35zJr7YmSkLDpv5srN1sSV1DMaofmFZJJCoDBmiac'
          })
          return result.token
        }
      }
    } else {
      return undefined
    }
  }

  const setTokenOnServer = async (
    subscriberId: string,
    token: string
  ): Promise<void> => {
    console.log('setTokenOnServer', { subscriberId, token })
    await sdk.subscribers.update({
      id: subscriberId,
      device_tokens: [token]
    })
  }

  const deleteToken = async (): Promise<void> => {
    await FirebaseMessaging.deleteToken()
  }

  return {
    checkPermissions,
    requestPermissions,
    getToken,
    deleteToken,
    setTokenOnServer
  }
}

// const getDeliveredNotifications = async () => {
//   const result = await FirebaseMessaging.getDeliveredNotifications()
//   return result.notifications
// }

// const removeDeliveredNotifications = async () => {
//   await FirebaseMessaging.removeDeliveredNotifications({
//     ids: ['798dfhliblqew89pzads']
//   })
// }

// const removeAllDeliveredNotifications = async () => {
//   await FirebaseMessaging.removeAllDeliveredNotifications()
// }

// const subscribeToTopic = async () => {
//   await FirebaseMessaging.subscribeToTopic({ topic: 'news' })
// }

// const unsubscribeFromTopic = async () => {
//   await FirebaseMessaging.unsubscribeFromTopic({ topic: 'news' })
// }

// const addTokenReceivedListener = async () => {
//   await FirebaseMessaging.addListener('tokenReceived', (event) => {
//     console.log('tokenReceived', { event })
//   })
// }

// const addNotificationReceivedListener = async () => {
//   await FirebaseMessaging.addListener('notificationReceived', (event) => {
//     console.log('notificationReceived', { event })
//   })
// }

// const addNotificationActionPerformedListener = async () => {
//   await FirebaseMessaging.addListener(
//     'notificationActionPerformed',
//     (event) => {
//       console.log('notificationActionPerformed', { event })
//     }
//   )
// }

// const removeAllListeners = async () => {
//   await FirebaseMessaging.removeAllListeners()
// }
