import ButtonStep from '#components/common/ButtonStep'
import Input, { type InputOnChange } from '#components/ui/Input'
import useToast from '#hooks/useToast'
import { type UserCategory } from '#tackpay-sdk'
import textUtil from '#utils/textUtil'
import { useIonRouter } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Text from '#components/ui/Text'
import InputPasswordTest from '#components/ui/InputPasswordTest'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import RegistrationModalTerms from './RegistrationModalTerms'
import { validatePassword } from '#utils/validatePassword'

interface Props {
  category: UserCategory
}

export default function RegistrationUserData({ category }: Props): JSX.Element {
  const SSN_COUNTRIES = ['United States']

  const { push } = useIonRouter()

  const { i18n } = useTranslation()

  const {
    userData,
    setUserData,
    addressData,
    stripeGatewayData,
    setStripeGatewayData,
    setCompanyData
  } = useRegistrationContext()

  const { showToast } = useToast('warning')

  const [showModalTerms, setShowModalTerms] = useState<boolean>(false)
  const [showCommercialCode, setShowCommercialCode] = useState<boolean>(false)

  const handleInputChange: InputOnChange = (event) => {
    const { name, value } = event.target

    if (name === 'email' && typeof value === 'string') {
      setUserData({ email: value })
    }

    if (name === 'password' && typeof value === 'string') {
      setUserData({ password: value })
    }

    if (name === 'ssn' && typeof value === 'string') {
      setStripeGatewayData({ ssn: value })
    }

    if (name === 'commercialCode' && typeof value === 'string') {
      setCompanyData({ commercial_code: value })
    }
  }

  const handlerPhoneChange = (number: string, country: any): void => {
    const correctPhone = `+${number}`

    setUserData({ phone: correctPhone })
  }

  const handlePrevious = (): void => {
    push(`/registrations/${category}/addresses`, 'back')
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setUserData({ lang: i18n.language as any })

    if (!textUtil.isEmail(userData?.email)) {
      showToast(undefined, 'registration.emailRequired')
      return
    }

    if (textUtil.isEmpty(userData?.password)) {
      showToast(undefined, 'registration.passwordRequired')
      return
    }
    if (textUtil.isEmpty(userData?.phone)) {
      showToast(undefined, 'error_phone')
      return
    }

    if (userData?.password == null || !validatePassword(userData.password)) {
      showToast(undefined, 'registration.passwordInvalid')
      return
    }

    setShowModalTerms(true)
  }

  return (
    <form onSubmit={handleSubmit} className='space-y-6'>
      <RegistrationModalTerms
        show={showModalTerms}
        setShow={setShowModalTerms}
      />

      {SSN_COUNTRIES.includes(addressData?.country_name ?? '') && (
        <div>
          <Input
            type='text'
            name='ssn'
            value={stripeGatewayData?.ssn}
            onIonInput={handleInputChange}
            labelOptions={{
              children: 'ssn',
              capitalize: false
            }}
            helperText='ssnHelperText'
            testId='ssn'
          />
        </div>
      )}
      <div>
        <Input
          type='email'
          name='email'
          autocomplete='on'
          value={userData?.email}
          onIonInput={handleInputChange}
          labelOptions={{
            children: 'email'
          }}
          testId='registrations-email'
        />
      </div>
      <div className='w-full'>
        <Text
          size='text-sm'
          fontWeight='font-medium'
          color='text-gray-900'
          className=' mb-1'
        >
          Phone
        </Text>
        <PhoneInput
          enableSearch
          autoFormat
          prefix='+'
          containerStyle={{ width: '100%' }}
          country='it'
          value={userData?.phone}
          onChange={(phone, data) => {
            handlerPhoneChange(phone, data)
          }}
        />
      </div>
      <div>
        <InputPasswordTest
          password={userData?.password}
          onPasswordChange={handleInputChange}
          testId='registrations-password'
          name='password'
        />
      </div>
      {category === 'business' && (
        <>
          <div className='mt-10 flex justify-center items-center'>
            <Text
              onClick={() => {
                setShowCommercialCode(true)
              }}
              className='cursor-pointer underline'
              size='text-sm'
            >
              commercialCode
            </Text>
          </div>
          {showCommercialCode && (
            <Input
              type='text'
              name='commercialCode'
              onIonInput={handleInputChange}
              labelOptions={{
                children: 'commercialCode',
                capitalize: false
              }}
            />
          )}
        </>
      )}
      <ButtonStep handleBack={handlePrevious} nextType='submit' />
    </form>
  )
}
