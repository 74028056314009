import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import formattedAmount from '#utils/formattedAmount'
import isDesktop from '#utils/isDesktop'
import { IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react'
import classNames from 'classnames'

interface CardSmallBalanceProps {
  /**
   * The additional class name
   */
  className?: string
  /**
   * The title
   */
  title?: string
  /**
   * The currency
   */
  currency?: string
  /**
   * The amount
   */
  amount?: number
  /**
   * If the card is loading
   */
  loading?: boolean
  /**
   * The error
   */
  error?: any
}
export default function CardSmallBalance(
  props: CardSmallBalanceProps
): JSX.Element {
  console.log('props', props)
  const { className, currency, amount, title, loading = true, error } = props

  const baseClass = 'rounded-xl diagonal-split-background ion-padding'

  const desktopClass = isDesktop() ? 'w-1/4' : ''

  const cardClass = classNames(baseClass, className, desktopClass)
  return (
    <Card className={cardClass}>
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonCol size='6' className='ion-no-padding space-y-5'>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <Text
                  color='gray-900'
                  fontWeight='font-bold'
                  data-testid={title}
                >
                  {loading && <Skeleton className='w-8' />}
                  {error != null && <ErrorBox error={error} />}
                  {!loading && error == null && `${title ?? 'balance'}`}
                </Text>
              </IonRow>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <IonCardTitle
                  className='text-black font-bold text-3xl ion-no-margin ion-no-padding'
                  data-testid={`${String(title)}-amount`}
                >
                  {loading && <Skeleton className='w-8' />}
                  {error != null && <ErrorBox error={error} />}
                  {!loading &&
                    error == null &&
                    formattedAmount(amount, currency)}
                </IonCardTitle>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='4'>
          <IonRow className='ion-justify-content-center'>
            <IonImg src='/assets/img/line-card.svg' />
          </IonRow>
        </IonCol>
      </IonRow>
    </Card>
  )
}
