import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Collaborator } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import { useState } from 'react'

interface Props {
  setShowCollaboratorModal: (showModal: boolean) => void
  collaborator: Collaborator
}
export default function DeleteCollaboratorButton({
  collaborator,
  setShowCollaboratorModal
}: Props): JSX.Element {
  const { deleteCollaborator } = useCollaboratorContext()

  const [showModal, setShowModal] = useState(false)

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('collaborator.deleting')

  const handleDelete = (): void => {
    if (deleteCollaborator != null) {
      setShowModal(false)

      setShowCollaboratorModal(false)

      startLoading()

      deleteCollaborator.mutate(collaborator.id, {
        onError: (error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        },
        onSuccess: () => {
          stopLoading()
          showSuccess(undefined, 'collaborator.deleted')
        }
      })
    }
  }

  return (
    <>
      <Button
        iconOptions={{
          src: trashOutline,
          slot: 'start',
          className: 'ion-justify-content-center ion-align-items-center'
        }}
        color='danger'
        fill='clear'
        size='small'
        className='ion-no-margin -ml-3'
        onClick={() => {
          setShowModal(true)
        }}
      >
        delete
      </Button>
      <Modal
        isOpen={showModal}
        setShowModal={setShowModal}
        initialBreakpoint={0.65}
        breakpoints={[0.5, 0.75, 1]}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Delete</IonTitle>
            <IonButtons slot='start'>
              <IonButton
                onClick={() => {
                  setShowModal(false)
                }}
                color='gray-500'
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding' scrollY={false} color='white'>
          <div className='md:max-w-md md:mx-auto'>
            <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
              <Text color='dark' className='font-semibold'>
                collaborator.delete.title
              </Text>
            </IonCol>
            <IonRow className='ion-no-padding ion-justify-content-center ion-text-center space-x-1 mt-10'>
              <Text>collaborator.delete.description</Text>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center space-x-5 mt-20'>
              <Button
                fill='clear'
                color='medium'
                onClick={() => {
                  setShowModal(false)
                }}
              >
                cancel
              </Button>
              <Button
                onClick={() => {
                  handleDelete()
                }}
                color='danger'
              >
                confirm
              </Button>
            </IonRow>
          </div>
        </IonContent>
      </Modal>
    </>
  )
}
