import CollaboratorRemoved from '#components/common/CollaboratorRemoved'
import ErrorBox from '#components/errors/ErrorBox'
import WalletBusinessSection from '#components/pages/wallets/WalletBusinessSection'
import ModalLoading from '#components/ui/ModalLoading'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { type UserCategory } from '#tackpay-sdk'

interface Props {
  category: UserCategory
}
export default function WalletCollaborator({ category }: Props): JSX.Element {
  const { collaborator, isLoading, error } = useCollaboratorContext()

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  if (collaborator == null && !isLoading) return <CollaboratorRemoved />

  return (
    <WalletBusinessSection
      category={category}
      jars={collaborator?.jars}
      isLoading={isLoading}
      error={error}
    />
  )
}
