import {
  IonButtons,
  type IonContent,
  IonHeader,
  IonRow,
  IonToolbar
} from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps } from 'react'
import TackPayIcon from '../../ui/TackpayIcon'
import BackButton from '../../ui/BackButton'
import LanguageSwitcher from '#components/common/LanguageSwitcher'
import LanguageSwitcherUsa from '#components/common/LanguageSwitcherUsa'

interface AuthHeaderProps {
  /**
   * The options for the IonHeader component.
   */
  headerProps?: ComponentProps<typeof IonHeader>
  /**
   * The options for the IonToolbar component.
   */
  toolbarProps?: ComponentProps<typeof IonToolbar>
  contentProps?: Omit<ComponentProps<typeof IonContent>, 'children'>
  /**
   * Show logo of TackPay
   */
  showLogo?: boolean
  /**
   * Show language switcher
   */
  showLanguageSwitcher?: boolean
  /**
   * Show back button
   */
  showBackButton?: boolean
  /**
   * Back button options
   */
  backButtonProps?: ComponentProps<typeof BackButton>
  /**
   * Logo options
   */
  logoProps?: ComponentProps<typeof TackPayIcon>

  isUsa?: boolean
}

export default function AuthHeader(props: AuthHeaderProps): JSX.Element {
  const {
    headerProps,
    toolbarProps,
    showLogo,
    showLanguageSwitcher,
    showBackButton,
    backButtonProps,
    logoProps,
    isUsa = false
  } = props

  const baseHeaderClass = 'ion-no-border bg-gray-50  max-z-index'

  const headerClass = classNames(headerProps?.className, baseHeaderClass)

  const baseToolbarClass = 'ion-no-padding max-w-6xl mx-auto'

  const toolbarClass = classNames(toolbarProps?.className, baseToolbarClass)
  return (
    <IonHeader {...headerProps} className={headerClass}>
      <IonToolbar color='black' {...toolbarProps} className={toolbarClass}>
        <IonButtons slot='start'>
          <IonRow className='flex ion-justify-content-between ion-align-items-center ion-padding  bg-gray-50 md:px-6'>
            {showBackButton === true && <BackButton {...backButtonProps} />}
            {showLogo === true && <TackPayIcon {...logoProps} />}
          </IonRow>
        </IonButtons>
        <IonButtons slot='end'>
          <IonRow className='ion-no-padding  bg-gray-50'>
            {showLanguageSwitcher === true && isUsa ? (
              <LanguageSwitcherUsa />
            ) : (
              <LanguageSwitcher />
            )}
          </IonRow>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}
