import {
  ApiResource,
  type Resource,
  type ResourceId,
  type ResourceRel
} from 'sdk/resource'

type StatisticViewType = 'statistic_views'
type StatisticViewRel = ResourceRel & { type: StatisticViewType }

interface StatisticView extends Resource {
  readonly type: StatisticViewType
  /**
   * Total views
   */
  total: number
  /**
   * View with tip
   */
  view_tip: number
  /**
   * View without tip
   */
  view_no_tip: number
}

class StatisticViews extends ApiResource<StatisticView> {
  static TYPE: StatisticViewType = 'statistic_views'

  isStaticFeedback(resource: any): resource is StatisticView {
    return resource.type != null && resource.type === StatisticViews.TYPE
  }

  relationship(id: string | ResourceId | null): StatisticViewRel {
    return id === null || typeof id === 'string'
      ? { id, type: StatisticViews.TYPE }
      : { id: id.id, type: StatisticViews.TYPE }
  }

  type(): StatisticViewType {
    return StatisticViews.TYPE
  }
}

export type { StatisticView, StatisticViewRel }
export default StatisticViews
