import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import AppLayout from '#components/layouts/app/AppLayout'
import EmptyMember from '#components/pages/members/EmptyMember'
import OtherMemberCardInfo from '#components/pages/members/info/OtherMemberCardInfo'
import Title from '#components/ui/Title'
import { type OtherMemberRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type ListResponse, type Member } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import {
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

export default function OtherMemberList(): JSX.Element {
  const { category, memberId, jarId } = useParams<OtherMemberRoutesParams>()

  const fetchMembers = async ({
    pageParam = 1
  }): Promise<ListResponse<Member>> => {
    return await sdk.jars.members(jarId, {
      include: ['user.person'],
      pageNumber: pageParam,
      filters: {
        ne: {
          id: memberId
        }
      },
      sort: {
        created_at: 'desc'
      }
    })
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['other-members'],
    queryFn: fetchMembers,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (!hasMore) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/members/${memberId}`
        }
      }}
    >
      <Title>memberList.title</Title>
      <IonCol>
        {status === 'pending' ? (
          <CardSkeleton hasAvatar />
        ) : status === 'error' ? (
          <ErrorBox error={error} />
        ) : data.pages != null && data.pages[0].length === 0 && !isFetching ? (
          <EmptyMember />
        ) : (
          <div className='space-y-6 ion-justify-content-center w-full'>
            {data.pages.map((page, i) => (
              <div
                key={i}
                className='space-y-6 ion-justify-content-center w-full'
              >
                {page.map((member) => {
                  if (member != null) {
                    return (
                      <OtherMemberCardInfo key={member.id} member={member} />
                    )
                  }
                  return null
                })}
              </div>
            ))}
            <IonInfiniteScroll
              disabled={hasMore == null || !hasMore}
              onIonInfinite={loadNext}
            >
              <IonInfiniteScrollContent
                loadingSpinner='circles'
                loadingText='Loading...'
                color='primary'
              />
            </IonInfiniteScroll>
          </div>
        )}
      </IonCol>
    </AppLayout>
  )
}
