import { useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar
} from '@ionic/react'
import type { CheckboxCustomEvent } from '@ionic/react'

export interface Item {
  text: string
  value: string
}

interface TypeaheadProps {
  items: Item[]
  selectedItems: string
  title?: string
  onSelectionCancel?: () => void
  onSelectionChange?: (items: string) => void
}

function AppTypeahead(props: TypeaheadProps): JSX.Element {
  const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items])
  const [workingSelectedValues, setWorkingSelectedValues] = useState<string>(
    props.selectedItems
  )

  const isChecked = (value: string): boolean => {
    return workingSelectedValues === value
  }

  const cancelChanges = (): void => {
    const { onSelectionCancel } = props
    if (onSelectionCancel !== undefined) {
      onSelectionCancel()
    }
  }

  const confirmChanges = (): void => {
    const { onSelectionChange } = props
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues)
    }
  }

  const searchbarInput = (ev: any): void => {
    filterList(ev.target.value)
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined): void => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items])
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase()
      setFilteredItems(
        props.items.filter((item) => {
          return item.text.toLowerCase().includes(normalizedQuery)
        })
      )
    }
  }

  const checkboxChange = (ev: CheckboxCustomEvent): void => {
    const { checked, value } = ev.detail
    if (checked) {
      setWorkingSelectedValues(value)
    } else {
      setWorkingSelectedValues('')
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color='light'>
          <IonButtons slot='start'>
            <IonButton onClick={cancelChanges}>Cancel</IonButton>
          </IonButtons>
          <IonTitle data-testid='select_country' className='ion-text-center'>
            {props.title}
          </IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={confirmChanges} color='primary'>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color='light' className='pt-5'>
          <IonSearchbar
            onIonInput={searchbarInput}
            id='search-country'
            color='search-input'
          />
        </IonToolbar>
      </IonHeader>

      <IonContent color='light' class='ion-padding'>
        <IonList id='modal-list' inset>
          {filteredItems.map((item) => (
            <IonItem key={item.value}>
              <IonCheckbox
                value={item.value}
                checked={isChecked(item.value)}
                onIonChange={checkboxChange}
                data-testid={item.value}
              >
                {item.text}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  )
}
export default AppTypeahead
