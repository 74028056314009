import ButtonStep from '#components/common/ButtonStep'
import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useCompanyContext } from '#contexts/CompanyContext'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import useToast from '#hooks/useToast'
import { useIonRouter } from '@ionic/react'
import BankJarCard from './BankJarCard'
import { type BankAccount, type UserCategory } from '#tackpay-sdk'
import AddTeamNewIbanForm from './AddTeamNewIbanForm'

interface Props {
  category: UserCategory
}
export default function AddBankByCompany({ category }: Props): JSX.Element {
  const { isLoading, company, error } = useCompanyContext()

  const { bankAccount, setBankBankAccount } = useCreateTeamStore()

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  if (isLoading)
    return (
      <div>
        <Skeleton className='w-full h-9' />
        <Skeleton className='w-full h-9' />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (company == null) return <div>Company not found</div>

  const handleNextStep = (): void => {
    if (bankAccount == null) {
      showToast(undefined, 'teamPage.selectIban')
    } else {
      push(`/dashboard/${category}/jars/add/reviews`)
    }
  }

  const handleSetBankAccount = (bankAccount: BankAccount): void => {
    setBankBankAccount(bankAccount)
  }

  return (
    <div className='mt-10'>
      {company?.bank_accounts != null ? (
        <div className='space-y-6'>
          {company.bank_accounts?.map((b) => (
            <BankJarCard
              key={b.id}
              banckAccount={b}
              handleSelected={handleSetBankAccount}
              bankAccountStore={bankAccount}
            />
          ))}
          <div className='pt-10'>
            <ButtonStep handleNext={handleNextStep} />
          </div>
        </div>
      ) : (
        <AddTeamNewIbanForm category={category} />
      )}
    </div>
  )
}
