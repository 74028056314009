import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import formattedAmount from '#utils/formattedAmount'
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react'

interface Props {
  title?: string
  balance?: {
    amount_cents: number
    amount_float: number
    amount_formatted: string
    currency: string
  }
  totalFeedback?: number
  totalComment?: number
  totalTip?: number
  companyId?: string
}
export default function MemberCardStatistic({
  title = 'Statistic',
  balance,
  totalComment,
  totalFeedback,
  totalTip,
  companyId
}: Props): JSX.Element {
  const hiddenTotalCompany = ['1bf77f69-8089-4495-8b28-410a00cf82e0']

  return (
    <Card>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className='ion-no-padding space-y-3'>
          {!hiddenTotalCompany.includes(companyId ?? '') && (
            <IonRow>
              <IonCol>
                <Text className='text-gray-600'>members.balance</Text>
              </IonCol>
              <IonCol className='text-right'>
                <Text color='dark' translate='no' className='font-semibold'>
                  {formattedAmount(balance?.amount_cents, balance?.currency)}
                </Text>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <Text className='text-gray-600'>members.totalFeedback</Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text color='dark' translate='no' className='font-semibold'>
                {totalFeedback}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Text className='text-gray-600'>members.totalComments</Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text color='dark' translate='no' className='font-semibold'>
                {totalComment}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Text className='text-gray-600'>members.totalTips</Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text color='dark' translate='no' className='font-semibold'>
                {totalTip}
              </Text>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </Card>
  )
}
