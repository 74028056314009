import AppLayout from '#components/layouts/app/AppLayout'
import AddBankAccountForm from '#components/pages/banks/AddBankAccountForm'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankContainer from '#contexts/BankContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function TeamAddBankAccount(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/withdrawals/banks`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
    >
      <Title hasSubtitle>bank.add.title</Title>
      <SubTitle>bank.add.subtitle</SubTitle>
      <BankContainer fetch jarId={jarId}>
        <AddBankAccountForm jarId={jarId} category={category} />
      </BankContainer>
    </AppLayout>
  )
}
