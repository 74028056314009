import { IonButton, IonContent, IonPopover } from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps, useRef } from 'react'

interface DropdownProps {
  /**
   * The toggle options.
   */
  toggleOptions?: ComponentProps<typeof IonButton>
  /**
   * The popover options.
   */
  popoverOptions?: ComponentProps<typeof IonPopover>
  /**
   * Content options.
   */
  contentOptions?: ComponentProps<typeof IonContent>
  /**
   * The children.
   */
  children: React.ReactNode
  /**
   * The popover is open.
   */
  isOpen: boolean
  /**
   * Set the popover is open.
   */
  setIsOpen: (isOpen: boolean) => void
}

export default function Dropdown(props: DropdownProps): JSX.Element {
  const {
    children,
    contentOptions,
    popoverOptions,
    toggleOptions,
    isOpen,
    setIsOpen
  } = props
  const popover = useRef<HTMLIonPopoverElement>(null)

  const openPopover = (e: any): void => {
    if (popover.current == null) return
    popover.current.event = e
    setIsOpen(true)
  }

  const baseContentPadding = 'ion-padding'
  const classNameContent = classNames(
    contentOptions?.className,
    baseContentPadding
  )

  return (
    <>
      <IonButton {...toggleOptions} onClick={openPopover}>
        {toggleOptions?.children}
      </IonButton>
      <IonPopover
        {...popoverOptions}
        ref={popover}
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false)
        }}
        arrow={false}
      >
        <IonContent {...contentOptions} className={classNameContent}>
          {children}
        </IonContent>
      </IonPopover>
    </>
  )
}
