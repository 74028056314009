import Button from '#components/ui/Button'
import Input, { type InputOnChange } from '#components/ui/Input'
import { getSymbolCurrency } from '#constants/country'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Subscription } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { IonCol, IonIcon, IonRow, useIonRouter } from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlanModalTeam from './PlanModalTeam'
import { useCompanyContext } from '#contexts/CompanyContext'
import Chip from '#components/ui/Chip'
import { isPlatform } from '@ionic/core'
import { Browser } from '@capacitor/browser'
import { useQueryClient } from '@tanstack/react-query'

const PRICE = [
  {
    amount: 9.99,
    currency: 'eur'
  },
  {
    amount: 16.99,
    currency: 'aud'
  },
  {
    amount: 19.98,
    currency: 'bgn'
  },
  {
    amount: 14.99,
    currency: 'cad'
  },
  {
    amount: 9.99,
    currency: 'chf'
  },
  {
    amount: 246.99,
    currency: 'czk'
  },
  {
    amount: 74.99,
    currency: 'dkk'
  },
  {
    amount: 8.99,
    currency: 'gbp'
  },
  {
    amount: 3815.99,
    currency: 'huf'
  },
  {
    amount: 17.99,
    currency: 'nzd'
  },
  {
    amount: 94.99,
    currency: 'mxn'
  },
  {
    amount: 119.99,
    currency: 'nok'
  },
  {
    amount: 43.99,
    currency: 'pln'
  },
  {
    amount: 49.99,
    currency: 'ron'
  },
  {
    amount: 111.99,
    currency: 'sek'
  },
  {
    amount: 10.99,
    currency: 'usd'
  }
]

interface PlanCardProps {
  currency: string
  subscription?: Subscription | null
  companyId: string
  isActive: boolean
}
export default function PlanPremiumCard({
  currency,
  subscription,
  companyId,
  isActive
}: PlanCardProps): JSX.Element {
  const queryClient = useQueryClient()

  const { routeInfo } = useIonRouter()

  const { company, refetch } = useCompanyContext()

  const { t } = useTranslation()

  const basePrice =
    PRICE.find((p) => p.currency === currency.toLowerCase())?.amount ?? 9.99

  const priceId =
    process.env.REACT_APP_PREMIUM_PRICE ?? 'price_1OL5bECDf41ktEVoQjM7CFbQ'

  const features = [
    {
      title: 'plan_2_feature_1',
      has: true
    },
    {
      title: 'plan_2_feature_2',
      has: true
    },
    {
      title: 'plan_2_feature_3',
      has: true
    },
    {
      title: 'plan_2_feature_4',
      has: true
    },
    {
      title: 'plan_2_feature_5',
      has: true
    }
  ]

  const [showModal, setShowModal] = useState<boolean>(false)

  const { startLoading, stopLoading } = useLoading(
    'subscription.update.loading'
  )

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const startQty =
    subscription?.subscription_items?.[0]?.quantity === 0
      ? 1
      : subscription?.subscription_items?.[0]?.quantity ?? 1

  const [quantity, setQuantity] = useState<number>(startQty)

  const [newQuantity, setNewQuantity] = useState<number>(
    isActive ? quantity : 1
  )

  const [price, setPrice] = useState<number>(basePrice * quantity)

  useEffect(() => {
    const subQty = subscription?.subscription_items?.[0]?.quantity
    if (subQty != null && subQty !== quantity) {
      setQuantity(subQty)
    }
  }, [subscription?.subscription_items?.[0]?.quantity])

  const handleTeamCount: InputOnChange = (e) => {
    const value = e.target.value
    if (value != null) {
      const intValue = parseInt(String(value))
      if (intValue > 0) {
        setNewQuantity(intValue)
      }
    }
  }

  const handleRefresh = (): void => {
    queryClient
      .refetchQueries({ type: 'active' })
      .then(() => {
        console.log('refetchQueries success')
      })
      .catch((error) => {
        console.log('invalidateQueries error', error)
      })
  }

  const handleTeamCountButton = (action: 'add' | 'remove'): void => {
    if (action === 'add') {
      setNewQuantity((prevValue) => Math.min(prevValue + 1, 20)) // Limit to maximum 10
      setPrice((prevValue) => Math.min(prevValue + basePrice, 20 * basePrice)) // Update price with limit
    } else {
      setNewQuantity((prevValue) => Math.max(prevValue - 1, 1)) // Limit to minimum 2
      setPrice((prevValue) => Math.max(prevValue - basePrice, basePrice)) // Update price with limit
    }
  }

  const getDisabledTeams = (): number => {
    const disabledTeams = company?.jars?.filter((t) => !t.active) ?? []
    return disabledTeams.length
  }

  const getActiveTeams = (): number => {
    const activeTeams = company?.jars?.filter((t) => t.active) ?? []
    return activeTeams.length
  }

  const getTotalTeams = (): number => {
    const totalTeams = company?.jars?.length ?? 1
    return totalTeams
  }

  const getOperationType = (): 'add' | 'remove' => {
    if (newQuantity > quantity) {
      return 'add'
    } else {
      return 'remove'
    }
  }

  const handleAction = (): void => {
    const disabledTeams = getDisabledTeams()
    const activeTeams = getActiveTeams()
    const totalTeams = getTotalTeams()
    const operation = getOperationType()
    // Se non è il piano corrente
    if (!isActive) {
      // Se sto aggiungendo team al piano ed esistono team disabilitati
      // mostro il modal per selezionare i team da abilitare
      if (operation === 'add' && disabledTeams > 0) {
        setShowModal(true)
      } else if (operation === 'remove' && totalTeams > newQuantity) {
        setShowModal(true)
      } else {
        const returnUrl =
          (process.env.REACT_APP_FRONTEND_URL ?? 'https://app.tackpay.net') +
          routeInfo.pathname

        startLoading()

        sdk.subscriptions_links
          .create({
            price_reference: priceId,
            success_url: returnUrl,
            cancel_url: returnUrl,
            quantity: newQuantity,
            customer: {
              id: company?.customer?.id ?? '',
              type: 'customers'
            }
          })
          .then((res) => {
            stopLoading()
            const url = res.url
            if (isPlatform('hybrid')) {
              Browser.open({ url })
                .then(() => {
                  console.log('Opened browser!')
                })
                .catch((error) => {
                  console.log('cannot open the browser', error)
                })

              void Browser.addListener('browserFinished', () => {
                handleRefresh()
              })
            } else {
              window.location.href = url
            }
          })
          .catch((error) => {
            stopLoading()
            console.log(error)
            showToast(undefined, getErrorMessage(error))
          })
      }
    } else {
      if (operation === 'add' && disabledTeams > 0) {
        setShowModal(true)
      } else if (operation === 'remove' && activeTeams > newQuantity) {
        setShowModal(true)
      } else {
        startLoading()
        sdk.subscription_items
          .update({
            id: subscription?.subscription_items?.[0]?.id ?? '',
            quantity: newQuantity
          })
          .then(() => {
            if (refetch != null) {
              void refetch({
                queryKey: [
                  'company',
                  {
                    include: [
                      'customer.subscription.subscription_items',
                      'jars'
                    ]
                  }
                ]
              })
            }

            stopLoading()
            successToast(undefined, 'subscription.update.success')
          })
          .catch((error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          })
      }
    }
  }

  const border = isActive ? 'border-green-400 ' : 'border-gray-100'

  return (
    <>
      <PlanModalTeam
        showModal={showModal}
        setShowModal={setShowModal}
        type='selectMany'
        oldQuantity={quantity}
        newQuantity={newQuantity}
        subscriptionItemId={subscription?.subscription_items?.[0]?.id ?? ''}
        jars={company?.jars ?? []}
        customerId={company?.customer?.id ?? ''}
      />
      <div
        className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border  shadow  xl:p-8   ${border}`}
      >
        <IonRow className='w-full ion-align-items-center ion-justify-content-center space-x-5'>
          <div>
            <h3 className='mb-4 text-2xl font-semibold'>{t('plan_2_title')}</h3>
          </div>
          {isActive && (
            <Chip color='white' className='bg-green-300 mb-4'>
              currentPlan
            </Chip>
          )}
        </IonRow>
        <p className='font-light text-gray-500 sm:text-lg '>
          {t('plan_2_subtitle')}
        </p>
        <div className='flex justify-center items-baseline my-8'>
          <span className='mr-2 text-5xl font-extrabold'>
            {getSymbolCurrency(currency)}
            {price.toFixed(2)}
          </span>

          <span className='text-gray-500 '>{`/${t('month')}`}</span>
        </div>

        {/* <Text className='ion-text-center' size='text-base' color='gray-500'>
          selectTeamCount
        </Text> */}
        <IonRow className='ion-align-items-center ion-justify-content-center ion-no-padding ion-no-margin mb-8'>
          <IonCol size='3'>
            <IonIcon
              icon={remove}
              className='cursor-pointer pt-1'
              size='large'
              onClick={() => {
                handleTeamCountButton('remove')
              }}
            />
          </IonCol>
          <IonCol size='6'>
            <Input
              id='newQuantity'
              pattern='[0-9]*'
              value={newQuantity}
              className='mx-auto newQuantity'
              onIonInput={handleTeamCount}
            />
          </IonCol>
          <IonCol size='3'>
            <IonIcon
              icon={add}
              color='primary'
              className='cursor-pointer pt-1'
              size='large'
              onClick={() => {
                handleTeamCountButton('add')
              }}
            />
          </IonCol>
        </IonRow>

        <ul role='list' className='mb-8 space-y-4 text-left'>
          {features.map((feature) => (
            <li key={feature.title} className='flex items-center space-x-3'>
              {feature.has ? (
                <svg
                  className='flex-shrink-0 w-5 h-5 text-green-500 '
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  className='flex-shrink-0 w-5 h-5 text-gray-500 '
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
              <span>{t(feature.title)}</span>
            </li>
          ))}
        </ul>
        <Button
          onClick={handleAction}
          disabled={quantity === newQuantity && isActive}
        >
          plan_2_button
        </Button>
      </div>
    </>
  )
}
