import AppLayout from '#components/layouts/app/AppLayout'
import PayoutBalanceSection from '#components/pages/payouts/balance/PayoutBalanceSection'
import PayoutHistorySection from '#components/pages/payouts/hyistory/PayoutHistorySection'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type UserCategory } from '#tackpay-sdk'
import { useParams } from 'react-router'

interface Props {
  jarId: string
  category: UserCategory
}

function HandlePayoutTeam({ jarId, category }: Props): JSX.Element {
  const { jar, isLoading, error } = useTeamContext()

  return (
    <PayoutBalanceSection
      addBankHref={`/dashboard/${category}/jars/${jarId}/settings/withdrawals/banks/add`}
      balance={jar?.balance}
      loading={isLoading}
      error={error}
      jarId={jarId}
      bankAccount={jar?.bank_account}
      category={category}
    />
  )
}

export default function TeamPayout(): JSX.Element {
  const { jarId, category } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/withdrawals`
        }
      }}
      teamProps={{
        fetch: true,
        params: {
          include: ['balance', 'bank_account']
        }
      }}
    >
      <Title>payout.title</Title>
      <HandlePayoutTeam category={category} jarId={jarId} />
      <PayoutHistorySection jarId={jarId} />
    </AppLayout>
  )
}
