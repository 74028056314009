import { IonCol, IonRow } from '@ionic/react'
import Avatar from './Avatar'
import Text from './Text'
import Underlined from './UnderlinedName'

interface Props {
  name?: string | undefined
  bio?: string | undefined
  avatar?: string | undefined
  showLine?: boolean
}

export default function SingleAvatarTipped({
  name = 'Aldo Brunet',
  bio = '👋 Hi, we are the jar of La Taverna restaurant. Thank you for leaving a tip with TackPay 🚀',
  avatar = '/assets/img/defaultTipped.svg',
  showLine = false
}: Props): JSX.Element {
  return (
    <IonCol size='12' className='ion-no-padding space-y-4 md:space-y-10'>
      <IonRow className='ion-justify-content-center ion-align-items-center pt-1 md:pt-6 mt-10 w-full'>
        <IonCol className='space-y-3' size='12'>
          <IonRow className='ion-justify-content-center'>
            <Avatar
              avatarOptions={{
                className: 'border-green-400 border-2 h-24 w-24'
              }}
              imgOptions={{
                src: avatar,
                alt: 'Avatar tipped'
              }}
            />
          </IonRow>
          <Underlined name={name} />
        </IonCol>
      </IonRow>

      <IonRow className='ion-justify-content-center ion-no-padding'>
        <div className='text-center'>
          <Text className='text-gray-900' fontWeight='font-base'>
            {bio}
          </Text>
        </div>
      </IonRow>
      {showLine && <IonRow className='border-t border-stone-200 w-full h-5' />}
    </IonCol>
  )
}
