import { IonItem, IonList, IonRow } from '@ionic/react'
import CoverIcon from './CoverIcon'
import Text from './Text'
import { type ComponentProps } from 'react'

interface ItemProps {
  label: string
  icon: string
  href?: string
}

interface Props {
  items: ItemProps[]
  listOptions?: ComponentProps<typeof IonList>
  itemsOptions?: ComponentProps<typeof IonItem>
}

export function ListLinkTerms({
  items,
  listOptions,
  itemsOptions
}: Props): JSX.Element {
  return (
    <IonList
      {...listOptions}
      lines='full'
      className='space-y-4 mt-8 ion-no-padding bg-transparent'
    >
      {items.map((item) => (
        <IonItem
          className='ion-no-padding w-full'
          detail
          href={item.href}
          key={item.label}
          lines='none'
          color='transparent'
          {...itemsOptions}
        >
          <IonRow className='py-3 ion-align-items-center space-x-4'>
            <CoverIcon iconOptions={{ icon: item.icon }} size='w-9 h-9' />
            <Text className='text-gray-800 text-[1rem] font-medium'>
              {item.label}
            </Text>
          </IonRow>
        </IonItem>
      ))}
    </IonList>
  )
}
