import ErrorBox from '#components/errors/ErrorBox'
import TeamBusinessList from '#components/pages/teams/TeamBusinessList'
import TeamUpdatePlanText from '#components/pages/teams/TeamUpdatePlanText'
import ModalAddJar from '#components/pages/teams/add/add-jar/ModalAddJar'
import Button from '#components/ui/Button'
import Info from '#components/ui/Info'
import Skeleton from '#components/ui/Skeleton'
import SubTitle from '#components/ui/SubTitle'
import Text from '#components/ui/Text'
import Title from '#components/ui/Title'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useCreateTeamContext } from '#contexts/CreateTeamContext'
import { type UserCategory } from '#tackpay-sdk'
import isCollaborator from '#utils/isCollaborator'
import sdk from '#utils/sdk'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'

interface Props {
  category: UserCategory
}
export default function TeamBusiness({ category }: Props): JSX.Element {
  const { collaborator, isLoading, error } = useCollaboratorContext()

  const { company, isLoading: isCompanyLoading } = useCompanyContext()

  const { clearCreateTeamStore } = useCreateTeamContext()

  const { clearCreateTeamStore: clearTeamContext } = useCreateTeamContext()

  const [showModal, setShowModal] = useState(false)

  const { push } = useIonRouter()

  useEffect(() => {
    if (clearCreateTeamStore != null) {
      clearCreateTeamStore()
    }
    if (clearTeamContext != null) {
      clearTeamContext()
    }
  }, [])

  const showAddButton = (): boolean => {
    if (isCollaborator(category)) {
      if (collaborator?.role === 'admin') {
        return true
      }
    } else {
      return true
    }
    return false
  }

  const handleAdd = (): void => {
    const isPremium =
      company?.customer?.subscription?.subscription_items?.[0]?.plan_type ===
      'premium'
    const subscriptionItems =
      company?.customer?.subscription?.subscription_items?.[0].quantity ?? 1

    sdk.jars
      .list()
      .then((res) => {
        const remainingJars =
          subscriptionItems - res.length + (isPremium ? 1 : 0)

        if (remainingJars > 0) {
          push(`/dashboard/${category}/jars/add`)
        } else {
          setShowModal(true)
        }
      })

      .catch((error) => {
        console.log('error to fetch jars', error)
      })

    // // (company?.customer?.subscription?.subscription_items?.[0].quantity ??
    // //   1) <= 1
    // if (isFree && remainingJars > 0) {
    //   setShowModal(true)
    //   sdk.jars
    //     .list()
    //     .then((res) => {
    //       if (res.length < 1) {
    //         push(`/dashboard/${category}/jars/add`)
    //       } else {
    //         setShowModal(true)
    //       }
    //     })

    //     .catch((error) => {
    //       console.log('error to fetch jars', error)
    //     })
    // } else if (isPremium && remainingJars > 0) {
    //   setShowModal(true)
    //   sdk.jars
    //     .list()
    //     .then((res) => {
    //       if (res.length < 1) {
    //         push(`/dashboard/${category}/jars/add`)
    //       } else {
    //         setShowModal(true)
    //       }
    //     })

    //     .catch((error) => {
    //       console.log('error to fetch jars', error)
    //     })
    // } else {
    //   push(`/dashboard/${category}/jars/add`)
    // }
  }

  return (
    <>
      <ModalAddJar
        category={category}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(!showModal)
        }}
      />

      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Title>teamPage.title</Title>
        {isCollaborator(category) && isLoading && <Skeleton />}
        {isCollaborator(category) && error != null && (
          <ErrorBox error={error} />
        )}
        {showAddButton() && (
          <Button
            iconOptions={{ icon: addOutline }}
            routerDirection='root'
            onClick={handleAdd}
            size='small'
          >
            add_jar
          </Button>
        )}
      </IonRow>
      <IonRow className='ion-items-align-center'>
        <SubTitle>teamPage.subtitle</SubTitle>
        <div className='mt-2 ml-1 md:mt-2 md:ml-2'>
          <Info title='i' text='jar_info' />
        </div>
      </IonRow>
      <TeamBusinessList category={category} />
      {company?.customer?.subscription?.subscription_items !== null &&
        !isCompanyLoading && (
          <TeamUpdatePlanText
            isFree={
              (company?.customer?.subscription?.subscription_items?.[0]
                ?.quantity ?? 1) === 1
            }
            category={category}
          />
        )}
      <IonRow className='ion-text-center my-10'>
        <IonCol>
          <Text size='text-sm'>change_jar_info</Text>
          <Text size='text-sm'>change_jar_info_2</Text>
        </IonCol>
      </IonRow>
    </>
  )
}
