import { initializeApp, type FirebaseOptions } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig: FirebaseOptions = {
  apiKey:
    process.env.REACT_APP_FB_API_KEY ??
    'AIzaSyAT_-H-LZ_7LglQxzkCJBd8Ou8wnQWrIFg',
  authDomain:
    process.env.REACT_APP_FB_AUTH_DOMAIN ?? 'tackpay-68906.firebaseapp.com',
  projectId: process.env.REACT_APP_FB_PROJECT_ID ?? 'tackpay-68906',
  storageBucket:
    process.env.REACT_APP_FB_STORAGE_BUCKET ?? 'tackpay-68906.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FB_MESSAGING_SENDER_ID ?? '241323138094',
  appId:
    process.env.REACT_APP_FB_APP_ID ??
    '1:241323138094:web:dacdb39141980485c293bf',
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID ?? 'G-4WXM4Y4C5Y'
}

const app = initializeApp(firebaseConfig)

const firebaseAuth = getAuth(app)
const storage = getStorage(app)

export const getFirebaseAuth = (): typeof firebaseAuth => firebaseAuth
export const getFirebaseStorage = (): typeof storage => storage

export default app
