import AddBankCountry from '#components/pages/banks/add/AddBankCountry'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import AddBankCurrency from '#components/pages/banks/add/AddBankCurrency'
import { useEffect, useState } from 'react'
import { COUNTRIES, ROUTING_NUMBER_COUNTRY } from '#constants/country'
import Input, { type InputOnChange } from '#components/ui/Input'
import ButtonStep from '#components/common/ButtonStep'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import { useIonRouter } from '@ionic/react'
import { type UserCategory } from '#tackpay-sdk'
// import { useUserContext } from '#contexts/UserContext'

interface Props {
  category: UserCategory
}
export default function AddTeamNewIbanForm({ category }: Props): JSX.Element {
  const { bankCreate, setBankCreate, setIsSameIban } = useCreateTeamStore()

  // const { user } = useUserContext()

  const [currencyName, setCurrencyName] = useState<string>('')

  const [countryName, setCountryName] = useState<string>('')

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  useEffect(() => {
    if (bankCreate?.currency != null && textUtil.isEmpty(currencyName)) {
      setCurrencyName(
        COUNTRIES.find(
          (c) => c.currency_code === bankCreate?.currency?.toUpperCase()
        )?.currency_name ?? ''
      )
    }

    if (bankCreate?.country_code != null && textUtil.isEmpty(countryName)) {
      setCountryName(
        COUNTRIES.find(
          (c) => c.country_code === bankCreate?.country_code?.toUpperCase()
        )?.country_name ?? ''
      )
    }
  }, [bankCreate?.currency, bankCreate?.country_code])

  const handleSelectionCountry = (country: string): void => {
    setCountryName(country)
    setBankCreate({
      country_code:
        COUNTRIES.find((c) => c.country_name === country)?.country_code ?? ''
    })
  }

  const handleSelectionCurrency = (currency: string): void => {
    setCurrencyName(currency)
    setBankCreate({
      currency:
        COUNTRIES.find((c) => c.currency_name === currency)?.currency_code ?? ''
    })
  }

  const handleInputChange: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'account_number') {
        setBankCreate({ account_number: value })
      } else if (name === 'routing_number') {
        setBankCreate({ routing_number: value })
      } else if (name === 'account_holder_name') {
        setBankCreate({ account_holder_name: value })
      }
    }
  }

  const routingNumberIsRequired = (): boolean => {
    return (
      countryName !== '' && ROUTING_NUMBER_COUNTRY.includes(countryName as any)
    )
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (textUtil.isEmpty(bankCreate?.account_number)) {
      showToast(undefined, 'bank.accountNumberRequired')
      return
    }

    if (textUtil.isEmpty(bankCreate?.currency)) {
      showToast(undefined, 'bank.currencyRequired')
      return
    }

    if (textUtil.isEmpty(bankCreate?.country_code)) {
      showToast(undefined, 'bank.countryRequired')
      return
    }

    if (
      routingNumberIsRequired() &&
      textUtil.isEmpty(bankCreate?.routing_number)
    ) {
      showToast(undefined, 'bank.routingNumberRequired')
      return
    }

    if (textUtil.isEmpty(bankCreate?.account_holder_name)) {
      showToast(undefined, 'bank.accountHolderNameRequired')
      return
    }

    setBankCreate({
      account_holder_type: 'individual'
    })

    setIsSameIban(false)

    push(`/dashboard/${category}/jars/add/reviews`)
  }

  const handlePreviousStep = (): void => {
    push(`/dashboard/${category}/jars/add/ibans`, 'back')
  }

  // const getAccountHolderName = (): string => {
  //   if (bankCreate?.account_holder_name != null) {
  //     return bankCreate?.account_holder_name
  //   } else {
  //     return `${user?.person?.first_name ?? ''} ${
  //       user?.person?.last_name ?? ''
  //     }`
  //   }
  // }

  return (
    <form onSubmit={handleSubmit} className='space-y-6'>
      <AddBankCountry
        country={countryName}
        setSelectionCountry={handleSelectionCountry}
      />
      <AddBankCurrency
        currency={currencyName}
        setSelectionCurrency={handleSelectionCurrency}
      />
      <Input
        type='text'
        name='account_holder_name'
        value={bankCreate?.account_holder_name}
        labelOptions={{
          children: 'accountHolderName',
          capitalize: false
        }}
        onIonChange={handleInputChange}
      />
      <div>
        <Input
          name='account_number'
          type='text'
          value={bankCreate?.account_number}
          onIonInput={handleInputChange}
          labelOptions={{
            children: 'accountNumber'
          }}
        />
      </div>
      {countryName != null &&
        ROUTING_NUMBER_COUNTRY.includes(countryName as any) && (
          <div>
            <Input
              name='routing_number'
              type='text'
              value={bankCreate?.routing_number}
              onIonInput={handleInputChange}
              labelOptions={{
                children: 'routingNumber'
              }}
            />
          </div>
        )}
      <ButtonStep handleBack={handlePreviousStep} nextType='submit' />
    </form>
  )
}
