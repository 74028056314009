import { useState, useEffect } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { type PaymentRequest } from '@stripe/stripe-js'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { IonCheckbox, IonCol, IonRow, useIonRouter } from '@ionic/react'
import Or from '#components/common/Or'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'
import useLoading from '#hooks/useLoading'
import { useTranslation } from 'react-i18next'
import Text from '#components/ui/Text'
import Info from '#components/ui/Info'
import { savePreferences } from 'storage/preferences'
import { type FeeSimulator } from '#tackpay-sdk'
import { getSymbolCurrency } from '#constants/country'

export default function CheckoutPaymentRequestButton(): JSX.Element {
  const { checkout } = useCheckoutContext()

  const stripe = useStripe()

  const { showToast } = useToast('warning')

  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.processing'
  )

  const { push } = useIonRouter()

  const { t } = useTranslation()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  const [payFee, setPayFee] = useState(false)

  const [forceRedirect, setForceRedirect] = useState(false)

  const [fee, setFee] = useState<FeeSimulator | null>(null)

  useEffect(() => {
    const fetchFee = async (): Promise<void> => {
      if (checkout?.payment == null) {
        return
      }

      const result = await sdk.checkout_sessions.fee_simulators(
        checkout?.payment?.amount_cents,
        checkout?.payment?.currency,
        true
      )

      setFee(result)
    }

    void fetchFee()
  }, [])

  useEffect(() => {
    const cleanPersistedFee = async (): Promise<void> => {
      await savePreferences('feeSimulator', null)
    }
    void cleanPersistedFee()
  }, [])

  const handleFeeChange = async (covered: boolean): Promise<void> => {
    if (checkout?.payment == null) {
      return
    }

    const result = await sdk.checkout_sessions.fee_simulators(
      checkout?.payment?.amount_cents,
      checkout?.payment?.currency,
      covered
    )
    await savePreferences('feeSimulator', result)
  }

  useEffect(() => {
    if (forceRedirect) {
      push(
        `/checkout/${checkout?.tackpay_id?.value}/${checkout?.id ?? ''}/reviews`
      )
    }

    return () => {
      setForceRedirect(false)
    }
  }, [forceRedirect])

  const handlePaymentMethod = async (
    paymentMethodReference: string
  ): Promise<void> => {
    await sdk.payment_methods.create({
      payment_method_reference: paymentMethodReference,
      method_type: 'card',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const handleFee = async (): Promise<void> => {
    const feePay =
      document?.querySelector<HTMLInputElement>('#feeDigital')?.checked

    await sdk.fees.create({
      covered: feePay ?? false,
      fee_category: 'payment',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  useEffect(() => {
    if (stripe != null && checkout?.payment != null) {
      const pr = stripe.paymentRequest({
        country: 'IT',
        currency: checkout.payment.currency ?? 'eur',
        total: {
          label: 'TackPay tip',
          amount: checkout.payment.amount_cents ?? 0
        },
        displayItems: [
          {
            amount: checkout.payment.amount_cents ?? 0,
            label: 'TackPay tip'
          }
        ],
        requestPayerName: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        disableWallets: ['link']
      })

      pr.canMakePayment()
        .then((result) => {
          if (result != null) {
            setPaymentRequest(pr)
          }
        })
        .catch((error) => {
          console.error(error)
        })

      pr.on('paymentmethod', async (ev) => {
        startLoading()
        try {
          const paymentMethodId = ev.paymentMethod.id
          await handlePaymentMethod(paymentMethodId)
          await handleFee()
          const { error, paymentIntent } = await stripe?.confirmCardPayment(
            checkout?.payment?.client_secret_reference ?? '',
            {
              payment_method: paymentMethodId
            }
          )

          if (error != null) {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          } else if (paymentIntent != null) {
            ev.complete('success')
            if (paymentIntent.status === 'requires_confirmation') {
              const { error } = await stripe.confirmCardPayment(
                checkout?.payment?.client_secret_reference ?? ''
              )

              if (error != null) {
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              } else {
                sdk.payments
                  .update({
                    id: checkout?.payment?.id ?? '',
                    reference: paymentIntent?.id ?? ''
                  })
                  .then(() => {
                    stopLoading()
                    setForceRedirect(true)
                    push(
                      `/checkout/${checkout.tackpay_id?.value}/${
                        checkout?.id ?? ''
                      }/reviews`
                    )
                  })
                  .catch((error) => {
                    console.error(error)
                    stopLoading()
                    setForceRedirect(true)
                    push(
                      `/checkout/${checkout.tackpay_id?.value}/${
                        checkout?.id ?? ''
                      }/reviews`
                    )
                  })
              }
            } else if (paymentIntent.status === 'succeeded') {
              sdk.payments
                .update({
                  id: checkout?.payment?.id ?? '',
                  reference: paymentIntent?.id ?? ''
                })
                .then(() => {
                  stopLoading()
                  setForceRedirect(true)
                  push(
                    `/checkout/${checkout.tackpay_id?.value}/${
                      checkout?.id ?? ''
                    }/reviews`
                  )
                })
                .catch((error) => {
                  console.error(error)
                  stopLoading()
                  setForceRedirect(true)
                  push(
                    `/checkout/${checkout.tackpay_id?.value}/${
                      checkout?.id ?? ''
                    }/reviews`
                  )
                })
            }
          } else {
            stopLoading()
            showToast(undefined, 'checkout.payment.error')
          }
        } catch (error) {
          console.error(error)
          stopLoading()
          showToast(undefined, 'checkout.payment.error')
        }
      })
    }
  }, [stripe, checkout?.payment])

  if (paymentRequest != null)
    return (
      <IonRow className='w-full'>
        <IonCol size='12'>
          <div className='pt-5 text-center'>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
            <Text size='text-xs' color='gray-500' className='mt-1 text-center'>
              accept_term_digital
            </Text>
          </div>
        </IonCol>
        <IonCol size='12'>
          <IonCol className='space-y-4'>
            <IonRow className='ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding w-full'>
              <IonCheckbox
                className='square text-gray-700'
                name='transactions'
                checked={payFee}
                onIonChange={(e) => {
                  setPayFee(e.detail.checked)
                  void handleFeeChange(e.detail.checked)
                }}
                labelPlacement='end'
                justify='start'
                id='feeDigital'
              >
                {t('checkout.payFee', {
                  amount: `(+ ${((fee?.totalFeeAmount ?? 0) / 100)?.toFixed(2)} ${getSymbolCurrency(checkout?.payment?.currency ?? 'eur')})`
                })}
              </IonCheckbox>

              <Info title='i' text={t('checkout.payFeeInfo')} />
            </IonRow>
          </IonCol>
        </IonCol>
        <Or />
      </IonRow>
    )

  return <></>
}
