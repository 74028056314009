import Card from '#components/ui/Card'
import Chip from '#components/ui/Chip'
import CoverIcon from '#components/ui/CoverIcon'
import Text from '#components/ui/Text'
import { useUserContext } from '#contexts/UserContext'
import { type Payout, type UserCategory } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import { IonCardContent, IonCol, IonRow } from '@ionic/react'

interface Props {
  payout: Payout
  category: UserCategory
  jarId?: string
  isDeduction?: boolean
}

export default function PayoutCard({
  payout,
  category,
  jarId,
  isDeduction = false
}: Props): JSX.Element {
  const { user } = useUserContext()

  const getChipColor = (): string => {
    if (payout.status === 'pending' || payout.status === 'in_transit')
      return 'warning'
    if (payout.status === 'paid') return 'success'
    return 'danger'
  }

  const getUrl = (): string => {
    if (payout.category === 'user') {
      return `/dashboard/${category}/settings/withdrawals/payouts/${payout.id}`
    }

    if (!isDeduction) {
      return `/dashboard/${category}/jars/${
        jarId ?? ''
      }/settings/withdrawals/payouts/${payout.id}`
    } else {
      return `/dashboard/${category}/jars/${
        jarId ?? ''
      }/settings/withdrawals/deductions/payouts/${payout.id}`
    }
  }

  return (
    <Card
      routerLink={getUrl()}
      routerDirection='root'
      className='ion-no-padding ion-no-margin card-payout'
    >
      <IonCardContent className='ion-no-padding ion-no-margin'>
        <IonRow className='ion-align-items-center ion-no-padding ion-no-margin w-full'>
          <IonCol
            size={`${payout.status === 'paid' ? 2.5 : 3}`}
            className='ion-no-padding my-0 ml-0'
          >
            <div className='flex flex-row justify-center'>
              <Chip
                className={`capitalize rounded-lg py-0 ${
                  payout.status === 'paid' ? 'px-2' : 'px-1'
                }`}
                color={getChipColor()}
              >
                {payout.status}
              </Chip>
            </div>
          </IonCol>
          <IonCol size='5' className='ion-no-padding ion-no-margin'>
            <IonRow className='ion-no-margin ion-no-padding'>
              <IonCol className='ion-no-margin ion-no-padding space-y-0'>
                <div className='w-full'>
                  <Text
                    translationOptions={{
                      translate: 'no'
                    }}
                    className='text-gray-800 text-card-payout'
                    fontWeight='font-semibold'
                  >
                    {formattedAmount(payout.amount_cents, payout.currency)}
                  </Text>
                </div>
                <div className='w-full'>
                  <Text translate='no' className='text-card-payout-data'>
                    Withdrawn on,{' '}
                    {formattedDate(
                      payout.created_at ?? new Date(),
                      user?.lang,
                      {
                        month: 'short',
                        day: '2-digit'
                      }
                    )}
                  </Text>
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size='4' className='ion-no-padding ion-no-margin'>
            <IonRow className='ion-no-margin ion-no-padding ion-justify-content-end ion-align-items-center'>
              <IonCol className='ion-no-margin ion-no-padding' size='5'>
                <IonRow className='ion-no-margin ion-no-padding w-full ion-justify-content-center pr-1'>
                  <CoverIcon
                    iconOptions={{ icon: '/assets/img/bank.svg' }}
                    className='w-7 h-7 rounded-sm bg-slate-200'
                  />
                </IonRow>
              </IonCol>
              <IonCol className='ion-no-margin ion-no-padding' size='6'>
                <IonRow className='ion-no-margin ion-no-padding w-full ion-justify-content-end ion-align-items-center'>
                  <IonCol
                    className='ion-no-margin ion-no-padding h-[13px]'
                    size='3'
                  >
                    <Text color='dark' className='text-card-payout'>
                      **
                    </Text>
                  </IonCol>
                  <IonCol className='ion-no-margin ion-no-padding' size='9'>
                    <Text
                      translate='no'
                      color='dark'
                      className='text-card-payout'
                    >
                      {`${payout?.bank_account?.last_account_number ?? ''}`}
                    </Text>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </Card>
  )
}
