export const LANGUAGES = [
  {
    name: 'English',
    code: 'en',
    img: '../assets/img/flags/en.svg'
  },
  {
    name: 'Spanish',
    code: 'es',
    img: '../assets/img/flags/es.svg'
  },
  {
    name: 'Italian',
    code: 'it',
    img: '../assets/img/flags/it.svg'
  },
  {
    name: 'French',
    code: 'fr',
    img: '../assets/img/flags/fr.svg'
  }
] as const

export const LANGUAGES_USA = [
  {
    name: 'English',
    code: 'en',
    img: '../assets/img/flags/us.svg'
  },
  {
    name: 'Spanish',
    code: 'es',
    img: '../assets/img/flags/es.svg'
  },
  {
    name: 'Italian',
    code: 'it',
    img: '../assets/img/flags/it.svg'
  },
  {
    name: 'French',
    code: 'fr',
    img: '../assets/img/flags/fr.svg'
  }
] as const
