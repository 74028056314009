import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { useBankDeductionContext } from '#contexts/BankContextDeduction'
import CardBankAccount from './CardBankAccount'
import EmptyBank from './EmptyBank'

export default function BankAccountDeductionSection(): JSX.Element {
  const { bank, isLoading, error } = useBankDeductionContext()

  if (isLoading) return <CardSkeleton />

  if (error != null) return <ErrorBox error={error} />

  if (bank == null) return <EmptyBank />

  return <CardBankAccount bank={bank} />
}
