import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type BankAccount } from './bankAccounts'
import { type User } from './users'
import { type Jar } from './jars'
import { type QueryParamsRetrieve } from 'sdk/query'
import {
  type BankAccountDeductionRel,
  type BankAccountDeduction
} from './bankAccountDeductions'

type PayoutType = 'payouts'
type PayoutRel = ResourceRel & { type: PayoutType }

type PayoutStatus = 'paid' | 'pending' | 'in_transit' | 'canceled' | 'failed'
type PayoutFailureCode =
  | 'account_closed'
  | 'account_frozen'
  | 'bank_account_restricted'
  | 'bank_ownership_changed'
  | 'could_not_process'
  | 'debit_not_authorized'
  | 'declined'
  | 'insufficient_funds'
  | 'invalid_account_number'
  | 'incorrect_account_holder_name'
  | 'incorrect_account_holder_address'
  | 'incorrect_account_holder_tax_id'
  | 'invalid_currency'
  | 'no_account'
  | 'unsupported_card'

type PayoutCategory = 'user' | 'jar' | 'deduction'

type BankAccountRel = ResourceRel & { type: 'bank_accounts' }
type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

interface Payout extends Resource {
  readonly type: PayoutType
  amount_cents: number
  amount_float: number
  amount_formatted: string
  currency: string
  status: PayoutStatus
  reference: string
  reference_origin: string
  failure_code?: PayoutFailureCode
  failure_message?: string
  statement_description?: string
  arrival_date?: number
  category: PayoutCategory
  bank_account?: BankAccount
  user?: User
  jar?: Jar
  bank_account_deduction?: BankAccountDeduction
}

interface PayoutCreate extends ResourceCreate {
  amount: number
  category: PayoutCategory
  currency: string
  bank_account?: BankAccountRel
  bank_account_deduction?: BankAccountDeductionRel
  user?: UserRel
  jar?: JarRel
}

class Payouts extends ApiResource<Payout> {
  static readonly TYPE: PayoutType = 'payouts' as const

  async create(
    resource: PayoutCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Payout> {
    return await this.resources.create<PayoutCreate, Payout>(
      {
        ...resource,
        type: Payouts.TYPE
      },
      params,
      options
    )
  }

  isPayout(resource: any): resource is Payout {
    return resource.type != null && resource.type === Payouts.TYPE
  }

  relationship(id: string | ResourceId | null): PayoutRel {
    return id === null || typeof id === 'string'
      ? { id, type: Payouts.TYPE }
      : { id: id.id, type: Payouts.TYPE }
  }

  type(): PayoutType {
    return Payouts.TYPE
  }
}

export type { Payout, PayoutCreate }
export default Payouts
