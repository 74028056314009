import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Jar } from './jars'
import { type Company } from './companies'
import { type User } from './users'
import { type Member } from './members'

type TransferType = 'transfers'
type TransferRel = ResourceRel & { type: TransferType }

type TransferStatus = 'pending' | 'succeeded' | 'failed' | 'canceled'
type TransferCategory = 'tipped' | 'jar' | 'member' | 'payout_fee'

type PaymentRel = ResourceRel & { type: 'payments' }
type MemberRel = ResourceRel & { type: 'members' }
type JarRel = ResourceRel & { type: 'jars' }

interface Transfer extends Resource {
  readonly type: TransferType

  amount_cents: number
  amount_float: number
  amount_formatted: string
  currency: string
  reference: string
  reference_origin: string
  status: TransferStatus
  error?: Record<string, unknown>
  category: TransferCategory
  split_divide: number
  tronc: boolean
  payment?: Payment
  jar?: Jar
  company?: Company
  user?: User
  member?: Member
}

interface TransferCreate extends ResourceCreate {
  amount: number
  currency: string
  payment?: PaymentRel
  member?: MemberRel
  jar?: JarRel
}

class Transfers extends ApiResource<Transfer> {
  static readonly TYPE: TransferType = 'transfers' as const

  async create(
    resource: TransferCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Transfer> {
    return await this.resources.create(
      { ...resource, type: Transfers.TYPE },
      params,
      options
    )
  }

  isTransfer(resource: any): resource is Transfer {
    return resource.type != null && resource.type === Transfers.TYPE
  }

  relationship(id: string | ResourceId | null): TransferRel {
    return id === null || typeof id === 'string'
      ? { id, type: Transfers.TYPE }
      : { id: id.id, type: Transfers.TYPE }
  }

  type(): TransferType {
    return Transfers.TYPE
  }
}

export type { Transfer, TransferCreate }

export default Transfers
