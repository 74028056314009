import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import DefaultTipButton from '#components/ui/DefaultTipButton'
import Text from '#components/ui/Text'
import { EUROPEAN_COUNTRY_CODES, getSymbolCurrency } from '#constants/country'
import {
  type Jar,
  type CheckoutSession,
  type ListResponse,
  type Member,
  type PaymentCreate
} from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import CustomModal, { type TypeCustomModal } from '../CustomModal'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import Underlined from '#components/ui/UnderlinedName'
import { useInfiniteQuery } from '@tanstack/react-query'
import AvatarSkeleton from '#components/common/AvatarSkeleton'
import Skeleton from '#components/ui/Skeleton'
import ErrorBox from '#components/errors/ErrorBox'
import randomString from '#utils/randomString'
import isDesktop from '#utils/isDesktop'
import { savePreferences } from 'storage/preferences'
import isBio from '#utils/isBio'
import { DEFAULT_COMPANY_BIO } from '#constants/bio'

interface Props {
  checkout: CheckoutSession
  jar: Jar
  tackpayIdValue: string
}

export default function CheckoutTeam({
  jar,
  tackpayIdValue,
  checkout
}: Props): JSX.Element {
  const hiddenJars = ['IDIQ' /* 'KOZY' */]

  const isHiddenJar = hiddenJars.includes(tackpayIdValue)

  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.create.loading'
  )

  const [enabled, setEnabled] = useState<boolean>(true)

  const { showToast, hideToast: hideMember } = useToast('success')

  const { showToast: showTeam, hideToast: hideTeam } = useToast('success')

  const { showToast: successToast } = useToast('success')

  const { showToast: showWarn } = useToast('warning')

  const { push } = useIonRouter()

  const [currentMember, setCurrentMember] = useState<Member | undefined>()

  const [currentAmount, setCurrentAmount] = useState<number | undefined>(0)

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const swiperRef = useRef<SwiperRef>(null)

  const handlerClickAvatar = (index: number): void => {
    if (index < activeIndex) {
      const diff = isDesktop() ? 2 : 1
      swiperRef.current?.swiper?.slideTo(activeIndex - diff)
    } else if (index > activeIndex) {
      swiperRef.current?.swiper?.slideTo(activeIndex + 1)
    } else if (index === activeIndex) {
      const diff = isDesktop() ? 1 : 0
      swiperRef.current?.swiper?.slideTo(index - diff)
    }
  }

  useEffect(() => {}, [activeIndex])

  const [tipModalType, setTipModalType] = useState<
    TypeCustomModal | undefined
  >()

  const [showModalCustom, setShowModalCustom] = useState<boolean>(false)

  const handlePaymentCreate = (createPayment: PaymentCreate): void => {
    startLoading()

    sdk.payments
      .create(createPayment)
      .then(() => {
        void savePreferences('checkout', 1)
        stopLoading()
        successToast(undefined, 'checkout.payment.create.success')
        push(`/checkout/${tackpayIdValue}/${checkout.id}/payment-methods`)
      })
      .catch((error) => {
        stopLoading()
        showWarn(undefined, getErrorMessage(error))
      })
  }

  const fetchMembers = async ({
    pageParam = 1
  }): Promise<ListResponse<Member>> => {
    return await sdk.checkout_sessions.members(jar.id, pageParam, 20)
  }

  const {
    data,
    hasNextPage: hasMore,
    // fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['checkout-members'],
    queryFn: fetchMembers,
    initialPageParam: 1,
    enabled,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined
  })

  // const loadNext = (e: IonInfiniteScrollCustomEvent<void>): void => {
  //   if (!hasMore || hasMore == null) {
  //     void e.target.complete()
  //   } else {
  //     fetchNextPage()
  //       .then(() => {
  //         void e.target.complete()
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //         void e.target.complete()
  //       })
  //   }
  // }

  const isExtraUE = (): boolean => {
    if (
      ['9c86881d-23f5-4cf4-9716-c7257ed4a914'].includes(jar.company?.id ?? '')
    ) {
      return true
    }

    if (jar.creator?.person?.address != null) {
      const iso = jar.creator.person.address.country_code.toUpperCase()
      return !EUROPEAN_COUNTRY_CODES.includes(iso)
    }

    return false
  }

  const isExtraEuNotification = (): boolean => {
    if (isExtraUE() && currentMember == null) {
      return jar.jar_type === 'shared'
    }
    return false
  }

  useEffect(() => {
    if (hasMore == null) {
      setEnabled(false)
    }

    return () => {
      setEnabled(false)
    }
  }, [enabled, hasMore])

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>): void => {
    if (e != null) {
      e.preventDefault()
    }

    if (currentAmount != null && currentAmount <= 0) {
      showWarn(undefined, 'checkout.payment.create.zeroAmount')
      return
    }

    if (isExtraEuNotification()) {
      showWarn(undefined, 'checkoutSelectMember', {
        data: {
          name: jar.name
        }
      })
      return
    }

    if (currentAmount != null) {
      const amount = parseFloat((currentAmount * 100).toFixed(2))

      if (jar.jar_type === 'private') {
        handlePaymentCreate({
          amount,
          category: 'jar',
          role: 'individual',
          currency: jar?.currency ?? 'eur',
          // tackpay_id: tackpayIdValue,
          jar: {
            id: jar.id,
            type: 'jars'
          },
          checkout_session: {
            id: checkout.id,
            type: 'checkout_sessions'
          },
          reference_review: 'jar'
        })
      } else if (jar.tipping_rule === 'tronc') {
        handlePaymentCreate({
          amount,
          category: 'jar',
          role: 'tronc',
          currency: jar?.currency ?? 'eur',
          // tackpay_id: tackpayIdValue,
          jar: {
            id: jar?.id ?? '',
            type: 'jars'
          },
          member:
            currentMember != null
              ? {
                  id: currentMember.id,
                  type: 'members'
                }
              : undefined,
          checkout_session: {
            id: checkout.id,
            type: 'checkout_sessions'
          },
          reference_review:
            currentMember == null ? 'all_member' : 'single_member'
        })
      } else if (jar.jar_type === 'shared') {
        if (jar.tipping_rule === 'equal_split') {
          handlePaymentCreate({
            amount,
            category: 'jar',
            role: 'equal_split',
            currency: jar?.currency ?? 'eur',
            // tackpay_id: tackpayIdValue,
            jar: {
              id: jar?.id ?? '',
              type: 'jars'
            },
            checkout_session: {
              id: checkout.id,
              type: 'checkout_sessions'
            },
            member:
              currentMember != null
                ? {
                    id: currentMember?.id ?? '',
                    type: 'members'
                  }
                : undefined,

            reference_review:
              currentMember == null ? 'all_member' : 'single_member'
          })
        } else if (jar.tipping_rule === 'individual') {
          handlePaymentCreate({
            amount,
            category: currentMember != null ? 'member' : 'jar',
            role: currentMember != null ? 'individual' : 'equal_split',
            currency: jar?.currency ?? 'eur',
            // tackpay_id: tackpayIdValue,
            member:
              currentMember != null
                ? {
                    id: currentMember?.id ?? '',
                    type: 'members'
                  }
                : undefined,
            jar: {
              id: jar?.id ?? '',
              type: 'jars'
            },
            checkout_session: {
              id: checkout.id,
              type: 'checkout_sessions'
            },
            reference_review:
              currentMember != null ? 'single_member' : 'all_member'
          })
        }
      }
    }
  }

  const handleSlideChange = (swiper: any): void => {
    setActiveIndex(swiper.activeIndex)

    if (swiper.activeIndex === 0) {
      if (!isHiddenJar) {
        setCurrentMember(undefined)
        hideMember()
        if (isExtraUE()) {
          showWarn(undefined, 'checkoutSelectMember', {
            data: {
              name: jar.name
            }
          })
        } else {
          showTeam(undefined, 'tipToTeam')
        }
      } else {
        const members = data?.pages.map((p) => p).flat()
        if (members != null && members.length > 0) {
          const member = members[0]
          setCurrentMember(member)
          showToast(undefined, 'tipToTipped', {
            data: {
              name:
                member?.user?.checkout_setting?.username ??
                member?.user?.person?.first_name
            }
          })
        }
      }
    } else {
      const members = data?.pages.map((p) => p).flat()
      if (members != null) {
        const member = members[swiper.activeIndex - (isHiddenJar ? 0 : 1)]
        hideTeam()
        setCurrentMember(member)
        showToast(undefined, 'tipToTipped', {
          data: {
            name:
              member?.user?.checkout_setting?.username ??
              member?.user?.person?.first_name
          }
        })
      }
    }
  }

  useEffect(() => {
    if (isHiddenJar) {
      if (data?.pages != null && data.pages.length > 0) {
        setCurrentMember(data?.pages[0][0])
      }
    }
  }, [data])

  const viewDesktop = isDesktop() ? 'm-auto w-5/6' : 'w-full'

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      <Swiper
        ref={swiperRef}
        spaceBetween={isDesktop() ? -240 : 20}
        slidesPerView={3}
        centeredSlides
        initialSlide={0}
        onSlideChange={handleSlideChange}
      >
        {!isHiddenJar && (
          <SwiperSlide>
            <Avatar
              avatarOptions={{
                className: `${
                  currentMember == null ? 'border-green-400' : 'border-gray-400'
                } border-4  mx-auto h-24 w-24`,
                onClick: () => {
                  handlerClickAvatar(0)
                }
              }}
              imgOptions={{
                src: jar.checkout_setting?.avatar ?? DEFAULT_IMAGES.logoTeam
              }}
            />
          </SwiperSlide>
        )}

        {jar?.checkout_setting?.show_member === true && (
          <>
            {status === 'pending' ? (
              <SwiperSlide key='0'>
                <IonCol className='block space-y-5'>
                  <AvatarSkeleton className='w-24 h-24 mx-auto' />
                  <Skeleton className='w-1/2 mx-auto' />
                </IonCol>
              </SwiperSlide>
            ) : status === 'error' ? (
              <ErrorBox error={error} />
            ) : data.pages != null &&
              data.pages[0].length === 0 &&
              !isFetching ? (
              <></>
            ) : (
              <>
                {data.pages.map((page) => (
                  <div key={randomString()}>
                    {page.map((member, index) => (
                      <SwiperSlide
                        key={randomString()}
                        className='swiper-slide swiper-slide-active'
                        onClick={() => {
                          handlerClickAvatar(index + 1)
                        }}
                      >
                        <Avatar
                          avatarOptions={{
                            className: `${
                              currentMember?.id === member.id
                                ? 'border-green-400'
                                : 'border-gray-400'
                            } border-4 mx-auto h-24 w-24 z-55`,
                            onClick: () => {
                              handlerClickAvatar(index + 1)
                            }
                          }}
                          imgOptions={{
                            src:
                              member.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </div>
                ))}
                {/* <IonInfiniteScroll
                  disabled={hasMore == null || !hasMore}
                  onIonInfinite={loadNext}
                >
                  <IonInfiniteScrollContent
                    loadingSpinner='circles'
                    loadingText='Loading...'
                    color='primary'
                  />
                </IonInfiniteScroll> */}
              </>
            )}
          </>
        )}
      </Swiper>

      <div className='mt-8'>
        <Underlined
          name={
            currentMember != null
              ? currentMember?.user?.checkout_setting?.username ??
                currentMember.user?.person?.first_name
              : jar?.checkout_setting?.username
          }
        />
      </div>
      <IonRow className='pt-8 ion-justify-content-center'>
        <Text
          className='text-gray-600 text-center'
          translationOptions={{
            translate: isBio(jar?.checkout_setting?.description ?? '')
              ? 'yes'
              : 'no'
          }}
        >
          {jar?.checkout_setting?.description ?? DEFAULT_COMPANY_BIO}
        </Text>
      </IonRow>
      <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
      <DefaultTipButton
        tip={jar?.checkout_setting?.fast_amounts?.map((amount) =>
          formattedAmount(amount)
        )}
        amount={currentAmount}
        setAmount={setCurrentAmount}
        currency={jar.currency ?? jar.checkout_setting?.currency ?? 'eur'}
      />
      <IonRow
        className={`${viewDesktop} ion-justify-content-around pt-5 px-2 mt-3`}
      >
        {jar?.checkout_setting?.show_custom_amount === true && (
          <Button
            type='button'
            id='modal-custom'
            color='white'
            className={`choose-button-preview shadow-md ion-no-margin rounded-md ${
              jar?.checkout_setting?.show_percentage_amount
                ? 'w-5/12'
                : 'w-full'
            }`}
            onClick={() => {
              setTipModalType('amount')
              setShowModalCustom(true)
            }}
          >
            <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
              {jar?.company?.id === '9c86881d-23f5-4cf4-9716-c7257ed4a914'
                ? 'Personalizar'
                : 'type_amount'}
            </Text>
          </Button>
        )}
        {jar?.checkout_setting?.show_percentage_amount === true && (
          <Button
            type='button'
            id='modal-custom'
            color='white'
            className={`choose-button-preview shadow-md ion-no-margin rounded-md ${
              jar?.checkout_setting?.show_custom_amount ? 'w-5/12' : 'w-full'
            }`}
            onClick={() => {
              setTipModalType('percentage')
              setShowModalCustom(true)
            }}
          >
            <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
              %
            </Text>
          </Button>
        )}
      </IonRow>
      <IonRow className='mt-12 mx-0 ion-no-padding w-full '>
        <Button
          type='submit'
          className='w-full ion-no-margin ion-no-padding'
          translationOptions={{
            translate: 'yes',
            data: {
              amount: currentAmount === 0 ? '' : currentAmount?.toFixed(2),
              currency:
                currentAmount === 0
                  ? ''
                  : getSymbolCurrency(jar?.checkout_setting?.currency ?? 'eur')
            }
          }}
        >
          confirmAmount
        </Button>
      </IonRow>
      <CustomModal
        dataTip={tipModalType ?? 'percentage'}
        openModal={showModalCustom}
        setOpenModal={setShowModalCustom}
        currency={jar.currency ?? jar?.checkout_setting?.currency ?? 'eur'}
        setCurrentAmount={setCurrentAmount}
        currentAmount={currentAmount ?? 0}
        submitFunction={handleSubmit}
      />
    </form>
  )
}
