import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { type Identity } from '#tackpay-sdk'

interface Props {
  isLoading: boolean
  error?: any
  identity?: Identity | null
}
export default function CardDetails({
  isLoading,
  error,
  identity
}: Props): JSX.Element {
  if (isLoading) return <Skeleton />

  if (error != null) return <ErrorBox error={error} />

  const getIdentityDetailText = (): string => {
    if (identity == null) return 'identity.noDetails'
    if (identity?.details_code != null && identity.details_code !== '')
      return identity?.details_code
    if (identity?.details != null && identity.details !== '')
      return identity?.details
    return 'identity.noDetails'
  }

  return (
    <div className='pt-5'>
      <Text>{getIdentityDetailText()}</Text>
    </div>
  )
}
