import { createAnimation } from '@ionic/core'
import { type MutableRefObject } from 'react'

interface Props {
  typeAnimation: MutableRefObject<HTMLDivElement | HTMLIonCardElement | null>
  start: string
  end: string
  duration?: number
}

export const animationsFade = ({
  typeAnimation,
  start,
  end,
  duration
}: Props): void => {
  const defaultDuration = duration ?? 300
  const animation = typeAnimation
  if (animation?.current !== null) {
    const createdAnimation = createAnimation()
      .addElement(animation?.current)
      .easing('ease-in-out')
      .duration(defaultDuration)
      .fromTo('opacity', '0', '1') // Animación de opacidad desde 1 (visible) a 0 (invisible)
      .fromTo('transform', `translateY(${start})`, `translateY(${end})`) // Mueve el elemento hacia abajo durante la animación

    createdAnimation
      .play()
      .then(() => {})
      .catch(() => {})
  }
}
