import AppLayout from '#components/layouts/app/AppLayout'
import AddBankAccountDeductionForm from '#components/pages/banks/AddBankAccountDeductionForm'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankDeductionContainer from '#contexts/BankContextDeduction'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function TeamAddBankAccountDeduction(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/deductions`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
    >
      <Title hasSubtitle>bank.add.title</Title>
      <SubTitle>bank.add.subtitle</SubTitle>
      <BankDeductionContainer fetch jarId={jarId}>
        <AddBankAccountDeductionForm jarId={jarId} category={category} />
      </BankDeductionContainer>
    </AppLayout>
  )
}
