import ErrorBox from '#components/errors/ErrorBox'
import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type Member, type Company, type Jar } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import sdk from '#utils/sdk'
import { IonCol, IonRow } from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import cn from 'classnames'

function HandleTableMember({
  jar
}: {
  company: Company
  jar: Jar
}): JSX.Element {
  const { t } = useTranslation()

  const [currentJar, setCurrentJar] = useState<Jar | undefined | null>(jar)

  const [members, setMembers] = useState<Member[]>([])

  const fetchMembers = (): void => {
    sdk.jars
      .retrieve(jar.id, {
        include: ['members.user.person']
      })
      .then((res) => {
        if (res.members != null) {
          setMembers(res.members)
        }
      })
      .catch((error) => {
        console.log('error to fetch members', error)
      })
  }

  useEffect(() => {
    if (jar.id !== currentJar?.id) {
      setCurrentJar(jar)
    }
    fetchMembers()
  }, [jar])

  const getMemberActiveText = (member: Member): string => {
    if (member.active) {
      return t('members.active')
    }

    return t('members.inactive')
  }

  return (
    <>
      {members?.map((member) => (
        <IonRow
          className='w-full border-stroke border-b ion-align-items-center ion-justify-content-between'
          key={member.id}
        >
          <IonCol>
            <div className='flex items-center gap-1 p-2.5 xl:p-3'>
              <div className='flex-shrink-0'>
                <Avatar
                  imgOptions={{
                    src: member.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
                  }}
                  avatarOptions={{
                    className: 'w-12 h-12'
                  }}
                />
              </div>
              <p className='hidden text-black sm:block pl-1'>
                {member.user?.person?.first_name}{' '}
                {member.user?.person?.last_name}
              </p>
            </div>
          </IonCol>
          <IonCol>
            <div className='flex items-center justify-center p-2.5 xl:p-5'>
              <p className='text-black'>{jar.name}</p>
            </div>
          </IonCol>
          <IonCol>
            <div className='flex items-center justify-center p-2.5 xl:p-5'>
              <p className='text-meta-3'>{member.total_tip}</p>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden items-center justify-center p-2.5 sm:flex xl:p-5'>
              <p className='text-black '>
                {formattedAmount(
                  member.balance.amount_cents,
                  member?.user?.currency
                )}
              </p>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden items-center justify-center p-2.5 sm:flex xl:p-5'>
              <p className='text-meta-5'>{member.total_feedback}</p>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden items-center justify-center p-2.5 sm:flex xl:p-5'>
              <IonRow className='ion-align-items-center space-x-2'>
                <div
                  className={cn(
                    {
                      'bg-green-400 rounded-full p-1': member.active,
                      'bg-red-400 rounded-full p-1': !member.active
                    },
                    'rounded-full p-1 w-2 h-2'
                  )}
                />
                <p className='text-meta-5'>{getMemberActiveText(member)}</p>
              </IonRow>
            </div>
          </IonCol>
        </IonRow>
      ))}
    </>
  )
}

export default function TableSocials({
  currentJar
}: {
  currentJar: Jar
}): JSX.Element {
  const { t } = useTranslation()
  const { company, isLoading, error } = useCompanyContext()
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <Card className='rounded-sm border border-gray-200'>
      <IonRow className='ion-align-items-center ion-justify-content-between mb-6'>
        <Text className='text-xl font-semibold text-black'>
          members.titleWithJar
        </Text>
        <div>
          <Button
            type='button'
            iconOptions={{ icon: addOutline }}
            routerDirection='root'
            routerLink={`/dashboard/${category}/jars/${currentJar.id}/members/add/new`}
          >
            add_member
          </Button>
        </div>
      </IonRow>

      <IonCol className=''>
        <IonRow className='bg-gray-200'>
          <IonCol>
            <div className='p-2.5 xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                {t('members.member')}
              </h5>
            </div>
          </IonCol>
          <IonCol>
            <div className='p-2.5 text-center xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                {t('jar')}
              </h5>
            </div>
          </IonCol>
          <IonCol>
            <div className='p-2.5 text-center xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                {t('tips')}
              </h5>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden p-2.5 text-center sm:block xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                {t('balance')}
              </h5>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden p-2.5 text-center sm:block xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                Feedback
              </h5>
            </div>
          </IonCol>
          <IonCol>
            <div className='hidden p-2.5 text-center sm:block xl:p-5'>
              <h5 className='text-sm font-medium uppercase xsm:text-base'>
                {t('status')}
              </h5>
            </div>
          </IonCol>
        </IonRow>
        {isLoading && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}

        {error != null && <ErrorBox error={error} />}

        {!isLoading && error == null && company != null ? (
          <HandleTableMember company={company} jar={currentJar} />
        ) : (
          <div>Company not found</div>
        )}
      </IonCol>
    </Card>
  )
}
