import {
  newspaperOutline,
  shieldCheckmarkOutline,
  shieldHalfOutline
} from 'ionicons/icons'

export const TERMS = [
  /** Tipped */
  {
    category: 'tipped',
    label: 'setting_dashboard.privacy',
    icon: shieldCheckmarkOutline,
    link: 'https://www.tackpay.net/En/Legals/Privacy%20Policy.pdf'
  },
  {
    category: 'tipped',
    label: 'Cookie',
    icon: shieldHalfOutline,
    link: 'https://www.tackpay.net/En/Legals/Cookie%20Policy.pdf'
  },
  {
    category: 'tipped',
    label: 'setting_dashboard.terms',
    icon: newspaperOutline,
    link: 'https://www.tackpay.net/En/Legals/Terms%20and%20Conditions.pdf'
  },
  /** Business */
  {
    category: 'business',
    label: 'setting_dashboard.privacy',
    icon: shieldCheckmarkOutline,
    link: 'https://www.tackpay.net/En/Legals/Privacy%20Policy.pdf'
  },
  {
    category: 'business',
    label: 'Cookie',
    icon: shieldHalfOutline,
    link: 'https://www.tackpay.net/En/Legals/Cookie%20Policy.pdf'
  },
  {
    category: 'business',
    label: 'setting_dashboard.terms',
    icon: newspaperOutline,
    link: 'https://www.tackpay.net/En/Legals/Terms%20and%20Conditions.pdf'
  },
  /** Manager */
  {
    category: 'manager',
    label: 'setting_dashboard.privacy',
    icon: shieldCheckmarkOutline,
    link: 'https://www.tackpay.net/En/Legals/Privacy%20Policy.pdf'
  },
  {
    category: 'manager',
    label: 'Cookie',
    icon: shieldHalfOutline,
    link: 'https://www.tackpay.net/En/Legals/Cookie%20Policy.pdf'
  },
  {
    category: 'manager',
    label: 'setting_dashboard.terms',
    icon: newspaperOutline,
    link: 'https://www.tackpay.net/En/Legals/Terms%20and%20Conditions.pdf'
  },

  /** Collaborator */
  /** Business */
  {
    category: 'collaborator',
    label: 'setting_dashboard.privacy',
    icon: shieldCheckmarkOutline,
    link: 'https://www.tackpay.net/En/Legals/Privacy%20Policy.pdf'
  },
  {
    category: 'collaborator',
    label: 'Cookie',
    icon: shieldHalfOutline,
    link: 'https://www.tackpay.net/En/Legals/Cookie%20Policy.pdf'
  },
  {
    category: 'collaborator',
    label: 'setting_dashboard.terms',
    icon: newspaperOutline,
    link: 'https://www.tackpay.net/En/Legals/Terms%20and%20Conditions.pdf'
  }
] as const
