import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { getPayoutFee } from './payoutFees'
import { usePayoutContext } from '#contexts/PayoutContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import {
  type UserCategory,
  type BankAccount,
  type BankAccountDeduction
} from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import getErrorMessage from '#utils/getErrorMessage'
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import PayoutIdentityVerification from './PayoutIdentityVerification'
import { useTranslation } from 'react-i18next'
import { getSymbolCurrency } from '#constants/country'
import { useTeamContext } from '#contexts/JarContext'
import Info from '#components/ui/Info'

interface Props {
  /**
   * If modal is visible or not.
   */
  show: boolean
  /**
   * Change status of visibility
   */
  setShow: (show: boolean) => void
  /**
   * Amount to be paid.
   */
  amount: number
  /**
   * Currency of the amount.
   */
  currency: string
  /**
   * The href for the add bank button
   */
  addBankHref?: string

  bankAccount?: BankAccount | null | BankAccountDeduction

  jarId?: string

  userId?: string

  category: UserCategory

  isDeduction?: boolean
}

export default function ModalPayout({
  show,
  setShow,
  amount,
  currency,
  addBankHref,
  bankAccount: bank,
  jarId,
  category,
  userId,
  isDeduction = false
}: Props): JSX.Element {
  const { t } = useTranslation()

  const { refetch } = useTeamContext()

  const { createPayout } = usePayoutContext()

  const MIN_PAYABLE_AMOUNT = [
    {
      currency: 'eur',
      minAmount: 500
    },
    {
      currency: 'cad',
      minAmount: 500
    },
    {
      currency: 'chf',
      minAmount: 500
    },
    {
      currency: 'czk',
      minAmount: 12500
    },
    {
      currency: 'dkk',
      minAmount: 3750
    },
    {
      currency: 'gbp',
      minAmount: 500
    },
    {
      currency: 'huf',
      minAmount: 175000
    },
    {
      currency: 'nzd',
      minAmount: 900
    },
    {
      currency: 'pln',
      minAmount: 2250
    },
    {
      currency: 'ron',
      minAmount: 2400
    },
    {
      currency: 'sek',
      minAmount: 5250
    },
    {
      currency: 'usd',
      minAmount: 500
    },
    {
      currency: 'aud',
      minAmount: 500
    }
  ]

  const { startLoading, stopLoading } = useLoading('payout.create.loading')

  const { showToast: successToast } = useToast('success')

  const { showToast } = useToast('warning')

  const [showVerificationModal, setShowVerificationModal] = useState(false)

  const handleContinue = (): void => {
    const minAmounts = MIN_PAYABLE_AMOUNT.find(
      (min) => min.currency === currency.toLowerCase()
    )

    if (minAmounts == null) {
      showToast(undefined, 'payout.minAmountNotFound')
      return
    }

    if (amount < minAmounts.minAmount) {
      showToast(undefined, 'payout.amountMin.invalid', {
        data: {
          amount: (minAmounts.minAmount / 100).toFixed(2),
          currency: getSymbolCurrency(minAmounts.currency)
        }
      })
    } else {
      if (createPayout != null) {
        setShow(false)
        startLoading()

        createPayout.mutate(
          {
            amount,
            bank_account: isDeduction
              ? undefined
              : {
                  id: bank?.id ?? '',
                  type: 'bank_accounts'
                },
            bank_account_deduction: isDeduction
              ? {
                  id: bank?.id ?? '',
                  type: 'bank_account_deductions'
                }
              : undefined,

            category: isDeduction
              ? 'deduction'
              : userId != null
                ? 'user'
                : 'jar',
            currency,
            user:
              userId != null
                ? {
                    id: userId,
                    type: 'users'
                  }
                : undefined,
            jar:
              jarId != null
                ? {
                    id: jarId,
                    type: 'jars'
                  }
                : undefined
          },
          {
            onError: (error: any) => {
              stopLoading()
              if ('code' in error && error.code === 'payouts_not_allowed') {
                setShow(false)
                setShowVerificationModal(true)
              } else {
                showToast(undefined, getErrorMessage(error))
              }
            },
            onSuccess: () => {
              if (refetch != null && jarId != null) {
                void refetch()
              }
              stopLoading()
              successToast(undefined, 'payout.create.success')
            }
          }
        )
      }
    }
  }

  const payoutFee = getPayoutFee(currency)
  const feeAmount = payoutFee.fee * 100
  const topLimit = payoutFee.topLimit * 100

  return (
    <>
      <PayoutIdentityVerification
        show={showVerificationModal}
        setShow={setShowVerificationModal}
        category={category}
        jarId={jarId}
      />
      <Modal isOpen={show} setShowModal={setShow} id='payout'>
        <IonContent scrollY className='h-full'>
          <IonRow className='ion-justify-content-center ion-align-items-between ion-no-padding ion-no-margin h-full'>
            <IonToolbar color='primary' className='ion-no-padding h-12'>
              <IonTitle>{t('payout.title')}</IonTitle>
              <IonButtons slot='end'>
                <Button
                  className='font-extrabold ion-no-padding'
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  cancel
                </Button>
              </IonButtons>
            </IonToolbar>

            <div className='ion-padding text-center w-full'>
              <Text className='text-gray-800 text-xl font-semibold'>
                payout.modal.title
              </Text>
            </div>
            <IonGrid className='ion-padding'>
              <IonRow className=' justify-between ion-align-items-center'>
                <IonCol>
                  <Text color='dark'>payout.bankAccount</Text>
                </IonCol>
                <IonCol className='text-right'>
                  {bank != null ? (
                    <Text translate='no'>
                      ***********{bank.last_account_number}
                    </Text>
                  ) : (
                    <Text>noBankAccount</Text>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className='justify-between ion-align-items-center'>
                <IonCol>
                  <Text color='dark'>payout.bankName</Text>
                </IonCol>
                <IonCol className='text-right'>
                  {bank != null ? (
                    <Text translate='no'>{bank.name}</Text>
                  ) : (
                    <Text>noBankAccount</Text>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className='justify-between ion-align-items-center'>
                <IonCol>
                  <IonRow className='ion-align-items-center'>
                    <Text color='dark'>payout.fee</Text>
                    <div className='pt-1 pl-1'>
                      <Info
                        title='i'
                        text={t('payout.feeInfo', {
                          amount: `${formattedAmount(topLimit, currency)}`
                        })}
                      />
                    </div>
                  </IonRow>
                </IonCol>
                <IonCol className='text-right'>
                  <Text translate='no'>
                    {formattedAmount(
                      amount <= topLimit ? feeAmount : 0,
                      currency
                    )}
                  </Text>
                </IonCol>
              </IonRow>
              <IonRow className='justify-between ion-align-items-center'>
                <IonCol>
                  <Text color='dark'>payout.amount</Text>
                </IonCol>
                <IonCol className='text-right'>
                  <Text translate='no'>
                    {formattedAmount(amount, currency)}
                  </Text>
                </IonCol>
              </IonRow>
            </IonGrid>
            <hr />

            <IonRow
              className={`${
                bank != null
                  ? 'ion-justify-content-center'
                  : 'ion-justify-content-around'
              } ion-align-items-center ion-no-padding ion-no-margin w-full`}
            >
              {bank?.active === true && (
                <Button
                  className={`${bank?.active ? 'w-5/6 xl:w-3/6 ' : 'w-5.5'}`}
                  onClick={handleContinue}
                >
                  continue
                </Button>
              )}
              {bank == null && (
                <Button
                  className={`${bank == null ? 'w-5/6 xl:w-3/6 ' : 'w-5.5'}`}
                  color='primary'
                  routerLink={addBankHref}
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  addBankAccount
                </Button>
              )}
            </IonRow>
          </IonRow>
        </IonContent>
      </Modal>
    </>
  )
}
