import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Avatar from '#components/ui/Avatar'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useMemberContext } from '#contexts/MemberContext'
import { type UserCategory } from '#tackpay-sdk'
import { IonCol } from '@ionic/react'
import MemberCardInfo from './MemberCardInfo'
import MemberCardStatistic from './MemberCardStatistic'

interface Props {
  category: UserCategory
}

export default function MemberInfoSection({ category }: Props): JSX.Element {
  const { member, isLoading, error } = useMemberContext()

  if (isLoading)
    return (
      <>
        <Skeleton className='w-full h-12' />
        <div className='mt-10'>
          <CardSkeleton />
        </div>
      </>
    )

  if (error != null) return <ErrorBox error={error} />

  if (member == null) return <div>Member is null</div>

  const getName = (): string => {
    if (member?.user?.person == null) {
      return member?.email ?? ''
    }
    return (
      member?.user?.person?.first_name +
      ' ' +
      String(member?.user?.person?.last_name)
    )
  }

  // const showActive = (): boolean => {
  //   return isBusiness(category) && member?.status === 'accepted'
  // }

  return (
    <IonCol>
      <Avatar
        imgOptions={{
          src: member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
        }}
        avatarOptions={{
          className: 'w-24 h-24 mx-auto'
        }}
      />
      <div className='mt-3'>
        <Text
          translate='no'
          color='dark'
          className='font-semibold mx-auto ion-text-center'
        >
          {getName()}
        </Text>
      </div>
      {/* {showActive() && (
        <IonRow className='justify-between mt-1 ion-align-items-center py-5 ion-no-margin ion-no-padding'>
          <Text className='text-lg ' color='dark'>
            members.active
          </Text>
          <Toggle
            checked={member?.active}
            // onIonChange={handleToggle}
            className='ion-no-margin ion-no-padding'
          />
        </IonRow>
      )} */}
      <MemberCardInfo member={member} />
      <hr className='my-8' />
      <MemberCardStatistic
        balance={member.balance}
        totalComment={member.total_comment}
        totalFeedback={member.total_feedback}
        totalTip={member.total_tip}
      />
    </IonCol>
  )
}
