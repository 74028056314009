import RegistrationContainer from '#contexts/RegistrationContext'
import RegistrationCollaboratorCompanyInfo from './RegistrationCollaboratorCompanyInfo'
import RegistrationCollaboratorForm from './RegistrationCollaboratorForm'
import RegistrationCollaboratorSubTitle from './RegistrationCollaboratorSubtitle'

export default function RegistrationCollaborator(): JSX.Element {
  return (
    <RegistrationContainer>
      <RegistrationCollaboratorSubTitle />
      <RegistrationCollaboratorCompanyInfo />
      <RegistrationCollaboratorForm />
    </RegistrationContainer>
  )
}
