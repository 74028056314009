import { IonSearchbar } from '@ionic/react'
import { type ComponentProps } from 'react'

interface SearchBarProps extends ComponentProps<typeof IonSearchbar> {}

export type SearchBarOnChange = ComponentProps<
  typeof IonSearchbar
>['onIonChange']
export type SearchBarValue = ComponentProps<typeof IonSearchbar>['value']

export default function SearchBar(props: SearchBarProps): JSX.Element {
  return <IonSearchbar {...props} />
}
