import { IonAvatar, IonImg } from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps } from 'react'

interface AvatarProps {
  /**
   * The avatar options
   */
  avatarOptions?: ComponentProps<typeof IonAvatar>
  /**
   * The image options
   */
  imgOptions?: ComponentProps<typeof IonImg>
  /**
   * The test id
   */
  testId?: string
  /**
   * Show shadow
   * @default true
   */
  shadow?: boolean
}

export default function Avatar(props: AvatarProps): JSX.Element {
  const { avatarOptions, imgOptions, testId, shadow } = props
  const className = classNames(
    avatarOptions?.className,
    shadow === true ? 'shadow' : '',
    ''
  )
  return (
    <IonAvatar {...avatarOptions} className={className}>
      <IonImg {...imgOptions} data-testid={testId} />
    </IonAvatar>
  )
}
