import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Payment } from '#tackpay-sdk'
import { IonCardHeader, IonCardTitle } from '@ionic/react'

interface Props {
  payment?: Payment | null
  isLoading: boolean
  error?: any
}

export default function PaymentCardFeedback({
  payment,
  isLoading,
  error
}: Props): JSX.Element {
  if (isLoading) return <CardSkeleton hasAvatar={false} />

  if (error != null) return <ErrorBox error={error} />

  return (
    <Card className=''>
      <IonCardHeader>
        <IonCardTitle>🎉 Feedback</IonCardTitle>
      </IonCardHeader>
      <div className='ion-padding ion-margin'>
        {payment?.feedbacks != null && payment?.feedbacks.length > 0 ? (
          payment?.feedbacks?.map((feedback) => (
            <Button key={feedback.id} fill='outline' color='green-500'>
              {`feedback.${feedback.name}`}
            </Button>
          ))
        ) : (
          <Text>noFeedback</Text>
        )}
      </div>
    </Card>
  )
}
