import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import ModalLoading from '#components/ui/ModalLoading'
import Text from '#components/ui/Text'
import { TUTORIAL_STEPS, type TutorialStep } from '#constants/tutorial'

import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import useFCMToken from '#hooks/useFCMToken'
import { type UserCategory } from '#tackpay-sdk'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { arrowForwardOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Navigation, Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'

interface Props {
  category: UserCategory
}
export default function TutorialContent({ category }: Props): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  const { user } = useUserContext()

  const [indexTutorials, setIndexTutorials] = useState<boolean>(false)

  const { getToken, setTokenOnServer } = useFCMToken()

  const handleGetFCMToken = async (): Promise<void> => {
    try {
      const token = await getToken()
      if (token != null) {
        await setTokenOnServer(user?.subscriber?.id ?? '', token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      (user?.subscriber?.device_tokens == null ||
        user?.subscriber?.device_tokens?.length <= 0) &&
      user != null
    ) {
      void handleGetFCMToken()
    }
  }, [user?.subscriber?.device_tokens])

  const isTipped = category === 'tipped'

  if (!isTipped && isLoading) return <ModalLoading />
  if (!isTipped && error != null) return <ErrorBox error={error} />

  const getTutorialSteps = (): TutorialStep[] => {
    

    let tutorialSteps = TUTORIAL_STEPS.filter(
      (step) => step.category === category
    )

    if (!isTipped) {
      tutorialSteps = tutorialSteps.filter(
        (step) =>
          step.teamRole?.includes(category === 'business' ? 'private' : 'shared')
      )
    }

    return tutorialSteps
  }

  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides
        modules={[Pagination, Navigation]}
        navigation={{
          enabled: true,
        }}
        pagination={{
          dynamicBullets: true
        }}
        onSlideChange={(swi) => {
          setIndexTutorials(swi.isEnd)
        }}
        
      >
        {getTutorialSteps().map((step) => {
          return (
            <SwiperSlide className='my-5' key={step.name}>
              <IonGrid className='ion-padding ion-text-center'>
                <IonRow className='h-80'>
                  <IonCol>
                    <IonRow className='ion-justify-content-center w-full ion-no-padding'>
                      {step.img}
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-justify-content-center ion-text-center'>
                  <Text
                    className='font-extrabold'
                    color='gray-900'
                    size='text-4xl'
                  >
                    {step.name}
                  </Text>
                </IonRow>
                <IonRow className='ion-justify-content-center pt-6'>
                  <Text
                    className='font-semibold'
                    color='gray-500'
                    size='text-xl'
                  >
                    {step.description}
                  </Text>
                </IonRow>
              </IonGrid>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {indexTutorials ? (
        <IonRow className='ion-justify-content-center pt-6'>
          <Button
            fill='clear'
            iconOptions={{
              src: arrowForwardOutline
            }}
            size='large'
            routerLink={`/dashboard/${category}/home`}
          >
            <Text
              className='text-center'
              fontWeight='font-medium'
              size='text-xl'
            >
              continue
            </Text>
          </Button>
        </IonRow>
      ) : null}
      {!indexTutorials ? (
        <IonRow className='w-full mt-5 ion-justify-content-center'>
          <IonCol size='5'>
            <Text
              className='text-center'
              fontWeight='font-medium'
              size='text-xl'
            >
              swipe
            </Text>
          </IonCol>
        </IonRow>
      ) : null}
    </>
  )
}
