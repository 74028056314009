import AppTypeahead from '#components/ui/AppTypeHead'
import Label from '#components/ui/Label'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { BANK_COUNTRIES } from '#constants/country'
import uniqueArray from '#utils/uniqueArray'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  currency: string
  setSelectionCurrency: (currency: string) => void
}
export default function AddBankCurrency({
  currency,
  setSelectionCurrency
}: Props): JSX.Element {
  const { t } = useTranslation()

  const [showCurrency, setShowCurrency] = useState(false)
  const getListCurrency = (): string[] => {
    return uniqueArray(BANK_COUNTRIES.map((c) => c.currency_name)).sort(
      (a, b) => a.localeCompare(b)
    )
  }
  const isEmptyCurrency = (): boolean => {
    return currency === ''
  }

  const handleSelectionCurrency = (currency: string): void => {
    setSelectionCurrency(currency)
    setShowCurrency(false)
  }
  return (
    <>
      <Modal
        isOpen={showCurrency}
        setShowModal={setShowCurrency}
        data-testid='modal_choose_currency'
      >
        <AppTypeahead
          title={`${t('select_currency')}`}
          items={getListCurrency().map((currency) => {
            return {
              text: currency,
              value: currency
            }
          })}
          selectedItems=''
          onSelectionCancel={() => {
            setShowCurrency(false)
          }}
          onSelectionChange={handleSelectionCurrency}
        />
      </Modal>
      <IonCol>
        <Label
          position='fixed'
          className='block mb-1 text-sm font-medium text-gray-900  capitalize'
        >
          currency
        </Label>
        <div
          data-testid='selected_currency'
          className='bg-white border border-gray-300 rounded-lg p-2'
          onClick={() => {
            setShowCurrency(true)
          }}
        >
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <Text
              color={isEmptyCurrency() ? 'gray-500' : 'gray-900'}
              className='pl-1'
            >
              {isEmptyCurrency() ? `${t('select_currency')}` : currency}
            </Text>
            <IonIcon src={chevronDownOutline} className='' />
          </IonRow>
        </div>
      </IonCol>
    </>
  )
}
