import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import { useStatisticAmountContext } from '#contexts/StatisticAmountContext'
import { useUserContext } from '#contexts/UserContext'
import isDesktop from '#utils/isDesktop'
import { isDonation } from '#utils/isDonation'
import EmptyStatisticAmount from './EmptyStatisticAmount'
import StatisticAmountCard from './StatisticAmountCard'

export default function StatisticAmountComponents(): JSX.Element {
  const { statisticAmount, error, isLoading } = useStatisticAmountContext()

  const { user } = useUserContext()

  if (isLoading) return <Skeleton className='h-56' />

  if (error != null) return <ErrorBox error={error} />

  if (statisticAmount == null) {
    if (isDesktop()) {
      return (
        <Card>
          <EmptyStatisticAmount isDonation={isDonation(user?.id)} />
        </Card>
      )
    } else return <EmptyStatisticAmount isDonation={isDonation(user?.id)} />
  }

  return <StatisticAmountCard showCard statisticAmount={statisticAmount} />
}
