import SelectTeam from '#components/common/TeamSelection'
import Text from '#components/ui/Text'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { type Jar } from '#tackpay-sdk'
import { IonCol } from '@ionic/react'
import { useEffect, useState } from 'react'

interface Props {
  jars?: Jar[]
}

export default function CheckoutSettingTeamSelection({
  jars
}: Props): JSX.Element {
  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  const [currentTeams, setCurrentTeams] = useState<Jar[] | undefined>(jars)

  useEffect(() => {
    if (checkoutSetting?.show_jars != null && jars != null) {
      setCurrentTeams(
        jars?.filter((jar) => checkoutSetting?.show_jars?.includes(jar.id))
      )
    }
  }, [checkoutSetting, jars])

  const handleSetCurrentTeams = (jar: Jar | Jar[]): void => {
    jar = Array.isArray(jar) ? jar : [jar]

    setCurrentTeams(jar)

    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        show_jars: jar.map((t) => t.id)
      })
    }
  }

  return (
    <div slot='content' className='pt-1 space-y-4'>
      <Text size='text-base' data-testid='subtitle-socials'>
        checkoutSetting.selectTeam.subtitle
      </Text>

      <IonCol className='block my-5'>
        <SelectTeam
          jars={jars}
          currentTeam={currentTeams}
          setCurrentTeam={handleSetCurrentTeams}
          multiple
        />
      </IonCol>
    </div>
  )
}
