import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import { type InputOnChange, type InputValue } from '#components/ui/Input'
import Text from '#components/ui/Text'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import { IonRow } from '@ionic/react'
import React, { useState } from 'react'
import InputPasswordTest from '#components/ui/InputPasswordTest'

export default function CardChangeUserData(): JSX.Element {
  const { editUser, user } = useUserContext()

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const { startLoading, stopLoading } = useLoading('passwordChanging')

  const [newPassword, setNewPassword] = useState<InputValue>()

  const handleInputChange: InputOnChange = (event) => {
    const { name, value } = event.target

    if (name === 'newpassword' && typeof value === 'string')
      setNewPassword(value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (newPassword == null || typeof newPassword !== 'string') {
      showToast(undefined, 'passwordEnter')
      return
    }

    if (editUser != null) {
      startLoading()

      editUser.mutate(
        {
          id: user?.id ?? '',
          password: newPassword ?? undefined
        },
        {
          onSuccess: () => {
            stopLoading()
            successToast(undefined, 'passwordChanged')
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }

  return (
    <Card>
      <Text color='gray-900' fontWeight='font-semibold' size='text-base'>
        Change Password
      </Text>
      <form className='space-y-4 mt-4' onSubmit={handleSubmit}>
        <div>
          <InputPasswordTest
            name='newpassword'
            labelName='Nuova password'
            password={newPassword}
            onPasswordChange={handleInputChange}
          />
        </div>
        <IonRow className='w-full ion-no-margin ion-no-padidng'>
          <Button type='submit' className='w-full ion-no-margin ion-no-padding'>
            confirm
          </Button>
        </IonRow>
      </form>
    </Card>
  )
}
