import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { IonCol, IonImg, IonRow } from '@ionic/react'
import WalletAvailableBalance from '../wallets/card/AvailableBalance'
import WalletPendingBalance from '../wallets/card/PendingBalance'
// import Button from '#components/ui/Button'
import Info from '#components/ui/Info'
import { type Jar, type UserCategory } from '#tackpay-sdk'
import { useEffect, useState } from 'react'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type BalanceDeduction } from 'sdk/resources/balanceDeductions'

interface Props {
  category: UserCategory
}
export default function CardBalanceDeduction({ category }: Props): JSX.Element {
  const { isLoading, error, company } = useCompanyContext()

  const [balance, setBalance] = useState<BalanceDeduction>()

  const getPending = (jar: Jar[]): number => {
    return jar.reduce((acc, jar) => {
      if (jar.balance_deduction != null) {
        return acc + jar.balance_deduction.pending.amount_cents
      }
      return acc
    }, 0)
  }

  const getAvailable = (jar: Jar[]): number => {
    return jar.reduce((acc, jar) => {
      if (jar.balance_deduction != null) {
        return acc + jar.balance_deduction.available.amount_cents
      }
      return acc
    }, 0)
  }

  useEffect(() => {
    if (company?.jars != null) {
      const pendingAmountCents = getPending(company.jars)
      const availableAmountCents = getAvailable(company.jars)
      const totalAmountCents = pendingAmountCents + availableAmountCents

      setBalance({
        total: {
          amount_cents: totalAmountCents,
          currency: company.currency,
          amount_float: Number((totalAmountCents / 100).toFixed(2)),
          amount_formatted: (totalAmountCents / 100).toFixed(2)
        },
        pending: {
          amount_cents: pendingAmountCents,
          currency: company.currency,
          amount_float: Number((pendingAmountCents / 100).toFixed(2)),
          amount_formatted: (pendingAmountCents / 100).toFixed(2)
        },
        available: {
          amount_cents: availableAmountCents,
          currency: company.currency,
          amount_float: Number((availableAmountCents / 100).toFixed(2)),
          amount_formatted: (availableAmountCents / 100).toFixed(2)
        },
        type: 'balance_deductions',
        id: 'balance_deductions'
      })
    }
  }, [company?.jars])

  return (
    <Card
      className='diagonal-split-background shadow md:w-9/12 md:mx-auto'
      data-testid='balance-available'
    >
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonCol size='6' className='ion-no-padding space-y-5'>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <Text
                  className='text-xl '
                  color='gray-900'
                  fontWeight='font-bold'
                >
                  deduction.balance_total
                </Text>
              </IonRow>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <WalletAvailableBalance
                  loading={isLoading}
                  balance={balance}
                  error={error}
                  useTotalBalance
                  currency={company?.currency}
                />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRow className='w-full ion-no-margin ion-no-padding ion-align-items-center space-x-2'>
                <Text
                  className=''
                  color='gray-900'
                  size='text-base'
                  data-testid='balance-pending'
                >
                  wallet.balance.pending
                </Text>
                <Info title='i' text='pending_balance_info' />
              </IonRow>

              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <WalletPendingBalance
                  loading={isLoading}
                  balance={balance}
                  error={error}
                  currency={company?.currency}
                  isTronc
                />
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='4' className='ion-no-padding space-y-10'>
          <IonRow className='ion-justify-content-center'>
            <IonImg src='/assets/img/line-card.svg' />
          </IonRow>
          <IonRow className='ion-justify-content-center ion-no-padding ion-no-margin'>
            {/* <Button
              color='dark'
              className='h-7'
              routerLink={`/dashboard/${category}/deductions/payouts`}
              routerDirection='root'
              disabled={isLoading}
              data-testid='goto-withdrawals'
            >
              withdrawal.title
            </Button> */}
          </IonRow>
        </IonCol>
      </IonRow>
    </Card>
  )
}
