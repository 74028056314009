import axios from 'axios'
import type {
  AxiosAdapter,
  CreateAxiosDefaults,
  AxiosInstance,
  AxiosProxyConfig,
  Method
} from 'axios'
import { handleError } from './error'
import type { InterceptorManager } from './interceptor'
import config from './config'
import type { Agent as HttpAgent } from 'http'
import type { Agent as HttpsAgent } from 'https'

const baseURL = (path: string = '/api/v1'): string => {
  return `${config.default.protocol}://${config.default.domain}${path}`
}

type ProxyConfig = AxiosProxyConfig | false
type Adapter = AxiosAdapter

type RequestParams = Record<string, string | number | boolean>
type RequestHeaders = Record<string, string>

// Subset of AxiosRequestConfig
interface RequestConfig {
  timeout?: number
  params?: RequestParams
  httpAgent?: HttpAgent
  httpsAgent?: HttpsAgent
  proxy?: ProxyConfig
  headers?: RequestHeaders
  forceList?: boolean
}

interface ApiConfig {
  isJsonApi?: boolean
  organization?: string
  path?: string
  accessToken?: string
}

type ApiClientInitConfig = ApiConfig & RequestConfig & { adapter?: Adapter }
type ApiClientConfig = Partial<ApiClientInitConfig>

class ApiClient {
  static create(options: ApiClientInitConfig): ApiClient {
    // for (const attr of config.client.requiredAttributes)
    //   if (options == null)
    //     throw new SdkError({ message: `Undefined '${attr}' parameter` })
    return new ApiClient(options)
  }

  baseUrl: string
  #accessToken: string | undefined
  readonly #client: AxiosInstance

  public interceptors: InterceptorManager

  private constructor(options: ApiClientInitConfig) {
    // console.log('new client instance %O', options)

    this.baseUrl = baseURL(options.path)

    this.#accessToken = options.accessToken

    const axiosConfig: RequestConfig = {
      timeout: options.timeout ?? config.client.timeout,
      proxy: options.proxy,
      httpAgent: options.httpAgent,
      httpsAgent: options.httpsAgent
    }

    // Set custom headers
    const customHeaders = this.customHeaders(options.headers)

    const axiosOptions: CreateAxiosDefaults = {
      baseURL: this.baseUrl,
      timeout: config.client.timeout,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: 'Bearer ' + this.#accessToken,
        'Access-Control-Allow-Origin': '*',
        ...customHeaders
      },
      ...axiosConfig
    }

    if (options.adapter != null) axiosOptions.adapter = options.adapter

    // console.log('axios options: %O', axiosOptions)

    this.#client = axios.create(axiosOptions)

    this.interceptors = this.#client.interceptors
  }

  config(config: ApiClientConfig): this {
    const def = this.#client.defaults

    // Axios config
    if (config.timeout == null) def.timeout = config.timeout
    if (config.proxy == null) def.proxy = config.proxy
    if (config.httpAgent != null) def.httpAgent = config.httpAgent
    if (config.httpsAgent != null) def.httpsAgent = config.httpsAgent

    // API Client config
    if (config.organization != null) this.baseUrl = baseURL(config.path)
    if (config.accessToken != null) {
      this.#accessToken = config.accessToken
      def.headers.common.Authorization = 'Bearer ' + this.#accessToken
    }
    if (config.headers != null)
      def.headers.common = this.customHeaders(config.headers)
    if (config.adapter != null) this.adapter(config.adapter)

    return this
  }

  adapter(adapter: Adapter): this {
    if (adapter != null) this.#client.defaults.adapter = adapter
    return this
  }

  async request(
    method: Method,
    path: string,
    body?: any,
    options?: ApiClientConfig
  ): Promise<any> {
    // console.log(
    //   'request %s %s, %O, %O',
    //   method,
    //   path,
    //   body ?? {},
    //   options != null || {}
    // )

    const data =
      body != null
        ? options?.isJsonApi === false
          ? { ...body }
          : { data: body }
        : {}

    const url = path

    // Runtime request parameters
    const baseUrl = baseURL(options?.path)

    const accessToken = options?.accessToken ?? this.#accessToken

    const headers = this.customHeaders(options?.headers)
    if (accessToken != null) headers.Authorization = 'Bearer ' + accessToken

    const requestParams = {
      method,
      baseURL: baseUrl,
      url,
      data,
      ...options,
      headers
    }

    console.log('request params: %O', requestParams)

    // const start = Date.now()
    return await this.#client
      .request(requestParams)
      .then((response) => response.data)
      .catch((error) => handleError(error))
    // .finally(() => console.log(`<<-- ${method} ${path} ${Date.now() - start}`))
  }

  private customHeaders(headers?: RequestHeaders): RequestHeaders {
    const customHeaders: RequestHeaders = {}
    if (headers != null) {
      for (const [name, value] of Object.entries(headers))
        customHeaders[name] = value
    }

    return customHeaders
  }
}

export default ApiClient

export type { ApiClientInitConfig, ApiClientConfig, RequestConfig }
