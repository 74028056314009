import AuthLayout from '#components/layouts/auth/AuthLayout'
import Avatar from '#components/ui/Avatar'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'

const teams = [
  {
    id: '1',
    name: 'Tips to the company',
    img: '/assets/img/bar.jpg',
    url: 'bar'
  },
  {
    id: '2',
    name: 'Tips directly to the staff',
    img: '/assets/img/restaurant2.jpg',
    url: 'restaurant'
  }
]

export default function DemoCompanyUsa(): JSX.Element {
  const { push } = useIonRouter()

  const handleTeamSelected = (team: string): void => {
    push(`/demo/usa/${team}?from=company`)
  }

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true,
        isUsa: true
      }}
    >
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src: '/assets/img/hotel.jpg'
            }}
            avatarOptions={{
              className: 'border-4 mx-auto h-24 w-24 border-green-400'
            }}
          />
          <div className='mt-8'>
            <Underlined name='Your company' />
          </div>
          <IonRow className='pt-8 ion-justify-content-center'>
            <Text className='text-gray-600 text-center'>Your bio</Text>
          </IonRow>
        </IonCol>
        <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
      </IonRow>
      <Text size='text-base' className='ion-text-center'>
        Which option works best for you?
      </Text>
      <IonRow className='w-full ion-justify-content-center ion-align-items-center mt-10 space-x-5'>
        {teams.map((jar) => (
          <IonCol
            key={jar.id}
            size='5'
            className='ion-text-center'
            onClick={() => {
              handleTeamSelected(jar.url)
            }}
          >
            <Avatar
              avatarOptions={{
                className: `border-4 mx-auto h-24 w-24`
              }}
              imgOptions={{
                src: jar.img
              }}
            />
            <IonRow className='w-full ion-justify-content-center ion-no-padding ion-no-margin '>
              <Text
                className='text-black'
                fontWeight='font-bold'
                size='text-lg'
                translationOptions={{
                  translate: 'no'
                }}
              >
                {jar.name}
              </Text>
            </IonRow>
          </IonCol>
        ))}
      </IonRow>
    </AuthLayout>
  )
}
