import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId,
  type ListResponse
} from 'sdk/resource'
import { type User } from './users'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type BranchCompany } from './branchCompanies'
import { type TackpayId } from './tackpayIds'
import { type CheckoutSetting } from './checkoutSettings'
import { type Collaborator } from './collaborators'
import { type Balance } from './balances'
import { type Customer } from './customers'
import { type CheckoutSession } from './checkoutSessions'
import { type Jar } from './jars'
import { type StripeGatewayAccount } from './stripeGatewayAccounts'
import { type BankAccount } from './bankAccounts'

type CompanyType = 'companies'
type CompanyRel = ResourceRel & { type: CompanyType }

type UserRel = ResourceRel & { type: 'users' }

interface Company extends Resource {
  name: string
  total_collaborator_admin: number
  total_collaborator_manager: number
  total_collaborator: number
  total_team: number
  description?: string
  currency: string
  category?: string
  phone?: string
  logo?: string
  email: string
  user?: User
  branch_companies?: BranchCompany[]
  tackpay_id?: TackpayId
  checkout_setting?: CheckoutSetting
  collaborators?: Collaborator[]
  balance?: Balance
  customer?: Customer
  checkout_sessions?: CheckoutSession[]
  jars?: Jar[]
  stripe_gateway_account?: StripeGatewayAccount
  bank_accounts?: BankAccount[]
}

interface CompanyCreate extends ResourceCreate {
  name: string
  description?: string
  currency: string
  category?: string
  phone?: string
  logo?: string
  email: string
  user?: UserRel
  commercial_code?: string
}

interface CompanyUpdate extends ResourceUpdate {
  name?: string
  description?: string
  category?: string
  phone?: string
  logo?: string
  email?: string
}

class Companies extends ApiResource<Company> {
  static readonly TYPE: CompanyType = 'companies' as const

  async create(
    resource: CompanyCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Company> {
    return await this.resources.create<CompanyCreate, Company>(
      { ...resource, type: Companies.TYPE },
      params,
      options
    )
  }

  async update(
    resource: CompanyUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Company> {
    return await this.resources.update<CompanyUpdate, Company>(
      { ...resource, type: Companies.TYPE },
      params,
      options
    )
  }

  async balance(
    companyId: string | Company,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Balance> {
    const _companyId = (companyId as Company).id ?? companyId
    return this.resources.fetch<Balance>(
      { type: 'balances' },
      `companies/${_companyId}/balance`,
      params,
      options
    ) as unknown as Balance
  }

  async checkout_setting(
    companyId: string | Company,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSetting> {
    const _companyId = (companyId as Company).id ?? companyId
    return this.resources.fetch<CheckoutSetting>(
      { type: 'checkout_settings' },
      `companies/${_companyId}/checkout_setting`,
      params,
      options
    ) as unknown as CheckoutSetting
  }

  async jars(
    companyId: string | Company,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<ListResponse<Jar>> {
    const _companyId = (companyId as Company).id ?? companyId
    return this.resources.fetch<Jar>(
      { type: 'jars' },
      `companies/${_companyId}/jars`,
      params,
      options
    ) as unknown as ListResponse<Jar>
  }

  isCompany(resource: any): resource is Company {
    return resource.type != null && resource.type === Companies.TYPE
  }

  relationship(id: string | ResourceId | null): CompanyRel {
    return id === null || typeof id === 'string'
      ? { id, type: Companies.TYPE }
      : { id: id.id, type: Companies.TYPE }
  }

  type(): CompanyType {
    return Companies.TYPE
  }
}

export type { Company, CompanyCreate, CompanyUpdate }
export default Companies
