import Text from '#components/ui/Text'
import {
  type BankAccount,
  type Balance,
  type UserCategory,
  type BankAccountDeduction,
  type BalanceDeduction
} from '#tackpay-sdk'
import { IonRow } from '@ionic/react'
import PayoutBalanceAvailable from './PayoutBalanceAvailable'
import PayoutBalancePending from './PayoutBalancePending'
import PayoutCollectButton from '../collect/PayoutCollectBalance'

interface Props {
  /**
   * The balance object
   */
  balance?: Balance | null | BalanceDeduction
  /**
   * The href for the collect button
   */
  addBankHref: string
  /**
   * The loading state
   */
  loading?: boolean
  /**
   * The error object
   */
  error?: any
  /**
   * The jar id
   */
  jarId?: string

  bankAccount?: BankAccount | null | BankAccountDeduction

  userId?: string

  category: UserCategory

  isDeduction?: boolean
}

export default function PayoutBalanceSection({
  balance,
  addBankHref,
  loading,
  error,
  jarId,
  bankAccount,
  userId,
  category,
  isDeduction = false
}: Props): JSX.Element {
  return (
    <div>
      <Text fontWeight='font-semibold' data-testid='balance-available'>
        payout.balance.available
      </Text>
      <IonRow className='pt-4 ion-align-items-center'>
        <PayoutBalanceAvailable
          balance={balance}
          loading={loading}
          error={error}
        />
        <PayoutBalancePending
          balance={balance}
          loading={loading}
          error={error}
        />
      </IonRow>
      <div className='mt-24'>
        <PayoutCollectButton
          addBankHref={addBankHref}
          jarId={jarId}
          balance={balance}
          bankAccount={bankAccount}
          userId={userId}
          category={category}
          isDeduction={isDeduction}
        />
      </div>
    </div>
  )
}
