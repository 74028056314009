import Link from '#components/ui/Link'
import { useTranslation } from 'react-i18next'

export default function SubTitleModal(): JSX.Element {
  const { t } = useTranslation()
  return (
    <p data-testid='title-comfirm'>
      {t('registration.terms.confirm_1')}
      <span>
        <Link link='https://tackpay.net'>
          <span
            className='ml-1 underline cursor-pointer text-gray-700'
            data-testid='name-tackpay'
          >
            TackPay
          </span>
        </Link>
      </span>
      ,
      <span>
        <Link link='https://stripe.com/connect-account/legal'>
          <span
            className='ml-1 underline cursor-pointer text-gray-700'
            data-testid='name-stripe'
          >
            Stripe
          </span>
        </Link>
      </span>{' '}
      {t('registration.terms.confirm_2')}
      <span>
        <Link link='https://stripe.com/connect-account/legal'>
          <span className='ml-1 underline cursor-pointer text-gray-700'>
            {t('registration.terms.confirm_3')}
          </span>
        </Link>
      </span>{' '}
      {t('e')}
    </p>
  )
}
