import AuthLayout from '#components/layouts/auth/AuthLayout'
import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import {
  downloadOutline,
  logoFacebook,
  logoInstagram,
  logoTwitter
} from 'ionicons/icons'

export default function DemoEnd(): JSX.Element {
  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
    >
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src: '/assets/img/pool.svg'
            }}
            avatarOptions={{
              className: ' border-4 mx-auto h-28 w-28 border-green-400'
            }}
          />
          <Underlined name='Pool' />
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-center mt-8'>
        <Text>default_reply</Text>
      </IonRow>
      <IonGrid className='mt-10'>
        <IonRow className='ion-justify-content-center ion-align-items-center space-x-4'>
          <IonIcon
            src={logoFacebook}
            className='text-blue-500  cursor-pointer'
            size='large'
            onClick={() =>
              window.open(`https://facebook.com/tackpay`, '_blank')
            }
          />

          <IonIcon
            src={logoInstagram}
            className='text-pink-400 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(`https://instagram.com/tackpay`, '_blank')
            }
          />

          <IonIcon
            src={logoTwitter}
            className='text-blue-400 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(`https://twitter.com/tackpay}`, '_blank')
            }
          />

          <IonIcon
            src='/assets/img/tripadvisor.svg'
            size='large'
            className='text-blue-400 cursor-pointer'
            onClick={() => window.open('https://tripadvisor.com', '_blank')}
          />

          <IonIcon
            src='/assets/img/web-icon.svg'
            className='text-gray-600 cursor-pointer'
            size='large'
            onClick={() => window.open('https://www.tackpay.net', '_blank')}
          />
        </IonRow>
      </IonGrid>
      <IonRow className='mt-20'>
        <Button className='w-full' href='https://tackpay.net' target='_blank'>
          visitWebSite
        </Button>
      </IonRow>
      <IonRow className='mt-40 ion-justify-content-center ion-align-items-center'>
        <Button
          className='underline ion-no-padding'
          color='dark'
          fill='clear'
          iconOptions={{ icon: downloadOutline }}
        >
          checkout.bill.title
        </Button>
      </IonRow>
    </AuthLayout>
  )
}
