import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import AuthLayout from '#components/layouts/auth/AuthLayout'
import ProgressBar from '#components/ui/ProgessBar'
import CardCategory from '#components/common/CardCategory'
import ButtonStep from '#components/common/ButtonStep'
import { type TippingRule } from '#tackpay-sdk'
import { useIonRouter } from '@ionic/react'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import Chip from '#components/ui/Chip'

export default function RegistrationTippingRule(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  const { jarData, setJarData } = useRegistrationContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleTippingRuleSelected = (tippingRule: TippingRule): void => {
    setJarData({ tipping_rule: tippingRule })
    push(`/registrations/${category}/smarts`)
  }

  const handlePrevious = (): void => {
    push(`/registrations/${category}/types`, 'back')
  }

  const handleNext = (): void => {
    if (textUtil.isEmpty(jarData?.tipping_rule)) {
      showToast(undefined, 'registration.chips.tippingRule.error')
      return
    }

    if (jarData?.jar_type === 'shared') {
      push(`/registrations/${category}/smarts`)
    } else {
      push(`/registrations/${category}/persons`)
    }
  }

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: `/registrations/${category}/types`
        }
      }}
    >
      <ProgressBar
        text='registration.chips.tippingRule.title'
        value={0.7}
        testId='progress-registration.chips.address.title'
      />
      <div className='pt-10 space-y-6'>
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleTippingRuleSelected('equal_split')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-equal.png',
              alt: 'tippingEqual'
            }
          }}
          title='equal_split'
          description='teamPage.equalSplit.description'
          showCheckIcon={jarData?.tipping_rule === 'equal_split'}
        />
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleTippingRuleSelected('individual')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-individual.webp',
              alt: 'tippingIndividual'
            }
          }}
          title='individual'
          description='teamPage.individual.description'
          showCheckIcon={jarData?.tipping_rule === 'individual'}
        />

        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleTippingRuleSelected('tronc')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-individual.webp',
              alt: 'tippingTronc'
            }
          }}
          title='tronc'
          description='teamPage.tronc.description'
          showCheckIcon={jarData?.tipping_rule === 'tronc'}
          showCustomIcon
          icon={
            <Chip
              color='primary'
              className='text-white bg-green-400 mx-auto transform scale-75 md:scale-100 absolute top-3 right-0 md:right-5 md:top-10'
            >
              eu_only
            </Chip>
          }
        />

        <ButtonStep handleBack={handlePrevious} handleNext={handleNext} />
      </div>
    </AuthLayout>
  )
}
