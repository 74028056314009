import Empty from '#components/ui/Empty'

interface Props {
  isTransferTronc?: boolean
  isDonation?: boolean
}
export default function EmptyPayment({
  isTransferTronc = false,
  isDonation = false
}: Props): JSX.Element {
  return (
    <Empty
      imgOptions={{
        src: '/assets/img/empty-payment.svg',
        alt: 'emptyPayment',
        className: 'w-4/12 md:w-1/6 mx-auto'
      }}
      textOptions={{
        text: isTransferTronc
          ? 'wallet.payments.empty_tronc'
          : isDonation
            ? 'empty_donations'
            : 'wallet.payments.empty',
        props: {
          className:
            'text-center text-base italic text-gray-600 mt-6 font-semibold'
        }
      }}
    />
  )
}
