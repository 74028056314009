import Text from '#components/ui/Text'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import { animationsFade } from '#utils/animations'
import {
  IonCard,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'
import { useRef, useState } from 'react'

type TermsTitle = 'marketing' | 'marketing3' | 'profillation'

interface Terms {
  currentTerms: TermsTitle | undefined
  showTermInfo: (terms: TermsTitle) => void
}

export default function TermsMarketing3({
  currentTerms,
  showTermInfo
}: Terms): JSX.Element {
  const { userData, setUserData } = useRegistrationContext()

  const labelTerms = useRef<HTMLIonCardElement | null>(null)

  const [showLabel, setShowLabel] = useState<boolean>(false)

  const showLabelControl = (show: boolean): void => {
    setShowLabel(!show)

    showTermInfo('marketing3')

    if (!show) {
      animationsFade({
        typeAnimation: labelTerms,
        start: '-45px',
        end: '0'
      })
    }
    if (show) {
      animationsFade({
        typeAnimation: labelTerms,
        start: '0',
        end: '-45px',
        duration: 500
      })
    }
  }
  return (
    <div>
      <IonGrid className='ion-no-padding ion-no-margin'>
        <IonRow className='ion-justify-content-between ion-align-items-center space-x-2'>
          <IonCol size='1'>
            <IonCheckbox
              labelPlacement='end'
              className='square transform scale-75'
              value={userData?.marketing_third_party}
              checked={userData?.marketing_third_party}
              onIonChange={(e) => {
                setUserData({ marketing_third_party: e.detail.checked })
              }}
            />
          </IonCol>
          <IonCol>
            <Text
              className='italic font-semibold  leading-relaxed'
              color='gray-900'
              size='text-sm'
            >
              registration.terms.marketing3
            </Text>
          </IonCol>
          <IonCol size='1'>
            <IonIcon
              src={showLabel ? chevronUpOutline : chevronDownOutline}
              className='transform scale-150 cursor-pointer'
              onClick={() => {
                showLabelControl(showLabel)
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonCard
        ref={labelTerms}
        className=' ml-8 ion-no-margin ion-no-padding shadow-none'
      >
        <Text
          data-testid='info-marketing'
          size='text-sm'
          className={showLabel ? 'visible' : 'hidden'}
        >
          registration.terms.marketing3Info
        </Text>
      </IonCard>
    </div>
  )
}
