import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { useUserContext } from '#contexts/UserContext'
import { type Jar } from '#tackpay-sdk'
import formattedDate from '#utils/formattedDate'
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface Props {
  jar?: Jar | null
  createdAt?: string
}
export default function MemberTeamCardInfo({
  jar,
  createdAt
}: Props): JSX.Element {
  const { user } = useUserContext()

  const { t } = useTranslation()

  const getTackpayId = (): string => {
    if (jar?.tackpay_ids != null) {
      if (!Array.isArray(jar?.tackpay_ids)) {
        return jar?.tackpay_ids?.value ?? 'none'
      } else if (jar?.tackpay_ids.length > 0) {
        return jar?.tackpay_ids[0]?.value ?? 'none'
      }
    }
    return 'none'
  }

  const getTotalMemberActive = (): number => {
    return jar?.members?.filter((member) => member.active).length ?? 0
  }
  return (
    <Card className='w-full mt-5'>
      <IonCardHeader>
        <IonCardTitle>{t('tipped_jar_page_jar_info')}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className='ion-no-padding'>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <Text className='text-gray-600'>members.subcategory</Text>
            </IonCol>
            <IonCol className='text-right ion-no-margin ion-no-padding'>
              <Text color='dark' translate='no' className='font-semibold'>
                {jar?.category ?? 'jar'}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600' translate='no'>
                Tackpay ID
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {getTackpayId()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <Text className='text-gray-600' translate='no'>
                teamSetting.tippingRule
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-margin ion-no-padding'>
              <Text color='dark' translate='no' className='font-semibold'>
                {jar?.tipping_rule ?? 'none'}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600' translate='no'>
                totalMembers
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {jar?.members?.length ?? jar?.total_member ?? 0}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600' translate='no'>
                totalMemberActive
              </Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {getTotalMemberActive()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='mt-3'>
            <IonCol className='ion-no-padding ion-no-margin'>
              <Text className='text-gray-600'>members.requestSend</Text>
            </IonCol>
            <IonCol className='text-right ion-no-padding ion-no-margin'>
              <Text color='dark' translate='no' className='font-semibold'>
                {formattedDate(createdAt ?? '', user?.lang)}
              </Text>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </Card>
  )
}
