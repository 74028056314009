import { type QueryParamsRetrieve, type Company } from '#tackpay-sdk'
import { createContext, useContext } from 'react'
import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters,
  useQuery
} from '@tanstack/react-query'
import sdk from '#utils/sdk'
import { useUserContext } from './UserContext'
import useAuthState from '#hooks/useAuthState'

interface CompanyContextValue {
  company?: Company | null
  isLoading: boolean
  error?: any
  refetch?: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<Company | null | undefined, unknown>>
}

const initialState: CompanyContextValue = {
  company: undefined,
  isLoading: false,
  error: undefined
}

const CompanyContext = createContext<CompanyContextValue>(initialState)

export const useCompanyContext = (): CompanyContextValue => {
  const context = useContext(CompanyContext)
  if (context == null) {
    throw new Error('useCompanyContext must be used within a CompanyProvider')
  }
  return context
}

interface CompanyContainerProps {
  children: React.ReactNode
  /**
   * If true, fetch the company on mount
   * @default true
   */
  fetch?: boolean
  /**
   * Query params to use when fetching the company
   */
  params?: QueryParamsRetrieve

  /**
   * @default false
   */
  isCollaborator?: boolean
}

export default function CompanyContainer(
  props: CompanyContainerProps
): JSX.Element {
  const { user } = useUserContext()

  const { children, fetch = true, params, isCollaborator = false } = props

  const { getAuthState } = useAuthState()

  const {
    data: company,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['company', params],
    queryFn: async () => {
      const authInfo = await getAuthState()

      if (!isCollaborator) {
        const company = await sdk.users.company(authInfo?.userId ?? '', params)

        if (company.jars != null && !Array.isArray(company.jars)) {
          company.jars = [company.jars]
        }

        return company
      } else {
        const includeDefault = ['company.balance']

        const includeParams: string[] = []

        if (params?.include != null) {
          params?.include.forEach((param) => {
            const item = 'company.' + param
            includeParams.push(item)
          })
        }

        const include =
          includeParams.length > 0 ? includeParams : includeDefault

        const collaborator = await sdk.users.collaborator(
          authInfo?.userId ?? '',
          {
            ...params,
            include
          }
        )

        return collaborator.company
      }
    },
    enabled: fetch && user != null
    // enabled: fetch && !initialFetchComplete && user != null
  })

  const companyContextValue: CompanyContextValue = {
    company,
    isLoading,
    error,
    refetch
  }

  return (
    <CompanyContext.Provider value={companyContextValue}>
      {children}
    </CompanyContext.Provider>
  )
}
