import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import SubTitle from '#components/ui/SubTitle'
import { useTokenContext } from '#contexts/TokenContext'

export default function RegistrationCollaboratorSubTitle(): JSX.Element {
  const { isLoading, error, token } = useTokenContext()

  if (isLoading)
    return (
      <>
        <Skeleton className='w-full' />
        <Skeleton className='w-1/2' />
      </>
    )
  if (error != null) return <ErrorBox error={error} />

  return (
    <SubTitle
      translationOptions={{
        translate: 'yes',
        data: { companyName: token?.company?.name }
      }}
    >
      registration.collaborator.subtitle
    </SubTitle>
  )
}
