import AppLayout from '#components/layouts/app/AppLayout'
import ListLink from '#components/ui/ListLink'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type UserCategory } from '#tackpay-sdk'
import {
  downloadOutline,
  receiptOutline,
  settingsOutline,
  walletOutline
} from 'ionicons/icons'
import { useParams } from 'react-router'
import { HandleJarDeduction } from './JarDeduction'

interface Props {
  category: UserCategory
  jarId: string
}
const HandleDeductionSetting = ({ category, jarId }: Props): JSX.Element => {
  const { jar } = useTeamContext()

  const baseUrl = `/dashboard/${category}/jars/${jarId}/settings`

  const settings = [
    {
      label: 'deduction.setting',
      href: `${baseUrl}/deductions/edit`,
      icon: settingsOutline,
      category
    },
    {
      label: 'profile.edit.deposit.selected_bank_deduction',
      icon: walletOutline,
      href: `${baseUrl}/withdrawals/deductions/banks`,
      category
    },
    {
      label: 'profile.edit.deposit.withdraw_deduction',
      category,
      href: `/dashboard/${category}/jars/${jarId}/settings/withdrawals/deductions/payouts`,
      icon: downloadOutline
    },
    {
      label: 'teamSetting.identity',
      href: `${baseUrl}/deductions/identities`,
      icon: receiptOutline,
      category
    }
  ]

  const showSetting = (): boolean => {
    return jar?.deduction_setting != null && jar.stripe_gateway_account != null
  }

  return showSetting() ? <ListLink items={settings} /> : <HandleJarDeduction />
}

export default function JarDeductionSetting(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: [
            'deduction_setting',
            'stripe_gateway_account',
            'company.stripe_gateway_account'
          ]
        }
      }}
    >
      <Title>deduction.title</Title>
      <HandleDeductionSetting category={category} jarId={jarId} />
    </AppLayout>
  )
}
