import AppLayout from '#components/layouts/app/AppLayout'
import PaymentListSection from '#components/pages/payments/list/PaymentsListSection'
import CardWalletBalance from '#components/pages/wallets/card/WallteBalanceCard'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type UserCategory } from '#tackpay-sdk'
import { useParams } from 'react-router'

interface Props {
  category: UserCategory
}
function HandleTeamBalance({ category }: Props): JSX.Element {
  const { jar, isLoading, error } = useTeamContext()

  return (
    <>
      <CardWalletBalance
        category={category}
        isLoading={isLoading}
        error={error}
        jar={jar}
      />

      <PaymentListSection
        jarId={jar?.id}
        category={category}
        isLoading={isLoading}
        errorContext={error}
        isTeam
        isTeamShared={jar?.jar_type === 'shared'}
      />
    </>
  )
}

export default function TeamWallet(): JSX.Element {
  const { jarId, category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      teamProps={{
        fetch: true,
        params: {
          include: ['balance']
        }
      }}
    >
      <Title>wallet.title</Title>
      <HandleTeamBalance category={category} />
    </AppLayout>
  )
}
