import Skeleton from '#components/ui/Skeleton'
import classNames from 'classnames'

interface Props {
  className?: string
}
export default function AvatarSkeleton({
  className = 'w-12 h-12'
}: Props): JSX.Element {
  const classes = classNames(className, 'rounded-full')
  return <Skeleton class='avatar' animated className={classes} />
}
