import Empty from '#components/ui/Empty'

export default function EmptyCollaboratorInviteConfirm(): JSX.Element {
  return (
    <Empty
      className='mt-16'
      imgOptions={{
        src: '/assets/img/empty-collaborator.svg',
        alt: 'emptyFeedback',
        className: 'w-1/2 md:w-2/6 mx-auto'
      }}
      textOptions={{
        text: 'collaborator.empty',
        props: {
          color: 'gray-500',
          size: 'text-base',
          className: 'text-center italic  mt-5 font-semibold'
        }
      }}
    />
  )
}
