import { useIonLoading } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function useLoading(initialMessage?: string): {
  startLoading: () => void
  stopLoading: () => void
  setMessage: (message: string) => void
} {
  const { t } = useTranslation()

  const [start, close] = useIonLoading()

  const initialMsg = initialMessage != null ? t(initialMessage) : ''

  const [message, setMessage] = useState(initialMsg)

  const startLoading = (): void => {
    void start({
      message: t(message),
      cssClass: 'custom-loading',
      id: 'loading'
    })
  }

  const stopLoading = (): void => {
    close()
      .then(() => {
        console.log('Loading closed')
      })
      .catch((error) => {
        console.log('Loading error', error)
      })
    document.getElementById('loading')?.remove()
  }

  const handleSetMessage = (message: string): void => {
    setMessage(String(t(message)))
  }

  return {
    startLoading,
    stopLoading,
    setMessage: handleSetMessage
  }
}
