import Button from '#components/ui/Button'
import { type Member } from '#tackpay-sdk'
import { useEffect, useState } from 'react'
import MemberCardDivideAmount from './MemberCardDivideAmount'
import MemberModalReview from './MemberModalReviews'
import EmptyMember from '#components/pages/members/EmptyMember'

interface MemberListProps {
  members: Member[]
  jarId: string
  balance: number
  equalSplit: boolean
  setEqualSplit: (equalSplit: boolean) => void
}

export type MemberAmount = Member & { amount: number }

export default function MemberList({
  members,
  jarId,
  balance,
  equalSplit,
  setEqualSplit
}: MemberListProps): JSX.Element {
  const memberInviteAccepted = members.filter(
    (member) =>
      member.status === 'invitation_accepted' &&
      member.user?.stripe_gateway_account != null
  )

  const [show, setShow] = useState(false)

  const [membersAmount, setMembersAmount] = useState<MemberAmount[]>(
    memberInviteAccepted.map((member) => ({ ...member, amount: 0 }))
  )

  const handleEqualSplit = (): void => {
    const amount = Math.round(balance / membersAmount.length)

    setMembersAmount((prevMembersAmount) =>
      prevMembersAmount.map((prevMemberAmount) => ({
        ...prevMemberAmount,
        amount
      }))
    )
  }

  useEffect(() => {
    if (equalSplit) {
      handleEqualSplit()
      setEqualSplit(false)
    }
  }, [equalSplit])

  const setMemberAmount = (memberAmount: MemberAmount): void => {
    setMembersAmount((prevMembersAmount) =>
      prevMembersAmount.map((prevMemberAmount) =>
        prevMemberAmount.id === memberAmount.id
          ? memberAmount
          : prevMemberAmount
      )
    )
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setShow(true)
  }

  return (
    <form className='space-y-5' onSubmit={handleSubmit}>
      <MemberModalReview
        setShow={setShow}
        show={show}
        members={membersAmount}
        jarId={jarId}
        setMembers={setMembersAmount}
        balance={balance}
      />
      {membersAmount.length > 0 ? (
        membersAmount.map((member) => (
          <MemberCardDivideAmount
            member={member}
            key={member.id}
            membersAmount={membersAmount}
            setMemberAmount={setMemberAmount}
          />
        ))
      ) : (
        <EmptyMember />
      )}
      {memberInviteAccepted.length > 0 && (
        <div className='pt-5'>
          <Button type='submit' className='w-full'>
            confirm
          </Button>
        </div>
      )}
    </form>
  )
}
