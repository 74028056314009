import AuthLayout from '#components/layouts/auth/AuthLayout'
import Title from '#components/ui/Title'
import TokenContainer from '#contexts/TokenContext'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import RegistrationCollaborator from '#components/pages/registrations/collaborators/RegistrationCollaborator'
import RegistrationMember from '#components/pages/registrations/member/RegistrationMember'

export default function RegistrationCollaboratorToken(): JSX.Element {
  const { token, category } = useParams<RegistrationRouteParms>()
  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
    >
      <TokenContainer
        fetch={token != null}
        value={token}
        params={{
          include: ['company.user.person.address', 'jar.company']
        }}
      >
        <Title>
          {category === 'collaborator'
            ? 'registration.collaborator.title'
            : 'registration.member.title'}
        </Title>
        {category === 'collaborator' ? (
          <RegistrationCollaborator />
        ) : (
          <RegistrationMember />
        )}
      </TokenContainer>
    </AuthLayout>
  )
}
