import Card from '#components/ui/Card'
import CoverIcon from '#components/ui/CoverIcon'
import Label from '#components/ui/Label'
import { IonRow } from '@ionic/react'
import classNames from 'classnames'

interface Props {
  /**
   * The additional class name
   */
  className?: string
  /**
   * The icon to be used
   */
  icon: string
  /**
   * The label to be used
   */
  label: string
  /**
   * The ion justify content
   */
  ionJustifyContent?: string
  /**
   * The ion align items
   */
  ionAlignItems?: string
  /**
   * The space between the icon and the label
   * @default 'ml-20'
   */
  ml?: string
  /**
   * The onClick event handler
   */
  onClick?: () => void
}

export default function CardIconLabel({
  className,
  icon,
  label,
  ionAlignItems,
  ionJustifyContent,
  ml,
  onClick
}: Props): JSX.Element {
  const baseClass = 'cursor-pointer'
  const cardClass = classNames(baseClass, className)
  const alignItems = ionAlignItems ?? 'ion-align-items-center'
  const justifyContent = ionJustifyContent ?? 'ion-justify-content-start'
  const ionRowClass = classNames(alignItems, justifyContent)
  const labelSpace = ml ?? 'ml-20'

  return (
    <Card className={cardClass} onClick={onClick} data-testid={`card-${label}`}>
      <IonRow className={ionRowClass}>
        <CoverIcon iconOptions={{ icon }} />
        <Label color='gray-900' className={`${labelSpace} font-bold`}>
          {label}
        </Label>
      </IonRow>
    </Card>
  )
}
