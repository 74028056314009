import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Input, {
  type InputOnChange,
  type InputValue
} from '#components/ui/Input'
import Text from '#components/ui/Text'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import textUtil from '#utils/textUtil'
import { IonRow } from '@ionic/react'
import React, { useEffect, useState } from 'react'

export default function CardChangeUserData(): JSX.Element {
  const { editUser, user } = useUserContext()

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const { startLoading, stopLoading } = useLoading('userEmailChanging')

  const [email, setEmail] = useState<InputValue>(user?.email)

  const handleInputChange: InputOnChange = (event) => {
    const { name, value } = event.target

    if (name === 'email' && typeof value === 'string') setEmail(value)
  }

  useEffect(() => {
    if (user != null) {
      setEmail(user.email)
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    let newEmail: string | null = null

    if (typeof email === 'string' && textUtil.isEmail(email)) newEmail = email

    if (newEmail == null) {
      showToast(undefined, 'emailMissing')
      return
    }

    if (editUser != null) {
      if (newEmail !== user?.email) {
        startLoading()
        editUser.mutate(
          {
            id: user?.id ?? '',
            email: newEmail ?? undefined
          },
          {
            onSuccess: () => {
              stopLoading()
              successToast(undefined, 'userEmailChanged')
            },
            onError: (error) => {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
      } else {
        showToast(undefined, 'userEmailEqual')
      }
    }
  }

  return (
    <Card>
      <Text color='gray-900' fontWeight='font-semibold' size='text-base'>
        Change Email
      </Text>
      <form className='space-y-4 mt-4' onSubmit={handleSubmit}>
        <div>
          <Input
            name='email'
            type='email'
            labelOptions={{
              children: 'Email'
            }}
            value={email}
            onIonInput={handleInputChange}
          />
        </div>

        <IonRow className='w-full ion-no-margin ion-no-padidng'>
          <Button type='submit' className='w-full ion-no-margin ion-no-padding'>
            confirm
          </Button>
        </IonRow>
      </form>
    </Card>
  )
}
