import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router'
import Checkout from './views/Checkout'
import CheckoutPaymentMethods from './views/CheckoutPaymentMethods'
import CheckoutReviews from './views/CheckoutReviews'
import CheckoutBill from './views/CheckoutBill'
import CheckoutConfirm from './views/CheckoutConfirm'

const dashboardBasePath = '/checkout/:tackpayId'

export interface CheckoutRoutesParams {
  tackpayId: string
  checkoutId: string
}

export default function CheckoutRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={dashboardBasePath}>
          <Checkout />
        </Route>
        <Route
          path='/checkout/tipped/:tackpayId'
          render={({ match }) => (
            <Redirect to={`/checkout/${match.params.tackpayId}`} />
          )}
        />
        <Route
          path='/checkout/structure/:tackpayId'
          render={({ match }) => (
            <Redirect to={`/checkout/${match.params.tackpayId}`} />
          )}
        />
        <Route
          path='/checkout/teams/:tackpayId'
          render={({ match }) => (
            <Redirect to={`/checkout/${match.params.tackpayId}`} />
          )}
        />

        <Route
          path='/checkoutBusiness/:tackpayId'
          render={({ match }) => (
            <Redirect to={`/checkout/${match.params.tackpayId}`} />
          )}
        />

        <Route
          path='/checkoutTipped/:tackpayId'
          render={({ match }) => (
            <Redirect to={`/checkout/${match.params.tackpayId}`} />
          )}
        />

        <Route exact path={`${dashboardBasePath}/:checkoutId/payment-methods`}>
          <CheckoutPaymentMethods />
        </Route>
        <Route exact path={`${dashboardBasePath}/:checkoutId/reviews`}>
          <CheckoutReviews />
        </Route>
        <Route exact path={`${dashboardBasePath}/:checkoutId/bills`}>
          <CheckoutBill />
        </Route>
        <Route
          exact
          path={`${dashboardBasePath}/:checkoutId/payment-methods/handle-confirm`}
        >
          <CheckoutConfirm />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
