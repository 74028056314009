import ErrorBox from '#components/errors/ErrorBox'
import AuthLayout from '#components/layouts/auth/AuthLayout'

export default function TokenExpires(): JSX.Element {
  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
    >
      <ErrorBox
        error={{
          message: 'token_expired_jar_invite'
        }}
      />
    </AuthLayout>
  )
}
