import Empty from '#components/ui/Empty'

export default function EmptyMember(): JSX.Element {
  return (
    <Empty
      imgOptions={{
        src: '/assets/img/empty-member.svg',
        alt: 'emptyMember',
        className: 'w-1/2 mx-auto md:w-1/4 md:mt-5'
      }}
      textOptions={{
        text: 'member.empty',
        props: {
          className:
            'text-center text-base italic text-gray-600 mt-10 font-semibold'
        }
      }}
    />
  )
}
