import HomeCompanyBalance from '#components/pages/home/HomeCompanyBalance'
import HomeQrCodeSection from '#components/pages/home/HomeQrCodeSection'
import HomeTeamSection from '#components/pages/home/HomeTeamSection'
import BannerComponents from '#components/ui/BannerComponents'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import useFCMToken from '#hooks/useFCMToken'
import { type UserCategory } from '#tackpay-sdk'
import { useEffect } from 'react'

interface Props {
  category: UserCategory
}
export default function HomeBusiness({ category }: Props): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  const { user } = useUserContext()

  const { getToken, setTokenOnServer } = useFCMToken()

  const handleGetFCMToken = async (): Promise<void> => {
    try {
      const token = await getToken()
      if (token != null) {
        await setTokenOnServer(user?.subscriber?.id ?? '', token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      (user?.subscriber?.device_tokens == null ||
        user?.subscriber?.device_tokens?.length <= 0) &&
      user != null
    ) {
      void handleGetFCMToken()
    }
  }, [user?.subscriber?.device_tokens])

  return (
    <>
      <BannerComponents />
      <HomeCompanyBalance />
      <HomeTeamSection
        category={category}
        jars={company?.jars}
        isLoading={isLoading}
        error={error}
      />
      <HomeQrCodeSection company={company} jars={company?.jars} />
    </>
  )
}
