import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { type BalanceDeduction, type Balance } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'

interface Props {
  /**
   * The balance object
   */
  balance?: Balance | null | BalanceDeduction
  /**
   * The loading state
   */
  loading?: boolean
  /**
   * The error object
   */
  error?: any
}
export default function PayoutBalanceAvailable({
  balance,
  error,
  loading = true
}: Props): JSX.Element {
  if (loading) return <Skeleton className='w-10 h-7' />
  if (error != null) return <ErrorBox error={error} />

  return (
    <Text
      size='text-4xl'
      fontWeight='font-bold'
      color='text-gray-900'
      translationOptions={{ translate: 'no' }}
    >
      {formattedAmount(
        balance?.available?.amount_cents,
        balance?.available?.currency
      )}
    </Text>
  )
}
