import { Preferences } from '@capacitor/preferences'

export const savePreferences = async <T>(
  key: string,
  value: T
): Promise<void> => {
  Preferences.set({
    key,
    value: JSON.stringify(value)
  })
    .then(() => {
      console.log(`${key} saved`, value)
    })
    .catch((error) => {
      console.error(`unexpected error while saving the ${key}`, error)
    })
}

export const getPreferences = async <T>(
  key: string
): Promise<T | undefined> => {
  const result = await Preferences.get({ key })
  if (result.value != null) {
    return JSON.parse(result.value) as T
  }
  return undefined
}

export const destroyPreferences = (key: string): void => {
  Preferences.remove({
    key
  })
    .then(() => {
      console.log(`${key} removed`)
    })
    .catch((error) => {
      console.error(`unexpected error while removing the ${key}`, error)
    })
}

export const clearPreferences = (): void => {
  Preferences.clear()
    .then(() => {
      console.log('preferences cleared')
    })
    .catch((error) => {
      console.error('unexpected error while clearing preferences', error)
    })
}
