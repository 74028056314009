import { IonPage, isPlatform, useIonRouter } from '@ionic/react'
import { useEffect, type ComponentProps } from 'react'
import AppHeader from './AppHeader'
import AppContent from './AppContent'
import AppMenu from './AppMenu'
import AppFooter from './AppFooter'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import Helmet from 'react-helmet'
import CollaboratorContainer from '#contexts/CollaboratorContext'
import CompanyContainer from '#contexts/CompanyContext'
import TeamContainer from '#contexts/JarContext'
import isCollaborator from '#utils/isCollaborator'
import AppMenuDesktop from './AppMenuDesktop'
import isDesktop from '#utils/isDesktop'
import HeaderDesktop from '#components/desktop/UI/HeaderDesktop'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import UserContainer from '#contexts/UserContext'
import useAuthState from '#hooks/useAuthState'
import getQueryParams from '#utils/getQueryParams'

interface AppLayoutProps {
  /**
   * The content of the layout.
   */
  children: React.ReactNode
  /**
   * The options for the IonPage component.
   */
  pageOptions?: ComponentProps<typeof IonPage>
  /**
   * The options for the AppHeader component.
   */
  headerOptions?: ComponentProps<typeof AppHeader>
  /**
   * The options for the AppContent component.
   */
  contentOptions?: ComponentProps<typeof AppContent>
  /**
   * The options for the AppFooter component.
   */
  footerOptions?: ComponentProps<typeof AppFooter>
  /**
   * The query params options to fetch the user.
   */
  userProps?: Omit<ComponentProps<typeof UserContainer>, 'children'>
  /**
   * The query params options to fetch the company.
   */
  companyProps?: Omit<ComponentProps<typeof CompanyContainer>, 'children'>
  /**
   * The query params options to fetch the collaborator.
   */
  collaboratorProps?: Omit<
    ComponentProps<typeof CollaboratorContainer>,
    'children'
  >
  /**
   * The query params options to fetch the jar.
   */
  teamProps?: Omit<ComponentProps<typeof TeamContainer>, 'children'>
  /**
   * The script to be added to the helmet.
   */
  helmetScript?: React.ReactNode
}
export default function AppLayout(props: AppLayoutProps): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const ionRouter = useIonRouter()

  const query = getQueryParams(ionRouter.routeInfo.search)

  const { refreshToken, getAuthState, authStatusChangeFunction } =
    useAuthState()

  useEffect(() => {
    const handleAuthCheck = async (): Promise<void> => {
      const authState = await getAuthState()
      const isQueryAuth =
        query.get('access_token') != null && query.get('user_id') != null

      if (authState?.isLoggedIn == null || !authState.isLoggedIn) {
        if (!isQueryAuth) {
          ionRouter.push('/login')
        }
      }
    }

    void handleAuthCheck()
  }, [ionRouter.routeInfo.pathname])

  useEffect(() => {
    return () => {
      authStatusChangeFunction()
    }
  }, [])

  useEffect(() => {
    const tokenRefreshInterval = setInterval(
      () => {
        void refreshToken()
      },
      1000 * 60 * 5
    ) // 5 minute

    return () => {
      clearInterval(tokenRefreshInterval)
    }
  }, [])

  const {
    pageOptions,
    headerOptions,
    contentOptions,
    userProps,
    companyProps,
    helmetScript,
    collaboratorProps,
    footerOptions,
    teamProps,
    children
  } = props

  const id = pageOptions?.id ?? 'main-content'

  const defaultUserParams = !isBusiness(category)
    ? { include: ['person', 'stripe_gateway_account'] }
    : { include: ['person'] }

  const defaultCompanyParams = { include: ['tackpay_id'] }

  const defaultCollaboratorParams = { include: ['jars'] }

  const defaultTeamParams = { include: ['company'] }

  const scrollY = contentOptions?.scrollY ?? true

  const { jarId } = useParams<TeamRoutesParams>()

  const checkTutorials = ionRouter.routeInfo.pathname.includes('tutorials')

  return (
    <UserContainer params={userProps?.params ?? defaultUserParams}>
      <CompanyContainer
        params={companyProps?.params ?? defaultCompanyParams}
        fetch={isBusiness(category)}
        isCollaborator={
          companyProps?.isCollaborator ?? isCollaborator(category)
        }
      >
        <CollaboratorContainer
          fetch={collaboratorProps?.fetch ?? isCollaborator(category)}
          params={collaboratorProps?.params ?? defaultCollaboratorParams}
        >
          <TeamContainer
            jarId={teamProps?.jarId ?? jarId}
            fetch={teamProps?.fetch}
            params={teamProps?.params ?? defaultTeamParams}
          >
            {helmetScript != null && (
              <Helmet>
                {helmetScript}
                <meta
                  http-equiv='Content-Security-Policy'
                  content='default-src *; script-src *; style-src *; img-src *; media-src *; font-src *; connect-src *; object-src *; child-src *; frame-src *; form-action *;'
                />
              </Helmet>
            )}
            {!isPlatform('desktop') && <AppMenu />}
            <IonPage {...pageOptions} id={id}>
              {isPlatform('desktop') && !checkTutorials && <AppMenuDesktop />}
              {!isDesktop() ? (
                <AppHeader {...headerOptions} />
              ) : !checkTutorials ? (
                <HeaderDesktop {...headerOptions} />
              ) : null}
              <AppContent {...contentOptions} scrollY={scrollY}>
                {children}
              </AppContent>
              <AppFooter
                {...footerOptions}
                showFooter={footerOptions?.showFooter ?? !isPlatform('desktop')}
              />
            </IonPage>
          </TeamContainer>
        </CollaboratorContainer>
      </CompanyContainer>
    </UserContainer>
  )
}
