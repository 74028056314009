import CardCategory from '#components/common/CardCategory'
import AuthLayout from '#components/layouts/auth/AuthLayout'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import { type UserCategory } from '#tackpay-sdk'
import { IonCol, useIonRouter } from '@ionic/react'

export default function RegistrationCategoryStep(): JSX.Element {
  const { setUserData } = useRegistrationContext()

  const { push } = useIonRouter()

  const handleCategoryClick = (category: UserCategory): void => {
    setUserData({ category })
    push(`/registrations/${category}`)
  }

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: '/'
        }
      }}
    >
      <Title>registration.title</Title>
      <SubTitle>registration.subtitle</SubTitle>
      <IonCol className='space-y-16'>
        <CardCategory
          options={{
            imgOptions: {
              src: '/assets/img/tippedIcon.svg'
            }
          }}
          title='tipped'
          description='registration.tipped.description'
          cardProps={{
            onClick: () => {
              handleCategoryClick('tipped')
            }
          }}
        />
        <CardCategory
          options={{
            imgOptions: {
              src: '/assets/img/companyIcon.svg'
            }
          }}
          title='business'
          description='registration.company.description'
          cardProps={{
            onClick: () => {
              handleCategoryClick('business')
            }
          }}
        />
      </IonCol>
    </AuthLayout>
  )
}
