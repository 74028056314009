import {
  IonButton,
  IonCol,
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  useIonRouter
} from '@ionic/react'
import { useState, type ComponentProps, useEffect } from 'react'
import { getSideMenu } from '../../../constants/sideMenu'
import { useParams } from 'react-router'
import CoverIcon from '../../ui/CoverIcon'
import Text from '../../ui/Text'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import Logout from '#components/common/Logout'
import { useUserContext } from '#contexts/UserContext'
import Label from '#components/ui/Label'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { Capacitor } from '@capacitor/core'
import sdk from '#utils/sdk'
import { isInactiveCapabilities } from '#utils/isInactiveCapabilities'

interface AppMenuProps {
  /**
   * The options for the IonMenu component.
   */
  menuOptions?: ComponentProps<typeof IonMenu>
}
export default function AppMenu(props: AppMenuProps): JSX.Element {
  const { menuOptions } = props

  const id = menuOptions?.contentId ?? 'main-content'

  const { category: categoryParam } = useParams<DashboardRoutesParams>()

  const { user } = useUserContext()

  const { collaborator } = useCollaboratorContext()

  const { company } = useCompanyContext()

  const { routeInfo, push } = useIonRouter()

  const [jars, setJar] = useState<number>(0)

  const [isMembers, setIsMembers] = useState<boolean>(false)

  useEffect(() => {
    if (company != null) {
      if (company.jars != null) {
        setJar(company.jars.length)
      } else {
        sdk.companies
          .jars(company.id)
          .then((res) => {
            setJar(res.length)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [company])

  useEffect(() => {
    if (user != null && user.category === 'tipped') {
      sdk.users
        .retrieve(user.id, { include: ['members'] })
        .then((res) => {
          if (res?.members != null && res.members.length > 0) {
            setIsMembers(true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [user])

  const linkTopMenu =
    categoryParam === 'tipped'
      ? `/dashboard/${categoryParam}/checkout-settings`
      : `/dashboard/${categoryParam}/companies/${company?.id ?? ''}`

  const menu = document.querySelector('ion-menu')

  const handleClick = (page: string): void => {
    if (page === routeInfo.pathname) {
      void menu?.close(true)
    } else if (page.includes('companies')) {
      page = page + '/' + (company?.id ?? '')

      push(page, 'root')
    } else {
      push(page, 'root')
    }
  }

  const hiddenCompanyItem = (title: string): boolean => {
    if (title === 'sidemenu.structure') {
      return jars === 1
    }
    return false
  }

  const hiddenQrCodeItem = (title: string): boolean => {
    if (title === 'sidemenu.material') {
      return isMembers
    }
    return false
  }

  const hiddenDeduction = (title: string): boolean => {
    if (title === 'deduction.side_menu') {
      return ![
        '9c86881d-23f5-4cf4-9716-c7257ed4a914',
        'e3bbf101-5ac8-4f94-b784-18d7d6cee3e0',
        '2865aa15-414d-46da-9b83-7e52a60ce83c'
      ].includes(company?.id ?? '')
    }
    return false
  }

  return (
    <>
      <IonMenu
        {...menuOptions}
        contentId={id}
        className={`${menuOptions?.className ?? ''}`}
      >
        <IonCol className={`${Capacitor.isNativePlatform() ? 'pt-16' : ''}`}>
          <IonItem
            id='item-header-menu'
            lines='none'
            detail={false}
            button
            className='py-3'
            routerLink={linkTopMenu}
            routerDirection='root'
          >
            <IonRow className='pl-1 pb-2 ion-justify-content-start ion-align-items-center space-x-4'>
              <div className='w-14 h-14 rounded-full shadow text-center flex flex-col justify-center bg-gray-100'>
                <span className='font-medium text-gray-600 uppercase'>
                  {user?.person?.first_name?.charAt(0)}
                  {user?.person?.last_name?.charAt(0)}
                </span>
              </div>
              <Label capitalize data-testid={user?.person?.first_name}>
                {user?.person?.first_name}
              </Label>
            </IonRow>
          </IonItem>

          <hr />
          <IonContent id='toolbar-menu' scrollY>
            <IonList
              className='mt-10 space-y-4  ion-no-padding ion-no-margin'
              lines='none'
            >
              {getSideMenu(categoryParam, collaborator?.role, undefined).map(
                (page) => (
                  <IonItem
                    className={
                      hiddenCompanyItem(page.title) ||
                      hiddenQrCodeItem(page.title) ||
                      hiddenDeduction(page.title)
                        ? 'hidden'
                        : 'pl-0'
                    }
                    button
                    key={page.title}
                    detail={false}
                    onClick={() => {
                      handleClick(page.url)
                    }}
                    routerDirection='root'
                    id='toolbar-menu'
                  >
                    <IonMenuToggle>
                      <IonButton
                        color='transparent'
                        className='w-full button-menu-toggle'
                      >
                        <IonRow className='ion-no-padding ion-justify-content-start ion-align-items-center space-x-6 w-full'>
                          <CoverIcon
                            iconOptions={{
                              icon: page.icon,
                              className: 'text-gray-600'
                            }}
                          />
                          <Text
                            size='text-base'
                            color='gray-900'
                            className='capitalize '
                            data-testid={page.title}
                          >
                            {page.title}
                          </Text>
                          {isInactiveCapabilities(
                            user?.stripe_gateway_account,
                            user?.category === 'tipped'
                          ) &&
                            page.title === 'sidemenu.setting' && (
                              <div className='bg-red-500 p-1 rounded-full w-3 h-3 mt-1' />
                            )}
                        </IonRow>
                      </IonButton>
                    </IonMenuToggle>
                  </IonItem>
                )
              )}
              <Logout />
            </IonList>
          </IonContent>
        </IonCol>
      </IonMenu>
    </>
  )
}
