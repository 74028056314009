import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import isCollaborator from '#utils/isCollaborator'
import { useParams } from 'react-router'
import WalletBusiness from './WalletBusiness'
import WalletCollaborator from './WalletCollaborator'
import WalletTipped from './WalletTipped'

export default function Wallet(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      userProps={{
        params: {
          include: isBusiness(category)
            ? ['person']
            : ['person', 'balance', 'members', 'stripe_gateway_account']
        }
      }}
      companyProps={{
        params: {
          include: isBusiness(category) ? ['jars.balance'] : undefined
        }
      }}
      collaboratorProps={{
        params: {
          include: isCollaborator(category) ? ['jars.balance'] : undefined
        }
      }}
    >
      <Title>wallet.title</Title>

      {isBusiness(category) && !isCollaborator(category) && (
        <WalletBusiness category={category} />
      )}
      {isBusiness(category) && isCollaborator(category) && (
        <WalletCollaborator category={category} />
      )}

      {!isBusiness(category) && <WalletTipped />}
    </AppLayout>
  )
}
