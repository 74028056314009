import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type QueryParamsRetrieve } from 'sdk/query'

type ContactUsType = 'contact_us'

type ContactUsRel = ResourceRel & { type: ContactUsType }

type CompanyRel = ResourceRel & { type: 'companies' }
type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

type ContactUsStatus =
  | 'awaiting_support'
  | 'awaiting_user'
  | 'closed'
  | 'open'
  | 'worked'
  | 'schedule'

interface ContactUs extends Resource {
  readonly type: ContactUsType
  name: string
  email: string
  message: string
  status: ContactUsStatus
  user_request?: User
  user_response?: User
  company?: Company
}

interface ContactUsCreate extends ResourceCreate {
  name: string
  email?: string
  message: string
  company?: CompanyRel
  jar?: JarRel
  user_request?: UserRel
}

class ContactUss extends ApiResource<ContactUs> {
  static readonly TYPE: ContactUsType = 'contact_us' as const

  async create(
    resource: ContactUsCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<ContactUs> {
    return await this.resources.create<ContactUsCreate, ContactUs>(
      { ...resource, type: ContactUss.TYPE },
      params,
      options
    )
  }

  isContactUs(resource: any): resource is ContactUs {
    return resource.type != null && resource.type === ContactUss.TYPE
  }

  relationship(id: string | ResourceId | null): ContactUsRel {
    return id === null || typeof id === 'string'
      ? { id, type: ContactUss.TYPE }
      : { id: id.id, type: ContactUss.TYPE }
  }

  type(): ContactUsType {
    return ContactUss.TYPE
  }
}

export type { ContactUs, ContactUsCreate }
export default ContactUss
