import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import TeamCardTipped from '#components/pages/teams/TeamCardTipped'
import TeamEmpty from '#components/pages/teams/TeamEmpty'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import MemberContainer from '#contexts/MemberContext'
import { useUserContext } from '#contexts/UserContext'
import { animationsFade } from '#utils/animations'
import { useEffect, useRef } from 'react'

export default function TeamTipped(): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  const list = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    animationsFade({
      typeAnimation: list,
      start: '-45px',
      end: '0',
      duration: 500
    })
  }, [user?.members])

  return (
    <>
      <Title className='ion-no-margin' hasSubtitle>
        teamPage.title
      </Title>
      <SubTitle>teamPage.subtitle</SubTitle>
      {isLoading && (
        <div className='space-y-6'>
          <CardSkeleton hasAvatar />
          <CardSkeleton hasAvatar />
        </div>
      )}

      {error != null && <ErrorBox error={error} />}
      {!isLoading && (user?.members == null || user?.members.length === 0) ? (
        <TeamEmpty isTipped />
      ) : (
        <MemberContainer fetch={false}>
          <div className='space-y-6' ref={list}>
            {user?.members?.map((member) => (
              <TeamCardTipped key={member.id} member={member} />
            ))}
          </div>
        </MemberContainer>
      )}
    </>
  )
}
