class BirthdateValidate {
  isValidDay(day?: number): boolean {
    if (typeof day === 'undefined') return false
    return day >= 1 && day <= 31
  }

  isValidMonth(month?: number): boolean {
    if (typeof month === 'undefined') return false
    return month >= 1 && month <= 12
  }

  isValidYear(year?: number): boolean {
    if (typeof year === 'undefined') return false
    return year >= 1900 && year <= new Date().getFullYear()
  }

  isOfLegalAge(day?: number, month?: number, year?: number): boolean {
    if (
      typeof day === 'undefined' ||
      typeof month === 'undefined' ||
      typeof year === 'undefined'
    )
      return false
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const currentDay = new Date().getDate()

    const isLegalAge =
      currentYear - year > 18 ||
      (currentYear - year === 18 && currentMonth > month) ||
      (currentYear - year === 18 && currentMonth === month && currentDay >= day)

    return isLegalAge
  }

  isValidDate(day?: number, month?: number, year?: number): boolean {
    const isValidDay = this.isValidDay(day)
    const isValidMonth = this.isValidMonth(month)
    const isValidYear = this.isValidYear(year)
    return isValidDay && isValidMonth && isValidYear
  }
}

const birthdateValidate = new BirthdateValidate()

export default birthdateValidate
