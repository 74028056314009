import AppLayout from '#components/layouts/app/AppLayout'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'
import TeamTipped from './TeamTipped'
import TeamBusiness from './TeamBusiness'
import { isBusiness } from '#utils/isBusiness'

export default function Jar(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      userProps={{
        fetch: true,
        params: {
          include: isBusiness(category)
            ? ['person']
            : [
                'person',
                'members.jar.balance',
                'members.jar.company',
                'stripe_gateway_account'
              ]
        }
      }}
      companyProps={{
        params: {
          include: ['customer.subscription.subscription_items']
        }
      }}
    >
      {!isBusiness(category) && <TeamTipped />}
      {isBusiness(category) && <TeamBusiness category={category} />}
    </AppLayout>
  )
}
