import AvatarSkeleton from '#components/common/AvatarSkeleton'
import Skeleton from '#components/ui/Skeleton'
import { IonRow } from '@ionic/react'

export default function CheckoutSkeleton(): JSX.Element {
  return (
    <div className='w-full pt-10'>
      <AvatarSkeleton className='w-28 h-28 mx-auto' />
      <Skeleton className='w-1/2 mx-auto' />
      <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
      <div className='mt-5'>
        <IonRow className='w-full ion-justify-content-between ion-align-items-center'>
          <Skeleton className='w-1/3 h-20' />
          <Skeleton className='w-1/3 h-20' />
          <Skeleton className='w-1/3 h-20' />
        </IonRow>
      </div>
      <div className='mt-5'>
        <IonRow className='w-full ion-justify-content-between ion-align-items-center'>
          <Skeleton className='w-1/2 h-20' />
          <Skeleton className='w-1/2 h-20' />
        </IonRow>
      </div>
    </div>
  )
}
