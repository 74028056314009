import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { type ListResponse, type Member, type UserCategory } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import EmptyMember from './EmptyMember'
import MemberCard from './MemberCard'
import { useEffect, useRef } from 'react'
import { animationsFade } from '#utils/animations'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'

interface Props {
  jarId: string
  category: UserCategory
}

export default function MemberSectionList({
  jarId,
  category
}: Props): JSX.Element {
  const fetchMembers = async ({
    pageParam = 1
  }): Promise<ListResponse<Member>> => {
    return await sdk.jars.members(
      jarId,
      {
        include: ['user.person', 'user.stripe_gateway_account'],
        pageNumber: pageParam,
        sort: {
          created_at: 'desc'
        }
      },
      {
        forceList: true
      }
    )
  }
  const listMembers = useRef<HTMLDivElement | null>(null)

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['members', jarId],
    queryFn: fetchMembers,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getCurrentPage() + 1 : undefined
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (!hasMore || hasMore == null) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  useEffect(() => {
    animationsFade({
      typeAnimation: listMembers,
      start: '-45px',
      end: '0',
      duration: 500
    })
  }, [data?.pages[0].length])

  return status === 'pending' ? (
    <CardSkeleton hasAvatar />
  ) : status === 'error' ? (
    <ErrorBox error={error} />
  ) : data.pages != null && data.pages[0].length === 0 && !isFetching ? (
    <EmptyMember />
  ) : (
    <div ref={listMembers} className='space-y-6'>
      {data.pages.map((page, i) => (
        <div key={i} className='space-y-6'>
          {page.map((member) => {
            if (member != null) {
              return (
                <MemberCard
                  key={member.id}
                  member={member}
                  category={category}
                  jarId={jarId}
                />
              )
            }
            return null
          })}
        </div>
      ))}
      <IonInfiniteScroll
        disabled={hasMore == null || !hasMore}
        onIonInfinite={loadNext}
      >
        <IonInfiniteScrollContent
          loadingSpinner='circles'
          loadingText='Loading...'
          color='primary'
        />
      </IonInfiniteScroll>
    </div>
  )
}
