import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useStatisticViewContext } from '#contexts/StatisticViewConytext'
import { useTeamContext } from '#contexts/JarContext'
import { type StatisticView } from '#tackpay-sdk'
import isDesktop from '#utils/isDesktop'
import EmptyStatisticView from './EmptyStatisticView'
import StatisticViewCard from './StatisticViewCard'
import { useUserContext } from '#contexts/UserContext'

export default function StatisticViewComponents(): JSX.Element {
  const noView: StatisticView = {
    total: 0,
    view_tip: 0,
    view_no_tip: 0,
    type: 'statistic_views',
    id: ''
  }
  const { statisticView, error, isLoading } = useStatisticViewContext()

  const { jar } = useTeamContext()

  const { user } = useUserContext()

  if (isDesktop() && statisticView == null) {
    if (isLoading) return <Skeleton className='h-56' />

    if (error != null) return <ErrorBox error={error} />

    return (
      <StatisticViewCard
        statisticView={noView}
        checkouts={jar?.checkout_sessions ?? []}
      />
    )
  }

  if (isLoading) return <Skeleton className='h-56' />

  if (error != null) return <ErrorBox error={error} />

  if (statisticView == null) return <EmptyStatisticView />

  return (
    <StatisticViewCard
      statisticView={statisticView}
      checkouts={jar?.checkout_sessions ?? user?.checkout_sessions ?? []}
    />
  )
}
