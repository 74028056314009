/* eslint-disable @typescript-eslint/no-empty-interface */
import ApiClient, { type ApiClientInitConfig } from './client'
import { denormalize, normalize } from './jsonApi'
import type {
  QueryParamsRetrieve,
  QueryParamsList,
  QueryFilter,
  QueryParams
} from './query'
import { generateQueryStringParams, isParamsList } from './query'
import type { ResourceTypeLock } from './api'
import config from './config'
import type { InterceptorManager } from './interceptor'
import { getPreferences } from 'storage/preferences'
import { AUTH_STATE_KEY, type AuthState } from '#hooks/useAuthState'

type ResourceNull = { id: null } & ResourceType
type ResourceRel = ResourceId | ResourceNull

type Metadata = Record<string, any>

interface ResourceType {
  readonly type: ResourceTypeLock
}

interface ResourceId extends ResourceType {
  readonly id: string
}

interface ResourceBase {
  reference?: string | null
  reference_origin?: string | null
  metadata?: Metadata
}

interface Resource extends ResourceBase, ResourceId {
  readonly created_at?: string
  readonly updated_at?: string
  readonly deleted_at?: string
}

interface ResourceCreate extends ResourceBase {}

interface ResourceUpdate extends ResourceBase {
  readonly id: string
}

interface ListMeta {
  readonly pageCount: number
  readonly recordCount: number
  readonly currentPage: number
  readonly recordsPerPage: number
}

class ListResponse<R> extends Array<R> {
  readonly meta: ListMeta

  constructor(meta: ListMeta, data: R[]) {
    super(...(data ?? []))
    this.meta = meta
  }

  first(): R | undefined {
    return this.length > 0 ? this[0] : undefined
  }

  last(): R | undefined {
    return this.length > 0 ? this[this.length - 1] : undefined
  }

  get(index: number): R | undefined {
    return this.length > 0 && index >= 0 ? this[index] : undefined
  }

  hasNextPage(): boolean {
    return this.meta.currentPage < this.meta.pageCount
  }

  hasPrevPage(): boolean {
    return this.meta.currentPage > 1
  }

  getRecordCount(): number {
    return this.meta.recordCount
  }

  getPageCount(): number {
    return this.meta.pageCount
  }

  getCurrentPage(): number {
    return this.meta.currentPage
  }

  get recordCount(): number {
    return this.meta.recordCount
  }

  get pageCount(): number {
    return this.meta.pageCount
  }
}

export type {
  Metadata,
  ResourceType,
  ResourceId,
  Resource,
  ResourceCreate,
  ResourceUpdate,
  ListMeta,
  ResourceRel
}

export { ListResponse }

// Resource adapters local configuration
interface ResourceAdapterConfig {
  // xyz?: boolean
}

type ResourcesInitConfig = ResourceAdapterConfig & ApiClientInitConfig
type ResourcesConfig = Partial<ResourcesInitConfig>

class ResourceAdapter {
  readonly #client: ApiClient

  readonly #config: ResourceAdapterConfig = {}

  #accessToken: string | undefined

  constructor(config: ResourcesInitConfig) {
    this.#client = ApiClient.create(config)
    this.localConfig(config)
  }

  get interceptors(): InterceptorManager {
    return this.#client.interceptors
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private localConfig(config: ResourceAdapterConfig): void {
    // if (typeof config.xyz !== 'undefined') this.#config.xyz = config.xyz
  }

  config(config: ResourcesConfig): this {
    console.log('config %o', config)

    // ResourceAdapter config
    this.localConfig(config)
    // Client config
    this.#client.config(config)

    return this
  }

  get clientInstance(): ApiClient {
    return this.#client
  }

  private async getAccessToken(): Promise<void> {
    const authInfo = await getPreferences<AuthState>(AUTH_STATE_KEY)
    if (authInfo != null) {
      this.#accessToken = authInfo.accessToken
      this.#client.config({ accessToken: this.#accessToken })
    }
  }

  async singleton<R extends Resource>(
    resource: ResourceType,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    // console.log(
    //   'singleton: %o, %O, %O',
    //   resource,
    //   params != null || {},
    //   options != null || {}
    // )

    await this.getAccessToken()

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const res = await this.#client.request(
      'get',
      `${resource.type}`,
      undefined,
      { ...options, params: queryParams }
    )
    const r = denormalize<R>(res) as R

    return r
  }

  async retrieve<R extends Resource>(
    resource: ResourceId,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    await this.getAccessToken()
    // console.log(
    //   'retrieve: %o, %O, %O',
    //   resource,
    //   params != null || {},
    //   options != null || {}
    // )

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const res = await this.#client.request(
      'get',
      `${resource.type}/${resource.id}`,
      undefined,
      { ...options, params: queryParams }
    )
    const r = denormalize<R>(res) as R

    return r
  }

  async list<R extends Resource>(
    resource: ResourceType,
    params?: QueryParamsList,
    options?: ResourcesConfig
  ): Promise<ListResponse<R>> {
    await this.getAccessToken()
    // console.log(
    //   'list: %o, %O, %O',
    //   resource,
    //   params != null || {},
    //   options != null || {}
    // )

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const res = await this.#client.request(
      'get',
      `${resource.type}`,
      undefined,
      { ...options, params: queryParams }
    )
    const r = denormalize<R>(res) as R[]

    const meta: ListMeta = {
      pageCount: Number(res.meta?.page_count),
      recordCount: Number(res.meta?.record_count),
      currentPage: params?.pageNumber ?? config.default.pageNumber,
      recordsPerPage: params?.pageSize ?? config.default.pageSize
    }

    return new ListResponse(meta, r)
  }

  async create<C extends ResourceCreate, R extends Resource>(
    resource: C & ResourceType,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    await this.getAccessToken()

    // console.log(
    //   'create: %o, %O, %O',
    //   resource,
    //   params != null || {},
    //   options != null || {}
    // )

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const data = normalize(resource)
    const res = await this.#client.request('post', resource.type, data, {
      ...options,
      params: queryParams
    })
    const r = denormalize<R>(res) as R

    return r
  }

  async update<U extends ResourceUpdate, R extends Resource>(
    resource: U & ResourceId,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    await this.getAccessToken()
    // console.log(
    //   'update: %o, %O, %O',
    //   resource,
    //   params != null || {},
    //   options != null || {}
    // )

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const data = normalize(resource)
    const res = await this.#client.request(
      'patch',
      `${resource.type}/${resource.id}`,
      data,
      { ...options, params: queryParams }
    )
    const r = denormalize<R>(res) as R

    return r
  }

  async delete(resource: ResourceId, options?: ResourcesConfig): Promise<void> {
    await this.getAccessToken()
    // console.log('delete: %o, %O', resource, options != null || {})
    await this.#client.request(
      'delete',
      `${resource.type}/${resource.id}`,
      undefined,
      options
    )
  }

  async fetch<R extends Resource>(
    resource: string | ResourceType,
    path: string,
    params?: QueryParams,
    options?: ResourcesConfig
  ): Promise<R | ListResponse<R>> {
    await this.getAccessToken()
    // console.log(
    //   'fetch: %o, %O, %O',
    //   path,
    //   params != null || {},
    //   options != null || {}
    // )

    const queryParams = generateQueryStringParams(params, resource)
    if (options?.params != null) Object.assign(queryParams, options?.params)

    const res = await this.#client.request('get', path, undefined, {
      ...options,
      params: queryParams
    })

    let r = denormalize<R>(res)

    if (options?.forceList === true) {
      if (!Array.isArray(r)) r = [r]
    }

    if (Array.isArray(r)) {
      const p = params as QueryParamsList
      const meta: ListMeta = {
        pageCount: Number(res.meta?.page_count),
        recordCount: Number(res.meta?.record_count),
        currentPage: p?.pageNumber ?? config.default.pageNumber,
        recordsPerPage: p?.pageSize ?? config.default.pageSize
      }
      return new ListResponse(meta, r)
    } else return r
  }
}

abstract class ApiResourceBase<R extends Resource> {
  static readonly TYPE: ResourceTypeLock
  protected readonly resources: ResourceAdapter

  constructor(adapter: ResourceAdapter) {
    // console.log('new resource instance: %s', this.type())
    this.resources = adapter
  }

  abstract relationship(id: string | ResourceId | null): ResourceRel

  abstract type(): ResourceTypeLock

  // reference, reference_origin and metadata attributes are always updatable
  async update(
    resource: ResourceUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    return await this.resources.update<ResourceUpdate, R>(
      { ...resource, type: this.type() },
      params,
      options
    )
  }
}

abstract class ApiResource<R extends Resource> extends ApiResourceBase<R> {
  async retrieve(
    id: string | ResourceId,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    return await this.resources.retrieve<R>(
      typeof id === 'string' ? { type: this.type(), id } : id,
      params,
      options
    )
  }

  async list(
    params?: QueryParamsList,
    options?: ResourcesConfig
  ): Promise<ListResponse<R>> {
    return await this.resources.list<R>({ type: this.type() }, params, options)
  }

  async count(
    filter?: QueryFilter | QueryParamsList,
    options?: ResourcesConfig
  ): Promise<number> {
    const params: QueryParamsList = {
      filters: isParamsList(filter) ? filter.filters : filter,
      pageNumber: 1,
      pageSize: 1
    }
    const response = await this.list(params, options)
    return await Promise.resolve(response.meta.recordCount)
  }
}

abstract class ApiSingleton<R extends Resource> extends ApiResourceBase<R> {
  async retrieve(
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<R> {
    return await this.resources.singleton<R>(
      { type: this.type() },
      params,
      options
    )
  }
}

export default ResourceAdapter

export { ApiResource, ApiSingleton }
export type { ResourcesConfig, ResourcesInitConfig }
