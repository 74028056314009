import * as api from './api'
import { ApiError } from './error'
import TackPay, { OPEN_API_SCHEMA_VERSION } from './tackpay'
import type { TackPayClient, TackPayInitConfig } from './tackpay'

/* Static functions */
export const TackPayStatic = {
  resources: (): readonly string[] => {
    return api.resourceList
  },

  // isSdkError: (error: unknown): error is SdkError => {
  //   return SdkError.isSdkError(error)
  // },

  isApiError: (error: unknown): error is ApiError => {
    return ApiError.isApiError(error)
  },

  init: (config: TackPayInitConfig): TackPayClient => {
    return TackPay(config)
  },

  get schemaVersion(): string {
    return OPEN_API_SCHEMA_VERSION
  }
}
