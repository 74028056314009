import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { ListResponse, type Collaborator } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import CollaboratorCard from './CollaboratorCard'
import EmptyCollaboratorInviteConfirm from './EmptyCollaboratorInviteConfirm'
import { useUserContext } from '#contexts/UserContext'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import { useCompanyContext } from '#contexts/CompanyContext'

export default function CollaboratorConfirmList(): JSX.Element {
  const { user } = useUserContext()

  const { company } = useCompanyContext()

  const fetchConfirmCollaborators = async ({
    pageParam = 1
  }): Promise<ListResponse<Collaborator>> => {
    if (company != null) {
      const collaborators = await sdk.collaborators.list({
        filters: {
          eq: {
            status: 'invited_accepted',
            company_id: company?.id
          }
        },
        include: ['user.person', 'jars'],
        sort: {
          created_at: 'desc'
        },
        pageNumber: pageParam
      })

      return collaborators.filter(
        (collaborator) => collaborator?.user?.id !== user?.id
      ) as ListResponse<Collaborator>
    }

    return new ListResponse<Collaborator>(
      { currentPage: 1, pageCount: 0, recordCount: 0, recordsPerPage: 0 },
      []
    )
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['confirmed-collaborators'],
    queryFn: fetchConfirmCollaborators,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined,
    enabled: user != null && company != null
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (!hasMore || hasMore == null) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  return status === 'pending' ? (
    <CardSkeleton />
  ) : status === 'error' ? (
    <ErrorBox error={error} />
  ) : data.pages[0].length === 0 && !isFetching ? (
    <EmptyCollaboratorInviteConfirm />
  ) : (
    <>
      {data.pages.map((page, i) => (
        <div key={i} className='space-y-6'>
          {page.map((collaborator) => {
            return (
              <CollaboratorCard
                key={collaborator.id}
                collaborator={collaborator}
              />
            )
          })}
        </div>
      ))}
      <IonInfiniteScroll
        disabled={hasMore == null || !hasMore}
        onIonInfinite={loadNext}
      >
        <IonInfiniteScrollContent
          loadingSpinner='circles'
          loadingText='Loading...'
          color='primary'
        />
      </IonInfiniteScroll>
    </>
  )
}
