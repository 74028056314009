import Button from '#components/ui/Button'
import { IonCol, IonRow } from '@ionic/react'
import { useState } from 'react'
import DeleteModalDeduction from './DeleteModalDeduction'
import { useTeamContext } from '#contexts/JarContext'
import ErrorBox from '#components/errors/ErrorBox'
import SaveModalDeduction from './SaveModalDeduction'

interface PercentageDeductionProps {
  percentage: number
}

export default function ButtonDeduction({
  percentage
}: PercentageDeductionProps): JSX.Element {
  const { isLoading, error, jar } = useTeamContext()

  const [showUpdate, setShowUpdate] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  if (isLoading) return <></>

  if (error != null) return <ErrorBox error={error} />

  return jar != null &&
    (jar?.stripe_gateway_account == null || jar?.deduction_setting == null) ? (
    <></>
  ) : (
    <IonRow className='mt-5 pb-10'>
      <SaveModalDeduction
        show={showUpdate}
        setShow={setShowUpdate}
        percentage={percentage}
        type='update'
      />
      <IonCol>
        <Button
          className='w-full'
          onClick={() => {
            setShowUpdate(true)
          }}
          disabled={percentage === jar?.deduction_setting?.percentage}
        >
          deduction.update
        </Button>

        {jar?.deduction_setting != null &&
          jar?.deduction_setting.percentage > 0 && (
            <Button
              className='w-full'
              color='danger'
              onClick={() => {
                setShowDeleteModal(true)
              }}
            >
              deduction.cancel
            </Button>
          )}
      </IonCol>
      <DeleteModalDeduction
        percentage={percentage}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      />
    </IonRow>
  )
}
