import { type UserCategory } from '#tackpay-sdk'

interface CategoryList {
  category: UserCategory[]
  name: string
  img: string
  value: string
}

const CATEGORIES: CategoryList[] = [
  /** Tipped */
  {
    category: ['tipped'],
    name: 'subcategory.Fattorino',
    img: '/assets/img/driver.svg',
    value: 'fattorino'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Barista',
    img: '/assets/img/barman.svg',
    value: 'barista'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Cameriere',
    img: '/assets/img/waiter.svg',
    value: 'cameriere'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Receptionist',
    img: '/assets/img/recepcionist.svg',
    value: 'receptionist'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Artista',
    img: '/assets/img/artist.svg',
    value: 'artista'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Barbiere',
    img: '/assets/img/barber.svg',
    value: 'barber'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Rider',
    img: '/assets/img/rider.svg',
    value: 'rider'
  },
  {
    category: ['tipped'],
    name: 'subcategory.Cuoco',
    img: '/assets/img/cook.svg',
    value: 'cuoco'
  },

  /** Business */
  {
    category: ['business', 'manager'],
    name: 'subcategory.Hotel',
    img: '/assets/img/hotel.svg',
    value: 'hotel'
  },
  {
    category: ['business', 'manager'],
    name: 'subcategory.Ristorante',
    img: '/assets/img/restaurant.svg',
    value: 'ristorante'
  },
  {
    category: ['business', 'manager'],
    name: 'subcategory.Bar',
    img: '/assets/img/bar.svg',
    value: 'bar'
  },
  {
    category: ['business', 'manager'],
    name: 'subcategory.Pizzeria',
    img: '/assets/img/pizzeria.svg',
    value: 'pizzeria'
  }
]

export const getRegistrationCategoryList = (
  category: UserCategory
): CategoryList[] => CATEGORIES.filter((c) => c.category.includes(category))
