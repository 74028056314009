import { type Birthdate } from '#tackpay-sdk'

export default function formattedBirthdate(
  birthdate: Date | string
): Birthdate {
  const dateObj =
    typeof birthdate === 'string' ? new Date(birthdate) : birthdate

  const day = dateObj.getDate()
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()

  return { day, month, year }
}
