import ErrorBox from '#components/errors/ErrorBox'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import CheckoutSkeleton from '../CheckoutSkeleton'
import CheckoutReviewContent from './CheckoutReviewContent'

export default function CheckoutReviewSection(): JSX.Element {
  const { isLoading, error, checkout } = useCheckoutContext()

  if (isLoading) return <CheckoutSkeleton />

  if (error != null) return <ErrorBox error={error} />

  if (checkout == null)
    return (
      <ErrorBox
        error={{
          title: 'Checkout not found',
          detail: 'The checkout you are looking for does not exist',
          code: 'checkout_not_found',
          status: 422
        }}
      />
    )

  return <CheckoutReviewContent checkout={checkout} />
}
