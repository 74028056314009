import { type Deduction } from '#tackpay-sdk'

export default function createDeductionCSV(
  deductions?: Deduction[],
  lang: string = 'en',
  category: 'jar' | 'tipped' = 'tipped'
): string {
  const labels: Record<string, Record<'jar' | 'tipped', string>> = {
    en: {
      jar: 'ID,Date,Time,Jar,Tip,Currency,Tipper name',
      tipped: 'ID,Date,Time,Jar,Amount,Currency'
    },
    it: {
      jar: 'ID,Data,Ora,Barattolo,Mancia,Valuta,Nome tipper',
      tipped: 'ID,Data,Ora,Barattolo,Importo,Valuta'
    },
    es: {
      jar: 'ID,Fecha,Hora,Bote,Propina,Moneda,Tipper nombre',
      tipped: 'ID,Fecha,Hora,Bote,Importe,Moneda'
    }
  }

  const getFormattedDate = (
    dateString?: string
  ): { date: string; time: string } => {
    const date = new Date(dateString ?? '')
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hour = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return {
      date: `${year}/${month}/${day}`,
      time: `${hour}:${minutes}`
    }
  }

  const formatCsvRow = (fields: Array<string | number | undefined>): string => {
    return fields
      .map((field) => {
        if (typeof field === 'string') {
          return `"${field.replace(/"/g, '""')}"`
        }
        return field
      })
      .join(',')
  }

  let csvContent = labels[lang][category] + '\n' // Intestazione

  deductions?.forEach((deduction) => {
    const { date, time } = getFormattedDate(deduction.created_at)
    const grossAmount = (deduction.amount / 100).toFixed(2).replace('.', ',')
    const jarName = deduction.jar?.name ?? 'unknown'

    const row = formatCsvRow([
      deduction.id,
      date,
      time,
      jarName,
      grossAmount,
      deduction.currency
    ])

    csvContent += row + '\n'
  })

  return csvContent
}
