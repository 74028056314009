class TextUtil {
  isEmpty(str?: string): boolean {
    return str == null || (str != null && str.trim().length === 0)
  }

  isEmail(email?: string): boolean {
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    return !this.isEmpty(email) && emailRegex.test(email as string)
  }
}

export default new TextUtil()
