import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Jar } from './jars'

type CollaboratorType = 'collaborators'

type CollaboratorRel = ResourceRel & { type: CollaboratorType }

type CollaboratorStatus =
  | 'invited_pending'
  | 'invited_accepted'
  | 'invited_rejected'

type CollaboratorRole = 'admin' | 'manager'

type CompayRel = ResourceRel & { type: 'companies' }

interface Collaborator extends Resource {
  readonly type: CollaboratorType
  email: string
  last_activity?: Date
  status: CollaboratorStatus
  role: CollaboratorRole
  jar_ids?: string[]
  user?: User
  user_invite?: User
  company?: Company
  jars?: Jar[]
}

interface CollaboratorCreate extends ResourceCreate {
  email: string
  role: CollaboratorRole
  jar_ids?: string[]
  company: CompayRel
}

interface CollaboratorUpdate extends ResourceUpdate {
  role?: CollaboratorRole
  jar_ids?: string[]
}

class Collaborators extends ApiResource<Collaborator> {
  static readonly TYPE: CollaboratorType = 'collaborators' as const

  async create(
    resource: CollaboratorCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Collaborator> {
    return await this.resources.create<CollaboratorCreate, Collaborator>(
      { ...resource, type: Collaborators.TYPE },
      params,
      options
    )
  }

  async update(
    resource: CollaboratorUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Collaborator> {
    return await this.resources.update<CollaboratorUpdate, Collaborator>(
      { ...resource, type: Collaborators.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Collaborators.TYPE } : id,
      options
    )
  }

  isCollaborator(resource: any): resource is Collaborator {
    return resource.type != null && resource.type === Collaborators.TYPE
  }

  relationship(id: string | ResourceRel | null): CollaboratorRel {
    return id === null || typeof id === 'string'
      ? { id, type: Collaborators.TYPE }
      : { id: id.id, type: Collaborators.TYPE }
  }

  type(): CollaboratorType {
    return Collaborators.TYPE
  }
}

export type {
  Collaborator,
  CollaboratorCreate,
  CollaboratorUpdate,
  CollaboratorRole
}
export default Collaborators
