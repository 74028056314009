import AppLayout from '#components/layouts/app/AppLayout'
import AddBankButton from '#components/pages/banks/AddBankButton'
import BankAccountSection from '#components/pages/banks/BankAccountSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankContainer from '#contexts/BankContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { IonCol } from '@ionic/react'
import { useParams } from 'react-router'

export default function BankAccount(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings/withdrawals`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person']
        }
      }}
    >
      <BankContainer>
        <Title hasSubtitle>bank.title</Title>
        <SubTitle>bank.subtitle</SubTitle>
        <IonCol className='block ion-no-padding ion-no-margin'>
          <BankAccountSection />
        </IonCol>
        <AddBankButton
          href={`/dashboard/${category}/settings/withdrawals/banks/add`}
        />
      </BankContainer>
    </AppLayout>
  )
}
