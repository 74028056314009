import {
  type TokenCreate,
  type QueryParamsRetrieve,
  type Token
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

type CreateTokenFunction = UseMutationResult<
  Token,
  unknown,
  TokenCreate,
  unknown
>

interface TokenContextValue {
  token?: Token | null
  isLoading: boolean
  error?: any
  createToken?: CreateTokenFunction
}

const tokenInitialValue: TokenContextValue = {
  token: null,
  isLoading: false,
  error: null
}

const TokenContext = createContext<TokenContextValue>(tokenInitialValue)

export const useTokenContext = (): TokenContextValue => {
  const context = useContext(TokenContext)
  if (context == null) {
    throw new Error(
      'useTokenContext() can only be used inside of <TokenContextProvider />, ' +
        'please declare it at a higher level.'
    )
  }
  return context
}

interface CheckoutSettingContainerProps {
  /**
   * The children of the component.
   */
  children: React.ReactNode
  /**
   * If automatic fetching is enabled, the component will fetch the data on mount
   * @default true
   */
  fetch?: boolean
  /**
   * The query params to use when fetching the data
   */
  params?: QueryParamsRetrieve
  /**
   * The value of the token
   */
  value?: string
}

const handleRetrieveToken = async (
  value: string,
  params?: QueryParamsRetrieve
): Promise<Token> => {
  const tokens = await sdk.tokens.list({
    filters: {
      eq: {
        value
      }
    },
    include: params?.include
  })
  return tokens[0]
}

const handleCreateToken = async (tokenCreate: TokenCreate): Promise<Token> => {
  return await sdk.tokens.create(tokenCreate)
}

export default function TokenContainer(
  props: CheckoutSettingContainerProps
): JSX.Element {
  const { children, fetch = true, params, value } = props

  const queryClient = useQueryClient()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: token,
    isLoading,
    error
  } = useQuery({
    queryKey: ['token', value, params],
    queryFn: async () => await handleRetrieveToken(value ?? '', params),
    enabled: fetch && !initialFetchComplete
  })

  useEffect(() => {
    if (fetch) {
      void queryClient.invalidateQueries({
        queryKey: ['token', value ?? '', params]
      })
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const useCreateToken = useMutation({
    mutationFn: handleCreateToken
  })

  const tokenContextValue: TokenContextValue = {
    token,
    isLoading,
    error,
    createToken: useCreateToken
  }

  return (
    <TokenContext.Provider value={tokenContextValue}>
      {children}
    </TokenContext.Provider>
  )
}
