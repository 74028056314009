import {
  type PersonCreate,
  type BankAccountCreate,
  type JarCreate,
  type AddressCreate,
  type StripeGatewayAccountCreate,
  type BankAccount,
  type StripeGatewayAccount
} from '#tackpay-sdk'
import { type Photo } from '@capacitor/camera'
import { useEffect, useState } from 'react'
import {
  destroyPreferences,
  getPreferences,
  savePreferences
} from 'storage/preferences'

export interface CreateTeamStore {
  teamCreate?: Partial<JarCreate> & { photo?: Photo }
  bankCreate?: Partial<BankAccountCreate>
  isSameCompany?: boolean
  isSameIban?: boolean
  personCreate?: Partial<PersonCreate>
  addressCreate?: Partial<AddressCreate>
  stripeGatewayAccount?: Partial<StripeGatewayAccountCreate>
  bankAccount?: BankAccount
  stripeGatewayAccountExistent?: StripeGatewayAccount
  setBankBankAccount: (bankAccount: BankAccount) => void
  setStripeGatewayAccountExistent: (
    stripeGatewayAccount: StripeGatewayAccount
  ) => void
  setTeamCreate: (jar: Partial<JarCreate> & { photo?: Photo }) => void
  setBankCreate: (bank: Partial<BankAccountCreate>) => void
  setIsSameCompany: (isSameCompany: boolean) => void
  setIsSameIban: (isSameIban: boolean) => void
  setPersonCreate: (person: Partial<PersonCreate>) => void
  setAddressCreate: (address: Partial<AddressCreate>) => void
  setStripeGatewayAccount: (
    stripeGatewayAccount: Partial<StripeGatewayAccountCreate>
  ) => void
  clearStore: () => void
}

export interface CreateTeamValue {
  teamCreate?: Partial<JarCreate>
  bankCreate?: Partial<BankAccountCreate>
  isSameCompany?: boolean
  isSameIban?: boolean
  personCreate?: Partial<PersonCreate>
  addressCreate?: Partial<AddressCreate>
  stripeGatewayAccount?: Partial<StripeGatewayAccountCreate>
  stripeGatewayAccountId?: string
  bankAccountId?: string
}

export default function useCreateTeamStore(): CreateTeamStore {
  const [teamCreate, setTeamCreate] = useState<Partial<JarCreate>>()

  const [bankCreate, setBankCreate] = useState<Partial<BankAccountCreate>>()

  const [isSameCompany, setIsSameCompany] = useState<boolean | undefined>()

  const [isSameIban, setIsSameIban] = useState<boolean | undefined>()

  const [personCreate, setPersonCreate] = useState<Partial<PersonCreate>>()

  const [addressCreate, setAddressCreate] = useState<Partial<AddressCreate>>()

  const [bankAccount, setBankBankAccount] = useState<BankAccount | undefined>()

  const [stripeGatewayAccountExistent, setStripeGatewayAccountExistent] =
    useState<StripeGatewayAccount | undefined>()

  const [stripeGatewayAccount, setStripeGatewayAccount] =
    useState<Partial<StripeGatewayAccountCreate>>()

  const teamKey = 'teamCreate'

  const bankKey = 'bankCreate'

  const sameCompanyKey = 'isSameCompany'

  const sameIbanKey = 'isSameIban'

  const personKey = 'personCreate'

  const addressKey = 'addressCreate'

  const stripeGatewayAccountKey = 'stripeGatewayAccountCreate'

  const stripeGatewayAccountIdKey = 'stripeGatewayAccountId'

  const bankAccountIdKey = 'bankAccountId'

  const fetchStore = async (key: string): Promise<void> => {
    const data = (await getPreferences(key)) as any

    if (key === teamKey) setTeamCreate(data)

    if (key === bankKey) setBankCreate(data)

    if (key === sameCompanyKey) setIsSameCompany(data)

    if (key === sameIbanKey) setIsSameIban(data)

    if (key === personKey) setPersonCreate(data)

    if (key === addressKey) setAddressCreate(data)

    if (key === stripeGatewayAccountKey) setStripeGatewayAccount(data)

    if (key === bankAccountIdKey) setBankBankAccount(data)

    if (key === stripeGatewayAccountIdKey) setStripeGatewayAccountExistent(data)
  }

  const clearStore = (): void => {
    destroyPreferences(teamKey)
    destroyPreferences(bankKey)
    destroyPreferences(sameCompanyKey)
    destroyPreferences(sameIbanKey)
    destroyPreferences(personKey)
    destroyPreferences(addressKey)
    destroyPreferences(stripeGatewayAccountKey)
    destroyPreferences(bankAccountIdKey)
    destroyPreferences(stripeGatewayAccountIdKey)
  }

  const handleSetTeamCreate = (jar: Partial<JarCreate>): void => {
    const newTeamCreate = { ...teamCreate, ...jar }
    setTeamCreate(newTeamCreate)
    void savePreferences(teamKey, newTeamCreate)
  }

  const handleSetBankCreate = (bank: Partial<BankAccountCreate>): void => {
    const newBankCreate = { ...bankCreate, ...bank }
    setBankCreate(newBankCreate)
    void savePreferences(bankKey, newBankCreate)
  }

  const handleIsSameCompany = (isSameCompany: boolean): void => {
    setIsSameCompany(isSameCompany)
    void savePreferences(sameCompanyKey, isSameCompany)
  }

  const handleIsSameIban = (isSameIban: boolean): void => {
    setIsSameIban(isSameIban)
    void savePreferences(sameIbanKey, isSameIban)
  }

  const handleSetPersonCreate = (person: Partial<PersonCreate>): void => {
    const newPersonCreate = { ...personCreate, ...person }
    setPersonCreate(newPersonCreate)
    void savePreferences(personKey, newPersonCreate)
  }

  const handleSetAddressCreate = (address: Partial<AddressCreate>): void => {
    const newAddressCreate = { ...addressCreate, ...address }
    setAddressCreate(newAddressCreate)
    void savePreferences(addressKey, newAddressCreate)
  }

  const handleSetBankAccount = (bankAccount: BankAccount): void => {
    const newBankAccount = { ...bankAccount, ...bankAccount }
    setBankBankAccount(newBankAccount)
    void savePreferences(bankAccountIdKey, newBankAccount)
  }

  const handleSetStripeGatewayAccountExistent = (
    stripeGatewayAccount: StripeGatewayAccount
  ): void => {
    const newStripeGatewayAccount = {
      ...stripeGatewayAccount,
      ...stripeGatewayAccount
    }
    setStripeGatewayAccountExistent(newStripeGatewayAccount)
    void savePreferences(stripeGatewayAccountIdKey, newStripeGatewayAccount)
  }

  const handleSetStripeGatewayAccount = (
    stripeGatewayAccount: Partial<StripeGatewayAccountCreate>
  ): void => {
    const newStripeGatewayAccount = {
      ...stripeGatewayAccount,
      ...stripeGatewayAccount
    }
    setStripeGatewayAccount(newStripeGatewayAccount)
    void savePreferences(stripeGatewayAccountKey, newStripeGatewayAccount)
  }

  useEffect(() => {
    if (teamCreate == null) void fetchStore(teamKey)

    if (bankCreate == null) void fetchStore(bankKey)

    if (isSameCompany == null) void fetchStore(sameCompanyKey)

    if (isSameIban == null) void fetchStore(sameIbanKey)

    if (personCreate == null) void fetchStore(personKey)

    if (addressCreate == null) void fetchStore(addressKey)

    if (stripeGatewayAccount == null) void fetchStore(stripeGatewayAccountKey)

    if (bankAccount == null) void fetchStore(bankAccountIdKey)

    if (stripeGatewayAccountExistent == null)
      void fetchStore(stripeGatewayAccountIdKey)
  }, [])

  return {
    teamCreate,
    bankCreate,
    isSameCompany,
    isSameIban,
    personCreate,
    addressCreate,
    stripeGatewayAccount,
    bankAccount,
    stripeGatewayAccountExistent,
    setTeamCreate: handleSetTeamCreate,
    setBankCreate: handleSetBankCreate,
    setIsSameCompany: handleIsSameCompany,
    setIsSameIban: handleIsSameIban,
    setPersonCreate: handleSetPersonCreate,
    setAddressCreate: handleSetAddressCreate,
    setStripeGatewayAccount: handleSetStripeGatewayAccount,
    setBankBankAccount: handleSetBankAccount,
    setStripeGatewayAccountExistent: handleSetStripeGatewayAccountExistent,
    clearStore
  }
}
