import AppLayout from '#components/layouts/app/AppLayout'
import TippedMaterialDownloadSection from '#components/pages/materials/TippedMaterialDownloadSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import { useParams } from 'react-router'

export default function MaterialDownload(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/materials`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: isBusiness(category) ? ['person'] : ['person', 'tackpay_id']
        }
      }}
    >
      <Title>material.download.title</Title>
      <SubTitle>material.download.subtitle</SubTitle>
      {!isBusiness(category) && <TippedMaterialDownloadSection />}
    </AppLayout>
  )
}
