import CardProduct from '#components/common/CardProduct'
import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import { useCompanyContext } from '#contexts/CompanyContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { isPlatform } from '@ionic/core'
import { useState } from 'react'
import { useParams } from 'react-router'
import { Browser } from '@capacitor/browser'
import { useIonRouter } from '@ionic/react'

function HandleProductKit(): JSX.Element {
  const { company } = useCompanyContext()

  const { routeInfo } = useIonRouter()

  const { startLoading, stopLoading } = useLoading('product.loading')

  const { showToast } = useToast('warning')

  const [quantity, setQuantity] = useState(0)

  const handleSubmit = (): void => {
    let returnUrl =
      (process.env.REACT_APP_FRONTEND_URL ?? 'https://app.tackpay.net') +
      routeInfo.pathname

    if (isPlatform('hybrid')) {
      returnUrl += '?refresh=available'
    }

    const price =
      process.env.REACT_APP_KIT_PRICE ?? 'price_1OUOOXCDf41ktEVo02gx3HtS'

    if (quantity > 0 && company != null) {
      startLoading()
      sdk.product_links
        .create({
          cancel_url: returnUrl,
          product_reference: price,
          quantity,
          return_url: returnUrl,
          customer: {
            id: company.customer?.id ?? '',
            type: 'customers'
          }
        })
        .then((res) => {
          stopLoading()
          if (isPlatform('hybrid')) {
            Browser.open({ url: res.url })
              .then(() => {
                console.log('Opened browser!')
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            window.location.href = res.url
          }
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <div className='my-16'>
      <CardProduct
        src='/assets/img/kit-tackpay.png'
        subtitle='standard kit'
        title='kit.title'
        description='kit.description'
        quantity={quantity}
        setQuantity={setQuantity}
        showQuantity
        action={handleSubmit}
      />
    </div>
  )
}

export default function TeamMaterialKit(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/materials`
        }
      }}
      companyProps={{
        params: {
          include: ['customer']
        }
      }}
    >
      <Title>material.kit.title</Title>
      <HandleProductKit />
    </AppLayout>
  )
}
