import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import TeamMembers from './views/TeamMembers'
import AddTeamMember from './views/AddTeamMember'
import MemberInfo from './views/MemberInfo'

const baseRoute = `/dashboard/:category/jars/:jarId/members`

export default function TeamMemberRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={baseRoute}>
          <TeamMembers />
        </Route>
        <Route exact path={`${baseRoute}/:memberId`}>
          <MemberInfo />
        </Route>
        <Route exact path={`${baseRoute}/add/new`}>
          <AddTeamMember />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
