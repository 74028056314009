import SubTitle from '#components/ui/SubTitle'
import { type Token } from '#tackpay-sdk'

interface Props {
  token: Token
}

export default function RegistrationMemberTitle({ token }: Props): JSX.Element {
  console.log(token)
  return (
    <SubTitle
      translationOptions={{
        translate: 'yes',
        data: {
          companyName: token?.jar?.company?.name ?? token?.company?.name
        }
      }}
    >
      registration.member.subtitle
    </SubTitle>
  )
}
