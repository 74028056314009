import AppLayout from '#components/layouts/app/AppLayout'
import CardChangeUserEmail from '#components/pages/identities/CardChangeUserEmail'
import CardChangeUserPassword from '#components/pages/identities/CardChangeUserPassword'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'

export default function AccesData(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person']
        }
      }}
    >
      <Title hasSubtitle>access_data</Title>

      <div className='space-y-5 mt-10'>
        <CardChangeUserEmail />
        <CardChangeUserPassword />
      </div>
    </AppLayout>
  )
}
