import ButtonStep from '#components/common/ButtonStep'
import CardCategory from '#components/common/CardCategory'
import Input, {
  type InputOnChange,
  type InputValue
} from '#components/ui/Input'
import ProgressBar from '#components/ui/ProgessBar'
import Text from '#components/ui/Text'
import { getRegistrationCategoryList } from '#constants/categories'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import useToast from '#hooks/useToast'
import { type UserCategory } from '#tackpay-sdk'
import textUtil from '#utils/textUtil'
import { IonCol, useIonRouter } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  category: UserCategory
}
export default function RegistrationSubcategory({
  category
}: Props): JSX.Element {
  const { t } = useTranslation()

  const lists = getRegistrationCategoryList(category)

  lists.forEach((list) => {
    list.name = t(list.name)
  })

  const [customCategory, setCustomCategory] = useState<InputValue>('')

  const [showCustomCategory, setShowCustomCategory] = useState<boolean>(false)

  const { userData, setUserData } = useRegistrationContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleCategorySelected = (subcategory: string): void => {
    setUserData({ subcategory })

    if (['business', 'manager'].includes(category)) {
      push(`/registrations/${category}/types`)
    } else push(`/registrations/${category}/persons`)
  }

  const handleCustomCategory: InputOnChange = (e) => {
    const { value } = e.target
    if (typeof value === 'string') {
      setCustomCategory(value)
      setUserData({ subcategory: value })
    }
  }

  const handlePrevious = (): void => {
    push('/registrations', 'back')
  }

  const handleNextStep = (): void => {
    if (
      textUtil.isEmpty(String(customCategory)) &&
      textUtil.isEmpty(userData?.subcategory)
    ) {
      showToast(undefined, 'registration.error.category')
      return
    }

    if (['business', 'manager'].includes(category)) {
      push(`/registrations/${category}/types`)
    } else push(`/registrations/${category}/persons`)
  }

  return (
    <>
      <ProgressBar
        text='registration.chips.category.title'
        value={0.1}
        testId='progress-registration.chips.category.title'
      />
      <IonCol className='space-y-6'>
        {lists
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((list) => (
            <CardCategory
              key={list.name}
              title={list.name}
              cardProps={{
                onClick: () => {
                  handleCategorySelected(list.value)
                }
              }}
              options={{
                avatarOptions: {
                  className: 'w-16 h-16'
                },
                imgOptions: {
                  src: list.img,
                  alt: list.name
                }
              }}
              showCheckIcon={userData?.subcategory === list.value}
            />
          ))}
        <Text
          onClick={() => {
            setShowCustomCategory(!showCustomCategory)
          }}
          className='ion-text-center mt-8 underline cursor-pointer'
          data-testId='registration.showCustomCategory'
        >
          registration.showCustomCategory
        </Text>
        {showCustomCategory && (
          <Input
            name='category'
            type='text'
            value={customCategory}
            onIonInput={handleCustomCategory}
            labelOptions={{
              children: 'categoryCustom'
            }}
            testId='input-add-category'
          />
        )}
      </IonCol>
      <ButtonStep handleBack={handlePrevious} handleNext={handleNextStep} />
    </>
  )
}
