import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Text from '#components/ui/Text'
import { ListResponse, type Comment } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import CardComment from './CardComment'
import EmptyComment from './EmptyComment'

interface Props {
  jarId?: string
  userId?: string
}
export default function CommentSection({ jarId, userId }: Props): JSX.Element {
  const fetchComments = async ({
    pageParam = 1
  }): Promise<ListResponse<Comment>> => {
    if (jarId != null && jarId !== '') {
      return await sdk.comments.jar(jarId, pageParam, 20)
    } else if (userId != null && userId !== '') {
      return await sdk.comments.user(userId ?? '', pageParam, 20)
    }

    return new ListResponse(
      {
        currentPage: 1,
        pageCount: 0,
        recordCount: 0,
        recordsPerPage: 0
      },
      []
    )
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['list-comments', jarId, userId],
    queryFn: fetchComments,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage?.hasNextPage() ? lastPage.getPageCount() + 1 : undefined
  })
  const loadNext = (e: IonInfiniteScrollCustomEvent<void>): void => {
    if (!hasMore || hasMore == null) {
      void e.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void e.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void e.target.complete()
        })
    }
  }
  return (
    <div className='mt-10'>
      <div className='pb-10'>
        <Text fontWeight='font-semibold' color='gray-900' size='text-xl'>
          your_comment
        </Text>
      </div>
      {status === 'pending' ? (
        <CardSkeleton />
      ) : status === 'error' ? (
        <ErrorBox error={error} />
      ) : data?.pages?.[0]?.length === 0 && !isFetching ? (
        <EmptyComment />
      ) : (
        <div className='space-y-6'>
          {data.pages.map((page, i) => (
            <div key={i} className='space-y-6'>
              {page?.map((comment) => {
                return <CardComment comment={comment} key={comment.id} />
              })}
            </div>
          ))}
          <IonInfiniteScroll
            disabled={hasMore == null || !hasMore}
            onIonInfinite={loadNext}
          >
            <IonInfiniteScrollContent
              loadingSpinner='circles'
              loadingText='Loading...'
              color='primary'
            />
          </IonInfiniteScroll>
        </div>
      )}
    </div>
  )
}
