import { IonCol, IonRow } from '@ionic/react'
import Text from '#components/ui/Text'
import Button from '#components/ui/Button'
import { type ChangeEventHandler, useState } from 'react'
import { getSymbolCurrency } from '#constants/country'
import CurrencyInput from '#components/common/CurrencyInput'

interface Props {
  currency: string
  setOpenModal: (arg: boolean) => void
  setCurrentAmount: (arg: number) => void
  currentAmount?: number
  submitFunction: (e?: React.FormEvent<HTMLFormElement>) => void
}

interface Percentage {
  icon: string
  value: number
}

export default function CustomPercentageForm({
  currency,
  currentAmount,
  setOpenModal,
  setCurrentAmount,
  submitFunction
}: Props): JSX.Element {
  const [amountReceipit, setAmountReceipit] = useState<number>()

  const [percentageAmount, setPercentageAmount] = useState<number>()

  const [percentage, setPercentage] = useState<number>()

  const percentages: Percentage[] = [
    { icon: '🙂', value: 10 },
    { icon: '😁', value: 15 },
    { icon: '😍', value: 20 },
    { icon: '🫶​', value: 25 }
  ]

  const handleSubmit = (): void => {
    setOpenModal(false)
    submitFunction()
  }

  const handleAmountReceiptChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    let value = String(e.target.value)
    value = value.replace(getSymbolCurrency(currency), '')
    value = value.replace(',', '.')
    const amount = parseFloat(value)
    setAmountReceipit(amount)

    if (percentage !== 0 && percentage != null) {
      const newAmount = Number(((amount * percentage) / 100).toFixed(2))
      setPercentageAmount(newAmount)
      setCurrentAmount(Number(newAmount.toFixed(2)))
    }
  }

  const handlePercentageChange = (value: number): void => {
    setPercentage(value)

    if (amountReceipit !== 0 && amountReceipit != null) {
      const amount = Number(((amountReceipit * value) / 100).toFixed(2))
      setPercentageAmount(amount)
      setCurrentAmount(Number(amount.toFixed(2)))
    }
  }

  const height = window.screen.height

  const heightView = (data: number): string => {
    if (data < 667 && data !== undefined) {
      return 'space-y-3'
    }
    if (data === 667 && data !== undefined) {
      return 'space-y-7'
    }

    if (data > 928 && data !== undefined) return 'space-y-20'

    return 'space-y-12'
  }

  const controlHeight = heightView(height)

  return (
    <div className={`px-4 mt-4 ${controlHeight}`}>
      <IonRow className='ion-justify-content-center pb-0'>
        <Text className='font-semibold'>checkout.percentageAmount.title</Text>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonRow className='ion-justify-content-center w-full mb-1'>
            <Text className='text-black font-medium'>
              checkout.percentageAmount.bill
            </Text>
          </IonRow>
          <CurrencyInput
            maskOptions={{
              prefix: getSymbolCurrency(currency)
            }}
            inputOptions={{
              placeholder: `${getSymbolCurrency(currency)} 0.00`,
              onChange: handleAmountReceiptChange,
              inputMode: 'decimal'
            }}
          />
          {/* <InputAmount
              amount={amountReceipit}
              handlerChange={handleAmountReceiptChange}
              currency={currency}
            /> */}
        </IonCol>
      </IonRow>
      <IonRow className='ion-no-margin ion-no-padding'>
        <IonCol size='12' className='ion-no-margin ion-no-padding'>
          <IonRow className='ion-justify-content-center mb-1 ion-no-padding'>
            <Text className='text-base text-black'>
              checkout.percentageAmount.service
            </Text>
          </IonRow>

          <IonRow className='ion-justify-content-around w-full ion-no-margin ion-no-padding'>
            {percentages.map((data, index) => {
              const isActive = data.value === percentage
              return (
                <div
                  key={index}
                  className='flex flex-col ion-align-items-center'
                >
                  <Button
                    color={isActive ? 'primary' : 'button-icon'}
                    onClick={() => {
                      handlePercentageChange(data.value)
                    }}
                    className='w-14 h-12 shadow rounded-2xl'
                  >
                    {data.icon}
                  </Button>
                  <Text>{`${data.value}%`}</Text>
                </div>
              )
            })}
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className='ion-no-margin ion-no-padding'>
        <IonCol size='12' className='ion-no-margin ion-no-padding'>
          <IonRow className='ion-justify-content-center w-full mb-1 ion-no-padding'>
            <Text className='text-black font-medium'>
              checkout.percentageAmount.tip
            </Text>
          </IonRow>
          <IonRow className='ion-justify-content-center ion-no-padding w-full'>
            <div className='w-2/12 flex justify-center items-center border border-gray-400 rounded-tl-md rounded-bl-md inputs'>
              <div className='text-gray-900  w-5'>
                {getSymbolCurrency(currency)}
              </div>
            </div>
            <div className='border border-gray-400 rounded-r-md w-10/12 pl-5 text-xl text-gray-900 py-2 inputs '>
              {percentageAmount?.toFixed(2) ?? '0.00'}
            </div>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow className='ion-no-margin ion-no-padding w-full'>
        <Button
          onClick={handleSubmit}
          className='ion-no-margin w-full'
          translationOptions={{
            translate: 'yes',
            data: {
              amount: currentAmount === 0 ? '' : percentageAmount?.toFixed(2),
              currency:
                currentAmount === 0 ? '' : getSymbolCurrency(currency ?? 'eur')
            }
          }}
        >
          confirmAmount
        </Button>
      </IonRow>
    </div>
  )
}
