import { IonText } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { type TranslationOptions } from '#types/translate'

interface TextProps extends ComponentProps<typeof IonText> {
  /**
   * The text to display in the Text.
   */
  children: React.ReactNode
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * The size of the text.
   * Default: `lg:text-xl text-lg`
   */
  size?: string
  /**
   * The font weight of the text.
   * Default: `font-normal`
   */
  fontWeight?: string
}
export default function Text(props: TextProps): JSX.Element {
  const { children, translationOptions, size, fontWeight, ...ionTextProps } =
    props

  const { t } = useTranslation()

  const text =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children
  const color = ionTextProps?.color ?? 'gray-500'

  const textBaseSize = 'lg:text-xl text-lg'

  const textSize = size ?? textBaseSize

  const fontWeightClass = fontWeight ?? 'font-normal'

  const textBaseClass = ''

  const textClass = classNames(
    ionTextProps?.className,
    textBaseClass,
    textSize,
    fontWeightClass
  )

  const { className, ...rest } = ionTextProps
  return (
    <IonText {...rest} color={color}>
      <p className={textClass}>{text}</p>
    </IonText>
  )
}
