import Card from '#components/ui/Card'
import CoverIcon from '#components/ui/CoverIcon'
import Text from '#components/ui/Text'
import { getSymbolCurrency } from '#constants/country'
import { type BankAccountDeduction, type BankAccount } from '#tackpay-sdk'
import { IonCardHeader, IonRow } from '@ionic/react'
import { type ComponentProps } from 'react'

interface Props {
  /**
   * The bank object.
   */
  bank: BankAccount | BankAccountDeduction
  /**
   * The props of the Card component.
   */
  cardProps?: ComponentProps<typeof Card>
}

export default function CardBankAccount({
  bank,
  cardProps
}: Props): JSX.Element {
  return (
    <Card {...cardProps}>
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <CoverIcon iconOptions={{ icon: '/assets/img/bank.svg' }} />
          <Text
            color='gray-500'
            translationOptions={{ translate: 'no' }}
          >{`***********${bank.last_account_number}`}</Text>
          <Text
            translationOptions={{ translate: 'no' }}
            color='gray-900'
            size='text-xl'
            fontWeight='font-bold'
          >
            {getSymbolCurrency(bank.currency)}
          </Text>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
