import Text from '#components/ui/Text'
import { IonCol, IonRow } from '@ionic/react'

export default function Or(): JSX.Element {
  return (
    <IonRow className='ion-justify-content-between ion-align-items-center w-full mt-4'>
      <IonCol size='4'>
        <hr className='w-full' />
      </IonCol>
      <IonCol>
        <Text className='ion-padding-horizontal ion-text-center'>or</Text>
      </IonCol>
      <IonCol size='4'>
        <hr className='w-full' />
      </IonCol>
    </IonRow>
  )
}
