import CardStatistic from '#components/common/CardStatistic'
import { type CheckoutSession, type StatisticView } from '#tackpay-sdk'
import isDesktop from '#utils/isDesktop'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  statisticView: StatisticView
  checkouts?: CheckoutSession[]
}
export default function StatisticViewCard({
  statisticView,
  checkouts
}: Props): JSX.Element {
  console.log('checkouts', checkouts)

  const getTotalViews = (): number => {
    if (checkouts != null) return checkouts.length ?? 0
    return statisticView.total
  }

  const getTotalViewTip = (): number => {
    if (checkouts != null) {
      const withTip = checkouts.filter(
        (checkout) =>
          checkout.status !== 'created' &&
          checkout.status !== 'payment_rejected'
      )
      return withTip.length ?? 0
    }
    return statisticView.view_tip
  }

  const getTotalViewNoTip = (): number => {
    if (checkouts != null) {
      const withNotTip = checkouts.filter(
        (checkout) =>
          checkout.status === 'created' ||
          checkout.status === 'payment_rejected'
      )
      return withNotTip.length ?? 0
    }
    return statisticView.view_no_tip
  }

  const calculateTipPercentage = (type: 'tip' | 'noTip'): number => {
    const totalView = getTotalViews()
    const totalViewTip = getTotalViewTip()
    const totalViewNoTip = getTotalViewNoTip()

    if (totalView === 0) {
      return 0 // Evitiamo la divisione per zero
    }

    const percentage = type === 'noTip' ? totalViewNoTip : totalViewTip

    return Number(((percentage / totalView) * 100).toFixed(2))
  }

  const getTemporaryPercentage = (): number => {
    const lastWeek = new Date()
    lastWeek.setDate(lastWeek.getDate() - 7)

    const last2Week = new Date()
    last2Week.setDate(last2Week.getDate() - 14)

    const checkoutSessionLastWeek = getTotalViewByPeriod(lastWeek, new Date())
    const checkoutSessionLast2Week = getTotalViewByPeriod(last2Week, lastWeek)

    const totalViewLastWeek = checkoutSessionLastWeek.length ?? 0
    const totalViewLast2Week = checkoutSessionLast2Week.length ?? 0

    if (totalViewLast2Week === 0) {
      return 100 // Evitiamo la divisione per zero
    }

    const percentage = totalViewLastWeek - totalViewLast2Week

    return Number(((percentage / totalViewLast2Week) * 100).toFixed(2))
  }

  const getTotalViewByPeriod = (from: Date, to: Date): CheckoutSession[] => {
    return (
      checkouts?.filter((checkout) => {
        const createdAt = new Date(checkout.created_at ?? '')
        return createdAt >= from && createdAt <= to
      }) ?? []
    )
  }

  const percetageTip = calculateTipPercentage('tip')

  const percetageNoTip = calculateTipPercentage('noTip')

  return !isDesktop() ? (
    <IonCol className='space-y-5'>
      <CardStatistic
        title='Total Views'
        percentageValue={getTemporaryPercentage()}
        totalValue={getTotalViews()}
      />
      <CardStatistic
        title='Total View Tip'
        percentageValue={percetageTip}
        totalValue={getTotalViewTip()}
      />
      <CardStatistic
        title='Total Views No Tip'
        percentageValue={percetageNoTip}
        totalValue={getTotalViewNoTip()}
        positive={percetageNoTip < 3}
      />
    </IonCol>
  ) : (
    <IonRow className='ion-justify-content-between w-full'>
      <IonCol size='2.9'>
        <CardStatistic
          width='w-full'
          title='Total Views'
          percentageValue={100}
          totalValue={getTotalViews()}
        />
      </IonCol>
      <IonCol size='2.9'>
        <CardStatistic
          width='w-full'
          title='Total View Tip'
          percentageValue={percetageTip}
          totalValue={statisticView.view_tip}
        />
      </IonCol>
      <IonCol size='2.9'>
        <CardStatistic
          width='w-full'
          title='Total Views No Tip'
          percentageValue={percetageNoTip}
          totalValue={statisticView.view_no_tip}
          positive={percetageNoTip < 3}
        />
      </IonCol>
      <IonCol size='2.9'>
        <CardStatistic
          width='w-full'
          title='Total Views No Tip'
          percentageValue={percetageNoTip}
          totalValue={statisticView.view_no_tip}
          positive={percetageNoTip < 3}
        />
      </IonCol>
    </IonRow>
  )
}
