import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Customer } from './customers'
import { type QueryParamsRetrieve } from 'sdk/query'

type SubscriptionLinkType = 'subscription_links'

type SubscriptionLinkRel = ResourceRel & { type: SubscriptionLinkType }

type CustomerRel = ResourceRel & { type: 'customers' }

interface SubscriptionLink extends Resource {
  readonly type: SubscriptionLinkType

  cancel_url: string
  price_id: string
  quantity: number
  success_url: string
  url: string
  customer?: Customer
}

interface SubscriptionLinkCreate extends ResourceCreate {
  cancel_url: string
  price_reference: string
  quantity: number
  success_url: string
  customer: CustomerRel
}

class SubscriptionLinks extends ApiResource<SubscriptionLink> {
  static readonly TYPE: SubscriptionLinkType = 'subscription_links' as const

  async create(
    resource: SubscriptionLinkCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<SubscriptionLink> {
    return await this.resources.create<
      SubscriptionLinkCreate,
      SubscriptionLink
    >({ ...resource, type: SubscriptionLinks.TYPE }, params, options)
  }

  isSubscriptionLink(resource: any): resource is SubscriptionLink {
    return resource.type != null && resource.type === SubscriptionLinks.TYPE
  }

  relationship(id: string | ResourceId | null): SubscriptionLinkRel {
    return id === null || typeof id === 'string'
      ? { id, type: SubscriptionLinks.TYPE }
      : { id: id.id, type: SubscriptionLinks.TYPE }
  }

  type(): SubscriptionLinkType {
    return SubscriptionLinks.TYPE
  }
}

export type { SubscriptionLink, SubscriptionLinkCreate }
export default SubscriptionLinks
