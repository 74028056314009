import PaymentListSection from '#components/pages/payments/list/PaymentsListSection'
import CardWalletBalance from '#components/pages/wallets/card/WallteBalanceCard'
import { useUserContext } from '#contexts/UserContext'

export default function WalletTipped(): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  return (
    <>
      <CardWalletBalance
        category='tipped'
        isLoading={isLoading}
        error={error}
        user={user}
      />
      <PaymentListSection
        category='tipped'
        userId={user?.id}
        isLoading={isLoading}
        errorContext={error}
      />
    </>
  )
}
