import { statsChart, wallet, home, medal, people } from 'ionicons/icons'

export const FOOTER_NAV_BAR = [
  /** Tipped */
  {
    category: 'tipped',
    icon: home,
    text: 'Home',
    href: '/dashboard/tipped/home',
    paths: ['/dashboard/tipped/home']
  },
  {
    category: 'tipped',
    icon: statsChart,
    text: 'Statistiche',
    href: '/dashboard/tipped/statistics',
    paths: ['/dashboard/tipped/statistics']
  },
  {
    category: 'tipped',
    icon: wallet,
    text: 'Portafoglio',
    href: '/dashboard/tipped/wallets',
    paths: ['/dashboard/tipped/wallets', '/dashboard/tipped/wallets/:id']
  },

  {
    category: 'tipped',
    icon: medal,
    text: 'Feedback',
    href: '/dashboard/tipped/feedbacks',
    paths: ['/dashboard/tipped/feedbacks']
  },
  /** Business */
  {
    category: 'business',
    icon: home,
    text: 'Home',
    href: '/dashboard/business/home',
    paths: ['/dashboard/business/home']
  },
  {
    category: 'business',
    icon: '/assets/img/defaultTeam.svg',
    text: 'Jar',
    href: '/dashboard/business/jars',
    paths: ['/dashboard/business/jars']
  },
  {
    category: 'business',
    icon: wallet,
    text: 'Portafoglio',
    href: '/dashboard/business/wallets',
    paths: ['/dashboard/business/wallets', '/dashboard/business/wallets/:id']
  },

  {
    category: 'business',
    icon: medal,
    text: 'Feedback',
    href: '/dashboard/business/feedbacks',
    paths: ['/dashboard/business/feedbacks']
  },

  /** Manager */
  {
    category: 'manager',
    icon: home,
    text: 'Home',
    href: '/dashboard/manager/home',
    paths: ['/dashboard/manager/home']
  },
  {
    category: 'manager',
    icon: '/assets/img/jar-icon-test.svg',
    text: 'Jar',
    href: '/dashboard/manager/jars',
    paths: ['/dashboard/manager/jars']
  },
  {
    category: 'manager',
    icon: wallet,
    text: 'Portafoglio',
    href: '/dashboard/manager/wallets',
    paths: ['/dashboard/manager/wallets', '/dashboard/manager/wallets/:id']
  },
  {
    category: 'manager',
    icon: medal,
    text: 'Feedback',
    href: '/dashboard/manager/feedbacks',
    paths: ['/dashboard/manager/feedbacks']
  },

  /** Collaborator */
  {
    category: 'collaborator',
    icon: home,
    text: 'Home',
    href: '/dashboard/collaborator/home',
    paths: ['/dashboard/collaborator/home']
  },
  {
    category: 'collaborator',
    icon: people,
    text: 'Jar',
    href: '/dashboard/collaborator/jars',
    paths: ['/dashboard/collaborator/jars']
  },
  {
    category: 'collaborator',
    icon: wallet,
    text: 'Portafoglio',
    href: '/dashboard/collaborator/wallets',
    paths: [
      '/dashboard/collaborator/wallets',
      '/dashboard/collaborator/wallets/:id'
    ]
  },
  {
    category: 'collaborator',
    icon: medal,
    text: 'Feedback',
    href: '/dashboard/collaborator/feedbacks',
    paths: ['/dashboard/collaborator/feedbacks']
  }
]
