import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type TackpayId } from '#tackpay-sdk'
import { IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react'

interface Props {
  tackpayId: TackpayId
}
export default function SearchWelcomeCard({ tackpayId }: Props): JSX.Element {
  const getAvatar = (): string => {
    if (tackpayId.category === 'user') {
      return tackpayId.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
    } else if (tackpayId.category === 'company') {
      return tackpayId.company?.logo ?? DEFAULT_IMAGES.avatarTipped
    } else if (tackpayId.category === 'jar') {
      return tackpayId.jar?.avatar ?? DEFAULT_IMAGES.avatarTipped
    }
    return DEFAULT_IMAGES.avatarTipped
  }

  const getName = (): string => {
    if (tackpayId.category === 'user') {
      return tackpayId.user?.person?.first_name ?? ''
    } else if (tackpayId.category === 'company') {
      return tackpayId.company?.name ?? ''
    } else if (tackpayId.category === 'jar') {
      return tackpayId.jar?.name ?? ''
    }
    return ''
  }

  const getSubcategory = (): string => {
    if (tackpayId.category === 'user') {
      return tackpayId.user?.subcategory ?? ''
    } else if (tackpayId.category === 'company') {
      return 'company'
    } else if (tackpayId.category === 'jar') {
      return tackpayId.jar?.category ?? 'jar'
    }
    return 'unknown'
  }

  const checkoutUrl = `/checkout/${tackpayId.value}?referenceOrigin=search`

  return (
    <Card routerDirection='root' routerLink={checkoutUrl} href={checkoutUrl}>
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCol size='3'>
            <Avatar
              avatarOptions={{
                className: 'w-14 h-14'
              }}
              imgOptions={{
                src: getAvatar(),
                alt: tackpayId.category
              }}
            />
          </IonCol>
          <IonCol>
            <IonCardTitle className='text-sm'>{getName()}</IonCardTitle>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              className='capitalize'
            >
              {getSubcategory()}
            </Text>
          </IonCol>
          <IonCol size='3'>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              fontWeight='font-bold'
              color='gray-900'
            >
              {tackpayId.value}
            </Text>
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
