import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { useBankContext } from '#contexts/BankContext'
import { IonCardHeader, IonIcon, IonRow } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'

interface Props {
  href: string
}

export default function AddBankButton({ href }: Props): JSX.Element {
  const { bank, isLoading, error } = useBankContext()

  if (isLoading) return <Skeleton />

  if (error != null) return <ErrorBox error={error} />

  return (
    <Card
      className='mt-20'
      padding='ion-no-padding'
      routerLink={href}
      href={href}
      data-testid='link_add_bank'
    >
      <IonCardHeader>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <Text color='gray-900'>
            {bank == null ? 'bank.add.title' : 'bankChangeTitle'}
          </Text>
          <IonIcon icon={addCircleOutline} size='large' color='primary' />
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
