import DateRanger from '#components/common/DateRanger'
import AppLayout from '#components/layouts/app/AppLayout'
import CardDownloadAccountStatement from '#components/pages/accountStatements/CardDownloadAccountStatement'
import Button from '#components/ui/Button'
import { type DateTimeValue } from '#components/ui/DateTime'
import Skeleton from '#components/ui/Skeleton'
import SubTitle from '#components/ui/SubTitle'
import Text from '#components/ui/Text'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { useUserContext } from '#contexts/UserContext'
import useFilesystem from '#hooks/useFilesystem'
import useLoading from '#hooks/useLoading'
import { useShare } from '#hooks/useShare'
import useToast from '#hooks/useToast'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type Member, type AccountStatement, type Jar } from '#tackpay-sdk'
import createPaymentCsvMember from '#utils/createPaymentCsvMember'
import csvMemberActive from '#utils/csvMemberActive'
import getErrorMessage from '#utils/getErrorMessage'
import isWeb from '#utils/isWeb'
import sdk from '#utils/sdk'
import { Directory, Encoding } from '@capacitor/filesystem'
import { useState } from 'react'
import { useParams } from 'react-router'

function HandleTeamAccountStatement(): JSX.Element {
  const { jar, isLoading } = useTeamContext()

  const { jarId } = useParams<TeamRoutesParams>()

  const { shareElement } = useShare()

  const currentDate = new Date()
  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const { showToast } = useToast('warning')

  const { showToast: successShow } = useToast('success')

  const { write } = useFilesystem()

  const { stopLoading } = useLoading('accountStatement.loading')

  const getTackpayId = (jar?: Jar | null): string => {
    let tackpayId = 'unknown'
    if (jar?.tackpay_ids != null) {
      if (Array.isArray(jar.tackpay_ids) && jar.tackpay_ids.length > 0) {
        tackpayId = jar.tackpay_ids[0].value
      } else if (
        !Array.isArray(jar.tackpay_ids) &&
        jar.tackpay_ids.value != null
      ) {
        tackpayId = jar.tackpay_ids.value
      }
    }
    return tackpayId
  }

  const handleSaveMemberActive = async (
    accountStatement: Member[]
  ): Promise<void> => {
    console.log('accountStatement', accountStatement)
    const csvObject = csvMemberActive(accountStatement)

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `Active Members - ${jar?.name?.replaceAll(
        ',',
        ' '
      )} - ${getTackpayId(jar)}.csv`
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: 'members.csv',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then((res) => {
          shareElement({
            title: 'Members',
            dialogTitle: 'Members',
            url: res.uri
          })
            .then(() => {
              stopLoading()
              successShow(undefined, 'accountStatement.success')
            })
            .catch((error) => {
              console.log('error to share', error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleGetAllMembers = async (
    page = 1,
    members: Member[] = []
  ): Promise<Member[]> => {
    const membersResponse = await sdk.jars.members(jarId, {
      include: ['user.person', 'user.tackpay_id', 'jar.tackpay_ids'],
      filters: {
        eq: {
          status: 'invitation_accepted'
        }
      },
      pageNumber: page
    })

    members.push(...membersResponse)

    if (membersResponse.hasNextPage()) {
      return await handleGetAllMembers(page + 1, members)
    }

    return members
  }

  const handleMemberActive = (): void => {
    handleGetAllMembers()
      .then((res) => {
        console.log('res', res)
        void handleSaveMemberActive(res as unknown as Member[])
      })
      .catch((error) => {
        console.log('error', error)
        showToast(undefined, getErrorMessage(error))
      })
  }

  if (isLoading) {
    return (
      <>
        <Skeleton className='mt-10 h-10' />
        <Skeleton className='mt-2 h-10' />
      </>
    )
  }

  const companyToShow = ['cfd3ff73-763b-4b5f-af1f-e4681df537ed']

  return companyToShow.includes(jar?.company?.id ?? '') ? (
    <>
      <div className='mt-10'>
        <Text color='gray-900' fontWeight='font-bold'>
          memberActive
        </Text>
        <Text>memberActiveInfo</Text>
      </div>
      <div className='mt-10'>
        <Button onClick={handleMemberActive} className='w-full'>
          download
        </Button>
      </div>
    </>
  ) : (
    <></>
  )
}

export default function TeamMemberAccountStatement(): JSX.Element {
  const { user } = useUserContext()

  const { category, jarId } = useParams<TeamRoutesParams>()

  const { shareElement } = useShare()

  const currentDate = new Date()
  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const [dateFrom, setDateFrom] = useState<DateTimeValue>(
    lastMonth.toISOString()
  )
  const [dateTo, setDateTo] = useState<DateTimeValue>(currentDate.toISOString())

  const { showToast } = useToast('warning')

  const { showToast: showInfoToast } = useToast('default')

  const { showToast: successShow } = useToast('success')

  const { write } = useFilesystem()

  const { startLoading, stopLoading } = useLoading('accountStatement.loading')

  const handleSave = async (
    accountStatement: AccountStatement
  ): Promise<void> => {
    const csvObject = createPaymentCsvMember(
      accountStatement.from_date,
      accountStatement.to_date,
      accountStatement.transfers,
      user?.lang
    )

    const fileName = 'Tips for Members.csv'

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then((res) => {
          shareElement({
            title: 'Account Statement',
            dialogTitle: 'Account Statement',
            url: res.uri
          })
            .then(() => {
              stopLoading()
              successShow(undefined, 'accountStatement.success')
            })
            .catch((error) => {
              console.log('error to share', error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleDownload = (): void => {
    if (dateFrom == null || dateTo == null) {
      showToast(undefined, 'accountStatement.dateRequired')
    } else if (dateFrom > dateTo) {
      showToast(undefined, 'accountStatement.dateInvalid')
    } else {
      startLoading()

      sdk.account_statements
        .create({
          to_date: dateTo.toString(),
          from_date: dateFrom.toString(),
          is_member: true,
          jar: { id: jarId, type: 'jars' }
        })
        .then((accountStatement) => {
          if (
            (accountStatement?.payments == null ||
              accountStatement.payments.length === 0) &&
            (accountStatement?.transfers == null ||
              accountStatement.transfers.length === 0)
          ) {
            stopLoading()
            showInfoToast(undefined, 'accountStatement.emptyPayment')
          } else {
            void handleSave(accountStatement)
          }
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: { include: ['company', 'tackpay_ids'] }
      }}
    >
      <Title hasSubtitle>accountStatement.member.title</Title>
      <SubTitle>accountStatement.member.subtitle</SubTitle>
      <Text color='gray-900' fontWeight='font-bold'>
        accountStatement.selectPeriod
      </Text>
      <DateRanger
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        preferWheel={false}
      />

      <CardDownloadAccountStatement onClick={handleDownload} />
      <HandleTeamAccountStatement />
    </AppLayout>
  )
}
