import InputTextArea, {
  type InputTextAreaOnChange
} from '#components/ui/InputTextArea'
import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import Toggle from '#components/ui/Toggle'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { isDefaultReply } from '#utils/isDefaultReply'
import { IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'

export default function CheckoutSettingReply(): JSX.Element {
  const { t } = useTranslation()

  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  const reply = checkoutSetting?.reply

  const handleReplyMessageChange: InputTextAreaOnChange = (e) => {
    const message = e.target.value
    if (typeof message === 'string') {
      if (setCurrentCheckoutSetting != null) {
        setCurrentCheckoutSetting({
          ...checkoutSetting,
          reply: {
            ...reply,
            message
          } as any
        })
      }
    }
  }

  const handleReplyActiveChange = (show: boolean): void => {
    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        reply: {
          ...reply,
          active: show
        } as any
      })
    }
  }

  return (
    <div slot='content' className='pt-1'>
      <Text size='text-base' data-testid='subtitle-replysetting'>
        checkoutSetting.reply.subtitle
      </Text>
      <InputTextArea
        name='message'
        value={
          isDefaultReply(checkoutSetting?.reply?.message)
            ? t(checkoutSetting?.reply?.message ?? 'defaultReply')
            : checkoutSetting?.reply?.message
        }
        rows={8}
        onIonInput={handleReplyMessageChange}
        labelOptions={{
          children: 'checkoutSetting.reply.title'
        }}
      />
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Label capitalize={false}>checkoutSetting.reply.active</Label>
        <Toggle
          checked={reply?.active}
          onClick={() => {
            if (reply?.active != null) {
              handleReplyActiveChange(!reply.active)
            } else {
              handleReplyActiveChange(true)
            }
          }}
          data-testid='toogle-reply'
        />
      </IonRow>
    </div>
  )
}
