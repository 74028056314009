import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { IonCardHeader, IonCol, IonRow, useIonRouter } from '@ionic/react'
import { type Jar } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import isDesktop from '#utils/isDesktop'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'

interface Props {
  jar: Jar
}

export default function HomeTeamCard(props: Props): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  const { push } = useIonRouter()
  const { jar } = props

  return (
    <Card
      padding='p-3'
      className={isDesktop() ? 'w-1/4 cursor-pointer' : 'cursor-pointer'}
      onClick={() => {
        push(`/dashboard/${category}/jars/${jar.id}/settings`)
      }}
    >
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-align-items-center ion-no-padding'>
          <Avatar
            imgOptions={{
              src: jar.avatar ?? DEFAULT_IMAGES.logoTeam
            }}
          />
          <IonCol className='ml-6'>
            <Text
              color='gray-900'
              fontWeight='font-semibold'
              data-testid={jar.name}
            >
              {jar.name ?? 'jar.default_name'}
            </Text>
            <IonRow className='-space-x-1 overflow-hidden'>
              {jar.members
                ?.filter((m) => m.status === 'invitation_accepted')
                ?.map((member, i) => {
                  if (i < 3) {
                    return (
                      <Avatar
                        key={member.id}
                        avatarOptions={{
                          className: 'inline-block ring-2 ring-white w-6 h-6'
                        }}
                        imgOptions={{
                          src:
                            member.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
                        }}
                      />
                    )
                  } else return null
                })}
            </IonRow>
          </IonCol>
          <Text
            color='gray-900'
            fontWeight='font-bold'
            size='text-xl'
            data-testid={`${jar.name}-amount`}
          >
            {formattedAmount(jar.balance?.total?.amount_cents, jar.currency)}
          </Text>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
