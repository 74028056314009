import {
  type ResourceUpdate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Subscription } from './subscriptions'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Price } from './prices'

type SubscriptionItemType = 'subscription_items'

type SubscriptionItemRel = ResourceRel & { type: SubscriptionItemType }

type PlanType = 'free' | 'premium' | 'custom'

interface SubscriptionItem extends Resource {
  readonly type: SubscriptionItemType

  quantity: number
  price?: Price
  reference: string
  reference_origin: string
  subscription?: Subscription
  plan_type?: PlanType
}

interface SubscriptionItemUpdate extends ResourceUpdate {
  quantity: number
}

class SubscriptionItems extends ApiResource<SubscriptionItem> {
  static readonly TYPE: SubscriptionItemType = 'subscription_items' as const

  async update(
    resource: SubscriptionItemUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<SubscriptionItem> {
    return await this.resources.update<
      SubscriptionItemUpdate,
      SubscriptionItem
    >({ ...resource, type: SubscriptionItems.TYPE }, params, options)
  }

  isSubscriptionItem(resource: any): resource is SubscriptionItem {
    return resource.type != null && resource.type === SubscriptionItems.TYPE
  }

  relationship(id: string | ResourceId | null): SubscriptionItemRel {
    return id === null || typeof id === 'string'
      ? { id, type: SubscriptionItems.TYPE }
      : { id: id.id, type: SubscriptionItems.TYPE }
  }

  type(): SubscriptionItemType {
    return SubscriptionItems.TYPE
  }
}

export type { SubscriptionItem, SubscriptionItemUpdate }

export default SubscriptionItems
