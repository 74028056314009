import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Input from '#components/ui/Input'
import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonImg,
  IonRow
} from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

interface Props {
  src?: string
  title?: string
  subtitle?: string
  description?: string
  quantity?: number
  setQuantity?: (quantity: number) => void
  showQuantity?: boolean
  action?: () => void
}

export default function CardProduct({
  src = 'https://ionicframework.com/docs/img/demos/card-media.png',
  title = 'Card Title',
  subtitle = 'Card Subtitle',
  description = 'Here is a small description for the card content. Nothing more, nothing less.',
  quantity = 0,
  setQuantity,
  showQuantity = false,
  action
}: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <Card padding='ion-no-padding' className='md:w-1/2 md:mx-auto'>
      <IonImg alt='Kit' src={src} />
      <IonCardHeader>
        <IonCardTitle>{t(title)}</IonCardTitle>
        <IonCardSubtitle>{t(subtitle)}</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>{t(description)}</IonCardContent>
      <div className='px-5'>
        {showQuantity && (
          <IonRow className='ion-align-items-center ion-justify-content-center ion-no-padding ion-no-margin mb-8'>
            <IonCol size='3'>
              <IonIcon
                icon={remove}
                className='cursor-pointer pt-1'
                size='large'
                onClick={() => {
                  if (setQuantity != null) {
                    setQuantity(quantity - 1)
                  }
                }}
              />
            </IonCol>
            <IonCol size='6'>
              <Input
                id='newQuantity'
                type='tel'
                value={quantity}
                className='mx-auto ion-text-center'
                // onIonInput={handleTeamCount}
              />
            </IonCol>
            <IonCol size='3'>
              <IonIcon
                icon={add}
                color='primary'
                className='cursor-pointer pt-1'
                size='large'
                onClick={() => {
                  if (setQuantity != null) {
                    setQuantity(quantity + 1)
                  }
                }}
              />
            </IonCol>
          </IonRow>
        )}
      </div>
      <IonRow className='ion-justify-content-end pr-5'>
        <Button fill='clear' color='primary' onClick={action}>
          buy
        </Button>
      </IonRow>
    </Card>
  )
}
