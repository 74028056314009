import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Input, {
  type InputOnChange,
  type InputValue
} from '#components/ui/Input'
import InputPasswordTest from '#components/ui/InputPasswordTest'
import ModalLoading from '#components/ui/ModalLoading'
import { useTokenContext } from '#contexts/TokenContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import textUtil from '#utils/textUtil'
import { useIonRouter } from '@ionic/react'
import { useState } from 'react'

export default function ResetPasswordSection(): JSX.Element {
  const { token, isLoading, error } = useTokenContext()

  const [email, setEmail] = useState<InputValue>('')

  const [password, setPassword] = useState<InputValue>('')

  const { startLoading, stopLoading } = useLoading('resetPassword.loading')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { push } = useIonRouter()

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  if (token == null) return <div>Token is null</div>

  const handleInputChange: InputOnChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'email') setEmail(value)
      else if (name === 'password') setPassword(value)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (!textUtil.isEmail(email as string)) {
      showToast(undefined, 'resetPassowrd.invalidEmail')
    } else if (textUtil.isEmpty(password as string)) {
      showToast(undefined, 'resetPassowrd.invalidPassword')
    } else if (textUtil.isEmpty(token.value)) {
      showToast(undefined, 'resetPassowrd.invalidToken')
    } else {
      startLoading()

      sdk.users
        .reset_password(token.value, email as string, password as string)
        .then((res) => {
          if (res.status === 200) {
            stopLoading()
            showSuccess(undefined, 'resetPassowrd.success')
            push('/login', 'root')
          } else {
            stopLoading()
            showToast(undefined, res.code ?? 'Reset password error')
          }
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, error)
        })
    }
  }

  return (
    <form className='space-y-6' onSubmit={handleSubmit}>
      <Input
        type='email'
        name='email'
        labelOptions={{
          children: 'email'
        }}
        value={email}
        onIonInput={handleInputChange}
      />
      <InputPasswordTest
        name='password'
        onPasswordChange={handleInputChange}
        password={password}
      />
      <Button type='submit' className='w-full'>
        confirm
      </Button>
    </form>
  )
}
