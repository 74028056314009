import AppLayout from '#components/layouts/app/AppLayout'
import CardBalanceDeduction from '#components/pages/deductions/CardBalanceDeduction'
import DeductionPayoutList from '#components/pages/deductions/DeductionPayoutList'
import DeductionReport from '#components/pages/deductions/DeductionReport'
import JarDeductionList from '#components/pages/deductions/JarDeductionList'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { IonRow } from '@ionic/react'
import { useParams } from 'react-router'

export default function Deduction(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      companyProps={{
        fetch: true,
        params: {
          include: ['jars.balance_deduction']
        }
      }}
    >
      <Title>deduction.title</Title>
      <CardBalanceDeduction category={category} />
      <IonRow className='mt-5 w-full block'>
        <JarDeductionList category={category} />
      </IonRow>
      <DeductionReport />
      <IonRow className='w-full block mt-10 space-y-5 pb-10'>
        <DeductionPayoutList category={category} />
      </IonRow>
    </AppLayout>
  )
}
