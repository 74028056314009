import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import { type Jar } from '#tackpay-sdk'
import { IonCardTitle, IonContent, IonIcon, IonRow } from '@ionic/react'
import { qrCodeOutline } from 'ionicons/icons'
import { useState } from 'react'
import CardQrCodeBusiness from '../home/cardQrCode/CardQrCodeBusiness'
import { useTranslation } from 'react-i18next'
import isDesktop from '#utils/isDesktop'

interface Props {
  jar?: Jar | null
}

export default function TeamQrCodeSmart({ jar }: Props): JSX.Element {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <Button
        onClick={() => {
          setShowModal(true)
        }}
        fill='clear'
        color='gray-900'
      >
        <IonIcon src={qrCodeOutline} size='large' className='pt-1' />
      </Button>
      <Modal
        setShowModal={setShowModal}
        isOpen={showModal}
        className={isDesktop() ? 'smart' : 'smart'}
      >
        <IonContent>
          <IonRow className='w-full ion-padding mt-5 ion-text-center ion-justify-content-center'>
            <IonCardTitle>{t('qr_jar')}</IonCardTitle>
          </IonRow>
          <div className='px-8'>
            <CardQrCodeBusiness jar={jar ?? undefined} isSmart />
          </div>
        </IonContent>
      </Modal>
    </div>
  )
}
