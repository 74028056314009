import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Jar, type UserCategory, type User } from '#tackpay-sdk'
import { IonCol, IonImg, IonRow } from '@ionic/react'
import WalletAvailableBalance from './AvailableBalance'
import WalletPendingBalance from './PendingBalance'
import { isBusiness } from '#utils/isBusiness'
import Info from '#components/ui/Info'

interface Props {
  /**
   * The category of the jar or user.
   */
  category: UserCategory
  /**
   * The jar of the card wallet.
   */
  jar?: Jar | null
  /**
   * The balance of the card wallet.
   */
  user?: User | null
  /**
   * loading state
   */
  isLoading: boolean
  /**
   * error state
   */
  error?: any
}

export default function CardWalletBalance({
  category,
  isLoading,
  error,
  jar,
  user
}: Props): JSX.Element {
  const isTipped = !isBusiness(category)

  const showWithdrawButton = isTipped || jar?.jar_type === 'private'

  const showPendingBalanceTronc = jar?.tipping_rule === 'tronc'

  const currentBalance = isTipped
    ? user?.balance
    : jar?.tipping_rule === 'tronc'
      ? jar?.balance?.tronc
      : jar?.balance

  const withdrawalLink = isTipped
    ? `/dashboard/${category}/wallets/withdrawals/payouts`
    : `/dashboard/${category}/jars/${
        jar?.id ?? ''
      }/settings/withdrawals/payouts`

  return (
    <Card
      className='diagonal-split-background shadow md:w-9/12 md:mx-auto'
      data-testid='balance-available'
    >
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonCol size='6' className='ion-no-padding space-y-5'>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <Text
                  className='text-xl '
                  color='gray-900'
                  fontWeight='font-bold'
                >
                  {isTipped ? user?.person?.first_name : jar?.name}
                </Text>
              </IonRow>
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <WalletAvailableBalance
                  loading={isLoading}
                  balance={currentBalance as any}
                  error={error}
                  useTotalBalance={
                    !isTipped &&
                    jar?.jar_type === 'shared' &&
                    jar?.tipping_rule !== 'tronc'
                  }
                />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            {(showWithdrawButton || showPendingBalanceTronc) && (
              <IonCol>
                <IonRow className='w-full ion-no-margin ion-no-padding ion-align-items-center space-x-2'>
                  <Text
                    className=''
                    color='gray-900'
                    size='text-base'
                    data-testid='balance-pending'
                  >
                    wallet.balance.pending
                  </Text>
                  <Info title='i' text='pending_balance_info' />
                </IonRow>

                <IonRow className='w-full ion-no-margin ion-no-padding'>
                  <WalletPendingBalance
                    loading={isLoading}
                    balance={currentBalance as any}
                    error={error}
                    isTronc={showPendingBalanceTronc}
                  />
                </IonRow>
              </IonCol>
            )}
          </IonRow>
        </IonCol>
        <IonCol size='4' className='ion-no-padding space-y-10'>
          {showWithdrawButton && (
            <IonRow className='ion-justify-content-center ion-no-padding ion-no-margin'>
              <Button
                color='dark'
                className='h-7'
                routerLink={withdrawalLink}
                routerDirection='root'
                disabled={isLoading}
                data-testid='goto-withdrawals'
              >
                Withdraw
              </Button>
            </IonRow>
          )}
          <IonRow className='ion-justify-content-center'>
            <IonImg src='/assets/img/line-card.svg' />
          </IonRow>
        </IonCol>
      </IonRow>
    </Card>
  )
}
