import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { type ListResponse, type Jar, type UserCategory } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import TeamEmpty from './TeamEmpty'
import TeamCardBusiness from './TeamCardBusiness'
import { useEffect, useRef } from 'react'
import { animationsFade } from '#utils/animations'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'

interface Props {
  category: UserCategory
}
export default function TeamBusinessList({ category }: Props): JSX.Element {
  const listTeam = useRef<HTMLDivElement | null>(null)

  const fetchTeam = async ({ pageParam = 1 }): Promise<ListResponse<Jar>> => {
    return await sdk.jars.list({
      pageNumber: pageParam,
      include: ['balance', 'members.user'],
      sort: {
        created_at: 'desc'
      }
    })
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['jars'],
    queryFn: fetchTeam,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (!hasMore) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  useEffect(() => {
    animationsFade({
      typeAnimation: listTeam,
      start: '-45px',
      end: '0',
      duration: 500
    })
  }, [data])

  console.log('Data', data)

  return status === 'pending' ? (
    <CardSkeleton />
  ) : status === 'error' ? (
    <ErrorBox error={error} />
  ) : data.pages[0].length === 0 && !isFetching ? (
    <TeamEmpty />
  ) : (
    <div ref={listTeam}>
      {data.pages.map((page, i) => (
        <div key={i} className='space-y-6'>
          {page.map((jar) => {
            return (
              <TeamCardBusiness key={jar.id} category={category} jar={jar} />
            )
          })}
        </div>
      ))}
      <IonInfiniteScroll
        disabled={hasMore == null || !hasMore}
        onIonInfinite={loadNext}
      >
        <IonInfiniteScrollContent
          loadingSpinner='circles'
          loadingText='Loading...'
          color='primary'
        />
      </IonInfiniteScroll>
    </div>
  )
}
