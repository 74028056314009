import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import RegistrationPersonalData from '#components/pages/registrations/RegistrationPersonalData'

export default function RegistrationPersonStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: false,
        backButtonProps: {
          defaultHref: `/registrations/${category}`
        }
      }}
    >
      <RegistrationPersonalData category={category} />
    </AuthLayout>
  )
}
