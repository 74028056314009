import Avatar from '#components/ui/Avatar'
import Chip from '#components/ui/Chip'
import Text from '#components/ui/Text'
import Toggle from '#components/ui/Toggle'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useMemberContext } from '#contexts/MemberContext'
import useToast from '#hooks/useToast'
import { type UserCategory, type Member } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { isBusiness } from '#utils/isBusiness'
import { isInactiveCapabilities } from '#utils/isInactiveCapabilities'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useEffect } from 'react'

interface Props {
  category: UserCategory
  /**
   * The member object.
   */
  member: Member

  jarId?: string
}

export default function MemberCard({
  member,
  category,
  jarId
}: Props): JSX.Element {
  const { updateMember } = useMemberContext()

  const { showToast } = useToast('warning')

  const { showToast: successShow } = useToast('success')

  const { push } = useIonRouter()

  const href = isBusiness(category)
    ? `/dashboard/${category}/jars/${jarId ?? ''}/members/${member?.id}`
    : `/dashboard/${category}/jars/${member?.id}`

  const routerLink = (): void => {
    push(href, 'root')
  }

  useEffect(() => {
    if (
      isInactiveCapabilities(member?.user?.stripe_gateway_account) &&
      member.status === 'invitation_accepted'
    ) {
      showToast(undefined, 'members.invalid_capability')
    }
  }, [])

  useEffect(() => {
    if (
      isInactiveCapabilities(member?.user?.stripe_gateway_account) &&
      member.status === 'invitation_accepted'
    ) {
      if (updateMember != null) {
        updateMember.mutate({
          id: member.id,
          active: false
        })
      }
    }
  }, [])

  const handleToggle = (active: boolean): void => {
    if (isInactiveCapabilities(member?.user?.stripe_gateway_account)) {
      showToast(undefined, 'members.invalid_capability')
    } else {
      if (updateMember != null) {
        updateMember.mutate(
          {
            id: member.id,
            active
          },
          {
            onSuccess: () => {
              console.log('Member updated')
              successShow(undefined, 'members.updated')
            },
            onError: (error) => {
              console.log('Error updating member')
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
      }
    }
  }

  const getColorCard = (): string => {
    if (
      isInactiveCapabilities(member?.user?.stripe_gateway_account) &&
      member.status === 'invitation_accepted'
    ) {
      return 'border-r-4 border-red-400'
    }
    return ''
  }

  const handleClickToogle = (): void => {
    if (isInactiveCapabilities(member?.user?.stripe_gateway_account)) {
      showToast(undefined, 'members.invalid_capability')
    }
  }

  return (
    <IonRow
      id='card-member'
      className={`ion-align-items-center ion-justify-content-between ion-no-margin ion-padding-vertical w-full rounded-md mt-5 cursor-pointer px-2 ${getColorCard()}`}
    >
      <IonCol className='ion-no-margin ion-no-padding' size='9.5'>
        <IonRow
          onClick={routerLink}
          className='ion-align-items-center ion-justify-content-center'
        >
          <IonCol size='2.5' className='ion-no-margin ion-no-padding'>
            <Avatar
              imgOptions={{
                src: member.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
              }}
            />
          </IonCol>

          <IonCol size='9.5' className='ion-no-margin pl-4 md:pl-2'>
            {member.user?.person?.first_name === undefined &&
            member.user?.person?.last_name === undefined ? null : (
              <Text
                size='text-base'
                color='text-gray-900'
                fontWeight='font-bold'
                translationOptions={{
                  translate: 'no'
                }}
              >
                {member.user?.person?.first_name}{' '}
                {member.user?.person?.last_name}
              </Text>
            )}

            <Text
              className='truncate'
              size='text-sm'
              translationOptions={{
                translate: 'no'
              }}
            >
              {member.email}
            </Text>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol
        size='2.5'
        className='ion-no-margin ion-no-padding'
        onClick={handleClickToogle}
      >
        <IonRow className='ion-no-padding ion-no-margin ion-justify-content-end'>
          {member.status === 'invitation_pending' ? (
            <Chip color='warning' className='ion-text-center'>
              <Text
                size='text-[0.5rem]'
                color='warning'
                fontWeight='font-semibold'
              >
                {member.status}
              </Text>
            </Chip>
          ) : (
            <Toggle
              checked={member.active}
              onIonChange={() => {
                handleToggle(!member.active)
              }}
              disabled={isInactiveCapabilities(
                member?.user?.stripe_gateway_account
              )}
              className='ion-no-margin ion-no-padding'
            />
          )}
        </IonRow>
      </IonCol>
    </IonRow>
  )
}
