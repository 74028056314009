import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type UserCategory } from '#tackpay-sdk'
import AddTeamPersonalDataForm from './AddPersonalDataForm'
import AddTeamCompanyQuestion from './AddTeamCompanyQuestion'

interface Props {
  category: UserCategory
}
export default function AddTeamCompanySection({
  category
}: Props): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  if (isLoading)
    return (
      <>
        <Skeleton className='w-full h-9' />
        <Skeleton className='w-full h-9' />
      </>
    )

  if (error != null) return <ErrorBox error={error} />

  return company?.stripe_gateway_account == null ? (
    <AddTeamPersonalDataForm category={category} />
  ) : (
    <AddTeamCompanyQuestion category={category} />
  )
}
