import ButtonInputFile from '#components/common/ButtonInputFile'
import Avatar from '#components/ui/Avatar'
import Input, { type InputOnChange } from '#components/ui/Input'
import InputTextArea, {
  type InputTextAreaOnChange
} from '#components/ui/InputTextArea'
import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import useStorage from '#hooks/useStorage'
import useToast from '#hooks/useToast'
import isBio from '#utils/isBio'
import { type Photo } from '@capacitor/camera'
import { IonRow } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  /**
   * If the user is tipped
   * @default false
   */
  isTipped?: boolean
  /**
   * @default false
   */
  isCompany?: boolean

  jarId?: string
}
export default function CheckoutSettingBrand({
  isTipped = false,
  isCompany = false,
  jarId
}: Props): JSX.Element {
  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  const { uploadByDataUrl } = useStorage()

  const { user } = useUserContext()

  const { company } = useCompanyContext()

  const { t } = useTranslation()

  const { showToast } = useToast('warning')

  const [, setPhoto] = useState<Photo | undefined>()

  const { startLoading, stopLoading } = useLoading('photoLoading')

  const handleChangePhoto = (photo: Photo): void => {
    startLoading()

    setPhoto(photo)

    let refPath = isTipped ? `${user?.id}` : `${company?.id}`

    if (jarId != null) {
      refPath += `/jars/${jarId}`
    }

    if (photo?.base64String != null || photo?.path != null) {
      uploadByDataUrl(
        photo?.path,
        photo?.base64String,
        refPath,
        `avatar.${photo?.format}`
      )
        .then((res) => {
          console.log('Upload success', res)
          if (setCurrentCheckoutSetting != null) {
            setCurrentCheckoutSetting({ ...checkoutSetting, avatar: res })
          }
          stopLoading()
        })
        .catch((err) => {
          stopLoading()
          console.log('Upload error', err)
          showToast(undefined, err.message)
        })
    }
    stopLoading()
  }

  const getPhoto = (): string => {
    return checkoutSetting?.avatar != null
      ? checkoutSetting.avatar
      : isTipped
      ? DEFAULT_IMAGES.avatarTipped
      : DEFAULT_IMAGES.logoTeam
  }

  const handleUsernameChange: InputOnChange = (e) => {
    const value = e.target.value

    if (typeof value === 'string') {
      if (setCurrentCheckoutSetting != null) {
        setCurrentCheckoutSetting({ ...checkoutSetting, username: value })
      }
    }
  }

  const handleDescriptionChange: InputTextAreaOnChange = (e) => {
    const value = e.target.value

    if (typeof value === 'string') {
      if (setCurrentCheckoutSetting != null) {
        setCurrentCheckoutSetting({ ...checkoutSetting, description: value })
      }
    }
  }

  return (
    <div slot='content' className='space-y-6 pt-1'>
      <Text size='text-base' data-testid='title-brand'>
        {isTipped === true ? 'checkoutSetting.brandInfoTipped' : 'checkoutSetting.brandInfo'}
      </Text>
      <div>
        <Label>checkoutSetting.img</Label>
      </div>
      <IonRow className='ion-align-items-center ion-justify-content-between space-y-4'>
        <Avatar
          avatarOptions={{
            className: 'w-20 h-20'
          }}
          imgOptions={{
            src: getPhoto()
          }}
        />
        <div>
          <ButtonInputFile
            text='changePhoto'
            color='green-500'
            fill='outline'
            setPhoto={handleChangePhoto}
            data-testid='input-photo-checkout'
          />
        </div>
      </IonRow>
      <div>
        <Input
          name='username'
          type='text'
          value={checkoutSetting?.username}
          labelOptions={{
            children: isTipped
              ? 'username'
              : isCompany
              ? 'companyName'
              : 'teamName',
            capitalize: isTipped
          }}
          onIonInput={handleUsernameChange}
        />
      </div>
      <div>
        <InputTextArea
          name='description'
          value={
            isBio(checkoutSetting?.description)
              ? t(checkoutSetting?.description ?? '')
              : checkoutSetting?.description ?? ''
          }
          rows={5}
          className='bg-white'
          labelOptions={{
            children: 'description'
          }}
          onIonInput={handleDescriptionChange}
        />
      </div>
    </div>
  )
}
