import { loadStripe, type Stripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ??
    'pk_live_MrWvzFs5qTLkkiflLoiBmlrt00yIkcNzZ1'
)

export const getStripe = async (): Promise<Stripe> => {
  const stripe = await stripePromise
  if (stripe == null) throw new Error('Stripe is not initialized')
  return stripe
}
