import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import RegistrationUserData from '#components/pages/registrations/RegistrationUserData'

export default function RegistrationUserStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: `/registrations/${category}/persons`
        }
      }}
    >
      <ProgressBar
        text='registration.chips.userInfo.title'
        value={0.9}
        testId='progress-registration.chips.userInfo.title'
      />
      <RegistrationUserData category={category} />
    </AuthLayout>
  )
}
