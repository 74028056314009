import BannerUnableCapabilities from '#components/common/BannerUnableCapability'
import CardTippedQrCode from '#components/pages/home/cardQrCode/CardQrCodeTipped'
import MemberInviteHandle from '#components/pages/home/memberInvite/MemberInviteHandle'
import BannerComponents from '#components/ui/BannerComponents'
import { useUserContext } from '#contexts/UserContext'
import useFCMToken from '#hooks/useFCMToken'
import { useEffect } from 'react'

export default function HomeTipped(): JSX.Element {
  const { user } = useUserContext()

  const { getToken, setTokenOnServer } = useFCMToken()

  const handleGetFCMToken = async (): Promise<void> => {
    try {
      const token = await getToken()
      if (token != null) {
        await setTokenOnServer(user?.subscriber?.id ?? '', token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      (user?.subscriber?.device_tokens == null ||
        user?.subscriber?.device_tokens?.length <= 0) &&
      user != null
    ) {
      void handleGetFCMToken()
    }
  }, [user?.subscriber?.device_tokens])

  return (
    <>
      <BannerComponents />
      <BannerUnableCapabilities />
      <MemberInviteHandle />
      <CardTippedQrCode />
    </>
  )
}
