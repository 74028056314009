import BasicSelect, {
  type ConditionalLabelProps,
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import { type Jar } from '#tackpay-sdk'
import { adapterModal } from '#utils/adapter'
import { useTranslation } from 'react-i18next'

interface Props {
  /**
   * Current jar
   */
  currentTeam?: Jar | Jar[]
  /**
   * Change jar function
   */
  setCurrentTeam: (args: any) => void
  /**
   * Select multiple jar
   * @default false
   */
  multiple?: boolean

  jars?: Jar[]

  formatted?: 'name-tackpayId' | 'name'

  setTackpayId?: (tackpayId: string) => void

  interfaceSelected?: 'action-sheet' | 'alert' | 'popover'

  labelProps?: ConditionalLabelProps

  tackpayId?: string
}

export default function SelectTeam({
  currentTeam,
  setCurrentTeam,
  multiple,
  jars,
  formatted = 'name',
  setTackpayId,
  interfaceSelected,
  labelProps,
  tackpayId
}: Props): JSX.Element {
  const { t } = useTranslation()

  const handleTeamSelect: SelectOnChangeEvent = (e) => {
    const { value } = e.target
    if (value != null) {
      if (Array.isArray(value)) {
        const teamSelect = jars?.filter((jar) => value.includes(jar.name))
        setCurrentTeam(teamSelect)
        if (value.includes('-')) {
          if (setTackpayId != null) {
            setTackpayId(value[0].split(' - ')[1])
          }
        }
      } else {
        const jar = jars?.find((jar) => value.includes(jar.name))
        console.log('jar', jar)
        if (typeof value === 'string') {
          if (value.includes('-')) {
            if (setTackpayId != null) {
              setTackpayId(value.split(' - ')[1])
            }
          }
        }
        setCurrentTeam(jar)
      }
    }
  }

  const getChildren = (): string[] => {
    const formattedValue: string[] = []
    if (jars != null) {
      jars.forEach((jar) => {
        if (formatted === 'name-tackpayId') {
          if (jar.tackpay_ids != null) {
            if (!Array.isArray(jar.tackpay_ids)) {
              jar.tackpay_ids = [jar.tackpay_ids]
            }

            jar.tackpay_ids.forEach((tackpayId) => {
              formattedValue.push(`${jar.name} - ${tackpayId.value}`)
            })
          }
        } else {
          formattedValue.push(jar.name)
        }
      })
    }
    return formattedValue
  }

  const getCurrentValue = (): string[] | string | undefined => {
    if (currentTeam == null) return undefined
    if (!Array.isArray(currentTeam)) {
      currentTeam = [currentTeam]
    }
    if (formatted === 'name-tackpayId') {
      return currentTeam
        .map((jar) => {
          if (jar.tackpay_ids != null) {
            if (!Array.isArray(jar.tackpay_ids)) {
              jar.tackpay_ids = [jar.tackpay_ids]
            }
            return jar.tackpay_ids
              .filter((tackpayIdJar) => tackpayIdJar.value === tackpayId)
              .map((tackpayId) => `${jar.name} - ${tackpayId.value}`)
          }
          return jar.name
        })
        .flat()
    } else {
      return currentTeam.map((jar) => jar.name)
    }
  }

  const controlLabels = labelProps ?? { show: false }

  return (
    <BasicSelect
      options={{
        childrens: getChildren()
      }}
      interface={adapterModal}
      placeholder={`${t('jar')}`}
      labelOptions={controlLabels}
      multiple={multiple}
      onIonChange={handleTeamSelect}
      value={getCurrentValue()}
    />
  )
}
