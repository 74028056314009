import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { IonContent, IonRow } from '@ionic/react'

interface Props {
  percentage: number
  show: boolean
  setShow: (show: boolean) => void
}
export default function DeleteModalDeduction({
  setShow,
  show,
  percentage
}: Props): JSX.Element {
  const { jar, refetch } = useTeamContext()

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const { startLoading, stopLoading } = useLoading('deduction.deleting')

  const handleDelete = (): void => {
    if (jar?.deduction_setting != null) {
      startLoading()
      sdk.deduction_settings
        .update({
          id: jar.deduction_setting.id,
          percentage: 0,
          active: false
        })
        .then(() => {
          if (refetch != null) {
            void refetch()
          }
          stopLoading()
          successToast(undefined, 'deduction.deleted')
          setShow(false)
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <Modal isOpen={show} setShowModal={setShow} id='simple'>
      <IonContent className='ion-padding ion-text-center' scrollY={false}>
        <Text
          className='ion-text-center'
          color='gray-900'
          fontWeight='font-bold'
          size='text-3xl'
        >
          deduction.title
        </Text>
        <IonRow className='w-full block mt-6'>
          <Text translationOptions={{ data: { percentage }, translate: 'yes' }}>
            deduction.delete_info
          </Text>
        </IonRow>
        <hr className='mt-5' />
        <IonRow className='mt-6'>
          <Button className='w-full' color='danger' onClick={handleDelete}>
            deduction.cancel
          </Button>
        </IonRow>
        <IonRow className='w-full block mt-6'>
          <Text
            className='underline cursor-pointer'
            onClick={() => {
              setShow(false)
            }}
          >
            back
          </Text>
        </IonRow>
      </IonContent>
    </Modal>
  )
}
