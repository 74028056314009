// import ChartMap from '#components/charts/ChartMap'
import SelectTeam from '#components/common/TeamSelection'
import ErrorBox from '#components/errors/ErrorBox'
import Title from '#components/ui/Title'
import { useCompanyContext } from '#contexts/CompanyContext'
import StatisticAmountContainer from '#contexts/StatisticAmountContext'
import StatisticBalanceContainer from '#contexts/StatisticBalanceContext'
import StatisticFeedbackContainer from '#contexts/StatisticFeedbackContext'
import StatisticViewContainer from '#contexts/StatisticViewConytext'
import {
  type UserCategory,
  type Company,
  type Jar,
  type Collaborator
} from '#tackpay-sdk'
import { IonCol, IonRow } from '@ionic/react'
import { Suspense, lazy, useState } from 'react'
import TableSocials from './UI/TableSocials'
import BannerComponents from '#components/ui/BannerComponents'
import ModalLoading from '#components/ui/ModalLoading'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import isCollaborator from '#utils/isCollaborator'
import CardQrCodeBusiness from '#components/pages/home/cardQrCode/CardQrCodeBusiness'

const StatisticAmount = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/amount/StatisticAmount')
)

const StatisticView = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/desktop/StatisticCardDesktop')
)

const StatisticBalance = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statistics/balance/StatisticBalance')
)

const StatisticFeedbackSection = lazy(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('./../pages/statisticFeedback/StatisticFeedbackSection')
)

function HandleDashboardBusiness({
  company,
  category,
  collaborator
}: {
  company: Company
  category: UserCategory
  collaborator: Collaborator | undefined
}): JSX.Element {
  const [currentTeam, setCurrentTeam] = useState<Jar | undefined | null>(
    isCollaborator(category) ? collaborator?.jars?.[0] : company?.jars?.[0]
  )

  // const [countryTips, setCountryTips] = useState<string[]>()

  // const handleCountriesTips = (): void => {
  //   if (currentTeam != null) {
  //     sdk.jars
  //       .metrics_country_tips(currentTeam.id)
  //       .then((res) => {
  //         setCountryTips(res.countries)
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }
  // }

  // useEffect(() => {
  //   handleCountriesTips()
  // }, [currentTeam])

  return (
    <>
      <BannerComponents />
      <IonRow className='w-full ion-no-margin ion-no-padding h-screen'>
        <IonCol size='12' className='py-10'>
          <div className='w-full mx-auto mt-14 space-y-5'>
            <IonRow className='w-full ion-no-padding ion-no-margin ion-justify-content-between ion-align-items-center'>
              <IonCol size='9' className='ion-no-padding ion-no-margin'>
                <Title
                  className='p-0 m-0'
                  translationOptions={{
                    translate: 'no'
                  }}
                >
                  {currentTeam?.name}
                </Title>
              </IonCol>
              <IonCol size='3' className='ion-no-padding ion-no-margin'>
                <SelectTeam
                  labelProps={{ show: false }}
                  interfaceSelected='popover'
                  setCurrentTeam={setCurrentTeam}
                  currentTeam={currentTeam ?? undefined}
                  jars={
                    collaborator?.jars ??
                    (isCollaborator(category) ? [] : company?.jars ?? [])
                  }
                  formatted='name'
                />
              </IonCol>
            </IonRow>

            <IonRow className='w-full ion-no-padding ion-no-margin'>
              <IonCol size='12' className='ion-no-padding ion-no-margin'>
                <StatisticViewContainer jarId={currentTeam?.id}>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticView
                      jarId={currentTeam?.id}
                      currency={currentTeam?.currency}
                    />
                  </Suspense>
                </StatisticViewContainer>
              </IonCol>
            </IonRow>
            {currentTeam?.jar_type === 'shared' && (
              <IonRow className='w-full ion-no-margin ion-no-padding'>
                <IonCol
                  size='12'
                  className='ion-no-margin ion-no-padding h-full'
                >
                  {currentTeam != null && (
                    <TableSocials currentJar={currentTeam} />
                  )}
                </IonCol>
              </IonRow>
            )}
            <IonRow className='w-full ion-justify-content-between'>
              <IonCol size='7' className='ion-no-padding ion-no-margin'>
                <StatisticAmountContainer jarId={currentTeam?.id}>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticAmount />
                  </Suspense>
                </StatisticAmountContainer>
              </IonCol>
              <IonCol size='4.8' className='ion-no-padding ion-no-margin'>
                <StatisticBalanceContainer jarId={currentTeam?.id}>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticBalance />
                  </Suspense>
                </StatisticBalanceContainer>
              </IonCol>
            </IonRow>
            <IonRow className='w-full ion-no-padding ion-no-margin ion-justify-content-between'>
              <IonCol size='4.8' className='ion-no-padding ion-no-margin'>
                <StatisticFeedbackContainer jarId={currentTeam?.id}>
                  <Suspense fallback={<ModalLoading />}>
                    <StatisticFeedbackSection />
                  </Suspense>
                </StatisticFeedbackContainer>
              </IonCol>
              <IonCol
                size='7'
                className='ion-no-padding ion-no-margin relative'
              >
                <CardQrCodeBusiness jar={currentTeam ?? undefined} />

                {/* <ChartMap countryTips={countryTips ?? []} /> */}
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
        {/* <IonCol size='12' className='pb-10'>
          <CardQrCodeBusiness jar={currentTeam ?? undefined} />
        </IonCol> */}
      </IonRow>
    </>
  )
}

export default function DashboardDesktopBusiness(): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  const { collaborator } = useCollaboratorContext()

  const { category } = useParams<DashboardRoutesParams>()

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  return company != null ? (
    <HandleDashboardBusiness
      company={company}
      category={category}
      collaborator={collaborator}
    />
  ) : (
    <></>
  )
}
