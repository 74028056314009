import AppLayout from '#components/layouts/app/AppLayout'
import ButtonDeduction from '#components/pages/deductions/ButtonDeduction'
import PercentageDeduction from '#components/pages/deductions/PercentageDeduction'
import PersonalDataDeduction from '#components/pages/deductions/PersonalDataDeduction'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

export function HandleJarDeduction(): JSX.Element {
  const { jar } = useTeamContext()

  const [percentage, setPercentage] = useState<number>(0)

  useEffect(() => {
    if (jar?.deduction_setting != null) {
      setPercentage(jar.deduction_setting.percentage)
    }
  }, [jar?.deduction_setting?.percentage])

  return (
    <>
      <PercentageDeduction
        percentage={percentage}
        setPercentage={setPercentage}
      />
      <PersonalDataDeduction percentage={percentage} />
      <ButtonDeduction percentage={percentage} />
    </>
  )
}

export default function JarDeduction(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/deductions`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: [
            'deduction_setting',
            'stripe_gateway_account',
            'company.stripe_gateway_account'
          ]
        }
      }}
    >
      <Title>deduction.title</Title>
      <SubTitle>deduction.subtitle</SubTitle>
      <HandleJarDeduction />
    </AppLayout>
  )
}
