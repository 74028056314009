import Button from '#components/ui/Button'
import { type UserCategory } from '#tackpay-sdk'
import { useState } from 'react'
import CheckoutSettingPreview from './CheckoutSettingPreview'
import TeamContainer from '#contexts/JarContext'
import CheckoutSettingCompanyPreview from './CheckoutSettingCompanyPreview'
interface Props {
  category: UserCategory
  jarId?: string
  /** @default false */
  isCompany?: boolean
  companyId?: string
}
export default function CheckoutSettingButtonPreview({
  category,
  jarId,
  isCompany = false,
  companyId
}: Props): JSX.Element {
  const [showPreview, setShowPreview] = useState(false)
  return (
    <>
      {!isCompany ? (
        <TeamContainer
          fetch={jarId != null}
          jarId={jarId}
          params={{
            include: ['members.user.person']
          }}
        >
          <CheckoutSettingPreview
            jarId={jarId}
            category={category}
            show={showPreview}
            setShow={setShowPreview}
          />
        </TeamContainer>
      ) : (
        <CheckoutSettingCompanyPreview
          setShow={setShowPreview}
          show={showPreview}
          companyId={companyId ?? ''}
        />
      )}
      <Button
        color='green-400'
        className='w-full mt-20'
        onClick={() => {
          setShowPreview(true)
        }}
        data-testid='preview'
      >
        preview
      </Button>
    </>
  )
}
