export default function countEqualItems(arr: any[]): number[] {
  const itemMap: Record<any, number> = {}

  // Conta le occorrenze di ciascun elemento nell'array
  for (const item of arr) {
    if (itemMap[item] != null) {
      itemMap[item]++
    } else {
      itemMap[item] = 1
    }
  }

  // Crea un array con le frequenze
  const frequencies: number[] = Object.values(itemMap)

  return frequencies
}
