import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { IonCardContent, IonCardHeader, IonRow } from '@ionic/react'
import CardQrCodeImage from './CardQrCodeImage'
import { useUserContext } from '#contexts/UserContext'
import CardQrCodeTackpayId from './CardQrCodeTackpayId'
import CardQrCodeLink from './CardQrCodeLink'
import isBfunMember from '#constants/isBfun'
import CardQrCodeMember from './CardQrCodeMember'
import ModalLoading from '#components/ui/ModalLoading'
import { type Member } from '#tackpay-sdk'

export default function CardTippedQrCode(): JSX.Element {
  const { isLoading, error, user } = useUserContext()
  const isBfun = (): boolean => {
    const members = user?.members ?? []
    for (const member of members) {
      if (isBfunMember(member.jar?.id ?? '')) return true
    }
    return false
  }

  if (isLoading) return <ModalLoading />

  const isQrCodeJar = (member: Member[]): boolean => {
    const companyIds = [
      'b491ee93-778a-4321-8bb8-89f6632fc7fb',
      '9c86881d-23f5-4cf4-9716-c7257ed4a914',
      'e3bbf101-5ac8-4f94-b784-18d7d6cee3e0'
    ]
    return member.some((m) => companyIds.includes(m.jar?.company?.id ?? ''))
  }

  return user?.members != null && isQrCodeJar(user.members) ? (
    <CardQrCodeMember />
  ) : (
    <Card color='bg-card'>
      <IonCardHeader className='ion-no-padding'>
        <CardQrCodeImage
          loading={isLoading}
          error={error}
          src={user?.tackpay_id?.qr_code_storage_url ?? ''}
          isBlur={isBfun()}
        />
      </IonCardHeader>
      <IonCardContent>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <Text
            color='gray-500'
            fontWeight='font-semibold'
            translationOptions={{ translate: 'no' }}
          >
            TackPay ID:
          </Text>
          <CardQrCodeTackpayId
            loading={isLoading}
            error={error}
            tackpayId={user?.tackpay_id?.value ?? ''}
          />
        </IonRow>
        {!isBfun() && (
          <CardQrCodeLink
            loading={isLoading}
            error={error}
            tackpayId={user?.tackpay_id}
          />
        )}
      </IonCardContent>
    </Card>
  )
}
