import { useState } from 'react'
import CheckoutStripeElement from '../CheckoutStripeElement'
import CheckoutAmount from './CheckoutAmount'
import CheckoutPaymentRequestButton from './CheckoutPaymentRequestButton'
import CheckoutCardPayemntMethod, {
  type CheckoutPaymentMethodType
} from './CheckoutCardPaymentMethods'
import CheckoutFormActive from './CheckoutFormActive'
import StripeBadge from '../StripeBadge'

interface Props {
  tackpayIdValue: string
}

export default function CheckoutPaymentMethodContent({
  tackpayIdValue
}: Props): JSX.Element {
  const [paymentMethodType, setMethodType] =
    useState<CheckoutPaymentMethodType>('card')

  return (
    <>
      <CheckoutAmount />

      <CheckoutStripeElement>
        <CheckoutPaymentRequestButton />
        <CheckoutCardPayemntMethod
          methodType={paymentMethodType}
          setMethodType={setMethodType}
        />
        <CheckoutFormActive
          methodType={paymentMethodType}
          tackpayIdValue={tackpayIdValue}
        />
        <StripeBadge />
      </CheckoutStripeElement>
    </>
  )
}
