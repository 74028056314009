import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import type Stripe from 'stripe'

type PriceType = 'prices'
type PriceRel = ResourceRel & { type: PriceType }

type PriceBillingScheme = 'per_unit' | 'tiered'
type PriceCategory = 'one_time' | 'recurring'
interface PriceRecurring extends Stripe.Price.Recurring {}

interface Price extends Resource {
  readonly type: PriceType
  reference: string
  reference_origin: string
  is_active: boolean
  currency: string
  billing_scheme: PriceBillingScheme
  unit_amount?: number
  unit_amount_decimal?: string
  category: PriceCategory
  quantity?: number
  nickname?: string
  reference_product?: string
  reference_product_origin?: string
  recurring?: PriceRecurring
}

class Prices extends ApiResource<Price> {
  static readonly TYPE: PriceType = 'prices' as const

  isPrice(resource: any): resource is Price {
    return resource.type != null && resource.type === Prices.TYPE
  }

  relationship(id: string | ResourceId | null): PriceRel {
    return id === null || typeof id === 'string'
      ? { id, type: Prices.TYPE }
      : { id: id.id, type: Prices.TYPE }
  }

  type(): PriceType {
    return Prices.TYPE
  }
}

export type { Price }
export default Prices
