import Button from '#components/ui/Button'
import { cloudDownloadOutline } from 'ionicons/icons'

interface Props {
  /**
   * The onClick event handler
   */
  onClick?: () => void
}

export default function CardDownloadAccountStatement({
  onClick
}: Props): JSX.Element {
  return (
    <Button
      className='mt-20 w-full'
      iconOptions={{
        src: cloudDownloadOutline,
        slot: 'end',
        className: 'ion-align-items-center pt-1'
      }}
      onClick={onClick}
    >
      download
    </Button>
  )
}
