import useAuthState from '#hooks/useAuthState'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import { IonAvatar, IonImg, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

export default function LoginWithGoogle(): JSX.Element {
  const { push } = useIonRouter()

  const { i18n } = useTranslation()

  const { showToast } = useToast('error')

  const { loginWithGoogle } = useAuthState()

  const { startLoading, stopLoading } = useLoading('signingIn')

  const handleLoginWithGoogle = (): void => {
    loginWithGoogle()
      .then((res) => {
        startLoading()
        i18n
          .changeLanguage(res?.lang ?? 'en')
          .then(() => {
            stopLoading()
            console.log('User fetched')
            push(`/dashboard/${res.category}/home`, 'root')
          })
          .catch((error) => {
            console.log(error)
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          })
      })
      .catch((error) => {
        showToast(undefined, getErrorMessage(error))
        stopLoading()
      })
  }

  return (
    <IonAvatar
      className='bg-white shadow'
      color='white'
      onClick={handleLoginWithGoogle}
    >
      <IonImg src='/assets/img/google.svg' className='p-4' />
    </IonAvatar>
  )
}
