import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { IonCol, IonIcon, IonImg, IonRow } from '@ionic/react'
import { card } from 'ionicons/icons'
import CheckoutPopoverMethods from './CheckoutPopoverMethod'

export type CheckoutPaymentMethodType =
  | 'card'
  // | 'giropay'
  | 'bankcontact'
  | 'eps'
  | 'ideal'
  | 'sofort'
  | 'multibanco'
  | 'p24'

interface Props {
  methodType: CheckoutPaymentMethodType
  setMethodType: (method: CheckoutPaymentMethodType) => void
}

const CheckoutCardPaymentMethodAux = ({
  methodType,
  setMethodType
}: Props): Array<{
  method: CheckoutPaymentMethodType
  Component: JSX.Element
}> => {
  const methods: CheckoutPaymentMethodType[] = [
    'bankcontact',
    'card',
    'eps',
    // 'giropay',
    'ideal',
    'multibanco',
    'p24',
    'sofort'
  ]

  const methodElement: Array<{
    method: CheckoutPaymentMethodType
    Component: JSX.Element
  }> = []

  const getImageName = (method: CheckoutPaymentMethodType): string => {
    if (method === 'card') return card
    else return `/assets/img/${method}.svg`
  }

  const getImage = (
    method: CheckoutPaymentMethodType,
    isActive: boolean
  ): JSX.Element => {
    const isMultibanco = method === 'multibanco'
    if (method === 'card')
      return (
        <IonIcon
          src={getImageName(method)}
          size='small'
          color={isActive ? 'primary' : 'gray-500'}
          className='mb-1'
        />
      )
    else
      return (
        <IonImg
          src={getImageName(method)}
          className={`${isMultibanco ? 'w-[1.5rem]' : 'w-[2.4rem]'} mb-1`}
        />
      )
  }

  const ElementMethod = (method: CheckoutPaymentMethodType): JSX.Element => {
    const isActive = method === methodType
    const cardBorderActive = 'border-green-400'

    const textColorActive = 'gray-900'
    const textColorInactive = 'gray-500'

    const handleClick = (newMethod: CheckoutPaymentMethodType): void => {
      if (newMethod === methodType) return
      setMethodType(newMethod)
      // setLastPaymentMethodType(methodType)
    }

    return (
      <Card
        color='white'
        className={`w-3/6 ion-no-padding ion-no-margin border ${
          isActive ? cardBorderActive : ''
        }`}
        onClick={(): void => {
          handleClick(method)
        }}
      >
        <IonCol className='ion-no-padding ion-no-margin'>
          {getImage(method, isActive)}
          <Text
            color={isActive ? textColorActive : textColorInactive}
            className='capitalize'
            size='text-sm'
          >
            {method === 'card' ? 'card.name' : method}
          </Text>
        </IonCol>
      </Card>
    )
  }

  methods.forEach((method) => {
    const CurrentElement = ElementMethod(method)
    methodElement.push({
      method,
      Component: CurrentElement
    })
  })

  return methodElement
}

export default function CheckoutCardPayemntMethod({
  methodType,
  setMethodType
}: Props): JSX.Element {
  const Elements = CheckoutCardPaymentMethodAux({
    methodType,
    setMethodType
  })

  const CurrentElement = Elements.find((m) => m.method === methodType)

  return (
    <IonRow className='mt-10 ion-justify-content-between ion-align-items-center w-full'>
      {CurrentElement?.Component}
      <CheckoutPopoverMethods
        currentMethods={methodType}
        setCurrentMethods={setMethodType}
      />
    </IonRow>
  )
}
