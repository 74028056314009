import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { type Balance } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCardTitle } from '@ionic/react'
import { type BalanceDeduction } from 'sdk/resources/balanceDeductions'

interface Props {
  loading?: boolean
  error?: any
  balance?: Balance | null | BalanceDeduction
  useTotalBalance?: boolean
  currency?: string
}

export default function WalletAvailableBalance({
  loading = false,
  error,
  balance,
  useTotalBalance = false,
  currency
}: Props): JSX.Element {
  if (loading) return <Skeleton className='w-10 h-6' />
  if (error != null) return <ErrorBox error={error} />

  const balanceView = useTotalBalance ? balance?.total : balance?.available
  return (
    <IonCardTitle
      className='text-black font-bold text-3xl ion-no-margin ion-no-padding'
      data-testid='data-available'
    >
      {formattedAmount(
        balanceView?.amount_cents,
        balanceView?.currency ?? currency
      )}
    </IonCardTitle>
  )
}
