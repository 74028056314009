import ChartLine from '#components/charts/ChartLine'
import Text from '#components/ui/Text'
import { type StatisticBalance } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import isDesktop from '#utils/isDesktop'
import { IonCol, IonRow } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  showCard?: boolean
  statisticBalance: StatisticBalance
}
export default function StatisticBalanceCard({
  showCard = false,
  statisticBalance
}: Props): JSX.Element {
  const { t } = useTranslation()

  const [selectedParams, setSelectedParams] = useState<string>('day')

  const sortedBalance = statisticBalance.balances.sort((a, b) => {
    return a.date < b.date ? -1 : 0
  })

  const mapAmounts = (amounts: number[]): number[] => {
    const res: number[] = []
    let prev = 0

    amounts.forEach((amount) => {
      res.push(Number(formattedAmount(amount + prev)))
      prev = amount + prev
    })

    return res
  }

  const amount = mapAmounts(sortedBalance.map((balance) => balance.amount))
  //   sortedBalance.map((balance) => {
  //   let prev = 0
  //   const res = Number(formattedAmount(balance.amount + prev))
  //   console.log('Res', res, 'Prev', prev, 'Amount', balance.amount)
  //   prev = balance.amount
  //   return res
  // })

  const dates = sortedBalance.map((balance) =>
    formattedDate(new Date(balance.date))
  )

  const firstDate = new Date(dates[0])
  firstDate.setDate(firstDate.getDate() - 4)

  dates.unshift(formattedDate(firstDate))

  const series = {
    name: 'Balance',
    data: amount
  }

  const formatDate = (dates: string): string => {
    const date = new Date(dates)
    const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(
      date
    )
    const day = date.getDate()
    const complete = day + '/' + month.substring(0, 3)
    return complete
  }

  const [seriesState, setSeriesState] = useState<any>(series)

  const [dateState, setDatesState] = useState<string[]>(dates.map(formatDate))

  const dataSelected: string[] = ['week', 'month', 'year']

  series.data.unshift(0)

  const controlPadding = isDesktop() ? 'p-9' : 'p-2'

  const controlSize = isDesktop() ? '6' : '6'

  const onClickSelected = (data: string): void => {
    setSelectedParams(data)
  }

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [])

  const settingWeekBalance = (): void => {
    const weekDate = new Date()
    weekDate.setDate(weekDate.getDate() - 7)

    const newStatisticBalance = statisticBalance.balances.filter((balance) => {
      const dateToCompare = new Date(balance.date)
      return (
        dateToCompare.getDate() >= weekDate.getDate() &&
        dateToCompare.getDate() <= weekDate.getDate() + 7 &&
        dateToCompare.getMonth() <= weekDate.getMonth() &&
        dateToCompare.getFullYear() <= weekDate.getFullYear()
      )
    })

    setDatesState(
      newStatisticBalance.map((balance) =>
        formattedDate(new Date(balance.date))
      )
    )

    setDatesState((prevDates) => {
      if (prevDates.length === 0) return prevDates

      const firstDate = new Date(prevDates[0])
      firstDate.setDate(firstDate.getDate() - 4)

      const dates = prevDates

      dates.unshift(formattedDate(firstDate))

      return dates
    })

    const sortedBalance = newStatisticBalance.sort((a, b) => {
      return a.date < b.date ? -1 : 0
    })

    const amount = mapAmounts(sortedBalance.map((balance) => balance.amount))
    // sortedBalance.map((balance) =>
    //   Number(formattedAmount(balance.amount))
    // )

    amount.unshift(0)

    setSeriesState({
      name: 'Balance',
      data: amount
    })
  }

  const settingMonthBalance = (): void => {
    const monthDate = new Date()
    monthDate.setMonth(monthDate.getMonth() - 1)

    const newStatisticBalance = statisticBalance.balances.filter((balance) => {
      const dateToCompare = new Date(balance.date)
      return (
        dateToCompare.getMonth() <= monthDate.getMonth() + 1 &&
        dateToCompare.getMonth() >= monthDate.getMonth() - 1 &&
        dateToCompare.getFullYear() <= monthDate.getFullYear()
      )
    })

    setDatesState(
      newStatisticBalance.map((balance) =>
        formattedDate(new Date(balance.date))
      )
    )

    setDatesState((prevDates) => {
      if (prevDates.length === 0) return prevDates
      const firstDate = new Date(prevDates[0])
      firstDate.setDate(firstDate.getDate() - 4)

      const dates = prevDates

      dates.unshift(formattedDate(firstDate))

      return dates
    })

    const sortedBalance = newStatisticBalance.sort((a, b) => {
      return a.date < b.date ? -1 : 0
    })

    const amount = sortedBalance.map((balance) => balance.amount)
    //   sortedBalance.map((balance) =>
    //   Number(formattedAmount(balance.amount))
    // )

    amount.unshift(0)

    setSeriesState({
      name: 'Balance',
      data: amount
    })
  }

  const settingYearBalance = (): void => {
    const yearDate = new Date()
    yearDate.setFullYear(yearDate.getFullYear() - 1)

    const newStatisticBalance = statisticBalance.balances.filter((balance) => {
      const dateToCompare = new Date(balance.date)
      return (
        dateToCompare.getFullYear() <= yearDate.getFullYear() + 1 &&
        dateToCompare.getFullYear() >= yearDate.getFullYear() - 1
      )
    })

    setDatesState(
      newStatisticBalance.map((balance) =>
        formattedDate(new Date(balance.date))
      )
    )

    setDatesState((prevDates) => {
      if (prevDates.length === 0) return prevDates
      const firstDate = new Date(prevDates[0])
      firstDate.setDate(firstDate.getDate() - 4)

      const dates = prevDates

      dates.unshift(formattedDate(firstDate))

      return dates
    })

    const sortedBalance = newStatisticBalance.sort((a, b) => {
      return a.date < b.date ? -1 : 0
    })

    const amount = mapAmounts(sortedBalance.map((balance) => balance.amount))
    //   sortedBalance.map((balance) =>
    //   Number(formattedAmount(balance.amount))
    // )

    amount.unshift(0)

    setSeriesState({
      name: 'Balance',
      data: amount
    })
  }

  useEffect(() => {
    if (selectedParams === 'week') {
      settingWeekBalance()
    } else if (selectedParams === 'month') {
      settingMonthBalance()
    } else if (selectedParams === 'year') {
      settingYearBalance()
    }
  }, [selectedParams])

  return showCard ? (
    <div
      className={`col-span-12 rounded-sm border border-stroke bg-statistic ${controlPadding} shadow-default h-full`}
    >
      <IonRow className=' w-full ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding'>
        <IonCol size='6' className='ion-no-margin ion-no-padding'>
          <IonRow className='w-full ion-justify-content-start ion-no-margin ion-no-padding'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonRow className='ion-no-margin ion-no-padding'>
                <span className='mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-green-500'>
                  <span className='block h-2.5 w-full max-w-2-5 rounded-full bg-green-500' />
                </span>
                <p className='font-semibold text-gray-900'>{t('balances')}</p>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>

        <IonCol size={controlSize} className='ion-no-margin ion-no-padding'>
          <IonRow className='w-full py-2 px-1 rounded-md bg-gray-100 ion-justify-content-around ion-align-items-center ion-no-margin ion-no-padding'>
            {dataSelected.map((params, i) => (
              <IonCol
                key={i}
                size='3'
                className={`ion-no-margin ion-no-padding ${
                  params === selectedParams
                    ? 'bg-white shadow-sm rounded-md'
                    : 'hover:bg-white  hover:shadow-sm rounded-md'
                }`}
              >
                <IonRow className='ion-align-items-center w-full ion-no-margin py-1'>
                  <button
                    className='w-full'
                    onClick={() => {
                      onClickSelected(params)
                    }}
                  >
                    <Text size='text-xs'>{params}</Text>
                  </button>
                </IonRow>
              </IonCol>
            ))}
          </IonRow>
        </IonCol>
      </IonRow>

      <div>
        <ChartLine amount={seriesState.data} dates={dateState} />
      </div>
    </div>
  ) : (
    <ChartLine amount={seriesState.data} dates={dateState} />
  )
}
