import AuthLayout from '#components/layouts/auth/AuthLayout'
import Avatar from '#components/ui/Avatar'
import useToast from '#hooks/useToast'
import { useEffect, useRef, useState } from 'react'
import TabCheckout from '../checkouts/TabCheckout'
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react'
import Underlined from '#components/ui/UnderlinedName'
import { IonRow, useIonRouter } from '@ionic/react'
import Text from '#components/ui/Text'
import DefaultTipButton from '#components/ui/DefaultTipButton'
import Button from '#components/ui/Button'
import isDesktop from '#utils/isDesktop'
import CustomModal, { type TypeCustomModal } from '../checkouts/CustomModal'
import { getSymbolCurrency } from '#constants/country'
import { savePreferences } from 'storage/preferences'
import useLoading from '#hooks/useLoading'

const profiles = [
  { img: '/assets/img/pool.svg', name: 'TackPay' },
  { img: '/assets/img/jack.jpeg', name: 'Giacomo' },
  { img: '/assets/img/matte.jpg', name: 'Matteo' },
  { img: '/assets/img/dave.jpg', name: 'Davide' },
  { img: '/assets/img/ale.jpg', name: 'Alessandro' }
]

export default function Select(): JSX.Element {
  const swiperRef = useRef<SwiperRef>(null)

  const { showToast: showTeam, hideToast: hideTeam } = useToast('success')

  const { showToast: showMember, hideToast: hideMember } = useToast('success')

  const { showToast: showWarn } = useToast('warning')

  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.create.loading'
  )

  const [currentMember, setCurrentMember] = useState<
    { img: string; name: string } | undefined
  >(undefined)

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const [currentAmount, setCurrentAmount] = useState<number | undefined>(0)

  const [tipModalType, setTipModalType] = useState<
    TypeCustomModal | undefined
  >()

  const [showModalCustom, setShowModalCustom] = useState<boolean>(false)

  const { push } = useIonRouter()

  useEffect(() => {}, [activeIndex])

  const handlerClickAvatar = (index: number): void => {
    if (index < activeIndex) {
      swiperRef.current?.swiper?.slideTo(activeIndex - 1)
    } else {
      swiperRef.current?.swiper?.slideTo(activeIndex + 1)
    }
    if (index === activeIndex) swiperRef.current?.swiper?.slideTo(index + 1)
  }

  const handleSlideChange = (swiper: any): void => {
    setActiveIndex(swiper.activeIndex)

    if (swiper.activeIndex === 0) {
      setCurrentMember(undefined)
      hideMember()

      showTeam(undefined, 'tipToTeam')
    } else {
      const members = profiles.map((p) => p).flat()
      if (members != null) {
        const member = members[swiper.activeIndex]
        hideTeam()
        setCurrentMember(member)
        showMember(undefined, 'tipToTipped', {
          data: {
            name: member?.name
          }
        })
      }
    }
  }

  const handleSubmit = (e: any): void => {
    e.preventDefault()
    if (currentAmount != null && currentAmount <= 0) {
      showWarn(undefined, 'checkout.payment.create.zeroAmount')
      return
    }

    if (currentAmount != null) {
      startLoading()
      const amount = parseFloat((currentAmount * 100).toFixed(2))
      void savePreferences('amount_checkout', amount)
      setTimeout(() => {
        stopLoading()
        push('/demo/methods')
      }, 1000 * 2)
    }
  }

  const viewDesktop = isDesktop() ? 'm-auto w-5/6' : 'w-full'

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
    >
      <TabCheckout section='select' />
      <form className='w-full' onSubmit={handleSubmit}>
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={3}
          centeredSlides
          initialSlide={0}
          onSlideChange={handleSlideChange}
        >
          <SwiperSlide>
            <Avatar
              avatarOptions={{
                className: `${
                  currentMember == null ? 'border-green-400' : 'border-gray-400'
                } border-4  mx-auto h-24 w-24`,
                onClick: () => {
                  handlerClickAvatar(0)
                }
              }}
              imgOptions={{
                src: profiles[0].img
              }}
            />
          </SwiperSlide>
          {profiles.slice(1, profiles.length).map((profile, index) => (
            <SwiperSlide key={index}>
              <Avatar
                avatarOptions={{
                  className: `${
                    currentMember?.name === profile.name
                      ? 'border-green-400'
                      : 'border-gray-400'
                  } border-4  mx-auto h-24 w-24`,
                  onClick: () => {
                    handlerClickAvatar(index + 1)
                  }
                }}
                imgOptions={{
                  src: profile.img
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className='mt-8'>
          <Underlined
            name={currentMember != null ? currentMember?.name : 'Pool'}
          />
        </div>
        <IonRow className='pt-8 ion-justify-content-center'>
          <Text className='text-gray-600 text-center'>default_bio</Text>
        </IonRow>
        <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
        <DefaultTipButton
          tip={['5', '10', '20']}
          amount={currentAmount}
          setAmount={setCurrentAmount}
          currency='eur'
        />
        <IonRow
          className={`${viewDesktop} ion-justify-content-around pt-5 px-2 mt-3`}
        >
          <Button
            type='button'
            id='modal-custom'
            color='white'
            className='choose-button-preview shadow-md ion-no-margin rounded-md w-5/12'
            onClick={() => {
              setTipModalType('amount')
              setShowModalCustom(true)
            }}
          >
            <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
              type_amount
            </Text>
          </Button>
          <Button
            type='button'
            id='modal-custom'
            color='white'
            className='choose-button-preview shadow-md ion-no-margin rounded-md w-5/12'
            onClick={() => {
              setTipModalType('percentage')
              setShowModalCustom(true)
            }}
          >
            <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
              %
            </Text>
          </Button>
        </IonRow>
        <IonRow className='mt-12 mx-0 ion-no-padding w-full '>
          <Button
            type='submit'
            className='w-full ion-no-margin ion-no-padding'
            translationOptions={{
              translate: 'yes',
              data: {
                amount: currentAmount === 0 ? '' : currentAmount?.toFixed(2),
                currency: currentAmount === 0 ? '' : getSymbolCurrency('eur')
              }
            }}
          >
            confirmAmount
          </Button>
        </IonRow>
      </form>
      <CustomModal
        dataTip={tipModalType ?? 'percentage'}
        openModal={showModalCustom}
        setOpenModal={setShowModalCustom}
        currency='eur'
        setCurrentAmount={setCurrentAmount}
        currentAmount={currentAmount ?? 0}
        submitFunction={handleSubmit}
      />
    </AuthLayout>
  )
}
