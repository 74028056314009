import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Title from '#components/ui/Title'
import {
  STATISTIC_CATEGORIES,
  type StatisticCategory
} from '#constants/statistics'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  currentCategory: StatisticCategory
  handleStatsChange: SelectOnChangeEvent
}

export default function StatisticHeader({
  currentCategory,
  handleStatsChange
}: Props): JSX.Element {
  const customPopoverOptions = {
    side: 'bottom',
    alignament: 'left'
  }
  return (
    <IonRow className='ion-justify-content-between ion-no-padding ion-align-items-center'>
      <IonCol className='ion-no-padding' size='8'>
        <Title>statistics.title</Title>
      </IonCol>
      <IonCol className='ion-no-padding' size='4'>
        <BasicSelect
          interface='popover'
          interfaceOptions={customPopoverOptions}
          placeholder={currentCategory}
          options={{
            childrens: STATISTIC_CATEGORIES as any
          }}
          value={currentCategory}
          onIonChange={handleStatsChange}
        />
      </IonCol>
    </IonRow>
  )
}
