import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type Subscription } from './subscriptions'
import { type SubscriptionLink } from './subscriptionLinks'
import { type ProductLink } from './productLinks'

type CustomerType = 'customers'
type CustomerRel = ResourceRel & { type: CustomerType }

type CustomerCategory = 'individual' | 'company'

interface Customer extends Resource {
  readonly type: CustomerType

  reference: string
  reference_origin: string
  currency: string
  email: string
  phone?: string
  balance: number
  category: CustomerCategory
  user?: User
  company?: Company
  subscription?: Subscription
  subscription_links?: SubscriptionLink[]
  product_links?: ProductLink[]
}

class Customers extends ApiResource<Customer> {
  static readonly TYPE: CustomerType = 'customers' as const

  isCustomer(resource: any): resource is Customer {
    return resource.type != null && resource.type === Customers.TYPE
  }

  relationship(id: string | ResourceId | null): CustomerRel {
    return id === null || typeof id === 'string'
      ? { id, type: Customers.TYPE }
      : { id: id.id, type: Customers.TYPE }
  }

  type(): CustomerType {
    return Customers.TYPE
  }
}

export type { Customer }
export default Customers
