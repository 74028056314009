import Text from '#components/ui/Text'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  value: any
  symbol: string
}
export default function BreakDown({ value, symbol }: Props): JSX.Element {
  return (
    <IonRow className='rounded-full w-[4.3rem] h-[4.3rem] bg-white ion-text-center border border-gray-200 ion-justify-content-between ion-align-items-center'>
      <IonCol
        size='12'
        className='ion-text-center w-full ion-no-padding ion-no-margin'
      >
        <IonRow className='w-full ion-no-padding ion-no-margin ion-justify-content-center'>
          <Text
            translationOptions={{
              translate: 'no'
            }}
            color='primary'
            fontWeight='font-semibold'
            size='text-xl'
            className='ion-text-center'
          >
            {value}
            {symbol}
          </Text>
        </IonRow>
      </IonCol>
    </IonRow>
  )
}
