import Text from '#components/ui/Text'
import { IonRow } from '@ionic/react'

export default function CheckoutMethodInfo(): JSX.Element {
  return (
    <IonRow className='ion-justify-content-start ion-align-items-center ion-no-padding ion-no-margin w-full mb-5'>
      <svg
        className='w-16 h-8'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 48 40'
        role='presentation'
      >
        <path
          opacity='.6'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 8a4 4 0 014-4h30a4 4 0 014 4v8a1 1 0 11-2 0v-4a2 2 0 00-2-2H4a2 2 0 00-2 2v20a2 2 0 002 2h30a2 2 0 002-2v-6a1 1 0 112 0v6a4 4 0 01-4 4H4a4 4 0 01-4-4V8zm4 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm29.992 9.409L44.583 20H29a1 1 0 100 2h15.583l-3.591 3.591a1 1 0 101.415 1.416l5.3-5.3a1 1 0 000-1.414l-5.3-5.3a1 1 0 10-1.415 1.416z'
        />
      </svg>
      <Text size='text-xs'>checkout.methodInfo</Text>
    </IonRow>
  )
}
