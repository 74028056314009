import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Collaborator } from '#tackpay-sdk'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
// import { createOutline } from 'ionicons/icons'
// import CollaboratorModal from './CollaboratorModal'
// import { useState } from 'react'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useState } from 'react'
import CollaboratorModal from './CollaboratorModal'
import { useCompanyContext } from '#contexts/CompanyContext'
import { createOutline } from 'ionicons/icons'

interface Props {
  /**
   * Collaborator object
   */
  collaborator: Collaborator

  testId?: string
}

export default function CollaboratorCard({
  collaborator,
  testId
}: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false)

  const { company } = useCompanyContext()

  return (
    <>
      <CollaboratorModal
        collaborator={collaborator}
        setShowModal={setShowModal}
        showModal={showModal}
        jars={company?.jars ?? []}
      />
      <Card>
        <IonRow
          className='ion-align-items-center ion-justify-content-between'
          data-testid={testId}
        >
          <Avatar
            imgOptions={{
              src: collaborator?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
            }}
          />
          <IonCol size='8'>
            <Text
              size='text-base'
              color='gray-900'
              fontWeight='font-bold'
              translationOptions={{
                translate: 'no'
              }}
              className='capitalize'
            >
              {collaborator?.user?.person?.first_name ?? collaborator?.role}
            </Text>
            <Text
              size='text-sm'
              translationOptions={{
                translate: 'no'
              }}
            >
              {collaborator?.user?.email ?? collaborator.email}
            </Text>
          </IonCol>
          {collaborator != null && (
            <IonIcon
              icon={createOutline}
              size='small'
              className='inline-block cursor-pointer'
              color='tertiary'
              onClick={() => {
                setShowModal(true)
              }}
            />
          )}
        </IonRow>
      </Card>
    </>
  )
}
