import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useTokenContext } from '#contexts/TokenContext'
import RegistrationMemberTeamInfo from './RegistrationMemberTeamInfo'
import RegistrationMemberTitle from './RegistrationMemberTitle'
import Button from '#components/ui/Button'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { savePreferences } from 'storage/preferences'
import { MEMBER_TOKEN_KEY } from '#constants/memberTokenKey'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import Text from '#components/ui/Text'

export default function RegistrationMember(): JSX.Element {
  const { token, isLoading, error } = useTokenContext()

  const { push } = useIonRouter()

  const { setUserData } = useRegistrationContext()

  if (isLoading)
    return (
      <>
        <Skeleton className='w-full h-10' />
        <Skeleton className='w-full h-10' />
      </>
    )

  if (error != null) return <ErrorBox error={error} />

  if (token == null) return <ErrorBox error='Token not found' />

  const handleContinue = (action: 'singIn' | 'signUp'): void => {
    if (action === 'signUp') {
      setUserData({ category: 'tipped', token: token.value })
      push('/registrations/tipped', 'root')
    } else {
      void savePreferences(MEMBER_TOKEN_KEY, token.value)
      push('/login', 'root')
    }
  }

  const expiresDate = new Date(token.expires_at)

  if (expiresDate.getTime() < Date.now())
    return (
      <ErrorBox
        error={{
          message: 'token_expired_jar_invite'
        }}
      />
    )

  return (
    <>
      <RegistrationMemberTitle token={token} />
      <RegistrationMemberTeamInfo token={token} />
      <IonCol className='block mt-10'>
        <Button
          className='w-full mt-5'
          onClick={() => {
            handleContinue('signUp')
          }}
        >
          signUp
        </Button>
        <IonRow className='mt-14 space-x-2 ion-text-center ion-justify-content-center ion-align-items-center'>
          <Text className='ion-text-center' size='text-sm'>
            alredyAccount
          </Text>

          <Text
            color='primary'
            size='text-sm'
            className='cursor-pointer'
            onClick={() => {
              handleContinue('singIn')
            }}
          >
            signIn
          </Text>
        </IonRow>
      </IonCol>
    </>
  )
}
