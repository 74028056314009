import Label from '#components/ui/Label'
import { IonCol, IonRow } from '@ionic/react'
import { type Address } from '#tackpay-sdk'
import GooglePlacesAutocomplete, {
  geocodeByAddress
} from 'react-google-places-autocomplete'

interface Props {
  setAddress: (address: Partial<Omit<Address, 'type'>>) => void
}
export default function AddressAutoComplete({
  setAddress
}: Props): JSX.Element {
  const street = ['route']
  const city = ['locality', 'political']
  const countries = [['country', 'political'], ['country']]
  const postalCode = ['postal_code']
  const state = [
    ['administrative_area_level_1', 'political'],
    ['administrative_area_level_1']
  ]
  const streetNumber = ['street_number']
  const handleAutoComplete = (value: any): void => {
    geocodeByAddress(value.label)
      .then((results) => {
        const result = results[0]
        // console.log('address result %o', result)
        const addressComponents = result.address_components
        const address: Partial<Address> = {}
        addressComponents.forEach((addressComponent) => {
          const types = addressComponent.types
          if (isEquals(types, street)) {
            console.log('street %o', addressComponent.long_name)
            address.street = addressComponent.long_name
            // setAddress({ street: addressComponent.long_name })
          } else if (isEquals(types, city)) {
            console.log('city %o', addressComponent.long_name)
            // setAddress({ city: addressComponent.long_name })
            address.city = addressComponent.long_name
          } else if (
            isEquals(types, countries[0]) ||
            isEquals(types, countries[1])
          ) {
            // console.log('country %o', addressComponent.long_name)
            // setAddress({ country_name: addressComponent.long_name })
            address.country_name = addressComponent.long_name
          } else if (isEquals(types, postalCode)) {
            // console.log('postal_code %o', addressComponent.long_name)
            // setAddress({ postal_code: addressComponent.long_name })
            address.postal_code = addressComponent.long_name
          } else if (isEquals(types, state[0]) || isEquals(types, state[1])) {
            // console.log('state %o', addressComponent.long_name)
            // setAddress({ state: addressComponent.long_name })
            address.state = addressComponent.long_name
          } else if (isEquals(types, streetNumber)) {
            // console.log('street_number %o', addressComponent.long_name)
            // setAddress({ street_number: addressComponent.long_name })
            address.street_number = addressComponent.long_name
          } else {
            console.log('unable to set address %o', addressComponent)
          }
        })
        setAddress(address)
      })
      .catch((error) => {
        console.log('unable to get address %o', error)
      })
  }

  const isEquals = (arr1: any[], arr2: any[]): boolean => {
    return JSON.stringify(arr1) === JSON.stringify(arr2)
  }

  return (
    <IonCol className='ion-no-padding ion-no-margin'>
      <Label
        position='fixed'
        className='mb-1 text-sm font-medium text-gray-900 '
        style={{ width: '100%' }}
        capitalize={false}
        data-testid='addressAutoComplete'
      >
        addressAutoComplete
      </Label>
      <IonRow>
        <GooglePlacesAutocomplete
          apiKey={
            process.env.REACT_APP_GOOGLE_API_KEY ??
            'AIzaSyBAwrXr_n0H2szztMKJ8P3Y5Zkzl9yHjFs'
          }
          apiOptions={{ language: 'en' }}
          selectProps={{
            onChange: handleAutoComplete,
            components: {
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null
            },
            styles: {
              menuList: () => ({
                backgroundColor: '#f7f7f7',
                borderRadius: '5px',
                color: '#000000'
              }),
              container: (base: any) => ({
                ...base,
                borderColor: 'black',
                boxShadow: 'none',
                marginTop: '-13px',
                width: '100%'
              }),
              input: (base: any) => ({
                ...base,
                borderColor: 'black',
                border: 'none'
              }),
              control: (base: any) => ({
                ...base,
                paddingTop: '3px',
                paddingBottom: '3px',
                marginTop: '1.25rem',
                borderColor: 'rgba(0, 0, 0, 0.2)'
              }),
              placeholder: (base: any) => ({
                ...base,
                width: '90%'
              })
            }
          }}
        />
      </IonRow>
    </IonCol>
  )
}
