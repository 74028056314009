import Button from '#components/ui/Button'
import { IonRow, useIonRouter } from '@ionic/react'

interface Props {
  /**
   * Handle next button click
   */
  handleNext?: () => void
  /**
   * Handle back button click
   */
  handleBack?: () => void
  /**
   * Next button type
   */
  nextType?: 'submit' | 'button'
  /**
   * page category
   */
  page?: string
  /**
   * Show back button
   * @default true
   */
  showBack?: boolean
  /**
   * Show next button
   * @default true
   */
  showNext?: boolean

  disabledNext?: boolean
}

export default function ButtonStep({
  handleNext,
  handleBack,
  nextType = 'button',
  showBack = true,
  showNext = true,
  disabledNext = false,
  page
}: Props): JSX.Element {
  const { goBack } = useIonRouter()

  const handleBackAction = (): void => {
    console.log('handleBackAction', handleBack)
    if (handleBack != null) {
      handleBack()
    } else {
      goBack()
    }
  }

  return (
    <IonRow
      className={`${
        showBack ? 'ion-justify-content-between' : 'ion-justify-content-end'
      } mt-5 ion-align-items-center`}
    >
      {showBack ? (
        <Button
          className='text-gray-700'
          color='trasparent'
          onClick={handleBackAction}
          data-testid='button-back'
        >
          back
        </Button>
      ) : null}

      {showNext && (
        <Button
          className='button-next-step text-white'
          onClick={nextType === 'submit' ? undefined : handleNext}
          type={nextType}
          data-testid='button-next'
          disabled={disabledNext}
        >
          next
        </Button>
      )}
    </IonRow>
  )
}
