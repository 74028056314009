import {
  type QueryParamsRetrieve,
  type CheckoutSetting,
  type CheckoutSettingUpdate,
  type LinksSocialUpdate,
  type ReplyUpdate
} from '#tackpay-sdk'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation,
  type RefetchOptions,
  type QueryObserverResult
} from '@tanstack/react-query'
import sdk from '#utils/sdk'
import useAuthState from '#hooks/useAuthState'

interface CheckoutSettingUpdateFunctionProps {
  checkoutSetting: CheckoutSettingUpdate
  linksSocial?: LinksSocialUpdate
  reply?: ReplyUpdate
}

type UpdateCheckoutSettingFunction = UseMutationResult<
  CheckoutSetting,
  unknown,
  CheckoutSettingUpdateFunctionProps,
  unknown
>

interface CheckoutSettingContextValue {
  checkoutSetting?: CheckoutSetting | null
  isLoading: boolean
  error?: any
  setCurrentCheckoutSetting?: (
    checkoutSetting: Partial<CheckoutSetting>
  ) => void
  updateCheckoutSetting?: UpdateCheckoutSettingFunction
  refetch?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CheckoutSetting, Error>>
}

const checkoutSettingInitialValue: CheckoutSettingContextValue = {
  checkoutSetting: null,
  isLoading: false,
  error: null,
  setCurrentCheckoutSetting: () => {}
}

const CheckoutSettingContext = createContext<CheckoutSettingContextValue>(
  checkoutSettingInitialValue
)

export const useCheckoutSettingContext = (): CheckoutSettingContextValue => {
  const context = useContext(CheckoutSettingContext)
  if (context == null) {
    throw new Error(
      'useCheckoutSettingContext() can only be used inside of <CheckoutSettingContextProvider />, ' +
        'please declare it at a higher level.'
    )
  }
  return context
}

interface CheckoutSettingContainerProps {
  /**
   * The children of the component.
   */
  children: React.ReactNode
  /**
   * If automatic fetching is enabled, the component will fetch the data on mount
   * @default true
   */
  fetch?: boolean
  /**
   * The id of the jar to fetch the data from
   */
  jarId?: string
  /**
   * The id of the company to fetch the data from
   */
  companyId?: string
  /**
   * The query params to use when fetching the data
   */
  params?: QueryParamsRetrieve
}

export default function CheckoutSettingContainer(
  props: CheckoutSettingContainerProps
): JSX.Element {
  const { children, fetch = true, params, jarId, companyId } = props

  const queryClient = useQueryClient()

  const { getAuthState } = useAuthState()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const [currentCheckoutSetting, setCheckoutSetting] = useState<
    CheckoutSetting | undefined
  >()

  const {
    data: checkoutSetting,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['checkoutSetting', jarId, params],
    queryFn: async () => {
      let checkoutSetting: CheckoutSetting | undefined
      if (jarId != null) {
        checkoutSetting = await sdk.jars.checkout_setting(jarId, params)
      } else if (companyId != null) {
        checkoutSetting = await sdk.companies.checkout_setting(
          companyId,
          params
        )
      } else {
        const authInfo = await getAuthState()
        checkoutSetting = await sdk.users.checkout_setting(
          authInfo?.userId ?? '',
          params
        )
      }
      if (checkoutSetting?.fast_amounts != null) {
        checkoutSetting.fast_amounts = checkoutSetting.fast_amounts.sort(
          (a, b) => a - b
        )
      }
      return checkoutSetting
    },
    enabled: !initialFetchComplete && fetch
  })

  useEffect(() => {
    if (!initialFetchComplete) {
      setCheckoutSetting(checkoutSetting)
    }
  }, [checkoutSetting, initialFetchComplete])

  useEffect(() => {
    if (checkoutSetting != null) {
      queryClient.setQueryData(
        ['checkoutSetting', jarId, params],
        checkoutSetting
      )
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const handleSetCheckoutSetting = (
    checkoutSettingUpdated: Partial<CheckoutSetting>
  ): void => {
    const newCheckoutSetting = {
      ...checkoutSetting,
      ...checkoutSettingUpdated,
      links_social: {
        ...checkoutSetting?.links_social,
        ...checkoutSettingUpdated.links_social
      },
      reply: {
        ...checkoutSetting?.reply,
        ...checkoutSettingUpdated.reply
      }
    } as unknown as CheckoutSetting
    setCheckoutSetting(newCheckoutSetting)
  }

  const handleUpdateCheckoutSetting = async (
    checkoutSettingUpdated: CheckoutSettingUpdateFunctionProps
  ): Promise<CheckoutSetting> => {
    const { checkoutSetting, linksSocial, reply } = checkoutSettingUpdated
    const result = await sdk.checkout_settings.update(checkoutSetting)

    if (linksSocial?.id != null) {
      await sdk.links_socials.update(linksSocial)
    }

    if (reply?.id != null) {
      await sdk.replies.update(reply)
    }

    return result
  }

  const useUpdateCheckoutSetting = useMutation({
    mutationFn: handleUpdateCheckoutSetting,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['checkoutSetting', jarId, params]
      })
      // queryClient.setQueryData(['checkoutSetting', params, jarId], data)
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['checkoutSetting', jarId, params]
      })
    }
  })

  const checkoutSettingContextValue: CheckoutSettingContextValue = {
    checkoutSetting: currentCheckoutSetting,
    isLoading,
    error,
    setCurrentCheckoutSetting: handleSetCheckoutSetting,
    updateCheckoutSetting: useUpdateCheckoutSetting,
    refetch
  }

  return (
    <CheckoutSettingContext.Provider value={checkoutSettingContextValue}>
      {children}
    </CheckoutSettingContext.Provider>
  )
}
