import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'

interface Props {
  loading?: boolean
  error?: any
  tackpayId: string
  size?: string
}

export default function CardQrCodeTackpayId({
  loading = true,
  error = null,
  tackpayId,
  size
}: Props): JSX.Element {
  if (loading) return <Skeleton className='w-1/4' />
  if (error != null) return <ErrorBox error={error} />
  return (
    <Text
      color='gray-900'
      fontWeight='font-bold'
      translationOptions={{
        translate: 'no'
      }}
      size={size}
    >
      {tackpayId}
    </Text>
  )
}
