import useAuthState from '#hooks/useAuthState'
import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'

export default function RedirectToLogin(): null {
  const { logout } = useAuthState()

  const { push } = useIonRouter()

  useEffect(() => {
    logout()
      .then(() => {
        push('/login', 'root')
      })
      .catch((error) => {
        console.error(error)
        push('/login', 'root')
      })
  }, [])
  return null
}
