import { IonImg, IonItem, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../constants/languages'
import Dropdown from '../ui/Dropdown'
import Text from '../ui/Text'
import { useState } from 'react'

export default function LanguageSwitcher(): JSX.Element {
  const { routeInfo } = useIonRouter()

  const [isOpen, setIsOpen] = useState(false)

  const { i18n } = useTranslation()

  const currentLanguage = LANGUAGES.find((lang) => lang.code === i18n.language)
  const onChangeLanguage = (languageCode: string): void => {
    console.log('Changing language to: ', languageCode)
    i18n
      .changeLanguage(languageCode)
      .then(() => {
        console.log('Language changed to: ', languageCode)
      })
      .catch((error) => {
        console.warn('Error changing language: ', error)
      })
    setIsOpen(false)
  }

  const CURRENT_LANGUAGES =
    routeInfo.pathname.includes('checkout') ||
    routeInfo.pathname.includes('demo')
      ? LANGUAGES
      : LANGUAGES.filter((l) => l.code !== 'fr')

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      toggleOptions={{
        fill: 'clear',
        className: 'ion-no-padding ion-no-margin',
        children: <IonImg src={currentLanguage?.img} className='w-9/12' />
      }}
      contentOptions={{
        scrollY: false,
        id: 'auth'
      }}
    >
      {CURRENT_LANGUAGES.map((language) => (
        <IonItem
          key={language.code}
          onClick={() => {
            onChangeLanguage(language.code)
          }}
          className='ion-no-padding ion-no-margin space-x-4 ion-justify-content-center ion-align-items-center p-2'
          lines='none'
        >
          <IonImg
            src={language.img}
            className='w-10'
            onClick={() => {
              onChangeLanguage(language.code)
            }}
          />
          <Text color='gray-900'>{language.name}</Text>
        </IonItem>
      ))}
    </Dropdown>
  )
}
