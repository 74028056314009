import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type PayoutRoutesParams } from '../../WithdrawalRoute'
import PayoutContainer from '#contexts/PayoutContext'
import PayoutTitle from '#components/pages/payouts/details/PayoutTitle'
import PayoutCardDetail from '#components/pages/payouts/details/PayoutCardDetail'
import { IonCol } from '@ionic/react'
import Button from '#components/ui/Button'

export default function PayoutId(): JSX.Element {
  const { category, payoutId } = useParams<PayoutRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings/withdrawals/payouts`
        }
      }}
    >
      <PayoutContainer
        payoutId={payoutId}
        params={{
          include: ['bank_account']
        }}
      >
        <PayoutTitle />
        <PayoutCardDetail />
        <IonCol className='mt-10 block space-y-5'>
          <Button
            className='w-full'
            routerLink={`/dashboard/${category}/settings/contact-us`}
            routerDirection='root'
          >
            contact
          </Button>
          {/* <Button color='dark'>edit_data</Button> */}
        </IonCol>
      </PayoutContainer>
    </AppLayout>
  )
}
