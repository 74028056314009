import { ref, listAll, getBlob } from 'firebase/storage'
import { getFirebaseStorage } from '#providers/firebase'
import randomString from '#utils/randomString'
import { FirebaseStorage } from '@capacitor-firebase/storage'
import JSZip from 'jszip'
import base64ToBlob from '#utils/base64ToBlob'

type UploadByDataUrl = (
  base64?: string,
  dataUrl?: string,
  refPath?: string,
  fileName?: string
) => Promise<string>

interface UseStorage {
  uploadByDataUrl: UploadByDataUrl
  downloadDir: (dir: string) => Promise<string[]>
  downloadFolderFromStorage: (folderPath: string) => Promise<Blob>
  getBlobImage: (
    imagePath: string,
    bucket?: 'default' | 'invoice'
  ) => Promise<Blob>
}

export default function useStorage(): UseStorage {
  const defaultName = `${randomString(10)}.png`
  const randomPath = randomString(10)

  const uploadFileAndReturnUrl = async (
    dataUrl?: string,
    blob?: Blob,
    refPath = randomPath,
    fileName = defaultName
  ): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return await new Promise(async (resolve, reject) => {
      await FirebaseStorage.uploadFile(
        {
          path: `${refPath}/${fileName}`,
          blob,
          uri: dataUrl
        },
        (event, error) => {
          if (error != null) reject(error)
          if (event?.completed === true) {
            FirebaseStorage.getDownloadUrl({
              path: `${refPath}/${fileName}`
            })
              .then((url) => {
                resolve(url.downloadUrl)
              })
              .catch((error) => {
                reject(error)
              })
          }
        }
      )
    })
  }

  const uploadByDataUrl: UploadByDataUrl = async (
    dataUrl,
    base64,
    refPath = randomPath,
    fileName = defaultName
  ) => {
    console.log('dataUrl', dataUrl)

    if (base64 != null) {
      const blob = base64ToBlob(base64)

      console.log('blob', blob)

      return await uploadFileAndReturnUrl(undefined, blob, refPath, fileName)
    } else if (dataUrl != null) {
      console.log('dataUrl', dataUrl)
      return await uploadFileAndReturnUrl(dataUrl, undefined, refPath, fileName)
    } else {
      throw new Error('Base64 or dataUrl is empty')
    }

    // if (blob != null || Object.entries(blob).length !== 0) {
    //   const storageRef = ref(getFirebaseStorage(), `${refPath}/${fileName}`)
    //   await uploadBytes(storageRef, blob)
    //   return await getPublicUrl(storageRef)
    // }

    // throw new Error('Blob is empty')
  }

  // const getPublicUrl = async (ref: StorageReference): Promise<string> => {
  //   return await getDownloadURL(ref)
  // }

  const downloadDir = async (dir: string): Promise<string[]> => {
    const files = await FirebaseStorage.listFiles({
      path: dir
    })

    const downloadPromise = files.items.map(async (file) => {
      const url = await FirebaseStorage.getDownloadUrl(file)
      return url.downloadUrl
    })
    const fileUrls = await Promise.all(downloadPromise)
    return fileUrls
  }

  const downloadFolderFromStorage = async (
    folderPath: string
  ): Promise<Blob> => {
    // Ottenere tutti i file nella cartella
    const files = await listAll(ref(getFirebaseStorage(), folderPath))

    // Iterare attraverso i file e scaricarli uno per uno
    const downloadPromises = files.items.map(async (fileRef) => {
      // Ottenere l'URL di download del file
      // const downloadURL = await getDownloadURL(
      //   ref(getFirebaseStorage(), fileRef.fullPath)
      // )
      // console.log('downloadURL', downloadURL)
      // Scaricare il file utilizzando l'URL
      const response = await getBlob(fileRef)
      console.log('Response fetch', response)
      return response

      // Esempio: Salvare il file nel file system dell'utente utilizzando la libreria FileSaver.js
      // Puoi utilizzare altre librerie o logiche per salvare i file a seconda delle tue esigenze
      // saveAs(blob, fileRef.name);
    })
    const blobs = await Promise.all(downloadPromises)
    const zip = new JSZip()
    blobs.forEach((blob, index) => {
      zip.file(files.items[index].name, blob)
    })
    const toSave = await zip.generateAsync({ type: 'blob' })

    return toSave
  }

  const getBlobImage = async (
    imagePath: string,
    bucket: 'default' | 'invoice' = 'default'
  ): Promise<Blob> => {
    const currentBucket = getFirebaseStorage()

    const blob = await getBlob(ref(currentBucket, imagePath))
    return blob
  }

  return {
    uploadByDataUrl,
    downloadFolderFromStorage,
    getBlobImage,
    downloadDir
  }
}
