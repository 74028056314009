import WalletBusinessSection from '#components/pages/wallets/WalletBusinessSection'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type UserCategory } from '#tackpay-sdk'

interface Props {
  category: UserCategory
}
export default function WalletBusiness({ category }: Props): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  return (
    <WalletBusinessSection
      category={category}
      jars={company?.jars}
      isLoading={isLoading}
      error={error}
    />
  )
}
