import AppLayout from '#components/layouts/app/AppLayout'
import HomeTitle from '#components/pages/home/HomeTitle'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import isCollaborator from '#utils/isCollaborator'
import { useParams } from 'react-router'
import HomeBusiness from './HomeBusiness'
import HomeCollaborator from './HomeCollaborator'
import HomeTipped from './HomeTipped'
import isDesktop from '#utils/isDesktop'
import DashboardDesktop from '#components/desktop/DashboardDesktop'
// import { ResourceProfileMonitoring } from '#components/common/ProfileMonitoring/ResourceProfileMonitoring'
// import { presetProfileMonitoring } from '#components/common/ProfileMonitoring/ResourceProfileMonitoring.mocks'

export default function Home(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const HomeContent = (): JSX.Element => {
    if (isBusiness(category) && !isCollaborator(category)) {
      return <HomeBusiness category={category} />
    } else if (isCollaborator(category)) {
      return <HomeCollaborator />
    }
    return <HomeTipped />
  }

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true,
        showNotificationsButton: true
      }}
      userProps={{
        params: {
          include: isBusiness(category)
            ? ['person', 'subscriber']
            : [
                'person',
                'tackpay_id',
                'subscriber',
                'members.jar.tackpay_ids',
                'members.jar.company',
                'stripe_gateway_account'
              ]
        }
      }}
      companyProps={{
        fetch: isBusiness(category),
        params: {
          include: [
            'tackpay_id',
            'balance',
            'jars.balance',
            'jars.tackpay_ids',
            'jars.members.user.person'
          ]
        }
      }}
      collaboratorProps={{
        fetch: isCollaborator(category),
        params: {
          include: ['company.tackpay_id,jars.balance,jars.tackpay_ids']
        }
      }}
    >
      {!isDesktop() ? (
        <>
          <HomeTitle />
          <HomeContent />
        </>
      ) : (
        <DashboardDesktop />
      )}
      {/* <ResourceProfileMonitoring resource={presetProfileMonitoring.tipped} /> */}
    </AppLayout>
  )
}
