import axios from 'axios'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

interface PropsCountriesInfo {
  countryTips: string[]
  dataLatLong: number[]
  setLatLong: (arg: any) => void
}

export function countriesInfo({
  countryTips,
  dataLatLong,
  setLatLong
}: PropsCountriesInfo): void {
  countryTips.map((countrie, i) => {
    axios
      .get(`https://restcountries.com/v3.1/name/${countrie}`)
      .then((data) => {
        dataLatLong[i] = data.data[0].latlng
      })
      .catch((err) => {
        console.log(err)
      })
    setLatLong(dataLatLong)

    return true
  })
}

interface PropsCoordMap {
  countryTips: string[]
  selectCountry: {
    type: 'FeatureCollection'
    features: any
  }
  setDataMap: (arg: any) => void
}

export function handleDownload({
  countryTips,
  selectCountry,
  setDataMap
}: PropsCoordMap): void {
  const storage = getStorage()
  const jsonFileRef = ref(
    storage,
    'gs://test-tackpay-13bf9.appspot.com/countries.json'
  )
  getDownloadURL(jsonFileRef)
    .then((url) => {
      fetch(url)
        .then(async (response) => await response.json())
        .then((data) => {
          countryTips.map((pais, i) => {
            const dataGeojson = data.features.find(
              (name: { properties: { ADMIN: string } }) =>
                name.properties.ADMIN === pais
            )

            return (selectCountry.features[i] = dataGeojson)
          })
          setDataMap(selectCountry)
        })
        .catch((error) => {
          console.error('Error al descargar el archivo JSON:', error)
        })
    })
    .catch((error: any) => {
      console.error('Error al obtener la URL de descarga:', error)
    })
}
