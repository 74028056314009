import CardIconLabel from '#components/common/CardIconLabel'

interface Props {
  /**
   * The icon to be used
   */
  icon: string
  /**
   * The label to be used
   */
  label: string
  /**
   * The space between the icon and the label
   * @default 'ml-20'
   */
  ml?: string
  /**
   * The onClick event handler
   */
  onClick: () => void
}
export default function CardMaterial({
  icon,
  label,
  ml,
  onClick
}: Props): JSX.Element {
  return <CardIconLabel icon={icon} label={label} onClick={onClick} ml={ml} />
}
