import { EUROPEAN_COUNTRY_CODES } from '#constants/country'
import useAuthState from '#hooks/useAuthState'
import {
  type CompanyCreate,
  type AddressCreate,
  type PersonCreate,
  type UserCreate,
  type JarCreate,
  type StripeGatewayAccountCreate,
  type User,
  type Person,
  type Address,
  type StripeGatewayAccount,
  type Company,
  type Jar
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useIonRouter } from '@ionic/react'
import i18n from 'i18n/config'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  destroyPreferences,
  getPreferences,
  savePreferences
} from 'storage/preferences'

interface RegistrationContextValue {
  setUserData: (data: Partial<UserCreate>) => void
  userData?: Partial<UserCreate>
  personData?: Partial<PersonCreate>
  setPersonData: (data: Partial<PersonCreate>) => void
  addressData?: Partial<AddressCreate>
  setAddressData: (data: Partial<AddressCreate>) => void
  companyData?: Partial<CompanyCreate>
  setCompanyData: (data: Partial<CompanyCreate>) => void
  jarData?: Partial<JarCreate>
  setJarData: (data: Partial<JarCreate>) => void
  stripeGatewayData?: Partial<StripeGatewayAccountCreate>
  setStripeGatewayData: (data: Partial<StripeGatewayAccountCreate>) => void
  handleRegistration: () => Promise<User>
  clearStorage: () => void
  refresh: () => Promise<void>
}

const RegistrationContext = createContext<RegistrationContextValue>({
  userData: {},
  personData: {},
  addressData: {},
  companyData: {},
  jarData: {},
  stripeGatewayData: {},
  setUserData: () => {},
  setPersonData: () => {},
  setAddressData: () => {},
  setCompanyData: () => {},
  setJarData: () => {},
  setStripeGatewayData: () => {},
  handleRegistration: async () => await Promise.resolve({} as unknown as User),
  clearStorage: () => {},
  refresh: async () => {
    await Promise.resolve()
  }
})

export const useRegistrationContext = (): RegistrationContextValue => {
  const context = useContext(RegistrationContext)

  if (context == null)
    throw new Error(
      'useRegistrationContext must be used within a RegistrationContextProvider'
    )

  return context
}

export default function RegistrationContainer({
  children
}: {
  children: React.ReactNode
}): JSX.Element {
  const { routeInfo } = useIonRouter()

  const { loginWithEmail } = useAuthState()

  const [userData, setUserData] = useState<Partial<UserCreate>>({})

  const [personData, setPersonData] = useState<Partial<PersonCreate>>({})

  const [addressData, setAddressData] = useState<Partial<AddressCreate>>({})

  const [companyData, setCompanyData] = useState<Partial<CompanyCreate>>({})

  const [jarData, setJarData] = useState<Partial<JarCreate>>({})

  const [stripeGatewayData, setStripeGatewayData] = useState<
    Partial<StripeGatewayAccountCreate>
  >({})

  const fetchFromStorage = async (): Promise<void> => {
    const userDataStorage =
      await getPreferences<Partial<UserCreate>>('userCreate')

    const personDataStorage =
      await getPreferences<Partial<PersonCreate>>('personCreate')

    const addressDataStorage =
      await getPreferences<Partial<AddressCreate>>('addressCreate')

    const companyDataStorage =
      await getPreferences<Partial<CompanyCreate>>('companyCreate')

    const jarDataStorage = await getPreferences<Partial<JarCreate>>('jarCreate')

    const stripeGatewayDataStorage = await getPreferences<
      Partial<StripeGatewayAccountCreate>
    >('stripeGatewayCreate')

    if (userDataStorage != null) {
      setUserData(userDataStorage)
    }

    if (personDataStorage != null) {
      setPersonData(personDataStorage)
    }

    if (addressDataStorage != null) {
      setAddressData(addressDataStorage)
    }

    if (companyDataStorage != null) {
      setCompanyData(companyDataStorage)
    }

    if (jarDataStorage != null) {
      setJarData(jarDataStorage)
    }

    if (stripeGatewayDataStorage != null) {
      setStripeGatewayData(stripeGatewayDataStorage)
    }
  }

  useEffect(() => {
    void fetchFromStorage()
  }, [routeInfo.pathname])

  const handleSetUserData = (data: Partial<UserCreate>): void => {
    const userDate = { ...userData, ...data }
    void savePreferences('userCreate', userDate)
    setUserData(userDate)
  }

  const handleSetPersonData = (data: Partial<PersonCreate>): void => {
    const personDate = { ...personData, ...data }
    void savePreferences('personCreate', personDate)
    setPersonData(personDate)
  }

  const handleSetAddressData = (data: Partial<AddressCreate>): void => {
    const addressDate = { ...addressData, ...data }
    void savePreferences('addressCreate', addressDate)
    setAddressData(addressDate)
  }

  const handleSetCompanyData = (data: Partial<CompanyCreate>): void => {
    const companyDate = { ...companyData, ...data }
    void savePreferences('companyCreate', companyDate)
    setCompanyData(companyDate)
  }

  const handleSetJarData = (data: Partial<JarCreate>): void => {
    const jarDate = { ...jarData, ...data }
    void savePreferences('jarCreate', jarDate)
    setJarData(jarDate)
  }

  const handleSetStripeGatewayData = (
    data: Partial<StripeGatewayAccountCreate>
  ): void => {
    const stripeGatewayDate = { ...stripeGatewayData, ...data }
    void savePreferences('stripeGatewayCreate', stripeGatewayDate)
    setStripeGatewayData(stripeGatewayDate)
  }

  const handleCreateUser = async (data: UserCreate): Promise<User> => {
    let user = await getPreferences<User>('user')

    if (user == null) {
      user = await sdk.users.create(data)
    }

    await loginWithEmail(user.email, data.password)

    await savePreferences('user', user)

    return user
  }

  const handleCreatePerson = async (data: PersonCreate): Promise<Person> => {
    let person = await getPreferences<Person>('person')

    if (person == null) {
      person = await sdk.persons.create(data)
    }

    const updatable = await isPersonUpdatable(data)

    if (updatable) {
      person = await sdk.persons.update({
        id: person.id,
        birthdate: data.birthdate ?? person.birthdate,
        first_name: data.first_name ?? person.first_name,
        last_name: data.last_name ?? person.last_name
      })
    }

    await savePreferences('person', person)

    return person
  }

  const isPersonUpdatable = async (data: PersonCreate): Promise<boolean> => {
    const person = await getPreferences<Person>('person')
    if (person == null) {
      return false
    }

    if (person.first_name !== data.first_name) return true
    if (person?.last_name != null) {
      if (person?.last_name !== data?.last_name) return true
    }

    if (person?.birthdate != null) {
      if (person.birthdate?.day !== data?.birthdate?.day) return true
      if (person?.birthdate?.month !== data?.birthdate?.month) return true
      if (person?.birthdate?.year !== data?.birthdate?.year) return true
    }

    return false
  }

  const handleCreateAddress = async (data: AddressCreate): Promise<Address> => {
    let address = await getPreferences<Address>('address')

    if (address == null) {
      address = await sdk.addresses.create(data)
    }

    const updatable = await isAddressUpdatable(data)
    if (updatable) {
      address = await sdk.addresses.update({
        id: address.id,
        street: data.street,
        street_number: data.street_number,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code
      })
    }

    await savePreferences('address', address)

    return address
  }

  const isAddressUpdatable = async (data: AddressCreate): Promise<boolean> => {
    const address = await getPreferences<Address>('address')
    if (address == null) {
      return false
    }

    if (address?.street !== data?.street) return true
    if (address?.city !== data?.city) return true
    if (address?.state !== data?.state) return true
    if (address?.postal_code !== data?.postal_code) return true
    if (address?.street_number !== data?.street_number) return true

    return false
  }

  const handleCreateStripeGatewayAccount = async (
    data: StripeGatewayAccountCreate
  ): Promise<StripeGatewayAccount> => {
    let stripeGatewayAccount = await getPreferences<StripeGatewayAccount>(
      'stripeGatewayAccount'
    )

    if (stripeGatewayAccount == null) {
      stripeGatewayAccount = await sdk.stripe_gateway_accounts.create(data)
    }

    await savePreferences('stripeGatewayAccount', stripeGatewayAccount)

    return stripeGatewayAccount
  }

  const handleCreateCompany = async (data: CompanyCreate): Promise<Company> => {
    let company = await getPreferences<Company>('company')

    if (company == null) {
      company = await sdk.companies.create(data)
    }

    await savePreferences('company', company)

    return company
  }

  const handleJarCreate = async (data: JarCreate): Promise<Jar> => {
    let jar = await getPreferences<Jar>('jar')

    if (jar == null) {
      jar = await sdk.jars.create(data)
    }

    await savePreferences('jar', jar)

    return jar
  }

  const handleRegistration = async (): Promise<User> => {
    await refresh()

    const userCreate: UserCreate = {
      ...userData,
      lang: i18n.language as any,
      auth_provider: 'password'
    } as unknown as UserCreate
    const user = await handleCreateUser(userCreate)
    const personCreate: PersonCreate = {
      ...personData,
      user: {
        id: user.id,
        type: 'users'
      }
    } as unknown as PersonCreate

    const person = await handleCreatePerson(personCreate)

    const addressCreate: AddressCreate = {
      ...addressData,
      person: {
        id: person.id,
        type: 'persons'
      }
    } as unknown as AddressCreate

    const address = await handleCreateAddress(addressCreate)

    if (user.category === 'tipped') {
      const stripeGatewayCreate: StripeGatewayAccountCreate = {
        ...stripeGatewayData,
        reference_category: 'user',
        profile_type: 'individual',
        user: {
          id: user.id,
          type: 'users'
        }
      }

      await handleCreateStripeGatewayAccount(stripeGatewayCreate)

      return user
    }

    if (['business', 'manager'].includes(user.category)) {
      const companyCreate: CompanyCreate = {
        ...companyData,
        user: {
          id: user.id,
          type: 'users'
        }
      } as unknown as CompanyCreate

      const company = await handleCreateCompany(companyCreate)

      let jarCreate: JarCreate = {
        ...jarData,
        company: {
          id: company.id,
          type: 'companies'
        }
      } as unknown as JarCreate

      if (
        !EUROPEAN_COUNTRY_CODES.includes(
          address.country_code.toUpperCase() as any
        )
      ) {
        jarCreate = {
          ...jarCreate,
          tipping_rule: 'individual'
        }
      }

      const jar = await handleJarCreate(jarCreate)

      if (user.category === 'business') {
        const stripeGatewayCreate: StripeGatewayAccountCreate = {
          ...stripeGatewayData,
          profile_type: 'individual',
          reference_category: 'jar',
          jar: {
            id: jar.id,
            type: 'jars'
          }
        }

        await handleCreateStripeGatewayAccount(stripeGatewayCreate)

        return user
      }
    }

    return user
  }

  const refresh = async (): Promise<void> => {
    await fetchFromStorage()
  }

  const clearStorage = (): void => {
    destroyPreferences('userCreate')
    destroyPreferences('personCreate')
    destroyPreferences('addressCreate')
    destroyPreferences('companyCreate')
    destroyPreferences('jarCreate')
    destroyPreferences('stripeGatewayCreate')

    destroyPreferences('user')
    destroyPreferences('person')
    destroyPreferences('address')
    destroyPreferences('company')
    destroyPreferences('jar')
    destroyPreferences('stripeGatewayAccount')
  }

  return (
    <RegistrationContext.Provider
      value={{
        userData,
        setUserData: handleSetUserData,
        personData,
        setPersonData: handleSetPersonData,
        addressData,
        setAddressData: handleSetAddressData,
        companyData,
        setCompanyData: handleSetCompanyData,
        jarData,
        setJarData: handleSetJarData,
        stripeGatewayData,
        setStripeGatewayData: handleSetStripeGatewayData,
        handleRegistration,
        clearStorage,
        refresh
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}
