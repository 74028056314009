import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type User } from './users'
import { type Member } from './members'
import { type Company } from './companies'
import { type Jar } from './jars'

type StatisticAmountType = 'statistic_amounts'
type StatisticAmountRel = ResourceRel & { type: StatisticAmountType }

type StatisticAmountCategory = 'tipped' | 'jar' | 'member'

interface StatisticAmountOccurence {
  /**
   * The amount of payments
   */
  amount: number
  /**
   * The occurence of the amount
   */
  occurence: number
  /**
   * The category of the amount
   */
  category: StatisticAmountCategory
}

interface StatisticAmount extends Resource {
  readonly type: StatisticAmountType
  /**
   * The amounts that are related to this statistic.
   */
  amounts: StatisticAmountOccurence[]
  /**
   * The most popular amount that is related to this statistic.
   */
  most_popular_occurence: number
  /**
   * The category of the most popular amount that is related to this statistic.
   */
  most_popular_category: StatisticAmountCategory

  user?: User

  member?: Member

  company?: Company

  jar?: Jar | null
}

class StatisticAmounts extends ApiResource<StatisticAmount> {
  static TYPE: StatisticAmountType = 'statistic_amounts'

  isStaticFeedback(resource: any): resource is StatisticAmount {
    return resource.type != null && resource.type === StatisticAmounts.TYPE
  }

  relationship(id: string | ResourceId | null): StatisticAmountRel {
    return id === null || typeof id === 'string'
      ? { id, type: StatisticAmounts.TYPE }
      : { id: id.id, type: StatisticAmounts.TYPE }
  }

  type(): StatisticAmountType {
    return StatisticAmounts.TYPE
  }
}

export type { StatisticAmount, StatisticAmountRel, StatisticAmountType }
export default StatisticAmounts
