import Text from '#components/ui/Text'
import { IonCol, IonImg, IonRow } from '@ionic/react'

interface Props {
  name: string | undefined
}

export default function Underlined({ name }: Props): JSX.Element {
  return (
    <IonRow className='w-full ion-justify-content-center ion-no-padding ion-no-margin mt-3'>
      <IonCol className='ion-no-padding ion-no-margin'>
        <IonRow className=' ion-justify-content-center ion-no-padding ion-no-margin '>
          <Text
            className='text-black'
            fontWeight='font-bold'
            size='text-xl'
            translationOptions={{
              translate: 'no'
            }}
          >
            {name}
          </Text>
        </IonRow>
        <IonRow className='ion-justify-content-center ion-no-padding ion-no-margin'>
          <IonImg src='/assets/img/sottolineato-1.svg' />
        </IonRow>
      </IonCol>
    </IonRow>
  )
}
