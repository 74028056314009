import {
  type QueryParamsRetrieve,
  type Payout,
  type PayoutCreate
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

type CreatePayoutFunction = UseMutationResult<
  Payout,
  unknown,
  PayoutCreate,
  unknown
>

interface PayoutContextValue {
  payout?: Payout | null
  isLoading: boolean
  error?: any
  createPayout?: CreatePayoutFunction
}

const payoutInitialState: PayoutContextValue = {
  payout: null,
  isLoading: false,
  error: null
}

const PayoutContext = createContext<PayoutContextValue>(payoutInitialState)

export const usePayoutContext = (): PayoutContextValue => {
  const context = useContext(PayoutContext)
  if (context == null) {
    throw new Error('usePayoutContext must be used within a PayoutProvider')
  }
  return context
}

interface PayoutContainerProps {
  children: React.ReactNode
  payoutId?: string
  /** @default true */
  fetch?: boolean
  params?: QueryParamsRetrieve
}

const handleCreatePayout = async (
  payoutCreate: PayoutCreate
): Promise<Payout> => {
  return await sdk.payouts.create(payoutCreate)
}

export default function PayoutContainer(
  props: PayoutContainerProps
): JSX.Element {
  const { children, fetch = true, params, payoutId } = props

  const queryClient = useQueryClient()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: payout,
    isLoading,
    error
  } = useQuery<Payout>({
    queryKey: ['payout', payoutId, params],
    queryFn: async () => {
      return await sdk.payouts.retrieve(payoutId ?? '', params)
    },
    enabled: !initialFetchComplete && fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  useEffect(() => {
    if (fetch) {
      void queryClient.invalidateQueries({
        queryKey: ['payout', payoutId, params]
      })
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const createPayout = useMutation({
    mutationFn: handleCreatePayout,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['payouts'] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
      void queryClient.invalidateQueries({ queryKey: ['payouts-deduction'] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['payouts'] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
      void queryClient.invalidateQueries({ queryKey: ['payouts-deduction'] })
    }
  })

  const payoutContextValue: PayoutContextValue = {
    payout,
    isLoading,
    error,
    createPayout
  }

  return (
    <PayoutContext.Provider value={payoutContextValue}>
      {children}
    </PayoutContext.Provider>
  )
}
