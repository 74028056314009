import { useState } from 'react'
import CollaboratorInviteButton from './CollaboratorInviteButton'
import CollaboratorInviteInput from './CollaboratorInviteInput'
import { type CollaboratorRole, type Jar } from '#tackpay-sdk'
import textUtil from '#utils/textUtil'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import useToast from '#hooks/useToast'
import useLoading from '#hooks/useLoading'
import { useCompanyContext } from '#contexts/CompanyContext'
import getErrorMessage from '#utils/getErrorMessage'

export default function CollaboratorInviteSection(): JSX.Element {
  const [email, setEmail] = useState('')

  const [role, setRole] = useState<CollaboratorRole>('admin')

  const [currentTeams, setCurrentTeams] = useState<Jar[]>([])

  const { createCollaborator } = useCollaboratorContext()

  const { company } = useCompanyContext()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading(
    'collaborator.invite.loading'
  )

  const handleDisable = (): boolean => {
    if (role === 'admin' && textUtil.isEmail(email)) return false
    if (
      role === 'manager' &&
      textUtil.isEmail(email) &&
      currentTeams.length > 0
    )
      return false
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (createCollaborator != null) {
      if (!textUtil.isEmail(email)) {
        showToast(undefined, 'collaborator.invite.emailRequired')
      } else if (textUtil.isEmpty(role)) {
        showToast(undefined, 'collaborator.invite.roleRequired')
      } else if (role === 'manager' && currentTeams.length === 0) {
        showToast(undefined, 'collaborator.invite.teamRequired')
      } else {
        startLoading()
        createCollaborator.mutate(
          {
            company: {
              id: company?.id ?? '',
              type: 'companies'
            },
            email,
            role,
            jar_ids: currentTeams.map((jar) => jar.id)
          },
          {
            onSuccess: () => {
              stopLoading()
              setEmail('')
              setRole('admin')
              setCurrentTeams([])
              showSuccess(undefined, 'collaborator.invite.success')
            },
            onError: (error) => {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <CollaboratorInviteInput
        setCurrentTeams={setCurrentTeams}
        currentTeams={currentTeams}
        email={email}
        setEmail={setEmail}
        setRole={setRole}
        role={role}
      />
      <CollaboratorInviteButton disabled={handleDisable()} />
    </form>
  )
}
