import {
  ApiResource,
  type ResourceCreate,
  type Resource,
  type ResourceId,
  type ResourceRel,
  type ResourcesConfig,
  type ResourceUpdate,
  type ResourcesInitConfig
} from 'sdk/resource'
import { type JarRel, type Jar } from './jars'
import { type QueryParamsRetrieve } from 'sdk/query'

type DeductionSettingType = 'deduction_settings'
type DeductionSettingRel = ResourceRel & { type: DeductionSettingType }

interface DeductionSetting extends Resource {
  readonly type: DeductionSettingType

  active: boolean

  jar?: Jar

  percentage: number
}

interface DeductionSettingCreate extends ResourceCreate {
  active: boolean
  jar: JarRel
  percentage: number
}

interface DeductionSettingUpdate extends ResourceUpdate {
  percentage: number
  active?: boolean
}

class DeductionSettings extends ApiResource<DeductionSetting> {
  static readonly TYPE: DeductionSettingType = 'deduction_settings' as const

  async create(
    resource: DeductionSettingCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<DeductionSetting> {
    return await this.resources.create<
      DeductionSettingCreate,
      DeductionSetting
    >({ ...resource, type: DeductionSettings.TYPE }, params, options)
  }

  async update(
    resource: DeductionSettingUpdate,
    params?: QueryParamsRetrieve | undefined,
    options?: Partial<ResourcesInitConfig> | undefined
  ): Promise<DeductionSetting> {
    return await this.resources.update<
      DeductionSettingUpdate,
      DeductionSetting
    >({ ...resource, type: DeductionSettings.TYPE }, params, options)
  }

  isDeduction(resource: any): resource is DeductionSetting {
    return resource.type != null && resource.type === DeductionSettings.TYPE
  }

  relationship(id: string | ResourceId | null): DeductionSettingRel {
    return id === null || typeof id === 'string'
      ? { id, type: DeductionSettings.TYPE }
      : { id: id.id, type: DeductionSettings.TYPE }
  }

  type(): DeductionSettingType {
    return DeductionSettings.TYPE
  }
}

export type {
  DeductionSetting,
  DeductionSettingCreate,
  DeductionSettingType,
  DeductionSettingRel,
  DeductionSettingUpdate
}
export default DeductionSettings
