import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import RegistartionAddress from '#components/pages/registrations/RegistrationAddress'
import ProgressBar from '#components/ui/ProgessBar'

export default function RegistrationAddressStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: `/registrations/${category}/persons`
        }
      }}
    >
      <ProgressBar
        text='registration.chips.address.title'
        value={0.5}
        testId='progress-registration.chips.address.title'
      />
      <RegistartionAddress category={category} />
    </AuthLayout>
  )
}
