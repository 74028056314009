import isDesktop from './isDesktop'

export const adapterModal = isDesktop() ? 'alert' : 'action-sheet'

const optionsDesktop = {
  children: 'Select problem'
}

const optionsMobil = {
  children: 'Select problem',
  position: 'stacked'
}

export const adapterOptionSelect = isDesktop() ? optionsDesktop : optionsMobil
