import Button from '#components/ui/Button'
import Label from '#components/ui/Label'
import { getSymbolCurrency } from '#constants/country'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import CheckoutFeeAndTerms from './CheckoutFeeAndTerms'
import { useEffect, useState } from 'react'
import useToast from '#hooks/useToast'
import useLoading from '#hooks/useLoading'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { getPreferences } from 'storage/preferences'
import { type FeeSimulator } from '#tackpay-sdk'

interface Props {
  tackpayIdValue: string
}
export default function CheckoutCardForm({
  tackpayIdValue
}: Props): JSX.Element {
  const stripe = useStripe()

  const elements = useElements()

  const { checkout } = useCheckoutContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const { startLoading: handleStartLoading, stopLoading: handleStopLoading } =
    useLoading('checkout.payment.processing')

  const [payFee, setPayFee] = useState(false)

  const [terms, setTerms] = useState(false)

  const [forceRedirect, setForceRedirect] = useState(false)

  const [amountWithFee, setAmountWithFee] = useState<number | null>(null)

  const classInput =
    'mt-1 focus:ring-0 bg-white block w-full shadow-sm border sm:text-sm border-gray-300 focus:border-gray-500 focus:ring-gray-500 rounded-md p-3 text-xl'

  useEffect(() => {
    if (forceRedirect) {
      push(`/checkout/${tackpayIdValue}/${checkout?.id ?? ''}/reviews`)
    }

    return () => {
      setForceRedirect(false)
    }
  }, [forceRedirect])

  useEffect(() => {
    const fetchStorageFee = async (): Promise<void> => {
      try {
        const fee = await getPreferences<FeeSimulator>('feeSimulator')
        if (fee != null) {
          if (fee.covered) {
            setAmountWithFee(
              fee.totalFeeAmount + (checkout?.payment?.gross_amount_cents ?? 0)
            )
          } else {
            setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
          }
        } else {
          setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
        }
      } catch (err) {
        console.error('Failed to fetch fee simulator preferences:', err)
        setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
      }
    }

    const intervalId = setInterval(() => {
      void fetchStorageFee()
    }, 100)

    void fetchStorageFee() // Fetch iniziale

    return () => {
      clearInterval(intervalId)
    }
  }, [checkout])

  const handlePaymentMethod = async (
    paymentMethodReference: string
  ): Promise<void> => {
    await sdk.payment_methods.create({
      payment_method_reference: paymentMethodReference,
      method_type: 'card',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const handleConfirm = async (): Promise<void> => {
    try {
      if (stripe == null || elements == null) return

      const cardElement = elements.getElement(CardNumberElement)

      if (cardElement == null) {
        throw new Error('Card element is null')
      }

      const resultMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement
      })
      if (resultMethod?.error != null)
        throw new Error(resultMethod.error.message)

      await handlePaymentMethod(resultMethod?.paymentMethod?.id ?? '')
      await sdk.fees.create({
        covered: payFee,
        fee_category: 'payment',
        payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
      })

      const resultConfirm = await stripe.confirmCardPayment(
        checkout?.payment?.client_secret_reference ?? '',
        {
          payment_method: resultMethod?.paymentMethod?.id ?? ''
        }
      )

      if (resultConfirm?.error != null)
        throw new Error(resultConfirm.error.message)

      await sdk.payments.update({
        id: checkout?.payment?.id ?? '',
        reference: resultConfirm?.paymentIntent?.id ?? ''
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (!terms) {
      showToast(undefined, 'checkout.payment.terms')
      return
    }

    if (stripe == null && elements == null) {
      showToast(undefined, 'checkout.payment.error')
      return
    }

    handleStartLoading()

    const cardElement = elements?.getElement(CardNumberElement)

    if (cardElement == null) {
      handleStopLoading()
      showToast(undefined, 'checkout.payment.error')
    } else {
      handleConfirm()
        .then(() => {
          handleStopLoading()
          setForceRedirect(true)
          push(`/checkout/${tackpayIdValue}/${checkout?.id ?? ''}/reviews`)
        })
        .catch((error) => {
          handleStopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const isSubmittable = (): boolean => {
    return elements?.getElement(CardNumberElement) != null && terms
  }

  return (
    <form className='pt-10 space-y-6' onSubmit={handleSubmit}>
      <IonRow className=''>
        <div>
          <Label capitalize={false}>card.number</Label>
        </div>
        <CardNumberElement
          className={classInput}
          options={{ showIcon: true }}
        />
      </IonRow>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <IonCol size='6'>
          <IonRow className='ion-no-margin ion-no-padding'>
            <Label capitalize={false}>card.expiration</Label>
            <CardExpiryElement className={classInput} />
          </IonRow>
        </IonCol>
        <IonCol size='6'>
          <IonRow className='ion-no-margin ion-no-padding'>
            <Label capitalize={false}>card.cvc</Label>
            <CardCvcElement className={classInput} />
          </IonRow>
        </IonCol>
      </IonRow>
      <CheckoutFeeAndTerms
        payFee={payFee}
        terms={terms}
        setPayFee={setPayFee}
        setTerms={setTerms}
      />
      <Button
        type='submit'
        disabled={!isSubmittable()}
        className='w-full mt-12 ion-no-padding'
        translationOptions={{
          translate: 'yes',
          data: {
            amount:
              amountWithFee != null
                ? amountWithFee / 100
                : checkout?.payment?.amount_float ?? 0,
            currency: getSymbolCurrency(checkout?.payment?.currency ?? 'eur')
          }
        }}
      >
        confirmAmount
      </Button>
    </form>
  )
}
