import ButtonStep from '#components/common/ButtonStep'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Input, { type InputOnChange } from '#components/ui/Input'
import { InputDate } from '#components/ui/InputDate'
import { type MaybeDate } from '#components/ui/InputDate/InputDateComponent'
import ProgressBar from '#components/ui/ProgessBar'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import useToast from '#hooks/useToast'
import { type Gender, type UserCategory } from '#tackpay-sdk'
import birthdateValidate from '#utils/birthdateValidator'
import isDesktop from '#utils/isDesktop'
import { isGender } from '#utils/isGenger'
import textUtil from '#utils/textUtil'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface Props {
  category: UserCategory
}

export default function RegistrationPersonalData({
  category
}: Props): JSX.Element {
  const { personData, setPersonData, setCompanyData, companyData } =
    useRegistrationContext()

  const { t } = useTranslation()

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  const majorAgeDate = new Date()
  majorAgeDate.setFullYear(majorAgeDate.getFullYear() - 18)

  const handleInputChanged: InputOnChange = (event) => {
    const { name, value } = event.target
    if (name === 'firstName' && typeof value === 'string')
      setPersonData({ first_name: value })
    else if (name === 'lastName' && typeof value === 'string')
      setPersonData({ last_name: value })
    else if (name === 'companyName' && typeof value === 'string')
      setCompanyData({ name: value })
  }

  const handleGender: SelectOnChangeEvent = (event) => {
    const gender = event.target.value as Gender
    setPersonData({ gender })
  }

  const validateStep = (): boolean => {
    const invalid = false
    if (textUtil.isEmpty(personData?.first_name)) {
      showToast(undefined, 'registration.firstNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personData?.last_name)) {
      showToast(undefined, 'registration.lastNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personData?.gender)) {
      showToast(undefined, 'registration.genderRequired')
      return invalid
    }

    if (typeof personData?.birthdate === 'undefined') {
      showToast(undefined, 'registration.birthdateRequired')
      return invalid
    }

    if (!isGender(personData.gender)) {
      showToast(undefined, 'registration.genderInvalid')
      return invalid
    }

    if (category === 'business' && textUtil.isEmpty(companyData?.name)) {
      showToast(undefined, 'registration.companyNameRequired')
      return invalid
    }

    if (typeof personData.birthdate !== 'undefined') {
      const validateDay = birthdateValidate.isValidDay(personData.birthdate.day)
      if (!validateDay) {
        showToast(undefined, 'registration.birthdateInvalidDay')
        return invalid
      }

      const validateMonth = birthdateValidate.isValidMonth(
        personData.birthdate.month
      )

      if (!validateMonth) {
        showToast(undefined, 'registration.birthdateInvalidMonth')
        return invalid
      }

      const validateYear = birthdateValidate.isValidYear(
        personData.birthdate.year
      )

      if (!validateYear) {
        showToast(undefined, 'registration.birthdateInvalidYear')
        return invalid
      }

      const { day, month, year } = personData.birthdate

      const validateAge = birthdateValidate.isOfLegalAge(day, month, year)

      if (!validateAge) {
        showToast(undefined, 'registration.birthdateInvalidAge')
        return invalid
      }
    }

    return !invalid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const isValid = validateStep()
    if (isValid) {
      push(`/registrations/${category}/addresses`)
    }
  }

  const handlePrevious = (): void => {
    if (category === 'tipped') {
      push(`/registrations/${category}`, 'back')
    } else {
      push(`/registrations/${category}/types`, 'back')
    }
  }

  const handleDateChange = (date: MaybeDate): void => {
    const day = date?.getDate()
    const month = date?.getMonth() ?? 0 + 1
    const year = date?.getFullYear()

    if (
      typeof day === 'number' &&
      typeof month === 'number' &&
      typeof year === 'number'
    ) {
      setPersonData({ birthdate: { day, month, year } })
    }
  }

  const getBirthDateDate = (): MaybeDate | undefined => {
    if (personData?.birthdate != null) {
      return new Date(
        personData.birthdate.year,
        personData.birthdate.month - 1,
        personData.birthdate.day
      )
    }
  }

  return (
    <>
      <ProgressBar text='registration.chips.personalData.title' value={0.3} />
      <form onSubmit={handleSubmit} className='space-y-6'>
        <div>
          <Input
            name='firstName'
            type='text'
            value={personData?.first_name}
            onIonInput={handleInputChanged}
            labelOptions={{
              children: 'firstName',
              capitalize: false
            }}
            testId='firstName'
          />
        </div>
        <div>
          <Input
            name='lastName'
            type='text'
            value={personData?.last_name}
            onIonInput={handleInputChanged}
            labelOptions={{ children: 'lastName', capitalize: false }}
            testId='lastName'
          />
        </div>
        {category === 'business' && (
          <div>
            <Input
              name='companyName'
              type='text'
              value={companyData?.name}
              onIonInput={handleInputChanged}
              labelOptions={{
                children: 'companyName',
                capitalize: false
              }}
              testId='name-company'
            />
          </div>
        )}
        <IonRow className='ion-align-items-center ion-justify-content-between space-x-1 ion-no-padding ion-no-margin'>
          <IonCol className='ion-no-padding ion-no-margin'>
            <InputDate
              onChange={handleDateChange}
              capitalize={false}
              label='birthdate'
              value={getBirthDateDate()}
              maxDate={majorAgeDate}
              yearDropdownItemNumber={30}
            />
          </IonCol>
          <IonCol className='ion-no-margin ion-no-padding'>
            <BasicSelect
              options={{
                childrens: ['male', 'female']
              }}
              placeholder={t('gender') ?? ''}
              interface={isDesktop() ? 'popover' : 'action-sheet'}
              labelOptions={{
                show: true,
                options: {
                  children: 'gender'
                }
              }}
              onIonChange={handleGender}
              value={personData?.gender}
            />
          </IonCol>
        </IonRow>
        <ButtonStep handleBack={handlePrevious} nextType='submit' />
      </form>
    </>
  )
}
