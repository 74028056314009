import {
  type ResourceId,
  type Resource,
  type ResourceRel,
  ApiResource
} from 'sdk/resource'

import { type Member } from './members'
import { type User } from './users'
import { type Company } from './companies'
import { type Jar } from './jars'

type StatisticBalanceType = 'statistic_balances'
type StatisticBalanceRel = ResourceRel & { type: StatisticBalanceType }

type StatisticBalanceCategory = 'tipped' | 'jar' | 'member'

interface StatisticBalanceDate {
  date: string
  amount: number
  category: StatisticBalanceCategory
}

interface StatisticBalance extends Resource {
  readonly type: StatisticBalanceType
  /**
   * The balance of the account at the end of the day
   */
  balances: StatisticBalanceDate[]

  user?: User

  company?: Company

  jar?: Jar

  member?: Member
}

class StatisticBalances extends ApiResource<StatisticBalance> {
  static TYPE: StatisticBalanceType = 'statistic_balances'

  isStaticFeedback(resource: any): resource is StatisticBalance {
    return resource.type != null && resource.type === StatisticBalances.TYPE
  }

  relationship(id: string | ResourceId | null): StatisticBalanceRel {
    return id === null || typeof id === 'string'
      ? { id, type: StatisticBalances.TYPE }
      : { id: id.id, type: StatisticBalances.TYPE }
  }

  type(): StatisticBalanceType {
    return StatisticBalances.TYPE
  }
}

export type {
  StatisticBalance,
  StatisticBalanceType,
  StatisticBalanceRel,
  StatisticBalanceCategory,
  StatisticBalanceDate
}
export default StatisticBalances
