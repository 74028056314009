import AppLayout from '#components/layouts/app/AppLayout'
import TransferContainer from '#contexts/TransferContext'
import { useParams } from 'react-router'
import { type TeamTransferRoutesParams } from '../../../../TeamRoutes'
import TransferIdHandler from '#components/pages/payments/TransferIdHandler'

export default function TeamTransfer(): JSX.Element {
  const { category, jarId, transferId } = useParams<TeamTransferRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/payments`
        }
      }}
    >
      <TransferContainer
        transferId={transferId}
        params={{
          include: [
            'jar',
            'user.person',
            'member.user.person',
            'payment.payment_method',
            'payment.fee',
            'payment.checkout_session',
            'payment.feedbacks',
            'payment.comment',
            'payment.jar',
            'payment.user.person',
            'payment.member.user.person'
          ]
        }}
      >
        <TransferIdHandler />
      </TransferContainer>
    </AppLayout>
  )
}
