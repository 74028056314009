import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Person, type Address, ApiError } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { IonContent, IonRow } from '@ionic/react'

interface Props {
  type?: 'create' | 'update'
  show: boolean
  setShow: (show: boolean) => void
  percentage: number
  address?: Partial<Address>
  person?: Partial<Person>
}
export default function SaveModalDeduction({
  type = 'update',
  setShow,
  show,
  percentage = 0,
  address,
  person
}: Props): JSX.Element {
  const { jar, refetch } = useTeamContext()

  console.log('Address', address)

  console.log('Person', person)

  const { startLoading, stopLoading } = useLoading(
    `deduction.${type === 'create' ? 'creating' : 'updating'}`
  )

  const { showToast } = useToast('warning')

  const saveOperations = async (jarId: string): Promise<void> => {
    if (type === 'create') {
      await sdk.persons.update(person as unknown as Person)
      await sdk.addresses.update(address as unknown as Address)
      try {
        if (
          jar?.stripe_gateway_account == null &&
          jar?.company?.stripe_gateway_account != null
        ) {
          await sdk.stripe_gateway_accounts.create({
            profile_type: 'individual',
            reference_category: 'jar',
            jar: { id: jarId, type: 'jars' },
            is_association: true,
            stripe_gateway_account_id: jar.company.stripe_gateway_account.id
          })
        } else if (jar?.stripe_gateway_account == null) {
          await sdk.stripe_gateway_accounts.create({
            profile_type: 'individual',
            reference_category: 'jar',
            jar: { id: jarId, type: 'jars' }
          })
        }
      } catch (error) {
        if (ApiError.isApiError(error) && error.status !== 409) {
          throw error
        }
      }
      await sdk.deduction_settings.create({
        active: true,
        jar: { id: jarId, type: 'jars' },
        percentage
      })

      if (refetch != null) {
        await refetch()
      }
    } else {
      if (jar?.deduction_setting != null) {
        await sdk.deduction_settings.update({
          id: jar.deduction_setting.id,
          percentage,
          active: true
        })

        if (refetch != null) {
          await refetch()
        }
      } else {
        await sdk.deduction_settings.create({
          active: true,
          jar: { id: jarId, type: 'jars' },
          percentage
        })

        if (refetch != null) {
          await refetch()
        }
      }
    }
  }

  const handleSave = (): void => {
    if (jar != null) {
      if (
        type === 'create' &&
        jar.stripe_gateway_account == null &&
        jar?.company?.stripe_gateway_account == null
      ) {
        if (person?.last_name == null) {
          setShow(false)
          showToast(undefined, 'Last name is required')
          return
        }

        if (person?.id == null) {
          setShow(false)
          showToast(undefined, 'Person ID is required')
          return
        }

        if (person?.birthdate == null) {
          setShow(false)
          showToast(undefined, 'Birthdate is required')
          return
        }

        if (address?.country_code == null) {
          setShow(false)
          showToast(undefined, 'Country code is required')
          return
        }

        if (address?.country_name == null) {
          setShow(false)
          showToast(undefined, 'Country name is required')
          return
        }

        if (address?.city == null) {
          setShow(false)
          showToast(undefined, 'City is required')
          return
        }

        if (address?.street == null) {
          setShow(false)
          showToast(undefined, 'Street is required')
          return
        }

        if (address?.street_number == null) {
          setShow(false)
          showToast(undefined, 'Street number is required')
          return
        }

        if (address?.postal_code == null) {
          setShow(false)
          showToast(undefined, 'Postal code is required')
          return
        }
      }

      if (percentage > 50) {
        setShow(false)
        showToast(undefined, 'deduction.percentage_error')
        return
      }

      startLoading()

      saveOperations(jar.id)
        .then(() => {
          stopLoading()
          setShow(false)
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
          console.log('error', error)
        })
    }
  }
  return (
    <Modal isOpen={show} setShowModal={setShow} id='simple'>
      <IonContent className='ion-padding ion-text-center' scrollY={false}>
        <Text
          className='ion-text-center'
          color='gray-900'
          fontWeight='font-bold'
          size='text-3xl'
        >
          deduction.title
        </Text>
        <IonRow className='w-full block mt-6'>
          <Text translationOptions={{ data: { percentage }, translate: 'yes' }}>
            deduction.save_info
          </Text>
        </IonRow>
        <hr className='mt-5' />
        <IonRow className='mt-6'>
          <Button className='w-full' onClick={handleSave}>
            save
          </Button>
        </IonRow>
        <IonRow className='w-full block mt-6'>
          <Text
            className='underline cursor-pointer'
            onClick={() => {
              setShow(false)
            }}
          >
            back
          </Text>
        </IonRow>
      </IonContent>
    </Modal>
  )
}
