import { useState } from 'react'
import Label from '../../ui/Label'
import LoginForgotModal from './LoginForgotModal'

export default function LoginForgotLabel(): JSX.Element {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <LoginForgotModal isOpen={showModal} setIsOpen={setShowModal} />
      <Label
        onClick={() => {
          setShowModal(true)
        }}
        color='primary'
        className='block mb-1 text-xs font-medium '
      >
        forgotPassword
      </Label>
    </>
  )
}
