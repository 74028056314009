import Card from '#components/ui/Card'
// import { useCheckoutContext } from '#contexts/CheckoutContext'
import { IonImg, IonRow, useIonRouter } from '@ionic/react'
import CheckoutFeeAndTerms from './CheckoutFeeAndTerms'
import { useState } from 'react'
import CheckoutMethodInfo from './CheckoutMethodInfo'
import { useStripe } from '@stripe/react-stripe-js'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import getErrorMessage from '#utils/getErrorMessage'
import useLoading from '#hooks/useLoading'

export default function CheckoutSofortForm(): JSX.Element {
  const { checkout } = useCheckoutContext()

  const stripe = useStripe()

  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.processing'
  )

  const { showToast } = useToast('warning')

  const { push, routeInfo } = useIonRouter()

  const [payFee, setPayFee] = useState(false)

  const [terms, setTerms] = useState(false)

  const handleFee = async (): Promise<void> => {
    await sdk.fees.create({
      covered: payFee,
      fee_category: 'payment',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const handlePaymentMethod = async (): Promise<void> => {
    await sdk.payment_methods.create({
      method_type: 'sofort',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const handleSofortPayment = (): void => {
    if (stripe == null) {
      showToast(undefined, 'checkout.stripe.notLoaded')
    } else if (!terms) {
      showToast(undefined, 'checkout.terms.notAccepted')
    } else {
      startLoading()
      handlePaymentMethod()
        .then(async () => {
          try {
            await handleFee()

            const url = window.location.href + '/handle-confirm'

            await stripe.confirmSofortPayment(
              checkout?.payment?.client_secret_reference ?? '',
              {
                payment_method: { sofort: { country: 'DE' } },
                return_url: url
              }
            )

            stopLoading()
            push(routeInfo.pathname + '/handle-confirm')
          } catch (error) {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <form className='mt-10'>
      <IonRow className='ion-justify-content-center ion-align-items-center w-full ion-text-center'>
        <Card onClick={handleSofortPayment}>
          <IonImg src='/assets/img/sofort.svg' className='w-1/2 mx-auto' />
        </Card>
      </IonRow>
      <CheckoutFeeAndTerms
        payFee={payFee}
        terms={terms}
        setPayFee={setPayFee}
        setTerms={setTerms}
      />
      <CheckoutMethodInfo />
    </form>
  )
}
