import AppLayout from '#components/layouts/app/AppLayout'
import MemberDelete from '#components/pages/members/MemberDelete'
import MemberInfoSection from '#components/pages/members/info/MemberInfoSection'
import MemberContainer from '#contexts/MemberContext'
import { type MemberRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function MemberInfo(): JSX.Element {
  const { category, memberId, jarId } = useParams<MemberRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/members`
        },
        showCustomIcon: true,
        customIconOptions: {
          icon: (
            <MemberDelete
              memberId={memberId}
              jarId={jarId}
              category={category}
            />
          )
        }
      }}
    >
      <MemberContainer
        memberId={memberId}
        params={{
          include: [
            'user.person',
            'user.tackpay_id',
            'jar',
            'user.stripe_gateway_account'
          ]
        }}
        fetch
      >
        <MemberInfoSection category={category} />
      </MemberContainer>
    </AppLayout>
  )
}
