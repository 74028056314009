import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
// import Chip from '#components/ui/Chip'
import Text from '#components/ui/Text'
import { type Jar, type UserCategory } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  category: UserCategory
  jar: Jar
}
export default function CardJarDeduction({
  category,
  jar
}: Props): JSX.Element {
  return (
    <Card className='md:w-9/12 mx-auto'>
      <IonRow className='ion-justify-content-start ion-align-items-center'>
        <IonCol size='3'>
          <Avatar imgOptions={{ src: '/assets/img/defaultTeam.svg' }} />
        </IonCol>
        <IonCol size='7'>
          <Text
            color='gray-900'
            fontWeight='font-medium'
            translationOptions={{
              translate: 'no'
            }}
          >
            {jar.name}
          </Text>
        </IonCol>
        {/* <IonCol size='2'>
          <Chip color='gray-900' className='bg-green-200 font-medium'>
            5%
          </Chip>
        </IonCol> */}
      </IonRow>
      <IonRow className='ion-text-center'>
        <IonCol>
          <Text color='gray-900' fontWeight='font-medium'>
            deduction.balance_available
          </Text>
        </IonCol>
        <IonCol>
          <Text
            color='gray-900'
            className='ion-text-end'
            fontWeight='font-medium'
            translationOptions={{ translate: 'no' }}
          >
            {formattedAmount(
              jar?.balance_deduction?.available.amount_cents ?? 0,
              jar.currency
            )}
          </Text>
        </IonCol>
      </IonRow>
      <IonRow className='ion-text-center'>
        <IonCol>
          <Text color='gray-900' fontWeight='font-medium' size='text-base'>
            deduction.balance_pending
          </Text>
        </IonCol>
        <IonCol>
          <Text
            color='gray-900'
            className='ion-text-end'
            fontWeight='font-medium'
            size='text-base'
            translationOptions={{ translate: 'no' }}
          >
            {formattedAmount(
              jar?.balance_deduction?.pending.amount_cents ?? 0,
              jar.currency
            )}
          </Text>
        </IonCol>
      </IonRow>
      <IonRow className='mt-3'>
        <Button
          className='w-full'
          color='dark'
          routerLink={`/dashboard/${category}/jars/${jar.id}/settings/withdrawals/deductions/payouts`}
          routerDirection='root'
          data-testid='goto-withdrawals'
        >
          withdrawal.title
        </Button>
      </IonRow>
    </Card>
  )
}
