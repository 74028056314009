const isBfunMember = (jarId: string): boolean => {
  return [
    '4fc1e6af-24dc-477a-a36c-cbaac1977a61',
    '4393f747-0b44-44a5-aaca-cee71cd2ab6c',
    '21baa22c-d22d-4d96-95ec-ccdd41712e7e',
    '8cf6f9db-41e4-469a-b426-2cf59d19ce8a',
    '7a53f729-66b9-40f4-9810-d09670594dd4'
  ].includes(jarId)
}

export default isBfunMember
