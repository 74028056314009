import Text from '#components/ui/Text'
import { type ListResponse, type Payout } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useInfiniteQuery } from '@tanstack/react-query'
import EmptyPayout from './EmptyPayout'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import PayoutCard from './PayoutCard'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'

interface Props {
  userId?: string
  jarId?: string
  isDeduction?: boolean
}
export default function PayoutHistorySection({
  userId,
  jarId,
  isDeduction = false
}: Props): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const fetchPayoutList = async ({
    pageParam = 1
  }): Promise<ListResponse<Payout>> => {
    if (jarId != null) {
      const filters = isDeduction
        ? {
            eq: {
              jar_id: jarId,
              category: 'deduction'
            }
          }
        : {
            eq: {
              jar_id: jarId
            },
            ne: {
              category: 'deduction'
            }
          }
      const include = isDeduction
        ? ['bank_account_deduction']
        : ['bank_account']

      return await sdk.payouts.list({
        filters,
        include,
        sort: {
          created_at: 'desc'
        },
        pageNumber: pageParam
      })
    } else {
      return await sdk.payouts.list({
        filters: {
          eq: {
            user_id: userId
          }
        },
        include: ['bank_account'],
        sort: {
          created_at: 'desc'
        },
        pageNumber: pageParam
      })
    }
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: [isDeduction ? 'payouts-deduction' : 'payouts'],
    queryFn: fetchPayoutList,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined,
    enabled: userId != null || jarId != null
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (hasMore == null || !hasMore) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  return (
    <div className='block mt-8'>
      <Text fontWeight='font-semibold' data-testid='title-payout-history'>
        payout.history
      </Text>
      <div className='mt-10'>
        {status === 'pending' ? (
          <CardSkeleton />
        ) : status === 'error' ? (
          <ErrorBox error={error} />
        ) : data.pages[0].length === 0 && !isFetching ? (
          <EmptyPayout />
        ) : (
          <>
            {data.pages.map((page, i) => (
              <div key={i} className='space-y-6'>
                {page.map((payout) => {
                  return (
                    <PayoutCard
                      key={payout.id}
                      payout={payout}
                      category={category}
                      jarId={jarId}
                      isDeduction={isDeduction}
                    />
                  )
                })}
              </div>
            ))}

            <IonInfiniteScroll
              disabled={hasMore == null || !hasMore}
              onIonInfinite={loadNext}
            >
              <IonInfiniteScrollContent
                loadingSpinner='circles'
                loadingText='Loading...'
                color='primary'
              />
            </IonInfiniteScroll>
          </>
        )}
      </div>
    </div>
  )
}
