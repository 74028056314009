import {
  type QueryParamsRetrieve,
  type BankAccount,
  type BankAccountCreate
} from '#tackpay-sdk'
import { createContext, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  type UseMutationResult,
  useMutation
} from '@tanstack/react-query'
import sdk from '#utils/sdk'
import useAuthState from '#hooks/useAuthState'

type CreateBankAccountFunction = UseMutationResult<
  BankAccount,
  unknown,
  BankAccountCreate,
  unknown
>

interface BankContextValue {
  bank?: BankAccount | null
  isLoading: boolean
  error?: any
  createBankAccount?: CreateBankAccountFunction
}

const bankInitialValue: BankContextValue = {
  bank: null,
  isLoading: false,
  error: null
}

const BankContext = createContext<BankContextValue>(bankInitialValue)

export const useBankContext = (): BankContextValue => {
  const context = useContext(BankContext)
  if (context == null) {
    throw new Error('useBankContext must be used within a BankProvider')
  }
  return context
}

interface BankContainerProps {
  /**
   * The render prop
   */
  children: React.ReactNode
  /**
   * If automatic fetching is enabled, the component will fetch the data on mount
   * @default true
   */
  fetch?: boolean
  /**
   * If true, the component will fetch the company data
   * @default false
   */
  fetchCompany?: boolean
  /**
   * The jarId to use when fetching the data
   */
  jarId?: string

  /**
   * The query params to use when fetching the data
   */
  params?: QueryParamsRetrieve
}

const handleCreateBankAccount = async (
  bankAccountCreate: BankAccountCreate
): Promise<BankAccount> => {
  return await sdk.bank_accounts.create(bankAccountCreate)
}

export default function BankContainer(props: BankContainerProps): JSX.Element {
  const {
    children,
    params,
    fetch = true,
    jarId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchCompany = false
  } = props

  const queryClient = useQueryClient()

  const { getAuthState } = useAuthState()

  const retrieveBankAccountUser = useQuery({
    queryKey: ['bank-user', params],
    queryFn: async () => {
      const authInfo = await getAuthState()
      return await sdk.users.bank_account(authInfo?.userId ?? '', params)
    },
    enabled: fetch && jarId == null
  })

  const retrieveBankAccountJar = useQuery({
    queryKey: ['bank-jar', params],
    queryFn: async () => {
      return await sdk.jars.bank_account(jarId ?? '', params)
    },
    enabled: fetch && jarId != null
  })

  const useBankAccountCreate = useMutation({
    mutationFn: handleCreateBankAccount,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['bank-user', params]
      })
      void queryClient.invalidateQueries({ queryKey: ['bank-jar', params] })
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId, params] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['bank-user', params] })
      void queryClient.invalidateQueries({ queryKey: ['bank-jar', params] })
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId, params] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
    }
  })

  const bankContextValue: BankContextValue = {
    bank: retrieveBankAccountJar.data ?? retrieveBankAccountUser.data,
    isLoading:
      jarId != null
        ? retrieveBankAccountJar.isLoading
        : retrieveBankAccountUser.isLoading,
    error:
      jarId != null
        ? retrieveBankAccountJar.error
        : retrieveBankAccountUser.error,
    createBankAccount: useBankAccountCreate
  }

  return (
    <BankContext.Provider value={bankContextValue}>
      {children}
    </BankContext.Provider>
  )
}
