import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

interface Props {
  amount: number[]
  dates: string[]
}

export default function ChartLine({ amount, dates }: Props): JSX.Element {
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)')

  const xColor = darkMode.matches ? 'transparent' : 'transparent'

  const yColor = darkMode.matches ? 'white' : '#dddddd'

  const textColor = darkMode.matches ? 'white' : '#333333'

  return (
    <div className='mt-8'>
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              grid: {
                color: xColor
              },
              ticks: {
                color: textColor
              }
            },
            y: {
              grid: {
                color: yColor
              },
              ticks: {
                color: textColor
              }
            }
          }
        }}
        data={{
          labels: dates,
          datasets: [
            {
              label: 'Tips',
              data: amount,
              borderColor: '#71e89d',
              backgroundColor: 'rgba(113,232,157,0.5)',
              fill: false,
              tension: 0.5,
              pointStyle: 'circle'
            }
          ]
        }}
        height='350px'
        width='300px'
      />
    </div>
  )
}
