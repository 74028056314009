import { type MemberAmount } from './MemberList'
// import CurrencyInput from '#components/common/CurrencyInput'
import { useEffect, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import cn from 'classnames'
import { type CurrencyCode } from '#types/currency'
import { getSymbolCurrency } from '#constants/country'

interface Props {
  member: MemberAmount
  setMemberAmount: (memberAmount: MemberAmount) => void
}
export default function InputAmountDivide({
  member,
  setMemberAmount
}: Props): JSX.Element {
  const currency = useMemo(
    () => member?.user?.currency ?? 'eur',
    [member?.user?.currency]
  ) as CurrencyCode

  const [_value, setValue] = useState(
    makeInitialValue({ cents: member.amount })
  )

  const decimalLength = useMemo(() => getDecimalLength(), [])

  useEffect(() => {
    setValue(makeInitialValue({ cents: member.amount, currency }))
  }, [member.amount, currency])

  if (
    member?.amount != null &&
    member?.amount > 0 &&
    member?.amount % 1 !== 0
  ) {
    return <div>`cents` ({member.amount}) is not an integer value</div>
  }

  // useEffect(() => {
  //   if (
  //     (currentValue === '' ||
  //       currentValue.replace(symbolCurrency, '') === '0') &&
  //     member.amount !== 0
  //   ) {
  //     setCurrentValue(member.amount.toString())
  //   }
  // }, [currentValue, member.amount])

  // useEffect(() => {
  //   if (member.amount.toString() !== currentValue) {
  //     setCurrentValue(member.amount.toString())
  //   }
  // })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleAmountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   const value = String(e.target.value)?.replace(symbolCurrency, '')
  //   console.log(value)

  //   const amount = parseFloat(value) ?? 0

  //   console.log('Amount', amount)

  //   setCurrentValue(value)

  //   setMemberAmount({
  //     ...member,
  //     amount
  //   })
  // }

  // const handleAmountChange = (value: number): void => {
  //   setCurrentValue(value.toString())
  //   setMemberAmount({
  //     ...member,
  //     amount: value
  //   })
  // }

  return (
    <div className='relative w-full'>
      <div
        data-testid='inputCurrency-symbol'
        className='absolute left-4 top-1/2 transform -translate-y-1/2 font-bold'
      >
        {getSymbolCurrency(currency)}
      </div>
      <CurrencyInput
        data-testid='currency-input'
        id='input-example'
        name='input-name'
        className={cn(
          'block w-full pl-8 pr-4 py-2.5 font-medium border border-gray-300 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500',
          'rounded outline-0'
        )}
        value={_value ?? ''}
        disableAbbreviations
        allowDecimals={decimalLength > 0}
        decimalsLimit={decimalLength}
        groupSeparator=' '
        decimalSeparator=','
        onValueChange={(val, __, values) => {
          setValue(val as any)
          if (values?.float == null) {
            // onChange(null, '')
            return
          }
          const newValue = Math.round(values.float * 100)
          // const newFormatted = formattedAmount(newValue, currency)
          setMemberAmount({ ...member, amount: newValue })
          // onChange(newValue, newFormatted)
        }}
      />
    </div>
  )
}

/**
 *  Returns the number of decimals for a given currency
 */
export function getDecimalLength(): number {
  return Math.log10(100)
  // return currency.subunit_to_unit.toString().split('0').length - 1
}

/**
 * Prepare the initial value for the component internal state.
 **/
function makeInitialValue({
  cents,
  currency
}: {
  cents?: number | null
  currency?: CurrencyCode
}): number | undefined {
  if (cents == null || currency == null) {
    return
  }

  return cents / 100
}
