import AppTypeahead from '#components/ui/AppTypeHead'
import Label from '#components/ui/Label'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { BANK_COUNTRIES } from '#constants/country'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { useState } from 'react'

interface Props {
  country: string
  setSelectionCountry: (country: string) => void
}
export default function AddBankCountry({
  country,
  setSelectionCountry
}: Props): JSX.Element {
  const [showCountry, setShowCountry] = useState(false)

  const isEmptyCountry = (): boolean => {
    return country === ''
  }

  const handleSelectionCountry = (country: string): void => {
    setSelectionCountry(country)
    setShowCountry(false)
  }

  return (
    <>
      <Modal
        isOpen={showCountry}
        setShowModal={setShowCountry}
        data-testid='modal_choose_country'
      >
        <AppTypeahead
          title='Select country'
          items={BANK_COUNTRIES.map((country) => {
            return {
              text: country.country_name,
              value: country.country_name
            }
          })}
          selectedItems=''
          onSelectionCancel={() => {
            setShowCountry(false)
          }}
          onSelectionChange={handleSelectionCountry}
        />
      </Modal>
      <IonCol>
        <Label
          position='fixed'
          className='block mb-1 text-sm font-medium text-gray-900  capitalize'
        >
          country
        </Label>
        <div
          className='bg-white border border-gray-300 rounded-lg p-2'
          onClick={() => {
            setShowCountry(true)
          }}
        >
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <Text
              color={isEmptyCountry() ? 'gray-500' : 'gary-900'}
              className='pl-1'
            >
              {isEmptyCountry() ? 'Select a country' : country}
            </Text>
            <IonIcon src={chevronDownOutline} className='' />
          </IonRow>
        </div>
      </IonCol>
    </>
  )
}
