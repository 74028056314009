import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { usePayoutContext } from '#contexts/PayoutContext'
import { useUserContext } from '#contexts/UserContext'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'
import { getPayoutFee } from '../collect/payoutFees'

export default function PayoutCardDetail(): JSX.Element {
  const { isLoading, payout, error } = usePayoutContext()

  const { user } = useUserContext()

  if (isLoading) return <CardSkeleton />

  if (error != null) return <ErrorBox error={error} />

  if (payout == null)
    return (
      <ErrorBox
        error={{
          title: 'Not found',
          detail: 'Payout not found',
          code: 'payout_not_found',
          status: 422
        }}
      />
    )

  const getArrivalDate = (): string => {
    const timestamp = (payout.arrival_date ?? 1) * 1000
    const date = new Date(timestamp)

    return formattedDate(date, user?.lang, {
      month: 'short',
      day: '2-digit'
    })
  }

  const hasCommission = (): boolean => {
    const date = new Date(2024, 6, 3).getTime()
    const payoutDate = new Date(payout.created_at ?? new Date()).getTime()
    return payoutDate >= date
  }

  const getCommission = (): number => {
    const amount = payout.amount_cents ?? 0
    const fee = getPayoutFee(payout?.currency ?? 'EUR')

    if (amount <= fee.topLimit * 100) {
      return fee.fee * 100
    }

    return 0
  }

  return (
    <Card>
      <IonCardContent>
        <IonGrid className='ion-no-padding space-y-3'>
          <IonRow className=' justify-between items-center'>
            <IonCol>
              <Text color='dark' size='text-base'>
                payout.bankAccount
              </Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text translate='no' className='text-gray-600'>
                {`****${payout?.bank_account?.last_account_number ?? ''}`}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='justify-between items-center'>
            <IonCol>
              <Text color='dark' size='text-base'>
                payout.arrivalDate
              </Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text translate='no' className='text-gray-600'>
                {getArrivalDate()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='justify-between items-center'>
            <IonCol>
              <Text color='dark' size='text-base'>
                payout.created
              </Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text translate='no' className='text-gray-600'>
                {formattedDate(payout.created_at ?? new Date(), user?.lang, {
                  month: 'short',
                  day: '2-digit'
                })}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='justify-between items-center'>
            <IonCol>
              <Text color='dark' size='text-base'>
                payout.amount
              </Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text translate='no' className='text-gray-600'>
                {formattedAmount(payout.amount_cents, payout.currency)}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='justify-between items-center'>
            <IonCol>
              <Text color='dark' size='text-base'>
                payout.fee
              </Text>
            </IonCol>
            <IonCol className='text-right'>
              <Text translate='no' className='text-gray-600'>
                {formattedAmount(
                  hasCommission() ? getCommission() : 0,
                  payout.currency ?? 'EUR'
                )}
              </Text>
            </IonCol>
          </IonRow>
        </IonGrid>
        {!['paid', 'in_transit'].includes(payout.status) && (
          <>
            <hr className='my-8' />
            <Text className='font-gray-500 font-semibold'>
              payout.status_warn
            </Text>
            <Text className='block mt-4' color='dark'>
              {payout.status}
            </Text>
            <Text className='font-gray-500 font-semibold'>payout.detail</Text>
            <Text className='block mt-4' color='dark'>
              {payout.failure_code ?? payout.failure_message ?? 'N/A'}
            </Text>
          </>
        )}
      </IonCardContent>
    </Card>
  )
}
