import { type Transfer } from '#tackpay-sdk'

const getMemberTackpayId = (
  transfers: Transfer[],
  memberName: string
): string => {
  for (const transfer of transfers) {
    if (
      (transfer?.member?.user?.person?.first_name ===
        memberName.split(' ')[0] &&
        transfer?.member?.user?.person?.last_name ===
          memberName.split(' ')[1]) ||
      transfer?.member?.id === memberName
    ) {
      return transfer.member?.user?.tackpay_id?.value ?? 'unknown'
    }
  }
  return 'unknown'
}

const getMemberName = (transfers: Transfer[], memberId: string): string => {
  for (const transfer of transfers) {
    if (transfer.member?.id === memberId) {
      return `${transfer.member?.user?.person?.first_name} ${transfer.member?.user?.person?.last_name}`.replace(
        ',',
        ''
      )
    }
  }
  return 'unknown'
}

const getTackpayIdJar = (transfer: Transfer[]): string => {
  let tackpayId = 'unknown'
  transfer?.forEach((transfer) => {
    const tackpayIdJar =
      transfer?.payment?.jar?.tackpay_ids ?? transfer?.member?.jar?.tackpay_ids
    if (Array.isArray(tackpayIdJar)) {
      tackpayId = tackpayIdJar?.[0]?.value ?? 'unknown'
    } else {
      tackpayId = tackpayIdJar?.value ?? 'unknown'
    }
  })

  return tackpayId
}

const formatCsvRow = (fields: Array<string | number | undefined>): string => {
  return fields
    .map((field) => {
      if (typeof field === 'string') {
        return `"${field.replace(/"/g, '""')}"`
      }
      return field
    })
    .join(',')
}

export default function createPaymentCsvMember(
  fromDate: string,
  toDate: string,
  transfers: Transfer[] = [],
  lang: string = 'en'
): string {
  const labels: Record<string, string> = {
    en: 'From Date, To Date, Jar, ID Jar, Tip, Currency, Member, ID Member',
    it: 'Data inizio, Data fine, Barattolo, ID Barattolo, Mancia, Valuta, Membro, ID Membro',
    es: 'Desde fecha, Hasta fecha, Bote, ID Bote, Propina, Moneda, Miembro, ID Miembro'
  }

  let csvContent = labels[lang] + '\n' // Intestazione

  const memberTotal: Record<string, number> = {}
  const jarName =
    transfers[0]?.jar?.name ??
    transfers[0]?.payment?.jar?.name ??
    transfers[0]?.member?.jar?.name ??
    'unknown'
  const currency = transfers[0]?.currency ?? 'unknown'

  for (const transfer of transfers) {
    const memberName = transfer.member?.id ?? 'unknown'
    const amount = transfer.amount_float

    if (memberTotal[memberName] != null) {
      memberTotal[memberName] += amount
    } else {
      memberTotal[memberName] = amount
    }
  }

  for (const member in memberTotal) {
    const memberName = getMemberName(transfers, member)
    const totalAmount = memberTotal[member].toFixed(2).replace('.', ',')
    const tackpayIdMember = getMemberTackpayId(transfers, member)
    const tackpayIdJar = getTackpayIdJar(transfers)
    const row = formatCsvRow([
      fromDate,
      toDate,
      jarName.replace(',', ''),
      tackpayIdJar,
      totalAmount,
      currency,
      memberName,
      tackpayIdMember
    ])

    csvContent += row + '\n'
  }

  return csvContent
}
