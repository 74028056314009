import AppLayout from '#components/layouts/app/AppLayout'
import CollaboratorInviteSection from '#components/pages/collaborators/invites/CollaboratorInviteSection'
import CollaboratorListSection from '#components/pages/collaborators/list/CollaboratorListSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import isCollaborator from '#utils/isCollaborator'
import { useParams } from 'react-router'

export default function Collaborator(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
      companyProps={{
        fetch: true,
        isCollaborator: isCollaborator(category),
        params: {
          include: ['jars']
        }
      }}
    >
      <Title hasSubtitle>collaborator.title</Title>
      <SubTitle>collaborator.subtitle</SubTitle>
      <CollaboratorInviteSection />
      <CollaboratorListSection />
    </AppLayout>
  )
}
