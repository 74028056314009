import Info from '#components/ui/Info'
import Text from '#components/ui/Text'
import { getSymbolCurrency } from '#constants/country'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { type FeeSimulator } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { IonCheckbox, IonCol, IonRow } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { savePreferences } from 'storage/preferences'

interface Props {
  payFee: boolean
  terms: boolean
  setPayFee: (value: boolean) => void
  setTerms: (value: boolean) => void
}

export default function CheckoutFeeAndTerms({
  payFee,
  terms,
  setPayFee,
  setTerms
}: Props): JSX.Element {
  const { t } = useTranslation()
  const { checkout } = useCheckoutContext()
  const [fees, setFee] = useState<FeeSimulator | null>(null)

  const openTerms = (): void => {
    if (window != null) {
      window.open(
        'https://www.tackpay.net/En/Legals/Terms%20and%20Conditions.pdf',
        '_blank'
      )
    }
  }

  useEffect(() => {
    const fetchFee = async (): Promise<void> => {
      if (checkout?.payment == null) {
        return
      }

      const result = await sdk.checkout_sessions.fee_simulators(
        checkout?.payment?.amount_cents,
        checkout?.payment?.currency,
        true
      )

      setFee(result)
    }

    void fetchFee()
  }, [])

  useEffect(() => {
    const cleanPersistedFee = async (): Promise<void> => {
      await savePreferences('feeSimulator', null)
    }
    void cleanPersistedFee()
  }, [])

  const handleFeeChange = async (covered: boolean): Promise<void> => {
    if (checkout?.payment == null) {
      return
    }

    const result = await sdk.checkout_sessions.fee_simulators(
      checkout?.payment?.amount_cents,
      checkout?.payment?.currency,
      covered
    )
    await savePreferences('feeSimulator', result)
  }

  return (
    <IonCol className='space-y-4'>
      <IonRow className='ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding w-full'>
        <IonCheckbox
          className='square text-gray-700'
          name='transactions'
          checked={payFee}
          onIonChange={(e) => {
            setPayFee(e.detail.checked)
            void handleFeeChange(e.detail.checked)
          }}
          labelPlacement='end'
          justify='start'
          id='fee'
        >
          {t('checkout.payFee', {
            amount: `(+ ${((fees?.totalFeeAmount ?? 0) / 100)?.toFixed(2)} ${getSymbolCurrency(checkout?.payment?.currency ?? 'eur')})`
          })}
        </IonCheckbox>

        <Info title='i' text={t('checkout.payFeeInfo')} />
      </IonRow>

      <IonRow className='ion-justify-content-start space-x-3 ion-align-items-center ion-no-margin ion-no-padding w-full'>
        <IonCheckbox
          className='square text-gray-700'
          checked={terms}
          onIonChange={(e) => {
            setTerms(e.detail.checked)
          }}
          name='terms'
          labelPlacement='end'
          justify='start'
          id='terms'
        />
        <IonRow className='space-x-1'>
          <Text
            color='gray-700'
            size='text-base'
            className='text-gray-700 cursor-pointer'
          >
            checkout.termsAccept
          </Text>
          <Text
            color='gray-700'
            size='text-base'
            onClick={openTerms}
            className='text-gray-700 cursor-pointer underline'
          >
            checkout.terms
          </Text>
        </IonRow>
      </IonRow>
    </IonCol>
  )
}
