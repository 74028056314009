import { type QueryParamsRetrieve } from 'sdk/query'
import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Jar } from './jars'
import { type Company } from './companies'
import { type User } from './users'

type BankAccountDeductionType = 'bank_account_deductions'
type BankAccountDeductionRel = ResourceRel & { type: BankAccountDeductionType }

type BankAccountDeductionHolderType = 'individual' | 'jar'

type BankStatus = 'new' | 'verified' | 'verification_failed' | 'errored'

type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

interface BankAccountDeduction extends Resource {
  readonly type: BankAccountDeductionType
  last_account_number: string
  routing_number?: string
  name?: string
  account_holder_type: BankAccountDeductionHolderType
  account_holder_name?: string
  active: boolean
  status: BankStatus
  country_code: string
  currency: string
  jars?: Jar[]
  company?: Company
  user?: User
}

interface BankAccountDeductionCreate extends ResourceCreate {
  account_number: string
  routing_number?: string
  account_holder_type: BankAccountDeductionHolderType
  account_holder_name: string
  country_code: string
  currency: string
  user?: UserRel
  jar?: JarRel
}

class BankAccountDeductions extends ApiResource<BankAccountDeduction> {
  static readonly TYPE: BankAccountDeductionType =
    'bank_account_deductions' as const

  async create(
    resource: BankAccountDeductionCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccountDeduction> {
    return await this.resources.create<
      BankAccountDeductionCreate,
      BankAccountDeduction
    >({ ...resource, type: BankAccountDeductions.TYPE }, params, options)
  }

  isBalance(resource: any): resource is BankAccountDeduction {
    return resource.type != null && resource.type === BankAccountDeductions.TYPE
  }

  relationship(id: string | ResourceId | null): BankAccountDeductionRel {
    return id === null || typeof id === 'string'
      ? { id, type: BankAccountDeductions.TYPE }
      : { id: id.id, type: BankAccountDeductions.TYPE }
  }

  type(): BankAccountDeductionType {
    return BankAccountDeductions.TYPE
  }
}

export type {
  BankAccountDeduction,
  BankAccountDeductionCreate,
  BankAccountDeductionRel
}
export default BankAccountDeductions
