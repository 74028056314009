import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import type Stripe from 'stripe'
import { type StripeGatewayAccount } from './stripeGatewayAccounts'

type IdentityType = 'identities'
type IdentityRel = ResourceRel & { type: IdentityType }

type IdentityStatus = 'unverified' | 'pending' | 'verified'
type IdentityDocument = Stripe.Person.Verification['document']
type IdentityFutureRequirements = Stripe.Account.FutureRequirements
type IdentityRequirements = Stripe.Account.Requirements

interface Identity extends Resource {
  readonly type: IdentityType
  reference_account_id: string
  status: IdentityStatus
  documents?: IdentityDocument
  details?: string
  details_code?: string
  future_requirements?: IdentityFutureRequirements
  requirements?: IdentityRequirements
  stripe_gateway_account?: StripeGatewayAccount
}

class Identities extends ApiResource<Identity> {
  static readonly TYPE: IdentityType = 'identities' as const

  isIdentity(resource: any): resource is Identity {
    return resource.type != null && resource.type === Identities.TYPE
  }

  relationship(id: string | ResourceId | null): IdentityRel {
    return id === null || typeof id === 'string'
      ? { id, type: Identities.TYPE }
      : { id: id.id, type: Identities.TYPE }
  }

  type(): IdentityType {
    return Identities.TYPE
  }
}

export type { Identity }
export default Identities
