import { IonRow, useIonRouter } from '@ionic/react'
import Avatar from '#components/ui/Avatar'
import ButtonInputFile from '#components/common/ButtonInputFile'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useState } from 'react'
import { type Photo } from '@capacitor/camera'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import Input, { type InputOnChange } from '#components/ui/Input'
import ButtonStep from '#components/common/ButtonStep'
import useToast from '#hooks/useToast'
import textUtil from '#utils/textUtil'
import useStorage from '#hooks/useStorage'
import { type UserCategory } from '#tackpay-sdk'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import randomString from '#utils/randomString'

interface Props {
  category: UserCategory
}
export default function AddTeamInfoForm({ category }: Props): JSX.Element {
  const { teamCreate, setTeamCreate } = useCreateTeamStore()

  const { startLoading, stopLoading } = useLoading('photoLoading')

  const { user } = useUserContext()

  const [photo, setPhoto] = useState<Photo | undefined>(teamCreate?.photo)

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const { uploadByDataUrl } = useStorage()

  const handleInputChange: InputOnChange = (event) => {
    const { name, value } = event.target
    if (typeof value === 'string') {
      if (name === 'name') {
        setTeamCreate({ name: value })
      }
    }
  }

  // const handlePhotoChange = (photo: Photo | undefined): void => {
  //   setPhoto(photo)
  //   setTeamCreate({ avatar: photo?.dataUrl, photo })
  // }
  const handlePhotoChange = (photo: Photo): void => {
    startLoading()

    setPhoto(photo)

    const refPath = `${user?.id ?? randomString()}/jars`

    if (photo?.base64String != null || photo?.path != null) {
      uploadByDataUrl(
        photo?.path,
        photo?.base64String,
        refPath,
        `avatar.${photo?.format}`
      )
        .then((res) => {
          console.log('Upload success', res)
          setTeamCreate({ avatar: res })
          stopLoading()
        })
        .catch((err) => {
          stopLoading()
          console.log('Upload error', err)
          showToast(undefined, err.message)
        })
    }
    stopLoading()
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (textUtil.isEmpty(teamCreate?.name)) {
      showToast(undefined, 'teamPage.add.nameRequired')
      return
    }

    console.log('photo', photo)

    if (
      teamCreate?.avatar != null &&
      teamCreate.avatar !== DEFAULT_IMAGES.logoTeam &&
      teamCreate?.avatar.includes('data:image')
    ) {
      startLoading()
      uploadByDataUrl(String(photo?.dataUrl), user?.id, 'avatar.jpg')
        .then((res) => {
          setTeamCreate({ avatar: res })
          console.log('res', res)
          stopLoading()
          push(`/dashboard/${category}/jars/add/roles`)
        })
        .catch((err) => {
          stopLoading()
          showToast(undefined, err.message)
        })
    } else {
      stopLoading()
      push(`/dashboard/${category}/jars/add/roles`)
    }
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars`, 'back')
  }

  return (
    <form className='mt-10 space-y-8' onSubmit={handleSubmit}>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Avatar
          avatarOptions={{
            className: 'w-20 h-20'
          }}
          imgOptions={{
            src: teamCreate?.avatar ?? DEFAULT_IMAGES.logoTeam,
            alt: 'avatar'
          }}
        />
        <ButtonInputFile
          text='changePhoto'
          color='green-500'
          fill='outline'
          setPhoto={handlePhotoChange}
          testId='button-change-photo'
        />
      </IonRow>
      <div>
        <Input
          name='name'
          type='text'
          value={teamCreate?.name}
          onIonInput={handleInputChange}
          labelOptions={{
            children: 'name'
          }}
          testId='name-company'
        />
      </div>
      <ButtonStep nextType='submit' handleBack={handleBack} />
    </form>
  )
}
