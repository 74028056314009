import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isBusiness } from '#utils/isBusiness'
import isCollaborator from '#utils/isCollaborator'
import { useParams } from 'react-router'
import DashboardDesktopBusiness from './DashboardDesktopBusiness'
import DashboardDesktopTipped from './DashboardDesktopTipped'

export default function DashboardDesktop(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  if (isBusiness(category) || isCollaborator(category)) {
    return <DashboardDesktopBusiness />
  }

  return <DashboardDesktopTipped />
}
