import Button from '#components/ui/Button'
import { useState } from 'react'
import ModalPayout from './PayoutModal'
import {
  type BankAccount,
  type Balance,
  type UserCategory,
  type BankAccountDeduction,
  type BalanceDeduction
} from '#tackpay-sdk'
import PayoutContainer from '#contexts/PayoutContext'

interface Props {
  addBankHref?: string
  jarId?: string
  balance?: Balance | null | BalanceDeduction
  bankAccount?: BankAccount | null | BankAccountDeduction
  userId?: string
  category: UserCategory
  isDeduction?: boolean
}
export default function PayoutCollectButton({
  addBankHref,
  jarId,
  balance,
  bankAccount,
  userId,
  category,
  isDeduction = false
}: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false)

  const getAmount = (): number => {
    return balance?.available?.amount_cents ?? 0
  }

  const getCurrency = (): string => {
    return balance?.available?.currency ?? 'eur'
  }
  return (
    <>
      <PayoutContainer fetch={false}>
        <ModalPayout
          setShow={setShowModal}
          show={showModal}
          amount={getAmount()}
          currency={getCurrency()}
          addBankHref={addBankHref}
          bankAccount={bankAccount}
          jarId={jarId}
          userId={userId}
          category={category}
          isDeduction={isDeduction}
        />
      </PayoutContainer>
      <Button
        className='w-full'
        onClick={() => {
          setShowModal(true)
        }}
        data-testid='button-payout-collect'
      >
        payout.collect
      </Button>
    </>
  )
}
