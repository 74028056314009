import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useUserContext } from '#contexts/UserContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type Transfer, type Payment } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import sdk from '#utils/sdk'
import {
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react'
import {
  bookmarkOutline,
  cardOutline,
  cashOutline,
  globeOutline,
  heartCircleOutline,
  qrCodeOutline,
  watchOutline
} from 'ionicons/icons'
import { useParams } from 'react-router'

interface Props {
  payment?: Payment | Transfer | null
  isLoading: boolean
  error?: any
}
export default function PaymentCardInfo({
  payment,
  isLoading,
  error
}: Props): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const { user } = useUserContext()

  if (isLoading) return <CardSkeleton hasAvatar={false} />

  if (error != null) return <ErrorBox error={error} />

  const getCountry = (): string => {
    const country =
      (payment as Payment)?.payment_method?.card?.country ??
      (payment as Transfer)?.payment?.payment_method?.card?.country ??
      'unknown'

    if (payment?.type === 'transfers') {
      if (payment.tronc) return 'jar'
    }

    return country
  }

  const getBrand = (): string => {
    const paymentMethod =
      (payment as Payment)?.payment_method ??
      (payment as Transfer)?.payment?.payment_method ??
      undefined

    if (payment?.type === 'transfers') {
      if (payment.tronc) return 'tronc'
    }

    if (paymentMethod == null) return 'unknown'

    return paymentMethod?.card?.brand ?? paymentMethod?.method_type ?? 'unknown'
  }

  const getRerefernceOrigin = (): string => {
    const transaction = sdk.payments.isPayment(payment)
      ? payment
      : payment?.payment

    let referenceOrigin: string | undefined = 'unknown'

    if (transaction?.reference_review === 'single_member') {
      referenceOrigin = `${transaction?.member?.user?.person?.first_name} ${transaction?.member?.user?.person?.last_name}`
    } else if (transaction?.category === 'tipped') {
      const user = payment?.user ?? transaction?.user
      const tackpayId = user?.tackpay_id?.value
      referenceOrigin = user?.person?.first_name
      if (tackpayId != null) {
        referenceOrigin += ` (${tackpayId})`
      }
    } else if (
      transaction?.reference_review === 'jar' ||
      transaction?.reference_review === 'all_member'
    ) {
      const jar = transaction?.jar ?? payment?.jar
      referenceOrigin = jar?.name
    }

    const user = payment?.user ?? transaction?.user
    const tackpayId = user?.tackpay_id?.value
    // referenceOrigin = user?.person?.first_name

    if (referenceOrigin === 'unknown' || referenceOrigin == null) {
      const jar = transaction?.jar ?? payment?.jar

      if (jar != null) return jar.name
      const user = payment?.user ?? transaction?.user

      const tackpayId = user?.tackpay_id?.value

      return `${user?.person?.first_name} (${tackpayId})`
    }

    return referenceOrigin ?? `${user?.person?.first_name} (${tackpayId})`
  }

  const getRole = (): string => {
    const role =
      (payment as Payment)?.role ??
      (payment as Transfer)?.payment?.role ??
      'unknown'

    if (payment?.type === 'transfers') {
      if (payment.tronc) return 'tronc'
    }

    if (role === 'individual') return 'private'

    return role
  }

  const getDate = (): string => {
    const date =
      (payment as Payment)?.created_at ??
      (payment as Transfer)?.payment?.created_at ??
      'unknown'

    return formattedDate(date, user?.lang, {
      dateStyle: 'medium'
    })
  }

  const getTime = (): string => {
    const time =
      (payment as Payment)?.created_at ??
      (payment as Transfer)?.payment?.created_at ??
      'unknown'

    return formattedDate(time, user?.lang, {
      hour: '2-digit',
      minute: '2-digit'
    })
  }
  const getPayFee = (): string => {
    const fee =
      (payment as Payment)?.fee?.covered ??
      (payment as Transfer)?.payment?.fee?.covered ??
      false
    if (fee) return 'feeCovered'
    return 'feeNotCovered'
  }

  const getAvatar = (): string => {
    const transaction = sdk.payments.isPayment(payment)
      ? payment
      : payment?.payment

    if (transaction?.reference_review === 'single_member') {
      return transaction?.member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
    } else if (transaction?.category === 'tipped') {
      return transaction?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
    }

    return transaction?.jar?.avatar ?? DEFAULT_IMAGES.logoTeam
  }

  const hasDeduction = (): boolean => {
    const transaction = sdk.payments.isPayment(payment)
      ? payment
      : payment?.payment

    return transaction?.deduction != null && category !== 'tipped'
  }

  const getDeductionAmount = (): string => {
    const transaction = sdk.payments.isPayment(payment)
      ? payment
      : payment?.payment

    return formattedAmount(
      transaction?.deduction?.amount,
      transaction?.deduction?.currency
    )
  }

  return (
    <Card>
      <IonCardHeader>
        <Avatar
          imgOptions={{
            src: getAvatar()
          }}
          avatarOptions={{
            className: 'w-24 h-24 mx-auto'
          }}
        />
      </IonCardHeader>
      <div className='mt-5 ion-padding'>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCardTitle>Tip</IonCardTitle>
          <IonCardTitle>
            {formattedAmount(payment?.amount_cents, payment?.currency)}
          </IonCardTitle>
        </IonRow>
        <IonRow className='border-t-gray-200 border my-5' />
        <Text color='gray-500' className='my-3'>
          information
        </Text>
        <IonGrid className='space-y-3'>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={cardOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getBrand()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={qrCodeOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getRerefernceOrigin()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={watchOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getDate()},{getTime()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={globeOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getCountry()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={heartCircleOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getPayFee()}
              </Text>
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
            <IonCol className='ion-no-margin ion-no-padding'>
              <IonIcon icon={bookmarkOutline} className='w-5 h-5' />
            </IonCol>
            <IonCol size='8'>
              <Text className='capitalize' size='text-base'>
                {getRole()}
              </Text>
            </IonCol>
          </IonRow>
          {hasDeduction() && (
            <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
              <IonCol className='ion-no-margin ion-no-padding'>
                <IonIcon icon={cashOutline} className='w-5 h-5' />
              </IonCol>
              <IonCol size='8'>
                <IonRow className='ion-align-items-center space-x-3'>
                  <Text size='text-base'>deduction.label</Text>
                  <Text
                    className='capitalize'
                    size='text-base'
                    translationOptions={{
                      translate: 'no'
                    }}
                  >
                    {getDeductionAmount()}
                  </Text>
                </IonRow>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </div>
    </Card>
  )
}
