import Avatar from '#components/ui/Avatar'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type Token } from '#tackpay-sdk'
import { IonCol, IonRow } from '@ionic/react'

interface Props {
  token: Token
  /**
   * @default true
   */
  showDescription?: boolean
}
export default function RegistrationMemberTeamInfo({
  token,
  showDescription = true
}: Props): JSX.Element {
  return (
    <IonRow className='pt-6'>
      <IonCol className='flex flex-col ion-justify-content-center ion-align-items-center space-y-5'>
        <Avatar
          imgOptions={{
            src: token?.jar?.avatar ?? DEFAULT_IMAGES.logoTeam,
            alt: token?.jar?.name ?? 'Jar logo'
          }}
          avatarOptions={{
            className: 'w-24 h-24'
          }}
        />
        <Underlined name={token?.jar?.name ?? 'Jar name'} />
        {showDescription && (
          <>
            <Text
              translate='yes'
              color='gray-500'
              className='mb-8 mt-3 ion-text-center'
            >
              invite_bio
            </Text>
            <hr className='w-full pt-5' />
          </>
        )}
      </IonCol>
    </IonRow>
  )
}
