import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import useFilesystem from '#hooks/useFilesystem'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type AccountStatement, type UserCategory } from '#tackpay-sdk'
import createPaymentCsv from '#utils/createPaymentCsv'
import getErrorMessage from '#utils/getErrorMessage'
import isWeb from '#utils/isWeb'
import sdk from '#utils/sdk'
import { Directory, Encoding } from '@capacitor/filesystem'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface Props {
  jarId: string
  category: UserCategory
  hasBalance?: boolean
  isTronc?: boolean
}
export default function TeamDelete({
  jarId,
  category,
  hasBalance,
  isTronc
}: Props): JSX.Element {
  const { jar } = useTeamContext()

  const { write } = useFilesystem()

  const [showModal, setShowModal] = useState(false)

  const [showDownloadBalance, setShowDownloadBalance] = useState(false)

  const [showDownloadAccountStatement, setShowDownloadAccountStatement] =
    useState(false)

  const queryClient = useQueryClient()

  const { deleteTeam } = useTeamContext()

  const { showToast: showSuccess } = useToast('success')

  const { showToast } = useToast('error')

  const { startLoading, stopLoading } = useLoading('teamPage.delete.loading')

  const { push } = useIonRouter()

  const handleSave = async (
    accountStatement: AccountStatement
  ): Promise<void> => {
    const csvObject = createPaymentCsv(
      accountStatement.payments,
      accountStatement.transfers
    )

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'accountStatement.csv'
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      await write({
        data: csvObject,
        path: 'accountStatement.csv',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
    }
  }

  const handleDownload = async (): Promise<void> => {
    const accountStatements = await sdk.account_statements.create({
      to_date: new Date().toISOString(),
      from_date: jar?.created_at ?? new Date().toISOString(),
      jar: { id: jarId, type: 'jars' }
    })

    if (
      (accountStatements?.payments != null &&
        accountStatements.payments.length > 0) ||
      (accountStatements?.transfers != null &&
        accountStatements.transfers.length > 0)
    ) {
      await handleSave(accountStatements)
    }
  }

  const handleDownloadClick = (): void => {
    handleDownload()
      .then(() => {
        setShowDownloadAccountStatement(false)
        setShowModal(true)
        showSuccess(undefined, 'teamAccountStatementdDownloaded')
      })
      .catch((error) => {
        setShowDownloadAccountStatement(false)
        showToast(undefined, getErrorMessage(error))
      })
  }

  const handleDelete = (): void => {
    if (deleteTeam != null) {
      setShowModal(false)

      startLoading()

      deleteTeam.mutate(jarId, {
        onSuccess: () => {
          queryClient.removeQueries({ queryKey: ['jar', jarId] })
          queryClient.removeQueries({
            queryKey: ['jar', jarId, { include: ['company'] }]
          })

          stopLoading()
          showSuccess('teamPage.delete.success')
          push(`/dashboard/${category}/jars`, 'root')
        },
        onError: (error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        }
      })
    }
  }

  const handleDownloadTips = (): string => {
    if (isTronc === true)
      return `/dashboard/${category}/jars/${jarId}/settings/tronc`
    return `/dashboard/${category}/jars/${jarId}/settings/withdrawals/payouts`
  }

  const handleShow = (): void => {
    sdk.payments
      .list({
        filters: {
          eq: {
            jar_id: jarId
          }
        },
        pageSize: 1
      })
      .then((res) => {
        if (hasBalance === true) {
          setShowDownloadBalance(true)
        } else if (res.length > 0) {
          setShowDownloadAccountStatement(true)
        } else {
          setShowModal(true)
        }
      })
      .catch((error) => {
        showToast(undefined, getErrorMessage(error))
      })
  }
  return (
    <>
      <IonRow className='py-8 ion-align-items-center space-x-4 ion-align-self-center ion-justify-content-center'>
        <Text
          className='text-red-400 text-[1rem] font-medium ion-text-center cursor-pointer'
          onClick={() => {
            handleShow()
          }}
        >
          teamSetting.delete
        </Text>
      </IonRow>
      <Modal
        isOpen={showDownloadAccountStatement}
        setShowModal={setShowDownloadAccountStatement}
        initialBreakpoint={0.65}
        breakpoints={[0.5, 0.75, 1]}
      >
        <div className='md:max-w-md md:mx-auto py-5'>
          <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
            <Text color='dark' className='font-semibold'>
              teamAccountStatementRetrieveTitle
            </Text>
            <IonRow className='ion-no-padding ion-justify-content-center ion-text-center mt-16 px-4'>
              <Text>teamAccountStatementRetrieveDescription</Text>
            </IonRow>
          </IonCol>
          <IonRow className='ion-justify-content-between ion-align-items-center mt-12 ion-padding'>
            <Button
              fill='clear'
              color='medium'
              onClick={() => {
                setShowDownloadAccountStatement(false)
              }}
            >
              cancel
            </Button>
            <Button
              color='primary'
              routerDirection='root'
              onClick={() => {
                handleDownloadClick()
              }}
            >
              confirm
            </Button>
          </IonRow>
        </div>
      </Modal>
      <Modal
        isOpen={showDownloadBalance}
        setShowModal={setShowDownloadBalance}
        initialBreakpoint={0.65}
        breakpoints={[0.5, 0.75, 1]}
      >
        <div className='md:max-w-md md:mx-auto py-5'>
          <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
            <Text color='dark' className='font-semibold'>
              teamBalanceRetrieveTitle
            </Text>
            <IonRow className='ion-no-padding ion-justify-content-center ion-text-center mt-16 px-4'>
              <Text>
                {isTronc === true
                  ? 'teamBalanceRetrieveDescriptionTronc'
                  : 'teamBalanceRetrieveDescription'}
              </Text>
            </IonRow>
          </IonCol>
          <IonRow className='ion-justify-content-between ion-align-items-center mt-12 ion-padding'>
            <Button
              fill='clear'
              color='medium'
              onClick={() => {
                setShowDownloadBalance(false)
              }}
            >
              cancel
            </Button>
            <Button
              color='primary'
              routerLink={handleDownloadTips()}
              routerDirection='root'
              onClick={() => {
                setShowDownloadBalance(false)
              }}
            >
              confirm
            </Button>
          </IonRow>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        setShowModal={setShowModal}
        initialBreakpoint={0.65}
        breakpoints={[0.5, 0.75, 1]}
      >
        <div className='md:max-w-md md:mx-auto py-5'>
          <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
            <Text color='dark' className='font-semibold'>
              teamPage.delete.title
            </Text>
            <IonRow className='ion-no-padding ion-justify-content-center ion-text-center mt-16 px-4'>
              <Text>teamPage.delete.description</Text>
            </IonRow>
          </IonCol>
          <IonRow className='ion-justify-content-between ion-align-items-center mt-12 ion-padding'>
            <Button
              fill='clear'
              color='medium'
              onClick={() => {
                setShowModal(false)
              }}
            >
              cancel
            </Button>
            <Button
              color='danger'
              onClick={() => {
                handleDelete()
              }}
            >
              confirm
            </Button>
          </IonRow>
        </div>
      </Modal>
    </>
  )
}
