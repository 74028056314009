import CollaboratorRemoved from '#components/common/CollaboratorRemoved'
import ErrorBox from '#components/errors/ErrorBox'
import HomeCompanyBalance from '#components/pages/home/HomeCompanyBalance'
import HomeQrCodeSection from '#components/pages/home/HomeQrCodeSection'
import HomeTeamSection from '#components/pages/home/HomeTeamSection'
import BannerComponents from '#components/ui/BannerComponents'
import ModalLoading from '#components/ui/ModalLoading'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useUserContext } from '#contexts/UserContext'
import useFCMToken from '#hooks/useFCMToken'
import { useEffect } from 'react'

export default function HomeCollaborator(): JSX.Element {
  const { collaborator, isLoading, error } = useCollaboratorContext()

  const { user } = useUserContext()

  const { getToken, setTokenOnServer } = useFCMToken()

  const handleGetFCMToken = async (): Promise<void> => {
    try {
      const token = await getToken()
      if (token != null) {
        await setTokenOnServer(user?.subscriber?.id ?? '', token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      (user?.subscriber?.device_tokens == null ||
        user?.subscriber?.device_tokens?.length <= 0) &&
      user != null
    ) {
      void handleGetFCMToken()
    }
  }, [user?.subscriber?.device_tokens])

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  if (collaborator == null) return <CollaboratorRemoved />

  return (
    <>
      <BannerComponents />
      {collaborator?.role === 'admin' && <HomeCompanyBalance />}
      <HomeTeamSection
        category='collaborator'
        jars={collaborator?.jars}
        isLoading={isLoading}
        error={error}
      />
      <HomeQrCodeSection
        jars={collaborator?.jars}
        company={
          collaborator?.role === 'admin' ? collaborator.company : undefined
        }
      />
    </>
  )
}
