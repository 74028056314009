import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import AddTeamInfoStep from './views/AddTeamInfoStep'
import AddTeamRole from './views/AddTeamRole'
import AddTeamCompany from './views/AddTeamCompany'
import AddTeamIban from './views/AddTeamIban'
import AddTeamReviews from './views/AddTeamReview'
import AddTeamPersonalData from './views/AddTeamPersonalData'
import AddTeamAddress from './views/AddTeamAddress'
import AddTeamNewIban from './views/AddTeamNewIban'
import AddTeamTippingRule from './views/AddTeamTippingRule'
import AddTeamSelectIban from './views/AddTeamSelectIban'

const teamBasePath = '/dashboard/:category/jars/add'

export default function AddTeamRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={teamBasePath}>
          <AddTeamInfoStep />
        </Route>
        <Route exact path={`${teamBasePath}/roles`}>
          <AddTeamRole />
        </Route>
        <Route exact path={`${teamBasePath}/companies`}>
          <AddTeamCompany />
        </Route>
        <Route exact path={`${teamBasePath}/ibans`}>
          <AddTeamIban />
        </Route>
        <Route exact path={`${teamBasePath}/reviews`}>
          <AddTeamReviews />
        </Route>
        <Route exact path={`${teamBasePath}/persons`}>
          <AddTeamPersonalData />
        </Route>
        <Route exact path={`${teamBasePath}/addresses`}>
          <AddTeamAddress />
        </Route>
        <Route exact path={`${teamBasePath}/ibans/add`}>
          <AddTeamNewIban />
        </Route>
        <Route exact path={`${teamBasePath}/tipping-rules`}>
          <AddTeamTippingRule />
        </Route>
        <Route exact path={`${teamBasePath}/select-ibans`}>
          <AddTeamSelectIban />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
