import AppLayout from '#components/layouts/app/AppLayout'
import PayoutBalanceSection from '#components/pages/payouts/balance/PayoutBalanceSection'
import PayoutHistorySection from '#components/pages/payouts/hyistory/PayoutHistorySection'
import Title from '#components/ui/Title'
import { useUserContext } from '#contexts/UserContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type UserCategory } from '#tackpay-sdk'
import { useParams } from 'react-router'

interface Props {
  category: UserCategory
}

function HandlePayout({ category }: Props): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  return (
    <>
      <PayoutBalanceSection
        category={category}
        addBankHref={`/dashboard/${category}/wallets/add/banks`}
        balance={user?.balance}
        loading={isLoading}
        error={error}
        bankAccount={user?.bank_account}
        userId={user?.id}
      />
      <PayoutHistorySection userId={user?.id} />
    </>
  )
}

export default function PayoutsTipped(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/wallets`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person', 'bank_account', 'balance']
        }
      }}
    >
      <Title>payout.title</Title>
      <HandlePayout category={category} />
    </AppLayout>
  )
}
