import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'
import AppLayout from '#components/layouts/app/AppLayout'
import LanguageSetting from '#components/pages/languages/LanguageSetting'

export default function Language(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: { defaultHref: `/dashboard/${category}/settings` }
      }}
    >
      <Title hasSubtitle>language.title</Title>
      <SubTitle>language.subtitle</SubTitle>
      <LanguageSetting />
    </AppLayout>
  )
}
