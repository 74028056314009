import type Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar, Thumbs } from 'swiper'
import { Swiper as SwiperView, SwiperSlide } from 'swiper/react'
import { useState } from 'react'
import { IonCol, IonRow } from '@ionic/react'
import Avatar from '#components/ui/Avatar'
import { type CheckoutSetting, type Jar } from '#tackpay-sdk'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import Text from '#components/ui/Text'

interface Props {
  jars?: Jar[] | null
  checkoutSetting?: CheckoutSetting
}

export default function SlidesAvatarTeams({
  jars,
  checkoutSetting
}: Props): JSX.Element {
  const [currentTeam, setCurrentTeam] = useState<Jar | undefined>()

  function selectTip(swiper: Swiper): void {
    if (swiper.activeIndex === 0) {
      setCurrentTeam(jars?.[0])
    } else {
      const jar = jars?.[swiper.activeIndex - 1]
      setCurrentTeam(jar)
    }
  }

  return (
    <IonCol size='12' className='ion-no-padding space-y-4 md:space-y-6'>
      <IonRow className='w-full ion-no-padding ion-margin-top'>
        <IonCol className='ion-no-padding ion-no-margin' size='12'>
          <IonRow className='ion-no-margin ion-no-padding ion-justify-content-center'>
            <SwiperView
              modules={[Navigation, Scrollbar, Pagination, Thumbs]}
              className='w-full'
              spaceBetween={20}
              slidesPerView={3}
              centeredSlides={jars?.length != null && jars.length < 3}
              initialSlide={0}
              onSlideChange={(Swiper) => {
                selectTip(Swiper)
              }}
            >
              {jars?.map((jar, index) => (
                <SwiperSlide key={index}>
                  <IonCol size='4'>
                    <Avatar
                      avatarOptions={{
                        className: `border-4 mx-auto h-24 w-24 ${
                          currentTeam?.id === jar.id
                            ? 'border-green-400'
                            : 'border-gray-400'
                        }`
                      }}
                      imgOptions={{
                        src: jar?.avatar ?? DEFAULT_IMAGES.logoTeam,
                        alt: 'Avatar tipped'
                      }}
                    />
                    <Text className='w-full py-3 text-center'>{jar.name}</Text>
                  </IonCol>
                </SwiperSlide>
              ))}
            </SwiperView>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonCol>
  )
}
