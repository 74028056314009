import { type IonContent } from '@ionic/react'
import { type ComponentProps } from 'react'

interface AuthContentProps extends ComponentProps<typeof IonContent> {}

export default function AuthContent(props: AuthContentProps): JSX.Element {
  return (
    <section className='md:rounded-md md:card-auth-shadow md:mx-auto md:max-w-2xl md:p-6 content-auth max-z-index md:bg-gray-50  md: px-5 mb-20'>
      {props.children}
    </section>
  )
}
