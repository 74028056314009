import isDesktop from '#utils/isDesktop'
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  type RefresherEventDetail
} from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { type ComponentProps } from 'react'

interface AppContentProps extends ComponentProps<typeof IonContent> {}
export default function AppContent(props: AppContentProps): JSX.Element {
  const queryClient = useQueryClient()

  const ionRouter = useIonRouter()

  const appContentClass = classNames(props.className, 'ion-padding')

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>): void => {
    queryClient
      .refetchQueries({ type: 'active' })
      .then(() => {
        console.log('refetchQueries success')
        e.detail.complete()
      })
      .catch((error) => {
        console.log('invalidateQueries error', error)
        e.detail.complete()
      })
  }

  const checkTutorials = ionRouter.routeInfo.pathname.includes('tutorials')

  const controlTutorialDesktop = isDesktop() && !checkTutorials

  const controlViewDesktop = controlTutorialDesktop
    ? 'w-10/12 absolute top-0 right-0 h-full px-10 pt-20'
    : 'mx-auto max-w-screen-md'

  return (
    <IonContent {...props} className={appContentClass} scrollY={props?.scrollY}>
      <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      <section className={controlViewDesktop}>{props.children}</section>
    </IonContent>
  )
}
