import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import classNames from 'classnames'
import { checkmarkDoneCircleOutline } from 'ionicons/icons'
import { type ComponentProps } from 'react'

interface Props {
  /**
   * The options for the card component
   */
  cardProps?: ComponentProps<typeof Card>
  /**
   * The options for the avatar component
   */
  options: ComponentProps<typeof Avatar>
  /**
   * The options for the label component
   */
  labelOptions?: ComponentProps<typeof Label>
  /**
   * The options for the text component
   */
  textOptions?: Omit<ComponentProps<typeof Text>, 'children'>
  /**
   * The title of the category
   */
  title: string
  /**
   * The description of the category
   */
  description?: string
  /**
   * The options for the check icon
   * @default false
   */
  showCheckIcon?: boolean

  showCustomIcon?: boolean

  icon?: React.ReactNode
}

export default function CardCategory(props: Props): JSX.Element {
  const {
    options,
    description,
    title,
    cardProps,
    labelOptions,
    textOptions,
    showCheckIcon = false,
    showCustomIcon = false,
    icon
  } = props

  const descriptionSectionClass =
    description != null
      ? 'ion-justify-content-start ml-5'
      : 'ion-align-self-center ml-12'

  const baseClass = 'cursor-pointer'

  const cardClassName = classNames(cardProps?.className, baseClass)

  return (
    <Card
      {...cardProps}
      className={cardClassName}
      data-testid={`card-${title}`}
    >
      <IonRow className='ion-align-items-center'>
        <Avatar
          avatarOptions={options?.avatarOptions}
          imgOptions={options?.imgOptions}
          testId={`img-${title}`}
          shadow={false}
        />
        <IonCol
          className={descriptionSectionClass}
          size={description != null ? '8' : '6'}
        >
          <Label
            color='gray-900'
            className='font-bold text-xl ion-text-center'
            data-testid={`label-${title}`}
            capitalize={labelOptions?.capitalize}
            translationOptions={{ translate: 'yes' }}
          >
            {title}
          </Label>
          <Text {...textOptions} size='text-sm' data-testid={description}>
            {description}
          </Text>
        </IonCol>
        {showCheckIcon && (
          <IonIcon
            src={checkmarkDoneCircleOutline}
            size='large'
            color='primary'
          />
        )}
        {showCustomIcon && icon}
      </IonRow>
    </Card>
  )
}
