import AppLayout from '#components/layouts/app/AppLayout'
import CardIdentity from '#components/pages/identities/CardIdentity'
import IdentityButton from '#components/pages/identities/IdentityButton'
// import ProfileTypeSelector from '#components/pages/identities/ProfileTypeSelectior'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

interface Props {
  jarId: string
}
function HandleCardIdentity({ jarId }: Props): JSX.Element {
  const { isLoading, error, jar } = useTeamContext()

  return (
    <>
      <CardIdentity
        isLoading={isLoading}
        error={error}
        identity={jar?.stripe_gateway_account?.identity}
      />
      <IdentityButton
        isLoading={isLoading}
        error={error}
        identity={jar?.stripe_gateway_account?.identity}
        stripeGatewayAccountId={jar?.stripe_gateway_account?.id}
      />
    </>
  )
}
interface PageProps {
  isDeduction?: boolean
}
export default function TeamIdentity({
  isDeduction = false
}: PageProps): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  const backUrl = isDeduction
    ? `/dashboard/${category}/jars/${jarId}/settings/deductions`
    : `/dashboard/${category}/jars/${jarId}/settings`

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: backUrl
        }
      }}
      teamProps={{
        fetch: true,
        params: {
          include: ['stripe_gateway_account.identity']
        }
      }}
    >
      <Title hasSubtitle>identity.title</Title>
      <SubTitle>identity.subtitle</SubTitle>
      <div className='space-y-6'>
        {/* <ProfileTypeSelector /> */}
        <HandleCardIdentity jarId={jarId} />
      </div>
    </AppLayout>
  )
}
