import AppLayout from '#components/layouts/app/AppLayout'
import ResetPasswordSection from '#components/pages/resetPassword/ResetPasswordSection'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'

export default function ResetPasswordPage(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showNotificationsButton: false,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
    >
      <Title>resetPasswordTitle</Title>
      <ResetPasswordSection />
    </AppLayout>
  )
}
