import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { addOutline } from 'ionicons/icons'
import { IonRow } from '@ionic/react'
import MemberSectionList from '#components/pages/members/MemberSectionList'
import Button from '#components/ui/Button'
import TeamTitle from '#components/pages/teams/TeamTitle'
import MemberContainer from '#contexts/MemberContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'

export default function TeamMembers(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showCustomIcon: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
        // customIconOptions: {
        //   icon: (
        //     <CoverIcon
        //       iconOptions={{ icon: settingsOutline, className: 'w-5 h-5' }}
        //       rounded='rounded-full'
        //       className='cursor-pointer'
        //       testId='icon-settings'
        //       onClick={() => {
        //         push(`/dashboard/${category}/jars/${jarId}/settings`)
        //       }}
        //     />
        //   )
        // }
      }}
      teamProps={{
        fetch: true
      }}
    >
      <IonRow className='ion-justify-content-between ion-align-items-center ion-no-padding ion-no-margin'>
        <div className='w-8/12'>
          <TeamTitle showRole={false} />
        </div>
        <div className='w-4/12 pt-1'>
          <Button
            type='button'
            iconOptions={{ icon: addOutline }}
            routerDirection='root'
            routerLink={`/dashboard/${category}/jars/${jarId}/members/add/new`}
            size='small'
            className='w-full ion-no-padding ion-no-margin'
          >
            add_member
          </Button>
        </div>
      </IonRow>
      <MemberContainer fetch={false}>
        <MemberSectionList jarId={jarId} category={category} />
      </MemberContainer>
    </AppLayout>
  )
}
