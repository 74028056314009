import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type Member } from '#tackpay-sdk'
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonRow
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface Props {
  members: Member[]
  currentMemberId: string
  jarId: string
}
export default function OtherMemberCard({
  members,
  currentMemberId,
  jarId
}: Props): JSX.Element {
  const { t } = useTranslation()

  const MAX_MEMBERS_VIEWS = 5

  const filteredMembers = members.filter(
    (member) => member.id !== currentMemberId
  )

  return (
    <Card>
      <IonCardHeader className='w-full'>
        <IonCardTitle>{t('tipped_jar_page_jar_members')}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='ion-no-margin ion-no-padding'>
        <IonRow className='ion-align-items-center mt-5 space-x-5'>
          {filteredMembers.slice(0, MAX_MEMBERS_VIEWS).map((member) => (
            <div key={member.id}>
              <Avatar
                imgOptions={{
                  src: member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
                }}
              />
              <Text className='ion-text-center' color='gray-900'>
                {member?.user?.person?.first_name ?? member?.user?.email ?? ''}
              </Text>
            </div>
          ))}
        </IonRow>
        {filteredMembers.length > MAX_MEMBERS_VIEWS && (
          <IonRow className='ion-justify-content-center ion-items-center'>
            <Button
              className='underline'
              fill='clear'
              color='gray-900'
              routerDirection='root'
              href={`/dashboard/tipped/members/${currentMemberId}/jars/${jarId}`}
              routerLink={`/dashboard/tipped/members/${currentMemberId}/jars/${jarId}`}
            >
              + {filteredMembers.length - MAX_MEMBERS_VIEWS} other
            </Button>
          </IonRow>
        )}
        {filteredMembers.length === 0 && (
          <IonRow className='ion-text-center'>
            <Text>member.emptyTipped</Text>
          </IonRow>
        )}
      </IonCardContent>
    </Card>
  )
}
