import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { type UserCategory } from '#tackpay-sdk'

interface Props {
  show: boolean
  category: UserCategory
  setShow: (show: boolean) => void
  jarId?: string
}
export default function PayoutIdentityVerification({
  show,
  jarId,
  category,
  setShow
}: Props): JSX.Element {
  const verificationIdentityUrl =
    jarId != null
      ? `/dashboard/${category}/jars/${jarId}/settings/identities`
      : `/dashboard/${category}/settings/identities`
  return (
    <Modal
      isOpen={show}
      setShowModal={setShow}
      initialBreakpoint={0.5}
      breakpoints={[0.5, 0.7, 1]}
    >
      <div className='ion-padding'>
        <Text
          className='w-full ion-text-center'
          fontWeight='font-semibold'
          color='dark'
        >
          verifyIdentityPayout
        </Text>
        <div className='pt-10'>
          <Text className='w-full ion-text-center'>
            verifyIdentityPayoutDetails
          </Text>
        </div>
        <div className='pt-10'>
          <Button
            className='w-full'
            routerLink={verificationIdentityUrl}
            routerDirection='root'
          >
            verifyIdentityPayoutButton
          </Button>
        </div>
      </div>
    </Modal>
  )
}
