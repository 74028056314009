import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useCompanyContext } from '#contexts/CompanyContext'
import { Fragment } from 'react'
import CardJarDeduction from './CardJarDeduction'
import { type UserCategory } from '#tackpay-sdk'

interface Props {
  category: UserCategory
}
export default function JarDeductionList({ category }: Props): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  if (isLoading) return <Skeleton />

  if (error != null) return <ErrorBox error={error} />

  if (company == null) return <div>No company found</div>

  return (
    <div className='space-y-5'>
      {company.jars?.map((jar) => {
        return (
          <Fragment key={jar.id}>
            {jar.balance_deduction != null && (
              <CardJarDeduction jar={jar} category={category} />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
