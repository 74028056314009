import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Comment } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import { IonCardHeader, IonCardTitle, IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface Props {
  comment: Comment
}
export default function CardComment({ comment }: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <Card padding='ion-padding'>
      <Text size='text-xl' fontWeight='font-semibold' color='gray-900'>
        {t('comments')}
      </Text>
      <IonRow className='pl-7 pt-1 ion-align-items-center'>
        <Text
          size='text-xs'
          translationOptions={{
            translate: 'no'
          }}
        >
          {`${formattedDate(
            comment.payment?.created_at ?? comment.created_at ?? new Date()
          )} - ${formattedAmount(
            comment.payment?.amount_cents ?? 0,
            comment?.payment?.currency
          )}`}
        </Text>
      </IonRow>
      <div className='ion-padding ion-no-margin'>
        <Text
          translationOptions={{
            translate: comment?.description?.trim() === '' ? 'yes' : 'no'
          }}
        >
          {comment?.description?.trim() === ''
            ? 'noComment'
            : comment.description}
        </Text>
      </div>
      {comment?.customer_name != null && comment?.customer_name !== '' && (
        <>
          <IonCardHeader>
            <IonCardTitle>👤 From</IonCardTitle>
          </IonCardHeader>
          <div className='ion-padding ion-margin'>
            <Text
              translationOptions={{
                translate: 'no'
              }}
            >
              {comment?.customer_name}
            </Text>
          </div>
        </>
      )}
    </Card>
  )
}
