import { IonCol, IonContent, IonPopover } from '@ionic/react'
import { type CheckoutPaymentMethodType } from './CheckoutCardPaymentMethods'
import Text from '#components/ui/Text'
import Card from '#components/ui/Card'
import { useState } from 'react'

interface Props {
  currentMethods: CheckoutPaymentMethodType
  setCurrentMethods: (method: CheckoutPaymentMethodType) => void
}

export default function CheckoutPopoverMethods({
  currentMethods,
  setCurrentMethods
}: Props): JSX.Element {
  const [show, setShow] = useState(false)

  const methods: CheckoutPaymentMethodType[] = [
    'bankcontact',
    'card',
    'eps',
    // 'giropay',
    'ideal',
    // 'multibanco',
    'p24',
    'sofort'
  ]

  const handleSelectMethod = (method: CheckoutPaymentMethodType): void => {
    setCurrentMethods(method)
    setShow(false)
  }

  return (
    <>
      <Card
        color='white'
        className='w-2/6 py-[1.55rem]'
        onClick={() => {
          setShow(true)
        }}
        id='popover-methods'
      >
        <Text
          color='gray-500'
          className='ion-text-center capitalize'
          translationOptions={{
            translate: 'no'
          }}
        >
          other
        </Text>
      </Card>

      <IonPopover
        trigger='popover-methods'
        side='left'
        arrow={false}
        isOpen={show}
        onDidDismiss={() => {
          setShow(false)
        }}
      >
        <IonContent color='light'>
          {methods
            .filter((m) => m !== currentMethods)
            .map((method) => (
              <IonCol className='block p-3' key={method}>
                <Text
                  color='gray-900'
                  className='capitalize'
                  size='text-base'
                  onClick={() => {
                    handleSelectMethod(method)
                  }}
                >
                  {method === 'card' ? 'card.name' : method}
                </Text>
              </IonCol>
            ))}
        </IonContent>
      </IonPopover>
    </>
  )
}
