import AppLayout from '#components/layouts/app/AppLayout'
import ListLink from '#components/ui/ListLink'
import Title from '#components/ui/Title'
import { getWithdrawalSettings } from '#constants/withdrawals'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useMemo } from 'react'
import { useParams } from 'react-router'

export default function Withdrawals(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const withdrawalSettings = getWithdrawalSettings(category)

  useMemo(() => {
    return withdrawalSettings
  }, [withdrawalSettings])

  return (
    <AppLayout
      headerOptions={{
        showBackButton: false,
        showMenuButton: true
      }}
    >
      <Title>withdrawal.title</Title>
      <ListLink items={withdrawalSettings} />
    </AppLayout>
  )
}
