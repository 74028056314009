import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import TeamMaterial from './views/TeamMaterial'
import TeamMaterialDownload from './views/TeamMaterialDownload'
import TeamMaterialKit from './views/TeamMaterialKit'
import TeamMaterialAssign from './views/TeamMaterialAssign'

const baseRoute = `/dashboard/:category/jars/:jarId/settings/materials`
export default function TeamMaterialRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={baseRoute}>
          <TeamMaterial />
        </Route>
        <Route exact path={baseRoute + '/downloads'}>
          <TeamMaterialDownload />
        </Route>
        <Route exact path={baseRoute + '/kits'}>
          <TeamMaterialKit />
        </Route>
        <Route exact path={baseRoute + '/assigns'}>
          <TeamMaterialAssign />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
