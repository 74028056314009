import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useMemberContext } from '#contexts/MemberContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type MemberStatus, type Member } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import getErrorMessage from '#utils/getErrorMessage'
import { IonCardTitle, IonCol, IonRow } from '@ionic/react'
import MemberActiveChip from '../members/info/MemberActiveChip'
import { useState } from 'react'

interface Props {
  member: Member
}
export default function TeamCardTipped({ member }: Props): JSX.Element {
  const { deleteMember, updateMember } = useMemberContext()

  const hiddenTotalCompany = ['1bf77f69-8089-4495-8b28-410a00cf82e0']

  const [showButtons, setShowButtons] = useState<{
    status: boolean
    accepted: boolean
  }>({
    status: member.status === 'invitation_pending',
    accepted: member.status === 'invitation_accepted'
  })

  const { showToast } = useToast()

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('member.response.loading')

  const handleResponse = (status: MemberStatus): void => {
    if (status === 'invitation_rejected') {
      if (deleteMember != null) {
        startLoading()
        deleteMember.mutate(member.id, {
          onSuccess: () => {
            stopLoading()
            showSuccess(undefined, 'member.response.rejected')
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        })
        setShowButtons({ status: false, accepted: false })
      }
    } else if (status === 'invitation_accepted') {
      if (updateMember != null) {
        startLoading()
        updateMember.mutate(
          {
            id: member.id,
            status: 'invitation_accepted'
          },
          {
            onSuccess: () => {
              stopLoading()
              showSuccess(undefined, 'member.response.accepted')
            },
            onError: (error) => {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
        setShowButtons({ status: false, accepted: true })
      }
    }
  }

  const justifyCenterCoantainer = showButtons.status
    ? 'ion-justify-content-center'
    : ''

  const justifyCenterText = showButtons.status
    ? 'ion-justify-content-center'
    : 'ion-justify-content-start'

  return (
    <Card
      routerLink={
        member.status === 'invitation_pending'
          ? undefined
          : `/dashboard/tipped/members/${member.id}`
      }
      routerDirection='root'
      href={
        member.status === 'invitation_pending'
          ? undefined
          : `/dashboard/tipped/members/${member.id}`
      }
      className='cursor-pointer px-2 py-4 ion-no-margin'
    >
      <IonRow
        className={`ion-align-items-center ${justifyCenterCoantainer} ion-no-padding ion-no-margin`}
      >
        <IonCol size='2.5' className='ion-no-padding ion-no-margin'>
          <Avatar
            avatarOptions={{
              className: 'w-14 h-14'
            }}
            imgOptions={{
              src: member?.jar?.avatar ?? DEFAULT_IMAGES.logoTeam,
              alt: member?.jar?.name ?? 'teamAvatar'
            }}
          />
        </IonCol>
        <IonCol className='ion-no-padding ion-no-margin' size='7'>
          <IonRow className={`w-full ${justifyCenterText}`}>
            <IonCardTitle
              className='text-lg'
              data-testid={`card-jar-${member?.jar?.name ?? ''}`}
            >
              {member?.jar?.name}
            </IonCardTitle>
          </IonRow>

          <IonRow className='ion-no-padding ion-no-margin w-full'>
            <IonRow
              className={`${justifyCenterText} ion-align-items-center space-x-4 mt-1 w-full`}
            >
              {!hiddenTotalCompany.includes(member?.jar?.company?.id ?? '') && (
                <IonRow className='space-x-2'>
                  <Text translationOptions={{ translate: 'no' }} size='text-sm'>
                    💰
                  </Text>
                  <Text
                    translationOptions={{ translate: 'no' }}
                    fontWeight='font-semibold'
                    size='text-sm'
                    data-testid={`amount-${member?.jar?.name ?? ''}`}
                  >
                    {formattedAmount(
                      member?.jar?.balance?.total?.amount_cents,
                      member?.jar?.currency
                    )}
                  </Text>
                </IonRow>
              )}
              <IonRow className='space-x-2'>
                <Text translationOptions={{ translate: 'no' }} size='text-sm'>
                  🏅
                </Text>
                <Text
                  translationOptions={{ translate: 'no' }}
                  fontWeight='font-semibold'
                  size='text-sm'
                >
                  {member?.jar?.total_feedback}
                </Text>
              </IonRow>
              <IonRow className='space-x-2'>
                <Text translationOptions={{ translate: 'no' }} size='text-sm'>
                  💬
                </Text>
                <Text
                  translationOptions={{ translate: 'no' }}
                  fontWeight='font-semibold'
                  size='text-sm'
                  data-testid={`total_comment-${member?.jar?.name ?? ''}`}
                >
                  {member?.jar?.total_comment}
                </Text>
              </IonRow>
            </IonRow>
          </IonRow>
        </IonCol>
        {!showButtons.status && (
          <IonCol size='2.3' className='ion-no-padding ion-no-margin'>
            <MemberActiveChip active={member.active} />
          </IonCol>
        )}
      </IonRow>

      {showButtons.status && (
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <Button
            fill='clear'
            color='danger'
            onClick={() => {
              handleResponse('invitation_rejected')
            }}
          >
            reject
          </Button>
          <Button
            fill='clear'
            color='primary'
            onClick={() => {
              handleResponse('invitation_accepted')
            }}
          >
            accept
          </Button>
        </IonRow>
      )}
    </Card>
  )
}
