import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Title from '#components/ui/Title'
import { useUserContext } from '#contexts/UserContext'

export default function HomeTitle(): JSX.Element {
  const { user, isLoading, error } = useUserContext()
  if (isLoading) return <Skeleton className='w-1/2 h-8 mb-8' />
  if (error != null) return <ErrorBox error={error} />
  return (
    <Title
      translationOptions={{
        translate: 'yes',
        data: { firstName: user?.person?.first_name }
      }}
    >
      dashboard.title
    </Title>
  )
}
