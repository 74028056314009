import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'

type PaymentMethodType = 'payment_methods'
type PaymentMethodRel = ResourceRel & { type: PaymentMethodType }

interface CreditCard {
  brand?: string
  brand_product?: string
  country?: string
  fingerprint?: string
  last4?: string
  three_D_supported?: boolean
  wallet_type?: string
}

type PaymentMethodCategoryType =
  | 'acss_debit'
  | 'affirm'
  | 'afterpay_clearpay'
  | 'alipay'
  | 'au_becs_debit'
  | 'bacs_debit'
  | 'bancontact'
  | 'blik'
  | 'boleto'
  | 'card'
  | 'card_present'
  | 'cashapp'
  | 'customer_balance'
  | 'eps'
  | 'fpx'
  | 'giropay'
  | 'grabpay'
  | 'ideal'
  | 'interac_present'
  | 'klarna'
  | 'konbini'
  | 'link'
  | 'oxxo'
  | 'p24'
  | 'paynow'
  | 'paypal'
  | 'pix'
  | 'promptpay'
  | 'sepa_debit'
  | 'sofort'
  | 'us_bank_account'
  | 'wechat_pay'
  | 'multibanco'
  | 'zip'
  | 'unknow'

type PaymentRel = ResourceRel & { type: 'payments' }

interface PaymentMethod extends Resource {
  readonly type: PaymentMethodType

  reference_customer?: string
  meta_data?: Record<string, any>
  card?: CreditCard
  reference?: string
  reference_origin?: string
  method_type: PaymentMethodCategoryType
  active: boolean
  payment?: Payment
}

interface PaymentMethodCreate extends ResourceCreate {
  method_type: PaymentMethodCategoryType
  payment: PaymentRel
  payment_method_reference?: string
}

class PaymentMethods extends ApiResource<PaymentMethod> {
  static readonly TYPE: PaymentMethodType = 'payment_methods' as const

  async create(
    resource: PaymentMethodCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<PaymentMethod> {
    return await this.resources.create<PaymentMethodCreate, PaymentMethod>(
      { ...resource, type: PaymentMethods.TYPE },
      params,
      options
    )
  }

  isPaymentMethod(resource: any): resource is PaymentMethod {
    return resource.type != null && resource.type === PaymentMethods.TYPE
  }

  relationship(id: string | ResourceId | null): PaymentMethodRel {
    return id === null || typeof id === 'string'
      ? { id, type: PaymentMethods.TYPE }
      : { id: id.id, type: PaymentMethods.TYPE }
  }

  type(): PaymentMethodType {
    return PaymentMethods.TYPE
  }
}

export type { PaymentMethod, PaymentMethodCreate }
export default PaymentMethods
