import { ApiError } from '#tackpay-sdk'

export default function getErrorMessage(error: any): string {
  if (ApiError.isApiError(error)) {
    let msg = error?.code ?? error.detail ?? error.detail ?? error.name
    if (msg === 'bad_request' || msg === 'unprocessable_entity') {
      msg = error.detail ?? error.name
    }
    return msg
  } else {
    return (
      error?.code ??
      error?.message ??
      error?.detail ??
      error?.error ??
      error?.name ??
      error?.toString() ??
      'Unknown error'
    )
  }
}
