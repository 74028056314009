import { useIonToast, type ToastOptions } from '@ionic/react'
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
  informationCircleOutline
} from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

type ToastType = 'success' | 'warning' | 'error' | 'default'
export default function useToast(
  type: ToastType = 'default',
  position: ToastOptions['position'] = 'top'
): {
  showToast: (
    header?: string,
    message?: string,
    translateOptions?: { data: Record<string, any> }
  ) => void
  hideToast: () => void
} {
  const { t } = useTranslation()

  const [show, close] = useIonToast()

  const handleShowToast = (
    header?: string,
    message?: string,
    translateOptions?: { data: Record<string, any> }
  ): void => {
    void show({
      header: t(header ?? type),
      message: t(message ?? type, { ...translateOptions?.data }),
      duration: 3000,
      cssClass: type,
      id: type,
      position,
      icon: getIcon(),
      buttons: [
        {
          role: 'cancel',
          icon: closeOutline,
          handler: () => {
            void close()
          }
        }
      ]
    })
  }

  const handleHideToast = (): void => {
    void close()
  }

  const getIcon = (): string => {
    switch (type) {
      case 'success':
        return checkmarkCircleOutline
      case 'warning':
        return alertCircleOutline
      case 'error':
        return closeCircleOutline
      default:
        return informationCircleOutline
    }
  }

  return {
    showToast: handleShowToast,
    hideToast: handleHideToast
  }
}
