import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Chip from '#components/ui/Chip'
import Input, { type InputOnChange } from '#components/ui/Input'
import InputTextArea, {
  type InputTextAreaOnChange
} from '#components/ui/InputTextArea'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { FEEDBACKS } from '#constants/feedbacks'
import { type CheckoutSession } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import textUtil from '#utils/textUtil'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
  checkout: CheckoutSession
}
export default function CheckoutReviewContent({
  checkout
}: Props): JSX.Element {
  const customFeedback = ['needs', 'tips', 'top_quality', 'tips_home']

  const checkoutSetting =
    checkout?.jar?.checkout_setting ?? checkout?.user?.checkout_setting

  const category =
    checkoutSetting?.jar?.category ??
    checkoutSetting?.user?.category ??
    undefined

  const [comment, setComment] = useState('')

  const [customerName, setCustomerName] = useState<string | undefined>()

  const [feedbackSelected, setFeedbackSelected] = useState<string[]>([])

  const { push } = useIonRouter()

  const feedbacksBySubcategory = FEEDBACKS.filter(
    (feed) => feed.subcategory === category
  )
    .map((feed) => feed.feedback)
    .flat()

  const feedbackByOther = FEEDBACKS.filter(
    (feed) => feed.subcategory === 'other'
  )
    .map((feed) => feed.feedback)
    .flat()

  let feedbacks =
    feedbacksBySubcategory.length > 0 ? feedbacksBySubcategory : feedbackByOther

  if (
    checkout?.jar?.company?.id === '9c86881d-23f5-4cf4-9716-c7257ed4a914' ||
    checkout?.company?.id === '9c86881d-23f5-4cf4-9716-c7257ed4a914'
  ) {
    feedbacks = customFeedback
  }

  const handleFeedbackSelected = (feedback: string): void => {
    if (feedbackSelected.includes(feedback)) {
      setFeedbackSelected(feedbackSelected.filter((feed) => feed !== feedback))
    } else {
      setFeedbackSelected([...feedbackSelected, feedback])
    }
  }

  const handleComment: InputTextAreaOnChange = (e) => {
    const { value } = e.target
    if (typeof value === 'string') setComment(value)
  }

  const customerNameChange: InputOnChange = (e) => {
    const value = e.target.value
    if (typeof value === 'string') setCustomerName(value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (feedbackSelected.length > 0) {
      for (const feedback of feedbackSelected) {
        sdk.feedbacks
          .create({
            name: feedback,
            category:
              checkout?.payment?.reference_review === 'jar'
                ? 'jar'
                : 'individual',
            payment: {
              id: checkout?.payment?.id ?? '',
              type: 'payments'
            }
          })
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }

    if (comment != null && !textUtil.isEmpty(comment)) {
      sdk.comments
        .create({
          description: comment,
          customer_name: customerName,
          category:
            checkout?.payment?.reference_review === 'jar'
              ? 'jar'
              : 'individual',
          payment: {
            id: checkout?.payment?.id ?? '',
            type: 'payments'
          }
        })
        .then((res) => {
          console.log(res)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    push(
      `/checkout/${checkout.tackpay_id?.value ?? ''}/${checkout.id}/bills`,
      'root'
    )
  }

  const getName = (): string => {
    if (checkout?.jar != null) return checkout?.jar?.name
    return checkout?.user?.person?.first_name ?? ''
  }

  return (
    <form onSubmit={handleSubmit}>
      <IonRow className='ion-justify-content-center ion-align-items-center ion-no-padding ion-no-margin'>
        <IonCol>
          <Avatar
            avatarOptions={{
              className: ' border-4 mx-auto h-28 w-28 border-green-400'
            }}
            imgOptions={{
              src: checkoutSetting?.avatar ?? DEFAULT_IMAGES.logoTeam
            }}
          />
          <Underlined name={checkoutSetting?.username ?? getName()} />
        </IonCol>
      </IonRow>
      <Text fontWeight='font-semibold' className='mt-10 '>
        checkout.leaveFeedbackTeam
      </Text>
      <IonRow className='mt-5'>
        <Swiper spaceBetween={0} slidesPerView={3}>
          {feedbacks.map((feedback, i) => {
            if (i < 8) {
              const isActive = feedbackSelected.includes(feedback)
              return (
                <SwiperSlide key={feedback}>
                  <Chip
                    color={isActive ? 'green-500' : 'white'}
                    onClick={() => {
                      handleFeedbackSelected(feedback)
                    }}
                    className={`shadow ${isActive ? '' : 'text-gray-900 '}`}
                  >
                    {`feedback.${feedback}`}
                  </Chip>
                </SwiperSlide>
              )
            } else {
              return null
            }
          })}
        </Swiper>
      </IonRow>
      <IonRow className='mt-5'>
        <Swiper spaceBetween={0} slidesPerView={3}>
          {feedbacks.map((feedback, i) => {
            if (i >= 8) {
              const isActive = feedbackSelected.includes(feedback)
              return (
                <SwiperSlide key={feedback}>
                  <Chip
                    color={isActive ? 'green-500' : 'white'}
                    onClick={() => {
                      handleFeedbackSelected(feedback)
                    }}
                    className={`shadow ${isActive ? '' : 'text-gray-900 '}`}
                  >
                    {`feedback.${feedback}`}
                  </Chip>
                </SwiperSlide>
              )
            } else {
              return null
            }
          })}
        </Swiper>
      </IonRow>
      <IonRow>
        <IonCol>
          <Text fontWeight='font-semibold' className='mt-10 ' size='text-sm'>
            checkout.leaveComment
          </Text>
          <InputTextArea
            name='feedback'
            rows={8}
            onIonInput={handleComment}
            value={comment}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <Input
          labelOptions={{
            children: 'checkout.leaveName',
            capitalize: false,
            className: 'font-semibold mb-5 text-leaveName'
          }}
          value={customerName}
          onIonInput={customerNameChange}
          name='customerName'
        />
      </IonRow>
      <IonRow className='mt-10 w-full mx-0 ion-no-padding'>
        <Button type='submit' className='w-full ion-no-margin'>
          continue
        </Button>
      </IonRow>
    </form>
  )
}
