import Text from '#components/ui/Text'
import { IonCol } from '@ionic/react'
import CardMaterial from '../materials/CardMaterial'
import { cloudDownloadOutline, mailOutline } from 'ionicons/icons'
import sdk from '#utils/sdk'
import ErrorBox from '#components/errors/ErrorBox'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { useCompanyContext } from '#contexts/CompanyContext'
import CardSkeleton from '#components/common/CardSkeleton'
import { type Material, type MaterialActionType } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { isPlatform } from '@ionic/core'
import useFile from '#hooks/useFile'

export default function CheckoutSettingMaterial(): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('material.loading')

  const { downloadFiles, toZip, downloadFile } = useFile()

  if (isLoading)
    return (
      <div className='space-y-10'>
        <CardSkeleton />
        <CardSkeleton />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (company == null) return <ErrorBox error='jar.error.notFound' />

  const handleDownload = async (material: Material): Promise<void> => {
    const result = await downloadFiles(material.storage_path ?? '')
    if (isPlatform('hybrid')) {
      for (const item of result.items) {
        await downloadFile(
          item.path,
          `material_${company?.tackpay_id?.value ?? ''}`
        )
      }
    } else {
      const zip = await toZip(result)

      const zipUrl = URL.createObjectURL(zip)
      const link = document.createElement('a')
      link.href = zipUrl
      link.download = `materials_${company?.tackpay_id?.value ?? ''}.zip` // Specifica il nome del file ZIP da scaricare

      // // Simula un clic sul link
      link.click()

      // // Rimuovi il link
      URL.revokeObjectURL(zipUrl)
    }
  }

  const downloadMaterialTeam = (actionType: MaterialActionType): void => {
    startLoading()

    sdk.materials
      .create({
        tackpay_id: {
          id: company?.tackpay_id?.id ?? '',
          type: 'tackpay_ids'
        },
        category: 'company',
        action_type: actionType,
        company: {
          id: company?.id ?? '',
          type: 'companies'
        }
      })
      .then((res) => {
        if (actionType === 'download') {
          if (actionType === 'download') {
            handleDownload(res)
              .then(() => {
                stopLoading()
                showSuccess(undefined, 'material.download.success')
              })
              .catch((error) => {
                console.log('error during download material', error)
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              })
          } else {
            stopLoading()
            showSuccess(undefined, 'material.email.success')
          }
        } else {
          stopLoading()
          showSuccess(undefined, 'material.email.success')
        }
      })
      .catch((error) => {
        stopLoading()
        console.log('error', error)
        showToast(undefined, getErrorMessage(error))
      })
  }
  return (
    <div slot='content' className='pt-1 space-y-4'>
      <Text size='text-base' data-testid='subtitle-socials'>
        checkoutSetting.material.subtitle
      </Text>
      <IonCol className='block space-y-10'>
        <CardMaterial
          icon={cloudDownloadOutline}
          label='material.download.direct'
          onClick={() => {
            downloadMaterialTeam('download')
          }}
          ml='ml-10'
        />
        <CardMaterial
          icon={mailOutline}
          label='material.download.email'
          onClick={() => {
            downloadMaterialTeam('email')
          }}
          ml='ml-10'
        />
      </IonCol>
    </div>
  )
}
