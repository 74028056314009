import ErrorBox from '#components/errors/ErrorBox'
import AppCustomHeaderIcon from '#components/layouts/app/AppCustomHeaderIcon'
import AppNotificationHeader from '#components/layouts/app/AppNotificationHeader'
import BackButton from '#components/ui/BackButton'
import Label from '#components/ui/Label'
import Skeleton from '#components/ui/Skeleton'
import { useUserContext } from '#contexts/UserContext'
import { IonCol, type IonHeader, IonRow, IonButtons } from '@ionic/react'

import { type ComponentProps } from 'react'

interface AppHeaderProps {
  /**
   * Show menu button.
   */
  showMenuButton?: boolean
  /**
   * The header props.
   */
  ionHeaderOptions?: ComponentProps<typeof IonHeader>
  /**
   * Show back button.
   */
  showBackButton?: boolean
  /**
   * Show Notifications button.
   * @default false
   */
  showNotificationsButton?: boolean
  /**
   * Show settings icon.
   * @default false
   */
  showCustomIcon?: boolean
  /**
   * Back button options
   */
  backButtonProps?: ComponentProps<typeof BackButton>
  /**
   * Settings icon options
   */
  customIconOptions?: ComponentProps<typeof AppCustomHeaderIcon> & {
    slot?: 'end' | 'start'
  }
}

export default function HeaderDesktop(props: AppHeaderProps): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  const {
    // ionHeaderOptions,
    // showMenuButton,
    showNotificationsButton,
    showBackButton,
    showCustomIcon,
    customIconOptions,
    backButtonProps
  } = props

  if (isLoading) return <Skeleton className='w-1/2 h-8 mb-8' />

  if (error != null) return <ErrorBox error={error} />

  return (
    <header
      className='z-50 w-10/12 px-5 absolute right-0 top-0'
      // style={{ background: '#f9fafb' }}
      id='header-desktop'
    >
      <IonRow className='ion-justify-content-between pt-2 w-full ion-align-items-center'>
        <IonCol>
          <IonRow
            className='ion-justify-content-start ion-align-items-center space-x-3'
            id='row-header-desktop'
          >
            {showBackButton === true ? (
              <IonButtons className=''>
                <BackButton {...backButtonProps} />
              </IonButtons>
            ) : (
              <IonRow className='pl-1 pb-2 ion-justify-content-start ion-align-items-center space-x-4'>
                <div className='w-14 h-14 rounded-full shadow text-center flex flex-col justify-center bg-gray-100'>
                  <span className='font-medium text-gray-600 uppercase'>
                    {user?.person?.first_name?.charAt(0)}
                    {user?.person?.last_name?.charAt(0)}
                  </span>
                </div>
                <Label
                  capitalize
                  data-testid={user?.person?.first_name}
                  className='text-3xl font-semibold'
                  color='gray-500'
                >
                  {user?.person?.first_name}
                </Label>
              </IonRow>
            )}
          </IonRow>
        </IonCol>
        <IonCol>
          <IonRow className='ion-justify-content-end'>
            {showNotificationsButton === true && (
              <>
                {user == null ? (
                  <Skeleton />
                ) : (
                  <AppNotificationHeader userId={user?.id} />
                )}
              </>
            )}

            {showCustomIcon === true && customIconOptions != null && (
              <>
                <AppCustomHeaderIcon {...customIconOptions} />
              </>
            )}
          </IonRow>
        </IonCol>
      </IonRow>
    </header>
  )
}
