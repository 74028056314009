import { type QueryParamsRetrieve } from 'sdk/query'
import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Jar } from './jars'
import { type Company } from './companies'
import { type User } from './users'

type BankAccountType = 'bank_accounts'
type BankAccountRel = ResourceRel & { type: BankAccountType }

type BankAccountHolderType = 'individual' | 'jar'

type BankStatus = 'new' | 'verified' | 'verification_failed' | 'errored'

type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }

interface BankAccount extends Resource {
  readonly type: BankAccountType
  last_account_number: string
  routing_number?: string
  name?: string
  account_holder_type: BankAccountHolderType
  account_holder_name?: string
  active: boolean
  status: BankStatus
  country_code: string
  currency: string
  jars?: Jar[]
  company?: Company
  user?: User
}

interface BankAccountCreate extends ResourceCreate {
  account_number: string
  routing_number?: string
  account_holder_type: BankAccountHolderType
  account_holder_name: string
  country_code: string
  currency: string
  user?: UserRel
  jar?: JarRel
}

class BankAccounts extends ApiResource<BankAccount> {
  static readonly TYPE: BankAccountType = 'bank_accounts' as const

  async create(
    resource: BankAccountCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccount> {
    return await this.resources.create<BankAccountCreate, BankAccount>(
      { ...resource, type: BankAccounts.TYPE },
      params,
      options
    )
  }

  isBalance(resource: any): resource is BankAccount {
    return resource.type != null && resource.type === BankAccounts.TYPE
  }

  relationship(id: string | ResourceId | null): BankAccountRel {
    return id === null || typeof id === 'string'
      ? { id, type: BankAccounts.TYPE }
      : { id: id.id, type: BankAccounts.TYPE }
  }

  type(): BankAccountType {
    return BankAccounts.TYPE
  }
}

export type { BankAccount, BankAccountCreate }
export default BankAccounts
