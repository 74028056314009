import Button from '#components/ui/Button'
import { type ComponentProps } from 'react'
import {
  Camera,
  CameraResultType,
  CameraSource,
  type Photo
} from '@capacitor/camera'
import { isPlatform } from '@ionic/core'

interface Props extends ComponentProps<typeof Button> {
  /**
   * The text to be used
   */
  text: string
  /**
   * Set photo to state
   */
  setPhoto: (photo: Photo) => void

  /**
   * data-testid
   */
  testId?: string
}

export default function ButtonInputFile(props: Props): JSX.Element {
  const { text, setPhoto, testId, ...buttonProps } = props

  const getPermission = (): void => {
    Camera.checkPermissions()
      .then((res) => {
        if (res.camera !== 'granted' || res.photos !== 'granted') {
          Camera.requestPermissions({
            permissions: ['camera', 'photos']
          })
            .then((res) => {
              console.log('permission granted', res)
            })
            .catch((error) => {
              console.log('error permission', error)
            })
        }
      })
      .catch((error) => {
        console.log('error check permission', error)
      })
  }

  const handleGetPhoto = (): void => {
    getPermission()
    Camera.getPhoto({
      saveToGallery: true,
      resultType: isPlatform('hybrid')
        ? CameraResultType.Uri
        : CameraResultType.Base64,
      source: CameraSource.Photos,
      quality: 50,
      width: 600
    })
      .then((res) => {
        console.log('camera result', res)
        setPhoto(res)
      })
      .catch((error) => {
        console.log('camera error', error)
      })
  }
  return (
    <Button {...buttonProps} onClick={handleGetPhoto} data-testid={testId}>
      {text}
    </Button>
  )
}
