import AppLayout from '#components/layouts/app/AppLayout'
import AddBankAccountForm from '#components/pages/banks/AddBankAccountForm'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankContainer from '#contexts/BankContext'
import { useUserContext } from '#contexts/UserContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type UserCategory } from '#tackpay-sdk'
import { IonRow } from '@ionic/react'
import { useParams } from 'react-router'

interface Props {
  category: UserCategory
}
function HandleAddBankAccount({ category }: Props): JSX.Element {
  const { user } = useUserContext()

  return (
    <BankContainer fetch={false}>
      <AddBankAccountForm category={category} userId={user?.id} />
    </BankContainer>
  )
}

export default function AddBanksWallets(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/wallets/withdrawals/payouts`
        }
      }}
    >
      <IonRow className='w-full ion-no-padding mb-5 mt-0 mx-0'>
        <Title className='ion-no-margin ion-no-padding'>bank.add.title</Title>
      </IonRow>

      <SubTitle>bank.add.subtitle</SubTitle>
      <HandleAddBankAccount category={category} />
    </AppLayout>
  )
}
