import ErrorBox from '#components/errors/ErrorBox'
import Chip from '#components/ui/Chip'
import Skeleton from '#components/ui/Skeleton'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { usePayoutContext } from '#contexts/PayoutContext'
import { IonRow } from '@ionic/react'

export default function PayoutTitle(): JSX.Element {
  const { payout, isLoading, error } = usePayoutContext()

  if (isLoading) return <Skeleton className='w-full h-10' />

  if (error != null) return <ErrorBox error={error} />

  if (payout == null)
    return (
      <ErrorBox
        error={{
          detail: 'Payout not found',
          title: 'Not found',
          code: 'payout_not_found',
          status: 422
        }}
      />
    )

  const getColor = (): string => {
    if (payout.status === 'pending') return 'warning'
    if (payout.status === 'paid') return 'success'
    return 'danger'
  }
  return (
    <>
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <Title>payout.title</Title>
        <Chip
          className='capitalize cursor-default rounded-lg '
          color={getColor()}
        >
          {payout.status}
        </Chip>
      </IonRow>
      <SubTitle
        translationOptions={{
          translate: 'no'
        }}
        size='text-base'
      >
        #{payout.id}
      </SubTitle>
    </>
  )
}
