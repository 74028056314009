import ErrorBox from '#components/errors/ErrorBox'
import RegistrationMemberTeamInfo from '#components/pages/registrations/member/RegistrationMemberTeamInfo'
import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { MEMBER_TOKEN_KEY } from '#constants/memberTokenKey'
import { useMemberContext } from '#contexts/MemberContext'
import { useTokenContext } from '#contexts/TokenContext'
import { useUserContext } from '#contexts/UserContext'
import useInviteMember from '#hooks/useInviteMember'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type MemberStatus } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { IonCol, IonContent, IonFooter, IonRow } from '@ionic/react'
import { destroyPreferences } from 'storage/preferences'

export default function MemberInviteModal(): JSX.Element {
  const { setShow, show } = useInviteMember()

  const { token, isLoading, error } = useTokenContext()

  const { createMember } = useMemberContext()

  const { user } = useUserContext()

  const { startLoading, stopLoading } = useLoading('member.response.loading')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const handleResponse = (status: MemberStatus): void => {
    if (status === 'invitation_rejected') {
      destroyPreferences(MEMBER_TOKEN_KEY)
      setShow(false)
      showSuccess(undefined, 'member.response.rejected')
    } else {
      if (createMember != null) {
        setShow(false)
        startLoading()

        createMember.mutate(
          {
            invitation_type: 'token',
            email: user?.email ?? '',
            status,
            jar: {
              id: token?.jar?.id ?? '',
              type: 'jars'
            },
            token: token?.value ?? '',
            user: {
              id: user?.id ?? '',
              type: 'users'
            }
          },
          {
            onSuccess: () => {
              destroyPreferences(MEMBER_TOKEN_KEY)
              stopLoading()
              showSuccess(undefined, 'member.response.accepted')
            },
            onError: (error) => {
              stopLoading()
              destroyPreferences(MEMBER_TOKEN_KEY)
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
      }
    }
  }

  return (
    <Modal
      isOpen={show}
      setShowModal={setShow}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.6]}
      showBackdrop
    >
      <IonContent className='ion-padding'>
        {isLoading && (
          <>
            <Skeleton className='w-full h-10' />
            <Skeleton className='w-full h-10' />
          </>
        )}

        {error != null && <ErrorBox error={error} />}

        {!isLoading && error == null && (
          <>
            {token == null ? (
              <ErrorBox error='Token not found' />
            ) : (
              <>
                <div className='md:max-w-md md:mx-auto'>
                  <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
                    <Text color='dark' className='font-semibold'>
                      member.invite.title
                    </Text>
                    <IonRow className='ion-no-padding ion-justify-content-center ion-text-center space-x-1 mt-1'>
                      <Text
                        size='text-xs'
                        translationOptions={{
                          translate: 'yes',
                          data: {
                            companyName:
                              token?.jar?.company?.name ?? token?.company?.name
                          }
                        }}
                      >
                        registration.member.subtitle
                      </Text>
                    </IonRow>
                  </IonCol>
                </div>
                <RegistrationMemberTeamInfo
                  token={token}
                  showDescription={false}
                />
                <IonFooter className='mt-10'>
                  <IonRow className='ion-justify-content-between ion-text-center'>
                    <Button
                      fill='clear'
                      color='danger'
                      onClick={() => {
                        handleResponse('invitation_rejected')
                      }}
                    >
                      reject
                    </Button>
                    <Button
                      onClick={() => {
                        handleResponse('invitation_accepted')
                      }}
                    >
                      join
                    </Button>
                  </IonRow>
                </IonFooter>
              </>
            )}
          </>
        )}
      </IonContent>
    </Modal>
  )
}
