import useAuthState from '#hooks/useAuthState'
import { type QueryParamsRetrieve, type StatisticFeedback } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

interface StatisticFeedbackContextValue {
  statisticFeedback?: StatisticFeedback | null
  isLoading: boolean
  error?: any
}

const statisticFeedbackInitialValue: StatisticFeedbackContextValue = {
  statisticFeedback: undefined,
  isLoading: false,
  error: null
}

const StatisticFeedbackContext = createContext<StatisticFeedbackContextValue>(
  statisticFeedbackInitialValue
)

export const useStatisticFeedbackContext =
  (): StatisticFeedbackContextValue => {
    const context = useContext(StatisticFeedbackContext)
    if (context == null) {
      throw new Error(
        'useStatisticFeedbackContext must be used within a StatisticFeedbackProvider'
      )
    }

    return context
  }

interface StatisticFeedbackContainerProps {
  children: React.ReactNode
  /**
   * @default true
   */
  fetch?: boolean

  jarId?: string

  params?: QueryParamsRetrieve
}

export default function StatisticFeedbackContainer(
  props: StatisticFeedbackContainerProps
): JSX.Element {
  const { children, fetch = true, params, jarId } = props

  const { getAuthState } = useAuthState()

  const {
    data: statisticFeedback,
    isLoading,
    error
  } = useQuery<StatisticFeedback | undefined | null>({
    queryKey: ['statisticFeedback', jarId, params],
    queryFn: async () => {
      if (jarId != null) {
        return await sdk.jars.statistic_feedback(jarId, params)
      } else {
        const authInfo = await getAuthState()
        return await sdk.users.statistic_feedback(
          authInfo?.userId ?? '',
          params
        )
      }
    },
    enabled: fetch
  })

  // // Aggiorniamo i dati utente se la prop "fetch" cambia
  // useEffect(() => {
  //   if (fetch) {
  //     void queryClient.invalidateQueries(['statisticFeedback', jarId, params])
  //     setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
  //   }
  // }, [fetch, queryClient])

  const statisticFeedbackContextValue: StatisticFeedbackContextValue = {
    statisticFeedback,
    isLoading,
    error
  }

  return (
    <StatisticFeedbackContext.Provider value={statisticFeedbackContextValue}>
      {children}
    </StatisticFeedbackContext.Provider>
  )
}
