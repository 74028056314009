import { IonCol } from '@ionic/react'
import CardMaterial from './CardMaterial'
import { cloudDownloadOutline, mailOutline } from 'ionicons/icons'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { type Material, type MaterialActionType } from '#tackpay-sdk'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'
import useLoading from '#hooks/useLoading'
import { useUserContext } from '#contexts/UserContext'
import useFile from '#hooks/useFile'
import { isPlatform } from '@ionic/core'
import { useShare } from '#hooks/useShare'

export default function TippedMaterialDownloadSection(): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  const TIME_OUT_DOWNLOAD = 1000 * 5

  const { shareElement } = useShare()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('material.loading')

  const { downloadFiles, toZip, downloadFile } = useFile()

  if (isLoading)
    return (
      <div className='space-y-10'>
        <CardSkeleton />
        <CardSkeleton />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (user == null) return <ErrorBox error='jar.error.notFound' />

  const deleteLastSlash = (path: string): string => {
    // Dividi il percorso in segmenti usando '/'
    const segmenti = path.split('/')

    // Rimuovi l'ultimo segmento
    const nuovoPercorso = segmenti.slice(0, -1).join('/')

    return nuovoPercorso
  }

  const handleDownload = async (material: Material): Promise<void> => {
    const result = await downloadFiles(material.storage_path ?? '')
    const resultPaths: string[] = []
    if (isPlatform('hybrid')) {
      for (const item of result.items) {
        const resultPath = await downloadFile(item.path, `tackpay_qr_code`)
        resultPaths.push(resultPath.path ?? '')
      }
      const resultPath = deleteLastSlash(resultPaths[0])

      await shareElement({
        title: 'Checkout my store',
        url: resultPath,
        text: 'Checkout my store',
        dialogTitle: 'Share with friends'
      })
        .then(() => {
          console.log('shared')
        })
        .catch((error) => {
          console.log('error to share', error)
        })
    } else {
      const zip = await toZip(result)

      const zipUrl = URL.createObjectURL(zip)
      const link = document.createElement('a')
      link.href = zipUrl
      link.download = `tackpay_qr_code.zip` // Specifica il nome del file ZIP da scaricare

      // // Simula un clic sul link
      link.click()

      // // Rimuovi il link
      URL.revokeObjectURL(zipUrl)
    }
  }

  const downloadMaterialTeam = (actionType: MaterialActionType): void => {
    startLoading()

    sdk.materials
      .create({
        tackpay_id: {
          id: user?.tackpay_id?.id ?? '',
          type: 'tackpay_ids'
        },
        category: 'user',
        action_type: actionType,
        user: {
          id: user?.id ?? '',
          type: 'users'
        }
      })
      .then((res) => {
        if (actionType === 'download') {
          setTimeout(() => {
            handleDownload(res)
              .then(() => {
                stopLoading()
                showSuccess(undefined, 'material.download.success')
              })
              .catch((error) => {
                console.log('error during download material', error)
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              })
          }, TIME_OUT_DOWNLOAD)
        } else {
          stopLoading()
          showSuccess(undefined, 'material.email.success')
        }
      })
      .catch((error) => {
        stopLoading()
        console.log('error', error)
        showToast(undefined, getErrorMessage(error))
      })
  }

  return (
    <IonCol className='block space-y-10'>
      <CardMaterial
        icon={cloudDownloadOutline}
        label='material.download.direct'
        onClick={() => {
          downloadMaterialTeam('download')
        }}
        ml='ml-10'
      />
      <CardMaterial
        icon={mailOutline}
        label='material.download.email'
        onClick={() => {
          downloadMaterialTeam('email')
        }}
        ml='ml-10'
      />
    </IonCol>
  )
}
