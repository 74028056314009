import { IonToggle } from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps } from 'react'

interface Props extends ComponentProps<typeof IonToggle> {}

export type ToggleOnChange = ComponentProps<typeof IonToggle>['onIonChange']
export default function Toggle(props: Props): JSX.Element {
  const toggleClass = classNames(props?.className, 'transform scale-75')
  return <IonToggle {...props} className={toggleClass} />
}
