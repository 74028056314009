import { type UserCategory } from '#tackpay-sdk'
import { Route } from 'react-router'
import Tutorial from './views/Tutorial'
import Home from './views/home/Home'
import Collaborator from './views/collaborators/Collaborator'
import RegistrationContainer from '#contexts/RegistrationContext'
import SettingRoutes from './views/settings/SettingRoutes'
import PlanPage from './views/PlanPage'
import Statistics from './views/Statistics'
import CheckoutSetting from './views/CheckoutSettings'
import MaterialRoutes from './views/materials/MaterialRoutes'
import Company from './views/companies/Company'
import TransferId from './views/wallets/payments/TransferId'
import { lazy, Suspense } from 'react'
import PaymentId from './views/wallets/payments/PaymentId'
import MemberTippedInfo from './views/teams/views/members/MemberTippedInfo'
import OtherMemberList from './views/teams/views/members/OtherMemberList'
import TeamRoutes from './views/teams/TeamRoutes'
import WalletsRoutes from './views/wallets/WalletsRoutes'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import WithdrawalBusiness from './views/WithdrawalBusiness'
import ModalLoading from '#components/ui/ModalLoading'
import DeductionRouter from './views/deductions/DeductionRouter'

const StatisticFeedbackPage = lazy(
  async () => await import('./views/feedbacks/Feedbacks')
)

const dashboardBasePath = '/dashboard/:category'

export interface DashboardRoutesParams {
  category: UserCategory
  companyId?: string
}

export interface WalletTransferRoutesParams extends DashboardRoutesParams {
  transferId: string
}

export interface WalletPaymentRoutesParams extends DashboardRoutesParams {
  paymentId: string
}

export interface OtherMemberRoutesParams extends DashboardRoutesParams {
  memberId: string
  jarId: string
}

export default function DashboardRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={`${dashboardBasePath}/tutorials`}>
          <RegistrationContainer>
            <Tutorial />
          </RegistrationContainer>
        </Route>
        <Route exact path={`${dashboardBasePath}/home`}>
          <Home />
        </Route>
        <Route exact path={`${dashboardBasePath}/collaborators`}>
          <Collaborator />
        </Route>
        <Route path={`${dashboardBasePath}/wallets`}>
          <WalletsRoutes />
        </Route>
        <Route
          exact
          path={`${dashboardBasePath}/wallets/transfers/:transferId`}
        >
          <TransferId />
        </Route>
        <Route exact path={`${dashboardBasePath}/wallets/payments/:paymentId`}>
          <PaymentId />
        </Route>
        <Route path={`${dashboardBasePath}/jars`}>
          <TeamRoutes />
        </Route>
        <Route path={`${dashboardBasePath}/deductions`}>
          <DeductionRouter />
        </Route>
        <Route path={`${dashboardBasePath}/settings`}>
          <SettingRoutes />
        </Route>
        <Route exact path={`${dashboardBasePath}/feedbacks`}>
          <Suspense fallback={<ModalLoading />}>
            <StatisticFeedbackPage />
          </Suspense>
        </Route>
        <Route exact path={`${dashboardBasePath}/plans`}>
          <PlanPage />
        </Route>
        <Route exact path={`${dashboardBasePath}/statistics`}>
          <Statistics />
        </Route>
        <Route exact path={`${dashboardBasePath}/checkout-settings`}>
          <CheckoutSetting />
        </Route>
        <Route path={`${dashboardBasePath}/materials`}>
          <MaterialRoutes />
        </Route>
        <Route exact path={`${dashboardBasePath}/members/:memberId`}>
          <MemberTippedInfo />
        </Route>
        <Route exact path={`${dashboardBasePath}/withdrawals`}>
          <WithdrawalBusiness />
        </Route>
        <Route
          exact
          path={`${dashboardBasePath}/members/:memberId/jars/:jarId`}
        >
          <OtherMemberList />
        </Route>
        <Route exact path={`${dashboardBasePath}/companies/:companyId`}>
          <Company />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
