import { FirebaseAnalytics } from '@capacitor-firebase/analytics'

interface UseFirebaseAnalytics {
  setCurrentScreen: (page: string) => void
}

const userFirebaseAnalytics = (): UseFirebaseAnalytics => {
  const setCurrentScreen = (page: string): void => {
    FirebaseAnalytics.setCurrentScreen({ screenName: page })
      .then(() => {
        console.log('setCurrentScreen success')
      })
      .catch((error) => {
        console.log('setCurrentScreen error', error)
      })
  }

  return {
    setCurrentScreen
  }
}

export { userFirebaseAnalytics }
