import Text from '#components/ui/Text'
import Logout from './Logout'

export default function CollaboratorRemoved(): JSX.Element {
  return (
    <div className='p-5 border border-red-300 rounded-lg'>
      <Text color='gray-900' fontWeight='font-semibold' className='mb-5'>
        collaboratorRemovedTitle
      </Text>
      <Text>collaboratorRemoved</Text>
      <div className='pt-5 w-full flex justify-start underline'>
        <Logout withCover={false} />
      </div>
    </div>
  )
}
