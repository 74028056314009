import AppLayout from '#components/layouts/app/AppLayout'
import TeamMaterialDownloadSection from '#components/pages/materials/TeamMaterialDownloadSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useParams } from 'react-router'

export default function TeamMaterialDownload(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/materials`
        }
      }}
      teamProps={{
        fetch: true,
        params: {
          include: ['tackpay_ids']
        }
      }}
    >
      <Title>material.download.title</Title>
      <SubTitle>material.download.subtitle</SubTitle>
      <TeamMaterialDownloadSection />
    </AppLayout>
  )
}
