import useCreateTeamStore from '#hooks/useCreateTeamStore'
import Avatar from '#components/ui/Avatar'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  useIonRouter
} from '@ionic/react'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import ButtonStep from '#components/common/ButtonStep'
import maskIBAN from '#utils/maskIban'
import { type UserCategory } from '#tackpay-sdk'
import { useCreateTeamContext } from '#contexts/CreateTeamContext'
import useToast from '#hooks/useToast'
import useLoading from '#hooks/useLoading'
import getErrorMessage from '#utils/getErrorMessage'

interface Props {
  category: UserCategory
}

export default function AddTeamReviewView({ category }: Props): JSX.Element {
  const {
    teamCreate,
    isSameCompany,
    isSameIban,
    bankCreate,
    addressCreate,
    personCreate,
    stripeGatewayAccount,
    bankAccount,
    stripeGatewayAccountExistent,
    clearStore
  } = useCreateTeamStore()

  const { handleTeamCreate, clearCreateTeamStore } = useCreateTeamContext()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('teamPage.add.loading')

  const { push } = useIonRouter()

  const backUrl =
    isSameCompany === true
      ? isSameIban === true && teamCreate?.jar_type === 'private'
        ? `/dashboard/${category}/jars/add/ibans`
        : teamCreate?.jar_type === 'shared'
        ? `/dashboard/${category}/jars/add/tipping-rules`
        : `/dashboard/${category}/jars/add/ibans/add`
      : `/dashboard/${category}/jars/add/addresses`

  const handleBack = (): void => {
    push(backUrl, 'back')
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (handleTeamCreate != null) {
      startLoading()
      handleTeamCreate
        .mutateAsync(
          {
            addressCreate,
            bankCreate,
            personCreate,
            teamCreate,
            stripeGatewayAccount,
            isSameCompany,
            isSameIban,
            bankAccountId: bankAccount?.id,
            stripeGatewayAccountId: stripeGatewayAccountExistent?.id
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                stopLoading()

                showSuccess(undefined, 'teamPage.add.success')

                if (clearStore != null) {
                  clearStore()
                }

                if (clearCreateTeamStore != null) {
                  clearCreateTeamStore()
                }

                push(`/dashboard/${category}/jars`, 'root')
              }, 1000 * 5)
            },
            onError: (error) => {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          }
        )

        .then()
        .catch((erro) => {
          console.log(erro)
        })
    }
  }

  const getJarTippingRules = (): string => {
    if (teamCreate?.tipping_rule === 'individual')
      return 'teamPage.tippingRules.individual.title'
    if (teamCreate?.tipping_rule === 'equal_split')
      return 'teamPage.tippingRules.equal.title'
    if (teamCreate?.tipping_rule === 'tronc')
      return 'teamPage.tippingRules.tronc.title'
    return 'unknown'
  }

  return (
    <form onSubmit={handleSubmit}>
      <IonRow className='ion-justify-content-center ion-align-items-center mt-10'>
        <Avatar
          imgOptions={{
            src: teamCreate?.avatar ?? DEFAULT_IMAGES.logoTeam,
            alt: 'avatar'
          }}
          avatarOptions={{
            className: 'w-20 h-20'
          }}
        />
      </IonRow>
      <Card className='mt-10'>
        <IonCardHeader className='ion-no-padding'>
          <IonCardTitle>Barattolo</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className='ion-no-padding ion-no-margin'>
          <IonGrid className='ion-no-padding py-5'>
            <IonRow>
              <IonCol>
                <Text>jarName</Text>
              </IonCol>
              <IonCol>
                <Text
                  translationOptions={{
                    translate: 'no'
                  }}
                  color='gray-900'
                  fontWeight='font-medium'
                >
                  {teamCreate?.name}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Text>sameCompany</Text>
              </IonCol>
              <IonCol>
                <Text
                  color='gray-900'
                  fontWeight='font-medium'
                  className='capitalize'
                >
                  {isSameCompany != null && isSameCompany ? 'yes' : 'no'}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Text>sameIban</Text>
              </IonCol>
              <IonCol>
                <Text color='gray-900' fontWeight='font-medium'>
                  {isSameIban != null &&
                  isSameIban &&
                  teamCreate?.jar_type === 'private'
                    ? 'yes'
                    : 'no'}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Text>teamPage.add.chip.category</Text>
              </IonCol>
              <IonCol>
                <Text color='gray-900' fontWeight='font-medium'>
                  {teamCreate?.jar_type === 'private'
                    ? 'teamPage.type.private.title'
                    : 'teamPage.type.shared.title'}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Text>teamPage.add.chip.tippingRules</Text>
              </IonCol>
              <IonCol>
                <Text color='gray-900' fontWeight='font-medium'>
                  {getJarTippingRules()}
                </Text>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </Card>
      {isSameIban === false && isSameCompany === true && (
        <Card className='mt-10'>
          <IonCardHeader className='ion-no-padding'>
            <IonCardTitle>BankAccount</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className='ion-no-padding ion-no-margin'>
            <IonGrid className='ion-no-padding py-5'>
              <IonRow className='ion-align-items-center'>
                <IonCol>
                  <Text>Iban</Text>
                </IonCol>
                <IonCol>
                  <Text
                    translationOptions={{
                      translate: 'no'
                    }}
                    color='gray-900'
                    fontWeight='font-medium'
                  >
                    {maskIBAN(bankCreate?.account_number ?? '')}
                  </Text>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text>country</Text>
                </IonCol>
                <IonCol>
                  <Text
                    color='gray-900'
                    fontWeight='font-medium'
                    className='capitalize'
                  >
                    {bankCreate?.country_code}
                  </Text>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text>currency</Text>
                </IonCol>
                <IonCol>
                  <Text color='gray-900' fontWeight='font-medium'>
                    {bankCreate?.currency}
                  </Text>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text>routingNumber</Text>
                </IonCol>
                <IonCol>
                  <Text color='gray-900' fontWeight='font-medium'>
                    {bankCreate?.routing_number ?? 'notRequired'}
                  </Text>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </Card>
      )}
      <ButtonStep handleBack={handleBack} nextType='submit' />
    </form>
  )
}
