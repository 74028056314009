import React from 'react'

interface Props {
  icon: React.ReactNode
  className?: string
}
export default function AppCustomHeaderIcon({
  icon,
  className
}: Props): JSX.Element {
  return <div className={className}>{icon}</div>
}
