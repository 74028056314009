import Card from '#components/ui/Card'
import { useUserContext } from '#contexts/UserContext'
import { IonCardContent, IonList } from '@ionic/react'
import CardUserItemLine from './CardUserItemLine'
import Text from '#components/ui/Text'

export default function CardUserIdentity(): JSX.Element {
  const { user, isLoading } = useUserContext()

  return (
    <Card>
      <Text color='gray-900' fontWeight='font-semibold' size='text-base'>
        identity.personalInfo
      </Text>
      <IonCardContent className='ion-no-padding'>
        <IonList className='ion-no-padding ion-no-margin'>
          <CardUserItemLine
            label='firstName'
            value={user?.person?.first_name ?? ''}
            loading={isLoading}
          />
          <CardUserItemLine
            label='lastName'
            value={user?.person?.last_name ?? ''}
            loading={isLoading}
          />
          <CardUserItemLine
            label='birthdate'
            value={
              `${user?.person?.birthdate?.day ?? ''}/${
                user?.person?.birthdate?.month ?? ''
              }/${user?.person?.birthdate?.year ?? ''}` ?? ''
            }
            loading={isLoading}
          />
          <CardUserItemLine
            label='gender'
            value={user?.person?.gender ?? ''}
            loading={isLoading}
          />
        </IonList>
      </IonCardContent>
    </Card>
  )
}
