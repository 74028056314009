import { IonRow } from '@ionic/react'
import { getSymbolCurrency } from '#constants/country'
import isDesktop from '#utils/isDesktop'

interface Props {
  tip: string[] | undefined
  amount: number | undefined
  setAmount: (arg: number) => void
  currency?: string
}

export default function DefaultTipButton({
  tip,
  amount,
  setAmount,
  currency
}: Props): JSX.Element {
  const viewDesktop = isDesktop() ? 'm-auto w-5/6' : 'w-full ion-no-margin px-4'

  const justifyDesktop = isDesktop()
    ? 'ion-justify-content-around'
    : 'ion-justify-content-between'

  const customTips = tip?.map((t) => t.replace(',', ''))

  return (
    <IonRow className={`${justifyDesktop} ion-no-padding ${viewDesktop}`}>
      {customTips?.map((tip, index) => {
        const isActive = tip === String(amount)
        const classActive = isActive
          ? 'bg-button-active text-white'
          : 'bg-white text-gray-400 font-bold'

        return (
          <div
            key={index}
            className={`${classActive} w-20 h-14 buttonDefault  rounded-lg transition-all text-center flex items-center cursor-pointer`}
            onClick={() => {
              setAmount(Number(tip))
            }}
          >
            <div className='mx-auto text-2xl font-bold'>
              {tip}
              <span className='text-xs font-bold'>
                {getSymbolCurrency(currency ?? 'eur')}
              </span>
            </div>
          </div>
        )
      })}
    </IonRow>
  )
}
