import { useCheckoutContext } from '#contexts/CheckoutContext'
import { getStripe } from '#providers/stripe'
import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

interface Props {
  children: React.ReactNode
}

export default function CheckoutStripeElement({
  children
}: Props): JSX.Element {
  const { checkout } = useCheckoutContext()

  const [activeElement, setActiveElement] = useState(false)

  const [clientSecret, setClientSecret] = useState<string>()

  useEffect(() => {
    if (!activeElement && checkout?.payment != null) {
      setClientSecret(checkout.payment.client_secret_reference)
      setActiveElement(true)
    }
  }, [checkout, activeElement])

  return activeElement && checkout != null && clientSecret != null ? (
    <Elements stripe={getStripe()} options={{ clientSecret }}>
      {children}
    </Elements>
  ) : (
    <></>
  )
}
