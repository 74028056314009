import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  type ResourcesConfig
} from 'sdk/resource'
import { type Company } from './companies'
import { type User } from './users'
import { type Jar } from './jars'
import { type QueryParamsRetrieve } from 'sdk/query'

type TackpayIdType = 'tackpay_ids'
type TackpayIdRel = ResourceRel & { type: TackpayIdType }

type TackpayIdStatus = 'assigned' | 'unassigned'
type TackpayIdCategory = 'user' | 'company' | 'jar' | 'unknown'

type JarRel = ResourceRel & { type: 'jars' }

interface TackpayId extends Resource {
  readonly type: TackpayIdType
  value: string
  status: TackpayIdStatus
  category: TackpayIdCategory
  qr_code_storage_url?: string
  qr_code_storage_path?: string
  company?: Company
  user?: User
  jar?: Jar
}

interface TackpayIdUpdate extends ResourceUpdate {
  value: string
  status: TackpayIdStatus
  jar: JarRel
}

class TackpayIds extends ApiResource<TackpayId> {
  static readonly TYPE: TackpayIdType = 'tackpay_ids' as const

  async update(
    resource: TackpayIdUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<TackpayId> {
    return await this.resources.update<TackpayIdUpdate, TackpayId>(
      { ...resource, type: TackpayIds.TYPE },
      params,
      options
    )
  }

  isTackpayId(resource: any): resource is TackpayId {
    return resource.type != null && resource.type === TackpayIds.TYPE
  }

  relationship(id: string | ResourceId | null): TackpayIdRel {
    return id === null || typeof id === 'string'
      ? { id, type: TackpayIds.TYPE }
      : { id: id.id, type: TackpayIds.TYPE }
  }

  type(): TackpayIdType {
    return TackpayIds.TYPE
  }
}

export type { TackpayId, TackpayIdUpdate }
export default TackpayIds
