import AuthLayout from '#components/layouts/auth/AuthLayout'
import TabCheckout from '#components/pages/checkouts/TabCheckout'
import { useParams } from 'react-router'
import { type CheckoutRoutesParams } from '../CheckoutRoutes'
import CheckoutHandleConfirm from '#components/pages/checkouts/CheckoutHandleConfirm'

export default function CheckoutConfirm(): JSX.Element {
  const { checkoutId } = useParams<CheckoutRoutesParams>()

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
      checkoutOptions={{
        fetch: true,
        id: checkoutId,
        params: {
          include: ['payment', 'member', 'jar', 'user', 'tackpay_id']
        }
      }}
    >
      <TabCheckout section='tip' />
      <CheckoutHandleConfirm />
    </AuthLayout>
  )
}
