import {
  type QueryParamsRetrieve,
  type Member,
  type MemberCreate,
  type MemberUpdate
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

type CreateMemberFunction = UseMutationResult<
  Member,
  unknown,
  MemberCreate,
  unknown
>

type DeleteMemberFunction = UseMutationResult<void, unknown, string, unknown>

type UpdateMemberFunction = UseMutationResult<
  Member,
  unknown,
  MemberUpdate,
  unknown
>

interface MemberContextValue {
  member?: Member | null
  isLoading: boolean
  error?: any
  createMember?: CreateMemberFunction
  deleteMember?: DeleteMemberFunction
  updateMember?: UpdateMemberFunction
}

const memberInitialValue: MemberContextValue = {
  member: null,
  isLoading: false,
  error: null
}

const MemberContext = createContext<MemberContextValue>(memberInitialValue)

export const useMemberContext = (): MemberContextValue => {
  const context = useContext(MemberContext)

  if (context == null) {
    throw new Error(
      'useMemberContext() can only be used inside of <MemberContextProvider />, ' +
        'please declare it at a higher level.'
    )
  }

  return context
}

interface MemberContainerProps {
  children: React.ReactNode
  memberId?: string
  /**
   * @default true
   */
  fetch?: boolean

  params?: QueryParamsRetrieve
}

const handleCreateMember = async (member: MemberCreate): Promise<Member> => {
  return await sdk.members.create(member)
}

const handleDeleteMember = async (memberId: string): Promise<void> => {
  await sdk.members.delete(memberId)
}

const handleUpdateMember = async (
  memberUpdate: MemberUpdate
): Promise<Member> => {
  return await sdk.members.update(memberUpdate)
}

export default function MemberContainer(
  props: MemberContainerProps
): JSX.Element {
  const { children, memberId, fetch = true, params } = props

  const queryClient = useQueryClient()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: member,
    isLoading,
    error
  } = useQuery<Member>({
    queryKey: ['member', memberId, params],
    queryFn: async () => {
      return await sdk.members.retrieve(memberId ?? '', params)
    },
    enabled: !initialFetchComplete && fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  useEffect(() => {
    if (fetch) {
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const useCreateMember = useMutation({
    mutationFn: handleCreateMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    }
  })

  const useDeleteMember = useMutation({
    mutationFn: handleDeleteMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    }
  })

  const useMemberUpdate = useMutation({
    mutationFn: handleUpdateMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['user', { include: ['person', 'members.jar.balance'] }]
      })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['member'] })
      void queryClient.invalidateQueries({ queryKey: ['members'] })
      void queryClient.invalidateQueries({
        queryKey: ['user', { include: ['person', 'members.jar.balance'] }]
      })
      void queryClient.invalidateQueries({
        queryKey: ['member', memberId, params]
      })
    }
  })

  const memberContextValue: MemberContextValue = {
    member,
    isLoading,
    error,
    createMember: useCreateMember,
    deleteMember: useDeleteMember,
    updateMember: useMemberUpdate
  }

  return (
    <MemberContext.Provider value={memberContextValue}>
      {children}
    </MemberContext.Provider>
  )
}
