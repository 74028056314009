import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'

interface Props {
  /**
   * The label to be used
   */
  label: string
  /**
   * The placeholder to be used
   */
  placeholder: string
  /**
   * The value to be used
   */
  value?: string
  /**
   * The onClick event handler
   */
  onClick?: () => void
  /**
   * The showSelectIcon to be used
   * @default true
   */
  showSelectIcon?: boolean
  /**
   * The capitalize label
   */
  capitalize?: boolean
  /**
   * Test Id
   */
  testId: string
}

export default function FakeInput({
  label,
  value,
  placeholder,
  onClick,
  showSelectIcon = true,
  capitalize,
  testId
}: Props): JSX.Element {
  return (
    <IonRow className='ion-no-margin'>
      <IonCol className='ion-no-margin ion-no-padding'>
        <Label
          position='fixed'
          className='block mb-1 text-sm font-medium text-gray-900  capitalize'
          capitalize={capitalize}
          data-testid={testId}
        >
          {label}
        </Label>
        <div
          className='p-2 border border-gray-300 rounded-lg  w-full inputs bg-white'
          onClick={onClick}
        >
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <Text
              color={value == null ? 'text-gray-900' : 'ligth'}
              className='pl-1 '
              data-testid={`${testId}text`}
            >
              {value ?? placeholder}
            </Text>
            {showSelectIcon && (
              <IonIcon
                src={chevronDownOutline}
                className=''
                data-testid={`icon-${label}`}
              />
            )}
          </IonRow>
        </div>
      </IonCol>
    </IonRow>
  )
}
