import AppLayout from '#components/layouts/app/AppLayout'
import TutorialContent from '#components/pages/tutorials/TutorialContent'
import TackPayIcon from '#components/ui/TackpayIcon'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '../DashboardRoutes'
import { useEffect } from 'react'
import { useRegistrationContext } from '#contexts/RegistrationContext'

export default function Tutorial(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const { clearStorage } = useRegistrationContext()

  useEffect(() => {
    clearStorage()
  }, [])

  return (
    <AppLayout
      headerOptions={{
        showBackButton: false,
        showMenuButton: false,
        showNotificationsButton: false,
        showCustomIcon: true,
        customIconOptions: {
          icon: <TackPayIcon />,
          slot: 'start',
          className: 'pt-5'
        }
      }}
      footerOptions={{
        showFooter: false
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person', 'subscriber']
        }
      }}
    >
      <TutorialContent category={category} />
    </AppLayout>
  )
}
