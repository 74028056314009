import StepBusiness1 from '#components/ui/svg/StepBusiness1'
import { StepBusiness2 } from '#components/ui/svg/StepBusiness2'
import { StepBusiness4 } from '#components/ui/svg/StepBusiness4'
import { StepBusiness5 } from '#components/ui/svg/StepBusiness5'
import { StepBusiness6 } from '#components/ui/svg/StepBusiness6'
import StepTipped2 from '#components/ui/svg/StepTipped2'
import StepTipped3 from '#components/ui/svg/StepTipped3'
import StepTipped4 from '#components/ui/svg/StepTipped4'
import { StepTipped5 } from '#components/ui/svg/StepTipped5'
import StepTipped1 from '#components/ui/svg/Stepstipped1'
import { type JarCategory, type UserCategory } from '#tackpay-sdk'

export interface TutorialStep {
  category: UserCategory
  img: JSX.Element
  name: string
  description: string
  isEnd: boolean
  teamRole?: JarCategory[]
}

export const TUTORIAL_STEPS: TutorialStep[] = [
  {
    category: 'tipped',
    img: StepTipped1(),
    name: 'tutorial.setPhoto',
    description: 'tutorial.setPhotoInfo',
    isEnd: false
  },
  {
    category: 'tipped',
    img: StepTipped2(),
    name: 'tutorial.customizeProfile',
    description: 'tutorial.customizeProfileInfo',
    isEnd: false
  },
  {
    category: 'tipped',
    img: StepTipped3(),
    name: 'tutorial.showQR',
    description: 'tutorial.QRInfo',
    isEnd: false
  },
  {
    category: 'tipped',
    img: StepTipped4(),
    name: 'tutorial.checkTips',
    description: 'tutorial.checkTipsInfo',
    isEnd: false
  },
  {
    category: 'tipped',
    img: StepTipped5(),
    name: 'tutorial.collectFeedback',
    description: 'tutorial.collectFeedbackInfo',
    isEnd: true
  },
  /** Manager */
  {
    category: 'manager',
    img: StepBusiness1(),
    name: 'tutorial.manageStaff',
    description: 'tutorial.manageStaffInfo',
    isEnd: false,
    teamRole: ['shared']
  },
  {
    category: 'manager',
    img: StepBusiness2(),
    name: 'tutorial.QR',
    description: 'tutorial.QRInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'manager',
    img: StepTipped2(),
    name: 'tutorial.customizeProfile',
    description: 'tutorial.customizeProfileInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'manager',
    img: StepBusiness4(),
    name: 'tutorial.setQR',
    description: 'tutorial.showQRInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'manager',
    img: StepBusiness5(),
    name: 'tutorial.setPhotoJar',
    description: 'tutorial.setPhotoJarInfo',
    isEnd: false,
    teamRole: ['shared']
  },
  {
    category: 'manager',
    img: StepBusiness6(),
    name: 'tutorial.collectFeedback',
    description: 'tutorial.collectFeedbackInfo',
    isEnd: true,
    teamRole: ['shared', 'private']
  },

  /** Business */
  {
    category: 'business',
    img: StepBusiness2(),
    name: 'tutorial.QR',
    description: 'tutorial.QRInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'business',
    img: StepTipped2(),
    name: 'tutorial.customizeProfile',
    description: 'tutorial.customizeProfileInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'business',
    img: StepBusiness4(),
    name: 'tutorial.setQR',
    description: 'tutorial.showQRInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'business',
    img: StepBusiness5(),
    name: 'tutorial.setPhotoJar',
    description: 'tutorial.setPhotoJar',
    isEnd: false,
    teamRole: ['shared']
  },
  {
    category: 'business',
    img: StepBusiness6(),
    name: 'tutorial.collectFeedback',
    description: 'tutorial.collectFeedbackInfo',
    isEnd: true,
    teamRole: ['shared', 'private']
  },
  /** Collaborator */
  {
    category: 'collaborator',
    img: StepBusiness1(),
    name: 'tutorial.manageStaff',
    description: 'tutorial.manageStaffInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'collaborator',
    img: StepBusiness6(),
    name: 'tutorial.manageTippingRule',
    description: 'tutorial.manageTippingRuleInfo',
    isEnd: false,
    teamRole: ['shared']
  },
  {
    category: 'collaborator',
    img: StepBusiness2(),
    name: 'tutorial.QR',
    description: 'tutorial.QRInfo',
    isEnd: false,
    teamRole: ['shared', 'private']
  },
  {
    category: 'collaborator',
    img: StepBusiness5(),
    name: 'tutorial.setPhotoJar',
    description: 'tutorial.setPhotoJarInfo',
    isEnd: true,
    teamRole: ['shared', 'private']
  }
]
