import { IonIcon } from '@ionic/react'
import classNames from 'classnames'
import React, { type ComponentProps } from 'react'

interface Props {
  /**
   * Background color. Default is `bg-green-100`
   */
  bg?: string
  /**
   * Rounded. Default is `rounded-lg`
   */
  rounded?: string
  /**
   * Size. Default is `w-12 h-12`
   */
  size?: string
  /**
   * Shadow. Default is `true`
   */
  shadow?: boolean
  /**
   * Custom class name
   */
  className?: string
  /**
   * Icon options
   */
  iconOptions: ComponentProps<typeof IonIcon>
  /**
   * Function to handle click event
   */
  onClick?: () => void
  /**
   * Id
   */
  id?: string
  /**
   * Test id
   */
  testId?: string

  children?: React.ReactNode
}

export default function CoverIcon({
  bg = 'bg-green-100 ',
  rounded = 'rounded-lg',
  size = 'w-11 h-11',
  shadow = true,
  id,
  onClick,
  children,
  testId,
  ...props
}: Props): JSX.Element {
  const base = `${bg} ${rounded} ${size} flex flex-row ion-align-items-center ion-justify-content-center m-0`
  const className = classNames(base, props?.className)
  return (
    <div data-testid={testId} id={id} className={className} onClick={onClick}>
      {children ?? <IonIcon {...props.iconOptions} />}
    </div>
  )
}
