import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import AppLayout from '#components/layouts/app/AppLayout'
import EmptyPayment from '#components/pages/payments/list/EmptyPayment'
import Title from '#components/ui/Title'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type ListResponse, type Transfer } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import {
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

export default function TeamTroncHistory(): JSX.Element {
  const { jarId, category } = useParams<TeamRoutesParams>()

  const fetchTransfers = async ({
    pageParam = 1
  }): Promise<ListResponse<Transfer>> => {
    return await sdk.transfers.list({
      include: ['member'],
      pageNumber: pageParam,
      filters: {
        eq: {
          jar_id: jarId,
          tronc: true
        }
      },
      sort: {
        created_at: 'desc'
      }
    })
  }

  const { data, hasNextPage, fetchNextPage, status, isFetching, error } =
    useInfiniteQuery({
      queryKey: ['transfers-members', jarId],
      queryFn: fetchTransfers,
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage() ? lastPage.getCurrentPage() + 1 : undefined
    })

  const loadNext = (e: IonInfiniteScrollCustomEvent<void>): void => {
    e.preventDefault()
    if (!hasNextPage) {
      void e.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void e.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void e.target.complete()
        })
    }
  }

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showMenuButton: false,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/tronc`
        }
      }}
    >
      <Title>tronc_history.title</Title>
      <IonCol>
        {status === 'pending' ? (
          <CardSkeleton hasAvatar />
        ) : status === 'error' ? (
          <ErrorBox error={error} />
        ) : data.pages != null && data.pages[0].length === 0 && !isFetching ? (
          <EmptyPayment isTransferTronc />
        ) : (
          <div className='space-y-6'>
            {data.pages.map((page, i) => (
              <div key={i} className='space-y-6'>
                {page.map((transfer) => {
                  if (transfer != null) {
                    return <div key={transfer.id}>Ciao</div>
                  }
                  return null
                })}
              </div>
            ))}
            <IonInfiniteScroll
              disabled={!hasNextPage}
              onIonInfinite={loadNext}
              threshold='100px'
            >
              <IonInfiniteScrollContent
                loadingSpinner='circles'
                loadingText='Loading...'
                color='primary'
              />
            </IonInfiniteScroll>
          </div>
        )}
      </IonCol>
    </AppLayout>
  )
}
