import ErrorBox from '#components/errors/ErrorBox'
import ModalLoading from '#components/ui/ModalLoading'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'

export default function CheckoutHandleConfirm(): JSX.Element {
  const { isLoading, checkout, error } = useCheckoutContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleConfirmPayment = async (): Promise<void> => {
    await sdk.payments.update({
      id: checkout?.payment?.id ?? '',
      reference: checkout?.payment?.reference ?? ''
    })
  }
  useEffect(() => {
    if (!isLoading && error == null && checkout != null) {
      handleConfirmPayment()
        .then(() => {
          push(`/checkout/${checkout.tackpay_id?.value}/${checkout.id}/reviews`)
        })
        .catch((error) => {
          console.log(error)
          showToast(undefined, getErrorMessage(error))
        })
    }
  }, [checkout, isLoading, error])

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  return (
    <div>
      <ModalLoading />
    </div>
  )
}
