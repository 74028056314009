import Card from '#components/ui/Card'
import Label from '#components/ui/Label'
import { IonCardHeader, IonCol, IonIcon, IonRow } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
interface Props {
  /**
   * The path of the icon
   */
  icon: string
  /**
   * The language code
   */
  lang: string
  /**
   * If the language is the current language
   */
  isCurrent?: boolean
  /**
   * The function to call when the language is selected
   */
  onClick?: () => void
}
export default function CardLanguage({
  icon,
  lang,
  isCurrent,
  onClick
}: Props): JSX.Element {
  return (
    <Card
      padding='ion-no-padding'
      onClick={onClick}
      data-testid={`card-${lang}`}
    >
      <IonCardHeader>
        <IonRow className='ion-align-items-center'>
          <IonCol size='5'>
            <IonIcon src={icon} size='large' />
          </IonCol>
          <IonCol size='5'>
            <Label color='gray-900'>{lang}</Label>
          </IonCol>
          <IonCol size='2'>
            {isCurrent === true && (
              <IonIcon
                icon={checkmarkCircleOutline}
                size='small'
                color='primary'
              />
            )}
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
