import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { useCompanyContext } from '#contexts/CompanyContext'
import { IonCol, useIonRouter } from '@ionic/react'
import PlanFreeCard from './PlanFreeCard'
import PlanPremiumCard from './PlanPremiumCard'
import PlanEnterpriseCard from './PlanEnterpriseCard'
import getQueryParams from '#utils/getQueryParams'
import { useEffect } from 'react'
import sdk from '#utils/sdk'

export default function Plans(): JSX.Element {
  const { company, isLoading, error } = useCompanyContext()

  const { routeInfo } = useIonRouter()

  const query = getQueryParams(routeInfo.search)

  useEffect(() => {
    if (query.get('success') === 'true') {
      if (query.get('operation') === 'remove') {
        void handleTeamUpdate('remove')
      } else if (query.get('operation') === 'add') {
        void handleTeamUpdate('add')
      }
    }
  }, [])

  if (isLoading)
    return (
      <IonCol className='block space-y-5'>
        <Skeleton className='w-full h-40' />
        <Skeleton className='w-full h-40' />
      </IonCol>
    )

  if (error != null) return <ErrorBox error={error} />

  const quantity =
    company?.customer?.subscription?.subscription_items?.[0]?.quantity ?? 1

  const isFree =
    company?.customer?.subscription?.subscription_items?.[0]?.plan_type ===
    'free'

  const isPremium =
    company?.customer?.subscription?.subscription_items?.[0]?.plan_type ===
    'premium'

  const isEnterprise =
    company?.customer?.subscription?.subscription_items?.[0]?.plan_type ===
    'custom'

  const handleTeamUpdate = async (
    operation: 'add' | 'remove'
  ): Promise<void> => {
    const jarIds = query.get('jar_id')?.split(',') ?? []

    for (const jar of jarIds) {
      await sdk.jars.update(
        {
          id: jar,
          active: operation === 'add'
        },
        undefined,
        {
          accessToken: query.get('access_token') ?? undefined
        }
      )
    }
  }
  return (
    <section>
      <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
        <div className='space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0'>
          <PlanFreeCard
            currency={company?.currency ?? 'eur'}
            isActive={isFree && quantity <= 1}
          />
          <PlanPremiumCard
            subscription={company?.customer?.subscription}
            companyId={company?.id ?? ''}
            currency={company?.currency ?? 'eur'}
            isActive={(quantity >= 2 && quantity <= 10) || isPremium}
          />
          <PlanEnterpriseCard isActive={quantity > 10 || isEnterprise} />
        </div>
      </div>
    </section>
  )
}
