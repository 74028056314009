import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'

type InvoiceType = 'invoices'
type InvoiceRel = ResourceRel & { type: InvoiceType }

type PaymentRel = ResourceRel & { type: 'payments' }

type InvoiceCategory = 'tipper' | 'tipped' | 'business'

interface Invoice extends Resource {
  readonly type: InvoiceType

  storage_path_url?: string
  storage_path?: string
  invoice_url?: string
  category: InvoiceCategory
  amount_cents: number
  amount_float: number
  amount_formatted: string
  gross_amount_cents: number
  rate_percentage: number
  rate_amount: number
  tax_code: string
  tax_code_description?: string
  fee_id?: string
  payment?: Payment
  currency: string
}

interface InvoiceCreate extends ResourceCreate {
  payment: PaymentRel
  category: InvoiceCategory
}

class Invoices extends ApiResource<Invoice> {
  static readonly TYPE: InvoiceType = 'invoices' as const

  async create(
    resource: InvoiceCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Invoice> {
    return await this.resources.create(
      { ...resource, type: Invoices.TYPE },
      params,
      options
    )
  }

  isInvoice(resource: any): resource is Invoice {
    return resource.type != null && resource.type === Invoices.TYPE
  }

  relationship(id: string | ResourceId | null): InvoiceRel {
    return id === null || typeof id === 'string'
      ? { id, type: Invoices.TYPE }
      : { id: id.id, type: Invoices.TYPE }
  }

  type(): InvoiceType {
    return Invoices.TYPE
  }
}

export type { Invoice, InvoiceCreate }

export default Invoices
