import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import Withdrawals from './views/Withdrawals'
import BankAccount from './views/banks/Banks'
import AddBankAccount from './views/banks/AddBank'
import Payout from './views/payouts/Payouts'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import PayoutId from './views/payouts/[Id]'

const withdrawalBaseRoute = '/dashboard/:category/settings/withdrawals'

export interface PayoutRoutesParams extends DashboardRoutesParams {
  payoutId: string
}

export default function WithdrawalRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet id='main'>
        <Route exact path={withdrawalBaseRoute}>
          <Withdrawals />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/banks`}>
          <BankAccount />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/banks/add`}>
          <AddBankAccount />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/payouts`}>
          <Payout />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/payouts/:payoutId`}>
          <PayoutId />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
