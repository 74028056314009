import {
  IonButton,
  IonIcon,
  IonTabBar,
  IonTabButton,
  useIonRouter
} from '@ionic/react'
import { FOOTER_NAV_BAR } from '../../../constants/footer'
import { type DashboardRoutesParams } from '../../../pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useEffect, useState } from 'react'
import isCollaborator from '#utils/isCollaborator'
import JarIcon from '#components/ui/svg/JarIcon'

interface Props {
  /**
   * If the footer should be shown.
   * @default true
   */
  showFooter?: boolean
}
export default function AppFooter({ showFooter = true }: Props): JSX.Element {
  const { category: categoryParam } = useParams<DashboardRoutesParams>()

  const [show, setShow] = useState(showFooter)

  const { collaborator, isLoading, error } = useCollaboratorContext()

  const router = useIonRouter()

  useEffect(() => {
    if (isCollaborator(categoryParam)) {
      if (!isLoading && error == null) {
        if (collaborator == null) {
          setShow(false)
        } else {
          setShow(true)
        }
      }
    }
  }, [collaborator, isLoading, error, categoryParam])

  return showFooter && show ? (
    <IonTabBar slot='bottom' color='white' className='rounded-t-lg shadow  p-2'>
      {FOOTER_NAV_BAR.filter(({ category }) => category === categoryParam).map(
        (footer) => {
          const isActive = router.routeInfo.pathname.includes(footer.paths[0])

          return (
            <IonTabButton className=' pb-3' key={footer.text} tab={footer.text}>
              <IonButton
                className='button-footer'
                routerDirection='root'
                routerLink={footer.href}
                data-testid={`${footer.text}-icon`}
              >
                {footer.text === 'Jar' ? (
                  <JarIcon color={isActive ? '#71e89d' : '#d4d4d8'} />
                ) : (
                  <IonIcon
                    // src='/assets/img/defaultTeam.svg'
                    icon={footer.icon}
                    size='small'
                    // size={`${footer.text === 'Jar' ? 'large' : 'small'}`}
                    color={isActive ? 'primary' : 'gray-300'}
                  />
                )}
              </IonButton>
            </IonTabButton>
          )
        }
      )}
    </IonTabBar>
  ) : (
    <></>
  )
}
