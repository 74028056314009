/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ResourceType } from './resource'

export type QueryFilter = Record<string, any>

export interface QueryParamsRetrieve {
  /**
   * The include resource types to include in the response.
   */
  include?: string[]
  /**
   * The fields to include in the response.
   */
  fields?: Record<string, string[] | string>
}

export interface QueryParamsList extends QueryParamsRetrieve {
  /**
   * The sort order of the results.
   */
  sort?: Record<string, 'asc' | 'desc'>
  /**
   * The filers to apply to the results.
   */
  filters?: Record<string, any>
  /**
   * The page number of the results.
   */
  pageNumber?: number
  /**
   * The page size of the results.
   */
  pageSize?: number
}

export type QueryParams = QueryParamsRetrieve | QueryParamsList

const isParamsList = (params: any): params is QueryParamsList => {
  return params.filters ?? params.number ?? params.page ?? params.sort
}

function generateQueryStringParams(
  params: QueryParamsList | QueryParamsRetrieve | undefined,
  res: string | ResourceType
): Record<string, string> {
  const query: Record<string, string> = {}

  if (params == null) return query

  if ('include' in params) {
    if (params.include != null) {
      query.include = params.include.join(',')
    }
  }

  if ('fields' in params) {
    if (params.fields != null) {
      if (Array.isArray(params.fields))
        params.fields = { [(res as ResourceType).type ?? res]: params.fields }
      Object.entries(params.fields).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          query[`fields[${key}]`] = value.join(',')
        }
      })
    }
  }

  if (isParamsList(params)) {
    if (params.sort != null) {
      Object.entries(params.sort).forEach(([key, value]) => {
        query.sort = value === 'asc' ? key : `-${key}`
      })
    }

    if (params.pageSize != null) {
      query['page[size]'] = params.pageSize.toString()
    }

    if (params.pageNumber != null) {
      query['page[number]'] = params.pageNumber.toString()
    }

    if (params.filters != null) {
      Object.entries(params.filters).forEach(([key, value]) => {
        query[`filter[${key}]`] = value
      })
    }
  }
  console.log('query %o', query)
  return query
}
export { generateQueryStringParams, isParamsList }
// import type { ResourceType } from './resource'

// type QueryFilter = Record<string, any>

// interface QueryParamsRetrieve {
//   include?: string[]
//   fields?: Record<string, string[] | string>
// }

// interface QueryParamsList extends QueryParamsRetrieve {
//   sort?: string[] | Record<string, 'asc' | 'desc'>
//   filters?: QueryFilter
//   pageNumber?: number
//   pageSize?: number
// }

// type QueryParams = QueryParamsRetrieve | QueryParamsList

// export type { QueryParamsRetrieve, QueryParamsList, QueryParams, QueryFilter }

// const isParamsList = (params: any): params is QueryParamsList => {
//   return (
//     params != null &&
//     (params.filters != null ||
//       params.pageNumber != null ||
//       params.pageSize != null ||
//       params.sort != null)
//   )
// }

// const generateQueryStringParams = (
//   params: QueryParamsRetrieve | QueryParamsList | undefined,
//   res: string | ResourceType
// ): Record<string, string> => {
//   console.log('generate query string params: %O, %O', params, res)

//   const qp: Record<string, string> = {}
//   if (params == null) return qp

//   // Include
//   if (params.include != null) qp.include = params.include.join(',')
//   // Fields
//   if (params.fields != null) {
//     if (params.fields != null) {
//       if (Array.isArray(params.fields))
//         params.fields = { [(res as ResourceType).type ?? res]: params.fields }
//       Object.entries(params.fields).forEach(([key, value]) => {
//         if (Array.isArray(value)) {
//           qp[`fields[${key}]`] = value.join(',')
//         }
//       })
//     }
//   }

//   if (isParamsList(params)) {
//     // Sort
//     if (params.sort != null) {
//       if (Array.isArray(params.sort)) qp.sort = params.sort.join(',')
//       else
//         qp.sort = Object.entries(params.sort)
//           .map(([k, v]) => `${v === 'desc' ? '-' : ''}${k}`)
//           .join(',')
//     }
//     // Page
//     if (params.pageNumber != null)
//       qp['page[number]'] = String(params.pageNumber)
//     if (params.pageSize != null) qp['page[size]'] = String(params.pageSize)

//     // Filters
//     if (params.filters != null) {
//       Object.entries(params.filters).forEach(([key, value]) => {
//         console.log('filter %s, %s', ...key)
//         qp[`filter[${key}]`] = String(value)
//       })
//     }
//   }

//   console.log('query string params: %O', qp)

//   return qp
// }

// export { generateQueryStringParams, isParamsList }
