import Text from '#components/ui/Text'
import { type UserCategory, type ListResponse, type Payout } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useInfiniteQuery } from '@tanstack/react-query'
import EmptyPayout from '#components/pages/payouts/hyistory/EmptyPayout'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import PayoutCard from './CardPayoutDeduction'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useEffect, useState } from 'react'
// import { useParams } from 'react-router'
// import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'

interface Props {
  category: UserCategory
}
export default function DeductionPayoutList({ category }: Props): JSX.Element {
  const { company } = useCompanyContext()

  const [jarIds, setJarIds] = useState<string[]>(
    company?.jars?.map((jar) => jar.id) ?? []
  )

  useEffect(() => {
    if (company?.jars != null) {
      setJarIds(company.jars.map((jar) => jar.id))
    }
  }, [company?.jars])

  //   const { category } = useParams<DashboardRoutesParams>()

  const fetchPayoutList = async ({
    pageParam = 1
  }): Promise<ListResponse<Payout>> => {
    return await sdk.payouts.list({
      filters: {
        eq: {
          category: 'deduction'
        },
        in: {
          jar_id: jarIds
        }
      },
      include: ['bank_account_deduction', 'jar'],
      sort: {
        created_at: 'desc'
      },
      pageNumber: pageParam
    })
  }

  const {
    data,
    hasNextPage: hasMore,
    fetchNextPage,
    status,
    isFetching,
    error
  } = useInfiniteQuery({
    queryKey: ['payouts-deduction-list', jarIds],
    queryFn: fetchPayoutList,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage() ? lastPage.getPageCount() + 1 : undefined,
    enabled: jarIds != null && jarIds.length > 0
  })

  const loadNext = (event: IonInfiniteScrollCustomEvent<void>): void => {
    if (hasMore == null || !hasMore) {
      void event.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void event.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void event.target.complete()
        })
    }
  }

  return (
    <div className='block mt-8'>
      <Text fontWeight='font-bold' size='text-xl' color='gray-900'>
        payout.history
      </Text>
      <div className='mt-10'>
        {status === 'pending' ? (
          <CardSkeleton />
        ) : status === 'error' ? (
          <ErrorBox error={error} />
        ) : data.pages[0].length === 0 && !isFetching ? (
          <EmptyPayout />
        ) : (
          <>
            {data.pages.map((page, i) => (
              <div key={i} className='space-y-6'>
                {page.map((payout) => {
                  return (
                    <PayoutCard
                      key={payout.id}
                      payout={payout}
                      category={category}
                      jarId={payout.jar?.id}
                    />
                  )
                })}
              </div>
            ))}

            <IonInfiniteScroll
              disabled={hasMore == null || !hasMore}
              onIonInfinite={loadNext}
            >
              <IonInfiniteScrollContent
                loadingSpinner='circles'
                loadingText='Loading...'
                color='primary'
              />
            </IonInfiniteScroll>
          </>
        )}
      </div>
    </div>
  )
}
