import Text from '#components/ui/Text'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

interface Props {
  validity: {
    minChar: boolean
    number: boolean
    specialChar: boolean
    upperCase: boolean
  }
  visible: boolean
}
const PasswordStrengthIndicator = ({
  validity: { minChar, number, specialChar, upperCase },
  visible
}: Props): JSX.Element => {
  return (
    <div className={`mt-2 text-left mb-4 ${visible ? 'visible' : 'hidden'}`}>
      <Text size='text-sm' color='gray-900'>
        passwordChart
      </Text>
      <ul className='text-muted'>
        <PasswordStrengthIndicatorItem
          isValid={minChar}
          text='registration.passwordMinChar'
          testId='icon-minChair'
        />
        <PasswordStrengthIndicatorItem
          isValid={number}
          text='registration.passwordNumber'
          testId='icon-passwordNumber'
        />
        <PasswordStrengthIndicatorItem
          isValid={upperCase}
          text='registration.passwordUppercase'
          testId='icon-passwordUppercase'
        />
        <PasswordStrengthIndicatorItem
          isValid={specialChar}
          text='registration.passwordSpecialChar'
          testId='icon-passwordSpecialChair'
        />
      </ul>
    </div>
  )
}

const PasswordStrengthIndicatorItem = ({
  isValid,
  text,
  testId
}: {
  isValid: boolean
  text: string
  testId: string | undefined
}): JSX.Element => {
  const highlightClass = isValid
    ? 'text-success'
    : isValid !== null
    ? 'text-danger'
    : ''
  const getColorIcon = (): string => {
    if (isValid) return 'success'
    if (isValid !== null) return 'danger'
    return 'muted'
  }
  return (
    <IonCol className='ion-no-padding ion-no-margin'>
      <IonRow className='ion-align-items-center space-x-3'>
        <IonIcon
          src={closeOutline}
          color={getColorIcon()}
          data-testid={testId}
        />
        <IonCol className='ion-no-padding ion-no-margin'>
          <Text size='text-xs' className={highlightClass}>
            {text}
          </Text>
        </IonCol>
      </IonRow>
    </IonCol>
  )
}

export default PasswordStrengthIndicator
