import DateRanger from '#components/common/DateRanger'
import AppLayout from '#components/layouts/app/AppLayout'
import CardDownloadAccountStatement from '#components/pages/accountStatements/CardDownloadAccountStatement'
import { type DateTimeValue } from '#components/ui/DateTime'
import SubTitle from '#components/ui/SubTitle'
import Text from '#components/ui/Text'
import Title from '#components/ui/Title'
import { useTeamContext } from '#contexts/JarContext'
import { useUserContext } from '#contexts/UserContext'
import useFilesystem from '#hooks/useFilesystem'
import useLoading from '#hooks/useLoading'
import { useShare } from '#hooks/useShare'
import useToast from '#hooks/useToast'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type AccountStatement } from '#tackpay-sdk'
import createPaymentCsv from '#utils/createPaymentCsv'
import getErrorMessage from '#utils/getErrorMessage'
import isWeb from '#utils/isWeb'
import sdk from '#utils/sdk'
import { Directory, Encoding } from '@capacitor/filesystem'
import { useState } from 'react'
import { useParams } from 'react-router'

function HandleTeamAccountStatement(): JSX.Element {
  const { user } = useUserContext()

  const { jar } = useTeamContext()

  const { jarId } = useParams<TeamRoutesParams>()

  const { shareElement } = useShare()

  const currentDate = new Date()
  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const [dateFrom, setDateFrom] = useState<DateTimeValue>(
    lastMonth.toISOString()
  )
  const [dateTo, setDateTo] = useState<DateTimeValue>(currentDate.toISOString())

  const { showToast } = useToast('warning')

  const { showToast: showInfoToast } = useToast('default')

  const { showToast: successShow } = useToast('success')

  const { write } = useFilesystem()

  const { startLoading, stopLoading } = useLoading('accountStatement.loading')

  const handleSave = async (
    accountStatement: AccountStatement
  ): Promise<void> => {
    const csvObject = createPaymentCsv(
      accountStatement.payments,
      accountStatement.transfers,
      user?.lang,
      'jar'
    )

    const tackpayId = Array.isArray(jar?.tackpay_ids)
      ? jar?.tackpay_ids?.[0]?.value
      : jar?.tackpay_ids?.value ?? 'none'
    const fileName = `Jar Transactions List - ${jar?.name} - ${tackpayId}`

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then((res) => {
          console.log('File written')
          shareElement({
            title: 'Account Statement',
            dialogTitle: 'Account Statement',
            url: res.uri
          })
            .then(() => {
              console.log('shared')
              stopLoading()
              successShow(undefined, 'accountStatement.success')
            })
            .catch((error) => {
              console.log('error to share', error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleDownload = (): void => {
    if (dateFrom == null || dateTo == null) {
      showToast(undefined, 'accountStatement.dateRequired')
    } else if (dateFrom > dateTo) {
      showToast(undefined, 'accountStatement.dateInvalid')
    } else {
      startLoading()

      sdk.account_statements
        .create({
          to_date: dateTo.toString(),
          from_date: dateFrom.toString(),
          jar: { id: jarId, type: 'jars' }
        })
        .then((accountStatement) => {
          if (
            (accountStatement?.payments == null ||
              accountStatement.payments.length === 0) &&
            (accountStatement?.transfers == null ||
              accountStatement.transfers.length === 0)
          ) {
            stopLoading()
            showInfoToast(undefined, 'accountStatement.emptyPayment')
          } else {
            void handleSave(accountStatement)
          }
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <>
      <DateRanger
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        preferWheel={false}
      />
      <CardDownloadAccountStatement onClick={handleDownload} />
    </>
  )
}

export default function TeamAccountStatement(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: ['tackpay_ids']
        }
      }}
    >
      <Title hasSubtitle>accountStatement.title</Title>
      <SubTitle>accountStatement.subtitle</SubTitle>
      <Text color='gray-900' fontWeight='font-bold'>
        accountStatement.selectPeriod
      </Text>

      <HandleTeamAccountStatement />
    </AppLayout>
  )
}
