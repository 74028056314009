import { IonDatetime } from '@ionic/react'
import { type ComponentProps } from 'react'

interface Props extends ComponentProps<typeof IonDatetime> {}

export type DateTimeValue = ComponentProps<typeof IonDatetime>['value']
export type DateTimeOnChage = ComponentProps<typeof IonDatetime>['onIonChange']
export default function DateTime(props: Props): JSX.Element {
  return <IonDatetime {...props} showDefaultButtons />
}
