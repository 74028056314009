import { type UserCategory } from '#tackpay-sdk'
import { downloadOutline, walletOutline } from 'ionicons/icons'

interface WithdrawalSetting {
  category: UserCategory
  label: string
  icon: string
  href: string
}

export const getWithdrawalSettings = (
  category: UserCategory,
  url?: string
): WithdrawalSetting[] => {
  const baseUrl = url ?? `/dashboard/${category}/settings`
  const WITHDRAWAL: WithdrawalSetting[] = [
    /** Tipped */
    {
      category: 'tipped',
      label: 'profile.edit.deposit.withdraw',
      icon: downloadOutline,
      href: `${baseUrl}/withdrawals/payouts`
    },
    {
      category: 'tipped',
      label: 'profile.edit.deposit.selected_bank',
      icon: walletOutline,
      href: `${baseUrl}/withdrawals/banks`
    },

    /** Business */
    {
      category: 'business',
      label: 'profile.edit.deposit.withdraw',
      icon: downloadOutline,
      href: `${baseUrl}/withdrawals/payouts`
    },
    {
      category: 'business',
      label: 'profile.edit.deposit.selected_bank',
      icon: walletOutline,
      href: `${baseUrl}/withdrawals/banks`
    }
  ]

  if (category === 'collaborator' || category === 'manager') {
    category = 'business'
  }

  return WITHDRAWAL.filter((item) => item.category === category)
}
