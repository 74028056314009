/* eslint-disable @typescript-eslint/no-empty-interface */
import * as api from './api'
import type { ApiError } from './error'
import type {
  ErrorInterceptor,
  InterceptorType,
  RawResponseReader,
  RequestInterceptor,
  ResponseInterceptor,
  ResponseObj,
  HeadersObj
} from './interceptor'
import { TackPayStatic } from './static'
import ResourceAdapter, { type ResourcesInitConfig } from './resource'

// Autogenerated schema version number, do not remove this line
const OPEN_API_SCHEMA_VERSION = '4.12.0'
export { OPEN_API_SCHEMA_VERSION }

// SDK local configuration
interface SdkConfig {
  // abc?: string
}

type TackPayInitConfig = SdkConfig & ResourcesInitConfig
type TackPayConfig = Partial<TackPayInitConfig>

class TackPayClient {
  // static get openApiSchemaVersion(): string { return OPEN_API_SCHEMA_VERSION }
  readonly openApiSchemaVersion = OPEN_API_SCHEMA_VERSION

  readonly #adapter: ResourceAdapter
  #organization: string
  // #environment: ApiMode = sdkConfig.default.environment

  readonly addresses: api.Addresses
  readonly users: api.Users
  readonly persons: api.Persons
  readonly balances: api.Balances
  readonly bank_accounts: api.BankAccounts
  readonly companies: api.Companies
  readonly checkout_settings: api.CheckoutSettings
  readonly branch_companies: api.BranchCompanies
  readonly links_socials: api.LinksSocials
  readonly replies: api.Replies
  readonly collaborators: api.Collaborators
  readonly contact_us: api.ContactUss
  readonly jars: api.Jars
  readonly stripe_gateway_accounts: api.StripeGatewayAccounts
  readonly stripe_gateway_identities: api.StripeGatewayIdentities
  readonly identities: api.Identities
  readonly tackpay_ids: api.TackpayIds
  readonly materials: api.Materials
  readonly customers: api.Customers
  readonly subscribers: api.Subscribers
  readonly subscriptions: api.Subscriptions
  readonly subscription_items: api.SubscriptionItems
  readonly subscriptions_links: api.SubscriptionLinks
  readonly prices: api.Prices
  readonly payouts: api.Payouts
  readonly payout_retain_fees: api.PayoutRetainFees
  readonly payments: api.Payments
  readonly payment_methods: api.PaymentMethods
  readonly fees: api.Fees
  readonly feedbacks: api.Feedbacks
  readonly comments: api.Comments
  readonly invoices: api.Invoices
  readonly transfers: api.Transfers
  readonly balance_transactions: api.BalanceTransactions
  readonly statistic_balances: api.StatisticBalances
  readonly statistic_views: api.StatisticViews
  readonly statistic_amounts: api.StatisticAmounts
  readonly statistic_feedbacks: api.StatisticFeedbacks
  readonly account_statements: api.AccountStatements
  readonly checkout_sessions: api.CheckoutSessions
  readonly members: api.Members
  readonly tokens: api.Tokens
  readonly product_links: api.ProductLinks
  readonly deduction_settings: api.DeductionSettings
  readonly balance_deductions: api.BalanceDeductions
  readonly bank_account_deductions: api.BankAccountDeductions
  readonly deduction_reports: api.DeductionReports

  constructor(config: TackPayInitConfig) {
    // console.log('new tackpay instance %O', config)

    this.#adapter = new ResourceAdapter(config)
    this.#organization = config.organization ?? ''
    // this.#environment = 'test'
    this.addresses = new api.Addresses(this.#adapter)
    this.users = new api.Users(this.#adapter)
    this.persons = new api.Persons(this.#adapter)
    this.balances = new api.Balances(this.#adapter)
    this.bank_accounts = new api.BankAccounts(this.#adapter)
    this.checkout_settings = new api.CheckoutSettings(this.#adapter)
    this.companies = new api.Companies(this.#adapter)
    this.branch_companies = new api.BranchCompanies(this.#adapter)
    this.links_socials = new api.LinksSocials(this.#adapter)
    this.replies = new api.Replies(this.#adapter)
    this.collaborators = new api.Collaborators(this.#adapter)
    this.contact_us = new api.ContactUss(this.#adapter)
    this.jars = new api.Jars(this.#adapter)
    this.stripe_gateway_accounts = new api.StripeGatewayAccounts(this.#adapter)
    this.stripe_gateway_identities = new api.StripeGatewayIdentities(
      this.#adapter
    )
    this.identities = new api.Identities(this.#adapter)
    this.tackpay_ids = new api.TackpayIds(this.#adapter)
    this.materials = new api.Materials(this.#adapter)
    this.customers = new api.Customers(this.#adapter)
    this.subscribers = new api.Subscribers(this.#adapter)
    this.subscriptions = new api.Subscriptions(this.#adapter)
    this.subscription_items = new api.SubscriptionItems(this.#adapter)
    this.subscriptions_links = new api.SubscriptionLinks(this.#adapter)
    this.prices = new api.Prices(this.#adapter)
    this.payouts = new api.Payouts(this.#adapter)
    this.payout_retain_fees = new api.PayoutRetainFees(this.#adapter)
    this.payments = new api.Payments(this.#adapter)
    this.fees = new api.Fees(this.#adapter)
    this.feedbacks = new api.Feedbacks(this.#adapter)
    this.comments = new api.Comments(this.#adapter)
    this.invoices = new api.Invoices(this.#adapter)
    this.transfers = new api.Transfers(this.#adapter)
    this.balance_transactions = new api.BalanceTransactions(this.#adapter)
    this.statistic_balances = new api.StatisticBalances(this.#adapter)
    this.statistic_views = new api.StatisticViews(this.#adapter)
    this.statistic_amounts = new api.StatisticAmounts(this.#adapter)
    this.statistic_feedbacks = new api.StatisticFeedbacks(this.#adapter)
    this.account_statements = new api.AccountStatements(this.#adapter)
    this.checkout_sessions = new api.CheckoutSessions(this.#adapter)
    this.members = new api.Members(this.#adapter)
    this.tokens = new api.Tokens(this.#adapter)
    this.payment_methods = new api.PaymentMethods(this.#adapter)
    this.product_links = new api.ProductLinks(this.#adapter)
    this.deduction_settings = new api.DeductionSettings(this.#adapter)
    this.balance_deductions = new api.BalanceDeductions(this.#adapter)
    this.bank_account_deductions = new api.BankAccountDeductions(this.#adapter)
    this.deduction_reports = new api.DeductionReports(this.#adapter)
  }

  get currentOrganization(): string {
    return this.#organization
  }
  // get currentAccessToken(): string { return this.#adapter?.clientInstance?.currentAccessToken}
  // get environment(): ApiMode { return this.#environment }

  private localConfig(config: SdkConfig & { organization?: string }): void {
    if (config.organization != null) this.#organization = config.organization
  }

  config(config: TackPayConfig): this {
    // console.log('config %o', config)

    // TackPay config
    this.localConfig(config)
    // ResourceAdapter config
    // To rebuild baseUrl in client in case only the domain is defined
    if (config.organization != null)
      config.organization = this.currentOrganization
    this.#adapter.config(config)

    return this
  }

  resources(): readonly string[] {
    return TackPayStatic.resources()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  isApiError(error: any): error is ApiError {
    return TackPayStatic.isApiError(error)
  }

  addRequestInterceptor(
    onFulfilled?: RequestInterceptor,
    onRejected?: ErrorInterceptor
  ): number {
    return this.#adapter.interceptors.request.use(onFulfilled, onRejected)
  }

  addResponseInterceptor(
    onFulfilled?: ResponseInterceptor,
    onRejected?: ErrorInterceptor
  ): number {
    return this.#adapter.interceptors.response.use(onFulfilled, onRejected)
  }

  removeInterceptor(type: InterceptorType, id: number): void {
    this.#adapter.interceptors[type].eject(id)
  }

  addRawResponseReader(options?: { headers: boolean }): RawResponseReader {
    const reader: RawResponseReader = {
      id: undefined,
      rawResponse: undefined,
      headers: undefined
    }

    function rawResponseInterceptor(response: ResponseObj): ResponseObj {
      reader.rawResponse = response?.data
      if (options?.headers != null)
        reader.headers = response.headers as HeadersObj
      return response
    }

    const interceptor = this.addResponseInterceptor(rawResponseInterceptor)
    reader.id = interceptor

    return reader
  }

  removeRawResponseReader(reader: number | RawResponseReader): void {
    const id = typeof reader === 'number' ? reader : reader?.id
    if (id != null && id >= 0) this.removeInterceptor('response', id)
  }
}

const TackPay = (config: TackPayInitConfig): TackPayClient => {
  return new TackPayClient(config)
}

export default TackPay
export { TackPay }

export type { TackPayClient, TackPayConfig, TackPayInitConfig }
