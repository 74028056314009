import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type BaseBalance } from './balances'
import { type User } from './users'
import { type Jar } from './jars'
import { type QueryParamsRetrieve } from 'sdk/query'

type MemberType = 'members'
type MemberRel = ResourceRel & { type: MemberType }

type InvitationType = 'token' | 'email' | 'notification'
type MemberStatus =
  | 'invitation_pending'
  | 'invitation_accepted'
  | 'invitation_rejected'
  | 'invitation_revoked'

type MemberRole = 'worker'

type JarRel = ResourceRel & { type: 'jars' }

type UserRel = ResourceRel & { type: 'users' }

interface Member extends Resource {
  readonly type: MemberType
  email?: string
  last_activity?: Date
  balance: BaseBalance
  active: boolean
  total_tip: number
  total_feedback: number
  total_comment: number
  invitation_type: InvitationType
  status: MemberStatus
  role: MemberRole
  user?: User
  jar?: Jar
}

interface MemberCreate extends ResourceCreate {
  invitation_type: InvitationType
  status?: MemberStatus
  email?: string
  role?: MemberRole
  token?: string
  jar: JarRel
  user?: UserRel
}

interface MemberUpdate extends ResourceUpdate {
  role?: MemberRole
  active?: boolean
  status?: MemberStatus
  user?: UserRel
}

class Members extends ApiResource<Member> {
  static readonly TYPE: MemberType = 'members' as const

  async create(
    resource: MemberCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Member> {
    return await this.resources.create<MemberCreate, Member>(
      { ...resource, type: Members.TYPE },
      params,
      options
    )
  }

  async update(
    resource: MemberUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Member> {
    return await this.resources.update<MemberUpdate, Member>(
      { ...resource, type: Members.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Members.TYPE } : id,
      options
    )
  }

  isMember(resource: any): resource is Member {
    return resource.type != null && resource.type === Members.TYPE
  }

  relationship(id: string | ResourceId | null): MemberRel {
    return id === null || typeof id === 'string'
      ? { id, type: Members.TYPE }
      : { id: id.id, type: Members.TYPE }
  }

  type(): MemberType {
    return Members.TYPE
  }
}

export type { Member, MemberCreate, MemberUpdate, MemberStatus }
export default Members
