import { Share } from '@capacitor/share'

export function useShare(): { shareElement: typeof shareElement } {
  return {
    shareElement
  }
}

interface TParams {
  title: string
  text?: string
  url?: string
  files?: string[]
  dialogTitle: string
}

async function shareElement({
  title,
  text,
  url,
  files,
  dialogTitle
}: TParams): Promise<ReturnType<typeof Share.share> | undefined> {
  try {
    const shareable = await Share.canShare()
    if (shareable.value) {
      console.log('can share')
    }

    return await Share.share({
      title,
      text,
      url,
      files,
      dialogTitle
    })
  } catch (error) {
    console.log('Share Error: ', error)
    return undefined
  }
}
