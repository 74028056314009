import AddressAutoComplete from '#components/common/AddressAutoComplete'
import ErrorBox from '#components/errors/ErrorBox'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Button from '#components/ui/Button'
import { type DateTimeOnChage } from '#components/ui/DateTime'
import Input, { type InputOnChange } from '#components/ui/Input'
import Modal from '#components/ui/Modal'
import ModalLoading from '#components/ui/ModalLoading'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import { useUserContext } from '#contexts/UserContext'
import { type Gender, type Address, type Person } from '#tackpay-sdk'
import formattedBirthdate from '#utils/formattedBirthdate'
import isDesktop from '#utils/isDesktop'
import {
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonRow
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveModalDeduction from './SaveModalDeduction'

interface Props {
  percentage: number
}

export default function PersonalDataDeduction({
  percentage
}: Props): JSX.Element {
  const { user } = useUserContext()

  const { jar, isLoading, error } = useTeamContext()

  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const [person, setPerson] = useState<Partial<Person>>({
    ...user?.person
  })

  const [showModal, setShowModal] = useState<boolean>(false)

  const [address, setAddress] = useState<Partial<Address>>({
    ...user?.person?.address
  })

  useEffect(() => {
    if (user?.person != null) {
      setPerson({ ...user?.person })
    }

    if (user?.person?.address != null) {
      setAddress({ ...user?.person?.address })
    }
  }, [user?.person, user?.person?.address])

  if (isLoading) return <ModalLoading />

  if (error != null) return <ErrorBox error={error} />

  if (jar == null) return <div>Jar not found, please refresh page</div>

  const getBirthdateString = (): string | undefined => {
    if (person?.birthdate != null) {
      return `${person?.birthdate.day}/${person?.birthdate.month}/${person?.birthdate.year}`
    }
    return undefined
  }

  const handleGender: SelectOnChangeEvent = (event) => {
    const gender = event.target.value as Gender
    setPerson({ ...person, gender })
  }

  const handleBirthdateChange: DateTimeOnChage = (e) => {
    const { value } = e.detail
    if (typeof value === 'string') {
      const birthdateDate = formattedBirthdate(value)
      setPerson({ ...person, birthdate: birthdateDate })
    }
  }

  // const handleCountryChange = (country: string): void => {
  //   setAddress({
  //     ...address,
  //     country_name: country,
  //     country_code: getIsoByCountry(country)
  //   })
  // }

  const handleAddressChange = (addressProps: Partial<Address>): void => {
    setAddress({
      city: addressProps?.city ?? address?.city,
      street: addressProps?.street ?? address?.street,
      street_number: addressProps?.street_number ?? address?.street_number,
      postal_code: addressProps?.postal_code ?? address?.postal_code,
      state: addressProps?.state ?? address?.state,
      type: addressProps?.type ?? address?.type,
      id: addressProps?.id ?? address?.id,
      country_name: address?.country_name,
      country_code: address?.country_code
    })
  }

  const handleInputChanges: InputOnChange = (event) => {
    const { name, value } = event.target
    if (typeof value === 'string') {
      if (name === 'lastName') setPerson({ ...person, last_name: value })
      if (name === 'city') setAddress({ ...address, city: value })
      if (name === 'street') setAddress({ ...address, street: value })
      if (name === 'street_number')
        setAddress({ ...address, street_number: value })
      if (name === 'postal_code') setAddress({ ...address, postal_code: value })
      if (name === 'state') setAddress({ ...address, state: value })
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setShowModal(true)
  }

  const isSubmittable = (): boolean => {
    return (
      percentage > 0 &&
      person != null &&
      address != null &&
      person?.last_name != null &&
      person?.gender != null &&
      person?.birthdate != null &&
      address.street != null &&
      address.street_number != null &&
      address.postal_code != null &&
      address.country_name != null &&
      person.first_name != null &&
      address.city != null
    )
  }

  return jar.deduction_setting == null &&
    jar.stripe_gateway_account == null &&
    jar.company?.stripe_gateway_account == null ? (
    <IonRow className='mt-5 w-full block'>
      <SaveModalDeduction
        show={showModal}
        setShow={setShowModal}
        percentage={percentage}
        address={address}
        person={person}
        type='create'
      />
      <Text color='gray-900' fontWeight='font-medium' size='text-xl'>
        deduction.personal_data_title
      </Text>
      <Text>deduction.personal_data_subtitle</Text>
      <form className='mt-5 space-y-5' onSubmit={handleSubmit}>
        <Input
          name='lastName'
          type='text'
          value={person?.last_name}
          onIonInput={handleInputChanges}
          labelOptions={{ children: 'lastName', capitalize: false }}
          testId='lastName'
        />
        <IonRow className='ion-align-items-center ion-justify-content-between space-x-1 ion-no-padding ion-no-margin'>
          <IonCol className='ion-no-padding ion-no-margin'>
            <IonDatetimeButton
              datetime='date'
              // placeholder='date'
              className='hidden'
              id='open-modal'
            />
            <Input
              type='text'
              value={getBirthdateString()}
              placeholder='DD/MM/YYYY'
              labelOptions={{
                children: 'birthdate',
                capitalize: false
              }}
              onFocus={() => {
                setOpenModal(!openModal)
              }}
            />
            <Modal
              trigger='open-modal'
              keepContentsMounted
              isOpen={openModal}
              onIonModalWillDismiss={() => {
                setOpenModal(false)
              }}
            >
              <IonDatetime
                id='date'
                presentation='date'
                // placeholder='Select Date'
                preferWheel={!isDesktop()}
                doneText='Done'
                value={getBirthdateString()}
                onIonChange={handleBirthdateChange}
              />
              <IonRow className='w-full ion-justify-content-between ion-no-margin ion-no-padding row-button-modal'>
                <IonButton
                  fill='clear'
                  className='w-1/3 ion-no-margin ion-no-padding button-delete font-semibold'
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  Cancel
                </IonButton>

                <IonButton
                  fill='clear'
                  className='w-1/3 ion-no-margin ion-no-padding button-done font-semibold text-green-400'
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  Done
                </IonButton>
              </IonRow>
            </Modal>
          </IonCol>
          <IonCol className='ion-no-margin ion-no-padding'>
            <BasicSelect
              options={{
                childrens: ['male', 'female']
              }}
              placeholder={t('gender') ?? ''}
              interface={isDesktop() ? 'popover' : 'action-sheet'}
              labelOptions={{
                show: true,
                options: {
                  children: 'gender'
                }
              }}
              onIonChange={handleGender}
              value={person?.gender}
            />
          </IonCol>
        </IonRow>
        <div className='mt-5'>
          <AddressAutoComplete setAddress={handleAddressChange} />
          {/* <CountrySelect
            country={address?.country_name ?? ''}
            setSelectionCountry={handleCountryChange}
          /> */}

          <div>
            <Input
              name='city'
              type='text'
              value={address?.city}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'city'
              }}
              testId='city'
            />
          </div>

          <div className='mt-3'>
            <Input
              name='street'
              type='text'
              value={address?.street}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'street'
              }}
              testId='street'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='street_number'
              type='text'
              value={address?.street_number}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'streetNumber',
                capitalize: false
              }}
              testId='streetNumber'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='postal_code'
              type='text'
              value={address?.postal_code}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'postalCode',
                capitalize: false
              }}
              testId='postalCode'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='state'
              type='text'
              value={address?.state}
              onIonInput={handleInputChanges}
              labelOptions={{ children: 'state' }}
              testId='state'
            />
          </div>
        </div>
        <Button className='w-full' type='submit' disabled={!isSubmittable()}>
          deduction.save
        </Button>
      </form>
    </IonRow>
  ) : (
    <></>
  )
}
