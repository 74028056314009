import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../../TeamRoutes'
import TeamContainer, { useTeamContext } from '#contexts/JarContext'
import TeamTitle from '#components/pages/teams/TeamTitle'
import TeamSettingList from '#components/pages/teams/TeamSettingList'
import { type UserCategory } from '#tackpay-sdk'
import TeamQrCodeSmart from '#components/pages/teams/TeamQrCodeSmart'

function HandleTeamSetting({
  category
}: {
  category: UserCategory
}): JSX.Element {
  const { jarId } = useParams<TeamRoutesParams>()

  const { jar } = useTeamContext()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars`
        },
        showCustomIcon: true,
        customIconOptions: {
          slot: 'end',
          icon: <TeamQrCodeSmart jar={jar} />
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true,
        params: {
          include: ['balance', 'tackpay_ids']
        }
      }}
    >
      <TeamTitle />
      <TeamSettingList category={category} jarId={jarId} />
    </AppLayout>
  )
}

export default function TeamSetting(): JSX.Element {
  const { jarId, category } = useParams<TeamRoutesParams>()

  return (
    <TeamContainer
      jarId={jarId}
      fetch
      params={{
        include: ['balance', 'tackpay_ids']
      }}
    >
      <HandleTeamSetting category={category} />
    </TeamContainer>
  )
}
