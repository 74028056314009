/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type CollaboratorRole, type UserCategory } from '#tackpay-sdk'
import {
  cogOutline,
  homeOutline,
  qrCodeOutline,
  peopleOutline,
  businessOutline,
  // peopleCircle,
  documentOutline,
  walletOutline,
  medal,
  cashOutline,
  imageOutline
} from 'ionicons/icons'

interface SideMenu {
  title: string
  icon: string
  url: string
  category: UserCategory
  role?: CollaboratorRole[]
  showMobile: boolean
  showDesktop: boolean
}

const SIDE_MENUS: SideMenu[] = [
  /** Business */
  {
    title: 'sidemenu.dashboard',
    icon: homeOutline,
    url: '/dashboard/business/home',
    category: 'business',
    showMobile: true,
    showDesktop: true
  },
  /** Desktop */
  {
    title: 'teamPage.title',
    icon: peopleOutline,
    url: '/dashboard/business/jars',
    category: 'business',
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'wallet.title',
    icon: walletOutline,
    url: '/dashboard/business/wallets',
    category: 'business',
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'deduction.side_menu',
    icon: walletOutline,
    url: '/dashboard/business/deductions',
    category: 'business',
    showDesktop: true,
    showMobile: true
  },
  {
    title: 'feedbackPage.title',
    icon: medal,
    category: 'business',
    url: '/dashboard/business/feedbacks',
    showDesktop: false,
    showMobile: false
  },
  {
    title: 'Others',
    icon: '',
    url: '',
    category: 'business',
    showMobile: false,
    showDesktop: true
  },
  {
    title: 'sidemenu.plan',
    icon: documentOutline,
    url: '/dashboard/business/plans',
    category: 'business',
    showMobile: true,
    showDesktop: true
  },
  {
    category: 'business',
    title: 'setting_dashboard.deposit',
    icon: cashOutline,
    url: `/dashboard/business/withdrawals`,
    showDesktop: true,
    showMobile: true
  },
  {
    title: 'sidemenu.structure',
    icon: businessOutline,
    url: '/dashboard/business/companies',
    category: 'business',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.setting',
    icon: cogOutline,
    url: '/dashboard/business/settings',
    category: 'business',
    showMobile: true,
    showDesktop: true
  },
  /** Tipped */
  {
    title: 'sidemenu.dashboard',
    icon: homeOutline,
    url: '/dashboard/tipped/home',
    category: 'tipped',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'wallet.title',
    icon: walletOutline,
    url: '/dashboard/tipped/wallets',
    category: 'tipped',
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'sidemenu.jar',
    icon: peopleOutline,
    url: '/dashboard/tipped/jars',
    category: 'tipped',
    showMobile: true,
    showDesktop: true
  },
  {
    category: 'tipped',
    title: 'setting_dashboard.deposit',
    icon: cashOutline,
    url: `/dashboard/tipped/settings/withdrawals`,
    showDesktop: true,
    showMobile: true
  },
  {
    title: 'Others',
    icon: '',
    url: '',
    category: 'tipped',
    showMobile: false,
    showDesktop: true
  },
  {
    title: 'sidemenu.material',
    icon: qrCodeOutline,
    url: '/dashboard/tipped/materials',
    category: 'tipped',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.profile',
    icon: imageOutline,
    url: '/dashboard/tipped/checkout-settings',
    category: 'tipped',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.setting',
    icon: cogOutline,
    url: '/dashboard/tipped/settings',
    category: 'tipped',
    showMobile: true,
    showDesktop: true
  },
  /** Manager */
  {
    title: 'sidemenu.dashboard',
    icon: homeOutline,
    url: '/dashboard/manager/home',
    category: 'manager',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'teamPage.title',
    icon: '/assets/img/jar.svg',
    url: '/dashboard/manager/jars',
    category: 'manager',
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'wallet.title',
    icon: walletOutline,
    url: '/dashboard/manager/wallets',
    category: 'manager',
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'deduction.side_menu',
    icon: walletOutline,
    url: '/dashboard/manager/deductions',
    category: 'manager',
    showDesktop: true,
    showMobile: true
  },
  {
    title: 'Others',
    icon: '',
    url: '',
    category: 'manager',
    showMobile: false,
    showDesktop: true
  },
  {
    title: 'sidemenu.plan',
    icon: documentOutline,
    url: '/dashboard/manager/plans',
    category: 'manager',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.structure',
    icon: businessOutline,
    url: '/dashboard/manager/companies',
    category: 'manager',
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.setting',
    icon: cogOutline,
    url: '/dashboard/manager/settings',
    category: 'manager',
    showMobile: true,
    showDesktop: true
  },

  /** Collaborator ADMIN */
  {
    title: 'sidemenu.dashboard',
    icon: homeOutline,
    url: '/dashboard/collaborator/home',
    category: 'collaborator',
    role: ['admin', 'manager'],
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'teamPage.title',
    icon: peopleOutline,
    url: '/dashboard/collaborator/jars',
    category: 'collaborator',
    role: ['manager', 'admin'],
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'wallet.title',
    icon: walletOutline,
    url: '/dashboard/collaborator/wallets',
    category: 'collaborator',
    role: ['manager', 'admin'],
    showDesktop: true,
    showMobile: false
  },
  {
    title: 'deduction.side_menu',
    icon: walletOutline,
    url: '/dashboard/collaborator/deductions',
    category: 'collaborator',
    role: ['manager', 'admin'],
    showDesktop: true,
    showMobile: true
  },
  {
    title: 'Others',
    icon: '',
    url: '',
    category: 'collaborator',
    role: ['admin', 'manager'],
    showMobile: false,
    showDesktop: true
  },
  {
    title: 'sidemenu.structure',
    icon: businessOutline,
    url: '/dashboard/collaborator/companies',
    category: 'collaborator',
    role: ['admin'],
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.plan',
    icon: documentOutline,
    url: '/dashboard/collaborator/plans',
    category: 'collaborator',
    role: ['admin'],
    showMobile: true,
    showDesktop: true
  },
  {
    title: 'sidemenu.setting',
    icon: cogOutline,
    url: '/dashboard/collaborator/settings',
    category: 'collaborator',
    role: ['admin', 'manager'],
    showMobile: true,
    showDesktop: true
  }
]

export const getSideMenu = (
  category: UserCategory,
  role?: CollaboratorRole,
  isDesktop: boolean = false
): SideMenu[] => {
  const filterFunction = (menu: SideMenu): boolean | undefined => {
    if (menu.category !== category) return false
    if (isDesktop) {
      return (
        menu.showDesktop === isDesktop &&
        (!role || menu.role?.includes(role)) &&
        menu.category.includes(category)
      )
    } else {
      return (
        menu.showMobile === !isDesktop && (!role || menu.role?.includes(role))
      )
    }
  }

  return SIDE_MENUS.filter(filterFunction)
}
