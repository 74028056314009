import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Payment } from './payments'
import { type QueryParamsRetrieve } from 'sdk/query'

type FeedbackType = 'feedbacks'
type FeedbackRel = ResourceRel & { type: FeedbackType }

type FeedbackCategory = 'individual' | 'jar'

type PaymentRel = ResourceRel & { type: 'payments' }

interface Feedback extends Resource {
  readonly type: FeedbackType

  name: string
  category: FeedbackCategory
  payment?: Payment
}

interface FeedbackCreate extends ResourceCreate {
  name: string
  category: FeedbackCategory
  payment: PaymentRel
}

class Feedbacks extends ApiResource<Feedback> {
  static readonly TYPE: FeedbackType = 'feedbacks' as const

  async create(
    resource: FeedbackCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Feedback> {
    return await this.resources.create(
      { ...resource, type: Feedbacks.TYPE },
      params,
      options
    )
  }

  isFeedback(resource: any): resource is Feedback {
    return resource.type != null && resource.type === Feedbacks.TYPE
  }

  relationship(id: string | ResourceId | null): FeedbackRel {
    return id === null || typeof id === 'string'
      ? { id, type: Feedbacks.TYPE }
      : { id: id.id, type: Feedbacks.TYPE }
  }

  type(): FeedbackType {
    return Feedbacks.TYPE
  }
}

export type { Feedback, FeedbackCreate }
export default Feedbacks
