import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import TeamSetting from './views/TeamSetting'
import CheckoutSetting from './views/CheckoutSetting'
import StatisticFeedbacks from './views/StatisticFeedbacks'
import TippingRule from './views/TippingRule'
import TeamMaterialRoutes from './views/materials/TeamMaterialRoutes'
import TeamWallet from './views/TeamWallet'
import TeamWithdrawalRoutes from './views/withdrawals/TeamWithdrawalRoutes'
import TeamIdentity from './views/TeamIdentity'
import TeamAccountStatement from './views/TeamAccountStatement'
import TeamPayment from './views/TeamPayment'
import { lazy, Suspense } from 'react'
import TeamTransfer from './views/TeamTransfer'
import TeamDivideTip from './views/TeamDivideTip'
import ModalLoading from '#components/ui/ModalLoading'
import TeamTroncHistory from './views/TeamTroncHistory'
import TeamMemberAccountStatement from './views/TeamMemberAccountStatement'
import JarDeductionSetting from './views/deductions/JarDeductionSetting'
import JarDeduction from './views/deductions/JarDeduction'

const TeamStatistic = lazy(async () => await import('./views/TeamStatistic'))

const baseRoute = `/dashboard/:category/jars/:jarId/settings`

export default function TeamSettingRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={baseRoute}>
          <TeamSetting />
        </Route>
        <Route exact path={`${baseRoute}/checkout-settings`}>
          <CheckoutSetting />
        </Route>
        <Route exact path={`${baseRoute}/feedbacks`}>
          <StatisticFeedbacks />
        </Route>
        <Route exact path={`${baseRoute}/tipping-rules`}>
          <TippingRule />
        </Route>
        <Route path={`${baseRoute}/materials`}>
          <TeamMaterialRoutes />
        </Route>
        <Route exact path={`${baseRoute}/identities`}>
          <TeamIdentity />
        </Route>
        <Route exact path={`${baseRoute}/tronc`}>
          <TeamDivideTip />
        </Route>
        <Route exact path={`${baseRoute}/tronc/history`}>
          <TeamTroncHistory />
        </Route>
        <Route exact path={`${baseRoute}/payments`}>
          <TeamWallet />
        </Route>
        <Route exact path={`${baseRoute}/payments/:paymentId`}>
          <TeamPayment />
        </Route>
        <Route exact path={`${baseRoute}/transfers/:transferId`}>
          <TeamTransfer />
        </Route>
        <Route exact path={`${baseRoute}/statistics`}>
          <Suspense fallback={<ModalLoading />}>
            <TeamStatistic />
          </Suspense>
        </Route>
        <Route path={`${baseRoute}/withdrawals`}>
          <TeamWithdrawalRoutes />
        </Route>
        <Route exact path={`${baseRoute}/deductions`}>
          <JarDeductionSetting />
        </Route>
        <Route exact path={`${baseRoute}/deductions/edit`}>
          <JarDeduction />
        </Route>
        <Route exact path={`${baseRoute}/deductions/identities`}>
          <TeamIdentity isDeduction />
        </Route>
        <Route exact path={`${baseRoute}/account-statements`}>
          <TeamAccountStatement />
        </Route>
        <Route exact path={`${baseRoute}/account-statements-members`}>
          <TeamMemberAccountStatement />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
