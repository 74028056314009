import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type Customer } from './customers'
import { type QueryParamsRetrieve } from 'sdk/query'

type ProductLinkType = 'product_links'
type ProductLinkRel = ResourceRel & { type: ProductLinkType }

type CustomerRel = ResourceRel & { type: 'customers' }

interface ProductLink extends Resource {
  readonly type: ProductLinkType

  quantity: number
  product_reference: string
  return_url: string
  cancel_url: string
  url: string
  customer?: Customer
}

interface ProductLinkCreate extends ResourceCreate {
  quantity: number
  product_reference: string
  return_url: string
  cancel_url: string
  customer: CustomerRel
}

class ProductLinks extends ApiResource<ProductLink> {
  static readonly TYPE: ProductLinkType = 'product_links' as const

  async create(
    resource: ProductLinkCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<ProductLink> {
    return await this.resources.create<ProductLinkCreate, ProductLink>(
      { ...resource, type: ProductLinks.TYPE },
      params,
      options
    )
  }

  isProductLink(resource: any): resource is ProductLink {
    return resource.type != null && resource.type === ProductLinks.TYPE
  }

  relationship(id: string | ResourceId | null): ProductLinkRel {
    return id === null || typeof id === 'string'
      ? { id, type: ProductLinks.TYPE }
      : { id: id.id, type: ProductLinks.TYPE }
  }

  type(): ProductLinkType {
    return ProductLinks.TYPE
  }
}

export type { ProductLink, ProductLinkCreate }
export default ProductLinks
