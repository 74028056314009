import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import isCollaborator from '#utils/isCollaborator'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '../DashboardRoutes'
import { type Jar, type UserCategory } from '#tackpay-sdk'
import SelectTeam from '#components/common/TeamSelection'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useEffect, useState } from 'react'
import { IonRow, useIonRouter } from '@ionic/react'
import Text from '#components/ui/Text'
import Button from '#components/ui/Button'
import ModalLoading from '#components/ui/ModalLoading'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'

function HandleWithdrawals({
  category
}: {
  category: UserCategory
}): JSX.Element {
  const { push } = useIonRouter()
  const { company, isLoading } = useCompanyContext()

  const { collaborator } = useCollaboratorContext()

  const [currentTeam, setCurrentTeam] = useState<Jar | undefined>()

  const [jars, setJars] = useState<Jar[]>([])

  useEffect(() => {
    if (!isCollaborator(category)) {
      if (company?.jars != null) {
        setJars(company?.jars?.filter((j) => j.jar_type === 'private') ?? [])
      }
    } else {
      if (collaborator?.jars != null) {
        setJars(
          collaborator?.jars?.filter((j) => j.jar_type === 'private') ?? []
        )
      }
    }
  }, [company?.jars, collaborator?.jars])

  const handleClick = (): void => {
    if (currentTeam == null) return
    push(
      `/dashboard/${category}/jars/${currentTeam?.id}/settings/withdrawals/payouts`
    )
  }

  if (isLoading) return <ModalLoading />

  return (
    <div className='mt-5'>
      {jars.length > 0 ? (
        <SelectTeam
          labelProps={{
            show: true,
            options: {
              translationOptions: {
                translate: 'yes'
              },
              capitalize: false,
              children: 'collaborator.selectTeam'
            }
          }}
          jars={company?.jars?.filter((j) => j.jar_type === 'private') ?? []}
          currentTeam={currentTeam}
          setCurrentTeam={setCurrentTeam}
          multiple={false}
        />
      ) : (
        <IonRow>
          <Text>not_have_jar_for_payout</Text>
        </IonRow>
      )}
      <div className='mt-10'>
        <Button
          disabled={currentTeam == null || jars.length <= 0}
          className='w-full'
          onClick={handleClick}
        >
          go_jar_payout
        </Button>
      </div>
    </div>
  )
}

export default function WithdrawalBusiness(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      companyProps={{
        fetch: true,
        isCollaborator: isCollaborator(category),
        params: {
          include: ['jars', 'tackpay_id']
        }
      }}
      collaboratorProps={{
        fetch: isCollaborator(category),
        params: {
          include: ['jars']
        }
      }}
    >
      <Title>withdrawal.title</Title>
      <HandleWithdrawals category={category} />
    </AppLayout>
  )
}
