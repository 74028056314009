import Empty from '#components/ui/Empty'

interface Props {
  isDonation?: boolean
}
export default function EmptyStatisticAmount({
  isDonation = false
}: Props): JSX.Element {
  return (
    <Empty
      imgOptions={{
        src: '/assets/img/empty-statistic-amount.svg',
        alt: 'emptyStatisticAmount',
        className: 'w-10/12 mx-auto md:w-6/12'
      }}
      textOptions={{
        text: isDonation ? 'empty_donations' : 'statisticsPage.empty',
        props: {
          color: 'gray-500',
          size: 'text-base',
          className: 'text-center italic font-semibold mt-10'
        }
      }}
    />
  )
}
