import AppLayout from '#components/layouts/app/AppLayout'
import AddBankButton from '#components/pages/banks/AddBankButton'
import BankAccountSection from '#components/pages/banks/BankAccountSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankContainer from '#contexts/BankContext'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { IonCol } from '@ionic/react'
import { useParams } from 'react-router'

export default function TeamBankAccount(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/withdrawals`
        }
      }}
      teamProps={{
        fetch: true
      }}
    >
      <BankContainer fetch jarId={jarId}>
        <Title hasSubtitle>bank.title</Title>
        <SubTitle>bank.subtitle</SubTitle>
        <IonCol className='block mt-10'>
          <BankAccountSection />
        </IonCol>
        <AddBankButton
          href={`/dashboard/${category}/jars/${jarId}/settings/withdrawals/banks/add`}
        />
      </BankContainer>
    </AppLayout>
  )
}
