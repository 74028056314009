import { type Member } from '#tackpay-sdk'

const getTackpayId = (member: Member): string => {
  let tackpayId = 'unknown'
  if (member?.jar?.tackpay_ids != null) {
    if (
      Array.isArray(member.jar.tackpay_ids) &&
      member.jar.tackpay_ids.length > 0
    ) {
      tackpayId = member.jar.tackpay_ids[0].value
    } else if (
      !Array.isArray(member.jar.tackpay_ids) &&
      member.jar.tackpay_ids.value != null
    ) {
      tackpayId = member.jar.tackpay_ids.value
    }
  }
  return tackpayId
}

export default function csvMemberActive(members: Member[]): string {
  const labels =
    'Barattolo,TackPay ID Barattolo,Nome,Cognome,TackPay ID Membro,Email,Attivo\n'

  let csvContent = labels // Intestazione

  members.forEach((member) => {
    const jarTackpayId = getTackpayId(member)
    const jarName = member.jar?.name ?? 'unknown'
    const userTackpayId = member.user?.tackpay_id?.value ?? 'unknown'
    const active = member.active ? 'Si' : 'No'
    csvContent += `${jarName.replaceAll(',', '')},${jarTackpayId},${member.user
      ?.person?.first_name},${member.user?.person
      ?.last_name},${userTackpayId},${member?.user?.email},${active}\n`
  })

  return csvContent
}
