import { IonText } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { type TranslationOptions } from '#types/translate'

interface TitleProps extends ComponentProps<typeof IonText> {
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * If the title has subtitle.
   */
  hasSubtitle?: boolean
}
export default function Title(props: TitleProps): JSX.Element {
  const { children, translationOptions, hasSubtitle, ...ionTextProps } = props

  const { t } = useTranslation()

  const title =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children

  const color = ionTextProps?.color ?? 'gray-900'

  const marginTitle = hasSubtitle === true ? 'mb-4' : 'mb-6'

  const titleBaseClass =
    'text-4xl font-extrabold leading-none tracking-tight md:text-4xl lg:text-4xl  mt-6'

  const titleClass = classnames(
    ionTextProps?.className,
    titleBaseClass,
    marginTitle
  )

  return (
    <IonText {...ionTextProps} color={color}>
      <h1 className={titleClass}>{title}</h1>
    </IonText>
  )
}
