import SearchBar, {
  type SearchBarOnChange,
  type SearchBarValue
} from '#components/ui/SearchBar'
import useToast from '#hooks/useToast'
import { type TackpayId } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { useRef, useState } from 'react'
import SearchWelcomeCard from './SearchWelcomeCard'
import textUtil from '#utils/textUtil'
import { animationsFade } from '#utils/animations'

export default function SearchWelcome(): JSX.Element {
  const { showToast } = useToast('warning')

  const cardSearch = useRef<HTMLDivElement | null>(null)

  const [tackpayId, setTackpayId] = useState<SearchBarValue>('')

  const [searchResults, setSearchResults] = useState<TackpayId[]>([])

  const handleChange: SearchBarOnChange = (e) => {
    const value = e.target.value

    setTackpayId(value)

    if (typeof value !== 'string' || textUtil.isEmpty(value)) {
      setSearchResults([])
      animationsFade({
        typeAnimation: cardSearch,
        start: '0',
        end: '-45px'
      })
    } else {
      sdk.tackpay_ids
        .list({
          include: ['user.person', 'company', 'jar'],
          filters: {
            like: {
              value: `%${value.toUpperCase()}%`
            }
          },
          pageSize: 3
        })
        .then((res) => {
          const result = res.filter(
            (t) =>
              t.user?.category !== 'business' &&
              t.user?.category !== 'collaborator' &&
              t.user?.category !== 'manager'
          )

          setSearchResults(result)

          animationsFade({
            typeAnimation: cardSearch,
            start: '-45px',
            end: '0'
          })
        })
        .catch((error) => {
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <div className='relative'>
      <SearchBar
        class='searchWelcome'
        value={tackpayId}
        onIonInput={handleChange}
        tabIndex={0}
        className='ion-no-padding mt-10 '
        animated
      />

      <div ref={cardSearch} className='space-y-6 z-30 absolute w-full'>
        {searchResults.length > 0 &&
          searchResults.map((tackpayId) => (
            <SearchWelcomeCard key={tackpayId.id} tackpayId={tackpayId} />
          ))}
      </div>
    </div>
  )
}
