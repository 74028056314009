import { type Transfer, type Payment, type TackpayId } from '#tackpay-sdk'

const getTackpayId = (tackpayId?: TackpayId | TackpayId[]): string => {
  if (Array.isArray(tackpayId)) {
    return tackpayId?.[0]?.value ?? 'none'
  } else {
    return tackpayId?.value ?? 'none'
  }
}

export default function createPaymentCsv(
  payments?: Payment[],
  transfers?: Transfer[],
  lang: string = 'en',
  category: 'jar' | 'tipped' = 'jar'
): string {
  const labels: Record<string, Record<'jar' | 'tipped', string>> = {
    en: {
      jar: 'ID,Date,Time,Jar,Jar ID,Tip,Currency,Tipper name,Cost,Cost covered',
      tipped: 'ID,Date,Time,Jar,Jar ID,Tip,Currency,Tipper name'
    },
    it: {
      jar: 'ID,Data,Ora,Barattolo,Barattolo ID,Mancia,Valuta,Nome tipper,Costo,Costo coperto',
      tipped: 'ID,Data,Ora,Barattolo,Barattolo ID,Mancia,Valuta,Nome tipper'
    },
    es: {
      jar: 'ID,Fecha,Hora,Bote,Bote ID,Propina,Moneda,Tipper nombre,Costo,Costo cubierto',
      tipped: 'ID,Fecha,Hora,Bote,Bote Id,Propina,Moneda,Tipper nombre'
    }
  }

  const getFormattedDate = (
    dateString?: string
  ): { date: string; time: string } => {
    const date = new Date(dateString ?? '')
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hour = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return {
      date: `${year}/${month}/${day}`,
      time: `${hour}:${minutes}`
    }
  }

  const formatCsvRow = (fields: Array<string | number | undefined>): string => {
    return fields
      .map((field) => {
        if (typeof field === 'string') {
          return `"${field.replace(/"/g, '""')}"`
        }
        return field
      })
      .join(',')
  }

  let csvContent = labels[lang][category] + '\n'

  // let totalGrossAmount = 0
  // const totalFees = 0
  // const totalVat = 0
  let totalNetTip = 0
  let totalTip = 0
  let totalCost = 0

  payments?.forEach((payment) => {
    const { date, time } = getFormattedDate(payment.created_at)
    const jarName = payment.jar?.name ?? 'Personal Jar'
    const currency = payment.currency.toLowerCase()
    const tipper = payment?.comment?.customer_name ?? 'unknown'
    const jarId = getTackpayId(payment?.jar?.tackpay_ids)

    if (category === 'tipped') {
      // const grossAmount = (payment.gross_amount_cents / 100)
      //   .toFixed(2)
      //   .replace('.', ',')
      // totalGrossAmount += payment.gross_amount_cents / 100

      // const vat =
      //   ((payment?.fee?.platform_vat_amount ?? 0) +
      //     (payment?.fee?.provider_vat_amount ?? 0)) /
      //   100
      // const vatFormatted = vat.toFixed(2).replace('.', ',')
      // totalVat += vat

      // const fees = (payment?.fee?.amount_cents ?? 0) / 100
      // const feesFormatted = fees.toFixed(2).replace('.', ',')
      // totalFees += fees

      // const feesWithoutVat = (fees - vat).toFixed(2).replace('.', ',')
      const netTip = parseFloat((payment.amount_cents / 100).toFixed(2))
      totalNetTip += netTip

      const row = formatCsvRow([
        payment.id,
        date,
        time,
        jarName,
        jarId,
        // grossAmount,
        // feesWithoutVat,
        // vatFormatted,
        // feesFormatted,
        netTip.toFixed(2).replace('.', ','),
        currency,
        tipper
      ])
      csvContent += row + '\n'
    } else {
      const tip = parseFloat((payment.amount_cents / 100).toFixed(2))
      totalTip += tip

      const cost = parseFloat(
        payment?.fee?.amount_formatted?.replace(',', '.') ?? '0'
      )
      totalCost += cost

      const covered = payment?.fee?.covered === true ? 'Yes' : 'No'
      const row = formatCsvRow([
        payment.id,
        date,
        time,
        jarName,
        jarId,
        tip.toFixed(2).replace('.', ','),
        currency,
        tipper,
        payment?.fee?.amount_formatted?.replace('.', ',') ?? '0',
        covered
      ])
      csvContent += row + '\n'
    }
  })

  transfers?.forEach((transfer) => {
    const { date, time } = getFormattedDate(transfer.created_at)
    const jarName = transfer?.jar?.name ?? 'unknown'
    const currency = transfer.currency.toLowerCase()
    const tipper = transfer?.payment?.comment?.customer_name ?? 'unknown'
    const jarId = getTackpayId(
      transfer?.jar?.tackpay_ids ?? transfer?.member?.jar?.tackpay_ids
    )

    if (category === 'tipped') {
      const grossAmount = ((transfer?.payment?.gross_amount_cents ?? 0) / 100)
        .toFixed(2)
        .replace('.', ',')
      // totalGrossAmount += (transfer?.payment?.gross_amount_cents ?? 0) / 100

      // const vat =
      //   ((transfer?.payment?.fee?.platform_vat_amount ?? 0) +
      //     (transfer?.payment?.fee?.provider_vat_amount ?? 0)) /
      //   100
      // const vatFormatted = vat.toFixed(2).replace('.', ',')
      // totalVat += vat

      // const fees = (transfer?.payment?.fee?.amount_cents ?? 0) / 100
      // const feesFormatted = fees.toFixed(2).replace('.', ',')
      // totalFees += fees

      // const feesWithoutVat = (fees - vat).toFixed(2).replace('.', ',')
      const netTip = parseFloat(transfer.amount_formatted.replace(',', '.'))
      totalNetTip += netTip

      const row = formatCsvRow([
        transfer.id,
        date,
        time,
        jarName,
        jarId,
        grossAmount,
        // feesWithoutVat,
        // vatFormatted,
        // feesFormatted,
        netTip.toFixed(2).replace('.', ','),
        currency,
        tipper
      ])
      csvContent += row + '\n'
    } else {
      const tip = parseFloat(transfer.amount_formatted.replace(',', '.'))
      totalTip += tip

      const cost = parseFloat(
        transfer?.payment?.fee?.amount_formatted?.replace(',', '.') ?? '0'
      )
      totalCost += cost

      const covered = transfer?.payment?.fee?.covered === true ? 'Yes' : 'No'
      const row = formatCsvRow([
        transfer.id,
        date,
        time,
        jarName,
        jarId,
        transfer.amount_formatted.replace('.', ','),
        currency,
        tipper,
        transfer?.payment?.fee?.amount_formatted?.replace('.', ',') ?? '0',
        covered
      ])
      csvContent += row + '\n'
    }
  })

  if (category === 'tipped') {
    const totalRow = formatCsvRow([
      'Total',
      '',
      '',
      '',
      '',
      // totalVat.toFixed(2).replace('.', ','),
      // totalFees.toFixed(2).replace('.', ','),
      totalNetTip.toFixed(2).replace('.', ','),
      '',
      ''
    ])
    csvContent += totalRow + '\n'
  } else {
    const totalRow = formatCsvRow([
      'Total',
      '',
      '',
      '',
      '',
      totalTip.toFixed(2).replace('.', ','),
      '',
      '',
      totalCost.toFixed(2).replace('.', ','),
      ''
    ])
    csvContent += totalRow + '\n'
  }

  return csvContent
}
