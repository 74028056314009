import Input, { type InputOnChange } from '#components/ui/Input'
import {
  COUNTRIES,
  EXTRA_BANK_INFORMATION_COUNTRIES,
  ROUTING_NUMBER_COUNTRY
} from '#constants/country'
import { useEffect, useState } from 'react'
import AddBankCountry from './add/AddBankCountry'
import AddBankCurrency from './add/AddBankCurrency'
import Button from '#components/ui/Button'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import textUtil from '#utils/textUtil'
import { useIonRouter } from '@ionic/react'
import { type UserCategory } from '#tackpay-sdk'
import { useUserContext } from '#contexts/UserContext'
import { useBankDeductionContext } from '#contexts/BankContextDeduction'

interface Props {
  jarId?: string
  userId?: string
  category: UserCategory
}
export default function AddBankAccountDeductionForm({
  jarId,
  category
}: Props): JSX.Element {
  const { user } = useUserContext()

  const { createBankAccountDeduction, bank } = useBankDeductionContext()

  const { startLoading, stopLoading } = useLoading(
    bank == null ? 'bank.saving' : 'bankChanging'
  )

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { push } = useIonRouter()

  const [countryName, setCountryName] = useState<string | undefined>(
    user?.person?.address?.country_name
  )

  const [currencyName, setCurrencyName] = useState<string>()

  const [accountNumber, setAccountNumber] = useState<string>()

  const [routingNumber, setRoutingNumber] = useState<string>()

  const [accountHolderName, setAccountHolderName] = useState<string>()

  const [countryCode, setCountryCode] = useState<string | undefined>(
    user?.person?.address?.country_code
  )

  const [currencyCode, setCurrencyCode] = useState<string>()

  useEffect(() => {
    if (user?.person?.address != null) {
      setCountryName(user?.person?.address?.country_name)
      setCountryCode(user?.person?.address?.country_code)
    }
  }, [user?.person?.address])

  const handleSelectionCountry = (country: string): void => {
    setCountryName(country)
    setCountryCode(
      COUNTRIES.find((c) => c.country_name === country)?.country_code ?? ''
    )
  }

  const handleSelectionCurrency = (currency: string): void => {
    setCurrencyName(currency)
    setCurrencyCode(
      COUNTRIES.find((c) => c.currency_name === currency)?.currency_code ?? ''
    )
  }
  const handleInputChange: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'account_number') {
        setAccountNumber(value)
      } else if (name === 'routing_number') {
        setRoutingNumber(value)
      } else if (name === 'account_holder_name') {
        setAccountHolderName(value)
      }
    }
  }

  const routingNumberIsRequired = (): boolean => {
    return (
      textUtil.isEmpty(routingNumber) &&
      EXTRA_BANK_INFORMATION_COUNTRIES.includes(countryName as any)
    )
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (textUtil.isEmpty(accountNumber)) {
      showToast(undefined, 'bank.accountNumberRequired')
      return
    }

    if (textUtil.isEmpty(countryName) || textUtil.isEmpty(countryCode)) {
      showToast(undefined, 'bank.countryRequired')
      return
    }

    if (textUtil.isEmpty(currencyName) || textUtil.isEmpty(currencyCode)) {
      showToast(undefined, 'bank.currencyRequired')
      return
    }

    if (routingNumberIsRequired() && textUtil.isEmpty(routingNumber)) {
      showToast(undefined, 'bank.routingNumberRequired')
      return
    }

    if (textUtil.isEmpty(accountHolderName)) {
      showToast(undefined, 'bank.accountHolderNameRequired')
      return
    }

    if (createBankAccountDeduction != null) {
      startLoading()
      createBankAccountDeduction.mutate(
        {
          account_holder_type: jarId == null ? 'individual' : 'jar',
          account_number: accountNumber?.trim() as string,
          country_code: countryCode as string,
          currency: currencyCode as string,
          account_holder_name: accountHolderName as string,
          routing_number: routingNumber,
          jar:
            jarId != null
              ? {
                  id: jarId,
                  type: 'jars'
                }
              : undefined
        },
        {
          onSuccess: () => {
            stopLoading()
            showSuccess(
              undefined,
              bank == null ? 'bank.bankAdded' : 'bankChanged'
            )
            if (jarId != null) {
              push(
                `/dashboard/${category}/jars/${jarId}/settings/withdrawals/deductions/banks`,
                'root'
              )
            } else {
              push(
                `/dashboard/${category}/settings/withdrawals/deductions/banks`,
                'root'
              )
            }
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }

  const getLabelRoutingNumber = (): string => {
    if (countryCode != null) {
      return `routing_number_${countryCode.toLowerCase()}`
    }
    return 'routingNumber'
  }

  const getIbanLabel = (): string => {
    if (
      countryCode != null &&
      ROUTING_NUMBER_COUNTRY.includes(countryName as any)
    ) {
      return `iban_${countryCode.toLowerCase()}`
    }

    return 'iban'
  }

  return (
    <form onSubmit={handleSubmit} className='space-y-6'>
      <AddBankCountry
        country={countryName ?? ''}
        setSelectionCountry={handleSelectionCountry}
      />
      <AddBankCurrency
        currency={currencyName ?? ''}
        setSelectionCurrency={handleSelectionCurrency}
      />
      <Input
        type='text'
        name='account_holder_name'
        value={accountHolderName}
        onIonChange={handleInputChange}
        labelOptions={{
          children: 'accountHolderName',
          capitalize: false
        }}
      />
      <div>
        <Input
          name='account_number'
          type='text'
          value={accountNumber}
          onIonInput={handleInputChange}
          labelOptions={{
            translate: 'yes',
            children: getIbanLabel()
          }}
        />
      </div>
      {countryName != null &&
        EXTRA_BANK_INFORMATION_COUNTRIES.includes(countryName as any) && (
          <div>
            <Input
              name='routing_number'
              type='text'
              value={routingNumber}
              onIonInput={handleInputChange}
              labelOptions={{
                children: getLabelRoutingNumber()
              }}
            />
          </div>
        )}
      <Button className='w-full' type='submit'>
        {bank == null ? 'add' : 'change'}
      </Button>
    </form>
  )
}
