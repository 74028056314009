import {
  type ResourceUpdate,
  type ResourceCreate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Person } from './persons'
import { type Company } from './companies'
import { type BranchCompany } from './branchCompanies'
import { type TackpayId } from './tackpayIds'
import { type StripeGatewayAccount } from './stripeGatewayAccounts'
import { type CheckoutSetting } from './checkoutSettings'
import { type Collaborator } from './collaborators'
import { type Balance } from './balances'
import { type Customer } from './customers'
import { type Member } from './members'
import { type Subscriber } from './subscribers'
import { type Payout } from './payouts'
import { type PayoutRetainFee } from './payoutRetainFees'
import { type Payment } from './payments'
import { type CheckoutSession } from './checkoutSessions'
import { type StatisticBalance } from './statisticBalances'
import { type StatisticView } from './statisticViews'
import { type StatisticAmount } from './statisticAmounts'
import { type StatisticFeedback } from './statisticFeedbacks'
import { type AccountStatement } from './accountStatements'
import { type BankAccount } from './bankAccounts'
import { type BankAccountDeduction } from './bankAccountDeductions'

type UserType = 'users'

type UserRel = ResourceRel & { type: UserType }

type UserCategory =
  | 'tipped'
  | 'business'
  | 'manager'
  | 'collaborator'
  | 'platform'

export type Language = 'en' | 'fr' | 'it' | 'es'

interface GenericResponse {
  message: string
  data?: any
  status: number
  code?: string
}

type UserStatus = 'pending' | 'blocked' | 'active'

type UserAuthProvider = 'password' | 'goole'

interface User extends Resource {
  readonly type: UserType
  email: string
  category: UserCategory
  subcategory: string
  lang: Language
  avatar?: string
  status: UserStatus
  marketing: boolean
  uid: string
  marketing_third_party: boolean
  phone?: string
  profillation: boolean
  currency: string
  auth_provider: UserAuthProvider
  last_login?: string
  last_password_reset?: string
  person?: Person
  company?: Company
  branch_companies?: BranchCompany[]
  tackpay_id?: TackpayId
  stripe_gateway_account?: StripeGatewayAccount
  checkout_setting?: CheckoutSetting
  collaborator?: Collaborator
  balance?: Balance
  customer?: Customer
  members?: Member[]
  subscriber?: Subscriber
  payouts?: Payout[]
  payout_retain_fee?: PayoutRetainFee
  payments?: Payment[]
  checkout_sessions?: CheckoutSession[]
  statistic_balance?: StatisticBalance
  statistic_view?: StatisticView
  statistic_amount?: StatisticAmount
  statistic_feedback?: StatisticFeedback
  account_statements?: AccountStatement[]
  bank_account?: BankAccount
}

interface UserCreate extends ResourceCreate {
  email: string
  password: string
  category: UserCategory
  subcategory: string
  lang?: Language
  marketing?: boolean
  marketing_third_party?: boolean
  phone?: string
  profillation?: boolean
  currency: string
  auth_provider: UserAuthProvider
  token?: string
}

interface UserUpdate extends ResourceUpdate {
  email?: string
  password?: string
  subcategory?: string
  lang?: Language
  marketing?: boolean
  marketing_third_party?: boolean
  phone?: string
  profillation?: boolean
  currency?: string
  bank_account_deduction?: BankAccountDeduction
}

class Users extends ApiResource<User> {
  static readonly TYPE: UserType = 'users' as const

  async create(
    resource: UserCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<User> {
    return await this.resources.create<UserCreate, User>(
      { ...resource, type: Users.TYPE },
      params,
      options
    )
  }

  async update(
    resource: UserUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<User> {
    return await this.resources.update<UserUpdate, User>(
      { ...resource, type: Users.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Users.TYPE } : id,
      options
    )
  }

  async balance(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Balance> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<Balance>(
      { type: 'balances' },
      `users/${_userId}/balance`,
      params,
      options
    ) as unknown as Balance
  }

  async bank_account(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccount> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<BankAccount>(
      { type: 'bank_accounts' },
      `users/${_userId}/bank_account`,
      params,
      options
    ) as unknown as BankAccount
  }

  async bank_account_deduction(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccountDeduction> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<BankAccountDeduction>(
      { type: 'bank_accounts' },
      `users/${_userId}/bank_account`,
      params,
      options
    ) as unknown as BankAccountDeduction
  }

  async checkout_setting(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSetting> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<CheckoutSetting>(
      { type: 'checkout_settings' },
      `users/${_userId}/checkout_setting`,
      params,
      options
    ) as unknown as CheckoutSetting
  }

  async collaborator(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Collaborator> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<Collaborator>(
      { type: 'collaborators' },
      `users/${_userId}/collaborator`,
      params,
      options
    ) as unknown as Collaborator
  }

  async company(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Company> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<Company>(
      { type: 'companies' },
      `users/${_userId}/company`,
      params,
      options
    ) as unknown as Company
  }

  async statistic_amount(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticAmount> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<StatisticAmount>(
      { type: 'statistic_amounts' },
      `users/${_userId}/statistic_amount`,
      params,
      options
    ) as unknown as StatisticAmount
  }

  async statistic_balance(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticBalance> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<StatisticBalance>(
      { type: 'statistic_balances' },
      `users/${_userId}/statistic_balance`,
      params,
      options
    ) as unknown as StatisticBalance
  }

  async statistic_feedback(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticFeedback> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<StatisticFeedback>(
      { type: 'statistic_feedbacks' },
      `users/${_userId}/statistic_feedback`,
      params,
      options
    ) as unknown as StatisticFeedback
  }

  async statistic_view(
    userId: string | User,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticView> {
    const _userId = (userId as User).id ?? userId
    return this.resources.fetch<StatisticView>(
      { type: 'statistic_views' },
      `users/${_userId}/statistic_view`,
      params,
      options
    ) as unknown as StatisticView
  }

  async confirm_email(token: string): Promise<{
    user: User
    access_token: string
  }> {
    const client = this.resources.clientInstance
    return client.request(
      'post',
      '/confirm_email',
      {
        token
      },
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/auth',
        isJsonApi: false
      }
    ) as unknown as { user: User; access_token: string }
  }

  async reset_password_request(email: string): Promise<GenericResponse> {
    const client = this.resources.clientInstance
    return client.request(
      'post',
      '/reset_password_request',
      {
        email
      },
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/auth',
        isJsonApi: false
      }
    ) as unknown as GenericResponse
  }

  async reset_password(
    token: string,
    email: string,
    password: string
  ): Promise<GenericResponse> {
    const client = this.resources.clientInstance
    return client.request(
      'post',
      '/reset_password',
      {
        email,
        password,
        token
      },
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/auth',
        isJsonApi: false
      }
    ) as unknown as GenericResponse
  }

  async metrics_country_tips(userId: string): Promise<{ countries: string[] }> {
    const client = this.resources.clientInstance
    return client.request('get', '/country_tips?userId=' + userId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { countries: string[] }
  }

  async metrics_total_amount(userId: string): Promise<{ amount: number }> {
    const client = this.resources.clientInstance
    return client.request(
      'get',
      '/total_amount_tips?userId=' + userId,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/metrics',
        isJsonApi: false
      }
    ) as unknown as { amount: number }
  }

  async metrics_total_tips(userId: string): Promise<{ occurrence: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/total_tips?userId=' + userId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { occurrence: number }
  }

  async metrics_avg_tips(userId: string): Promise<{ avg_tip: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/total_tips?userId=' + userId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { avg_tip: number }
  }

  async metrics_scans(userId: string): Promise<{ occurrence: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/scans?userId=' + userId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { occurrence: number }
  }

  isUser(resource: any): resource is User {
    return resource.type != null && resource.type === Users.TYPE
  }

  relationship(id: string | ResourceId | null): UserRel {
    return id === null || typeof id === 'string'
      ? { id, type: Users.TYPE }
      : { id: id.id, type: Users.TYPE }
  }

  type(): UserType {
    return Users.TYPE
  }
}

export type { User, UserCreate, UserUpdate, UserCategory }
export default Users
