import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import TeamWithdrawal from './views/TeamWithdrawal'
import TeamBankAccount from './views/banks/TeamBankAccount'
import TeamAddBankAccount from './views/banks/TeamAddBankAccount'
import TeamPayout from './views/payouts/TeamPayout'
import TeamPayoutId from './views/payouts/TeamPayoutId'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import TeamAddBankAccountDeduction from './views/banks/TeamAddBankAccountDeduction'
import TeamBankAccountDeduction from './views/banks/TeamBankAccountDeduction'
import TeamPayoutDeduction from './views/payouts/TeamPayoutDeduction'
import TeamPayoutIdDeduction from './views/payouts/TeamPayoutIdDeduction'
// import PayoutRoutes from './views/payouts/PayoutRoutes'

const withdrawalBaseRoute =
  '/dashboard/:category/jars/:jarId/settings/withdrawals'

export interface JarPayoutRouterParams extends DashboardRoutesParams {
  jarId: string
  payoutId: string
}

export default function TeamWithdrawalRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={withdrawalBaseRoute}>
          <TeamWithdrawal />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/deductions/banks`}>
          <TeamBankAccountDeduction />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/banks`}>
          <TeamBankAccount />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/deductions/banks/add`}>
          <TeamAddBankAccountDeduction />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/banks/add`}>
          <TeamAddBankAccount />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/payouts`}>
          <TeamPayout />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/deductions/payouts`}>
          <TeamPayoutDeduction />
        </Route>
        <Route exact path={`${withdrawalBaseRoute}/payouts/:payoutId`}>
          <TeamPayoutId />
        </Route>
        <Route
          exact
          path={`${withdrawalBaseRoute}/deductions/payouts/:payoutId`}
        >
          <TeamPayoutIdDeduction />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
