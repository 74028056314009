import SubTitle from '../components/ui/SubTitle'
import Text from '../components/ui/Text'
import Title from '../components/ui/Title'
import Button from '../components/ui/Button'
import { IonRow } from '@ionic/react'
import Link from '../components/ui/Link'
import AuthLayout from '../components/layouts/auth/AuthLayout'
import SearchWelcome from '#components/pages/welcome/SearchWelcome'

export default function Welcome(): JSX.Element {
  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
      contentOptions={{ scrollY: true }}
    >
      <Title hasSubtitle>index.title</Title>
      <SubTitle>index.subtitle</SubTitle>
      <SearchWelcome />
      <Text className='w-full ion-text-center mt-10'>or</Text>
      <Button
        className='w-full mt-14 ion-no-margin'
        routerLink='/registrations'
        routerDirection='root'
        data-testid='button-go-to-registrations'
      >
        signUp
      </Button>
      <IonRow className='mt-14 space-x-2 ion-text-center ion-justify-content-center'>
        <Text className='ion-text-center'>alredyAccount</Text>
        <Link data-testid='login' link='/login'>
          <Text color='primary'>signIn</Text>
        </Link>
      </IonRow>
    </AuthLayout>
  )
}
