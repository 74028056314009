import CheckoutBankcontactForm from './CheckoutBankcontactForm'
import CheckoutCardForm from './CheckoutCardForm'
import { type CheckoutPaymentMethodType } from './CheckoutCardPaymentMethods'
import CheckoutEpsForm from './CheckoutEpsForm'
import CheckoutIdealForm from './CheckoutIdealForm'
import CheckoutMultibancoForm from './CheckoutMultibancoForm'
import CheckoutP24Form from './CheckoutFormP24Form'
import CheckoutSofortForm from './CheckoutSofortForm'
import { useMemo } from 'react'

interface Props {
  methodType: CheckoutPaymentMethodType
  tackpayIdValue: string
}

export default function CheckoutFormActive({
  methodType,
  tackpayIdValue
}: Props): JSX.Element {
  const dictionaries: Record<CheckoutPaymentMethodType, JSX.Element> = {
    card: <CheckoutCardForm tackpayIdValue={tackpayIdValue} />,
    bankcontact: <CheckoutBankcontactForm />,
    eps: <CheckoutEpsForm />,
    // giropay: <CheckoutGiropayForm />,
    ideal: <CheckoutIdealForm />,
    multibanco: <CheckoutMultibancoForm />,
    p24: <CheckoutP24Form />,
    sofort: <CheckoutSofortForm />
  }
  const Content = useMemo(
    () => dictionaries[methodType],
    [methodType, tackpayIdValue]
  )

  return Content ?? <></>
}
