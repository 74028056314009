import { useUserContext } from '#contexts/UserContext'
// import isDesktop from '#utils/isDesktop'
import { IonIcon } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function BannerComponents(): JSX.Element {
  const { t } = useTranslation()

  const [showBanner, setShowBanner] = useState(false)

  const { user } = useUserContext()

  // const bannerDesktop = isDesktop() ? 'top-28' : position

  useEffect(() => {
    if (user?.status === 'pending') {
      setShowBanner(true)
    }
    return () => {
      setShowBanner(false)
    }
  }, [user?.status])

  return showBanner ? (
    <div
      className='fixed top-0 lg:top-20 lg:left-60 right-0 left-0 isolate flex items-center gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5 sm:before:flex-1 bg-green-200 rounded-b-md'
      style={{
        zIndex: 9999
      }}
    >
      <div
        className='absolute left-0 right-0 top-0 -z-10 -translate-y-1/2 transform-gpu blur-2xl'
        aria-hidden='true'
      >
        <div className='aspect-[577/310] w-[36.0625rem] bg-green-200 opacity-30' />
      </div>
      <div
        className='absolute left-0 right-0 top-0 -z-10 -translate-y-1/2 transform-gpu blur-2xl'
        aria-hidden='true'
      >
        <div className='aspect-[577/310] w-[36.0625rem] bg-green-200' />
      </div>
      <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
        <p className='text-sm leading-6 text-gray-900 bg-transparent'>
          <strong className='font-semibold'>TackPay</strong>
          <svg
            viewBox='0 0 2 2'
            className='mx-2 inline h-0.5 w-0.5 fill-current'
            aria-hidden='true'
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          {t('bannerText', { email: user?.email })}
        </p>
        {/* <a
          href='#'
          className='flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'
        >
          Register now <span aria-hidden='true'>&rarr;</span>
        </a> */}
      </div>
      <div className='flex flex-1 justify-end'>
        <button
          type='button'
          className='-m-3 p-3 focus-visible:outline-offset-[-4px]'
        >
          <span className='sr-only'>Dismiss</span>
          <IonIcon
            src={closeOutline}
            className='h-5 w-5 text-gray-900 pt-1'
            aria-hidden='true'
            onClick={() => {
              setShowBanner(false)
            }}
          />
        </button>
      </div>
    </div>
  ) : (
    <></>
  )
}
