import { type LinksSocial } from '#tackpay-sdk'
import textUtil from '#utils/textUtil'
import { IonGrid, IonIcon, IonRow } from '@ionic/react'
import {
  logoFacebook,
  logoGoogle,
  logoInstagram,
  logoTwitter
} from 'ionicons/icons'

interface Props {
  linksSocial: LinksSocial
}

export default function CheckoutLinksSocial({
  linksSocial
}: Props): JSX.Element {
  return (
    <IonGrid className='mt-10'>
      <IonRow className='ion-justify-content-center ion-align-items-center space-x-4'>
        {!textUtil.isEmpty(linksSocial.facebook) && (
          <IonIcon
            src={logoFacebook}
            className='text-blue-500 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://facebook.com/${linksSocial.facebook}`,
                '_blank'
              )
            }
          />
        )}
        {!textUtil.isEmpty(linksSocial.instagram) && (
          <IonIcon
            src={logoInstagram}
            className='text-pink-400 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://instagram.com/${linksSocial.instagram}`,
                '_blank'
              )
            }
          />
        )}
        {!textUtil.isEmpty(linksSocial.twitter) && (
          <IonIcon
            src={logoTwitter}
            className='text-blue-400 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://twitter.com/${linksSocial.twitter}`,
                '_blank'
              )
            }
          />
        )}

        {!textUtil.isEmpty(linksSocial.tripadvisor) && (
          <IonIcon
            src='/assets/img/tripadvisor.svg'
            size='large'
            className='text-blue-400 cursor-pointer'
            onClick={() => window.open(linksSocial.tripadvisor, '_blank')}
          />
        )}

        {!textUtil.isEmpty(linksSocial.website) && (
          <IonIcon
            src='/assets/img/web-icon.svg'
            className='text-gray-600 cursor-pointer'
            size='large'
            onClick={() => window.open(linksSocial.website, '_blank')}
          />
        )}

        {!textUtil.isEmpty(linksSocial.google_review) && (
          <IonIcon
            src={logoGoogle}
            className='text-gray-600'
            onClick={() => window.open(linksSocial.google_review, '_blank')}
          />
        )}
      </IonRow>
    </IonGrid>
  )
}
