import AppLayout from '#components/layouts/app/AppLayout'
import ListLink from '#components/ui/ListLink'
import Title from '#components/ui/Title'
import { getWithdrawalSettings } from '#constants/withdrawals'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { type UserCategory } from '#tackpay-sdk'
import { useMemo } from 'react'
import { useParams } from 'react-router'

interface Props {
  category: UserCategory
  jarId: string
}
function HandleTeamWithdrawal({ category, jarId }: Props): JSX.Element {
  const withdrawalSettings = getWithdrawalSettings(
    category,
    `/dashboard/${category}/jars/${jarId}/settings`
  )

  useMemo(() => {
    return withdrawalSettings
  }, [withdrawalSettings])
  return <ListLink items={withdrawalSettings} />
}

export default function TeamWithdrawal(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: ['balance', 'deduction_setting']
        }
      }}
    >
      <Title>withdrawal.title</Title>
      <HandleTeamWithdrawal category={category} jarId={jarId} />
    </AppLayout>
  )
}
