import AppLayout from '#components/layouts/app/AppLayout'
import Button from '#components/ui/Button'
import Input, {
  type InputOnChange,
  type InputValue
} from '#components/ui/Input'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { IonRow } from '@ionic/react'
import { useState } from 'react'
import { useParams } from 'react-router'

export default function TeamMaterialAssign(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  const [tackpayId, setTackpayId] = useState<InputValue>('')

  const { startLoading, stopLoading } = useLoading('tackpayId.assigning')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const handleTackpayIdChange: InputOnChange = (e) => {
    setTackpayId(e.detail.value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (tackpayId == null || typeof tackpayId !== 'string') {
      showToast(undefined, 'tackpayId.required')
    } else if (tackpayId.length < 4 || tackpayId.length > 4) {
      showToast(undefined, 'tackpayId.length')
    } else {
      startLoading()
      sdk.tackpay_ids
        .list({
          filters: {
            eq: {
              value: tackpayId.toUpperCase()
            }
          },
          pageSize: 1
        })
        .then((tackpayIds) => {
          const tk = tackpayIds[0]
          if (tk != null) {
            sdk.tackpay_ids
              .update({
                id: tk.id,
                value: tk.value ?? '',
                status: 'assigned',
                jar: {
                  id: jarId,
                  type: 'jars'
                }
              })
              .then(() => {
                stopLoading()
                showSuccess(undefined, 'tackpayId.assigned')
              })
              .catch((error) => {
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              })
          } else {
            stopLoading()
            showToast(undefined, 'tackpayId.notFound')
          }
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/materials`
        }
      }}
    >
      <IonRow className='mb-5 mt-0 mx-0'>
        <Title>material.assign.title</Title>
      </IonRow>
      <IonRow>
        <SubTitle>material.assign.subtitle</SubTitle>
      </IonRow>
      <form onSubmit={handleSubmit}>
        <Input
          name='tackpayId'
          value={tackpayId}
          minlength={4}
          maxlength={4}
          onIonInput={handleTackpayIdChange}
          labelOptions={{
            children: 'TackPay ID',
            translationOptions: {
              translate: 'no'
            }
          }}
        />
        <div className='mt-10'>
          <Button type='submit' className='w-full'>
            add
          </Button>
        </div>
      </form>
    </AppLayout>
  )
}
