import { type UserCategory } from '#tackpay-sdk'

export const isBusiness = (category: string): boolean => {
  const businessCategories: UserCategory[] = [
    'business',
    'collaborator',
    'manager'
  ]
  return businessCategories.includes(category as UserCategory)
}
