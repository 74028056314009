import Text from '#components/ui/Text'
import { IonCardHeader } from '@ionic/react'

export default function TitleModal(): JSX.Element {
  return (
    <IonCardHeader className='ion-no-padding ion-no-margin'>
      <Text color='gray-900' fontWeight='font-bold' data-testid='title-terms'>
        registration.terms.title
      </Text>
    </IonCardHeader>
  )
}
