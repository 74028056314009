import {
  type JarUpdate,
  type QueryParamsRetrieve,
  type Jar
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { createContext, useContext } from 'react'

type DeleteTeamFunction = UseMutationResult<void, unknown, string, unknown>

type UpdateTeamFunction = UseMutationResult<Jar, unknown, JarUpdate, unknown>

interface TeamContextValue {
  jar?: Jar | null
  isLoading: boolean
  error?: any
  deleteTeam?: DeleteTeamFunction
  updateTeam?: UpdateTeamFunction
  refetch?: () => Promise<void>
  setBalanceTronc?: (balance: number) => void
}

const teamInitialState: TeamContextValue = {
  jar: null,
  isLoading: false,
  error: null
}

const JarContext = createContext<TeamContextValue>(teamInitialState)

export const useTeamContext = (): TeamContextValue => {
  const context = useContext(JarContext)
  if (context == null) {
    throw new Error('useTeamContext must be used within a TeamProvider')
  }
  return context
}

interface TeamContainerProps {
  children: React.ReactNode

  /**
   * The jar id to fetch
   * @default false
   */
  fetch?: boolean

  params?: QueryParamsRetrieve

  jarId?: string
}

const handleRetrieveTeam = async (
  jarId: string,
  params?: QueryParamsRetrieve
): Promise<Jar> => {
  return await sdk.jars.retrieve(jarId, params)
}

const handleDeleteTeam = async (jarId: string): Promise<void> => {
  await sdk.jars.delete(jarId)
}

const handleUpdateTeam = async (teamUpdate: JarUpdate): Promise<Jar> => {
  return await sdk.jars.update(teamUpdate)
}

export default function TeamContainer(props: TeamContainerProps): JSX.Element {
  const { children, fetch = false, params, jarId } = props

  const queryClient = useQueryClient()

  // const [initialFetchComplete, setInitialFetchComplete] = useState(false)
  console.log('Params', params)
  const {
    data: jar,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['jar', jarId, params],
    queryFn: async () => await handleRetrieveTeam(jarId ?? '', params),
    enabled: fetch
  })

  const useDeleteTeam = useMutation({
    mutationFn: handleDeleteTeam,
    onSuccess: () => {
      queryClient.clear()
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId, params] })
      void queryClient.invalidateQueries({ queryKey: ['jars'] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jars'] })
    }
  })

  const useUpdateTeam = useMutation({
    mutationFn: handleUpdateTeam,
    onSuccess: () => {
      // void queryClient.invalidateQueries(['jar', jarId, params])
      void queryClient.invalidateQueries({ queryKey: ['jars'] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jars'] })
    }
  })

  const handleRefetch = async (): Promise<void> => {
    try {
      await refetch()
    } catch (error) {
      console.log('Refetch jar error', error)
    }
  }

  const handleSetBalanceTronc = (balance: number): void => {
    if (jar != null) {
      if (jar.balance != null) {
        jar.balance.tronc.available.amount_cents = balance
        jar.balance.tronc.available.amount_float = balance / 100
        jar.balance.tronc.available.amount_formatted = (balance / 100).toFixed(
          2
        )
      }
    }
  }
  const teamContextValue = {
    jar,
    isLoading,
    error,
    deleteTeam: useDeleteTeam,
    updateTeam: useUpdateTeam,
    refetch: handleRefetch,
    setBalanceTronc: handleSetBalanceTronc
  }

  return (
    <JarContext.Provider value={teamContextValue}>
      {children}
    </JarContext.Provider>
  )
}
