export default function formattedDate(
  date: Date | string,
  lang: string = 'en',
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date
  const dataFormat = new Intl.DateTimeFormat(lang, options).format(dateObj)
  return dataFormat
}
