import Button from '#components/ui/Button'
// import { type InputOnChange } from '#components/ui/Input'
import Text from '#components/ui/Text'
import { IonCol, IonRow } from '@ionic/react'
// import InputAmount from './InputAmount'
import { getSymbolCurrency } from '#constants/country'
import CurrencyInput from '#components/common/CurrencyInput'
import { type ChangeEventHandler } from 'react'

interface Props {
  setOpenModal: (arg: boolean) => void
  setCurrentAmount: (arg: number | undefined) => void
  currentAmount: number
  currency: string
  submitFunction: (e?: React.FormEvent<HTMLFormElement>) => void
}

export default function CustomAmountForm({
  setOpenModal,
  setCurrentAmount,
  submitFunction,
  currentAmount,
  currency
}: Props): JSX.Element {
  const handleAmountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    let value = String(e.target.value)
    value = value.replace(getSymbolCurrency(currency), '')
    value = value.replace(',', '.')
    const amount = parseFloat(value)
    if (isNaN(amount)) {
      setCurrentAmount(undefined)
      return
    }
    setCurrentAmount(amount)
  }

  const handlerSubmit = (): void => {
    setOpenModal(false)
    submitFunction()
  }
  const height = window.screen.height

  const heightView = (data: number): string => {
    if (data < 667 && data !== undefined) {
      return 'space-y-3'
    }
    if (data === 667 && data !== undefined) {
      return 'space-y-7'
    }

    if (data > 928 && data !== undefined) return 'space-y-20'

    return 'space-y-12'
  }

  const controlHeight = heightView(height)

  return (
    <div>
      <IonCol size='12' className={controlHeight}>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='10'>
            <Text
              className='text-center'
              fontWeight='font-medium md:font-bold'
              size='text-lg'
            >
              checkout.customAmount.title
            </Text>
          </IonCol>
        </IonRow>

        <div className='px-4'>
          <CurrencyInput
            inputOptions={{
              placeholder: `${getSymbolCurrency(currency)}0.00`,
              onChange: handleAmountChange,
              // pattern: '[0-9,]+',
              inputMode: 'decimal'
            }}
            maskOptions={{
              prefix: getSymbolCurrency(currency)
            }}
          />
        </div>

        {/* <InputAmount
          amount={currentAmount}
          handlerChange={handleChangeInputs}
          currency={currency}
        /> */}

        <IonRow className='ion-margin-top'>
          <Button
            className='ion-margin-horizontal w-full'
            translationOptions={{
              translate: 'yes',
              data: {
                amount: currentAmount === 0 ? '' : currentAmount,
                currency:
                  currentAmount === 0 || currentAmount == null
                    ? ''
                    : getSymbolCurrency(currency ?? 'eur')
              }
            }}
            onClick={handlerSubmit}
          >
            confirmAmount
          </Button>
        </IonRow>
      </IonCol>
    </div>
  )
}
