import { type QueryParamsRetrieve } from 'sdk/query'
import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  type ResourceId,
  type ResourcesConfig,
  ApiResource
} from 'sdk/resource'
import { type Person } from './persons'

type AddressType = 'addresses'

type AddressRel = ResourceRel & { type: AddressType }

type PersonRel = ResourceRel & { type: 'persons' }

interface Address extends Resource {
  readonly type: AddressType
  country_name: string
  state?: string
  country_code: string
  city?: string
  street?: string
  postal_code?: string
  street_number?: string
  latitude?: string
  longitude?: string
  person?: Person
}

interface AddressCreate extends ResourceCreate {
  country_name: string
  state?: string
  city?: string
  street?: string
  postal_code?: string
  street_number?: string
  latitude?: string
  longitude?: string
  person: PersonRel
}

interface AddressUpdate extends ResourceUpdate {
  city?: string
  street?: string
  postal_code?: string
  street_number?: string
  latitude?: string
  longitude?: string
  state?: string
}

class Addresses extends ApiResource<Address> {
  static TYPE: AddressType = 'addresses' as const

  async create(
    resource: AddressCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Address> {
    return await this.resources.create<AddressCreate, Address>(
      { ...resource, type: Addresses.TYPE },
      params,
      options
    )
  }

  async update(
    resource: AddressUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Address> {
    return await this.resources.update<AddressUpdate, Address>(
      { ...resource, type: Addresses.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Addresses.TYPE } : id,
      options
    )
  }

  isAddress(resource: any): resource is Address {
    return resource.type != null && resource.type === Addresses.TYPE
  }

  relationship(id: string | ResourceId | null): AddressRel {
    return id === null || typeof id === 'string'
      ? { id, type: Addresses.TYPE }
      : { id: id.id, type: Addresses.TYPE }
  }

  type(): AddressType {
    return Addresses.TYPE
  }
}

export type { Address, AddressCreate, AddressUpdate }
export default Addresses
