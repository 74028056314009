import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../../TeamRoutes'
import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import DivideTipSection from '#components/pages/teams/divideTip/DivideTipSection'
import SubTitle from '#components/ui/SubTitle'
import { IonRow } from '@ionic/react'
// import CoverIcon from '#components/ui/CoverIcon'
// import { swapHorizontalOutline } from 'ionicons/icons'

export default function TeamDivideTip(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  // const { push } = useIonRouter()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showCustomIcon: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
        // customIconOptions: {
        //   icon: (
        //     <CoverIcon
        //       iconOptions={{
        //         icon: swapHorizontalOutline,
        //         className: 'w-5 h-5'
        //       }}
        //       rounded='rounded-full'
        //       className='cursor-pointer'
        //       testId='icon-settings'
        //       onClick={() => {
        //         push(
        //           `/dashboard/${category}/jars/${jarId}/settings/tronc/history`
        //         )
        //       }}
        //     />
        //   )
        // }
      }}
      teamProps={{
        fetch: true,
        jarId,
        params: {
          include: [
            'members.user.person',
            'members.user.stripe_gateway_account',
            'balance'
          ]
        }
      }}
    >
      <IonRow className='w-full ion-no-padding mb-5 mt-0 mx-0'>
        <Title className='ion-no-margin ion-no-padding'>divideTip.title</Title>
      </IonRow>
      <IonRow>
        <SubTitle>divideTip.subtitle</SubTitle>
      </IonRow>

      <DivideTipSection />
    </AppLayout>
  )
}
