import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import Deduction from './Deduction'

const basePath = '/dashboard/:category/deductions'

export default function DeductionRouter(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={basePath}>
          <Deduction />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
