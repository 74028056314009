import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import { IonCol, useIonRouter } from '@ionic/react'
import CardCategory from '#components/common/CardCategory'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import ButtonStep from '#components/common/ButtonStep'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import { type UserCategory, type JarCategory } from '#tackpay-sdk'
import { useUserContext } from '#contexts/UserContext'

interface Props {
  category: UserCategory
}
function HandleTeamRole({ category }: Props): JSX.Element {
  const { user } = useUserContext()

  const { teamCreate, setTeamCreate, setIsSameCompany, setIsSameIban } =
    useCreateTeamStore()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleSaveRole = (role: JarCategory): void => {
    if (role === 'private') {
      setTeamCreate({ jar_type: role, tipping_rule: 'individual' })
      if (user?.category === 'manager') {
        setIsSameCompany(false)
      }
    } else {
      setTeamCreate({ jar_type: role })
      setIsSameCompany(true)
      setIsSameIban(true)
    }

    if (role === 'private') {
      push(`/dashboard/${category}/jars/add/companies`)
    } else {
      push(`/dashboard/${category}/jars/add/tipping-rules`)
    }
  }

  const handleNextStep = (): void => {
    if (textUtil.isEmpty(teamCreate?.jar_type)) {
      showToast(undefined, 'teamPage.add.categoryRequired')
      return
    }

    handleSaveRole(teamCreate?.jar_type as JarCategory)
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add`, 'back')
  }

  return (
    <>
      <IonCol className='space-y-10'>
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleSaveRole('private')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-private.svg'
            }
          }}
          title='teamPage.type.private.title'
          description='teamPage.type.private.description'
          showCheckIcon={teamCreate?.jar_type === 'private'}
        />
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleSaveRole('shared')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-shared.svg'
            }
          }}
          title='teamPage.type.shared.title'
          description='teamPage.type.shared.description'
          showCheckIcon={teamCreate?.jar_type === 'shared'}
        />
      </IonCol>
      <ButtonStep handleNext={handleNextStep} handleBack={handleBack} />
    </>
  )
}

export default function AddTeamRole(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add`
        }
      }}
    >
      <ProgressBar value={0.4} text='teamPage.add.chip.category' />
      <HandleTeamRole category={category} />
    </AppLayout>
  )
}
