import {
  type QueryParamsRetrieve,
  type Collaborator,
  type CollaboratorCreate,
  type CollaboratorUpdate
} from '#tackpay-sdk'
import { createContext, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'
// import useAuthInfo from '#hooks/useAuthInfo'
import sdk from '#utils/sdk'
import { useUserContext } from './UserContext'

type CreateCollaboratorFunction = UseMutationResult<
  Collaborator,
  unknown,
  CollaboratorCreate,
  unknown
>

type UpdateCollaboratorFunction = UseMutationResult<
  Collaborator,
  unknown,
  CollaboratorUpdate,
  unknown
>

type DeleteCollaboratorFunction = UseMutationResult<
  void,
  unknown,
  string,
  unknown
>

interface CollaboratorContextValue {
  collaborator?: Collaborator
  isLoading: boolean
  error?: any
  createCollaborator?: CreateCollaboratorFunction
  updateCollaborator?: UpdateCollaboratorFunction
  deleteCollaborator?: DeleteCollaboratorFunction
}

const initialState: CollaboratorContextValue = {
  collaborator: undefined,
  isLoading: false,
  error: undefined
}

const CompanyContext = createContext<CollaboratorContextValue>(initialState)

export const useCollaboratorContext = (): CollaboratorContextValue => {
  const context = useContext(CompanyContext)
  if (context == null) {
    throw new Error('useCompanyContext must be used within a CompanyProvider')
  }
  return context
}

interface CollaboratorContainerProps {
  children: React.ReactNode
  /**
   * If true, fetch the company on mount
   * @default true
   */
  fetch?: boolean
  /**
   * Query params to use when fetching the company
   */
  params?: QueryParamsRetrieve
}

const handleCreateCollaborator = async (
  createCollaborator: CollaboratorCreate
): Promise<Collaborator> => {
  return await sdk.collaborators.create(createCollaborator)
}

const handleEditCollaborator = async (
  updateCollaborator: CollaboratorUpdate
): Promise<Collaborator> => {
  return await sdk.collaborators.update(updateCollaborator)
}

const handleDeleteCollaborator = async (
  collaboratorId: string
): Promise<void> => {
  await sdk.collaborators.delete(collaboratorId)
}

export default function CollaboratorContainer(
  props: CollaboratorContainerProps
): JSX.Element {
  const { user } = useUserContext()

  const { children, fetch = true, params } = props

  const queryClient = useQueryClient()

  // const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: collaborator,
    isLoading,
    error
  } = useQuery({
    queryKey: ['collaborator', params],
    queryFn: async () => {
      return await sdk.users.collaborator(user?.id ?? '', params)
    },
    enabled: fetch && user != null
  })

  const useCreateCollaborator = useMutation({
    mutationFn: handleCreateCollaborator,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
    },
    onError: (_err: any, _variables: any, context: any) => {
      queryClient.setQueryData(
        ['pending-collaborators', context?.previousCollaborators],
        context.previousUser
      )
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
    }
  })

  const useEditCollaborator = useMutation({
    mutationFn: handleEditCollaborator,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
      void queryClient.invalidateQueries({
        queryKey: ['confirmed-collaborators']
      })
    },
    onError: (_err: any, _variables: any, context: any) => {
      queryClient.setQueryData(
        ['pending-collaborators', context?.previousCollaborators],
        context.previousUser
      )
      queryClient.setQueryData(
        ['confirmed-collaborators', context?.previousCollaborators],
        context.previousUser
      )
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
      void queryClient.invalidateQueries({
        queryKey: ['confirmed-collaborators']
      })
    }
  })

  const useDeleteCollaborator = useMutation({
    mutationFn: handleDeleteCollaborator,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
      void queryClient.invalidateQueries({
        queryKey: ['confirmed-collaborators']
      })
    },
    onError: (_err: any, _variables: any, context: any) => {
      queryClient.setQueryData(
        ['pending-collaborators', context?.previousCollaborators],
        context.previousUser
      )
      queryClient.setQueryData(
        ['confirmed-collaborators', context?.previousCollaborators],
        context.previousUser
      )
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['pending-collaborators']
      })
      void queryClient.invalidateQueries({
        queryKey: ['confirmed-collaborators']
      })
    }
  })

  const collaboratorContextValue: CollaboratorContextValue = {
    collaborator,
    isLoading,
    error,
    createCollaborator: useCreateCollaborator,
    updateCollaborator: useEditCollaborator,
    deleteCollaborator: useDeleteCollaborator
  }

  return (
    <CompanyContext.Provider value={collaboratorContextValue}>
      {children}
    </CompanyContext.Provider>
  )
}
