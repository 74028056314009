import {
  type TransferCreate,
  type QueryParamsRetrieve,
  type Transfer
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

type CreateTransferFunction = UseMutationResult<
  Transfer,
  unknown,
  TransferCreate,
  unknown
>

interface TransferContextValue {
  transfer?: Transfer | null
  isLoading: boolean
  error?: any
  createTransfer?: CreateTransferFunction
}

const transferIntialState: TransferContextValue = {
  isLoading: false,
  transfer: undefined,
  error: undefined
}

const TransferContext = createContext<TransferContextValue>(transferIntialState)

export const useTransferContext = (): TransferContextValue => {
  const context = useContext(TransferContext)
  if (context == null)
    throw new Error(
      'useTransferContext must be used within a TransferContextProvider'
    )
  return context
}

interface TransferContainerProps {
  children: React.ReactNode
  transferId?: string
  /** @default true */
  params?: QueryParamsRetrieve
  fetch?: boolean
}

export default function TransferContainer(
  props: TransferContainerProps
): JSX.Element {
  const { children, transferId, params, fetch = true } = props

  const queryClient = useQueryClient()

  const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const {
    data: transfer,
    isLoading,
    error
  } = useQuery<Transfer>({
    queryKey: ['transfer', transferId],
    queryFn: async () => {
      return await sdk.transfers.retrieve(transferId ?? '', params)
    },
    enabled: !initialFetchComplete && fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  useEffect(() => {
    if (fetch) {
      void queryClient.invalidateQueries({ queryKey: ['transfer', transferId] })
      setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
    }
  }, [fetch, queryClient])

  const handleCreateTransfer = async (
    createTransfer: TransferCreate
  ): Promise<Transfer> => {
    return await sdk.transfers.create(createTransfer, {
      include: ['jar']
    })
  }

  const useCreateTransfer = useMutation({
    mutationKey: ['createTransfer', transferId],
    mutationFn: handleCreateTransfer,
    onSuccess: (data) => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({
        queryKey: [
          'jar',
          data?.jar?.id ?? '',
          {
            include: ['members.user.person', 'balance']
          }
        ]
      })
    },
    onError: () => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
    }
  })

  const transferContextValue: TransferContextValue = {
    transfer,
    isLoading,
    error,
    createTransfer: useCreateTransfer
  }

  return (
    <TransferContext.Provider value={transferContextValue}>
      {children}
    </TransferContext.Provider>
  )
}
