import { type User } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type AuthStateChangeListener,
  FirebaseAuthentication
} from '@capacitor-firebase/authentication'
import {
  destroyPreferences,
  getPreferences,
  savePreferences
} from 'storage/preferences'

export interface AuthState {
  category: string
  isLoggedIn: boolean
  userId: string
  accessToken: string
}

interface UseAuthState {
  loginWithEmail: (email: string, password: string) => Promise<User>
  loginWithToken: (token: string) => Promise<User>
  loginWithGoogle: () => Promise<User>
  logout: () => Promise<void>
  refreshToken: () => Promise<void>
  getAccessToken: (force?: boolean) => Promise<string>
  getAuthState: () => Promise<AuthState | undefined>
  getCurrentUser: () => Promise<User | undefined>
  setAuthState: (authState: AuthState) => Promise<void>
  authStatusChangeFunction: () => void
}

export const AUTH_STATE_KEY = 'authState'

export default function useAuthState(): UseAuthState {
  const loginWithGoogle = async (): Promise<User> => {
    const firebaseUser = await FirebaseAuthentication.signInWithGoogle({
      mode: 'popup'
    })
    if (firebaseUser.user == null) throw new Error('No user returned')

    const accessToken = await getAccessToken()

    const user = await sdk.users.retrieve(firebaseUser.user.uid, undefined, {
      accessToken
    })

    await savePreferences<AuthState>(AUTH_STATE_KEY, {
      isLoggedIn: true,
      accessToken,
      category: user.category,
      userId: user.id
    })
    return user
  }
  const loginWithEmail = async (
    email: string,
    password: string
  ): Promise<User> => {
    const firebaseUser =
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password
      })

    if (firebaseUser.user == null) throw new Error('No user returned')

    const accessToken = await getAccessToken()

    const user = await sdk.users.retrieve(firebaseUser.user.uid, undefined, {
      accessToken
    })

    await savePreferences<AuthState>(AUTH_STATE_KEY, {
      isLoggedIn: true,
      accessToken,
      category: user.category,
      userId: user.id
    })
    return user
  }

  const getAccessToken = async (force = true): Promise<string> => {
    const result = await FirebaseAuthentication.getIdToken({
      forceRefresh: force
    })
    return result.token
  }

  const loginWithToken = async (token: string): Promise<User> => {
    const result = await FirebaseAuthentication.signInWithCustomToken({
      token
    })

    if (result.user == null) throw new Error('No user returned')

    const accessToken = await getAccessToken()

    const user = await sdk.users.retrieve(result.user.uid, undefined, {
      accessToken
    })

    await savePreferences<AuthState>(AUTH_STATE_KEY, {
      isLoggedIn: true,
      accessToken,
      category: user.category,
      userId: user.id
    })

    return user
  }

  const logout = async (): Promise<void> => {
    destroyPreferences(AUTH_STATE_KEY)
    await FirebaseAuthentication.signOut()
  }

  const refreshToken = async (): Promise<void> => {
    const result = await FirebaseAuthentication.getIdToken({
      forceRefresh: true
    })

    if (result.token == null) {
      console.log('no token returned')
      return
    }

    console.log('refreshing token')

    const currentUser = await FirebaseAuthentication.getCurrentUser()

    if (currentUser.user != null) {
      const user = await sdk.users.retrieve(currentUser.user.uid)

      await savePreferences<AuthState>(AUTH_STATE_KEY, {
        isLoggedIn: true,
        accessToken: result.token,
        category: user.category,
        userId: user.id
      })

      console.log('refreshed token successfully')
    }
  }

  const getAuthState = async (): Promise<AuthState | undefined> => {
    const authState = await getPreferences<AuthState>(AUTH_STATE_KEY)
    return authState
  }

  const setAuthState = async (authState: AuthState): Promise<void> => {
    await savePreferences<AuthState>(AUTH_STATE_KEY, authState)
  }

  const getCurrentUser = async (): Promise<User | undefined> => {
    const authState = await getPreferences<AuthState>(AUTH_STATE_KEY)
    if (authState == null) return undefined
    const user = await sdk.users.retrieve(authState.userId)
    return user
  }

  const authStatusChangeFunction: AuthStateChangeListener = (change) => {
    if (change.user == null) {
      logout()
        .then(() => {
          console.log('logged out')
        })
        .catch((error) => {
          console.log('error logging out', error)
        })
    } else {
      console.log('logged in')
    }
  }

  const eventListener = (): void => {
    FirebaseAuthentication.addListener(
      'authStateChange',
      authStatusChangeFunction
    )
      .then((listener) => {
        console.log('listener added', listener)
      })
      .catch((error) => {
        console.log('error adding listener', error)
      })
  }

  return {
    loginWithEmail,
    loginWithGoogle,
    loginWithToken,
    getAccessToken,
    logout,
    refreshToken,
    getAuthState,
    getCurrentUser,
    setAuthState,
    authStatusChangeFunction: eventListener
  }
}
