import { type InputOnChange } from '#components/ui/Input'
import { IonCol, IonInput, IonRow, IonText } from '@ionic/react'
import { useState } from 'react'
import MemberCopyLinkButton from './MemberCopyLinkButton'
import { mailOutline } from 'ionicons/icons'
import textUtil from '#utils/textUtil'
import TokenContainer from '#contexts/TokenContext'
import { useMemberContext } from '#contexts/MemberContext'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import useLoading from '#hooks/useLoading'
import SearchMemberInput from './search/SearchMemberInput'
import Text from '#components/ui/Text'
import Or from '#components/common/Or'
import Button from '#components/ui/Button'

interface Props {
  jarId: string
}

export default function MemberInviteSection({ jarId }: Props): JSX.Element {
  const [email, setEmail] = useState('')

  const { createMember } = useMemberContext()

  const { showToast } = useToast('warning')

  const { showToast: successShow } = useToast('success')

  const { startLoading, stopLoading } = useLoading('member.invite.loading')

  const handleEmailChange: InputOnChange = (e) => {
    const { value } = e.target
    if (typeof value === 'string') setEmail(value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!textUtil.isEmail(email)) {
      showToast(undefined, 'member.invite.emailRequired')
      return
    }

    if (createMember != null) {
      startLoading()

      createMember.mutate(
        {
          invitation_type: 'email',
          email,
          jar: {
            id: jarId,
            type: 'jars'
          }
        },
        {
          onSuccess: () => {
            stopLoading()
            successShow(undefined, 'member.invite.success')
            setEmail('')
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }

  const borderClass =
    '  border-2 border-gray-200  focus-within:border-gray-400  input-email-members rounded-md'
  return (
    <form onSubmit={handleSubmit} className=''>
      <IonRow className='ion-justify-content-between ion-align-items-center w-full mt-5 ion-no-padding'>
        <IonCol size='12' className='ion-mo-padding ion-no-margin'>
          <TokenContainer fetch={false}>
            <MemberCopyLinkButton jarId={jarId} />
          </TokenContainer>
        </IonCol>
      </IonRow>
      <Or />
      <IonText className='text-gray-600 font-semibold text-base ion-no-padding'>
        Email
      </IonText>
      <IonRow
        className={`ion-no-padding mx-0 mt-2 ion-align-items-end ion-justify-content-between  ${borderClass}`}
      >
        <IonCol className='ion-no-padding ion-no-margin' size='8'>
          <IonInput
            id='input-email-members'
            type='email'
            name='email'
            value={email}
            className='rounded-l-md'
            onIonInput={handleEmailChange}
          />
        </IonCol>

        <IonCol className='ion-no-padding ion-no-margin' size='4'>
          <Button
            size='small'
            color='dark'
            className='ion-no-margin w-9/12 md:w-1/4 ion-no-padding input-email-members rounded-r-md pointer-events-auto float-right font-semibold capitalize'
            type='submit'
            textOptions={{
              size: 'text-sm'
            }}
            iconOptions={{
              src: mailOutline,
              slot: 'start',
              color: 'gray-900',
              className:
                'mx-0 mr-1 ion-no-padding icon-invited pt-[0.15rem] text-gray-900'
            }}
            fill='clear'
            data-testid='invited-members'
            disabled={!textUtil.isEmail(email)}
          >
            collaborator.invite.button
          </Button>
        </IonCol>
      </IonRow>
      <Or />

      <div>
        <Text className='ion-text-center'>searchByTackpay</Text>
        <SearchMemberInput jarId={jarId} />
      </div>
    </form>
  )
}
