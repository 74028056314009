import { IonIcon } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import MemberDeleteModal from './MemberDeleteModal'
import MemberContainer from '#contexts/MemberContext'
import { useState } from 'react'
import { type UserCategory } from '#tackpay-sdk'

interface Props {
  memberId: string
  jarId?: string
  category: UserCategory
}
export default function MemberDelete({
  memberId,
  jarId,
  category
}: Props): JSX.Element {
  const [show, setShow] = useState(false)

  return (
    <>
      <IonIcon
        src={trashOutline}
        className='text-red-500 w-7 h-7 cursor-pointer'
        onClick={() => {
          setShow(true)
        }}
      />
      <MemberContainer fetch={false}>
        <MemberDeleteModal
          memberId={memberId}
          setShow={setShow}
          show={show}
          jarId={jarId}
          category={category}
        />
      </MemberContainer>
    </>
  )
}
