import Button from '#components/ui/Button'
import { IonRow } from '@ionic/react'
import { mailOutline } from 'ionicons/icons'

interface Props {
  disabled?: boolean
}
export default function CollaboratorInviteButton({
  disabled = true
}: Props): JSX.Element {
  return (
    <IonRow className='mt-5'>
      <Button
        data-testid='button_invited'
        className='w-full ion-no-margin'
        iconOptions={{
          src: mailOutline,
          slot: 'start',
          className: 'ion-text-center ion-align-items-center'
        }}
        type='submit'
        disabled={disabled}
      >
        collaborator.invite.button
      </Button>
    </IonRow>
  )
}
