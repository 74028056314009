import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import InputAmountDivide from './InputAmountDivide'
import { type MemberAmount } from './MemberList'

interface Props {
  member: MemberAmount
  membersAmount: MemberAmount[]
  setMemberAmount: (memberAmount: MemberAmount) => void
}
export default function MemberCardDivideAmount({
  member,
  membersAmount,
  setMemberAmount
}: Props): JSX.Element {
  return (
    <Card className='w-full'>
      <IonGrid className='ion-no-padding'>
        <IonRow className='ion-align-items-center'>
          <IonCol size='3'>
            <Avatar
              imgOptions={{
                src: member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
              }}
              avatarOptions={{
                className: 'w-14 h-14'
              }}
            />
          </IonCol>
          <IonCol size='5'>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              color='gray-900'
              fontWeight='font-semibold'
            >
              {member?.user?.person?.first_name ?? ''}{' '}
              {member?.user?.person?.last_name ?? ''}
            </Text>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              size='text-sm'
              className='truncate'
            >
              {member.email ?? ''}
            </Text>
          </IonCol>
          <IonCol size='4'>
            <InputAmountDivide
              member={member}
              setMemberAmount={setMemberAmount}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </Card>
  )
}
