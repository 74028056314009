import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { getSymbolCurrency } from '#constants/country'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { type FeeSimulator } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonRow } from '@ionic/react'
import { useEffect, useState } from 'react'
import { getPreferences } from 'storage/preferences'

export default function CheckoutAmount(): JSX.Element {
  const { checkout, isLoading, error, refetch } = useCheckoutContext()

  const [attempt, setAttempt] = useState(0)
  const [amountWithFee, setAmountWithFee] = useState<number | null>(null)

  useEffect(() => {
    if (!isLoading && error == null && checkout == null) {
      if (refetch != null) {
        if (attempt < 3) {
          setAttempt(attempt + 1)
          void refetch()
        }
      }
    }
  }, [checkout, attempt])

  useEffect(() => {
    const fetchStorageFee = async (): Promise<void> => {
      try {
        const fee = await getPreferences<FeeSimulator>('feeSimulator')
        if (fee != null) {
          if (fee.covered) {
            setAmountWithFee(
              fee.totalFeeAmount + (checkout?.payment?.gross_amount_cents ?? 0)
            )
          } else {
            setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
          }
        } else {
          setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
        }
      } catch (err) {
        console.error('Failed to fetch fee simulator preferences:', err)
        setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
      }
    }

    const intervalId = setInterval(() => {
      void fetchStorageFee()
    }, 100)

    void fetchStorageFee() // Fetch iniziale

    return () => {
      clearInterval(intervalId)
    }
  }, [checkout])

  if (isLoading) return <Skeleton className='w-1/3 mx-auto h-10' />

  if (error != null) return <ErrorBox error={error} />

  if (checkout == null)
    return (
      <ErrorBox
        error={{
          title: 'Checkout not found',
          code: 'checkout_not_found',
          detail: 'The checkout you are looking for does not exist',
          status: 404
        }}
      />
    )

  return (
    <IonRow className=' ion-justify-content-center py-5'>
      <Text
        className=''
        size='text-6xl'
        color='gray-900'
        fontWeight='font-extrabold'
      >
        {formattedAmount(
          amountWithFee ?? checkout?.payment?.gross_amount_cents
        )}
      </Text>
      <Text
        className=' pt-6'
        color='gray-900'
        size='text-2xl'
        fontWeight='font-extrabold'
      >
        {getSymbolCurrency(checkout?.payment?.currency ?? 'eur')}
      </Text>
    </IonRow>
  )
}
