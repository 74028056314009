import Button from '#components/ui/Button'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Jar } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/core'
import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useAuthState from '#hooks/useAuthState'

interface Props {
  setShowModal: (show: boolean) => void
  jars: Jar[]
  customerId: string
}
export default function PlanModalTeamOneContent({
  setShowModal,
  jars,
  customerId
}: Props): JSX.Element {
  const { routeInfo } = useIonRouter()

  const { getAuthState } = useAuthState()

  const queryClient = useQueryClient()

  const priceId =
    process.env.REACT_APP_FREE_PRICE ?? 'price_1OLmT0CDf41ktEVot67bdSag'

  const { startLoading, stopLoading } = useLoading(
    'subscription.update.loading'
  )

  const { showToast } = useToast('warning')

  const [currentTeam, setCurrentTeam] = useState<Jar>()

  const handleRefresh = (): void => {
    queryClient
      .refetchQueries({ type: 'active' })
      .then(() => {
        console.log('refetchQueries success')
      })
      .catch((error) => {
        console.log('invalidateQueries error', error)
      })
  }

  const handleSubmit = (): void => {
    let returnUrl =
      (process.env.REACT_APP_FRONTEND_URL ?? 'https://app.tackpay.net') +
      routeInfo.pathname

    if (currentTeam == null) {
      showToast(undefined, 'Please select a jar')
    } else {
      getAuthState()
        .then((authState) => {
          startLoading()

          returnUrl +=
            '?access_token=' +
            authState?.accessToken +
            '&user_id=' +
            authState?.userId +
            '&jar_id=' +
            jars
              .map((j) => j.id)
              .filter((j) => j !== currentTeam.id)
              .join(',') +
            '&operation=remove'

          sdk.subscriptions_links
            .create({
              success_url: returnUrl + '&success=true',
              cancel_url: returnUrl + '&success=false',
              quantity: 1,
              customer: {
                id: customerId ?? '',
                type: 'customers'
              },
              price_reference: priceId
            })
            .then((res) => {
              stopLoading()
              if (isPlatform('hybrid')) {
                Browser.open({ url: res.url })
                  .then(() => {
                    console.log('Opened browser!')
                  })
                  .catch((error) => {
                    console.log('cannot open the browser', error)
                  })

                void Browser.addListener('browserFinished', () => {
                  handleRefresh()
                  stopLoading()
                  setShowModal(false)
                })
              } else {
                stopLoading()
                window.location.href = res.url
              }
            })
            .catch((error) => {
              stopLoading()
              console.log(error)
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          stopLoading()
          console.log(error)
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <>
      <IonHeader color='light'>
        <IonToolbar color='light'>
          <IonTitle>Select jar</IonTitle>
          <IonButtons slot='start'>
            <Button
              onClick={() => {
                setShowModal(false)
              }}
              color='gray-500'
            >
              Close
            </Button>
          </IonButtons>
          <IonButtons slot='end'>
            <Button color='green-600' onClick={handleSubmit}>
              confirm
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <Text className='ion-text-center'>selectTeamToView</Text>
        <IonList className='mt-5'>
          {jars.map((jar) => (
            <IonItem
              key={jar.id}
              onClick={() => {
                setCurrentTeam(jar)
              }}
              className='cursor-pointer'
            >
              <IonAvatar slot='start'>
                <IonImg src={jar.avatar ?? DEFAULT_IMAGES.logoTeam} />
              </IonAvatar>
              <IonLabel>
                <h2>{jar.name}</h2>
                <p>{jar.category ?? jar.jar_type}</p>
              </IonLabel>
              {currentTeam?.id === jar.id && (
                <IonIcon icon={checkmark} color='green-500' size='small' />
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  )
}
