import Button from '#components/ui/Button'
import { useTokenContext } from '#contexts/TokenContext'

export default function RegistrationCollaboratorButton(): JSX.Element {
  const { isLoading, token } = useTokenContext()

  return (
    <Button
      type='submit'
      className='w-full mt-10'
      disabled={isLoading}
      translationOptions={{
        translate: 'yes',
        data: { companyName: token?.company?.name ?? '' }
      }}
    >
      registration.collaborator.button
    </Button>
  )
}
