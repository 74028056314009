import ButtonStep from '#components/common/ButtonStep'
import CardCategory from '#components/common/CardCategory'
import AuthLayout from '#components/layouts/auth/AuthLayout'
import { type JarCategory } from '#tackpay-sdk'
import { IonCol, useIonRouter } from '@ionic/react'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import { useParams } from 'react-router'
import ProgressBar from '#components/ui/ProgessBar'
import useToast from '#hooks/useToast'
import { useRegistrationContext } from '#contexts/RegistrationContext'

export default function RegistrationBusinessTypeStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  const { jarData, setJarData, setUserData } = useRegistrationContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleRoleSelected = (jarType: JarCategory): void => {
    if (jarType === 'private') {
      setJarData({
        jar_type: jarType,
        tipping_rule: 'individual',
        category: 'business'
      })
      setUserData({ category: 'business' })
      push(`/registrations/${category}/persons`)
    } else {
      setJarData({ jar_type: jarType, category: 'manager' })
      setUserData({ category: 'manager' })
      push(`/registrations/${category}/tippingRules`)
    }
  }

  const handlePrevious = (): void => {
    push(`/registrations/${category}`, 'back')
  }

  const handleNextStep = (): void => {
    if (jarData?.jar_type == null) {
      showToast(undefined, 'registration.chips.businessType.error')
      return
    }

    if (jarData.jar_type === 'shared') {
      push(`/registrations/${category}/tippingRules`)
    } else {
      push(`/registrations/${category}/persons`)
    }
  }

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: `/registrations/${category}`
        }
      }}
    >
      <ProgressBar text='registration.chips.jar_type.title' value={0.3} />
      <IonCol className='space-y-6'>
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleRoleSelected('private')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-private.svg'
            }
          }}
          title='teamPage.type.private.title'
          description='teamPage.type.private.description'
          showCheckIcon={jarData?.jar_type === 'private'}
        />
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleRoleSelected('shared')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-shared.svg'
            }
          }}
          title='teamPage.type.shared.title'
          description='teamPage.type.shared.description'
          showCheckIcon={jarData?.jar_type === 'shared'}
        />
      </IonCol>
      <ButtonStep handleBack={handlePrevious} handleNext={handleNextStep} />
    </AuthLayout>
  )
}
