import Text from '#components/ui/Text'
import { type Jar, type Company } from '#tackpay-sdk'
import { SwiperSlide, Swiper } from 'swiper/react'
import CardQrCodeBusiness from './cardQrCode/CardQrCodeBusiness'
import isDesktop from '#utils/isDesktop'
import { Pagination } from 'swiper'

interface Props {
  company?: Company | null
  jars?: Jar[] | null
}

export default function HomeQrCodeSection({
  company,
  jars
}: Props): JSX.Element {
  return (
    <div className='mt-5 mb-10'>
      <Text
        color='gray-900'
        fontWeight='font-bold'
        size='text-xl'
        data-testid='qrCode-section'
      >
        dashboard.your_qr
      </Text>
      {/* < className='absolute inset-x-0 ion-padding md:relative'> */}
      <div>
        <Swiper
          modules={[Pagination]}
          autoplay={false}
          slidesPerView={2}
          pagination={{
            dynamicBullets: true
          }}
          spaceBetween={isDesktop() ? 0 : 10}
          className='ion-padding ion-margin h-96'
        >
          {company?.jars != null && company.jars.length > 1 && (
            <SwiperSlide>
              <CardQrCodeBusiness company={company} />
            </SwiperSlide>
          )}
          {jars?.map((jar) => {
            return (
              <SwiperSlide key={jar.id}>
                <CardQrCodeBusiness jar={jar} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
