import Input, { type InputOnChange } from '#components/ui/Input'
import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import Toggle from '#components/ui/Toggle'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { IonRow } from '@ionic/react'
// import { logoGoogle } from 'ionicons/icons'
import { type ChangeEventHandler } from 'react'

interface Props {
  isTipped?: boolean
}
export default function CheckoutSettingLinksSocial({
  isTipped = false
}: Props): JSX.Element {
  const { checkoutSetting, setCurrentCheckoutSetting } =
    useCheckoutSettingContext()

  const linksSocial = checkoutSetting?.links_social

  const handleLinkSocialChange: InputOnChange = (e) => {
    const { name, value } = e.target

    if (typeof value === 'string' && setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        links_social: {
          ...linksSocial,
          [name]: value
        } as any
      })
    }
  }

  const handleShowLinkSocialChange = (show: boolean): void => {
    if (setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        links_social: { ...linksSocial, active: show } as any
      })
    }
  }

  const handleLinkSocialMediaChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { name, value } = e.target
    if (typeof value === 'string' && setCurrentCheckoutSetting != null) {
      setCurrentCheckoutSetting({
        ...checkoutSetting,
        links_social: {
          ...linksSocial,
          [name]: value
        } as any
      })
    }
  }

  return (
    <div slot='content' className='pt-1 space-y-4'>
      <Text size='text-base' data-testid='subtitle-socials'>
        checkoutSetting.linksSocialInfo
      </Text>
      <div className='col-span-3 sm:col-span-2'>
        <label
          htmlFor='facebook'
          className='block text-sm font-medium text-gray-700'
        >
          Facebook
        </label>
        <div className='mt-1 flex rounded-md shadow-sm'>
          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 '>
            www.facebook.com/
          </span>
          <input
            type='text'
            name='facebook'
            id='facebook'
            value={linksSocial?.facebook ?? ''}
            onChange={handleLinkSocialMediaChange}
            className='focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border border-gray-300 py-2.5 focus-visible:ring-gray-500 focus:outline-none'
          />
        </div>
      </div>
      <div className='col-span-3 sm:col-span-2'>
        <label
          htmlFor='instagram'
          className='block text-sm font-medium text-gray-700'
        >
          Instagram
        </label>
        <div className='mt-1 flex rounded-md shadow-sm'>
          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500'>
            www.instagram.com/
          </span>
          <input
            type='text'
            name='instagram'
            id='instagram'
            value={linksSocial?.instagram ?? ''}
            onChange={handleLinkSocialMediaChange}
            className='focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border border-gray-300 py-2.5 focus-visible:border-gray-500 focus-visible:ring-gray-500 focus:outline-none'
          />
        </div>
      </div>
      <div>
        {!isTipped && (
          <Input
            type='url'
            id='google_review'
            name='google_review'
            labelOptions={{
              children: 'googleReview'
            }}
            // iconOptions={{
            //   src: logoGoogle,
            //   className: 'w-10 h-10',
            //   slot: 'end'
            // }}
            value={linksSocial?.google_review}
            onIonInput={handleLinkSocialChange}
            testId='input-google-review'
          />
        )}
      </div>
      <div>
        <Input
          type='url'
          name='website'
          labelOptions={{
            children: 'website'
          }}
          value={linksSocial?.website}
          onIonInput={handleLinkSocialChange}
          testId='input-website'
        />
      </div>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Label capitalize={false}>checkoutSetting.showLinkSocial</Label>
        <Toggle
          name='active'
          checked={linksSocial?.active}
          onClick={() => {
            if (linksSocial?.active != null) {
              handleShowLinkSocialChange(!linksSocial.active)
            } else {
              handleShowLinkSocialChange(true)
            }
          }}
          data-testid='toogle-socials'
        />
      </IonRow>
    </div>
  )
}
