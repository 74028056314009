/* eslint-disable no-useless-escape */
export function validatePassword(password: string): boolean {
  const isNumberRegx = /\d/

  const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

  const upperCaseRegx = /.*[A-Z].*/

  const passwordLen = 8

  if (password.length < passwordLen) {
    return false
  }

  if (!isNumberRegx.test(password)) {
    return false
  }

  if (!specialCharacterRegx.test(password)) {
    return false
  }

  if (!upperCaseRegx.test(password)) {
    return false
  }

  return true
}
