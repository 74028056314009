import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type StripeGatewayAccount } from './stripeGatewayAccounts'
import { type QueryParamsRetrieve } from 'sdk/query'

type StripeGatewayIdentityType = 'stripe_gateway_identities'
type StripeGatewayIdentityRel = ResourceRel & {
  type: StripeGatewayIdentityType
}

type StripeGatewayAccountRel = ResourceRel & { type: 'stripe_gateway_accounts' }

interface StripeGatewayIdentity extends Resource {
  readonly type: StripeGatewayIdentityType
  identity_url: string
  reference: string
  reference_origin: string
  return_url: string
  url_type: string
  stripe_gateway_account?: StripeGatewayAccount
}

interface StripeGatewayIdentityCreate extends ResourceCreate {
  return_url: string
  url_type: string
  stripe_gateway_account: StripeGatewayAccountRel
}

class StripeGatewayIdentities extends ApiResource<StripeGatewayIdentity> {
  static readonly TYPE: StripeGatewayIdentityType =
    'stripe_gateway_identities' as const

  async create(
    resource: StripeGatewayIdentityCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StripeGatewayIdentity> {
    return await this.resources.create<
      StripeGatewayIdentityCreate,
      StripeGatewayIdentity
    >({ ...resource, type: StripeGatewayIdentities.TYPE }, params, options)
  }

  isStripeGatewayIdentity(resource: any): resource is StripeGatewayIdentity {
    return (
      resource.type != null && resource.type === StripeGatewayIdentities.TYPE
    )
  }

  relationship(id: string | ResourceId | null): StripeGatewayIdentityRel {
    return id === null || typeof id === 'string'
      ? { id, type: StripeGatewayIdentities.TYPE }
      : { id: id.id, type: StripeGatewayIdentities.TYPE }
  }

  type(): StripeGatewayIdentityType {
    return StripeGatewayIdentities.TYPE
  }
}

export type { StripeGatewayIdentity, StripeGatewayIdentityCreate }
export default StripeGatewayIdentities
