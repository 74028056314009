import Button from '#components/ui/Button'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useCompanyContext } from '#contexts/CompanyContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Jar } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { isPlatform } from '@ionic/core'
import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react'
import { checkmark, trash } from 'ionicons/icons'
import { useState } from 'react'
import { Browser } from '@capacitor/browser'
import { useQueryClient } from '@tanstack/react-query'
import useAuthState from '#hooks/useAuthState'

interface Props {
  operation: 'add' | 'remove'
  setShowModal: (show: boolean) => void
  jars: Jar[]
  subscriptionItemId: string
  newQuantity: number
  customerId: string
  subscriptionQuantity: number
}
export default function PlanModalTeamManyContent({
  operation,
  setShowModal,
  jars,
  subscriptionItemId,
  newQuantity,
  customerId,
  subscriptionQuantity
}: Props): JSX.Element {
  const queryClient = useQueryClient()

  const { routeInfo } = useIonRouter()

  const priceId =
    process.env.REACT_APP_PREMIUM_PRICE ?? 'price_1OL5bECDf41ktEVoQjM7CFbQ'

  const { refetch } = useCompanyContext()

  const { startLoading, stopLoading } = useLoading(
    'subscription.update.loading'
  )

  const { getAuthState } = useAuthState()

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const [currentTeam] = useState<Jar[]>(
    operation === 'add'
      ? jars.filter((t) => !t.active)
      : jars.filter((t) => t.active)
  )

  const [teamToUpdated, setTeamToUpdated] = useState<Jar[]>([])

  const handleSelectTeam = (jar: Jar): void => {
    const alreadySelected = teamToUpdated?.find((t) => t.id === jar.id)
    if (alreadySelected != null) {
      setTeamToUpdated(teamToUpdated?.filter((t) => t.id !== jar.id))
    } else {
      setTeamToUpdated([...teamToUpdated, jar])
    }
  }

  const handleTeamUpdate = async (jar: Jar): Promise<void> => {
    await sdk.jars.update({
      id: jar.id,
      active: operation === 'add'
    })
  }

  const handleRefresh = (): void => {
    queryClient
      .refetchQueries({ type: 'active' })
      .then(() => {
        console.log('refetchQueries success')
      })
      .catch((error) => {
        console.log('invalidateQueries error', error)
      })
  }

  const handleJarUpdated = (): void => {
    for (const jar of teamToUpdated) {
      handleTeamUpdate(jar)
        .then(() => {
          console.log('jar updated')
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
          console.log(error)
        })
    }
  }

  const handleSubmit = (): void => {
    const activeJarTotal = jars.filter((t) => t.active).length

    const diff =
      operation === 'add'
        ? newQuantity - activeJarTotal
        : activeJarTotal - newQuantity

    if (operation === 'add') {
      const diffCurrentTeam =
        jars.length -
        teamToUpdated.length -
        activeJarTotal -
        (newQuantity === 2 ? 1 : 0)
      console.log('diffCurrentTeam', diffCurrentTeam)
      if (diffCurrentTeam !== 0) {
        showToast(undefined, 'selectTeamToAddQty', {
          data: { qty: Math.abs(diffCurrentTeam) }
        })
        return
      }
    } else if (operation === 'remove') {
      if (diff !== teamToUpdated.length) {
        showToast(undefined, 'selectTeamToRemoveQty', {
          data: { qty: diff }
        })
        return
      }
    }

    setShowModal(false)
    startLoading()

    if (subscriptionQuantity === 1) {
      let returnUrl =
        (process.env.REACT_APP_FRONTEND_URL ?? 'https://app.tackpay.net') +
        routeInfo.pathname

      getAuthState()
        .then((authState) => {
          returnUrl += `?access_token=${authState?.accessToken}&user_id=${authState?.userId}`
          returnUrl += `&jar_id=${teamToUpdated.map((t) => t.id).join(',')}`
          returnUrl += `&operation=${operation}`

          sdk.subscriptions_links
            .create({
              price_reference: priceId,
              success_url: returnUrl + '&success=true',
              cancel_url: returnUrl + '&success=false',
              quantity: newQuantity,
              customer: {
                id: customerId,
                type: 'customers'
              }
            })
            .then((res) => {
              if (isPlatform('hybrid')) {
                Browser.open({ url: res.url })
                  .then(() => {
                    console.log('Opened browser!')
                  })
                  .catch((error) => {
                    console.log('cannot open the browser', error)
                  })

                void Browser.addListener('browserFinished', () => {
                  handleRefresh()
                  stopLoading()
                  setShowModal(false)
                })
              } else {
                stopLoading()
                window.location.href = res.url
              }
            })
            .catch((error) => {
              stopLoading()
              console.log(error)
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          stopLoading()
          console.log(error)
          showToast(undefined, getErrorMessage(error))
        })
    } else {
      sdk.subscription_items
        .update({
          id: subscriptionItemId,
          quantity: newQuantity
        })
        .then(() => {
          handleJarUpdated()

          if (refetch != null) {
            void refetch({
              queryKey: [
                'company',
                {
                  include: ['customer.subscription.subscription_items', 'jars']
                }
              ]
            })
          }

          stopLoading()
          successToast(undefined, 'subscription.update.success')
        })
        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <>
      <IonHeader color='light'>
        <IonToolbar color='light'>
          <IonTitle>Select jar</IonTitle>
          <IonButtons slot='start'>
            <Button
              onClick={() => {
                setShowModal(false)
              }}
              color='gray-500'
            >
              Close
            </Button>
          </IonButtons>
          <IonButtons slot='end'>
            <Button color='green-600' onClick={handleSubmit}>
              confirm
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <Text className='ion-text-center'>
          {operation === 'remove' ? 'selectTeamToRemove' : 'selectTeamToAdd'}
        </Text>
        <IonList className='mt-5'>
          {currentTeam.map((jar) => (
            <IonItem
              key={jar.id}
              onClick={() => {
                handleSelectTeam(jar)
              }}
              className='cursor-pointer'
            >
              <IonAvatar slot='start'>
                <IonImg src={jar.avatar ?? DEFAULT_IMAGES.logoTeam} />
              </IonAvatar>
              <IonLabel>
                <h2>{jar.name}</h2>
                <p>{jar.category ?? jar.jar_type}</p>
              </IonLabel>
              {teamToUpdated?.find((t) => t.id === jar.id) != null && (
                <IonIcon
                  icon={operation === 'remove' ? trash : checkmark}
                  color={operation === 'remove' ? 'danger' : 'green-500'}
                  size='small'
                />
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  )
}
