import ErrorBox from '#components/errors/ErrorBox'
import CoverIcon from '#components/ui/CoverIcon'
import Skeleton from '#components/ui/Skeleton'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import useFile from '#hooks/useFile'
import useStorage from '#hooks/useStorage'
import useToast from '#hooks/useToast'
import { type Payment, type Transfer } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { isPlatform } from '@ionic/core'
import { IonCol, IonRow } from '@ionic/react'
import { receiptOutline } from 'ionicons/icons'
import fileSave from 'file-saver'
import useLoading from '#hooks/useLoading'
import { useShare } from '#hooks/useShare'
import { isDonation } from '#utils/isDonation'
import { useUserContext } from '#contexts/UserContext'

interface Props {
  isLoading: boolean
  error?: any
  payment?: Payment | Transfer | null
}
export default function PaymentTitle({
  isLoading,
  error,
  payment
}: Props): JSX.Element {
  const { user } = useUserContext()

  const TIMEOUT = 1000 * 3 // 3 seconds

  const { shareElement } = useShare()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('checkout.bill.loading')

  const { downloadFile } = useFile()

  const { getBlobImage } = useStorage()

  if (isLoading)
    return (
      <IonCol>
        <Skeleton />
        <Skeleton />
      </IonCol>
    )

  if (error != null) return <ErrorBox error={error} />

  if (payment == null)
    return (
      <ErrorBox
        error={{
          title: 'Transfer not found',
          detail: 'The transfer you are looking for does not exist',
          code: 'transfer_not_found',
          status: 404
        }}
      />
    )

  const handleDownloadBill = (): void => {
    startLoading()

    const paymentId = sdk.payments.isPayment(payment)
      ? payment.id
      : payment.payment?.id ?? ''

    sdk.invoices
      .create({
        category: 'tipped',
        payment: {
          id: paymentId,
          type: 'payments'
        }
      })
      .then((response) => {
        if (isPlatform('hybrid')) {
          setTimeout(() => {
            downloadFile(response.storage_path ?? '', `bill_${response.id}.pdf`)
              .then((res) => {
                shareElement({
                  dialogTitle: 'Bill',
                  title: 'Bill',
                  url: res.path
                })
                  .then(() => {
                    showSuccess('Bill downloaded')
                    stopLoading()
                  })
                  .catch((error) => {
                    stopLoading()
                    showToast(undefined, getErrorMessage(error))
                  })
              })
              .catch((error) => {
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              })
          }, TIMEOUT)
        } else {
          setTimeout(() => {
            getBlobImage(response.storage_path ?? '')
              .then((blob) => {
                stopLoading()
                fileSave(blob, `bill_${paymentId}.pdf`)
              })
              .catch((error) => {
                stopLoading()
                showToast(undefined, getErrorMessage(error))
              })
          }, TIMEOUT)
        }
      })
      .catch((error) => {
        stopLoading()
        showToast(undefined, getErrorMessage(error))
      })
  }

  return (
    <IonCol className='ion-no-margin ion-no-padding'>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <Title hasSubtitle>
          {isDonation(user?.id) ? 'donation' : 'payment'}
        </Title>
        <div className='mt-1'>
          <CoverIcon
            iconOptions={{ src: receiptOutline }}
            onClick={handleDownloadBill}
          />
        </div>
      </IonRow>
      <SubTitle
        translationOptions={{
          translate: 'no'
        }}
        size='text-base'
      >
        #{(payment as Payment)?.id ?? (payment as Transfer)?.id ?? ''}
      </SubTitle>
    </IonCol>
  )
}
