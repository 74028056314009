import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react'
import { useState, type ComponentProps, useEffect } from 'react'
import AppNotificationHeader from './AppNotificationHeader'
import BackButton from '#components/ui/BackButton'
import Skeleton from '#components/ui/Skeleton'
import AppCustomHeaderIcon from './AppCustomHeaderIcon'
import { useUserContext } from '#contexts/UserContext'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useParams } from 'react-router'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import isCollaborator from '#utils/isCollaborator'

interface AppHeaderProps {
  /**
   * Show menu button.
   */
  showMenuButton?: boolean
  /**
   * The header props.
   */
  ionHeaderOptions?: ComponentProps<typeof IonHeader>
  /**
   * Show back button.
   */
  showBackButton?: boolean
  /**
   * Show Notifications button.
   * @default false
   */
  showNotificationsButton?: boolean
  /**
   * Show settings icon.
   * @default false
   */
  showCustomIcon?: boolean
  /**
   * Back button options
   */
  backButtonProps?: ComponentProps<typeof BackButton>
  /**
   * Settings icon options
   */
  customIconOptions?: ComponentProps<typeof AppCustomHeaderIcon> & {
    slot?: 'end' | 'start'
  }
}

export default function AppHeader(props: AppHeaderProps): JSX.Element {
  const {
    ionHeaderOptions,
    showMenuButton,
    showNotificationsButton,
    showBackButton,
    showCustomIcon,
    customIconOptions,
    backButtonProps
  } = props

  const { category } = useParams<DashboardRoutesParams>()

  const [showHeader, setShowHeader] = useState(true)

  const { user } = useUserContext()

  const { collaborator, isLoading, error } = useCollaboratorContext()

  useEffect(() => {
    if (isCollaborator(category)) {
      if (!isLoading && error == null) {
        if (collaborator == null) {
          setShowHeader(false)
        } else {
          setShowHeader(true)
        }
      }
    }
  }, [collaborator, error, isLoading, category])

  // const menu = document.querySelector('ion-menu')

  return (
    <IonHeader {...ionHeaderOptions} className='ion-no-border p-2'>
      <IonToolbar
        color='black'
        className='ion-justify-content-between ion-align-items-center'
      >
        {showMenuButton === true && showHeader && (
          <IonButtons
            slot='start'
            className='bg-transparent z-50'
            data-testid='button-menu'
          >
            <IonMenuButton
              color='dark'
              // onClick={() => {
              //   void menu?.close(false)
              // }}
            />
          </IonButtons>
        )}
        {showNotificationsButton === true && showHeader && (
          <IonButtons
            slot='end'
            className='bg-transparent ion-justify-content-end w-9/12'
          >
            {user == null ? (
              <Skeleton />
            ) : (
              <AppNotificationHeader userId={user?.id} />
            )}
          </IonButtons>
        )}
        {showBackButton === true && showHeader && (
          <IonButtons slot='start' className=''>
            <BackButton {...backButtonProps} />
          </IonButtons>
        )}

        {showCustomIcon === true && customIconOptions != null && showHeader && (
          <IonButtons slot={customIconOptions?.slot ?? 'end'} className=''>
            <AppCustomHeaderIcon {...customIconOptions} />
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  )
}
