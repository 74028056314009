import DateRanger from '#components/common/DateRanger'
import SelectTeam from '#components/common/TeamSelection'
import AppLayout from '#components/layouts/app/AppLayout'
import CardDownloadAccountStatement from '#components/pages/accountStatements/CardDownloadAccountStatement'
import { type DateTimeValue } from '#components/ui/DateTime'
import SubTitle from '#components/ui/SubTitle'
import Text from '#components/ui/Text'
import Title from '#components/ui/Title'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import useFilesystem from '#hooks/useFilesystem'
import useLoading from '#hooks/useLoading'
import { useShare } from '#hooks/useShare'
import useToast from '#hooks/useToast'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type AccountStatement, type Jar } from '#tackpay-sdk'
import createPaymentCsv from '#utils/createPaymentCsv'
import getErrorMessage from '#utils/getErrorMessage'
import { isBusiness } from '#utils/isBusiness'
import isCollaborator from '#utils/isCollaborator'
import { isDonation } from '#utils/isDonation'
import isWeb from '#utils/isWeb'
import sdk from '#utils/sdk'
import { Directory, Encoding } from '@capacitor/filesystem'
import { IonRow } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

function HandleAccountStatement(): JSX.Element {
  const { user } = useUserContext()

  const { shareElement } = useShare()

  const { showToast } = useToast('warning')

  const { write } = useFilesystem()

  const { showToast: showInfoToast } = useToast('default')

  const { showToast: successShow } = useToast('success')

  const { startLoading, stopLoading } = useLoading('accountStatement.loading')

  const currentDate = new Date()

  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const [dateFrom, setDateFrom] = useState<DateTimeValue>(
    lastMonth.toISOString()
  )
  const [dateTo, setDateTo] = useState<DateTimeValue>(currentDate.toISOString())

  const handleSave = async (
    accountStatement: AccountStatement
  ): Promise<void> => {
    const csvObject = createPaymentCsv(
      accountStatement.payments,
      accountStatement.transfers,
      user?.lang,
      'tipped'
    )
    const person = `${user?.person?.first_name} ${user?.person?.last_name}`
    const tackpayId = user?.tackpay_id?.value ?? ''
    const fileName = `Transactions list - ${person} - ${tackpayId}.csv`

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then((res) => {
          console.log('File written')
          shareElement({
            dialogTitle: 'Account Statement',
            title: 'Account Statement',
            url: res.uri
          })
            .then(() => {
              stopLoading()
              successShow(undefined, 'accountStatement.success')
            })
            .catch((error) => {
              console.log('error', error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleDownload = (): void => {
    if (dateFrom == null || dateTo == null) {
      showToast(undefined, 'accountStatement.dateRequired')
    } else if (dateFrom > dateTo) {
      showToast(undefined, 'accountStatement.dateInvalid')
    } else {
      startLoading()
      sdk.account_statements
        .create({
          to_date: String(dateTo),
          from_date: String(dateFrom),
          user: { id: user?.id ?? '', type: 'users' }
        })
        .then((accountStatement) => {
          if (
            (accountStatement?.payments == null ||
              accountStatement.payments.length === 0) &&
            (accountStatement?.transfers == null ||
              accountStatement.transfers.length === 0)
          ) {
            stopLoading()
            showInfoToast(undefined, 'accountStatement.emptyPayment')
          } else {
            void handleSave(accountStatement)
          }
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }
  return (
    <>
      <Text color='gray-900' fontWeight='font-bold'>
        accountStatement.selectPeriod
      </Text>
      <DateRanger
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        preferWheel={false}
      />
      <CardDownloadAccountStatement onClick={handleDownload} />
    </>
  )
}

const HandleBusinessAccountStatement = ({
  isCollaborator
}: {
  isCollaborator: boolean
}): JSX.Element => {
  const { user } = useUserContext()

  const { company } = useCompanyContext()

  const { collaborator } = useCollaboratorContext()

  const { write } = useFilesystem()

  const { showToast } = useToast('warning')

  const { showToast: successShow } = useToast('success')

  const { showToast: showInfoToast } = useToast('default')

  const { startLoading, stopLoading } = useLoading('accountStatement.loading')

  const currentDate = new Date()

  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const [dateFrom, setDateFrom] = useState<DateTimeValue>(
    lastMonth.toISOString()
  )
  const [dateTo, setDateTo] = useState<DateTimeValue>(currentDate.toISOString())

  const [currentTeams, setCurrentTeams] = useState<Jar | undefined>(
    company?.jars?.[0] ?? undefined
  )

  const [jars, setJars] = useState(
    isCollaborator ? collaborator?.jars ?? [] : company?.jars ?? []
  )

  useEffect(() => {
    if (isCollaborator) {
      if (collaborator?.jars != null && collaborator.jars.length > 0) {
        setCurrentTeams(collaborator.jars[0])
        setJars(collaborator.jars)
      }
    } else {
      if (company?.jars != null && company.jars.length > 0) {
        if (currentTeams == null) {
          setCurrentTeams(company.jars[0])
        }
        setJars(company.jars)
      }
    }
  }, [currentTeams, company, isCollaborator])

  const handleSave = async (
    accountStatement: AccountStatement
  ): Promise<void> => {
    const csvObject = createPaymentCsv(
      accountStatement.payments,
      accountStatement.transfers,
      user?.lang,
      'jar'
    )

    const tackpayId = Array.isArray(currentTeams?.tackpay_ids)
      ? currentTeams?.tackpay_ids?.[0]?.value
      : currentTeams?.tackpay_ids?.value ?? 'none'
    const fileName = `Jar Transactions List - ${currentTeams?.name} - ${tackpayId}.csv`

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then(() => {
          console.log('File written')
          stopLoading()
          successShow(undefined, 'accountStatement.success')
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleDownload = (): void => {
    if (dateFrom == null || dateTo == null) {
      showToast(undefined, 'accountStatement.dateRequired')
    } else if (dateFrom > dateTo) {
      showToast(undefined, 'accountStatement.dateInvalid')
    } else {
      startLoading()
      sdk.account_statements
        .create(
          {
            to_date: String(dateTo),
            from_date: String(dateFrom),
            jar: {
              id: currentTeams?.id ?? '',
              type: 'jars'
            }
          },
          {
            include: ['payments']
          }
        )
        .then((accountStatement) => {
          if (
            accountStatement?.payments == null ||
            accountStatement.payments.length === 0
          ) {
            stopLoading()
            showInfoToast(undefined, 'accountStatement.emptyPayment')
          } else {
            void handleSave(accountStatement)
          }
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }
  return (
    <>
      <div className='my-5'>
        <SelectTeam
          currentTeam={currentTeams}
          setCurrentTeam={setCurrentTeams}
          jars={jars}
          multiple={false}
          labelProps={{
            show: true,
            options: {
              children: 'accountStatement.selectJar',
              capitalize: false
            }
          }}
        />
      </div>
      <Text color='gray-900' fontWeight='font-bold'>
        accountStatement.selectPeriod
      </Text>
      <DateRanger
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        preferWheel={false}
      />
      <CardDownloadAccountStatement onClick={handleDownload} />
    </>
  )
}

function HandleTitle(): JSX.Element {
  const { user } = useUserContext()

  return (
    <>
      <IonRow className='w-full ion-no-padding mb-5 mt-0 mx-0'>
        <Title className='ion-no-margin ion-no-padding'>
          {!isDonation(user?.id)
            ? 'accountStatement.title'
            : 'accountStatement_donation_title'}
        </Title>
      </IonRow>

      <SubTitle>
        {!isDonation(user?.id)
          ? 'accountStatement.subtitle'
          : 'accountStatement_donation_subtitle'}
      </SubTitle>
    </>
  )
}

export default function AccountStatementPage(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person', 'tackpay_id']
        }
      }}
      companyProps={{
        isCollaborator: isCollaborator(category),
        params: {
          include: ['jars']
        }
      }}
      collaboratorProps={{
        fetch: isCollaborator(category),
        params: {
          include: ['jars.tackpay_ids']
        }
      }}
    >
      <HandleTitle />
      {!isBusiness(category) ? (
        <HandleAccountStatement />
      ) : (
        <HandleBusinessAccountStatement
          isCollaborator={isCollaborator(category)}
        />
      )}
    </AppLayout>
  )
}
