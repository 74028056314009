import { useParams } from 'react-router'
import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import CheckoutSettingContainer from '#contexts/CheckoutSettingContext'
import SubTitle from '#components/ui/SubTitle'
import { IonAccordionGroup, IonItem } from '@ionic/react'
import Accordion from '#components/ui/Accordion'
import Label from '#components/ui/Label'
import CheckoutSettingBrand from '#components/pages/checkoutSettings/CheckoutSettingBrand'
import CheckoutSettingAmount from '#components/pages/checkoutSettings/CheckoutSettingAmount'
import { useTeamContext } from '#contexts/JarContext'
import CheckoutSettingMember from '#components/pages/checkoutSettings/CheckoutSettingMember'
import CheckoutSettingLinksSocial from '#components/pages/checkoutSettings/CheckoutSettingLinksSocial'
import CheckoutSettingReply from '#components/pages/checkoutSettings/CheckoutSettingReply'
import CheckoutSettingButtonPreview from '#components/pages/checkoutSettings/CheckoutSettingButtonPreview'
import CheckoutSettingButtonSave from '#components/pages/checkoutSettings/CheckoutSettingButtonSave'
import { type UserCategory } from '#tackpay-sdk'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { useUserContext } from '#contexts/UserContext'
import { EUROPEAN_COUNTRY_CODES } from '#constants/country'
import { useEffect, useRef } from 'react'

interface Props {
  jarId: string
  category: UserCategory
}
const HandleCheckoutSetting = ({ jarId, category }: Props): JSX.Element => {
  const { user } = useUserContext()

  const { jar } = useTeamContext()

  const isFullTippingRule =
    user?.person?.address != null
      ? EUROPEAN_COUNTRY_CODES.includes(
          user?.person?.address?.country_code.toUpperCase() as any
        )
      : false

  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null)

  useEffect(() => {
    if (accordionGroup.current == null) {
      return
    }

    accordionGroup.current.value = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth'
    ]
  }, [])
  return (
    <CheckoutSettingContainer
      jarId={jarId}
      params={{
        include: ['links_social', 'reply']
      }}
    >
      <IonAccordionGroup multiple ref={accordionGroup}>
        <Accordion value='first' data-testid='accordion-brand'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label
              color='text-gray-900'
              className='italic font-semibold'
              size='lg'
            >
              Brand
            </Label>
          </IonItem>
          <CheckoutSettingBrand jarId={jarId} isCompany />
        </Accordion>
        {jar?.jar_type === 'shared' && isFullTippingRule && (
          <Accordion value='second' data-testid='accordion-members'>
            <IonItem
              slot='header'
              lines='none'
              className='ion-no-padding'
              color='transparent'
            >
              <Label
                color='text-gray-900'
                className='italic font-semibold'
                size='lg'
              >
                teamCheckoutSetting.members
              </Label>
            </IonItem>
            <CheckoutSettingMember />
          </Accordion>
        )}
        <Accordion value='third' data-testid='accordion-amounts'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label
              color='text-gray-900'
              className='italic font-semibold'
              size='lg'
            >
              amounts
            </Label>
          </IonItem>
          <CheckoutSettingAmount />
        </Accordion>

        <Accordion value='fourth' data-testid='accordion-socials'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label
              color='text-gray-900'
              className='italic font-semibold'
              size='lg'
            >
              Links Social
            </Label>
          </IonItem>
          <CheckoutSettingLinksSocial />
        </Accordion>
        <Accordion value='fifth' data-testid='accordion-replySettings'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label
              color='text-gray-900'
              className='italic font-semibold'
              size='lg'
            >
              reply
            </Label>
          </IonItem>
          <CheckoutSettingReply />
        </Accordion>
      </IonAccordionGroup>
      <CheckoutSettingButtonPreview category={category} jarId={jarId} />
      <CheckoutSettingButtonSave jarId={jarId} />
    </CheckoutSettingContainer>
  )
}

export default function CheckoutSetting(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      teamProps={{
        fetch: true
      }}
    >
      <Title>checkoutSetting.title</Title>
      <SubTitle>checkoutSetting.subtitleTeam</SubTitle>
      <HandleCheckoutSetting jarId={jarId} category={category} />
    </AppLayout>
  )
}
