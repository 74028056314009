import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import Wallet from './Wallet'
import PayoutsTipped from '../settings/views/withdrawals/views/payouts/PayoutsTipped'
import AddBanksWallets from '../settings/views/withdrawals/views/banks/AddBanksWallets'
import TransferId from './payments/TransferId'
import PaymentId from './payments/PaymentId'

const baseRoute = '/dashboard/:category/wallets'
export default function WalletsRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path={baseRoute}>
          <Wallet />
        </Route>
        <Route exact path={`${baseRoute}/withdrawals/payouts`}>
          <PayoutsTipped />
        </Route>
        <Route exact path={`${baseRoute}/add/banks`}>
          <AddBanksWallets />
        </Route>
        <Route exact path={`${baseRoute}/transfers/:transferId`}>
          <TransferId />
        </Route>
        <Route exact path={`${baseRoute}/payments/:paymentId`}>
          <PaymentId />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  )
}
