import AppLayout from '#components/layouts/app/AppLayout'
import AddBankButton from '#components/pages/banks/AddBankButton'
import BankAccountDeductionSection from '#components/pages/banks/BankAccountDeductionSection'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import BankDeductionContainer from '#contexts/BankContextDeduction'
import { type TeamRoutesParams } from '#pages/dashboard/views/teams/TeamRoutes'
import { IonCol } from '@ionic/react'
import { useParams } from 'react-router'

export default function TeamBankAccountDeduction(): JSX.Element {
  const { category, jarId } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/${jarId}/settings/deductions`
        }
      }}
      teamProps={{
        fetch: true
      }}
    >
      <BankDeductionContainer fetch jarId={jarId}>
        <Title hasSubtitle>bank.title</Title>
        <SubTitle>bank.subtitle</SubTitle>
        <IonCol className='block mt-10'>
          <BankAccountDeductionSection />
        </IonCol>
        <AddBankButton
          href={`/dashboard/${category}/jars/${jarId}/settings/withdrawals/deductions/banks/add`}
        />
      </BankDeductionContainer>
    </AppLayout>
  )
}
