import { IonCol, IonContent } from '@ionic/react'
import Modal from '../../ui/Modal'
import Input, { type InputOnChange, type InputValue } from '../../ui/Input'
import { useState } from 'react'
import Text from '../../ui/Text'
import Button from '../../ui/Button'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'

interface ForgotModalProps {
  /**
   * If the modal is open.
   */
  isOpen: boolean
  /**
   * Set the modal open or close.
   */
  setIsOpen: (isOpen: boolean) => void
}

export default function LoginForgotModal(props: ForgotModalProps): JSX.Element {
  const { isOpen, setIsOpen } = props

  const [email, setEmail] = useState<InputValue>('')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const handleChanges: InputOnChange = (e) => {
    setEmail(e.target.value)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const handleSubmit = (): void => {
    if (!textUtil.isEmail(email as any)) {
      showToast(undefined, 'invalidEmail')
    } else {
      sdk.users
        .reset_password_request(email as any)
        .then(() => {
          showSuccess(undefined, 'forgotModal.success')
          setEmail('')
          closeModal()
        })
        .catch((error) => {
          showToast(undefined, getErrorMessage(error))
        })
    }
  }
  return (
    <Modal
      id='forgot-password'
      isOpen={isOpen}
      setShowModal={closeModal}
      initialBreakpoint={0.75}
      breakpoints={[0, 0.25, 0.5, 0.75, 0.95]}
    >
      <IonContent className='ion-padding' scrollY={false}>
        <div className='md:max-w-md md:mx-auto'>
          <IonCol className='ion-no-padding ion-justify-content-center ion-text-center space-y-3'>
            <Text color='dark' className='font-semibold' size='text-2xl'>
              forgotModal.title
            </Text>
            {/* <IonRow className='ion-no-padding ion-justify-content-center ion-text-center space-x-1 mt-1'>
              <Text size='text-base'>forgotModal.remember</Text>
              <Text size='text-base' color='primary'>
                forgotModal.signIn
              </Text>
            </IonRow> */}
          </IonCol>
          <form className='mt-8' onSubmit={handleSubmit}>
            <Input
              name='email'
              type='email'
              value={email}
              onIonInput={handleChanges}
              labelOptions={{
                children: 'email'
              }}
              helperText='forgotModal.helperText'
            />
            <div className='mt-10'>
              <Button className='w-full' onClick={handleSubmit}>
                confirm
              </Button>
            </div>
          </form>
        </div>
      </IonContent>
    </Modal>
  )
}
