import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router'
import RegistrationCategoryStep from './views/RegistrationCategoryStep'
import { type UserCategory } from '#tackpay-sdk'
import RegistrationSubcategoryStep from './views/RegistrationSubcategoryStep'
import RegistrationPersonStep from './views/RegistrationPersonStep'
import RegistrationAddressStep from './views/RegistrationAddressStep'
import RegistrationUserStep from './views/RegistrationUserStep'
import RegistrationBusinessTypeStep from './views/RegistrationBusinessTypeStep'
import RegistrationTippingRuleStep from './views/RegistrationTippingRuleStep'
import RegistrationSmartStep from './views/RegistrationSmartStep'
import RegistrationToken from './views/RegistrationToken'
import RegistrationContainer from '#contexts/RegistrationContext'

const registrationBasePath = '/registrations'

export interface RegistrationRouteParms {
  category: UserCategory
  token?: string
}

export default function RegistrationRoutes(): JSX.Element {
  return (
    <IonPage>
      <IonRouterOutlet>
        <RegistrationContainer>
          <Route exact path={registrationBasePath}>
            <RegistrationCategoryStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category`}>
            <RegistrationSubcategoryStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/persons`}>
            <RegistrationPersonStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/addresses`}>
            <RegistrationAddressStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/users`}>
            <RegistrationUserStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/types`}>
            <RegistrationBusinessTypeStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/tippingRules`}>
            <RegistrationTippingRuleStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/smarts`}>
            <RegistrationSmartStep />
          </Route>
          <Route exact path={`${registrationBasePath}/:category/invite/:token`}>
            <RegistrationToken />
          </Route>
        </RegistrationContainer>
      </IonRouterOutlet>
    </IonPage>
  )
}
