import CardSkeleton from '#components/common/CardSkeleton'
import Link from '#components/ui/Link'
import Text from '#components/ui/Text'
import { type Jar, type UserCategory } from '#tackpay-sdk'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import HomeTeamCard from './HomeTeamCard'
import ErrorBox from '#components/errors/ErrorBox'

interface Props {
  category: UserCategory
  jars?: Jar[] | null
  isLoading: boolean
  error?: any
}
export default function HomeTeamSection({
  category,
  jars,
  isLoading,
  error
}: Props): JSX.Element {
  const MAX_TEAMS_VISIBLE = 5

  return (
    <>
      <IonRow className='mt-6 ion-justify-content-between ion-align-items-center'>
        <Text
          color='gray-900'
          fontWeight='font-bold'
          size='text-xl'
          data-testid='title-your-jars'
        >
          dashboard.your_jars
        </Text>
        {isLoading && <CardSkeleton className='w-full' />}
        {error != null && <ErrorBox error={error} />}

        <>
          {jars != null && jars?.length > 0 && jars.length > 1 && (
            <div className=''>
              <IonRow className='ion-justify-content-end ion-align-items-center'>
                <Link link={`/dashboard/${category}/jars`}>
                  <Text
                    className='underline w-full'
                    size='text-sm'
                    data-testid='viewAll-jars'
                  >
                    viewAll
                  </Text>
                </Link>
                <IonIcon
                  src={chevronForwardOutline}
                  className='w-3 h-3 mt-1'
                  color='gray-500'
                />
              </IonRow>
            </div>
          )}
        </>
      </IonRow>
      <IonCol className='space-y-3'>
        {jars != null &&
          jars.length > 0 &&
          jars.slice(0, MAX_TEAMS_VISIBLE).map((jar) => {
            return <HomeTeamCard key={jar.id} jar={jar} />
          })}
      </IonCol>
    </>
  )
}
