import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Company } from './companies'
import { type User } from './users'
import { type QueryParamsRetrieve } from 'sdk/query'

type BranchCompanyType = 'branch_companies'

type BranchCompanyRel = ResourceRel & { type: BranchCompanyType }

type CompanyRel = ResourceRel & { type: 'companies' }

interface BranchCompany extends Resource {
  readonly type: BranchCompanyType

  name: string
  company?: Company
  user?: User
}

interface BranchCompanyCreate extends ResourceCreate {
  name: string
  company: CompanyRel
}

interface BranchCompanyUpdate extends ResourceUpdate {
  name: string
}

class BranchCompanies extends ApiResource<BranchCompany> {
  static readonly TYPE: BranchCompanyType = 'branch_companies' as const

  async create(
    resource: BranchCompanyCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BranchCompany> {
    return await this.resources.create<BranchCompanyCreate, BranchCompany>(
      { ...resource, type: BranchCompanies.TYPE },
      params,
      options
    )
  }

  async update(
    resource: BranchCompanyUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BranchCompany> {
    return await this.resources.update<BranchCompanyUpdate, BranchCompany>(
      { ...resource, type: BranchCompanies.TYPE },
      params,
      options
    )
  }

  isBranchCompany(resource: any): resource is BranchCompany {
    return resource.type != null && resource.type === BranchCompanies.TYPE
  }

  relationship(id: string | ResourceId | null): BranchCompanyRel {
    return id === null || typeof id === 'string'
      ? { id, type: BranchCompanies.TYPE }
      : { id: id.id, type: BranchCompanies.TYPE }
  }

  type(): BranchCompanyType {
    return BranchCompanies.TYPE
  }
}

export type { BranchCompany, BranchCompanyCreate, BranchCompanyUpdate }
export default BranchCompanies
