import {
  type ResourceUpdate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig,
  type ResourceCreate
} from 'sdk/resource'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type User } from './users'
import { type Address } from './addresses'

type PersonType = 'persons'

type PersonRel = ResourceRel & { type: PersonType }

type Gender = 'male' | 'female'

type UserRel = ResourceRel & { type: 'users' }

type BranchCompanyRel = ResourceRel & { type: 'branch_companies' }

interface Birthdate {
  day: number
  month: number
  year: number
}

interface Person extends Resource {
  readonly type: PersonType
  first_name: string
  last_name?: string
  gender?: Gender
  birthdate?: Birthdate
  full_name: string
  user?: User
  address?: Address
}

interface PersonCreate extends ResourceCreate {
  first_name: string
  last_name?: string
  gender?: Gender
  birthdate?: Birthdate
  user?: UserRel
  branch_company?: BranchCompanyRel
}

interface PersonUpdate extends ResourceUpdate {
  first_name?: string
  last_name?: string
  birthdate?: Partial<Birthdate>
}

class Persons extends ApiResource<Person> {
  static TYPE: PersonType = 'persons' as const

  async create(
    resource: PersonCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Person> {
    return await this.resources.create<PersonCreate, Person>(
      { ...resource, type: Persons.TYPE },
      params,
      options
    )
  }

  async update(
    resource: PersonUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Person> {
    return await this.resources.update<PersonUpdate, Person>(
      { ...resource, type: Persons.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Persons.TYPE } : id,
      options
    )
  }

  isPerson(resource: any): resource is Person {
    return resource.type != null && resource.type === Persons.TYPE
  }

  relationship(id: string | ResourceId | null): PersonRel {
    return id === null || typeof id === 'string'
      ? { id, type: Persons.TYPE }
      : { id: id.id, type: Persons.TYPE }
  }

  type(): PersonType {
    return Persons.TYPE
  }
}

export type { Person, PersonCreate, PersonUpdate, Gender, Birthdate }
export default Persons
