import { type Language } from '#types/language'
import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type LinksSocial } from './linksSocials'
import { type Reply } from './replies'
import { type Jar } from './jars'

type CheckoutSettingType = 'checkout_settings'
type CheckoutSettingRel = ResourceRel & { type: CheckoutSettingType }

type UserRel = ResourceRel & { type: 'users' }
type CompanyRel = ResourceRel & { type: 'companies' }

interface CheckoutSetting extends Resource {
  readonly type: CheckoutSettingType

  avatar?: string
  description?: string
  currency: string
  lang: Language
  username: string
  fast_amounts: number[]
  show_percentage_amount: boolean
  show_custom_amount: boolean
  show_member: boolean
  subcategory?: string
  logo?: string
  user?: User
  company?: Company
  links_social?: LinksSocial
  show_jars?: string[]
  reply?: Reply
  jar?: Jar
}

interface CheckoutSettingCreate extends ResourceCreate {
  avatar?: string
  description?: string
  currency?: string
  lang?: Language
  username?: string
  fast_amounts?: number[]
  show_percentage_amount?: boolean
  show_custom_amount?: boolean
  show_member?: boolean
  subcategory?: string
  logo?: string
  user?: UserRel
  company?: CompanyRel
}

interface CheckoutSettingUpdate extends ResourceUpdate {
  avatar?: string
  description?: string
  currency?: string
  lang?: Language
  username?: string
  fast_amounts?: number[]
  show_percentage_amount?: boolean
  show_custom_amount?: boolean
  show_member?: boolean
  subcategory?: string
  show_jars?: string[]
  logo?: string
}

class CheckoutSettings extends ApiResource<CheckoutSetting> {
  static readonly TYPE: CheckoutSettingType = 'checkout_settings' as const

  async create(
    resource: CheckoutSettingCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSetting> {
    return await this.resources.create<CheckoutSettingCreate, CheckoutSetting>(
      { ...resource, type: CheckoutSettings.TYPE },
      params,
      options
    )
  }

  async update(
    resource: CheckoutSettingUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSetting> {
    return await this.resources.update<CheckoutSettingUpdate, CheckoutSetting>(
      { ...resource, type: CheckoutSettings.TYPE },
      params,
      options
    )
  }

  isCheckoutSetting(resource: any): resource is CheckoutSetting {
    return resource.type != null && resource.type === CheckoutSettings.TYPE
  }

  relationship(id: string | ResourceId | null): CheckoutSettingRel {
    return id === null || typeof id === 'string'
      ? { id, type: CheckoutSettings.TYPE }
      : { id: id.id, type: CheckoutSettings.TYPE }
  }

  type(): CheckoutSettingType {
    return CheckoutSettings.TYPE
  }
}

export type { CheckoutSetting, CheckoutSettingCreate, CheckoutSettingUpdate }
export default CheckoutSettings
