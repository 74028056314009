/* eslint-disable no-useless-escape */
import { useEffect, useRef, useState } from 'react'
import { eyeOutline, eyeOffOutline } from 'ionicons/icons'
import { IonCol, IonIcon, IonInput, IonRow } from '@ionic/react'
import Button from '#components/ui/Button'
import { type InputOnChange, type InputValue } from '#components/ui/Input'
import { animationsFade } from '#utils/animations'
// import LoginForgotLabel from '#components/pages/login/LoginForgotLabel'
import PasswordStrengthIndicator from '#components/common/PasswordStrengthIndicator'
import Text from './Text'
import LoginForgotLabel from '#components/pages/login/LoginForgotLabel'
import isDesktop from '#utils/isDesktop'

interface Props {
  name?: string

  labelName?: string

  password: InputValue

  onPasswordChange: InputOnChange
  /**
   * The test id.
   */
  ['data-testid']?: string
  testId?: string
  missingPassword?: boolean

  autocomplete?: 'new-password' | 'current-password'

  showValidityPass?: boolean
}
export default function InputPasswordTest({
  name,
  labelName,
  password,
  onPasswordChange,
  missingPassword,
  autocomplete = 'new-password',
  showValidityPass = true,
  ...props
}: Props): JSX.Element {
  const isNumberRegx = /\d/

  const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

  const upperCaseRegx = /.*[A-Z].*/

  const passwordLen = 8

  const [showPassword, setShowPassword] = useState(false)

  const [showIndicator, setShowIndicator] = useState(false)

  const [focusInput, setFocusInput] = useState(false)

  const indicatorPassword = useRef<HTMLDivElement | null>(null)

  const [passwordValidity, setPasswordValidity] = useState({
    minChar: false,
    number: false,
    upperCase: false,
    specialChar: false
  })

  const toggleShowPassword = (): void => {
    setShowPassword((prevState) => !prevState)
  }

  const icon = showPassword ? eyeOffOutline : eyeOutline

  const type = showPassword ? 'text' : 'password'

  useEffect(() => {
    if (typeof password === 'string') {
      setPasswordValidity({
        minChar: password.length >= passwordLen,
        number: !!isNumberRegx.test(password),
        specialChar: !!specialCharacterRegx.test(password),
        upperCase: !!upperCaseRegx.test(password)
      })
    }
  }, [password])

  const handlePasswordChange: InputOnChange = (event) => {
    const { value } = event.target
    if (typeof value === 'string') {
      if (onPasswordChange != null) {
        onPasswordChange(event)
      }
    }
  }

  const onFocus = (): void => {
    setShowIndicator(!showIndicator)
    setFocusInput(!focusInput)
    animationsFade({
      typeAnimation: indicatorPassword,
      start: '-45px',
      end: '0',
      duration: 200
    })
  }

  const onBlur = (): void => {
    setShowIndicator(!showIndicator)
    setFocusInput(!focusInput)
    animationsFade({
      typeAnimation: indicatorPassword,
      start: '0px',
      end: '-45px',
      duration: 300
    })
  }

  const showMissingPassword =
    missingPassword === true ? <LoginForgotLabel /> : null

  const desktopPadding = isDesktop() ? 'pt-3' : 'pt-1'

  const borderInput = focusInput
    ? 'border-2 border-gray-400 '
    : 'border-2 border-gray-200 '

  return (
    <div>
      <div>
        <IonRow className='w-full ion-no-padding ion-no-margin'>
          <IonCol size='6' className='ion-no-padding ion-no-margin'>
            <Text
              className='block mb-1 text-gray-900 '
              fontWeight='font-medium'
              size='text-sm'
            >
              {labelName ?? 'Password'}
            </Text>
          </IonCol>
          <IonCol size='6' className='ion-no-padding ion-no-margin'>
            <IonRow className='ion-no-padding ion-no-margin ion-justify-content-end'>
              {showMissingPassword}
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow
          className={`w-full ion-no-margin ion-no-padding rounded-md ${borderInput}`}
          id='bg-input-password'
        >
          <IonCol size='11' className='ion-no-margin ion-no-padding rounded-md'>
            <IonInput
              className='block   text-gray-900  text-base'
              name={name ?? 'password'}
              type={type}
              value={password}
              onIonInput={handlePasswordChange}
              onIonFocus={onFocus}
              onIonBlur={onBlur}
              autocomplete='on'
              id='input-password'
              {...props}
            />
          </IonCol>
          <IonCol
            size='1'
            className='ion-no-margin ion-no-padding rounded-md bg-white'
          >
            <div id='box-show-password' className='rounded-md -mt-1'>
              <Button
                id='show-password'
                type='button'
                fill='clear'
                color='gray-900'
                size='small'
                slot='icon-only'
                className={`ion-no-margin ${desktopPadding}`}
                onClick={toggleShowPassword}
              >
                <IonIcon icon={icon} className='text-gray-900 ' />
              </Button>
            </div>
          </IonCol>
        </IonRow>
      </div>
      {showValidityPass ? (
        <div ref={indicatorPassword}>
          <PasswordStrengthIndicator
            validity={passwordValidity}
            visible={showIndicator}
          />
        </div>
      ) : null}
    </div>
  )
}
