import { useParams } from 'react-router'
import AppLayout from '#components/layouts/app/AppLayout'
import Title from '#components/ui/Title'
import CheckoutSettingContainer from '#contexts/CheckoutSettingContext'
import SubTitle from '#components/ui/SubTitle'
import { IonAccordionGroup, IonItem } from '@ionic/react'
import Accordion from '#components/ui/Accordion'
import Label from '#components/ui/Label'
import CheckoutSettingBrand from '#components/pages/checkoutSettings/CheckoutSettingBrand'
import CheckoutSettingAmount from '#components/pages/checkoutSettings/CheckoutSettingAmount'
import CheckoutSettingLinksSocial from '#components/pages/checkoutSettings/CheckoutSettingLinksSocial'
import CheckoutSettingReply from '#components/pages/checkoutSettings/CheckoutSettingReply'
import CheckoutSettingButtonPreview from '#components/pages/checkoutSettings/CheckoutSettingButtonPreview'
import CheckoutSettingButtonSave from '#components/pages/checkoutSettings/CheckoutSettingButtonSave'
import { type UserCategory } from '#tackpay-sdk'
import { type DashboardRoutesParams } from '../DashboardRoutes'
import { useEffect, useRef } from 'react'

interface Props {
  category: UserCategory
}
const HandleCheckoutSetting = ({ category }: Props): JSX.Element => {
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null)

  useEffect(() => {
    if (accordionGroup.current == null) {
      return
    }

    accordionGroup.current.value = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth'
    ]
  }, [])

  return (
    <CheckoutSettingContainer
      params={{
        include: ['links_social', 'reply']
      }}
    >
      <IonAccordionGroup multiple ref={accordionGroup}>
        <Accordion value='first' data-testid='accordion-brand'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              Brand
            </Label>
          </IonItem>
          <CheckoutSettingBrand isTipped={category === 'tipped'} />
        </Accordion>
        <Accordion value='second' data-testid='accordion-amounts'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              amounts
            </Label>
          </IonItem>
          <CheckoutSettingAmount />
        </Accordion>
        <Accordion value='fourth' data-testid='accordion-socials'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              Links Social
            </Label>
          </IonItem>
          <CheckoutSettingLinksSocial isTipped />
        </Accordion>
        <Accordion value='fifth' data-testid='accordion-replySettings'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              reply
            </Label>
          </IonItem>
          <CheckoutSettingReply />
        </Accordion>
      </IonAccordionGroup>
      <CheckoutSettingButtonPreview category={category} />
      <CheckoutSettingButtonSave />
    </CheckoutSettingContainer>
  )
}

export default function CheckoutSetting(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
    >
      <Title>checkoutSetting.title</Title>
      <SubTitle>checkoutSetting.subtitle</SubTitle>
      <HandleCheckoutSetting category={category} />
    </AppLayout>
  )
}
