import ErrorBox from '#components/errors/ErrorBox'
import { useTokenContext } from '#contexts/TokenContext'
import useAuthState from '#hooks/useAuthState'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'

export default function ConfirmEmailContent(): JSX.Element {
  const { token, error } = useTokenContext()

  const { startLoading, stopLoading } = useLoading('confirmLoading')

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  const { loginWithToken } = useAuthState()

  useEffect(() => {
    startLoading()
  }, [])

  const handleConfirmEmail = async (): Promise<void> => {
    await sdk.users
      .confirm_email(token?.value ?? '')
      .then((resp) => {
        console.log(resp)

        loginWithToken(resp.access_token)
          .then(() => {
            stopLoading()
            push(`/dashboard/${resp.user.category}/home`)
          })
          .catch((error) => {
            stopLoading()

            showToast(undefined, getErrorMessage(error))
          })
      })
      .catch((error) => {
        stopLoading()
        if ('code' in error) {
          if (error.code === 'token_already_consumed') {
            push('/login')
          }
        }
        showToast(undefined, getErrorMessage(error))
      })
  }

  useEffect(() => {
    if (token != null) void handleConfirmEmail()
  }, [token])

  if (error != null) return <ErrorBox error={error} />

  return <></>
}
