import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type CheckoutSetting } from './checkoutSettings'
import { type QueryParamsRetrieve } from 'sdk/query'

type LinksSocialType = 'links_socials'
type LinkSocialRel = ResourceRel & { type: LinksSocialType }

type CheckoutSettingRel = ResourceRel & { type: 'checkout_settings' }

interface LinksSocial extends Resource {
  readonly type: LinksSocialType

  facebook?: string
  twitter?: string
  instagram?: string
  youtube?: string
  tiktok?: string
  twitch?: string
  discord?: string
  tripadvisor?: string
  website?: string
  google_review?: string
  active: boolean
  checkout_setting?: CheckoutSetting
}

interface LinksSocialCreate extends ResourceCreate {
  facebook?: string
  twitter?: string
  instagram?: string
  youtube?: string
  tiktok?: string
  twitch?: string
  discord?: string
  tripadvisor?: string
  website?: string
  active?: boolean
  checkout_setting: CheckoutSettingRel
}

interface LinksSocialUpdate extends ResourceUpdate {
  facebook?: string
  twitter?: string
  instagram?: string
  youtube?: string
  google_review?: string
  tiktok?: string
  twitch?: string
  discord?: string
  tripadvisor?: string
  website?: string
  active?: boolean
}

class LinksSocials extends ApiResource<LinksSocial> {
  static readonly TYPE: LinksSocialType = 'links_socials' as const

  async create(
    resource: LinksSocialCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<LinksSocial> {
    return await this.resources.create<LinksSocialCreate, LinksSocial>(
      { ...resource, type: LinksSocials.TYPE },
      params,
      options
    )
  }

  async update(
    resource: LinksSocialUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<LinksSocial> {
    return await this.resources.update<LinksSocialUpdate, LinksSocial>(
      { ...resource, type: LinksSocials.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: LinksSocials.TYPE } : id,
      options
    )
  }

  isLinksSocial(resource: any): resource is LinksSocial {
    return resource.type != null && resource.type === LinksSocials.TYPE
  }

  relationship(id: string | ResourceId | null): LinkSocialRel {
    return id === null || typeof id === 'string'
      ? { id, type: LinksSocials.TYPE }
      : { id: id.id, type: LinksSocials.TYPE }
  }

  type(): LinksSocialType {
    return LinksSocials.TYPE
  }
}

export type { LinksSocial, LinksSocialCreate, LinksSocialUpdate }
export default LinksSocials
