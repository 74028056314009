import Card from '#components/ui/Card'
import { IonCardHeader, IonCol, IonRow } from '@ionic/react'
import CardQrCodeImage from './CardQrCodeImage'
import { type Company, type TackpayId, type Jar } from '#tackpay-sdk'
import Text from '#components/ui/Text'
import CardQrCodeTackpayId from './CardQrCodeTackpayId'
import CardQrCodeLink from './CardQrCodeLink'

interface Props {
  jar?: Jar
  company?: Company | null
  isSmart?: boolean
}

const CardQrCodeBusinessContent = ({
  company,
  isSmart,
  jar
}: Props): JSX.Element => {
  const getTackpayId = (): TackpayId | undefined => {
    if (jar?.tackpay_ids != null) {
      if (Array.isArray(jar.tackpay_ids)) {
        return jar.tackpay_ids.find((t) => t.qr_code_storage_url != null)
      } else {
        return jar.tackpay_ids
      }
    }

    if (company?.tackpay_id != null) {
      return company?.tackpay_id
    }
  }

  const isCompany = jar == null

  return (
    <>
      <IonCardHeader className='ion-no-padding'>
        <CardQrCodeImage
          loading={false}
          error={undefined}
          src={getTackpayId()?.qr_code_storage_url ?? ''}
        />
      </IonCardHeader>
      <IonCol>
        <IonRow className='ion-justify-content-between ion-align-items-center mt-3 truncate'>
          <Text
            color='gray-500'
            fontWeight='font-semibold'
            size='text-xs md:text-base'
          >
            {isCompany ? 'company_qr' : 'jar_qr'}
          </Text>
          <Text
            color='gray-900'
            fontWeight='font-semibold'
            size='text-xs md:text-base'
            translationOptions={{
              translate: 'no'
            }}
          >
            {isCompany ? company?.name : jar?.name}
          </Text>
        </IonRow>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <Text
            color='gray-500'
            fontWeight='font-semibold'
            translationOptions={{ translate: 'no' }}
            size='text-xs md:text-base'
          >
            TackPay ID:
          </Text>
          <CardQrCodeTackpayId
            loading={false}
            error={undefined}
            tackpayId={getTackpayId()?.value ?? ''}
            size='text-xs md:text-base'
          />
        </IonRow>
        <CardQrCodeLink
          loading={false}
          error={undefined}
          tackpayId={getTackpayId()}
          textSize='text-xxs md:text-base'
        />
        {isCompany && (
          <IonRow className='ion-justify-content-center pt-4'>
            <Text size='text-xs'>multi_jar</Text>
          </IonRow>
        )}
      </IonCol>
    </>
  )
}

export default function CardQrCodeBusiness({
  jar,
  company,
  isSmart = false
}: Props): JSX.Element {
  return isSmart ? (
    <CardQrCodeBusinessContent company={company} isSmart={isSmart} jar={jar} />
  ) : (
    <Card color='bg-card' className='shadow-none border border-gray-200'>
      <CardQrCodeBusinessContent
        company={company}
        isSmart={isSmart}
        jar={jar}
      />
    </Card>
  )
}
