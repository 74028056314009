import Button from '#components/ui/Button'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type CheckoutSettingUpdate } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'

interface Props {
  /**
   * The related jar id
   */
  jarId?: string
  /**
   * The related company id
   */
  companyId?: string
}

export default function CheckoutSettingButtonSave({
  jarId,
  companyId
}: Props): JSX.Element {
  const { updateCheckoutSetting, checkoutSetting, refetch } =
    useCheckoutSettingContext()

  const { startLoading, stopLoading } = useLoading(
    'checkoutSetting.update.loading'
  )

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const handleSave = (): void => {
    if (updateCheckoutSetting != null) {
      console.log('CheckoutSettingButtonSave', checkoutSetting)

      startLoading()

      updateCheckoutSetting.mutate(
        {
          checkoutSetting: checkoutSetting as CheckoutSettingUpdate,
          reply: checkoutSetting?.reply,
          linksSocial: checkoutSetting?.links_social
        },
        {
          onSuccess: () => {
            stopLoading()
            showSuccess(undefined, 'checkoutSetting.update.success')
            if (refetch != null) {
              void refetch()
            }
          },
          onError: (error) => {
            stopLoading()
            const err = error as any
            stopLoading()
            if (
              'code' in err &&
              'detail' in err &&
              err.code === 'checkout_setting_invalid_amount_min'
            ) {
              showToast(undefined, err.detail)
            } else {
              showToast(undefined, getErrorMessage(error))
            }
          }
        }
      )
    }
  }

  return (
    <Button
      color='primary'
      className='w-full mt-5'
      onClick={handleSave}
      data-testid='save'
    >
      save
    </Button>
  )
}
