import DateTime, {
  type DateTimeOnChage,
  type DateTimeValue
} from '#components/ui/DateTime'
import Modal from '#components/ui/Modal'
import formattedDate from '#utils/formattedDate'
import { IonCol, IonDatetimeButton, IonRow } from '@ionic/react'
import { useState } from 'react'
import FakeInput from './FakeInput'
import { useTranslation } from 'react-i18next'
import isDesktop from '#utils/isDesktop'

interface Props {
  dateFrom: DateTimeValue
  dateTo: DateTimeValue
  setDateFrom: (date: DateTimeValue) => void
  setDateTo: (date: DateTimeValue) => void
  /**
   * @default true
   */
  preferWheel?: boolean
}

interface PropsDateStart {
  showModal?: boolean
  dateFrom?: DateTimeValue
  lang?: string
  preferWheel?: boolean
  handleDateFromToChange?: DateTimeOnChage
}

export function DateStart({
  showModal,
  dateFrom,
  lang,
  preferWheel = true,
  handleDateFromToChange
}: PropsDateStart): JSX.Element {
  return (
    <>
      <IonCol id='open-modal-start' size='6'>
        <IonDatetimeButton
          datetime='date-from'
          // placeholder='date'
          className='hidden'
        />

        <FakeInput
          value={formattedDate(dateFrom as string, lang)}
          label='accountStatement.startDate'
          placeholder={formattedDate(dateFrom as string, lang)}
          testId='date-from'
        />
      </IonCol>
      <Modal keepContentsMounted trigger='open-modal-start' isOpen={showModal}>
        <DateTime
          id='date-from'
          presentation='date'
          size='cover'
          preferWheel={isDesktop() ? false : preferWheel}
          color='primary'
          onIonChange={handleDateFromToChange}
          value={dateFrom}
          data-testid='date-time-start'
        />
      </Modal>
    </>
  )
}

interface PropsDateEnd {
  showModal?: boolean
  dateTo?: DateTimeValue
  lang?: string
  preferWheel?: boolean
  handleDateEndToChange?: DateTimeOnChage
}
export function DateEnd({
  showModal,
  dateTo,
  lang,
  preferWheel = true,
  handleDateEndToChange
}: PropsDateEnd): JSX.Element {
  return (
    <>
      <IonCol id='open-modal-end' size='6'>
        <IonDatetimeButton
          datetime='date-to'
          // placeholder='date'
          className='hidden'
        />
        <FakeInput
          value={formattedDate(dateTo as string, lang)}
          label='accountStatement.endDate'
          placeholder={formattedDate(dateTo as string, lang)}
          testId='date-to'
        />
      </IonCol>
      <Modal keepContentsMounted trigger='open-modal-end' isOpen={showModal}>
        <DateTime
          id='date-to'
          presentation='date'
          size='cover'
          preferWheel={isDesktop() ? false : preferWheel}
          color='primary'
          onIonChange={handleDateEndToChange}
          value={dateTo}
          data-testid='date-time-to'
        />
      </Modal>
    </>
  )
}

export default function DateRanger({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  preferWheel = true
}: Props): JSX.Element {
  const { i18n } = useTranslation()
  const [showModalStart, setShowModalStart] = useState(false)
  const [showModalEnd, setShowModalEnd] = useState(false)

  const handleDateFromToChange: DateTimeOnChage = (e) => {
    setDateFrom(e.detail.value)
    setShowModalStart(false)
  }

  const handleDateEndToChange: DateTimeOnChage = (e) => {
    setDateTo(e.detail.value)
    setShowModalEnd(false)
  }

  return (
    <IonRow className='mt-5'>
      <IonRow className='w-full ion-justify-content-between ion-align-items-center'>
        <DateStart
          showModal={showModalStart}
          dateFrom={dateFrom}
          handleDateFromToChange={handleDateFromToChange}
          preferWheel={preferWheel}
          lang={i18n.language}
        />
        <DateEnd
          showModal={showModalEnd}
          dateTo={dateTo}
          handleDateEndToChange={handleDateEndToChange}
          preferWheel={preferWheel}
          lang={i18n.language}
        />
      </IonRow>
    </IonRow>
  )
}
