import Logout from '#components/common/Logout'
import CoverIcon from '#components/ui/CoverIcon'
import TackPayIcon from '#components/ui/TackpayIcon'
import Text from '#components/ui/Text'
import JarIcon from '#components/ui/svg/JarIcon'
import { getSideMenu } from '#constants/sideMenu'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { isInactiveCapabilities } from '#utils/isInactiveCapabilities'
import sdk from '#utils/sdk'
import { IonItem, IonList, IonRow, useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

export default function AppMenuDesktop(): JSX.Element {
  const { category: categoryParam } = useParams<DashboardRoutesParams>()

  const { collaborator } = useCollaboratorContext()

  const { company } = useCompanyContext()

  const { user } = useUserContext()

  const { routeInfo } = useIonRouter()

  const [isMembers, setIsMembers] = useState<boolean>(false)

  const [jars, setJar] = useState<number>(0)

  useEffect(() => {
    if (company != null) {
      if (company.jars != null) {
        setJar(company.jars.length)
      } else {
        sdk.companies
          .jars(company.id)
          .then((res) => {
            setJar(res.length)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [company])

  useEffect(() => {
    if (user != null && user.category === 'tipped') {
      sdk.users
        .retrieve(user.id, { include: ['members'] })
        .then((res) => {
          if (res?.members != null && res.members.length > 0) {
            setIsMembers(true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [user])

  const hiddenQrCodeItem = (title: string): boolean => {
    if (title === 'sidemenu.material') {
      return isMembers
    }
    return false
  }

  const hiddenCompanyItem = (title: string): boolean => {
    if (title === 'sidemenu.structure') {
      return jars === 1
    }
    return false
  }

  const hiddenDeduction = (title: string): boolean => {
    if(title === 'deduction.side_menu'){
    return !['9c86881d-23f5-4cf4-9716-c7257ed4a914', 'e3bbf101-5ac8-4f94-b784-18d7d6cee3e0','2865aa15-414d-46da-9b83-7e52a60ce83c'].includes(company?.id ?? '')
    }
    return false
  }

  const handleClick = (page: string): string => {
    if (page === routeInfo.pathname) {
      const menu = document.querySelector('ion-menu')
      void menu?.setOpen(false)
      return page
    } else {
      if (page.includes('companies')) {
        page = page + '/' + (company?.id ?? '')
        const menu = document.querySelector('ion-menu')
        void menu?.setOpen(false)
        return page
      } else {
        return page
      }
    }
  }

  return (
    <aside
      className='fixed top-0 left-0 z-50 w-2/12 h-screen overflow-y-scroll border-r border-gray-300'
      id='header-menu-desktop'
    >
      <IonRow className='mx-auto w-full block p-5'>
        <TackPayIcon />
      </IonRow>
      <hr />
      <div id='toolbar-menu'>
        <div className='pl-6 mt-4'>
          <Text fontWeight='font-bold' size='text-lg'>
            MENU
          </Text>
        </div>
        <IonList
          className='mt-5 space-y-4 '
          lines='none'
          id='list-menu-desktop'
        >
          {getSideMenu(categoryParam, collaborator?.role, true).map(
            (page) => {
              if(page.title === 'Others'){
                return (
                  <Text
                    fontWeight='font-bold'
                    size='text-md'
                    className='pl-6 mt-8'
                    key={page.title}
                  >
                    {page.title}
                  </Text>
                )
              }

              return (
                <IonItem
                  id='item-menu-desktop'
                  button
                  data-testid={page.title}
                  key={page.title}
                  detail={false}
                  routerLink={handleClick(page.url)}
                  routerDirection='root'
                  className={
                    hiddenQrCodeItem(page.title) ||
                      hiddenCompanyItem(page.title) ||
                      hiddenDeduction(page.title)
                      ? 'hidden'
                      : 'pl-0'
                  }
                >
                  <IonRow className='ion-no-padding ion-justify-content-start ion-align-items-center space-x-6'>
                    {page.title === 'teamPage.title' ?
                      <CoverIcon iconOptions={{
                        icon: page.icon,
                        className: 'text-gray-600'
                      }} >
                        <JarIcon color='#005563' />
                      </CoverIcon>
                      :
                      <CoverIcon
                        iconOptions={{
                          icon: page.icon,
                          className: 'text-gray-600'
                        }}
                      />
                    }
                    <Text
                      size='text-base'
                      color='gray-900'
                      className='capitalize '
                    >
                      {page.title}
                    </Text>
                    {isInactiveCapabilities(
                      user?.stripe_gateway_account,
                      user?.category === 'tipped'
                    ) &&
                      page.title === 'sidemenu.setting' && (
                        <div className='bg-red-500 p-1 rounded-full w-3 h-3 mt-1' />
                      )}
                  </IonRow>
                </IonItem>
              )
            }
          )}
          <Logout />
        </IonList>
      </div>
    </aside>
  )
}
