import CardLanguage from './CardLanguage'
import { LANGUAGES } from '#constants/languages'
import { useUserContext } from '#contexts/UserContext'
import { type LanguageCode } from '#types/language'
import { IonCol } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'

export default function LanguageSetting(): JSX.Element {
  const { user, editUser } = useUserContext()

  const { i18n } = useTranslation()

  const [currentLanguage, setCurrentLanguage] = useState<string>(
    user?.lang ?? i18n.language
  )

  const { showToast } = useToast('success')

  const { showToast: showToastWarn } = useToast('warning')

  const handleChangeLanguage = (langCode: LanguageCode): void => {
    setCurrentLanguage(langCode)
    void i18n.changeLanguage(langCode)
    if (editUser != null) {
      editUser.mutate(
        {
          id: user?.id ?? '',
          lang: langCode
        },
        {
          onSuccess: () => {
            showToast(undefined, 'language.success')
          },
          onError: (error) => {
            showToastWarn(undefined, getErrorMessage(error))
            console.error(error)
          }
        }
      )
    }
  }
  return (
    <IonCol className='space-y-5'>
      {LANGUAGES.filter((l) => l.code !== 'fr').map((language) => (
        <CardLanguage
          key={language.code}
          lang={language.code}
          icon={language.img}
          isCurrent={currentLanguage === language.code}
          onClick={() => {
            handleChangeLanguage(language.code)
          }}
        />
      ))}
    </IonCol>
  )
}
