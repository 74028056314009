import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { useMemberContext } from '#contexts/MemberContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type UserCategory } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { isBusiness } from '#utils/isBusiness'
import { IonCol, IonContent, IonRow, useIonRouter } from '@ionic/react'

interface Props {
  show: boolean
  setShow: (show: boolean) => void
  memberId: string
  jarId?: string
  category: UserCategory
}
export default function MemberDeleteModal({
  show,
  setShow,
  memberId,
  jarId,
  category
}: Props): JSX.Element {
  const { deleteMember } = useMemberContext()

  const { startLoading, stopLoading } = useLoading('member.deleting')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { push } = useIonRouter()

  const handleDelete = (): void => {
    if (deleteMember != null) {
      setShow(false)

      startLoading()

      deleteMember.mutate(memberId, {
        onSuccess: () => {
          stopLoading()
          showSuccess(undefined, 'member.deleted')
          if (jarId != null) {
            push(`/dashboard/${category}/jars/${jarId}/members`)
          } else if (!isBusiness(category)) {
            push(`/dashboard/${category}/jars`)
          }
        },
        onError: (error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        }
      })
    }
  }

  const titleModal =
    category === 'tipped' ? 'membersDeleteTipped' : 'members.delete'

  const textOtherInfo =
    category === 'tipped' ? 'membersOtherInfo' : 'members.deleteOtherInfo'

  return (
    <Modal
      isOpen={show}
      setShowModal={setShow}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.6]}
      showBackdrop
    >
      <IonContent className='ion-padding' scrollY={false}>
        <div className='md:max-w-md md:mx-auto'>
          <IonCol className='ion-no-padding ion-justify-content-center ion-text-center'>
            <Text color='dark' className='font-semibold'>
              {titleModal}
            </Text>
            <IonRow className='ion-no-padding ion-justify-content-center ion-text-center space-x-1 mt-1'>
              <Text size='text-xs'>members.deleteInfo</Text>
            </IonRow>
          </IonCol>
        </div>
        <div className='mt-5'>
          <Text className='ion-text-center' size='text-base'>
            {textOtherInfo}
          </Text>
        </div>

        <IonRow className='ion-justify-content-between ion-text-center mt-10'>
          <Button
            fill='clear'
            color='medium'
            onClick={() => {
              setShow(false)
            }}
          >
            cancel
          </Button>
          <div>
            <Button
              color='danger'
              onClick={() => {
                handleDelete()
              }}
            >
              confirm
            </Button>
          </div>
        </IonRow>
      </IonContent>
    </Modal>
  )
}
