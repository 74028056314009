import ErrorBox from '#components/errors/ErrorBox'
import Chip from '#components/ui/Chip'
import Skeleton from '#components/ui/Skeleton'
import { type Identity } from '#tackpay-sdk'

interface Props {
  isLoading: boolean
  error?: any
  identity?: Identity | null
}
export default function CardStatus({
  isLoading,
  error,
  identity
}: Props): JSX.Element {
  if (isLoading) return <Skeleton className='w-10 rounded-lg' />

  if (error != null) return <ErrorBox error={error} />

  const getIdentityText = (): string => {
    if (identity?.status === 'verified') return 'identity.verified'
    if (identity?.status === 'pending') return 'identity.pending'
    if (identity?.status === 'unverified') return 'identity.unverified'
    return 'identity.toVerify'
  }

  const getIdentityColor = (): string => {
    if (identity?.status === 'verified') return 'success'
    if (identity?.status === 'pending') return 'warning'
    if (identity?.status === 'unverified') return 'danger'
    return 'gray-500'
  }

  return <Chip color={getIdentityColor()}>{getIdentityText()}</Chip>
}
