import Avatar from '#components/ui/Avatar'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_COMPANY_BIO } from '#constants/bio'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type Jar, type CheckoutSession } from '#tackpay-sdk'
import getQueryParams from '#utils/getQueryParams'
import isDesktop from '#utils/isDesktop'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
  checkout: CheckoutSession
}

export default function CheckoutCompany({ checkout }: Props): JSX.Element {
  const { routeInfo } = useIonRouter()

  const queryParams = getQueryParams(routeInfo.search)

  useEffect(() => {
    if (checkout?.company?.jars?.length === 1) {
      const jar = checkout?.company?.jars[0]
      if (jar.tackpay_ids != null) {
        if (Array.isArray(jar.tackpay_ids)) {
          window.location.href = `/checkout/${jar.tackpay_ids[0].value.toUpperCase()}`
          // push(`/checkout/${jar.tackpay_ids[0].value.toUpperCase()}`)
        } else {
          window.location.href = `/checkout/${jar.tackpay_ids.value.toUpperCase()}`
          // push(`/checkout/${jar.tackpay_ids.value.toUpperCase()}`)
        }
      }
    }
  }, [])

  const getTeams = (): Jar[] => {
    const filteredTeams = checkout?.company?.checkout_setting?.show_jars
    const jars = checkout?.company?.jars
    const currentTeams = []

    if (filteredTeams != null && jars != null) {
      currentTeams.push(...jars.filter((jar) => filteredTeams.includes(jar.id)))
    }
    const activeTeams = currentTeams.filter((jar) => jar.active)
    return activeTeams
  }

  const getTeamLength = (): number => {
    return getTeams().length
  }

  const handleTeamSelected = (jar: Jar): void => {
    const tackpayIds = jar.tackpay_ids

    const referenceOrigin = queryParams.get('referenceOrigin')

    let href
    if (tackpayIds != null) {
      if (!Array.isArray(tackpayIds)) {
        href = `/checkout/${(tackpayIds as any).value.toUpperCase()}`
      } else {
        const firstTackpayId = tackpayIds[0]
        href = `/checkout/${firstTackpayId.value.toUpperCase()}`
      }
    }

    if (referenceOrigin != null) {
      href = `${href}?referenceOrigin=${referenceOrigin}`
    }

    window.location.href = href ?? '/'
  }

  return (
    <>
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src:
                checkout?.company?.checkout_setting?.avatar ??
                DEFAULT_IMAGES.logoCompany
            }}
            avatarOptions={{
              className: 'border-4 mx-auto h-24 w-24 border-green-400'
            }}
          />
          <div className='mt-8'>
            <Underlined
              name={
                checkout?.company?.checkout_setting?.username ??
                checkout?.company?.name
              }
            />
          </div>
          <IonRow className='pt-8 ion-justify-content-center'>
            <Text className='text-gray-600 text-center'>
              {checkout?.company?.checkout_setting?.description ??
                DEFAULT_COMPANY_BIO}
            </Text>
          </IonRow>
        </IonCol>
        <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
      </IonRow>
      <Text size='text-base' className='ion-text-center'>
        checkout.selectTeam
      </Text>
      {getTeamLength() >= 4 ? (
        <IonRow className='w-full ion-align-items-center mt-10'>
          <Swiper
            spaceBetween={isDesktop() ? -80 : 20}
            slidesPerView={3}
            className='w-full'
          >
            {getTeams().map((jar) => (
              <SwiperSlide key={jar.id}>
                <IonCol
                  onClick={() => {
                    handleTeamSelected(jar)
                  }}
                  className='ion-text-center'
                >
                  <Avatar
                    avatarOptions={{
                      className: `border-4 mx-auto h-24 w-24`
                    }}
                    imgOptions={{
                      src:
                        jar.checkout_setting?.avatar ?? DEFAULT_IMAGES.logoTeam
                    }}
                  />
                  <IonRow className='w-full ion-justify-content-center ion-no-padding ion-no-margin '>
                    <Text
                      className='text-black'
                      fontWeight='font-bold'
                      size='text-lg'
                      translationOptions={{
                        translate: 'no'
                      }}
                    >
                      {jar.checkout_setting?.username ?? jar.name}
                    </Text>
                  </IonRow>
                </IonCol>
              </SwiperSlide>
            ))}
          </Swiper>
        </IonRow>
      ) : (
        <IonRow className='w-full ion-justify-content-center ion-align-items-center mt-10'>
          {getTeams().map((jar) => (
            <IonCol
              key={jar.id}
              size='4'
              className='ion-text-center'
              onClick={() => {
                handleTeamSelected(jar)
              }}
            >
              <Avatar
                avatarOptions={{
                  className: `border-4 mx-auto h-24 w-24`
                }}
                imgOptions={{
                  src: jar.checkout_setting?.avatar ?? DEFAULT_IMAGES.logoTeam
                }}
              />
              <IonRow className='w-full ion-justify-content-center ion-no-padding ion-no-margin '>
                <Text
                  className='text-black'
                  fontWeight='font-bold'
                  size='text-lg'
                  translationOptions={{
                    translate: 'no'
                  }}
                >
                  {jar.checkout_setting?.username ?? jar.name}
                </Text>
              </IonRow>
            </IonCol>
          ))}
        </IonRow>
      )}
    </>
  )
}
