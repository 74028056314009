import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import AddTeamNewIbanForm from '#components/pages/teams/add/AddTeamNewIbanForm'

export default function AddTeamNewIban(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add/ibans`
        }
      }}
    >
      <ProgressBar value={0.7} text='teamPage.add.chip.newIban' />
      <AddTeamNewIbanForm category={category} />
    </AppLayout>
  )
}
