import Card from '#components/ui/Card'
import { MapContainer, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet'
import { useEffect, useRef, useState } from 'react'
import { type GeoJsonObject, type GeoJSON as typeGeojson } from 'geojson'
import 'leaflet/dist/leaflet'
import { type Marker as typeMarker, type LatLng } from 'leaflet'
import { countriesInfo, handleDownload } from './functionMap'
import { IonRow } from '@ionic/react'
import Text from '#components/ui/Text'

interface Props {
  countryTips: string[]
}

export default function ChartMap({ countryTips }: Props): JSX.Element {
  const [dataMap, setDataMap] = useState<typeGeojson>()

  const [latLong, setLatLong] = useState<LatLng[]>([])

  const dataLatLong: number[] = []

  const popUp = useRef<typeMarker<any> | null>(null)

  const selectCountry: typeGeojson = {
    type: 'FeatureCollection',
    features: []
  }

  useEffect(() => {
    handleDownload({ selectCountry, countryTips, setDataMap })
    countriesInfo({ countryTips, dataLatLong, setLatLong })
    testPopUpOpen()
  }, [countryTips])

  window.dispatchEvent(new Event('resize'))

  const testPopUpOpen = (): void => {
    if (popUp.current != null) {
      popUp.current.openPopup()
    }
  }

  if (dataMap !== null) {
    return (
      <Card className='w-full h-full border border-gray-200'>
        <IonRow className='pb-5'>
          <Text color='gray-900' fontWeight='font-semibold'>
            tip_from
          </Text>
        </IonRow>
        <MapContainer
          center={[42.83333333, 12.83333333]}
          zoom={4}
          minZoom={3}
          className='bg-white'
          fadeAnimation
        >
          <TileLayer
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            url='https://api.maptiler.com/maps/winter-v2/256/{z}/{x}/{y}.png?key=013ZM1bE9PbMElweAwg3'
          />

          <GeoJSON
            key={JSON.stringify(dataMap)}
            data={dataMap as GeoJsonObject}
            style={{
              stroke: true,
              fillColor: '#71e89d',
              bubblingMouseEvents: true,
              interactive: true,
              fillOpacity: 0.9,
              color: 'white',
              weight: 1
            }}
            eventHandlers={{
              click: (e) => {
                console.log(e.latlng)
              }
            }}
          />

          {latLong.map((data: LatLng, i: number) => (
            <Marker ref={popUp} key={i} position={data}>
              <Popup>city capitals</Popup>
            </Marker>
          ))}
        </MapContainer>
      </Card>
    )
  } else {
    return <></>
  }
}
