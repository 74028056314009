import { IonModal } from '@ionic/react'
import { type ComponentProps } from 'react'

interface ModalProps extends ComponentProps<typeof IonModal> {
  /**
   * Set show modal
   */
  setShowModal?: (state: boolean) => void
}
export default function Modal(props: ModalProps): JSX.Element {
  const { setShowModal, ...rest } = props

  return (
    <IonModal
      {...rest}
      onDidDismiss={() => {
        if (setShowModal != null) setShowModal(false)
      }}
    >
      {props.children}
    </IonModal>
  )
}
