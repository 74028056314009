import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type CheckoutRoutesParams } from '../CheckoutRoutes'
import CheckoutReviewSection from '#components/pages/checkouts/reviews/CheckoutReviewSection'
import TabCheckout from '#components/pages/checkouts/TabCheckout'

export default function CheckoutReviews(): JSX.Element {
  const { checkoutId } = useParams<CheckoutRoutesParams>()
  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
      checkoutOptions={{
        fetch: true,
        id: checkoutId,
        params: {
          include: ['payment', 'member', 'jar', 'user', 'tackpay_id']
        }
      }}
    >
      <TabCheckout section='review' />
      <CheckoutReviewSection />
    </AuthLayout>
  )
}
