import Text from '#components/ui/Text'
import useToast from '#hooks/useToast'
import { type CheckoutRoutesParams } from '#pages/checkouts/CheckoutRoutes'
import { IonRow, useIonRouter } from '@ionic/react'
import { useParams } from 'react-router'
import { getPreferences } from 'storage/preferences'

interface Props {
  section: 'select' | 'tip' | 'review'
}

export default function TabCheckout({ section }: Props): JSX.Element {
  const { showToast } = useToast('warning')

  const { tackpayId } = useParams<CheckoutRoutesParams>()

  const { push } = useIonRouter()

  const tabCheckout: string[] = ['select', 'tip', 'review']

  const baseClass = 'p-1 text-sm'

  const basActiveClass = 'shadow bg-white p-2 rounded-lg ion-align-self-center'

  const handleSelect = (tab: string): void => {
    getPreferences<number>('checkout')
      .then((value) => {
        if (tab === 'tip') {
          if (value == null) {
            showToast(undefined, 'first_select_amount')
          }
        } else if (tab === 'select') {
          if (value == null) return
          if (value === 1) {
            push(`/checkout/${tackpayId}`)
          }
        } else if (tab === 'review') {
          if (value == null || value === 1) {
            showToast(undefined, 'first_select_amount')
          }
        }
      })
      .catch((error) => {
        console.log('Error to get preference', error)
      })
  }

  return (
    <IonRow className='w-full ion-justify-content-between ion-align-items-center ion-no-padding ion-no-margin mb-6'>
      {tabCheckout.map((data) => (
        <Text
          key={data}
          color={data === section ? 'gray-900' : 'gray-500'}
          fontWeight={data === section ? 'font-medium' : 'font-normal'}
          className={`${baseClass} ${
            data === section ? basActiveClass : ''
          } cursor-pointer`}
          size='text-sm'
          onClick={() => {
            handleSelect(data)
          }}
        >
          {`tabCheckout.${data}`}
        </Text>
      ))}
    </IonRow>
  )
}
