import Modal from '#components/ui/Modal'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { type MemberAmount } from './MemberList'
import { getSymbolCurrency } from '#constants/country'
import Button from '#components/ui/Button'
import { send } from 'ionicons/icons'
import sdk from '#utils/sdk'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import { type SetStateAction, useState } from 'react'
import Skeleton from '#components/ui/Skeleton'
import { useTeamContext } from '#contexts/JarContext'
import formattedAmount from '#utils/formattedAmount'

interface Props {
  show: boolean
  setShow: (show: boolean) => void
  setMembers: (value: SetStateAction<MemberAmount[]>) => void
  members: MemberAmount[]
  jarId: string
  balance: number
}
export default function MemberModalReview({
  show,
  setShow,
  setMembers,
  members,
  balance,
  jarId
}: Props): JSX.Element {
  const { refetch } = useTeamContext()

  const { showToast } = useToast('error')

  const { showToast: successShow } = useToast('success')

  const [currentMember, setCurrentMember] = useState<MemberAmount | undefined>()

  const [, setMemberExecuted] = useState<string[]>([])

  const [executing, setExecuting] = useState(false)

  const handleSingleTransfer = async (member: MemberAmount): Promise<void> => {
    setCurrentMember(member)
    await sdk.transfers.create({
      amount: member.amount,
      currency: member.user?.currency ?? 'eur',
      member: {
        id: member.id,
        type: 'members'
      },
      jar: {
        id: jarId,
        type: 'jars'
      }
    })
  }

  const handleSendSingle = (member: MemberAmount): void => {
    if (balance <= 0 || balance < member.amount) {
      showToast(undefined, 'Balance is not enough')
    } else {
      handleSingleTransfer(member)
        .then(() => {
          // if (setBalanceTronc != null) {
          //   const newBalance = balance - member.amount * 100
          //   setBalanceTronc(newBalance)
          // }

          setMemberExecuted((prevMemberExecuted) => [
            ...prevMemberExecuted,
            member.id
          ])
          setMembers((prevMembers) => {
            return prevMembers.map((prevMember) =>
              prevMember.id === member.id
                ? {
                    ...prevMember,
                    amount: 0
                  }
                : prevMember
            )
          })

          setTimeout(() => {
            if (refetch != null) {
              refetch()
                .then(() => {
                  setCurrentMember(undefined)
                  setShow(false)
                })
                .catch((error) => {
                  console.log('Refetch jar error', error)
                })
            } else {
              setCurrentMember(undefined)
              setShow(false)
            }
            successShow(undefined, 'tipTroncSend')
            setCurrentMember(undefined)
            setShow(false)
          }, 1000 * 3)
        })
        .catch((error) => {
          setCurrentMember(undefined)
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleSendAll = (): void => {
    const allZeroAmoint = members.every((member) => member.amount === 0)
    if (allZeroAmoint) {
      setShow(false)
    } else {
      setExecuting(true)
      const handleExcecuteAll = async (): Promise<void> => {
        const membersToExecute = members.filter((m) => m.amount > 0)
        for (const member of membersToExecute) {
          setCurrentMember(member)
          await handleSingleTransfer(member)
          setMemberExecuted((prevMemberExecuted) => [
            ...prevMemberExecuted,
            member.id
          ])
          setMembers((prevMembers) => {
            return prevMembers.map((prevMember) =>
              prevMember.id === member.id
                ? {
                    ...prevMember,
                    amount: 0
                  }
                : prevMember
            )
          })

          if (refetch != null) {
            refetch()
              .then(() => {
                console.log('Refetch jar')
              })
              .catch((error) => {
                console.log('Refetch jar error', error)
              })
          }
        }
      }
      Promise.all([handleExcecuteAll()])
        .then(() => {
          successShow(undefined, 'tipTroncSendAll')
          setShow(false)
          setTimeout(() => {
            if (refetch != null) {
              refetch()
                .then(() => {
                  console.log('Refetch jar')
                  setCurrentMember(undefined)
                  // successShow(undefined, 'tipTroncSendAll')
                  // setShow(false)
                })
                .catch((error) => {
                  console.log('Refetch jar error', error)
                })
            } else {
              setCurrentMember(undefined)
              setShow(false)
            }
          }, 1000 * 2)
        })
        .catch((error) => {
          console.log('Error', error)
          setShow(false)
        })
        .finally(() => {
          setExecuting(false)
        })
    }
  }

  return (
    <Modal isOpen={show} setShowModal={setShow}>
      <IonHeader>
        <IonToolbar color='bg-card'>
          <IonButtons slot='start'>
            <IonButton
              color='medium'
              onClick={() => {
                setShow(false)
              }}
            >
              cancel
            </IonButton>
          </IonButtons>
          <IonTitle />
          <IonButtons slot='end'>
            <IonButton
              onClick={() => {
                handleSendAll()
              }}
              strong
              color='primary'
              disabled={executing}
            >
              confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding ion-margin' scrollY color='bg-card'>
        <div>
          {members.map((member) => (
            <IonItem
              key={`${member.id}_${member.amount}`}
              className='ion-align-items-center'
              color='bg-card'
            >
              <IonLabel>
                {member?.user?.person?.first_name ?? ''}{' '}
                {member?.user?.person?.last_name ?? ''}
              </IonLabel>
              {currentMember?.id === member.id ? (
                <Skeleton />
              ) : (
                <>
                  <IonLabel>
                    {getSymbolCurrency(member.user?.currency ?? 'eur')}{' '}
                    {formattedAmount(member.amount)}
                  </IonLabel>

                  <Button
                    slot='end'
                    fill='clear'
                    color='primary'
                    iconOptions={{
                      icon: send
                    }}
                    onClick={() => {
                      handleSendSingle(member)
                    }}
                  />
                </>
              )}
            </IonItem>
          ))}
        </div>
      </IonContent>
    </Modal>
  )
}
