import { type ApexOptions } from 'apexcharts'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

interface Props {
  categories: string[]
  series: {
    name: string
    data: number[]
  }
}
export default function ChartBar({ categories, series }: Props): JSX.Element {
  const MAX_VISIBLE = 6

  const options: ApexOptions = {
    colors: ['#2dd36f', '#80CAEE'],
    chart: {
      id: 'chartBarTest',
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 335,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },

    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: '25%'
            }
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '25%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last'
      }
    },
    dataLabels: {
      enabled: false
    },

    xaxis: {
      categories: categories.splice(0, MAX_VISIBLE),
      axisBorder: {
        show: false
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0)
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Satoshi',
      fontWeight: 500,
      markers: {
        radius: 99
      }
    },
    fill: {
      opacity: 1
    }
  }
  const [state] = useState({
    series: [
      {
        name: series.name,
        data: series.data.splice(0, MAX_VISIBLE)
      }
    ]
  })

  return (
    <div id='chartTwo' className='-ml-5 -mb-9'>
      <ReactApexChart
        options={options}
        series={state.series}
        type='bar'
        height={350}
      />
    </div>
  )
}
