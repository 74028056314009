import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type RegistrationRouteParms } from '../RegistrationRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import Input, { type InputOnChange } from '#components/ui/Input'
import CountrySelect from '#components/common/CountrySelect'
import ButtonStep from '#components/common/ButtonStep'
import { useIonRouter } from '@ionic/react'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import RegistrationModalTerms from '#components/pages/registrations/RegistrationModalTerms'
import { useEffect, useState } from 'react'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import { getCurrencyByCountryName } from '#constants/country'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Text from '#components/ui/Text'
import InputPasswordTest from '#components/ui/InputPasswordTest'
import { validatePassword } from '#utils/validatePassword'

export default function RegistrationSmartStep(): JSX.Element {
  const { category } = useParams<RegistrationRouteParms>()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const [showModalTerms, setShowModalTerms] = useState<boolean>(false)
  const [showCommercialCode, setShowCommercialCode] = useState<boolean>(false)

  const {
    addressData,
    personData,
    userData,
    companyData,
    setAddressData,
    setCompanyData,
    setPersonData,
    setUserData,
    refresh
  } = useRegistrationContext()

  useEffect(() => {
    void refresh()
  }, [])

  const handlePrevious = (): void => {
    push(`/registrations/${category}/tippingRules`, 'back')
  }

  const handleInputChanges: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'companyName') setCompanyData({ name: value })

      if (name === 'email') setUserData({ email: value })

      if (name === 'firstName') setPersonData({ first_name: value })

      if (name === 'password') setUserData({ password: value })

      if (name === 'commercialCode') setCompanyData({ commercial_code: value })

      void refresh()
    }
  }

  const handleCountrySelect = (country: string): void => {
    setAddressData({ country_name: country })
    const currency = getCurrencyByCountryName(country)
    setUserData({ currency: currency.toUpperCase() })
    void refresh()
  }

  const validateStep = (): boolean => {
    void refresh()
    const isFail = false

    if (!textUtil.isEmail(userData?.email)) {
      showToast(undefined, 'registration.emailRequired')
      return isFail
    }

    if (textUtil.isEmpty(companyData?.name)) {
      showToast(undefined, 'registration.companyNameRequired')
      return isFail
    }

    if (textUtil.isEmpty(personData?.first_name)) {
      showToast(undefined, 'registration.firstNameRequired')
      return isFail
    }

    if (textUtil.isEmpty(addressData?.country_name)) {
      showToast(undefined, 'registration.countryRequired')
      return isFail
    }

    if (textUtil.isEmpty(userData?.password)) {
      showToast(undefined, 'registration.passwordRequired')
      return isFail
    }

    if (userData?.password != null && !validatePassword(userData.password)) {
      showToast(undefined, 'registration.passwordInvalid')
      return isFail
    }

    return !isFail
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const isValidStep = validateStep()

    if (isValidStep) {
      void refresh()
      setShowModalTerms(true)
    }
  }

  const handlerPhoneChange = (number: string, country: any): void => {
    const correctPhone = `+${number}`
    setUserData({ phone: correctPhone })
  }

  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        showLanguageSwitcher: true,
        backButtonProps: {
          defaultHref: `/registrations/${category}/tippingRules`
        }
      }}
    >
      <RegistrationModalTerms
        show={showModalTerms}
        setShow={setShowModalTerms}
      />

      <ProgressBar
        text='registration.chips.manager.title'
        value={0.9}
        testId='progress-registration.chips.address.title'
      />
      <form onSubmit={handleSubmit} className='space-y-4'>
        <CountrySelect
          country={addressData?.country_name ?? ''}
          setSelectionCountry={handleCountrySelect}
        />
        <div>
          <Input
            name='firstName'
            type='text'
            value={personData?.first_name}
            onIonInput={handleInputChanges}
            labelOptions={{ children: 'firstName' }}
          />
        </div>
        <div>
          <Input
            name='companyName'
            type='text'
            value={companyData?.name}
            onIonInput={handleInputChanges}
            labelOptions={{
              children: 'companyName',
              capitalize: false
            }}
          />
        </div>
        <div>
          <Input
            name='email'
            type='email'
            value={userData?.email}
            onIonInput={handleInputChanges}
            labelOptions={{ children: 'email' }}
          />
        </div>
        <div className='w-full'>
          <Text
            size='text-sm'
            fontWeight='font-medium'
            color='text-gray-900'
            className=' mb-1'
          >
            Phone
          </Text>
          <PhoneInput
            enableSearch
            autoFormat
            prefix='+'
            containerStyle={{ width: '100%' }}
            country='it'
            value={userData?.phone}
            onChange={(phone, data) => {
              handlerPhoneChange(phone, data)
            }}
          />
        </div>
        <InputPasswordTest
          onPasswordChange={handleInputChanges}
          password={userData?.password}
          testId='registrations-password'
          name='password'
        />

        {['business', 'manager'].includes(category) && (
          <>
            <div className='flex items-center justify-center w-full ion-text-center mt-7'>
              <Text
                onClick={() => {
                  setShowCommercialCode(!showCommercialCode)
                }}
                className='underline cursor-pointer'
                size='text-sm'
              >
                showCommercialCode
              </Text>
            </div>
            {showCommercialCode && (
              <div className='mb-4'>
                <Input
                  name='commercialCode'
                  type='text'
                  value={companyData?.commercial_code}
                  onIonInput={handleInputChanges}
                  labelOptions={{
                    children: 'commercialCode',
                    capitalize: false
                  }}
                />
              </div>
            )}
          </>
        )}
        <ButtonStep handleBack={handlePrevious} nextType='submit' />
      </form>
    </AuthLayout>
  )
}
