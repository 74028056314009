import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import { type ComponentProps } from 'react'
import Label from '#components/ui/Label'
import { IonItem } from '@ionic/react'
import classNames from 'classnames'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
}

interface Props {
  maskOptions?: Partial<typeof defaultMaskOptions>
  inputOptions?: Omit<ComponentProps<typeof MaskedInput>, 'mask'>
  labelOptions?: ComponentProps<typeof Label> & { show: boolean }
}

const CurrencyInput = ({
  maskOptions = defaultMaskOptions,
  inputOptions,
  labelOptions
}: Props): JSX.Element => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  })

  const labelBaseClass = 'block text-sm font-medium text-gray-900 '

  const labelClassName = classNames(labelOptions?.className, labelBaseClass)

  const labelPosition = labelOptions?.position ?? 'stacked'

  return (
    <IonItem
      lines='none'
      className='ion-no-padding ion-no-margin'
      color='transparent'
    >
      {labelOptions?.show === true && (
        <Label
          position={labelPosition}
          className={labelClassName}
          capitalize={labelOptions?.capitalize ?? true}
        >
          {labelOptions.children}
        </Label>
      )}
      <MaskedInput id='input-currency' mask={currencyMask} {...inputOptions} />
    </IonItem>
  )
}

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {}
}

export default CurrencyInput
