import { IonCol, IonRow, IonTextarea } from '@ionic/react'
import { type ComponentProps } from 'react'
import Label from './Label'
import Text from './Text'

interface Props extends ComponentProps<typeof IonTextarea> {
  /**
   * The label options.
   */
  labelOptions?: ComponentProps<typeof Label>
  /**
   * The optional label.
   */
  optionalLabel?: React.ReactNode
}

export type InputTextAreaValue = ComponentProps<typeof IonTextarea>['value']
export type InputTextAreaOnChange = ComponentProps<
  typeof IonTextarea
>['onIonChange']

export default function InputTextArea(props: Props): JSX.Element {
  const { labelOptions, optionalLabel, ...textAreaProps } = props
  return (
    <IonCol>
      {labelOptions != null && optionalLabel != null && (
        <IonRow className='ion-no-padding ion-no-margin ion-justify-content-between ion-align-items-center'>
          <Label
            position='fixed'
            className='block mb-1 text-sm font-medium text-gray-900  capitalize'
          >
            {labelOptions?.children}
          </Label>
          {optionalLabel}
        </IonRow>
      )}
      {labelOptions != null && optionalLabel != null && (
        <Label
          position='fixed'
          className='block mb-1 text-sm font-medium text-gray-900  capitalize'
        >
          {labelOptions.children}
        </Label>
      )}
      <IonTextarea
        {...textAreaProps}
        className='border-2 border-gray-200  focus-within:border-gray-400  text-gray-900 text-sm rounded-lg block w-full '
      />
      {textAreaProps.helperText != null && (
        <Text size='text-xs'>{textAreaProps.helperText}</Text>
      )}
      {textAreaProps.errorText != null && (
        <Text size='text-xs' color='text-red-500'>
          {textAreaProps.errorText}
        </Text>
      )}
    </IonCol>
  )
}
