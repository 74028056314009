import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type User } from './users'
import { type Company } from './companies'
import { type Jar } from './jars'
import { type TackpayId } from './tackpayIds'
import { type QueryParamsRetrieve } from 'sdk/query'

type MaterialType = 'materials'
type MaterialRel = ResourceRel & { type: MaterialType }

type MaterialActionType = 'creation' | 'download' | 'email'
type MaterialCategory = 'user' | 'company' | 'jar'

type TackpayIdRel = ResourceRel & { type: 'tackpay_ids' }
type UserRel = ResourceRel & { type: 'users' }
type CompanyRel = ResourceRel & { type: 'companies' }
type JarRel = ResourceRel & { type: 'jars' }

interface Material extends Resource {
  readonly type: MaterialType
  title?: string
  storage_url: string
  storage_path: string
  action_type: MaterialActionType
  category: MaterialCategory
  user?: User
  company?: Company
  jar?: Jar
  tackpay_id?: TackpayId
}

interface MaterialCreate extends ResourceCreate {
  title?: string
  action_type: MaterialActionType
  tackpay_id: TackpayIdRel
  category: MaterialCategory
  user?: UserRel
  company?: CompanyRel
  jar?: JarRel
}

class Materials extends ApiResource<Material> {
  static readonly TYPE: MaterialType = 'materials' as const

  async create(
    resource: MaterialCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Material> {
    return await this.resources.create<MaterialCreate, Material>(
      { ...resource, type: Materials.TYPE },
      params,
      options
    )
  }

  isMaterial(resource: any): resource is Material {
    return resource.type != null && resource.type === Materials.TYPE
  }

  relationship(id: string | ResourceId | null): MaterialRel {
    return id === null || typeof id === 'string'
      ? { id, type: Materials.TYPE }
      : { id: id.id, type: Materials.TYPE }
  }

  type(): MaterialType {
    return Materials.TYPE
  }
}

export type { Material, MaterialCreate, MaterialActionType }
export default Materials
