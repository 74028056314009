import { MEMBER_TOKEN_KEY } from '#constants/memberTokenKey'
import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { getPreferences } from 'storage/preferences'

interface InviteMemberValue {
  show: boolean
  setShow: (show: boolean) => void
  token?: string
}

export default function useInviteMember(): InviteMemberValue {
  const [token, setToken] = useState<string>()

  const [show, setShow] = useState<boolean>(false)

  const { routeInfo } = useIonRouter()

  const handleFetchToken = async (): Promise<void> => {
    const token = await getPreferences<string>(MEMBER_TOKEN_KEY)
    setToken(token)
    setShow(token != null)
  }

  useEffect(() => {
    if (routeInfo?.pathname === '/dashboard/tipped/home') {
      void handleFetchToken()
    }

    return () => {
      setShow(false)
    }
  }, [routeInfo?.pathname])

  return {
    show,
    setShow,
    token
  }
}
