import { type UserCategory } from '#tackpay-sdk'
import {
  documentOutline,
  languageOutline,
  archiveOutline,
  cashOutline,
  folderOutline,
  helpBuoyOutline,
  keyOutline,
  peopleCircle
} from 'ionicons/icons'

interface SettingInfo {
  category: UserCategory
  label: string
  icon: string
  href: string
}

export const getSetting = (category: UserCategory): SettingInfo[] => {
  const basePath = `/dashboard/${category}/settings`

  const SETTINGS: SettingInfo[] = [
    /** Tipped */
    // {
    //   category: 'tipped',
    //   label: 'setting_dashboard.deposit',
    //   icon: cashOutline,
    //   href: `${basePath}/withdrawals`
    // },
    {
      category: 'tipped',
      label: 'setting_dashboard.account_statement',
      icon: folderOutline,
      href: `${basePath}/account-statements`
    },
    {
      category: 'tipped',
      label: 'setting_dashboard.profile',
      icon: documentOutline,
      href: `${basePath}/identities`
    },
    {
      category: 'tipped',
      label: 'access_data',
      icon: keyOutline,
      href: `${basePath}/access/data`
    },
    {
      category: 'tipped',
      label: 'setting_dashboard.languages',
      icon: languageOutline,
      href: `${basePath}/languages`
    },
    {
      category: 'tipped',
      label: 'setting_dashboard.terms',
      icon: archiveOutline,
      href: `${basePath}/terms-and-conditions`
    },
    {
      category: 'tipped',
      label: 'setting_dashboard.contact-us',
      icon: helpBuoyOutline,
      href: `${basePath}/contact-us`
    },

    /** Business */
    {
      category: 'business',
      label: 'setting_dashboard.account_statement',
      icon: cashOutline,
      href: `${basePath}/account-statements`
    },
    {
      label: 'sidemenu.collaborator',
      icon: peopleCircle,
      href: '/dashboard/business/collaborators',
      category: 'business'
    },
    {
      category: 'business',
      label: 'access_data',
      icon: keyOutline,
      href: `${basePath}/access/data`
    },
    {
      category: 'business',
      label: 'setting_dashboard.languages',
      icon: languageOutline,
      href: `${basePath}/languages`
    },
    {
      category: 'business',
      label: 'setting_dashboard.terms',
      icon: archiveOutline,
      href: `${basePath}/terms-and-conditions`
    },
    {
      category: 'business',
      label: 'setting_dashboard.contact-us',
      icon: helpBuoyOutline,
      href: `${basePath}/contact-us`
    },
    /** Manager */
    {
      category: 'manager',
      label: 'setting_dashboard.account_statement',
      icon: cashOutline,
      href: `${basePath}/account-statements`
    },
    {
      label: 'sidemenu.collaborator',
      icon: peopleCircle,
      href: '/dashboard/business/collaborators',
      category: 'manager'
    },
    {
      category: 'manager',
      label: 'access_data',
      icon: keyOutline,
      href: `${basePath}/access/data`
    },
    {
      category: 'manager',
      label: 'setting_dashboard.languages',
      icon: languageOutline,
      href: `${basePath}/languages`
    },
    {
      category: 'manager',
      label: 'setting_dashboard.terms',
      icon: archiveOutline,
      href: `${basePath}/terms-and-conditions`
    },
    {
      category: 'manager',
      label: 'setting_dashboard.contact-us',
      icon: helpBuoyOutline,
      href: `${basePath}/contact-us`
    },
    /** Collaborator */
    {
      category: 'collaborator',
      label: 'setting_dashboard.account_statement',
      icon: cashOutline,
      href: `${basePath}/account-statements`
    },
    {
      label: 'sidemenu.collaborator',
      icon: peopleCircle,
      href: `/dashboard/${category}/collaborators`,
      category: 'collaborator'
    },
    {
      category: 'collaborator',
      label: 'access_data',
      icon: keyOutline,
      href: `${basePath}/access/data`
    },
    {
      category: 'collaborator',
      label: 'setting_dashboard.languages',
      icon: languageOutline,
      href: `${basePath}/languages`
    },
    {
      category: 'collaborator',
      label: 'setting_dashboard.terms',
      icon: archiveOutline,
      href: `${basePath}/terms-and-conditions`
    },
    {
      category: 'collaborator',
      label: 'setting_dashboard.contact-us',
      icon: helpBuoyOutline,
      href: `${basePath}/contact-us`
    }
  ]

  const result = SETTINGS.filter((setting) => setting.category === category)

  return result
}
