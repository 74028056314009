import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Skeleton from '#components/ui/Skeleton'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow } from '@ionic/react'
import MemberList from './members/MemberList'
import { useState } from 'react'

export default function DivideTipSection(): JSX.Element {
  const { isLoading, error, jar } = useTeamContext()

  const [equalSplit, setEqualSplit] = useState(false)

  if (isLoading)
    return (
      <IonCol>
        <Skeleton className='w-full h-24' />
        <Skeleton className='w-full h-24' />
        <Skeleton className='w-full h-24' />
        <Skeleton className='w-full h-24' />
      </IonCol>
    )

  if (error != null) return <ErrorBox error={error} />

  if (jar == null)
    return (
      <ErrorBox
        error={{
          title: 'Jar not found',
          detail: 'Jar not found',
          code: 'team_not_found',
          message: 'Jar not found'
        }}
      />
    )

  return (
    <IonCol>
      <IonRow className='ion-align-items-center'>
        <IonCol size='6'>
          <Text className='text-xl' color='gray-900'>
            balance
          </Text>
          <Text size='text-2xl' color='gray-900' fontWeight='font-extrabold'>
            {formattedAmount(
              jar.balance?.tronc.available.amount_cents,
              jar.balance?.tronc.available.currency
            )}
          </Text>
        </IonCol>
        <IonCol size='6' className='flex ion-justify-content-end'>
          <Button
            data-testid='equal-split'
            size='small'
            onClick={() => {
              setEqualSplit(true)
            }}
          >
            divideTip.equalSplit
          </Button>
        </IonCol>
      </IonRow>
      <div className='mt-10'>
        <MemberList
          members={jar?.members ?? []}
          jarId={jar.id}
          balance={jar.balance?.tronc?.available.amount_cents ?? 0}
          equalSplit={equalSplit}
          setEqualSplit={setEqualSplit}
        />
      </div>
    </IonCol>
  )
}
