import Avatar from '#components/ui/Avatar'
import DefaultTipButton from '#components/ui/DefaultTipButton'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_COMPANY_BIO } from '#constants/bio'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type PaymentCreate, type CheckoutSession } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import CustomModal, { type TypeCustomModal } from '../CustomModal'
import Button from '#components/ui/Button'
import { getSymbolCurrency } from '#constants/country'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'
import isDesktop from '#utils/isDesktop'
import { savePreferences } from 'storage/preferences'

interface Props {
  checkout: CheckoutSession
  tackpayIdValue: string
}

export default function CheckoutTipped({
  checkout,
  tackpayIdValue
}: Props): JSX.Element {
  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.create.loading'
  )

  const { showToast: successToast } = useToast('success')

  const { showToast: showWarn } = useToast('warning')

  const { push } = useIonRouter()

  const [currentAmount, setCurrentAmount] = useState<number | undefined>(0)

  const [tipModalType, setTipModalType] = useState<
    TypeCustomModal | undefined
  >()

  const [showModalCustom, setShowModalCustom] = useState<boolean>(false)

  const handlePaymentCreate = (createPayment: PaymentCreate): void => {
    startLoading()

    sdk.payments
      .create(createPayment)
      .then(() => {
        void savePreferences('checkout', 1)
        stopLoading()
        successToast(undefined, 'checkout.payment.create.success')
        push(`/checkout/${tackpayIdValue}/${checkout.id}/payment-methods`)
      })
      .catch((error) => {
        stopLoading()
        showWarn(undefined, getErrorMessage(error))
      })
  }

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>): void => {
    if (e != null) {
      e.preventDefault()
    }

    if (currentAmount != null && currentAmount > 0) {
      const amount = parseFloat((currentAmount * 100).toFixed(2))
      handlePaymentCreate({
        amount,
        category: 'tipped',
        checkout_session: {
          id: checkout.id,
          type: 'checkout_sessions'
        },
        currency: checkout.user?.checkout_setting?.currency ?? 'eur',
        role: 'individual',
        // tackpay_id: tackpayIdValue,
        user: {
          id: checkout.user?.id ?? '',
          type: 'users'
        },
        reference_review: 'tipped'
      })
    }
  }

  const viewDesktop = isDesktop() ? 'm-auto w-5/6' : 'w-full'

  const justifyMobil =
    window.screen.width >= 414
      ? 'ion-justify-content-between'
      : 'ion-justify-content-around'
  return (
    <form onSubmit={handleSubmit}>
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src:
                checkout?.user?.checkout_setting?.avatar ??
                DEFAULT_IMAGES.avatarTipped
            }}
            avatarOptions={{
              className: 'border-4 mx-auto h-24 w-24 border-green-400'
            }}
          />
          <div className='mt-8'>
            <Underlined
              name={
                checkout?.user?.checkout_setting?.username ??
                checkout?.user?.person?.first_name
              }
            />
          </div>
          <IonRow className='pt-8 ion-justify-content-center'>
            <Text className='text-gray-600 text-center'>
              {checkout?.user?.checkout_setting?.description ??
                DEFAULT_COMPANY_BIO}
            </Text>
          </IonRow>
        </IonCol>
        <IonRow className='border-t border-stone-200 w-full h-5 my-5' />
      </IonRow>
      <DefaultTipButton
        tip={checkout?.user?.checkout_setting?.fast_amounts?.map((amount) =>
          formattedAmount(amount)
        )}
        amount={currentAmount}
        setAmount={setCurrentAmount}
        currency={
          checkout?.user?.currency ??
          checkout?.user?.checkout_setting?.currency ??
          'eur'
        }
      />
      <IonRow className={`${viewDesktop} ${justifyMobil} pt-5 px-4 mt-3`}>
        <IonCol
          size={
            checkout?.user?.checkout_setting?.show_custom_amount === true &&
            !checkout?.user?.checkout_setting?.show_percentage_amount
              ? '12'
              : '5.5'
          }
          className='ion-no-padding ion-no-margin'
        >
          {checkout?.user?.checkout_setting?.show_custom_amount === true && (
            <Button
              type='button'
              id='modal-custom'
              color='white'
              className='choose-button-preview  ion-no-margin rounded-md w-full'
              onClick={() => {
                setTipModalType('amount')
                setShowModalCustom(true)
              }}
            >
              <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
                type_amount
              </Text>
            </Button>
          )}
        </IonCol>
        <IonCol size='5.5' className='ion-no-padding ion-no-margin'>
          {checkout?.user?.checkout_setting?.show_percentage_amount ===
            true && (
            <Button
              type='button'
              id='modal-custom'
              color='white'
              className='choose-button-preview ion-no-margin rounded-md w-full'
              onClick={() => {
                setTipModalType('percentage')
                setShowModalCustom(true)
              }}
            >
              <Text className='text-gray-400 text-lg' fontWeight='font-bold'>
                %
              </Text>
            </Button>
          )}
        </IonCol>
      </IonRow>
      <Button
        type='submit'
        className='w-full mt-12 ion-no-padding mx-0'
        translationOptions={{
          translate: 'yes',
          data: {
            amount: currentAmount === 0 ? '' : currentAmount,
            currency:
              currentAmount === 0
                ? ''
                : getSymbolCurrency(
                    checkout?.user?.checkout_setting?.currency ?? 'eur' ?? 'eur'
                  )
          }
        }}
      >
        confirmAmount
      </Button>
      <CustomModal
        dataTip={tipModalType ?? 'percentage'}
        openModal={showModalCustom}
        setOpenModal={setShowModalCustom}
        currency={
          checkout?.user?.currency ??
          checkout?.user?.checkout_setting?.currency ??
          'eur'
        }
        currentAmount={currentAmount ?? 0}
        setCurrentAmount={setCurrentAmount}
        submitFunction={handleSubmit}
      />
    </form>
  )
}
