import SelectTeam from '#components/common/TeamSelection'
import Avatar from '#components/ui/Avatar'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Collaborator, type Jar } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import isCollaboratorManager from '#utils/isCollaboratorManger'
import { IonCol, IonContent, IonRow } from '@ionic/react'
import { useState } from 'react'
import DeleteCollaboratorButton from './DeleteCollaboratorButton'
import { adapterModal } from '#utils/adapter'

interface Props {
  jars: Jar[]
  collaborator: Collaborator
  showModal: boolean
  setShowModal: (showModal: boolean) => void
}
export default function CollaboratorModal({
  jars,
  collaborator,
  showModal,
  setShowModal
}: Props): JSX.Element {
  const { updateCollaborator } = useCollaboratorContext()

  const [role, setRole] = useState(collaborator.role)

  const [currentTeams, setCurrentTeams] = useState<Jar[]>(
    collaborator.jars ?? []
  )

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('collaborator.updating')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (updateCollaborator != null) {
      if (role === 'manager' && currentTeams.length === 0) {
        showToast(undefined, 'collaborator.selectTeam')
        return
      }

      setShowModal(false)

      startLoading()

      updateCollaborator.mutate(
        {
          id: collaborator.id,
          role,
          jar_ids: currentTeams.map((jar) => jar.id)
        },
        {
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          },
          onSuccess: () => {
            stopLoading()
            showSuccess(undefined, 'collaborator.updated')
          }
        }
      )
    }
  }

  const handleSetRole: SelectOnChangeEvent = (e) => {
    const role = e.detail.value
    setRole(role)
    if (role === 'admin') setCurrentTeams([])
  }
  return (
    <Modal
      isOpen={showModal}
      setShowModal={setShowModal}
      initialBreakpoint={0.65}
      breakpoints={[0.5, 0.75, 1]}
    >
      <IonContent className='ion-padding'>
        <form onSubmit={handleSubmit}>
          <IonRow className='ion-justify-content-start ion-align-items-center space-x-4'>
            <Avatar
              avatarOptions={{
                className: 'w-20 h-20'
              }}
              imgOptions={{
                src: collaborator?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
              }}
            />
            <IonCol>
              <Text color='text-gray-900' fontWeight='font-semibold'>
                {collaborator?.user?.person?.first_name ?? collaborator.email}{' '}
                {collaborator?.user?.person?.last_name}
              </Text>
              <Text size='text-sm' className='capitalize'>
                {collaborator.role}
              </Text>
              <DeleteCollaboratorButton
                setShowCollaboratorModal={setShowModal}
                collaborator={collaborator}
              />
            </IonCol>
          </IonRow>
          <hr className='block my-5' />
          <BasicSelect
            options={{
              childrens: ['admin', 'manager'],
              props: {
                className: 'capitalize'
              }
            }}
            interface={adapterModal}
            placeholder={role}
            labelOptions={{
              show: true,
              options: {
                children: 'role'
              }
            }}
            onIonChange={handleSetRole}
            value={role}
            className='capitalize'
          />
          {isCollaboratorManager(role) && (
            <div className='mt-5'>
              <SelectTeam
                currentTeam={currentTeams}
                setCurrentTeam={setCurrentTeams}
                jars={jars}
                multiple
                labelProps={{
                  show: true,
                  options: {
                    capitalize: false,
                    children: 'collaborator.selectTeams'
                  }
                }}
              />
            </div>
          )}

          <IonRow className='ion-justify-content-between ion-align-items-center py-10 space-x-5'>
            <Button
              fill='clear'
              color='medium'
              onClick={() => {
                setShowModal(false)
              }}
            >
              cancel
            </Button>
            <Button type='submit' color='primary' size='default'>
              save
            </Button>
          </IonRow>
        </form>
      </IonContent>
    </Modal>
  )
}
