import AuthLayout from '#components/layouts/auth/AuthLayout'
import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { DEMO_USA_PROFILES } from '#constants/demoUsa'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { logoFacebook, logoGoogle, logoInstagram } from 'ionicons/icons'
import { useParams } from 'react-router'

export default function DemoEndUsa(): JSX.Element {
  const { category } = useParams<{ category: string }>()

  const profiles = DEMO_USA_PROFILES[category]

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true,
        isUsa: true
      }}
    >
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src: profiles[0]?.img ?? '/assets/img/pool.svg'
            }}
            avatarOptions={{
              className: ' border-4 mx-auto h-28 w-28 border-green-400'
            }}
          />
          <Underlined name={profiles[0]?.name ?? 'Pool'} />
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-center mt-8'>
        <Text>default_reply</Text>
      </IonRow>
      <IonGrid className='mt-10'>
        <IonRow className='ion-justify-content-center ion-align-items-center space-x-4'>
          <IonIcon
            src={logoFacebook}
            className='text-blue-500  cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://www.tackpay.net/Us/virtual-tip-jar-business.html`,
                '_blank'
              )
            }
          />

          <IonIcon
            src={logoInstagram}
            className='text-pink-400 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://www.tackpay.net/Us/virtual-tip-jar-business.html`,
                '_blank'
              )
            }
          />

          <IonIcon
            src={logoGoogle}
            className='text-blue-600 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                `https://www.tackpay.net/Us/virtual-tip-jar-business.html`,
                '_blank'
              )
            }
          />

          {/* <IonIcon
            src='/assets/img/tripadvisor.svg'
            size='large'
            className='text-blue-400 cursor-pointer'
            onClick={() => window.open('https://tripadvisor.com', '_blank')}
          /> */}

          <IonIcon
            src='/assets/img/web-icon.svg'
            className='text-gray-600 cursor-pointer'
            size='large'
            onClick={() =>
              window.open(
                'https://www.tackpay.net/Us/virtual-tip-jar-business.html',
                '_blank'
              )
            }
          />
        </IonRow>
      </IonGrid>
      <IonRow className='mt-20'>
        <Button
          className='w-full'
          href='https://app.tackpay.net/registrations'
          target='_blank'
        >
          Create your TackPay profile now
        </Button>
      </IonRow>
      <IonRow className='mt-40 ion-justify-content-center ion-align-items-center'>
        {/* <Button
          className='underline ion-no-padding'
          color='dark'
          fill='clear'
          iconOptions={{ icon: downloadOutline }}
        >
          checkout.bill.title
        </Button> */}
      </IonRow>
    </AuthLayout>
  )
}
