import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId
} from 'sdk/resource'
import { type Payment } from './payments'
import { type Payout } from './payouts'
import { type QueryParamsRetrieve } from 'sdk/query'

type FeeType = 'fees'
type FeeRel = ResourceRel & { type: FeeType }

type FeeCategory = 'payment' | 'payout' | 'subscription'

type PaymentRel = ResourceRel & { type: 'payments' }
type PayoutRel = ResourceRel & { type: 'payouts' }

interface Fee extends Resource {
  readonly type: FeeType
  amount_cents: number
  amount_float: number
  amount_formatted: string
  platform_amount: number
  provider_amount: number
  covered: boolean
  currency: string
  platform_fixed_calculation: number
  platform_percentage_calculation: number
  provider_fixed_calculation: number
  provider_percentage_calculation: number
  provider_vat_amount: number
  platform_vat_amount: number
  decimal_deviation?: number
  retain_per_payout: boolean
  fee_category: FeeCategory
  is_domestic: boolean
  is_used: boolean
  payment?: Payment
  payout?: Payout
}

interface FeeCreate extends ResourceCreate {
  covered: boolean
  fee_category: FeeCategory
  payment?: PaymentRel
  payout?: PayoutRel
}

class Fees extends ApiResource<Fee> {
  static readonly TYPE: FeeType = 'fees' as const

  async create(
    resource: FeeCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Fee> {
    return await this.resources.create<FeeCreate, Fee>(
      { ...resource, type: Fees.TYPE },
      params,
      options
    )
  }

  isFee(resource: any): resource is Fee {
    return resource.type != null && resource.type === Fees.TYPE
  }

  relationship(id: string | ResourceId | null): FeeRel {
    return id === null || typeof id === 'string'
      ? { id, type: Fees.TYPE }
      : { id: id.id, type: Fees.TYPE }
  }

  type(): FeeType {
    return Fees.TYPE
  }
}

export type { Fee, FeeCreate }
export default Fees
