import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Skeleton from '#components/ui/Skeleton'
import { useStatisticFeedbackContext } from '#contexts/StatisticFeedbackContext'
import isDesktop from '#utils/isDesktop'
import EmptyStatisticFeedback from './EmptyStatisticFeedback'
import StatisticFeedbackContent from './StatisticFeedbackContent'

export default function StatisticFeedbackSection(): JSX.Element {
  const { statisticFeedback, isLoading, error } = useStatisticFeedbackContext()

  if (isLoading) return <Skeleton className='w-full h-20' />

  if (error != null) return <ErrorBox error={error} />

  if (statisticFeedback == null) {
    if (isDesktop()) {
      return (
        <Card>
          <EmptyStatisticFeedback />
        </Card>
      )
    } else return <EmptyStatisticFeedback />
  }

  return <StatisticFeedbackContent statisticFeedback={statisticFeedback} />
}
