import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useUserContext } from '#contexts/UserContext'
import { type UserCategory, type Transfer, type Payment } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import formattedDate from '#utils/formattedDate'
import { IonCol, IonGrid, IonRow } from '@ionic/react'

interface Props {
  payment: Transfer | Payment
  category: UserCategory
  isTeam?: boolean
  isTransfer?: boolean
}

export default function PaymentCard({
  payment,
  category,
  isTeam = false,
  isTransfer = true
}: Props): JSX.Element {
  const { user } = useUserContext()
  const getName = (): string => {
    if (payment.jar != null) return payment.jar.name
    else if (payment.member != null)
      return payment.member.user?.person?.first_name ?? 'Unknown'
    else if (payment.user != null)
      return payment.user.person?.first_name ?? 'Unknown'
    else return 'Unknown'
  }

  const getAvatar = (): string => {
    const reviewReference =
      (payment as Payment)?.reference_review ??
      (payment as Transfer)?.payment?.reference_review ??
      ''
    if (reviewReference === 'single_member')
      return (
        payment?.member?.user?.avatar ??
        payment?.jar?.avatar ??
        DEFAULT_IMAGES.avatarTipped
      )
    if (reviewReference === 'jar')
      return payment?.jar?.avatar ?? DEFAULT_IMAGES.logoTeam
    if (reviewReference === 'tipped')
      return payment?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
    return DEFAULT_IMAGES.avatarTipped
  }

  const getPaymentUrl = (): string => {
    if (isTransfer && isTeam)
      return `/dashboard/${category}/jars/${
        payment?.jar?.id ?? ''
      }/settings/transfers/${payment.id}`

    if (!isTransfer && isTeam)
      return `/dashboard/${category}/jars/${
        payment?.jar?.id ?? ''
      }/settings/payments/${payment.id}`

    if (isTransfer && !isTeam)
      return `/dashboard/${category}/wallets/transfers/${payment.id}`

    return `/dashboard/${category}/wallets/payments/${payment.id}`
  }

  return (
    <Card
      routerDirection='root'
      routerLink={getPaymentUrl()}
      className='cursor-pointer'
    >
      <IonGrid className='ion-no-padding ion-no-margin'>
        <IonRow className='ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding'>
          <IonCol size='3' className='ion-no-margin ion-no-padding'>
            <Avatar imgOptions={{ src: getAvatar() }} />
          </IonCol>
          <IonCol size='6'>
            <Text size='text-base' color='gray-900' fontWeight='font-bold'>
              {getName()}
            </Text>
            <Text
              translationOptions={{
                translate: 'no'
              }}
              className='capitalize'
              size='text-base'
            >
              {formattedDate(payment.created_at ?? '', user?.lang, {
                day: 'numeric',
                month: 'short'
              })}
            </Text>
          </IonCol>

          <IonCol size='3'>
            <Text
              color='primary'
              className='justify-end flex'
              size='text-xl'
              fontWeight='font-bold'
            >
              {formattedAmount(payment.amount_cents, payment.currency)}
            </Text>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Card>
  )
}
