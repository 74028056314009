import { useTokenContext } from '#contexts/TokenContext'
import useToast from '#hooks/useToast'
import { GLOBAL_CONFIG } from 'configs/global'
import getErrorMessage from '#utils/getErrorMessage'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useEffect, useState } from 'react'
import { ClipboardToCopy } from '#components/common/Clipboard'

interface Props {
  jarId: string
}
export default function MemberCopyLinkButton({ jarId }: Props): JSX.Element {
  const { createToken } = useTokenContext()

  const { company } = useCompanyContext()

  const { showToast } = useToast('warning')

  // const { showToast: successShow } = useToast('success')

  const [copyUrl, setCopyUrl] = useState<string>('')

  const handleCopy = (): void => {
    if (createToken != null && jarId != null) {
      createToken.mutate(
        {
          category: 'team_invite',
          jar: {
            id: jarId,
            type: 'jars'
          },
          company:
            company != null
              ? {
                  type: 'companies',
                  id: company.id
                }
              : undefined
        },
        {
          onSuccess: (data) => {
            setCopyUrl(
              `${GLOBAL_CONFIG.frontEndURL}/registrations/member/invite/${
                data?.value ?? ''
              }`
            )
          },
          onError: (error) => {
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }

  useEffect(() => {
    if (copyUrl === '') {
      handleCopy()
    }
  }, [])

  return (
    <ClipboardToCopy value={copyUrl} />
    // <CopyToClipboard
    //   text={copyUrl}
    //   onCopy={() => {
    //     successShow('members.linkCopied', 'members.linkCopiedInfo')
    //   }}
    // >
    //   <Button
    //     className='w-full'
    //     fill='clear'
    //     color='gray-900'
    //     iconOptions={{
    //       src: copyOutline,
    //       slot: 'start'
    //     }}
    //     onClick={handleCopy}
    //     data-testid='copy-link'
    //   >
    //     member.invite.link
    //   </Button>
    // </CopyToClipboard>
  )
}
