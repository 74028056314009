import { type Jar, type UserCategory } from '#tackpay-sdk'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardWalletBalance from './card/WallteBalanceCard'
import PaymentListSection from '../payments/list/PaymentsListSection'
import { useEffect, useState } from 'react'
import { Navigation, Pagination } from 'swiper'
import SubTitle from '#components/ui/SubTitle'

interface Props {
  jars?: Jar[] | null
  category: UserCategory
  isLoading: boolean
  error?: any
}
export default function WalletBusinessSection({
  jars,
  category,
  isLoading,
  error
}: Props): JSX.Element {
  const [currentTeam, setCurrentTeam] = useState<Jar | undefined>(jars?.[0])

  const handleSlideChange = (swiper: any): void => {
    setCurrentTeam(jars?.[swiper.activeIndex])
  }

  useEffect(() => {
    if (jars != null && currentTeam == null) setCurrentTeam(jars[0])
  }, [jars, currentTeam])

  return (
    <>
      {jars?.length != null && jars.length > 1 && (
        <SubTitle>swipeWallet</SubTitle>
      )}
      <div className='absolute inset-x-0 md:relative' id='wallet'>
        <Swiper
          centeredSlides
          slidesPerView={1}
          spaceBetween={10}
          onSlideChange={handleSlideChange}
          modules={[Pagination, Navigation]}
          navigation={{
            enabled: true
          }}
          pagination={{
            dynamicBullets: true,
            clickable: true
          }}
        >
          {jars?.map((jar) => (
            <SwiperSlide key={jar.id} className='ion-padding mb-10'>
              <CardWalletBalance
                category={category}
                jar={jar}
                isLoading={isLoading}
                error={error}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='mt-72 md:mt-10'>
        <PaymentListSection
          jarId={currentTeam?.id}
          category={category}
          isLoading={isLoading}
          errorContext={error}
          isTeamShared={currentTeam?.jar_type === 'shared'}
        />
      </div>
    </>
  )
}
