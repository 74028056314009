import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { type Payment } from '#tackpay-sdk'
import { IonCardHeader, IonCardTitle } from '@ionic/react'

interface Props {
  payment?: Payment | null
  isLoading: boolean
  error?: any
}
export default function PaymentCardComment({
  payment,
  isLoading,
  error
}: Props): JSX.Element {
  if (isLoading) return <CardSkeleton hasAvatar={false} />

  if (error != null) return <ErrorBox error={error} />

  return (
    <Card>
      <IonCardHeader>
        <IonCardTitle>💬 Commenti</IonCardTitle>
      </IonCardHeader>
      <div className='ion-padding ion-margin'>
        <Text
          translationOptions={{
            translate:
              payment?.comment == null ||
              payment?.comment?.description?.trim() === ''
                ? 'yes'
                : 'no'
          }}
        >
          {payment?.comment == null ||
          payment?.comment?.description?.trim() === ''
            ? 'noComment'
            : payment?.comment.description}
        </Text>
      </div>
      {payment?.comment?.customer_name != null &&
        payment?.comment?.customer_name !== '' && (
          <>
            <IonCardHeader>
              <IonCardTitle>👤 From</IonCardTitle>
            </IonCardHeader>
            <div className='ion-padding ion-margin'>
              <Text
                translationOptions={{
                  translate: 'no'
                }}
              >
                {payment?.comment?.customer_name}
              </Text>
            </div>
          </>
        )}
    </Card>
  )
}
