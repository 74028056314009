import { IonContent, IonPopover, IonRow } from '@ionic/react'
import Text from './Text'
import { useRef, useState } from 'react'

interface Props {
  text: string
  title?: string
}
export default function Info({ text, title }: Props): JSX.Element {
  const popover = useRef<HTMLIonPopoverElement>(null)
  const [showPopover, setShowPopover] = useState<boolean>(false)

  function show(): void {
    setShowPopover(true)
  }
  return (
    <IonRow
      className={
        title === 'i'
          ? 'ion-justify-content-center'
          : 'ion-justify-content-center'
      }
    >
      <div
        onClick={show}
        id='trigger'
        className={
          title === 'i'
            ? ' text-gray-400 border border-gray-500 h-4 w-4 rounded-full text-center text-xs'
            : 'ion-no-margin ion-no-padding h-5 button-address'
        }
      >
        {title === 'i' ? 'i' : title}
      </div>
      <IonPopover
        ref={popover}
        isOpen={showPopover}
        trigger='trigger'
        triggerAction='click'
        reference='trigger'
        arrow={false}
        side='right'
      >
        <IonContent className='bg-gray-400'>
          <div className='p-5'>
            <Text color='gray-500' size='text-base'>
              {text}
            </Text>
          </div>
        </IonContent>
      </IonPopover>
    </IonRow>
  )
}
