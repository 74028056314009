import {
  type ResourceUpdate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type User } from './users'
import { type QueryParamsRetrieve } from 'sdk/query'

type SubscriberType = 'subscribers'
type SubscriberRel = ResourceRel & { type: SubscriberType }

interface Subscriber extends Resource {
  readonly type: SubscriberType

  reference: string
  reference_origin: string
  device_tokens?: string[]
  user?: User
}

interface SubscriberUpdate extends ResourceUpdate {
  device_tokens: string[]
}

class Subscribers extends ApiResource<Subscriber> {
  static readonly TYPE: SubscriberType = 'subscribers' as const

  async update(
    resource: SubscriberUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Subscriber> {
    return await this.resources.update<SubscriberUpdate, Subscriber>(
      { ...resource, type: Subscribers.TYPE },
      params,
      options
    )
  }

  isSubscriber(resource: any): resource is Subscriber {
    return resource.type != null && resource.type === Subscribers.TYPE
  }

  relationship(id: string | ResourceId | null): SubscriberRel {
    return id === null || typeof id === 'string'
      ? { id, type: Subscribers.TYPE }
      : { id: id.id, type: Subscribers.TYPE }
  }

  type(): SubscriberType {
    return Subscribers.TYPE
  }
}

export type { Subscriber, SubscriberUpdate }
export default Subscribers
