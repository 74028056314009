import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Core CSS required for Ionic icons to work properly */
import '@ionic/react/css/ionic.bundle.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/ionicStyle.css'
import './theme/global.css'
import './theme/satoshi.css'

/* Swiper css **/
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

/* Page */
import Welcome from '#pages/Welcome'
import Login from '#pages/Login'
import RegistrationRoutes from '#pages/registrations/RegistrationRoutes'
import DashboardRoutes from '#pages/dashboard/DashboardRoutes'
import CheckoutRoutes from '#pages/checkouts/CheckoutRoutes'
import ConfirmEmail from '#pages/ConfirmEmail'
import ResetPassword from '#pages/ResetPassword'
import RedirectToLogin from '#components/common/RedirectToLogin'
import AccessRedirect from '#pages/Access'
import Select from '#components/pages/demo/Select'
import DemoMethods from '#components/pages/demo/DemoMethods'
import DemoReview from '#components/pages/demo/DemoReview'
import DemoEnd from '#components/pages/demo/DemonEnd'
import { userFirebaseAnalytics } from '#hooks/analytics'
import { useEffect } from 'react'
import TokenExpires from '#pages/TokenExpires'
import RestaurantSelectUsa from '#pages/demoUsa/DemoSelectUsa'
import DemoMethodUsa from '#pages/demoUsa/DemoMethodUsa'
import DemoReviewUsa from '#pages/demoUsa/DemoReviewUsa'
import DemoEndUsa from '#pages/demoUsa/DemoEndUsa'
import DemoCompanyUsa from '#pages/demoUsa/DemoCompanyUsa'

setupIonicReact()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 5, // 5 seconds
      refetchOnReconnect: 'always'
      // staleTime: 1000 * 60 * 5 // 5 minutes
    }
  }
})

const App: React.FC = () => {
  const { setCurrentScreen } = userFirebaseAnalytics()

  useEffect(() => {
    if (window != null) {
      // Ottieni il nome della pagina dalla posizione corrente
      const pageName = window.location.pathname

      // Imposta la pagina corrente usando setCurrentScreen
      setCurrentScreen(pageName)
    }
  }, [window, setCurrentScreen])

  return (
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet id='main'>
            <Route exact path='/'>
              <Welcome />
            </Route>
            <Route exact path='/login'>
              <Login />
            </Route>
            <Route path='/registrations'>
              <RegistrationRoutes />
            </Route>
            <Route path='/dashboard/:category'>
              <DashboardRoutes />
            </Route>
            <Route path='/checkout/:tackpayId'>
              <CheckoutRoutes />
            </Route>
            <Route exact path='/confirm-email'>
              <ConfirmEmail />
            </Route>
            <Route exact path='/reset-passwords'>
              <ResetPassword />
            </Route>
            <Route exact path='/demo'>
              {/* <DemoCompany /> */}
              <DemoCompanyUsa />
            </Route>
            <Route exact path='/demo/jars'>
              <Select />
            </Route>
            <Route exact path='/demo/methods'>
              <DemoMethods />
            </Route>
            <Route exact path='/demo/reviews'>
              <DemoReview />
            </Route>
            <Route exact path='/demo/end'>
              <DemoEnd />
            </Route>
            <Route exact path='/demo/usa/company/select'>
              <DemoCompanyUsa />
            </Route>
            <Route exact path='/demo/usa/:category'>
              <RestaurantSelectUsa />
            </Route>
            <Route exact path='/demo/usa/:category/payment-methods'>
              <DemoMethodUsa />
            </Route>
            <Route exact path='/demo/usa/:category/reviews'>
              <DemoReviewUsa />
            </Route>
            <Route exact path='/demo/usa/:category/end'>
              <DemoEndUsa />
            </Route>
            <Route exact path='/logout' render={() => <RedirectToLogin />} />
            <Route
              path='/access'
              render={(props) => <AccessRedirect {...props} />}
            />

            <Route
              path='/en/:restOfPath*'
              render={({ match }) => (
                <Redirect to={'/' + match.params.restOfPath ?? '/'} />
              )}
            />
            <Route
              path='/fr/:restOfPath*'
              render={({ match }) => (
                <Redirect to={'/' + match.params.restOfPath ?? '/'} />
              )}
            />
            <Route
              path='/it/:restOfPath*'
              render={({ match }) => (
                <Redirect to={'/' + match.params.restOfPath ?? '/'} />
              )}
            />
            <Route
              path='/es/:restOfPath*'
              render={({ match }) => (
                <Redirect to={'/' + match.params.restOfPath ?? '/'} />
              )}
            />
            <Route
              path='/ar/:restOfPath*'
              render={({ match }) => (
                <Redirect to={'/' + match.params.restOfPath ?? '/'} />
              )}
            />

            <Route
              path='/checkout/tipped/:tackpayId'
              render={({ match }) => (
                <Redirect to={`/checkout/${match.params.tackpayId}`} />
              )}
            />
            <Route
              path='/checkout/structure/:tackpayId'
              render={({ match }) => (
                <Redirect to={`/checkout/${match.params.tackpayId}`} />
              )}
            />
            <Route
              path='/checkout/team/:tackpayId'
              render={({ match }) => (
                <Redirect to={`/checkout/${match.params.tackpayId}`} />
              )}
            />

            <Route
              path='/checkoutBusiness/:tackpayId'
              render={({ match }) => (
                <Redirect to={`/checkout/${match.params.tackpayId}`} />
              )}
            />

            <Route
              path='/checkoutTipped/:tackpayId'
              render={({ match }) => (
                <Redirect to={`/checkout/${match.params.tackpayId}`} />
              )}
            />

            <Route path='/teams' render={() => <TokenExpires />} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
