import useAuthState from '#hooks/useAuthState'
import { type QueryParamsRetrieve, type StatisticView } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

interface StatisticViewContextValue {
  statisticView?: StatisticView | null
  isLoading: boolean
  error?: any
}

const statisticViewInitialValue: StatisticViewContextValue = {
  statisticView: undefined,
  isLoading: false,
  error: null
}

const StatisticViewContext = createContext<StatisticViewContextValue>(
  statisticViewInitialValue
)

export const useStatisticViewContext = (): StatisticViewContextValue => {
  const context = useContext(StatisticViewContext)
  if (context == null) {
    throw new Error(
      'useStatisticViewContext must be used within a StatisticViewProvider'
    )
  }

  return context
}

interface StatisticViewContainerProps {
  children: React.ReactNode
  /**
   * @default true
   */
  fetch?: boolean

  jarId?: string

  params?: QueryParamsRetrieve
}

export default function StatisticViewContainer(
  props: StatisticViewContainerProps
): JSX.Element {
  const { children, fetch = true, params, jarId } = props

  const { getAuthState } = useAuthState()

  const {
    data: statisticView,
    isLoading,
    error
  } = useQuery<StatisticView | undefined | null>({
    queryKey: ['statisticView', jarId, params],
    queryFn: async () => {
      if (jarId != null) {
        return await sdk.jars.statistic_view(jarId, params)
      } else {
        const authInfo = await getAuthState()
        return await sdk.users.statistic_view(authInfo?.userId ?? '', params)
      }
    },
    enabled: fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  // useEffect(() => {
  //   if (fetch) {
  //     void queryClient.invalidateQueries(['statisticView', jarId, params])
  //     void queryClient.invalidateQueries(['statisticView', jarId])
  //     setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
  //   }
  //   console.log('STATISTIC VIEW', statisticView)
  // }, [fetch, queryClient])

  const statisticViewContextValue: StatisticViewContextValue = {
    statisticView,
    isLoading,
    error
  }

  return (
    <StatisticViewContext.Provider value={statisticViewContextValue}>
      {children}
    </StatisticViewContext.Provider>
  )
}
