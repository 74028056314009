import { Link as ReactLink } from 'react-router-dom'

interface Props {
  /**
   * Path to link to
   */
  link: string
  /**
   * Button children
   */
  children: React.ReactNode
  /**
   * Button class name (default: 'tracking-tight leading-tight')
   */
  className?: string
  /**
   * Data test id
   */
  ['data-testid']?: string
}

export default function Link({
  link,
  children,
  className = 'tracking-tight leading-tight',
  ...p
}: Props): JSX.Element {
  return (
    <ReactLink to={link} className={className} {...p}>
      {children}
    </ReactLink>
  )
}
