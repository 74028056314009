import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import { IonCol, useIonRouter } from '@ionic/react'
import CardCategory from '#components/common/CardCategory'
import ButtonStep from '#components/common/ButtonStep'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import useToast from '#hooks/useToast'

export default function AddTeamIban(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  const { isSameIban, setIsSameIban } = useCreateTeamStore()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleIban = (isSameIban: boolean): void => {
    setIsSameIban(isSameIban)

    if (!isSameIban) {
      push(`/dashboard/${category}/jars/add/ibans/add`)
    } else {
      push(`/dashboard/${category}/jars/add/select-ibans`)
    }
  }

  const handleNextStep = (): void => {
    if (isSameIban == null) {
      showToast(undefined, 'teamPage.selectIban')
      return
    }

    if (!isSameIban) {
      push(`/dashboard/${category}/jars/add/ibans/add`)
    } else {
      push(`/dashboard/${category}/jars/add/select-ibans`)
    }
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add/companies`, 'back')
  }

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add/companies`
        }
      }}
    >
      <ProgressBar value={0.5} text='teamPage.add.chip.iban' />
      <IonCol className='space-y-10'>
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleIban(true)
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-private.svg'
            }
          }}
          title='teamPage.add.ibanStep.equalCompany.title'
          description='teamPage.add.ibanStep.equalCompany.description'
          showCheckIcon={isSameIban}
        />
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleIban(false)
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-shared.svg'
            }
          }}
          title='teamPage.add.ibanStep.notEqualCompany.title'
          description='teamPage.add.ibanStep.notEqualCompany.description'
          showCheckIcon={isSameIban != null && !isSameIban}
        />
      </IonCol>
      <ButtonStep handleBack={handleBack} handleNext={handleNextStep} />
    </AppLayout>
  )
}
