import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type BaseBalance } from './balances'

type BalanceDeductionType = 'balance_deductions'
type BalanceDeductionRel = ResourceRel & { type: BalanceDeductionType }

interface BalanceDeduction extends Resource {
  readonly type: BalanceDeductionType

  total: BaseBalance

  available: BaseBalance

  pending: BaseBalance
}

class BalanceDeductions extends ApiResource<BalanceDeduction> {
  static readonly TYPE: BalanceDeductionType = 'balance_deductions' as const

  isBalanceDeduction(resource: any): resource is BalanceDeduction {
    return resource.type != null && resource.type === BalanceDeductions.TYPE
  }

  relationship(id: string | ResourceId | null): BalanceDeductionRel {
    return id === null || typeof id === 'string'
      ? { id, type: BalanceDeductions.TYPE }
      : { id: id.id, type: BalanceDeductions.TYPE }
  }

  type(): BalanceDeductionType {
    return BalanceDeductions.TYPE
  }
}

export type { BalanceDeduction }
export default BalanceDeductions
