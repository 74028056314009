import ErrorBox from '#components/errors/ErrorBox'
import Skeleton from '#components/ui/Skeleton'
import { type Balance } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCardTitle } from '@ionic/react'
import { type BalanceDeduction } from 'sdk/resources/balanceDeductions'

interface Props {
  loading?: boolean
  error?: any
  balance?: Balance | null | BalanceDeduction
  isTronc?: boolean
  currency?: string
}

export default function WalletPendingBalance({
  loading = false,
  error,
  balance,
  isTronc = false,
  currency
}: Props): JSX.Element {
  if (loading) return <Skeleton className='w-10 h-4' />
  if (error != null) return <ErrorBox error={error} />
  return (
    <IonCardTitle
      className='text-black font-bold text-xl ion-no-margin ion-no-padding flex items-center'
      data-testid='data-pending'
    >
      {isTronc && balance?.type === 'balances'
        ? formattedAmount(
            balance?.tronc?.pending?.amount_cents,
            balance?.tronc?.pending?.currency ?? currency
          )
        : formattedAmount(
            balance?.pending?.amount_cents,
            balance?.pending?.currency ?? currency
          )}
    </IonCardTitle>
  )
}
