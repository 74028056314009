import { IonBackButton, IonIcon } from '@ionic/react'
import { type ComponentProps } from 'react'
import Button from './Button'
import { chevronBackOutline } from 'ionicons/icons'

interface BackButtonProps extends ComponentProps<typeof IonBackButton> {}

export default function BackButton(props: BackButtonProps): JSX.Element {
  const href: string | undefined = props?.defaultHref
  return href === undefined ? (
    <IonBackButton
      text=''
      disabled={props?.disabled ?? false}
      className='ion-no-padding '
      type='button'
      color={props.color ?? 'dark'}
    />
  ) : (
    <Button
      id='back-button'
      routerLink={props.defaultHref}
      routerDirection='root'
      color='dark'
      className='ion-no-margin ion-no-padding'
    >
      <IonIcon
        className='on-no-margin ion-no-padding -translate-x-3'
        icon={chevronBackOutline}
      />
    </Button>
  )
}
