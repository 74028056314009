import { IonImg, IonRow } from '@ionic/react'

interface Props {
  /**
   * Show title name
   * @default true
   */
  showTitle?: boolean
}

export default function TackPayIcon({ showTitle = true }: Props): JSX.Element {
  return (
    <IonRow className='flex ion-justify-content-start ion-align-items-center space-x-2'>
      <IonImg src='/assets/img/icon.svg' style={{ width: 40, height: 40 }} />
      {showTitle && (
        <IonImg src='/assets/img/logo.svg' style={{ width: 90, height: 40 }} />
      )}
    </IonRow>
  )
}
