import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import translationEN from './locales/en/common.json'
import translationIT from './locales/it/common.json'
import translationES from './locales/es/common.json'
import translationAR from './locales/ar/common.json'
import translationFR from './locales/fr/common.json'

import { initReactI18next } from 'react-i18next'

export const resources = {
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  ar: {
    translation: translationAR
  }
} as const

void i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    // lng: 'it',
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'ar', 'it', 'fr'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources,
    keySeparator: '.'
  })

export default i18n
