import { useTransferContext } from '#contexts/TransferContext'
import PaymentTitle from './info/PaymentTitle'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import PaymentCardInfo from './info/PaymentCardInfo'
import PaymentCardFeedback from './info/PaymentCardFeedback'
import PaymentCardComment from './info/PaymentCardComment'

export default function TransferIdHandler(): JSX.Element {
  const { isLoading, error, transfer } = useTransferContext()

  return (
    <>
      <PaymentTitle isLoading={isLoading} error={error} payment={transfer} />
      <Swiper
        centeredSlides
        slidesPerView={1}
        spaceBetween={10}
        modules={[Pagination]}
        pagination={{
          dynamicBullets: true
        }}
        className='h-[660px]'
      >
        <SwiperSlide className='mb-10'>
          <PaymentCardInfo
            isLoading={isLoading}
            error={error}
            payment={transfer}
          />
        </SwiperSlide>
        <SwiperSlide className='mb-10'>
          <PaymentCardFeedback
            isLoading={isLoading}
            error={error}
            payment={transfer?.payment}
          />
        </SwiperSlide>
        <SwiperSlide className='mb-10'>
          <PaymentCardComment
            isLoading={isLoading}
            error={error}
            payment={transfer?.payment}
          />
        </SwiperSlide>
      </Swiper>
    </>
  )
}
