import { IonCol } from '@ionic/react'
import CardMaterial from './CardMaterial'
import { cloudDownloadOutline, mailOutline } from 'ionicons/icons'
import { useTeamContext } from '#contexts/JarContext'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import SelectTeam from '#components/common/TeamSelection'
import { useEffect, useState } from 'react'
import { type MaterialActionType, type Jar, type Material } from '#tackpay-sdk'
import useToast from '#hooks/useToast'
import sdk from '#utils/sdk'
import getErrorMessage from '#utils/getErrorMessage'
import useLoading from '#hooks/useLoading'
import useFile from '#hooks/useFile'
import { isPlatform } from '@ionic/core'
import { useShare } from '#hooks/useShare'

export default function TeamMaterialDownloadSection(): JSX.Element {
  const { jar, isLoading, error } = useTeamContext()

  const { shareElement } = useShare()

  const TIME_OUT_DOWNLOAD = 1000 * 5 // 5 secondi

  const [currentTeam, setCurrentTeam] = useState<Jar>()

  const [tackpayId, setTackpayId] = useState<string>()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading('material.loading')

  const { downloadFiles, toZip, downloadFile } = useFile()

  useEffect(() => {
    if (jar?.tackpay_ids != null) {
      if (Array.isArray(jar.tackpay_ids) && jar.tackpay_ids.length === 1) {
        setCurrentTeam(jar)
        setTackpayId(jar.tackpay_ids[0].value)
      } else if (jar.tackpay_ids != null && !Array.isArray(jar.tackpay_ids)) {
        setCurrentTeam(jar)
        setTackpayId(jar.tackpay_ids.value)
      }
    }
  }, [jar])

  if (isLoading)
    return (
      <div className='space-y-10'>
        <CardSkeleton />
        <CardSkeleton />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (jar == null) return <ErrorBox error='jar.error.notFound' />

  const deleteLastSlash = (path: string): string => {
    // Dividi il percorso in segmenti usando '/'
    const segmenti = path.split('/')

    // Rimuovi l'ultimo segmento
    const nuovoPercorso = segmenti.slice(0, -1).join('/')

    return nuovoPercorso
  }

  const handleDownload = async (material: Material): Promise<void> => {
    const result = await downloadFiles(material.storage_path ?? '')
    const resultPaths: string[] = []
    if (isPlatform('hybrid')) {
      for (const item of result.items) {
        const resultPath = await downloadFile(
          item.path,
          `tackpay_qr_code_${tackpayId ?? ''}`
        )
        resultPaths.push(resultPath.path ?? '')
      }
      const resultPath = deleteLastSlash(resultPaths[0])

      await shareElement({
        title: 'Checkout my store',
        url: resultPath,
        text: 'Checkout my store',
        dialogTitle: 'Share with friends'
      })
        .then(() => {
          console.log('shared')
        })
        .catch((error) => {
          console.log('error to share', error)
        })
    } else {
      const zip = await toZip(result)

      const zipUrl = URL.createObjectURL(zip)
      const link = document.createElement('a')
      link.href = zipUrl
      link.download = `tackpay_qr_code_${tackpayId ?? ''}.zip` // Specifica il nome del file ZIP da scaricare

      // // Simula un clic sul link
      link.click()

      // // Rimuovi il link
      URL.revokeObjectURL(zipUrl)
    }
  }

  const downloadMaterialTeam = (actionType: MaterialActionType): void => {
    if (currentTeam == null || tackpayId == null) {
      showToast(undefined, 'material.tackpayIdRequired')
    } else {
      const currentTackpayId = Array.isArray(currentTeam?.tackpay_ids)
        ? currentTeam?.tackpay_ids.find((t) => t.value === tackpayId)
        : currentTeam.tackpay_ids

      startLoading()

      sdk.materials
        .create({
          tackpay_id: {
            id: currentTackpayId?.id ?? '',
            type: 'tackpay_ids'
          },
          category: 'jar',
          action_type: actionType,
          jar: {
            id: jar?.id ?? '',
            type: 'jars'
          }
        })
        .then((res) => {
          if (actionType === 'download') {
            setTimeout(() => {
              handleDownload(res)
                .then(() => {
                  stopLoading()
                  showSuccess(undefined, 'material.download.success')
                })
                .catch((error) => {
                  console.log('error during download material', error)
                  stopLoading()
                  showToast(undefined, getErrorMessage(error))
                })
            }, TIME_OUT_DOWNLOAD)
          } else {
            stopLoading()
            showSuccess(undefined, 'material.email.success')
          }
        })
        .catch((error) => {
          stopLoading()
          console.log('error to fetch material', error)
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <IonCol className='block space-y-10'>
      <SelectTeam
        jars={[jar]}
        currentTeam={currentTeam}
        setCurrentTeam={setCurrentTeam}
        formatted='name-tackpayId'
        setTackpayId={setTackpayId}
        multiple={false}
        tackpayId={tackpayId}
        labelProps={{
          show: true,
          options: {
            children: 'selectTeams',
            capitalize: false
          }
        }}
      />
      <CardMaterial
        icon={cloudDownloadOutline}
        label='material.download.direct'
        onClick={() => {
          downloadMaterialTeam('download')
        }}
        ml='ml-10'
      />
      <CardMaterial
        icon={mailOutline}
        label='material.download.email'
        onClick={() => {
          downloadMaterialTeam('email')
        }}
        ml='ml-10'
      />
    </IonCol>
  )
}
