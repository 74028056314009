import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import AddTeamInfoForm from '#components/pages/teams/add/AddTeamInfoForm'

export default function AddTeamInfoStep(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        showMenuButton: false,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
    >
      <ProgressBar value={0.2} text='teamPage.add.chip.details' />
      <AddTeamInfoForm category={category} />
    </AppLayout>
  )
}
