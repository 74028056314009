import { ApiError } from '#tackpay-sdk'
import { IonIcon, IonRow, useIonRouter } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  error?: any
  className?: string
}
export default function ErrorBox({
  error,
  className = 'border border-red-200 text-gray-900 p-2 rounded-lg my-3 ion-align-items-center space-x-3'
}: Props): JSX.Element {
  const { push } = useIonRouter()
  const { t } = useTranslation()
  const getErrorMessage = (): string => {
    if (ApiError.isApiError(error)) {
      return error?.code != null ? error.code : error.message
    } else {
      return error?.message != null ? error.message : error
    }
  }

  useEffect(() => {
    if (ApiError.isApiError(error) && error.status === 401) {
      push('/login', 'root')
    }
  }, [error])

  return (
    <IonRow className={className}>
      <IonIcon icon={alertCircleOutline} className='text-red-500' />
      <div>{t(getErrorMessage())}</div>
    </IonRow>
  )
}
