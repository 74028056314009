import DateRanger from '#components/common/DateRanger'
import Button from '#components/ui/Button'
import { type DateTimeValue } from '#components/ui/DateTime'
import Text from '#components/ui/Text'
import { useCompanyContext } from '#contexts/CompanyContext'
import { useUserContext } from '#contexts/UserContext'
import useFilesystem from '#hooks/useFilesystem'
import useLoading from '#hooks/useLoading'
import { useShare } from '#hooks/useShare'
import useToast from '#hooks/useToast'
import { type DeductionReport } from '#tackpay-sdk'
import createDeductionCSV from '#utils/createDeductionCsv'
import getErrorMessage from '#utils/getErrorMessage'
import isWeb from '#utils/isWeb'
import sdk from '#utils/sdk'
import { Directory, Encoding } from '@capacitor/filesystem'
import { IonRow } from '@ionic/react'
import { useState } from 'react'

export default function DeductionReportPage(): JSX.Element {
  const { company } = useCompanyContext()

  const { user } = useUserContext()

  const { shareElement } = useShare()

  const { showToast } = useToast('warning')

  const { write } = useFilesystem()

  const { showToast: showInfoToast } = useToast('default')

  const { showToast: successShow } = useToast('success')

  const { startLoading, stopLoading } = useLoading('accountStatement.loading')

  const currentDate = new Date()

  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)

  const [dateFrom, setDateFrom] = useState<DateTimeValue>(
    lastMonth.toISOString()
  )
  const [dateTo, setDateTo] = useState<DateTimeValue>(currentDate.toISOString())

  const handleSave = async (report: DeductionReport): Promise<void> => {
    const csvObject = createDeductionCSV(
      report.deductions,
      user?.lang,
      'tipped'
    )

    if (isWeb()) {
      const blob = new Blob([csvObject], {
        type: 'text/csv;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'accountStatement.csv'
      document.body.appendChild(a)
      stopLoading()
      a.click()
      window.URL.revokeObjectURL(url)
    } else {
      write({
        data: csvObject,
        path: 'accountStatement.csv',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
        .then((res) => {
          console.log('File written')
          shareElement({
            dialogTitle: 'Account Statement',
            title: 'Account Statement',
            url: res.uri
          })
            .then(() => {
              stopLoading()
              successShow(undefined, 'accountStatement.success')
            })
            .catch((error) => {
              console.log('error', error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  const handleDownload = (): void => {
    if (dateFrom == null || dateTo == null || company == null) {
      showToast(undefined, 'accountStatement.dateRequired')
    } else if (dateFrom > dateTo) {
      showToast(undefined, 'accountStatement.dateInvalid')
    } else {
      startLoading()
      sdk.deduction_reports
        .create({
          to_date: String(dateTo),
          from_date: String(dateFrom),
          company: { id: company.id, type: 'companies' }
        })
        .then((report) => {
          if (report.deductions == null || report.deductions.length === 0) {
            stopLoading()
            showInfoToast(undefined, 'accountStatement.emptyPayment')
          } else {
            void handleSave(report)
          }
        })
        .catch((error) => {
          console.log('error', error)
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <IonRow className='w-full block mt-10 space-y-5'>
      <Text color='gray-900' fontWeight='font-bold'>
        deduction.report_title
      </Text>
      <Text>deduction.report_subtitle</Text>
      <DateRanger
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        preferWheel={false}
      />
      <Button className='w-full' onClick={handleDownload}>
        deduction.download_report
      </Button>
    </IonRow>
  )
}
