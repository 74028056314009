import AddressAutoComplete from '#components/common/AddressAutoComplete'
import ButtonStep from '#components/common/ButtonStep'
import CountrySelect from '#components/common/CountrySelect'
import Input, { type InputOnChange } from '#components/ui/Input'
import { getCurrencyByCountryName } from '#constants/country'
import { useRegistrationContext } from '#contexts/RegistrationContext'
import useToast from '#hooks/useToast'
import { type UserCategory, type Address } from '#tackpay-sdk'
import textUtil from '#utils/textUtil'
import { useIonRouter } from '@ionic/react'

interface Props {
  category: UserCategory
}

export default function RegistartionAddress({ category }: Props): JSX.Element {
  const { addressData, setAddressData, setUserData } = useRegistrationContext()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleInputChanges: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'city') {
        setAddressData({ city: value })
      } else if (name === 'street') {
        setAddressData({ street: value })
      } else if (name === 'street_number') {
        setAddressData({ street_number: value })
      } else if (name === 'state') {
        setAddressData({ state: value })
      } else if (name === 'postal_code') {
        setAddressData({ postal_code: value })
      }
    }
  }

  const handleCountryChange = (country: string): void => {
    setAddressData({ country_name: country })
    const currency = getCurrencyByCountryName(country).toUpperCase()
    setUserData({ currency })
  }

  const handleAddressChange = (address: Partial<Address>): void => {
    setAddressData({ ...address } as any)
    if (address?.country_name != null) {
      const currency = getCurrencyByCountryName(
        address.country_name
      ).toUpperCase()
      setUserData({ currency })
    }
  }

  const handlePrevious = (): void => {
    push(`/registrations/${category}/persons`, 'back')
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (textUtil.isEmpty(addressData?.country_name)) {
      showToast(undefined, 'registration.countryRequired')
      return
    }

    if (textUtil.isEmpty(addressData?.city)) {
      showToast(undefined, 'registration.cityRequired')
      return
    }

    if (textUtil.isEmpty(addressData?.street)) {
      showToast(undefined, 'registration.streetRequired')
      return
    }

    if (textUtil.isEmpty(addressData?.street_number)) {
      showToast(undefined, 'registration.streetNumberRequired')
      return
    }

    if (textUtil.isEmpty(addressData?.postal_code)) {
      showToast(undefined, 'registration.postalCodeRequired')
      return
    }

    push(`/registrations/${category}/users`)
  }

  return (
    <form onSubmit={handleSubmit} className='pt-3 space-y-4'>
      <AddressAutoComplete setAddress={handleAddressChange} />

      <CountrySelect
        country={addressData?.country_name ?? ''}
        setSelectionCountry={handleCountryChange}
      />

      <div>
        <Input
          name='city'
          type='text'
          value={addressData?.city}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'city'
          }}
          testId='city'
        />
      </div>

      <div>
        <Input
          name='street'
          type='text'
          value={addressData?.street}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'street'
          }}
          testId='street'
        />
      </div>
      <div>
        <Input
          name='street_number'
          type='text'
          value={addressData?.street_number}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'streetNumber',
            capitalize: false
          }}
          testId='streetNumber'
        />
      </div>
      <div>
        <Input
          name='postal_code'
          type='text'
          value={addressData?.postal_code}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'postalCode',
            capitalize: false
          }}
          testId='postalCode'
        />
      </div>
      <div>
        <Input
          name='state'
          type='text'
          value={addressData?.state}
          onIonInput={handleInputChanges}
          labelOptions={{ children: 'state' }}
          testId='state'
        />
      </div>
      <ButtonStep handleBack={handlePrevious} nextType='submit' />
    </form>
  )
}
