import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonRow,
  IonToolbar
} from '@ionic/react'
import TabCheckout from '../checkouts/TabCheckout'
import SingleAvatarTipped from '#components/ui/SingleAvatarTipped'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import Text from '#components/ui/Text'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type CheckoutSettingUpdate, type Jar } from '#tackpay-sdk'
import SlidesAvatarTeams from '../checkouts/SlideAvatarTeams'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'

interface Props {
  show: boolean
  setShow: (show: boolean) => void
  companyId: string
}
export default function CheckoutSettingCompanyPreview({
  setShow,
  show,
  companyId
}: Props): JSX.Element {
  const { checkoutSetting, updateCheckoutSetting, refetch } =
    useCheckoutSettingContext()

  const { company } = useCompanyContext()

  const { startLoading, stopLoading } = useLoading(
    'checkoutSetting.update.loading'
  )

  const { showToast: successShow } = useToast('success')

  const { showToast } = useToast('warning')

  const handleShowTeam = (): Jar[] => {
    return (
      company?.jars?.filter(
        (jar) => checkoutSetting?.show_jars?.includes(jar.id)
      ) ?? []
    )
  }

  const handleSave = (): void => {
    if (updateCheckoutSetting != null) {
      setShow(false)

      startLoading()

      updateCheckoutSetting.mutate(
        {
          checkoutSetting: checkoutSetting as CheckoutSettingUpdate
        },
        {
          onSuccess: () => {
            stopLoading()
            successShow(undefined, 'checkoutSetting.update.success')
            if (refetch != null) {
              void refetch()
            }
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }
  return (
    <Modal isOpen={show} setShowModal={setShow}>
      <IonHeader color='green-400'>
        <IonToolbar color='green-400'>
          <IonButtons slot='start'>
            <Button
              onClick={() => {
                setShow(false)
              }}
              color='dark'
            >
              Cancel
            </Button>
          </IonButtons>
          <IonButtons slot='end'>
            <Button strong onClick={handleSave}>
              Confirm
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding' scrollY>
        <TabCheckout section='select' />
        <IonRow className='ion-justify-content-center ion-align-items-center mx-auto'>
          <IonCol
            className='space-y-3 md:space-y-4 ion-no-padding md:ion-padding-vertical'
            size='12'
          >
            <SingleAvatarTipped
              name={checkoutSetting?.username}
              bio={checkoutSetting?.description}
              avatar={checkoutSetting?.avatar ?? DEFAULT_IMAGES.logoCompany}
              showLine={false}
            />
          </IonCol>
          <IonRow className='border-t border-stone-200 w-full h-5 my-8' />
          <IonRow className='w-full ion-justify-content-center ion-no-padding ion-no-margin'>
            <IonCol size='11'>
              <Text className='w-full text-center'>selectTeam</Text>
            </IonCol>
          </IonRow>
          <SlidesAvatarTeams jars={handleShowTeam()} />
        </IonRow>
      </IonContent>
    </Modal>
  )
}
