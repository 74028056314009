import { type StatisticFeedback } from '#tackpay-sdk'
import { IonCol, IonRow } from '@ionic/react'
import EmptyStatisticFeedback from './EmptyStatisticFeedback'
import FeedbackCard from './FeedbackCard'
import MostFeedbackReceived from './MostFeedbackReceived'
import BreakDown from './BreakDown'
import Text from '#components/ui/Text'
import isDesktop from '#utils/isDesktop'
import Card from '#components/ui/Card'

interface Props {
  statisticFeedback?: StatisticFeedback | null
  showBreakDown?: boolean
}
export default function StatisticFeedbackContent({
  statisticFeedback,
  showBreakDown = false
}: Props): JSX.Element {
  if (
    statisticFeedback?.feedbacks == null ||
    statisticFeedback.feedbacks.length === 0
  )
    return isDesktop() ? (
      <Card>
        <EmptyStatisticFeedback />
      </Card>
    ) : (
      <EmptyStatisticFeedback />
    )

  const breackDowns = [
    {
      value: 10,
      symbol: '%',
      description: 'From jar'
    },
    {
      value: 55,
      symbol: '%',
      description: 'From manager'
    },
    {
      value: 35,
      symbol: '%',
      description: 'From peers'
    }
  ]

  return !isDesktop() ? (
    <IonCol className='block ion-no-padding ion-no-margin'>
      <MostFeedbackReceived statisticFeedback={statisticFeedback} />
      <FeedbackCard statisticFeedback={statisticFeedback} />
      {showBreakDown && (
        <>
          <IonRow className='mt-9'>
            <Text color='gray-900' fontWeight='font-semibold'>
              Breakdown
            </Text>
          </IonRow>
          <IonRow className='ion-justify-content-between ion-align-items-center ion-no-padding ion-margin-vertical'>
            {breackDowns.map((breakDown, i) => (
              <IonCol key={i} className='ion-no-padding ion-no-margin'>
                <IonRow className='ion-justify-content-center ion-align-items-center w-full ion-no-padding ion-no-margin'>
                  <IonCol size='8' className='ion-no-padding ion-no-margin'>
                    <BreakDown
                      symbol={breakDown.symbol}
                      value={breakDown.value}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className='ion-no-padding ion-no-margin'>
                  <IonCol size='12' className='ion-no-padding ion-no-margin'>
                    <p className='text-center text-sm text-gray-500'>
                      {breakDown.description}
                    </p>
                  </IonCol>
                </IonRow>
              </IonCol>
            ))}
          </IonRow>
        </>
      )}
    </IonCol>
  ) : (
    <IonRow className='ion-justify-content-between ion-padding ion-no-margin w-full rounded-sm border border-gray-200 bg-statistic'>
      <IonCol size='12' className='ion-no-padding ion-no-margin'>
        <MostFeedbackReceived statisticFeedback={statisticFeedback} />
        <FeedbackCard statisticFeedback={statisticFeedback} />
      </IonCol>
    </IonRow>
  )
}
