import AppLayout from '#components/layouts/app/AppLayout'
import PaymentIdHandler from '#components/pages/payments/PaymentIdHandler'
import PaymentContainer from '#contexts/PaymentContext'
import { type WalletPaymentRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'

export default function PaymentId(): JSX.Element {
  const { category, paymentId } = useParams<WalletPaymentRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/wallets`
        }
      }}
    >
      <PaymentContainer
        paymentId={paymentId}
        params={{
          include: [
            'jar',
            'user.person',
            'member.user.person',
            'payment_method',
            'fee',
            'feedbacks',
            'comment',
            'deduction'
          ]
        }}
      >
        <PaymentIdHandler />
      </PaymentContainer>
    </AppLayout>
  )
}
