import Link from '#components/ui/Link'
import Text from '#components/ui/Text'
import { type UserCategory } from '#tackpay-sdk'
import { IonRow } from '@ionic/react'

interface Props {
  isFree: boolean
  category: UserCategory
}

export default function TeamUpdatePlanText({
  category,
  isFree
}: Props): JSX.Element {
  return isFree ? (
    <IonRow className='ion-text-center mt-48'>
      <Link link={`/dashboard/${category}/plans`} className='w-full'>
        <Text className='w-full underline'>updatePlan</Text>
      </Link>
    </IonRow>
  ) : (
    <></>
  )
}
