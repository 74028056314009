import { type TranslationOptions } from '#types/translate'
import { IonChip } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends ComponentProps<typeof IonChip> {
  /**
   * The translation options.
   */
  translationOptions?: TranslationOptions

  testId?: string
}
export default function Chip(props: Props): JSX.Element {
  const { children, translationOptions, testId, ...rest } = props

  const { t } = useTranslation()

  const text =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children

  return (
    <IonChip {...rest} data-testid={`chip-${testId as string}`}>
      {text}
    </IonChip>
  )
}
