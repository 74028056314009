import AppLayout from '#components/layouts/app/AppLayout'
import CheckoutSettingBrand from '#components/pages/checkoutSettings/CheckoutSettingBrand'
import CheckoutSettingButtonPreview from '#components/pages/checkoutSettings/CheckoutSettingButtonPreview'
import CheckoutSettingButtonSave from '#components/pages/checkoutSettings/CheckoutSettingButtonSave'
import CheckoutSettingMaterial from '#components/pages/checkoutSettings/CheckoutSettingMaterial'
import CheckoutSettingTeamSelection from '#components/pages/checkoutSettings/CheckoutSettingTeamSelection'
import Accordion from '#components/ui/Accordion'
import Label from '#components/ui/Label'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import CheckoutSettingContainer from '#contexts/CheckoutSettingContext'
import { useCompanyContext } from '#contexts/CompanyContext'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { type UserCategory } from '#tackpay-sdk'
import isCollaborator from '#utils/isCollaborator'
import { IonAccordionGroup, IonItem } from '@ionic/react'
import { useParams } from 'react-router'

interface Props {
  companyId?: string
  category: UserCategory
}
function HandleCheckoutSetting({ companyId, category }: Props): JSX.Element {
  const { company } = useCompanyContext()

  return (
    <CheckoutSettingContainer companyId={companyId}>
      <IonAccordionGroup multiple>
        <Accordion value='first' data-testid='accordion-brand'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              Brand
            </Label>
          </IonItem>
          <CheckoutSettingBrand isCompany />
        </Accordion>
        <Accordion value='second' data-testid='accordion-brand'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              checkoutSetting.selectTeam.title
            </Label>
          </IonItem>
          <CheckoutSettingTeamSelection jars={company?.jars ?? []} />
        </Accordion>
        <Accordion value='third' data-testid='accordion-brand'>
          <IonItem
            slot='header'
            lines='none'
            className='ion-no-padding'
            color='transparent'
          >
            <Label color='text-gray-900' className='italic'>
              checkoutSetting.material.title
            </Label>
          </IonItem>
          <CheckoutSettingMaterial />
        </Accordion>
        <CheckoutSettingButtonPreview
          category={category}
          isCompany
          companyId={companyId}
        />
        <CheckoutSettingButtonSave companyId={companyId} />
      </IonAccordionGroup>
    </CheckoutSettingContainer>
  )
}

export default function Company(): JSX.Element {
  const { companyId, category } = useParams<DashboardRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showMenuButton: true
      }}
      companyProps={{
        fetch: true,
        isCollaborator: isCollaborator(category),
        params: {
          include: ['jars', 'tackpay_id']
        }
      }}
      collaboratorProps={{
        fetch: false
      }}
    >
      <Title>companyPage.title</Title>
      <SubTitle>companyPage.subtitle</SubTitle>
      <HandleCheckoutSetting companyId={companyId} category={category} />
    </AppLayout>
  )
}
