import {
  ListResponse,
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  type ResourcesConfig,
  ApiResource,
  type ResourceId
} from 'sdk/resource'
import { type Company } from './companies'
import { type User } from './users'
import { type BankAccount } from './bankAccounts'
import { type BranchCompany } from './branchCompanies'
import { type CheckoutSetting } from './checkoutSettings'
import { type QueryParams, type QueryParamsRetrieve } from 'sdk/query'
import { type StripeGatewayAccount } from './stripeGatewayAccounts'
import { type TackpayId } from './tackpayIds'
import { type Balance } from './balances'
import { type Member } from './members'
import { type Payout } from './payouts'
import { type PayoutRetainFee } from './payoutRetainFees'
import { type Payment } from './payments'
import { type Collaborator } from './collaborators'
import { type CheckoutSession } from './checkoutSessions'
import { type StatisticView } from './statisticViews'
import { type StatisticBalance } from './statisticBalances'
import { type StatisticAmount } from './statisticAmounts'
import { type StatisticFeedback } from './statisticFeedbacks'
import { type AccountStatement } from './accountStatements'
import { type DeductionSetting } from './deductionSettings'
import { type BalanceDeduction } from './balanceDeductions'
import { type BankAccountDeduction } from './bankAccountDeductions'
import { denormalize } from 'sdk/jsonApi'

type JarType = 'jars'
type JarRel = ResourceRel & { type: JarType }

type TippingRule = 'equal_split' | 'tronc' | 'individual'
type JarCategory = 'private' | 'shared'

type CompanyRel = ResourceRel & { type: 'companies' }
type BranchRel = ResourceRel & { type: 'branch_companies' }
type BankAccountRel = ResourceRel & { type: 'bank_accounts' }
type StripeGatewayAccountRel = ResourceRel & { type: 'stripe_gateway_accounts' }

interface Jar extends Resource {
  readonly type: JarType
  name: string
  active: boolean
  description?: string
  jar_type: JarCategory
  avatar?: string
  total_member: number
  total_tip: number
  total_comment: number
  total_feedback: number
  category?: string
  tipping_rule: TippingRule
  currency: string
  company?: Company
  creator?: User
  stripe_gateway_account?: StripeGatewayAccount
  bank_account?: BankAccount
  branch_company?: BranchCompany
  checkout_setting?: CheckoutSetting
  tackpay_ids?: TackpayId[] | TackpayId
  balance?: Balance
  members?: Member[]
  payouts?: Payout[]
  payout_retain_fee?: PayoutRetainFee
  payments?: Payment[]
  collaborators?: Collaborator[]
  checkout_sessions?: CheckoutSession[]
  statistic_balance?: StatisticBalance
  statistic_view?: StatisticView
  statistic_amount?: StatisticAmount
  statistic_feedback?: StatisticFeedback
  account_statements?: AccountStatement[]
  deduction_setting?: DeductionSetting
  balance_deduction?: BalanceDeduction
  bank_account_deduction?: BankAccountDeduction
}

interface JarCreate extends ResourceCreate {
  name: string
  active?: boolean
  description?: string
  jar_type?: JarCategory
  avatar?: string
  category?: string
  tipping_rule: TippingRule
  currency?: string
  company: CompanyRel
  branch_company?: BranchRel
  bank_account?: BankAccountRel
  stripe_gateway_account?: StripeGatewayAccountRel
}

interface JarUpdate extends ResourceUpdate {
  name?: string
  active?: boolean
  description?: string
  avatar?: string
  category?: string
  tipping_rule?: TippingRule
}

class Jars extends ApiResource<Jar> {
  static readonly TYPE: JarType = 'jars'

  async create(
    resource: JarCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Jar> {
    return await this.resources.create<JarCreate, Jar>(
      { ...resource, type: Jars.TYPE },
      params,
      options
    )
  }

  async update(
    resource: JarUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Jar> {
    return await this.resources.update<JarUpdate, Jar>(
      { ...resource, type: Jars.TYPE },
      params,
      options
    )
  }

  async delete(
    id: string | ResourceId,
    options?: ResourcesConfig
  ): Promise<void> {
    await this.resources.delete(
      typeof id === 'string' ? { id, type: Jars.TYPE } : id,
      options
    )
  }

  async balance(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Balance> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<Balance>(
      { type: 'balances' },
      `jars/${_jarId}/balance`,
      params,
      options
    ) as unknown as Balance
  }

  async bank_account(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccount> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<BankAccount>(
      { type: 'bank_accounts' },
      `jars/${_jarId}/bank_account`,
      params,
      options
    ) as unknown as BankAccount
  }

  async bank_account_deduction(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<BankAccountDeduction> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<BankAccountDeduction>(
      { type: 'bank_account_deductions' },
      `jars/${_jarId}/bank_account_deduction`,
      params,
      options
    ) as unknown as BankAccountDeduction
  }

  async checkout_setting(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<CheckoutSetting> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<CheckoutSetting>(
      { type: 'checkout_settings' },
      `jars/${_jarId}/checkout_setting`,
      params,
      options
    ) as unknown as CheckoutSetting
  }

  async statistic_amount(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticAmount> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<StatisticAmount>(
      { type: 'statistic_amounts' },
      `jars/${_jarId}/statistic_amount`,
      params,
      options
    ) as unknown as StatisticAmount
  }

  async statistic_balance(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticBalance> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<StatisticBalance>(
      { type: 'statistic_balances' },
      `jars/${_jarId}/statistic_balance`,
      params,
      options
    ) as unknown as StatisticBalance
  }

  async statistic_feedback(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticFeedback> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<StatisticFeedback>(
      { type: 'statistic_feedbacks' },
      `jars/${_jarId}/statistic_feedback`,
      params,
      options
    ) as unknown as StatisticFeedback
  }

  async statistic_view(
    jarId: string | Jar,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<StatisticView> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<StatisticView>(
      { type: 'statistic_views' },
      `jars/${_jarId}/statistic_view`,
      params,
      options
    ) as unknown as StatisticView
  }

  async members(
    jarId: string | Jar,
    params?: QueryParams,
    options?: ResourcesConfig
  ): Promise<ListResponse<Member>> {
    const _jarId = (jarId as Jar).id ?? jarId
    return this.resources.fetch<Member>(
      { type: 'members' },
      `jars/${_jarId}/members`,
      params,
      options
    ) as unknown as ListResponse<Member>
  }

  async metrics_country_tips(jarId: string): Promise<{ countries: string[] }> {
    const client = this.resources.clientInstance
    return client.request('get', '/country_tips?jarId=' + jarId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { countries: string[] }
  }

  async metrics_members(jarId: string): Promise<Member[]> {
    const client = this.resources.clientInstance
    const res = (await client.request(
      'get',
      '/members_active?jarId=' + jarId,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/metrics',
        isJsonApi: false
      }
    )) as unknown as any
    const r = denormalize<Member>(res) as any[]
    const meta = {
      pageCount: Number(res.meta?.page_count),
      recordCount: Number(res.meta?.record_count),
      currentPage: 10,
      recordsPerPage: 20
    }

    return new ListResponse(meta, r)
  }

  async metrics_total_amount(jarId: string): Promise<{ amount: number }> {
    const client = this.resources.clientInstance
    return client.request(
      'get',
      '/total_amount_tips?jarId=' + jarId,
      undefined,
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        path: '/metrics',
        isJsonApi: false
      }
    ) as unknown as { amount: number }
  }

  async metrics_total_tips(jarId: string): Promise<{ occurrence: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/total_tips?jarId=' + jarId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { occurrence: number }
  }

  async metrics_avg_tips(jarId: string): Promise<{ avg_tip: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/avg_tips?jarId=' + jarId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { avg_tip: number }
  }

  async metrics_scans(jarId: string): Promise<{ occurrence: number }> {
    const client = this.resources.clientInstance
    return client.request('get', '/scans?jarId=' + jarId, undefined, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      path: '/metrics',
      isJsonApi: false
    }) as unknown as { occurrence: number }
  }

  isJar(resource: any): resource is Jar {
    return resource.type != null && resource.type === Jars.TYPE
  }

  relationship(id: string | ResourceId | null): JarRel {
    return id === null || typeof id === 'string'
      ? { id, type: Jars.TYPE }
      : { id: id.id, type: Jars.TYPE }
  }

  type(): JarType {
    return Jars.TYPE
  }
}

export type { Jar, JarCreate, JarUpdate, TippingRule, JarCategory, JarRel }
export default Jars
