import Avatar from '#components/ui/Avatar'
import Button from '#components/ui/Button'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useMemberContext } from '#contexts/MemberContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type User } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react'

interface Props {
  user: User
  setSearchResults: (value: User[]) => void
  setValue: (value: string) => void
  jarId: string
}
export default function SearchMemberCard({
  user,
  jarId,
  setSearchResults,
  setValue
}: Props): JSX.Element {
  const { createMember } = useMemberContext()

  const { startLoading, stopLoading } = useLoading('member.invite.loading')

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const handleInvite = (): void => {
    if (createMember != null) {
      startLoading()
      createMember.mutate(
        {
          invitation_type: 'notification',
          jar: {
            id: jarId,
            type: 'jars'
          },
          email: user.email,
          user: {
            id: user.id,
            type: 'users'
          }
        },
        {
          onSuccess: () => {
            stopLoading()
            setSearchResults([])
            setValue('')
            showSuccess('member.invite.success')
          },
          onError: (error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }
  return (
    <Card>
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCol size='3'>
            <Avatar
              avatarOptions={{
                className: 'w-14 h-14'
              }}
              imgOptions={{
                src: user.avatar ?? DEFAULT_IMAGES.avatarTipped,
                alt: user.email
              }}
            />
          </IonCol>
          <IonCol className='ion-align-items-center ion-no-padding'>
            <IonCardTitle className='text-sm' data-testid='card-jar-'>
              {user?.person?.first_name} {user?.person?.last_name}
            </IonCardTitle>
            <Text translate='no' className='capitalize'>
              {user.tackpay_id?.value ?? user.subcategory}
            </Text>
          </IonCol>
          <IonCol size='3' className='ion-justify-content-end'>
            <Button
              className='capitalize text-right cursor-pointer underline'
              size='small'
              fill='clear'
              color='green-600'
              onClick={handleInvite}
            >
              inviteLink
            </Button>
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
