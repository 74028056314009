import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import AddTeamCompanySection from '#components/pages/teams/add/AddTeamComapanySection'
import ProgressBar from '#components/ui/ProgessBar'

export default function AddTeamCompany(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add/roles`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
      companyProps={{
        params: {
          include: ['stripe_gateway_account']
        }
      }}
    >
      <ProgressBar value={0.5} text='teamPage.add.chip.company' />
      <AddTeamCompanySection category={category} />
    </AppLayout>
  )
}
