import { IonModal } from '@ionic/react'
import CustomAmountForm from './CustomAmountForm'
import CustomPercentageForm from './CustomPercentageForm'

export type TypeCustomModal = 'amount' | 'percentage'

interface Props {
  dataTip: TypeCustomModal
  openModal: boolean
  setOpenModal: (open: boolean) => void
  currency: string
  setCurrentAmount: (arg: number | undefined) => void
  currentAmount: number
  submitFunction: (e?: React.FormEvent<HTMLFormElement>) => void
}

export default function CustomModal({
  dataTip,
  openModal,
  currency,
  currentAmount,
  setOpenModal,
  setCurrentAmount,
  submitFunction
}: Props): JSX.Element {
  const eightModal = dataTip === 'amount' ? 0.4 : 0.9

  return (
    <IonModal
      id='custom'
      trigger='modal-custom'
      initialBreakpoint={eightModal}
      breakpoints={[0.5, 0.7, 0.9]}
      isOpen={openModal}
      onDidDismiss={() => {
        setOpenModal(false)
      }}
      className='custom'
    >
      {/* <IonContent> */}
      {dataTip === 'amount' && (
        <CustomAmountForm
          setOpenModal={setOpenModal}
          currency={currency}
          setCurrentAmount={setCurrentAmount}
          currentAmount={currentAmount}
          submitFunction={submitFunction}
        />
      )}
      {dataTip === 'percentage' && (
        <CustomPercentageForm
          setOpenModal={setOpenModal}
          currency={currency}
          currentAmount={currentAmount}
          setCurrentAmount={setCurrentAmount}
          submitFunction={submitFunction}
        />
      )}
    </IonModal>
  )
}
