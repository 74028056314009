import Text from '#components/ui/Text'
import { ListResponse, type UserCategory, type Transfer } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useEffect, useRef } from 'react'
import EmptyPayment from './EmptyPayment'
import { useInfiniteQuery } from '@tanstack/react-query'
import { animationsFade } from '#utils/animations'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import {
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react'
import PaymentCard from './PaymentCard'
import { type IonInfiniteScrollCustomEvent } from '@ionic/core'
import PaymentListTeamSection from './PaymentListTeamSection'
import { isDonation } from '#utils/isDonation'

interface Props {
  /**
   * The jar id to fetch payments.
   */
  jarId?: string

  userId?: string

  category: UserCategory

  isLoading: boolean

  errorContext?: any
  /**
   * If the internal jar page
   */
  isTeam?: boolean

  isTeamShared?: boolean
}
export default function PaymentListSection({
  jarId,
  userId,
  category,
  isLoading,
  errorContext,
  isTeam = false,
  isTeamShared = false
}: Props): JSX.Element {
  if (isTeamShared) {
    return (
      <PaymentListTeamSection
        jarId={jarId ?? ''}
        category={category}
        isLoading={isLoading}
        errorContext={errorContext}
        isTeam={isTeam}
      />
    )
  }
  const fetchTransfers = async ({
    pageParam = 1
  }): Promise<ListResponse<Transfer>> => {
    if (jarId != null) {
      return await sdk.transfers.list({
        include: ['payment', 'jar', 'member.user.person', 'user.person'],
        pageNumber: pageParam,
        filters: {
          eq: {
            jar_id: jarId
          }
        },
        sort: {
          created_at: 'desc'
        }
      })
    } else if (userId != null) {
      return await sdk.transfers.list({
        include: ['payment', 'jar', 'member.user.person', 'user.person'],
        pageNumber: pageParam,
        filters: {
          eq: {
            user_id: userId
          }
        },
        sort: {
          created_at: 'desc'
        }
      })
    }
    return new ListResponse(
      {
        currentPage: 1,
        pageCount: 0,
        recordCount: 0,
        recordsPerPage: 0
      },
      []
    )
  }
  const listTransfers = useRef<HTMLDivElement | null>(null)

  const { data, hasNextPage, fetchNextPage, status, isFetching, error } =
    useInfiniteQuery({
      queryKey: ['transfers', jarId, userId],
      queryFn: fetchTransfers,
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage() ? lastPage.getCurrentPage() + 1 : undefined
    })

  const loadNext = (e: IonInfiniteScrollCustomEvent<void>): void => {
    e.preventDefault()
    if (!hasNextPage) {
      void e.target.complete()
    } else {
      fetchNextPage()
        .then(() => {
          console.log('loaded')
          void e.target.complete()
        })
        .catch((err) => {
          console.log(err)
          void e.target.complete()
        })
    }
  }

  useEffect(() => {
    animationsFade({
      typeAnimation: listTransfers,
      start: '-45px',
      end: '0',
      duration: 500
    })
  }, [data])

  if (errorContext != null) return <ErrorBox error={errorContext} />

  return (
    <div className='mt-8'>
      <Text
        className=''
        color='gray-900'
        fontWeight='font-semibold'
        data-testid='label-listTip'
      >
        {!isDonation(userId) ? 'payments' : 'donations'}
      </Text>
      <IonCol>
        {status === 'pending' ? (
          <CardSkeleton hasAvatar />
        ) : status === 'error' ? (
          <ErrorBox error={error} />
        ) : data.pages != null && data.pages[0].length === 0 && !isFetching ? (
          <EmptyPayment isDonation={isDonation(userId)} />
        ) : (
          <div className='space-y-6'>
            {data.pages.map((page, i) => (
              <div key={i} className='space-y-6'>
                {page.map((transfer) => {
                  if (transfer != null) {
                    return (
                      <PaymentCard
                        key={transfer.id}
                        payment={transfer}
                        category={category}
                        isTeam={isTeam}
                        isTransfer={!isTeamShared}
                      />
                    )
                  }
                  return null
                })}
              </div>
            ))}
            <IonInfiniteScroll
              disabled={!hasNextPage}
              onIonInfinite={loadNext}
              threshold='100px'
            >
              <IonInfiniteScrollContent
                loadingSpinner='circles'
                loadingText='Loading...'
                color='primary'
              />
            </IonInfiniteScroll>
          </div>
        )}
      </IonCol>
    </div>
  )
}
