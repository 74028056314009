import CardCategory from '#components/common/CardCategory'
import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { EUROPEAN_COUNTRY_CODES } from '#constants/country'
import { useTeamContext } from '#contexts/JarContext'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type UserCategory, type TippingRule } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import { IonCol, IonHeader, IonRow } from '@ionic/react'
import { useState } from 'react'

interface Props {
  category: UserCategory
}
export default function TeamTippingRuleSection({
  category
}: Props): JSX.Element {
  const { user } = useUserContext()

  const { jar, isLoading, error, updateTeam } = useTeamContext()

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const { startLoading, stopLoading } = useLoading(
    'teamPage.tippingRule.loading'
  )

  const [showModal, setShowModal] = useState(false)

  if (isLoading)
    return (
      <div className='mt-10 space-y-6'>
        <CardSkeleton />
        <CardSkeleton />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (jar == null) return <ErrorBox error='teamPage.error.teamNotFound' />

  const handleRuleChange = (rule: TippingRule): void => {
    if (
      jar.tipping_rule === 'tronc' &&
      ((jar?.balance?.tronc?.pending.amount_cents ?? 0) > 0 ||
        (jar?.balance?.tronc?.available.amount_cents ?? 0) > 0)
    ) {
      setShowModal(true)
    } else {
      if (updateTeam != null) {
        startLoading()

        updateTeam.mutate(
          {
            id: jar?.id ?? '',
            tipping_rule: rule
          },
          {
            onSuccess: (data) => {
              stopLoading()
              showSuccess(undefined, 'teamPage.tippingRule.success')
              jar.tipping_rule = data.tipping_rule
            },
            onError: (error) => {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          }
        )
      }
    }
  }

  const isFullTippingRule =
    user?.person?.address != null
      ? EUROPEAN_COUNTRY_CODES.includes(
          user?.person?.address?.country_code.toUpperCase() as any
        )
      : false

  return (
    <IonCol className='block space-y-10'>
      <Modal
        isOpen={showModal}
        setShowModal={setShowModal}
        initialBreakpoint={0.65}
        breakpoints={[0.5, 0.75, 1]}
      >
        <div className='p-8'>
          <IonHeader>
            <Text
              color='gray-900'
              fontWeight='font-bold'
              className='ion-text-center'
            >
              teamPage.tippingRuleModal.title
            </Text>
          </IonHeader>
          <div className='mt-10 ion-text-center'>
            <Text>teamPage.tippingRuleModal.description</Text>
          </div>
          <IonRow className='ion-justify-content-between ion-align-items-centerspace-x-5 py-16'>
            <Button
              fill='clear'
              color='medium'
              onClick={() => {
                setShowModal(false)
              }}
            >
              cancel
            </Button>
            <Button
              routerLink={`/dashboard/${category}/jars/${jar.id}/settings/tronc`}
              routerDirection='root'
              onClick={() => {
                setShowModal(false)
              }}
            >
              confirm
            </Button>
          </IonRow>
        </div>
      </Modal>
      {isFullTippingRule && (
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleRuleChange('equal_split')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-equal.png',
              alt: 'tippingEqual'
            }
          }}
          title='equal_split'
          description='teamPage.equalSplit.description'
          showCheckIcon={jar?.tipping_rule === 'equal_split'}
        />
      )}
      <CardCategory
        cardProps={{
          onClick: (): void => {
            handleRuleChange('individual')
          }
        }}
        options={{
          imgOptions: {
            src: '/assets/img/tipping-individual.webp',
            alt: 'tippingIndividual'
          }
        }}
        title='individual'
        description='teamPage.individual.description'
        showCheckIcon={jar?.tipping_rule === 'individual'}
      />

      {isFullTippingRule && (
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleRuleChange('tronc')
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/tipping-individual.webp',
              alt: 'tippingTronc'
            }
          }}
          title='tronc'
          description='teamPage.tronc.description'
          showCheckIcon={jar?.tipping_rule === 'tronc'}
        />
      )}
    </IonCol>
  )
}
