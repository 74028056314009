import { useEffect, useState } from 'react'
import { type CheckoutSession } from '#tackpay-sdk'
import CheckoutSkeleton from './CheckoutSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import CheckoutCompany from './company/CheckoutCompany'
import CheckoutTipped from './tipped/CheckoutTipped'
import { useIonRouter } from '@ionic/react'
import getQueryParams from '#utils/getQueryParams'
import CheckoutTeam from './team/CheckoutTeam'
import sdk from '#utils/sdk'

interface HandleCheckoutProps {
  checkout?: CheckoutSession
  tackpayIdValue: string
}

const HandleCheckout = ({
  checkout,
  tackpayIdValue
}: HandleCheckoutProps): JSX.Element => {
  return (
    <>
      {checkout?.jar != null && (
        <CheckoutTeam
          jar={checkout.jar}
          tackpayIdValue={tackpayIdValue}
          checkout={checkout}
        />
      )}

      {checkout?.company != null && <CheckoutCompany checkout={checkout} />}
      {checkout?.user != null && (
        <CheckoutTipped checkout={checkout} tackpayIdValue={tackpayIdValue} />
      )}
    </>
  )
}

interface Props {
  tackpayIdValue: string
}
export default function CheckoutSection({
  tackpayIdValue
}: Props): JSX.Element {
  const { routeInfo } = useIonRouter()

  const [isLoading, setIsLoading] = useState(true)

  const [error, setError] = useState<any | undefined>()

  const [checkout, setCheckout] = useState<CheckoutSession | undefined>()

  const queryParams = getQueryParams(routeInfo.search)

  const handleCreateCheckout = (): void => {
    const referenceOrigin = queryParams.get('referenceOrigin')
    sdk.checkout_sessions
      .create(
        {
          reference_origin: (referenceOrigin as any) ?? 'qr_code',
          tackpay_id_value: tackpayIdValue
        },
        {
          include: [
            'user.checkout_setting',
            'jar.checkout_setting',
            'jar.creator.person.address',
            'jar.company',
            'company.checkout_setting',
            'company.jars.checkout_setting',
            'company.jars.tackpay_ids'
          ]
        }
      )
      .then((res) => {
        setCheckout(res)
        setIsLoading(false)
        setError(undefined)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }

  // useEffect(() => {
  //   if (checkout == null) {
  //     handleCreateCheckout()
  //   }
  // }, [checkout])

  useEffect(() => {
    handleCreateCheckout()
  }, [tackpayIdValue, routeInfo.lastPathname])

  if (isLoading) return <CheckoutSkeleton />

  if (error != null) return <ErrorBox error={error} />

  if (checkout == null)
    return (
      <ErrorBox
        error={{
          detail: 'Checkout not found',
          code: 'checkout_not_found',
          status: 404
        }}
      />
    )

  return <HandleCheckout checkout={checkout} tackpayIdValue={tackpayIdValue} />
}
