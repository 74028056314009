import { type CreateTeamValue } from '#hooks/useCreateTeamStore'
import {
  type Jar,
  type StripeGatewayAccount,
  type BankAccount,
  type JarCreate,
  type StripeGatewayAccountCreate,
  type BankAccountCreate,
  type Person,
  type Address,
  type BranchCompanyCreate,
  type BranchCompany,
  type AddressCreate
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  destroyPreferences,
  getPreferences,
  savePreferences
} from 'storage/preferences'
import { type PersonCreate } from 'sdk/resources/persons'
import {
  type UseMutationResult,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { useCompanyContext } from './CompanyContext'
import { useUserContext } from './UserContext'

type CreateTeamFunction = UseMutationResult<
  Jar,
  unknown,
  CreateTeamValue,
  unknown
>

interface CreateTeamContextValue {
  stripeGatewayAccount?: StripeGatewayAccount
  jar?: Jar
  bankAccount?: BankAccount
  person?: Person
  address?: Address
  clearCreateTeamStore?: () => void
  handleTeamCreate?: CreateTeamFunction
}

const createTeamInitialState: CreateTeamContextValue = {}

const CreateTeamContext = createContext<CreateTeamContextValue>(
  createTeamInitialState
)

export const useCreateTeamContext = (): CreateTeamContextValue => {
  const context = useContext(CreateTeamContext)
  if (context == null) {
    throw new Error(
      'useCreateTeamContext must be used within a CreateTeamContextProvider'
    )
  }
  return context
}

interface CreateTeamContainerProps {
  children: React.ReactNode
}

export default function CreateTeamContainer(
  props: CreateTeamContainerProps
): JSX.Element {
  const { children } = props

  const queryClient = useQueryClient()

  const { company } = useCompanyContext()

  const { user } = useUserContext()

  const [stripeGatewayAccount, setStripeGatewayAccount] =
    useState<StripeGatewayAccount>()

  const [jar, setTeam] = useState<Jar>()

  const [bankAccount, setBankAccount] = useState<BankAccount>()

  const [person, setPerson] = useState<Person>()

  const [address, setAddress] = useState<Address>()

  const [branchCompany, setBranchCompany] = useState<BranchCompany>()

  const stripeGatewayAccountKey = 'stripeGatewayAccount'

  const teamKey = 'jar'

  const bankKey = 'bank'

  const personKey = 'person'

  const addressKey = 'address'

  const branchKey = 'branch'

  const fetchFromStore = async (key: string): Promise<void> => {
    const value = (await getPreferences(key)) as any

    if (key === stripeGatewayAccountKey) {
      setStripeGatewayAccount(value)
    }

    if (key === teamKey) {
      setTeam(value)
    }

    if (key === bankKey) {
      setBankAccount(value)
    }

    if (key === personKey) {
      setPerson(value)
    }

    if (key === addressKey) {
      setAddress(value)
    }

    if (key === branchKey) {
      setBranchCompany(value)
    }
  }

  useEffect(() => {
    void fetchFromStore(stripeGatewayAccountKey)
    void fetchFromStore(teamKey)
    void fetchFromStore(bankKey)
    void fetchFromStore(personKey)
    void fetchFromStore(addressKey)
    void fetchFromStore(branchKey)
  }, [])

  const handleCreateTeam = async (teamCreate: JarCreate): Promise<Jar> => {
    if (jar != null) return jar

    if (teamCreate == null) throw new Error('teamCreate is required')

    let newTeam

    console.log('Companies', company)

    if (company != null) {
      let cmp
      if (Array.isArray(company) && company.length > 0) {
        cmp = company[0]
      } else {
        cmp = company
      }
      newTeam = await sdk.jars.create({
        ...teamCreate,
        company: {
          id: cmp.id,
          type: 'companies'
        },
        branch_company: teamCreate?.branch_company ?? undefined
      })
    } else {
      if (
        user != null &&
        (user.category === 'manager' || user.category === 'business')
      ) {
        const companyRet = await sdk.users.company(user?.id)
        newTeam = await sdk.jars.create({
          ...teamCreate,
          company: {
            id: companyRet.id,
            type: 'companies'
          },
          branch_company: teamCreate?.branch_company ?? undefined
        })
      }
    }

    if (newTeam == null) throw new Error('Company not found')

    setTeam(newTeam)

    await setToLocalStorage(teamKey, newTeam)

    return newTeam
  }

  const handleCreateBankAccount = async (
    currentTeam: Jar,
    bankAccountCreate: BankAccountCreate
  ): Promise<BankAccount> => {
    if (bankAccount != null) return bankAccount

    if (bankAccountCreate == null)
      throw new Error('bankAccountCreate is required')

    const newBankAccount = await sdk.bank_accounts.create({
      ...bankAccountCreate,
      account_holder_type: 'jar',
      jar: {
        id: currentTeam.id,
        type: 'jars'
      }
    })

    setBankAccount(newBankAccount)

    await setToLocalStorage(bankKey, newBankAccount)

    return newBankAccount
  }

  const handleCreateBranchCompany = async (
    BranchCompanyCreate: BranchCompanyCreate
  ): Promise<BranchCompany> => {
    if (branchCompany != null) return branchCompany
    const newBranch = await sdk.branch_companies.create(BranchCompanyCreate)
    setBranchCompany(newBranch)

    await setToLocalStorage(branchKey, newBranch)

    return newBranch
  }

  const handleCreatePerson = async (
    personCreate: PersonCreate
  ): Promise<Person> => {
    if (person != null) return person

    const newPerson = await sdk.persons.create(personCreate)

    setPerson(newPerson)

    await setToLocalStorage(personKey, newPerson)

    return newPerson
  }

  const handleCreateAddress = async (
    addressCreate: AddressCreate
  ): Promise<Address> => {
    if (address != null) return address

    const newAddress = await sdk.addresses.create(addressCreate)

    setAddress(newAddress)

    await setToLocalStorage(addressKey, newAddress)

    return newAddress
  }

  const handleCreateStripeGatewayAccount = async (
    stripeGatewayAccountCreate: StripeGatewayAccountCreate
  ): Promise<StripeGatewayAccount> => {
    console.log('handleCreateStripeGatewayAccount')
    if (stripeGatewayAccount != null) return stripeGatewayAccount

    const newStripeGatewayAccount = await sdk.stripe_gateway_accounts.create(
      stripeGatewayAccountCreate
    )

    setStripeGatewayAccount(newStripeGatewayAccount)

    await setToLocalStorage(stripeGatewayAccountKey, newStripeGatewayAccount)

    return newStripeGatewayAccount
  }

  const handleTeamCreate = async (props: CreateTeamValue): Promise<Jar> => {
    if (props.isSameCompany === false) {
      console.log('Create new company')
      const branchCompany = await handleCreateBranchCompany({
        name: props.teamCreate?.name ?? '',
        company: {
          id: company?.id ?? '',
          type: 'companies'
        }
      })

      const person = await handleCreatePerson({
        ...(props.personCreate as PersonCreate),
        branch_company: {
          id: branchCompany.id,
          type: 'branch_companies'
        }
      })

      await handleCreateAddress({
        ...(props.addressCreate as AddressCreate),
        person: {
          id: person.id,
          type: 'persons'
        }
      })

      const jar = await handleCreateTeam({
        ...(props.teamCreate as JarCreate),
        branch_company: {
          id: branchCompany.id,
          type: 'branch_companies'
        }
      })

      await handleCreateStripeGatewayAccount({
        ...(props?.stripeGatewayAccount as StripeGatewayAccountCreate),
        profile_type: 'individual',
        reference_category: 'jar',
        jar: {
          id: jar.id,
          type: 'jars'
        }
      })

      return jar
    }
    // Same company

    const jar = await handleCreateTeam({
      ...(props.teamCreate as JarCreate),
      stripe_gateway_account:
        props.stripeGatewayAccountId != null
          ? {
              id: props.stripeGatewayAccountId,
              type: 'stripe_gateway_accounts'
            }
          : undefined,
      bank_account:
        props.bankAccountId != null && props.isSameIban === true
          ? {
              id: props.bankAccountId,
              type: 'bank_accounts'
            }
          : undefined
    })

    if (props?.isSameIban === false) {
      await handleCreateBankAccount(jar, props.bankCreate as BankAccountCreate)
    }

    return jar
  }

  const useHandleCreateTeam = useMutation({
    mutationFn: handleTeamCreate,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jars'] })
      clearCreateTeamStore()
    },
    onError: (_err: any, _variables: any, context: any) => {
      queryClient.setQueryData(['jars'], context.jars)
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['jars']
      })
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
    }
  })

  const setToLocalStorage = async (key: string, data: any): Promise<void> => {
    await savePreferences(key, data)
  }

  const clearCreateTeamStore = (): void => {
    destroyPreferences(stripeGatewayAccountKey)
    destroyPreferences(teamKey)
    destroyPreferences(bankKey)
    destroyPreferences(personKey)
    destroyPreferences(addressKey)
    destroyPreferences(branchKey)
  }

  const createTeamContextValue: CreateTeamContextValue = {
    stripeGatewayAccount,
    jar,
    bankAccount,
    clearCreateTeamStore,
    handleTeamCreate: useHandleCreateTeam
  }

  return (
    <CreateTeamContext.Provider value={createTeamContextValue}>
      {children}
    </CreateTeamContext.Provider>
  )
}
