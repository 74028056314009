export const DEMO_USA_RESTAURANTS = [
  { img: '/assets/img/restaurant_usa.jpg', name: 'Ocean' },
  { img: '/assets/img/waiter1.avif', name: 'James' },
  { img: '/assets/img/waiter2.jpg', name: 'Emily' },
  { img: '/assets/img/waiter3.jpg', name: 'Jackson' },
  { img: '/assets/img/waiter4.jpg', name: 'Lucas' }
]

const DEMO_USA_BARBERS = [
  { img: '/assets/img/barbershop.jpg', name: 'Barber-Shop' },
  { img: '/assets/img/barber1.jpeg', name: 'Lucas' },
  { img: '/assets/img/barber2.jpg', name: 'Jackson' },
  { img: '/assets/img/barber3.jpg', name: 'James' },
  { img: '/assets/img/barber4.jpg', name: 'Benjamin' }
]

const DEMO_HOTELS = [{ img: '/assets/img/hotel.jpg', name: 'Cordoba' }]

const DEMO_BAR = [
  {
    img: '/assets/img/bar.jpg',
    name: 'Bar'
  }
]

export const DEMO_USA_PROFILES: Record<
  string,
  Array<{ img: string; name: string }>
> = {
  restaurant: DEMO_USA_RESTAURANTS,
  barber: DEMO_USA_BARBERS,
  hotel: DEMO_HOTELS,
  bar: DEMO_BAR
}
