import MemberContainer from '#contexts/MemberContext'
import TokenContainer from '#contexts/TokenContext'
import useInviteMember from '#hooks/useInviteMember'
import MemberInviteModal from './MemberInviteModal'

export default function MemberInviteHandle(): JSX.Element {
  const { token } = useInviteMember()

  return (
    <TokenContainer
      fetch={token != null}
      value={token}
      params={{
        include: ['jar.company', 'company']
      }}
    >
      <MemberContainer fetch={false}>
        <MemberInviteModal />
      </MemberContainer>
    </TokenContainer>
  )
}
