import {
  type CheckoutSessionCreate,
  type CheckoutSession,
  type QueryParamsRetrieve
} from '#tackpay-sdk'
import sdk from '#utils/sdk'
import {
  type UseMutationResult,
  useMutation,
  useQuery,
  type RefetchOptions,
  type RefetchQueryFilters,
  type QueryObserverResult
} from '@tanstack/react-query'
import { createContext, useContext } from 'react'

type CheckoutSessionCreateProps = CheckoutSessionCreate & {
  params?: QueryParamsRetrieve
}

type CreateCheckoutFunction = UseMutationResult<
  CheckoutSession,
  unknown,
  CheckoutSessionCreateProps,
  unknown
>

interface CheckoutContextValue {
  checkout?: CheckoutSession
  isLoading: boolean
  error?: any
  createCheckout?: CreateCheckoutFunction
  refetch?: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<CheckoutSession, unknown>>
}

const checkoutInitialContext: CheckoutContextValue = {
  isLoading: false,
  error: undefined,
  checkout: undefined
}

const CheckoutContext = createContext<CheckoutContextValue>(
  checkoutInitialContext
)

export const useCheckoutContext = (): CheckoutContextValue => {
  const context = useContext(CheckoutContext)
  if (context == null) {
    throw new Error('useCheckoutContext must be used within a CheckoutProvider')
  }

  return context
}

interface CheckoutContainerProps {
  children?: React.ReactNode
  id?: string
  params?: QueryParamsRetrieve
  /** @default true */
  fetch?: boolean
}

const handleCreateCheckout = async (
  createCheckout: CheckoutSessionCreateProps
): Promise<CheckoutSession> => {
  const { params, ...rest } = createCheckout
  return await sdk.checkout_sessions.create(rest, params)
}

export default function CheckoutContainer(
  props: CheckoutContainerProps
): JSX.Element {
  const { children, fetch = true, id, params } = props

  // const queryClient = useQueryClient()

  // const [initialFetchComplete, setInitialFetchComplete] = useState(false)

  const useCreateCheckout = useMutation({
    // mutationKey: ['create-checkout'],
    mutationFn: handleCreateCheckout
  })

  const {
    data: checkout,
    isLoading,
    error,
    refetch
  } = useQuery<CheckoutSession>({
    queryKey: ['checkout', id, params],
    queryFn: async () => {
      return await sdk.checkout_sessions.retrieve(id ?? '', params)
    },
    enabled: fetch,
    refetchInterval: 0
  })

  // useEffect(() => {
  //   if (fetch) {
  //     void queryClient.invalidateQueries({ queryKey: ['checkout'] })
  //     setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
  //   }
  // }, [fetch, queryClient])

  const checkoutContextValue: CheckoutContextValue = {
    checkout,
    isLoading,
    error,
    createCheckout: useCreateCheckout,
    refetch
  }

  return (
    <CheckoutContext.Provider value={checkoutContextValue}>
      {children}
    </CheckoutContext.Provider>
  )
}
