import AuthLayout from '#components/layouts/auth/AuthLayout'
import { FEEDBACKS } from '#constants/feedbacks'
import { useState } from 'react'
import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import Button from '#components/ui/Button'
import Input, { type InputOnChange } from '#components/ui/Input'
import InputTextArea, {
  type InputTextAreaOnChange
} from '#components/ui/InputTextArea'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import Avatar from '#components/ui/Avatar'
import { Swiper, SwiperSlide } from 'swiper/react'
import Chip from '#components/ui/Chip'
import TabCheckout from '#components/pages/checkouts/TabCheckout'
import { useParams } from 'react-router'
import { DEMO_USA_PROFILES } from '#constants/demoUsa'

export default function DemoReviewUsa(): JSX.Element {
  const { category } = useParams<{ category: string }>()

  const profiles = DEMO_USA_PROFILES[category]

  let feedbacks = FEEDBACKS.filter((feed) => feed.subcategory === category)
    .map((feed) => feed.feedback)
    .flat()

  if (feedbacks.length === 0) {
    feedbacks = FEEDBACKS.filter((feed) => feed.subcategory === 'other')
      .map((feed) => feed.feedback)
      .flat()
  }

  const [comment, setComment] = useState('')

  const [customerName, setCustomerName] = useState<string | undefined>()

  const [feedbackSelected, setFeedbackSelected] = useState<string[]>([])

  const { push } = useIonRouter()

  const handleFeedbackSelected = (feedback: string): void => {
    if (feedbackSelected.includes(feedback)) {
      setFeedbackSelected(feedbackSelected.filter((feed) => feed !== feedback))
    } else {
      setFeedbackSelected([...feedbackSelected, feedback])
    }
  }

  const handleComment: InputTextAreaOnChange = (e) => {
    const { value } = e.target
    if (typeof value === 'string') setComment(value)
  }

  const customerNameChange: InputOnChange = (e) => {
    const value = e.target.value
    if (typeof value === 'string') setCustomerName(value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    push(`/demo/usa/${category}/end`, 'root')
  }

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true,
        isUsa: true
      }}
    >
      <TabCheckout section='review' />
      <form onSubmit={handleSubmit}>
        <IonRow className='ion-justify-content-center ion-align-items-center ion-no-padding ion-no-margin'>
          <IonCol>
            <Avatar
              avatarOptions={{
                className: ' border-4 mx-auto h-28 w-28 border-green-400'
              }}
              imgOptions={{
                src: profiles[0]?.img ?? '/assets/img/pool.svg'
              }}
            />
            <Underlined name={profiles[0]?.name ?? 'Pool'} />
          </IonCol>
        </IonRow>
        <Text fontWeight='font-semibold' className='mt-10 '>
          checkout.leaveFeedbackTeam
        </Text>
        <IonRow className='mt-5'>
          <Swiper spaceBetween={0} slidesPerView={3}>
            {feedbacks.map((feedback, i) => {
              if (i < 8) {
                const isActive = feedbackSelected.includes(feedback)
                return (
                  <SwiperSlide key={feedback}>
                    <Chip
                      color={isActive ? 'green-500' : 'white'}
                      onClick={() => {
                        handleFeedbackSelected(feedback)
                      }}
                      className={`shadow ${isActive ? '' : 'text-gray-900 '}`}
                    >
                      {`feedback.${feedback}`}
                    </Chip>
                  </SwiperSlide>
                )
              } else {
                return null
              }
            })}
          </Swiper>
        </IonRow>
        <IonRow className='mt-5'>
          <Swiper spaceBetween={0} slidesPerView={3}>
            {feedbacks.map((feedback, i) => {
              if (i >= 8) {
                const isActive = feedbackSelected.includes(feedback)
                return (
                  <SwiperSlide key={feedback}>
                    <Chip
                      color={isActive ? 'green-500' : 'white'}
                      onClick={() => {
                        handleFeedbackSelected(feedback)
                      }}
                      className={`shadow ${isActive ? '' : 'text-gray-900 '}`}
                    >
                      {`feedback.${feedback}`}
                    </Chip>
                  </SwiperSlide>
                )
              } else {
                return null
              }
            })}
          </Swiper>
        </IonRow>
        <IonRow>
          <IonCol>
            <Text fontWeight='font-semibold' className='mt-10 ' size='text-sm'>
              checkout.leaveComment
            </Text>
            <InputTextArea
              name='feedback'
              rows={8}
              onIonInput={handleComment}
              value={comment}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <Input
            labelOptions={{
              children: 'checkout.leaveName',
              capitalize: false,
              className: 'font-semibold mb-5 text-leaveName'
            }}
            value={customerName}
            onIonInput={customerNameChange}
            name='customerName'
          />
        </IonRow>
        <IonRow className='mt-10 w-full mx-0 ion-no-padding'>
          <Button type='submit' className='w-full ion-no-margin'>
            continue
          </Button>
        </IonRow>
      </form>
    </AuthLayout>
  )
}
