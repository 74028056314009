import { type TranslationOptions } from '#types/translate'
import { IonButton, IonIcon } from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import Text from './Text'

interface ButtonProps extends ComponentProps<typeof IonButton> {
  /**
   * Translation options.
   */
  translationOptions?: TranslationOptions
  /**
   * The icon options.
   */
  iconOptions?: ComponentProps<typeof IonIcon>

  textOptions?: Omit<ComponentProps<typeof Text>, 'children'>
}
export default function Button(props: ButtonProps): JSX.Element {
  const {
    translationOptions,
    children,
    iconOptions,
    textOptions,
    ...ionButtonProps
  } = props

  const { t } = useTranslation()

  const text =
    (typeof translationOptions === 'undefined' ||
      translationOptions.translate === 'yes') &&
    typeof children === 'string'
      ? t(children, { ...translationOptions?.data })
      : children

  return (
    <IonButton
      {...ionButtonProps}
      strong={props?.strong ?? true}
      routerDirection='root'
    >
      {ionButtonProps.fill === 'clear' ? (
        <Text {...textOptions} className='' color={ionButtonProps.color}>
          {text}
        </Text>
      ) : (
        text
      )}

      {iconOptions != null && <IonIcon {...iconOptions} />}
    </IonButton>
  )
}
