/* eslint-disable prettier/prettier */
import ButtonStep from '#components/common/ButtonStep'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Input, { type InputOnChange } from '#components/ui/Input'
import { InputDate } from '#components/ui/InputDate'
import { type MaybeDate } from '#components/ui/InputDate/InputDateComponent'
import { useUserContext } from '#contexts/UserContext'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import useToast from '#hooks/useToast'
import { type Gender, type UserCategory } from '#tackpay-sdk'
import { adapterModal } from '#utils/adapter'
import birthdateValidate from '#utils/birthdateValidator'
import { isGender } from '#utils/isGenger'
import textUtil from '#utils/textUtil'
import {
  IonCol,
  IonRow,
  useIonRouter
} from '@ionic/react'
import { useEffect, useState } from 'react'

interface Props {
  category: UserCategory
}
export default function AddTeamPersonalDataForm({
  category
}: Props): JSX.Element {
  const { user } = useUserContext()

  const {
    setPersonCreate,
    personCreate,
    stripeGatewayAccount,
    setStripeGatewayAccount
  } = useCreateTeamStore()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const defaultDate = new Date()

  defaultDate.setFullYear(defaultDate.getFullYear() - 18)

  const [hasSsn, setHasSsn] = useState<boolean>(false)

  const handleInputChanged: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'firstName') {
        setPersonCreate({ first_name: value })
      }

      if (name === 'lastName') {
        setPersonCreate({ last_name: value })
      }

      if (name === 'ssn') {
        setStripeGatewayAccount({
          ssn: value
        })
      }
    }
  }

  const handleBirthdateChange = (e:  MaybeDate): void => {
    const dayNumber = e?.getDate() ?? 0
    const monthNumber = e?.getMonth() ?? 0 + 1
    const yearNumber = e?.getFullYear() ?? 0
    setPersonCreate({
      birthdate: {
        day: dayNumber,
        month: monthNumber,
        year: yearNumber
      }
    })
  }

  const handleGender: SelectOnChangeEvent = (event) => {
    const gender = event.target.value as Gender
    setPersonCreate({ gender })
  }


  const validateStep = (): boolean => {
    const invalid = false
    if (textUtil.isEmpty(personCreate?.first_name)) {
      showToast(undefined, 'registration.firstNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personCreate?.last_name)) {
      showToast(undefined, 'registration.lastNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personCreate?.gender)) {
      showToast(undefined, 'registration.genderRequired')
      return invalid
    }

    if (typeof personCreate?.birthdate === 'undefined') {
      showToast(undefined, 'registration.birthdateRequired')
      return invalid
    }

    if (!isGender(personCreate?.gender)) {
      showToast(undefined, 'registration.genderInvalid')
      return invalid
    }

    if (typeof personCreate?.birthdate !== 'undefined') {
      const validateDay = birthdateValidate.isValidDay(
        personCreate?.birthdate.day
      )
      if (!validateDay) {
        showToast(undefined, 'registration.birthdateInvalidDay')
        return invalid
      }

      const validateMonth = birthdateValidate.isValidMonth(
        personCreate?.birthdate.month
      )

      if (!validateMonth) {
        showToast(undefined, 'registration.birthdateInvalidMonth')
        return invalid
      }

      const validateYear = birthdateValidate.isValidYear(
        personCreate?.birthdate.year
      )

      if (!validateYear) {
        showToast(undefined, 'registration.birthdateInvalidYear')
        return invalid
      }

      const { day, month, year } = personCreate?.birthdate

      const validateAge = birthdateValidate.isOfLegalAge(day, month, year)

      if (!validateAge) {
        showToast(undefined, 'registration.birthdateInvalidAge')
        return invalid
      }
    }

    return !invalid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const isValid = validateStep()

    if (isValid) {
      push(`/dashboard/${category}/jars/add/addresses`)
    }
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add/companies`, 'back')
  }

  useEffect(() => {
    if (user?.person?.address != null) {
      setHasSsn(user?.person?.address.country_code === 'US')
    }
  }, [user])

  const getBirthDate = (): MaybeDate => {
    if (personCreate?.birthdate != null) {
      const { day, month, year } = personCreate?.birthdate
      return new Date(year, month - 1, day)
    }
    return null
  }


  return (
    <form onSubmit={handleSubmit} className='space-y-5'>
      <div className='mt-5'>
        <Input
          name='firstName'
          type='text'
          value={personCreate?.first_name}
          onIonInput={handleInputChanged}
          labelOptions={{
            children: 'firstName'
          }}
          testId='firstName'
        />
      </div>
      <div>
        <Input
          name='lastName'
          type='text'
          value={personCreate?.last_name}
          onIonInput={handleInputChanged}
          labelOptions={{ children: 'lastName' }}
          testId='lastName'
        />
      </div>

      {hasSsn && (
        <div>
          <Input
            type='text'
            name='ssn'
            value={stripeGatewayAccount?.ssn}
            onIonInput={handleInputChanged}
            labelOptions={{
              children: 'ssn',
              capitalize: false
            }}
            helperText='ssnHelperText'
            testId='ssn'
          />
        </div>
      )}

      <IonRow className='ion-align-items-center ion-justify-content-between ion-no-padding ion-no-margin'>
        <IonCol
          size='5.4'
          id='open-modal'
          className='ion-no-padding ion-no-margin'
        >
          <InputDate label='birthdate' value={getBirthDate()} onChange={handleBirthdateChange} capitalize={false} />
        </IonCol>
        <IonCol size='5.4' className='ion-no-padding ion-no-margin'>
          <BasicSelect
            options={{
              childrens: ['male', 'female']
            }}
            interface={adapterModal}
            placeholder='Gender'
            labelOptions={{
              show: true,
              options: {
                children: 'gender'
              }
            }}
            onIonChange={handleGender}
            value={personCreate?.gender}
          />
        </IonCol>
      </IonRow>
      <div className='pt-5'>
        <ButtonStep nextType='submit' handleBack={handleBack} />
      </div>
    </form>
  )
}
