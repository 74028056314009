import Empty from '#components/ui/Empty'

export default function EmptyComment(): JSX.Element {
  return (
    <Empty
      className='pt-6'
      imgOptions={{
        src: '/assets/img/empty-comment.svg',
        alt: 'emptyComment',
        className: 'w-1/2 mx-auto md:w-3/12'
      }}
      textOptions={{
        text: 'feedbackPage.empty',
        props: {
          className:
            'text-center text-base italic text-gray-600 mt-10 font-semibold'
        }
      }}
    />
  )
}
