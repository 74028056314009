import Empty from '#components/ui/Empty'

export default function EmptyBank(): JSX.Element {
  return (
    <Empty
      className='space-y-3'
      imgOptions={{
        src: '/assets/img/empty-bank.svg',
        alt: 'emptyFeedback',
        className: 'w-1/3 md:w-3/12 mx-auto'
      }}
      textOptions={{
        text: 'bank.empty',
        props: {
          className: 'text-center text-base italic text-gray-600 font-semibold'
        }
      }}
    />
  )
}
