import { type TippingRule, type JarCategory } from '#tackpay-sdk'
import {
  analytics,
  qrCodeOutline,
  swapHorizontalOutline,
  cashOutline,
  medalOutline,
  analyticsOutline,
  receiptOutline,
  sparklesOutline,
  shuffleOutline,
  personOutline,
  imageOutline
} from 'ionicons/icons'

interface TeamSettings {
  model: JarCategory
  label: string
  icon: string
  href: string
  tippingRule: TippingRule[]
}

export const getTeamSetting = (URL: string): TeamSettings[] => {
  return [
    /** Private */
    {
      model: 'private',
      label: 'teamSetting.checkoutSetting',
      icon: imageOutline,
      href: `${URL}/checkout-settings`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.material',
      icon: qrCodeOutline,
      href: `${URL}/materials`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.payments',
      icon: swapHorizontalOutline,
      href: `${URL}/payments`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.payout',
      icon: cashOutline,
      href: `${URL}/withdrawals`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'deduction.side_menu',
      icon: cashOutline,
      href: `${URL}/deductions`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.feedback',
      icon: medalOutline,
      href: `${URL}/feedbacks`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.statistics',
      icon: analyticsOutline,
      href: `${URL}/statistics`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.identity',
      icon: receiptOutline,
      href: `${URL}/identities`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'private',
      label: 'teamSetting.accountStatement',
      icon: receiptOutline,
      href: `${URL}/account-statements`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },

    /** Shared */
    {
      model: 'shared',
      label: 'teamSetting.checkoutSetting',
      icon: imageOutline,
      href: `${URL}/checkout-settings`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'members.title',
      icon: personOutline,
      href: `${URL.replace('/settings', '')}/members`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.tippingRule',
      icon: sparklesOutline,
      href: `${URL}/tipping-rules`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.tronc',
      icon: shuffleOutline,
      href: `${URL}/tronc`,
      tippingRule: ['tronc']
    },
    {
      model: 'shared',
      label: 'deduction.side_menu',
      icon: cashOutline,
      href: `${URL}/deductions`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.material',
      icon: qrCodeOutline,
      href: `${URL}/materials`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.payments',
      icon: swapHorizontalOutline,
      href: `${URL}/payments`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.feedback',
      icon: medalOutline,
      href: `${URL}/feedbacks`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'teamSetting.statistics',
      icon: analytics,
      href: `${URL}/statistics`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'setting_dashboard.account_statement',
      icon: receiptOutline,
      href: `${URL}/account-statements`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    },
    {
      model: 'shared',
      label: 'setting_dashboard.account_statement_member',
      icon: receiptOutline,
      href: `${URL}/account-statements-members`,
      tippingRule: ['individual', 'equal_split', 'tronc']
    }
  ]
}
