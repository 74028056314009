import type { Resource /* ResourceRel */ } from './resource'

export { default as Users } from './resources/users'
export { default as Persons } from './resources/persons'
export { default as Addresses } from './resources/addresses'
export { default as Balances } from './resources/balances'
export { default as BankAccounts } from './resources/bankAccounts'
export { default as Companies } from './resources/companies'
export { default as BranchCompanies } from './resources/branchCompanies'
export { default as CheckoutSettings } from './resources/checkoutSettings'
export { default as LinksSocials } from './resources/linksSocials'
export { default as Replies } from './resources/replies'
export { default as Collaborators } from './resources/collaborators'
export { default as Jars } from './resources/jars'
export { default as ContactUss } from './resources/contactUs'
export { default as StripeGatewayAccounts } from './resources/stripeGatewayAccounts'
export { default as StripeGatewayIdentities } from './resources/stripeGatewayIdentities'
export { default as Identities } from './resources/identities'
export { default as TackpayIds } from './resources/tackpayIds'
export { default as Materials } from './resources/materials'
export { default as Customers } from './resources/customers'
export { default as Members } from './resources/members'
export { default as Subscribers } from './resources/subscribers'
export { default as Subscriptions } from './resources/subscriptions'
export { default as SubscriptionItems } from './resources/subscriptionItems'
export { default as SubscriptionLinks } from './resources/subscriptionLinks'
export { default as Prices } from './resources/prices'
export { default as ProductLinks } from './resources/productLinks'
export { default as Tokens } from './resources/tokens'
export { default as Payouts } from './resources/payouts'
export { default as PayoutRetainFees } from './resources/payoutRetainFees'
export { default as Payments } from './resources/payments'
export { default as PaymentMethods } from './resources/paymentMethods'
export { default as Fees } from './resources/fees'
export { default as Feedbacks } from './resources/feedbacks'
export { default as Comments } from './resources/comments'
export { default as Invoices } from './resources/invoices'
export { default as Transfers } from './resources/transfers'
export { default as BalanceTransactions } from './resources/balanceTransactions'
export { default as CheckoutSessions } from './resources/checkoutSessions'
export { default as StatisticBalances } from './resources/statisticBalances'
export { default as StatisticViews } from './resources/statisticViews'
export { default as StatisticAmounts } from './resources/statisticAmounts'
export { default as StatisticFeedbacks } from './resources/statisticFeedbacks'
export { default as AccountStatements } from './resources/accountStatements'
export { default as DeductionSettings } from './resources/deductionSettings'
export { default as BalanceDeductions } from './resources/balanceDeductions'
export { default as BankAccountDeductions } from './resources/bankAccountDeductions'
export { default as DeductionReports } from './resources/deductionReports'
export { default as Deductions } from './resources/deductions'

export const resourceList = [
  'account_statements',
  'users',
  'users',
  'persons',
  'addresses',
  'balances',
  'bank_accounts',
  'branch_companies',
  'companies',
  'collaborators',
  'contact_us',
  'checkout_settings',
  'checkout_sessions',
  'links_socials',
  'replies',
  'jars',
  'stripe_gateway_accounts',
  'stripe_gateway_identities',
  'subscriptions',
  'subscription_items',
  'subscribers',
  'subscription_links',
  'product_links',
  'identities',
  'customers',
  'payments',
  'payouts',
  'members',
  'materials',
  'tackpay_ids',
  'prices',
  'tokens',
  'payout_retain_fees',
  'payment_methods',
  'fees',
  'feedbacks',
  'comments',
  'invoices',
  'transfers',
  'balance_transactions',
  'statistic_balances',
  'statistic_views',
  'statistic_amounts',
  'statistic_feedbacks',
  'deduction_settings',
  'balance_deductions',
  'bank_account_deductions',
  'deduction_reports',
  'deductions'
] as const

export type ResourceTypeLock = (typeof resourceList)[number]

export type VersionableResource = Resource & {
  //   type: VersionableResourceType
  //   versions?: Array<ResourceRel & { type: VersionType }> | null
}
