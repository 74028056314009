import {
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId
} from 'sdk/resource'
import type Stripe from 'stripe'
import { type Customer } from './customers'
import { type SubscriptionItem } from './subscriptionItems'

type SubscriptionType = 'subscriptions'
type SubscriptionRel = ResourceRel & { type: SubscriptionType }

type SubscriptionStatus = Stripe.Subscription.Status

interface SubscriptionPendingUpdate {
  /**
   * f the update is applied, determines the date of the first full invoice, and, for plans with month or year intervals, the day of the month for subsequent invoices.
   * The timestamp is in UTC format.
   */
  billing_cycle_anchor?: number
  /**
   * The point after which the changes reflected by this update will be discarded and no longer applied.
   */
  expires_at?: number
  /**
   * Unix timestamp representing the end of the trial period the customer will get before being charged for the first time, if the update is applied
   */
  trial_end?: number
  /**
   * The subscription items to which the update will be applied.
   */
  items?: Array<Partial<any>>
}

interface Subscription extends Resource {
  readonly type: SubscriptionType

  status: SubscriptionStatus
  reference: string
  reference_origin: string
  payment_method_reference?: string
  payment_method_reference_origin?: string
  currency: string
  description?: string
  pending_update?: SubscriptionPendingUpdate
  day_until_due?: number
  default_source?: string
  current_period_end?: number
  current_period_start?: number
  customer?: Customer
  subscription_items?: SubscriptionItem[]
}

class Subscriptions extends ApiResource<Subscription> {
  static readonly TYPE: SubscriptionType = 'subscriptions' as const

  isSubscription(resource: any): resource is Subscription {
    return resource.type != null && resource.type === Subscriptions.TYPE
  }

  relationship(id: string | ResourceId | null): SubscriptionRel {
    return id === null || typeof id === 'string'
      ? { id, type: Subscriptions.TYPE }
      : { id: id.id, type: Subscriptions.TYPE }
  }

  type(): SubscriptionType {
    return Subscriptions.TYPE
  }
}

export type { Subscription }
export default Subscriptions
