import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourcesConfig,
  type ResourceId,
  type ResourceUpdate
} from 'sdk/resource'
import { type User } from './users'
import { type Jar } from './jars'
import { type Member } from './members'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type PaymentMethod } from './paymentMethods'
import { type Fee } from './fees'
import { type Feedback } from './feedbacks'
import { type Invoice } from './invoices'
import { type Transfer } from './transfers'
import { type CheckoutSession } from './checkoutSessions'
import { type Comment } from './comments'
import { type Deduction } from './deductions'

type PaymentType = 'payments'
type PaymentRel = ResourceRel & { type: PaymentType }

type PaymentStatus =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_capture'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded'

type PaymentRole = 'equal_split' | 'individual' | 'tronc'
type PaymentReviewReference = 'tipped' | 'jar' | 'single_member' | 'all_member'
type PaymentCategory = 'jar' | 'tipped' | 'member' | 'subscription' | 'kit'

type UserRel = ResourceRel & { type: 'users' }
type JarRel = ResourceRel & { type: 'jars' }
type CheckoutSessionRel = ResourceRel & { type: 'checkout_sessions' }
type MemberRel = ResourceRel & { type: 'members' }

interface Payment extends Resource {
  readonly type: PaymentType

  amount_cents: number
  amount_float: number
  amount_formatted: string
  gross_amount_cents: number
  currency: string
  reference: string
  reference_origin: string
  transfer_group?: string
  description?: string
  client_secret_reference?: string
  split_divide: number
  status: PaymentStatus
  role: PaymentRole
  reference_review: PaymentReviewReference
  category: PaymentCategory
  user?: User
  jar?: Jar
  member?: Member
  payment_method?: PaymentMethod
  fee?: Fee
  feedbacks?: Feedback[]
  comment?: Comment
  invoice?: Invoice
  transfers?: Transfer[]
  checkout_session?: CheckoutSession
  deduction?: Deduction
}

interface PaymentCreate extends ResourceCreate {
  amount: number
  currency: string
  role: PaymentRole
  category: PaymentCategory
  reference_review: PaymentReviewReference
  checkout_session?: CheckoutSessionRel
  user?: UserRel
  jar?: JarRel
  member?: MemberRel
}

interface PaymentUpdate extends ResourceUpdate {
  reference: string
}

class Payments extends ApiResource<Payment> {
  static readonly TYPE: PaymentType = 'payments' as const

  async create(
    resource: PaymentCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Payment> {
    return await this.resources.create<PaymentCreate, Payment>(
      {
        ...resource,
        type: Payments.TYPE
      },
      params,
      options
    )
  }

  async update(
    resource: PaymentUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Payment> {
    return await this.resources.update<PaymentUpdate, Payment>(
      {
        ...resource,
        type: Payments.TYPE
      },
      params,
      options
    )
  }

  isPayment(resource: any): resource is Payment {
    return resource.type != null && resource.type === Payments.TYPE
  }

  relationship(id: string | ResourceId | null): PaymentRel {
    return id === null || typeof id === 'string'
      ? { id, type: Payments.TYPE }
      : { id: id.id, type: Payments.TYPE }
  }

  type(): PaymentType {
    return Payments.TYPE
  }
}

export type { Payment, PaymentCreate }
export default Payments
