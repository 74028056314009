import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type TeamRoutesParams } from '../../../TeamRoutes'
import ProgressBar from '#components/ui/ProgessBar'
import AddTeamPersonalDataForm from '#components/pages/teams/add/AddPersonalDataForm'

export default function AddTeamPersonData(): JSX.Element {
  const { category } = useParams<TeamRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars/add/companies`
        }
      }}
      userProps={{
        fetch: true,
        params: {
          include: ['person.address']
        }
      }}
    >
      <ProgressBar
        value={0.6}
        text='teamPage.add.chip.stripeGatewayAccount.title'
        description='teamPage.add.chip.stripeGatewayAccount.description'
        showDescription
      />
      <AddTeamPersonalDataForm category={category} />
    </AppLayout>
  )
}
