import ButtonStep from '#components/common/ButtonStep'
import CardCategory from '#components/common/CardCategory'
import { useCompanyContext } from '#contexts/CompanyContext'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import useToast from '#hooks/useToast'
import { type UserCategory } from '#tackpay-sdk'
import { IonCol, useIonRouter } from '@ionic/react'

interface Props {
  category: UserCategory
}
export default function AddTeamCompanyQuestion({
  category
}: Props): JSX.Element {
  const { company } = useCompanyContext()

  const { isSameCompany, setIsSameCompany, setStripeGatewayAccountExistent } =
    useCreateTeamStore()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const handleCompanyQuestion = (isSameCompany: boolean): void => {
    setIsSameCompany(isSameCompany)

    if (company?.stripe_gateway_account != null) {
      setStripeGatewayAccountExistent(company.stripe_gateway_account)
    }

    if (isSameCompany) {
      push(`/dashboard/${category}/jars/add/ibans`)
    } else {
      push(`/dashboard/${category}/jars/add/persons`)
    }
  }

  const handleNextStep = (): void => {
    if (isSameCompany == null) {
      showToast(undefined, 'teamPage.selectIban')
      return
    }

    if (company?.stripe_gateway_account != null) {
      setStripeGatewayAccountExistent(company.stripe_gateway_account)
    }

    if (isSameCompany) {
      push(`/dashboard/${category}/jars/add/ibans`)
    } else {
      push(`/dashboard/${category}/jars/add/persons`)
    }
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add/roles`, 'back')
  }

  return (
    <>
      <IonCol className='space-y-10'>
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleCompanyQuestion(true)
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-private.svg'
            }
          }}
          title='teamPage.company.same.title'
          description='teamPage.company.same.description'
          labelOptions={{
            capitalize: false
          }}
          textOptions={{
            translationOptions: {
              translate: 'yes',
              data: { companyName: company?.name }
            }
          }}
          showCheckIcon={isSameCompany}
        />
        <CardCategory
          cardProps={{
            onClick: (): void => {
              handleCompanyQuestion(false)
            }
          }}
          options={{
            imgOptions: {
              src: '/assets/img/jar-shared.svg'
            }
          }}
          title='teamPage.company.different.title'
          description='teamPage.company.different.description'
          labelOptions={{
            capitalize: false
          }}
          showCheckIcon={isSameCompany != null && !isSameCompany}
        />
      </IonCol>
      <ButtonStep handleNext={handleNextStep} handleBack={handleBack} />
    </>
  )
}
