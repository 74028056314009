import SearchBar, { type SearchBarOnChange } from '#components/ui/SearchBar'
import useToast from '#hooks/useToast'
import { type User } from '#tackpay-sdk'
import getErrorMessage from '#utils/getErrorMessage'
import sdk from '#utils/sdk'
import { closeCircleOutline } from 'ionicons/icons'
import { useRef, useState } from 'react'
import SearchMemberCard from './SearchMemberCard'
import { isBusiness } from '#utils/isBusiness'
import { IonCol } from '@ionic/react'
import { animationsFade } from '#utils/animations'

interface Props {
  jarId: string
}
export default function SearchMemberInput({ jarId }: Props): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('')

  const [searchResults, setSearchResults] = useState<User[]>([])

  const { showToast } = useToast('warning')

  const cardSearch = useRef<HTMLDivElement | null>(null)

  const handleSearch: SearchBarOnChange = (e) => {
    const value = e.target.value

    if (typeof value !== 'string') return

    setSearchValue(value)

    if (value === '') {
      setSearchResults([])
      animationsFade({
        typeAnimation: cardSearch,
        start: '0',
        end: '-45px',
        duration: 400
      })
    } else {
      sdk.tackpay_ids
        .list({
          include: ['user.person,user.tackpay_id'],
          filters: {
            like: {
              value: `${value.toUpperCase()}`
            },
            eq: {
              category: 'user'
            }
          }
        })
        .then((res) => {
          res.forEach((tackpayId) => {
            if (
              tackpayId.category === 'user' &&
              tackpayId.user != null &&
              !isBusiness(tackpayId.user.category)
            ) {
              setSearchResults((prev): any => {
                if (
                  prev.find((user) => user.id === tackpayId.user?.id) == null
                ) {
                  return [...prev, tackpayId.user]
                }
                return prev
              })
              animationsFade({
                typeAnimation: cardSearch,
                start: '-45px',
                end: '0',
                duration: 400
              })
            }
          })
        })
        .catch((error) => {
          showToast(undefined, getErrorMessage(error))
        })
    }
  }
  return (
    <div>
      <SearchBar
        class='searchWelcome'
        value={searchValue}
        onIonInput={handleSearch}
        tabIndex={0}
        placeholder='Tackpay ID'
        className='ion-no-padding mt-10 '
        onIonCancel={() => {
          setSearchResults([])
        }}
        // onIonBlur={handleSearch}
        animated
        clearIcon={closeCircleOutline}
      />
      {/* Mostra i risultati della ricerca */}
      <IonCol className='ion-no-margin ion-no-padding'>
        <div ref={cardSearch} className='space-y-6'>
          {searchResults.map((result) => (
            <SearchMemberCard
              key={result.id}
              user={result}
              setSearchResults={setSearchResults}
              setValue={setSearchValue}
              jarId={jarId}
            />
          ))}
        </div>
      </IonCol>
    </div>
  )
}
