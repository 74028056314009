import AppLayout from '#components/layouts/app/AppLayout'
import ContactUsForm from '#components/pages/contactUs/ContactUsForm'
import SubTitle from '#components/ui/SubTitle'
import Title from '#components/ui/Title'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'

export default function ContactUs(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
    >
      <Title hasSubtitle>contactUs.title</Title>
      <SubTitle>contactUs.subtitle</SubTitle>
      <ContactUsForm />
    </AppLayout>
  )
}
