import Card from '#components/ui/Card'
import { useUserContext } from '#contexts/UserContext'
import { type Member, type TackpayId } from '#tackpay-sdk'
import { IonCardContent, IonCardHeader, IonRow } from '@ionic/react'
import { Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'
import CardQrCodeImage from './CardQrCodeImage'
import Text from '#components/ui/Text'
import CardQrCodeTackpayId from './CardQrCodeTackpayId'
import CardQrCodeLink from './CardQrCodeLink'
import { Fragment } from 'react'

interface Props {
  tackpayId?: TackpayId
  loading: boolean
  error: any
  jarName: string
}
function CardMember(props: Props): JSX.Element {
  const { tackpayId, loading, error, jarName } = props
  return (
    <Card color='bg-card'>
      <IonCardHeader className='ion-no-padding'>
        <CardQrCodeImage
          loading={loading}
          error={error}
          src={tackpayId?.qr_code_storage_url ?? ''}
        />
      </IonCardHeader>
      <IonCardContent>
        <IonRow className='ion-justify-content-between ion-align-items-center mt-2'>
          <Text
            color='gray-500'
            fontWeight='font-semibold'
            translationOptions={{ translate: 'no' }}
          >
            TackPay ID:
          </Text>
          <CardQrCodeTackpayId
            loading={loading}
            error={error}
            tackpayId={tackpayId?.value ?? ''}
          />
        </IonRow>
        <IonRow className='ion-justify-content-between ion-align-items-center mt-2'>
          <Text color='gray-500' fontWeight='font-semibold'>
            teamPage.title
          </Text>
          <Text
            color='gray-900'
            fontWeight='font-bold'
            translationOptions={{
              translate: 'no'
            }}
          >
            {jarName}
          </Text>
        </IonRow>

        <CardQrCodeLink loading={loading} error={error} tackpayId={tackpayId} />
      </IonCardContent>
    </Card>
  )
}

export default function CardQrCodeMember(): JSX.Element {
  const { user, isLoading, error } = useUserContext()

  if (isLoading) return <div>Loading...</div>

  if (error != null) return <div>Error: {error.message}</div>

  const getTackpayIds = (member: Member): TackpayId | undefined => {
    if (
      member?.jar?.tackpay_ids != null &&
      Array.isArray(member.jar.tackpay_ids) &&
      member.jar.tackpay_ids.length > 0
    ) {
      return member.jar.tackpay_ids[0]
    } else if (
      !Array.isArray(member?.jar?.tackpay_ids) &&
      member?.jar?.tackpay_ids != null
    ) {
      return member.jar.tackpay_ids
    }
  }
  return (
    <Swiper
      modules={[Pagination]}
      autoplay={false}
      slidesPerView={1}
      pagination={{
        dynamicBullets: true
      }}
      className='ion-padding ion-margin'
    >
      {user?.members != null && user?.members.length > 0 && (
        <>
          {user.members.map((member) => {
            return (
              <Fragment key={member.id}>
                {member.jar?.tackpay_ids != null && (
                  <SwiperSlide>
                    <CardMember
                      tackpayId={getTackpayIds(member)}
                      loading={isLoading}
                      error={error}
                      jarName={member.jar.name}
                    />
                  </SwiperSlide>
                )}
              </Fragment>
            )
          })}
        </>
      )}
    </Swiper>
  )
}
