import AuthLayout from '#components/layouts/auth/AuthLayout'
import { useParams } from 'react-router'
import { type CheckoutRoutesParams } from '../CheckoutRoutes'
import CheckoutBillContent from '#components/pages/checkouts/bills/CheckoutBill'

export default function CheckoutBill(): JSX.Element {
  const { checkoutId } = useParams<CheckoutRoutesParams>()

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
      checkoutOptions={{
        fetch: true,
        id: checkoutId,
        params: {
          include: [
            'payment',
            'member.user.checkout_setting.links_social',
            'jar.checkout_setting.links_social',
            'user.checkout_setting.links_social',
            'member.user.checkout_setting.reply',
            'jar.checkout_setting.reply',
            'user.checkout_setting.reply'
          ]
        }
      }}
    >
      <CheckoutBillContent />
    </AuthLayout>
  )
}
