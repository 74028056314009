interface PayoutLimit {
  currency: string
  topLimit: number
  fee: number
}

const currencyLimits: PayoutLimit[] = [
  { currency: 'AED', topLimit: 400, fee: 2 },
  { currency: 'AFN', topLimit: 9350, fee: 50 },
  { currency: 'ALL', topLimit: 10500, fee: 55 },
  { currency: 'AMD', topLimit: 40000, fee: 200 },
  { currency: 'ANG', topLimit: 195, fee: 1 },
  { currency: 'AOA', topLimit: 55000, fee: 275 },
  { currency: 'ARS', topLimit: 25000, fee: 125 },
  { currency: 'AUD', topLimit: 150, fee: 0.8 },
  { currency: 'AWG', topLimit: 195, fee: 1 },
  { currency: 'AZN', topLimit: 185, fee: 1 },
  { currency: 'BAM', topLimit: 200, fee: 1 },
  { currency: 'BBD', topLimit: 200, fee: 1 },
  { currency: 'BDT', topLimit: 11500, fee: 55 },
  { currency: 'BGN', topLimit: 200, fee: 1 },
  { currency: 'BIF', topLimit: 300000, fee: 1500 },
  { currency: 'BMD', topLimit: 100, fee: 0.5 },
  { currency: 'BND', topLimit: 150, fee: 1.75 },
  { currency: 'BOB', topLimit: 750, fee: 4 },
  { currency: 'BRL', topLimit: 550, fee: 3 },
  { currency: 'BSD', topLimit: 100, fee: 0.5 },
  { currency: 'BWP', topLimit: 1500, fee: 8 },
  { currency: 'BYN', topLimit: 300, fee: 1.5 },
  { currency: 'BZD', topLimit: 200, fee: 1 },
  { currency: 'CAD', topLimit: 150, fee: 0.75 },
  { currency: 'CDF', topLimit: 270000, fee: 1350 },
  { currency: 'CHF', topLimit: 100, fee: 0.5 },
  { currency: 'CLP', topLimit: 85000, fee: 430 },
  { currency: 'CNY', topLimit: 700, fee: 3 },
  { currency: 'COP', topLimit: 450000, fee: 2250 },
  { currency: 'CRC', topLimit: 60000, fee: 300 },
  { currency: 'CVE', topLimit: 10000, fee: 50 },
  { currency: 'CZK', topLimit: 2500, fee: 15 },
  { currency: 'DJF', topLimit: 20000, fee: 100 },
  { currency: 'DKK', topLimit: 800, fee: 4 },
  { currency: 'DOP', topLimit: 6000, fee: 30 },
  { currency: 'DZD', topLimit: 15000, fee: 75 },
  { currency: 'EGP', topLimit: 3500, fee: 20 },
  { currency: 'ETB', topLimit: 6000, fee: 30 },
  { currency: 'EUR', topLimit: 100, fee: 0.5 },
  { currency: 'FJD', topLimit: 250, fee: 1.5 },
  { currency: 'FKP', topLimit: 100, fee: 0.5 },
  { currency: 'GBP', topLimit: 100, fee: 0.5 },
  { currency: 'GEL', topLimit: 300, fee: 1.5 },
  { currency: 'GIP', topLimit: 100, fee: 0.5 },
  { currency: 'GMD', topLimit: 6500, fee: 35 },
  { currency: 'GNF', topLimit: 1000000, fee: 5000 },
  { currency: 'GTQ', topLimit: 850, fee: 4.5 },
  { currency: 'GYD', topLimit: 25000, fee: 115 },
  { currency: 'HKD', topLimit: 850, fee: 4.5 },
  { currency: 'HNL', topLimit: 2750, fee: 15 },
  { currency: 'HTG', topLimit: 15000, fee: 75 },
  { currency: 'HUF', topLimit: 40000, fee: 200 },
  { currency: 'IDR', topLimit: 1700000, fee: 10000 },
  { currency: 'ILS', topLimit: 400, fee: 2 },
  { currency: 'INR', topLimit: 10000, fee: 50 },
  { currency: 'ISK', topLimit: 15000, fee: 75 },
  { currency: 'JMD', topLimit: 16750, fee: 85 },
  { currency: 'JPY', topLimit: 15000, fee: 75 },
  { currency: 'KES', topLimit: 15000, fee: 75 },
  { currency: 'KGS', topLimit: 9500, fee: 50 },
  { currency: 'KHR', topLimit: 450000, fee: 2250 },
  { currency: 'KMF', topLimit: 50000, fee: 250 },
  { currency: 'KRW', topLimit: 150000, fee: 750 },
  { currency: 'KYD', topLimit: 100, fee: 0.5 },
  { currency: 'KZT', topLimit: 50000, fee: 250 },
  { currency: 'LAK', topLimit: 2000000, fee: 10000 },
  { currency: 'LBP', topLimit: 2000000, fee: 10000 },
  { currency: 'LKR', topLimit: 35000, fee: 200 },
  { currency: 'LRD', topLimit: 20000, fee: 100 },
  { currency: 'LSL', topLimit: 2000, fee: 10 },
  { currency: 'MAD', topLimit: 1000, fee: 5 },
  { currency: 'MDL', topLimit: 2000, fee: 10 },
  { currency: 'MGA', topLimit: 500000, fee: 2500 },
  { currency: 'MKD', topLimit: 7000, fee: 35 },
  { currency: 'MMK', topLimit: 250000, fee: 1200 },
  { currency: 'MNT', topLimit: 400000, fee: 2000 },
  { currency: 'MOP', topLimit: 1000, fee: 5 },
  { currency: 'MUR', topLimit: 5000, fee: 25 },
  { currency: 'MVR', topLimit: 2000, fee: 10 },
  { currency: 'MWK', topLimit: 120000, fee: 600 },
  { currency: 'MXN', topLimit: 2000, fee: 10 },
  { currency: 'MYR', topLimit: 500, fee: 2.5 },
  { currency: 'MZN', topLimit: 7000, fee: 35 },
  { currency: 'NAD', topLimit: 2000, fee: 10 },
  { currency: 'NGN', topLimit: 50000, fee: 250 },
  { currency: 'NIO', topLimit: 4000, fee: 20 },
  { currency: 'NOK', topLimit: 1200, fee: 6 },
  { currency: 'NPR', topLimit: 15000, fee: 75 },
  { currency: 'NZD', topLimit: 175, fee: 1 },
  { currency: 'PAB', topLimit: 100, fee: 0.5 },
  { currency: 'PEN', topLimit: 400, fee: 2 },
  { currency: 'PGK', topLimit: 400, fee: 2 },
  { currency: 'PHP', topLimit: 6000, fee: 30 },
  { currency: 'PKR', topLimit: 30000, fee: 150 },
  { currency: 'PLN', topLimit: 500, fee: 2.5 },
  { currency: 'PYG', topLimit: 800000, fee: 4000 },
  { currency: 'QAR', topLimit: 400, fee: 2 },
  { currency: 'RON', topLimit: 500, fee: 2.5 },
  { currency: 'RSD', topLimit: 15000, fee: 65 },
  { currency: 'RUB', topLimit: 8500, fee: 45 },
  { currency: 'RWF', topLimit: 125000, fee: 635 },
  { currency: 'SAR', topLimit: 400, fee: 2 },
  { currency: 'SBD', topLimit: 1000, fee: 5 },
  { currency: 'SCR', topLimit: 1500, fee: 7.5 },
  { currency: 'SEK', topLimit: 1200, fee: 6 },
  { currency: 'SGD', topLimit: 150, fee: 1 },
  { currency: 'SHP', topLimit: 100, fee: 0.5 },
  { currency: 'SLE', topLimit: 2500, fee: 15 },
  { currency: 'SOS', topLimit: 60000, fee: 305 },
  { currency: 'SRD', topLimit: 4000, fee: 20 },
  { currency: 'STD', topLimit: 2500000, fee: 12000 },
  { currency: 'SZL', topLimit: 2000, fee: 10 },
  { currency: 'THB', topLimit: 4000, fee: 20 },
  { currency: 'TJS', topLimit: 2000, fee: 6 },
  { currency: 'TOP', topLimit: 250, fee: 1.5 },
  { currency: 'TRY', topLimit: 2500, fee: 15 },
  { currency: 'TTD', topLimit: 750, fee: 4 },
  { currency: 'TWD', topLimit: 3500, fee: 20 },
  { currency: 'TZS', topLimit: 275000, fee: 1500 },
  { currency: 'UAH', topLimit: 4000, fee: 20 },
  { currency: 'UGX', topLimit: 400000, fee: 2000 },
  { currency: 'USD', topLimit: 100, fee: 0.5 },
  { currency: 'UYU', topLimit: 4000, fee: 20 },
  { currency: 'UZS', topLimit: 1300000, fee: 6500 },
  { currency: 'VND', topLimit: 2500000, fee: 12500 },
  { currency: 'VUV', topLimit: 150000, fee: 75 },
  { currency: 'WST', topLimit: 300, fee: 1.5 },
  { currency: 'XAF', topLimit: 65000, fee: 350 },
  { currency: 'XCD', topLimit: 300, fee: 1.5 },
  { currency: 'XOF', topLimit: 65000, fee: 350 },
  { currency: 'XPF', topLimit: 15000, fee: 65 },
  { currency: 'YER', topLimit: 30000, fee: 150 },
  { currency: 'ZAR', topLimit: 2000, fee: 10 },
  { currency: 'ZMW', topLimit: 2000, fee: 10 }
]

export const getPayoutFee = (currency: string): PayoutLimit => {
  return (
    currencyLimits.find(
      (c) => c.currency.toUpperCase() === currency?.toUpperCase()
    ) ?? {
      currency,
      topLimit: 100,
      fee: 0.5
    }
  )
}
