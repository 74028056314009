import AppLayout from '#components/layouts/app/AppLayout'
import { useParams } from 'react-router'
import { type MemberRoutesParams } from '../../TeamRoutes'
import MemberDelete from '#components/pages/members/MemberDelete'
import MemberContainer from '#contexts/MemberContext'
import MemberTeamInfoSection from '#components/pages/members/info/MemberTeamInfoSection'

export default function MemberTippedInfo(): JSX.Element {
  const { category, memberId } = useParams<MemberRoutesParams>()
  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/jars`
        },
        showCustomIcon: true,
        customIconOptions: {
          icon: <MemberDelete memberId={memberId} category={category} />
        }
      }}
    >
      <MemberContainer
        memberId={memberId}
        params={{
          include: [
            'user.person',
            'jar.balance',
            'jar.tackpay_ids',
            'jar.members.user.person',
            'jar.company'
          ]
        }}
      >
        <MemberTeamInfoSection category={category} />
      </MemberContainer>
    </AppLayout>
  )
}
