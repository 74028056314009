import type Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar, Thumbs } from 'swiper'
import { Swiper as SwiperView, SwiperSlide } from 'swiper/react'
import { useState } from 'react'
import useToast from '#hooks/useToast'
import { IonCol, IonRow } from '@ionic/react'
import Avatar from '#components/ui/Avatar'
import Text from '#components/ui/Text'
import Underlined from '#components/ui/UnderlinedName'
import { type Member, type CheckoutSetting } from '#tackpay-sdk'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { DEFAULT_COMPANY_BIO } from '#constants/bio'

interface Props {
  members?: Member[] | null
  checkoutSetting?: CheckoutSetting
}

export default function SlidesAvatarMembers({
  members,
  checkoutSetting
}: Props): JSX.Element {
  const { showToast, hideToast } = useToast('success')

  const { showToast: showTeam, hideToast: hideTeam } = useToast('success')

  const [currentMember, setCurrentMember] = useState<Member | undefined>()

  const [currentCheckoutSetting, setCurrentCheckoutSetting] = useState<
    CheckoutSetting | undefined
  >(checkoutSetting)

  function selectTip(swiper: Swiper): void {
    if (swiper.activeIndex === 0) {
      setCurrentMember(undefined)
      setCurrentCheckoutSetting(checkoutSetting)
      hideToast()
      showTeam(undefined, 'tipToTeam')
    } else {
      const member = members?.[swiper.activeIndex - 1]
      hideTeam()
      setCurrentMember(member)

      showToast(undefined, 'tipToTipped', {
        data: {
          name: member?.user?.person?.first_name
        }
      })
    }
  }

  return (
    <IonCol size='12' className='ion-no-padding space-y-4 md:space-y-6'>
      <IonRow className='w-full ion-no-padding ion-margin-top'>
        <IonCol className='ion-no-padding ion-no-margin' size='12'>
          <IonRow className='ion-no-margin ion-no-padding'>
            <SwiperView
              modules={[Navigation, Scrollbar, Pagination, Thumbs]}
              className='w-full'
              spaceBetween={20}
              slidesPerView={3}
              centeredSlides
              initialSlide={0}
              onSlideChange={(Swiper) => {
                selectTip(Swiper)
              }}
            >
              <SwiperSlide>
                <IonCol>
                  <Avatar
                    avatarOptions={{
                      className: `${
                        currentMember == null
                          ? 'border-green-400'
                          : 'border-gray-400'
                      } border-4  mx-auto h-24 w-24`
                    }}
                    imgOptions={{
                      src:
                        currentCheckoutSetting?.avatar ??
                        DEFAULT_IMAGES.logoTeam
                    }}
                  />
                </IonCol>
              </SwiperSlide>
              {members?.map((member, index) => (
                <SwiperSlide key={index}>
                  <IonCol>
                    <Avatar
                      avatarOptions={{
                        className: `border-4 mx-auto h-24 w-24 ${
                          currentMember?.id === member.id
                            ? 'border-green-400'
                            : 'border-gray-400'
                        }`
                      }}
                      imgOptions={{
                        src:
                          member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped,
                        alt: 'Avatar tipped'
                      }}
                    />
                  </IonCol>
                </SwiperSlide>
              ))}
            </SwiperView>
            <Underlined
              name={
                currentMember != null
                  ? currentMember?.user?.person?.first_name
                  : currentCheckoutSetting?.username
              }
            />
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow className='pt-16 ion-justify-content-center'>
        <Text className='text-gray-600 text-center'>
          {currentCheckoutSetting?.description ?? DEFAULT_COMPANY_BIO}
        </Text>
      </IonRow>
      <IonRow className='border-t border-stone-200 w-full h-5' />
    </IonCol>
  )
}
