import CardSkeleton from '#components/common/CardSkeleton'
import ErrorBox from '#components/errors/ErrorBox'
import Avatar from '#components/ui/Avatar'
import Skeleton from '#components/ui/Skeleton'
import Underlined from '#components/ui/UnderlinedName'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { useMemberContext } from '#contexts/MemberContext'
import { type UserCategory } from '#tackpay-sdk'
import { IonCol, IonRow } from '@ionic/react'
import MemberTeamCardInfo from './MemberTeamCardInfo'
import MemberCardStatistic from './MemberCardStatistic'
import Toggle from '#components/ui/Toggle'
import OtherMemberCard from './OtherMemberCard'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import { useTranslation } from 'react-i18next'

interface Props {
  category: UserCategory
}
export default function MemberTeamInfoSection({
  category
}: Props): JSX.Element {
  const { member, isLoading, error, updateMember } = useMemberContext()

  const { showToast } = useToast('warning')

  const { showToast: successShow } = useToast('success')

  const { t } = useTranslation()

  if (isLoading)
    return (
      <>
        <Skeleton className='w-full h-12' />
        <div className='mt-10'>
          <CardSkeleton />
        </div>
      </>
    )

  if (error != null) return <ErrorBox error={error} />

  if (member == null)
    return (
      <ErrorBox
        error={{
          title: 'Member is null',
          detail: 'Member is null',
          code: 'member_not_found',
          status: 422
        }}
      />
    )

  const handleToggle = (): void => {
    if (updateMember != null) {
      updateMember.mutate(
        {
          id: member?.id ?? '',
          active: !member?.active
        },
        {
          onSuccess: () => {
            console.log('Member updated')
            successShow(undefined, 'Member updated')
            member.active = !member.active
          },
          onError: (error) => {
            console.log('Error updating member')
            showToast(undefined, getErrorMessage(error))
          }
        }
      )
    }
  }

  return (
    <IonCol className='w-full block space-y-5'>
      <Avatar
        imgOptions={{
          src: member?.jar?.avatar ?? DEFAULT_IMAGES.logoTeam
        }}
        avatarOptions={{
          className: 'w-24 h-24 mx-auto'
        }}
      />
      <Underlined name={member?.jar?.name} />
      <IonRow className='ion-no-padding ion-no-margin w-full py-5'>
        <Toggle
          className='w-full text-lg'
          labelPlacement='start'
          onClick={handleToggle}
          checked={member?.active}
        >
          {t('tipped_jar_page_status')}
        </Toggle>
      </IonRow>
      <MemberTeamCardInfo jar={member?.jar} createdAt={member?.created_at} />
      <MemberCardStatistic
        balance={member?.jar?.balance?.total}
        title={t('tipped_jar_page_jar_info') ?? 'Statistic Jar'}
        totalComment={member?.jar?.total_comment}
        totalFeedback={member?.jar?.total_feedback}
        totalTip={member?.jar?.total_tip}
        companyId={member?.jar?.company?.id}
      />
      <MemberCardStatistic
        title={t('tipped_jar_page_tipped_stats') ?? 'Statistic Member'}
        balance={member?.balance}
        totalComment={member?.total_comment}
        totalFeedback={member?.total_feedback}
        totalTip={member?.total_tip}
      />
      <OtherMemberCard
        members={member?.jar?.members ?? []}
        currentMemberId={member?.id ?? ''}
        jarId={member?.jar?.id ?? ''}
      />
    </IonCol>
  )
}
