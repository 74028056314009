import AddressAutoComplete from '#components/common/AddressAutoComplete'
import CountrySelect from '#components/common/CountrySelect'
import Input, { type InputOnChange } from '#components/ui/Input'
import ButtonStep from '#components/common/ButtonStep'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import textUtil from '#utils/textUtil'
import useToast from '#hooks/useToast'
import { useIonRouter } from '@ionic/react'
import { type UserCategory, type Address } from '#tackpay-sdk'
import { useUserContext } from '#contexts/UserContext'
import { useEffect } from 'react'

interface Props {
  category: UserCategory
}
export default function AddTeamAddressForm({ category }: Props): JSX.Element {
  const { addressCreate, setAddressCreate } = useCreateTeamStore()

  const { user } = useUserContext()

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  useEffect(() => {
    if (
      user != null &&
      (addressCreate == null || Object.keys(addressCreate).length === 0)
    ) {
      if (user.person?.address != null) {
        setAddressCreate({
          city: user.person?.address?.city,
          country_name: user.person?.address?.country_name,
          postal_code: user.person?.address?.postal_code,
          state: user.person?.address?.state,
          street: user.person?.address?.street,
          street_number: user.person?.address?.street_number
        })
      }
    }
  }, [user])

  const handleCountryChange = (country: string): void => {
    setAddressCreate({ country_name: country })
  }

  const handleAddressChange = (address: Partial<Address>): void => {
    setAddressCreate({ ...address } as any)
  }

  const handleInputChanges: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'city') {
        setAddressCreate({ city: value })
      } else if (name === 'street') {
        setAddressCreate({ street: value })
      } else if (name === 'street_number') {
        setAddressCreate({ street_number: value })
      } else if (name === 'state') {
        setAddressCreate({ state: value })
      } else if (name === 'postal_code') {
        setAddressCreate({ postal_code: value })
      }
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (textUtil.isEmpty(addressCreate?.country_name)) {
      showToast(undefined, 'registration.countryRequired')
      return
    }

    if (textUtil.isEmpty(addressCreate?.city)) {
      showToast(undefined, 'registration.cityRequired')
      return
    }

    if (textUtil.isEmpty(addressCreate?.street)) {
      showToast(undefined, 'registration.streetRequired')
      return
    }

    if (textUtil.isEmpty(addressCreate?.street_number)) {
      showToast(undefined, 'registration.streetNumberRequired')
      return
    }

    if (textUtil.isEmpty(addressCreate?.postal_code)) {
      showToast(undefined, 'registration.postalCodeRequired')
      return
    }

    push(`/dashboard/${category}/jars/add/reviews`)
  }

  const handlePrevious = (): void => {
    push(`/dashboard/${category}/jars/add/persons`, 'back')
  }

  return (
    <form onSubmit={handleSubmit} className='pt-3 space-y-4'>
      <AddressAutoComplete setAddress={handleAddressChange} />
      <CountrySelect
        country={addressCreate?.country_name ?? ''}
        setSelectionCountry={handleCountryChange}
      />
      <div>
        <Input
          name='city'
          type='text'
          value={addressCreate?.city}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'city'
          }}
          testId='city'
        />
      </div>
      <div>
        <Input
          name='street'
          type='text'
          value={addressCreate?.street}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'street'
          }}
          testId='street'
        />
      </div>
      <div>
        <Input
          name='street_number'
          type='text'
          value={addressCreate?.street_number}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'streetNumber',
            capitalize: false
          }}
          testId='streetNumber'
        />
      </div>
      <div>
        <Input
          name='postal_code'
          type='text'
          value={addressCreate?.postal_code}
          onIonInput={handleInputChanges}
          labelOptions={{
            children: 'postalCode',
            capitalize: false
          }}
          testId='postalCode'
        />
      </div>
      <div>
        <Input
          name='state'
          type='text'
          value={addressCreate?.state}
          onIonInput={handleInputChanges}
          labelOptions={{ children: 'state' }}
          testId='state'
        />
      </div>
      <div>
        <ButtonStep handleBack={handlePrevious} nextType='submit' />
      </div>
    </form>
  )
}
