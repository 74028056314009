import ErrorBox from '#components/errors/ErrorBox'
import ListLink from '#components/ui/ListLink'
import Skeleton from '#components/ui/Skeleton'
import { getTeamSetting } from '#constants/teamSetting'
import { useCollaboratorContext } from '#contexts/CollaboratorContext'
import { useTeamContext } from '#contexts/JarContext'
import { type UserCategory } from '#tackpay-sdk'
import isCollaborator from '#utils/isCollaborator'
import TeamDelete from './TeamDelete'

interface Props {
  /**
   * Jar ID
   */
  jarId: string
  /**
   * The category of user
   */
  category: UserCategory
}

export default function TeamSettingList({
  jarId,
  category
}: Props): JSX.Element {
  const { jar, error, isLoading } = useTeamContext()

  const { collaborator } = useCollaboratorContext()

  if (isLoading)
    return (
      <div className='mt-10 space-y-5'>
        <Skeleton animated className='w-full h-10' />
        <Skeleton animated className='w-full h-10' />
        <Skeleton animated className='w-full h-10' />
      </div>
    )

  if (error != null) return <ErrorBox error={error} />

  if (jar == null) return <div>Not found</div>

  const baseURL = `/dashboard/${category}/jars/${jarId}/settings`

  let settingList = getTeamSetting(baseURL).filter(
    (c) => c.model === jar.jar_type && c.tippingRule.includes(jar.tipping_rule)
  )

  const hasBalance = (): boolean => {
    return (
      (jar?.balance?.available?.amount_cents ?? 0) > 0 ||
      (jar?.balance?.pending?.amount_cents ?? 0) > 0 ||
      (jar?.balance?.tronc?.available.amount_cents ?? 0) > 0 ||
      (jar?.balance?.tronc?.pending.amount_cents ?? 0) > 0
    )
  }

  const isTronc = (): boolean => {
    return jar.tipping_rule === 'tronc'
  }

  const bfunJars = [
    '4393f747-0b44-44a5-aaca-cee71cd2ab6c',
    '4fc1e6af-24dc-477a-a36c-cbaac1977a61',
    '21baa22c-d22d-4d96-95ec-ccdd41712e7e',
    '7a53f729-66b9-40f4-9810-d09670594dd4',
    '8cf6f9db-41e4-469a-b426-2cf59d19ce8a'
  ]

  if (bfunJars.includes(jarId) && isCollaborator(category)) {
    settingList = settingList.filter(
      (c) =>
        c.label !== 'teamSetting.checkoutSetting' &&
        c.label !== 'teamSetting.tippingRule'
    )
  }

  return (
    <>
      <ListLink items={settingList} />
      {(isCollaborator(category) &&
        !isLoading &&
        collaborator?.role !== 'manager') ||
        (!isCollaborator(category) && (
          <TeamDelete
            jarId={jarId}
            category={category}
            hasBalance={hasBalance()}
            isTronc={isTronc()}
          />
        ))}
    </>
  )
}
