import Text from '#components/ui/Text'

import { IonCheckbox, IonCol, IonGrid, IonRow } from '@ionic/react'

interface IntAcceptedTerms {
  accept: boolean
  setAccept: (arg: boolean) => void
}

export function AcceptedTerms({
  accept,
  setAccept
}: IntAcceptedTerms): JSX.Element {
  return (
    <div className='mt-5'>
      <IonGrid className='ion-no-padding ion-no-margin'>
        <IonRow className='ion-justify-content-between ion-align-items-center space-x-2'>
          <IonCol size='1'>
            <IonCheckbox
              labelPlacement='end'
              className='square transform scale-75'
              value={accept}
              checked={accept}
              onIonChange={(e) => {
                setAccept(e.detail.checked)
              }}
            />
          </IonCol>
          <IonCol>
            <Text
              className='italic font-semibold  leading-relaxed'
              color='gray-900'
              size='text-sm'
            >
              registration.terms.accept
            </Text>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}
