import {
  IonCol,
  type IonItem,
  IonSelect,
  IonSelectOption,
  IonRow
} from '@ionic/react'
import { type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Label from './Label'

export type ConditionalLabelProps =
  | { show: true; options: ComponentProps<typeof Label> }
  | { show: false; options?: never }

interface Props extends ComponentProps<typeof IonSelect> {
  /**
   * The options of select
   */
  options: {
    childrens: React.ReactNode[]
    props?: ComponentProps<typeof IonSelectOption>
  }
  /**
   * The label options
   */
  labelOptions?: ConditionalLabelProps
  /**
   * The container props
   */
  containerProps?: ComponentProps<typeof IonItem>
  /**
   * The padding
   * @default py-2.5
   */
  padding?: string
}

export type SelectOnChangeEvent = ComponentProps<
  typeof IonSelect
>['onIonChange']

export type SelectValue = ComponentProps<typeof IonSelect>['value']

export default function BasicSelect(props: Props): JSX.Element {
  const {
    options,
    labelOptions,
    containerProps,
    padding,
    ...ionSelectionProps
  } = props

  const { t } = useTranslation()

  const baseClass = 'border border-gray-300 rounded-lg  w-full inputs'

  const className = classNames(baseClass, padding, ionSelectionProps?.className)

  const selectOptionsClass = classNames(options?.props?.className)

  return (
    <IonRow className='ion-no-margin ion-no-padding w-full'>
      {labelOptions?.show === true && (
        <IonRow className='w-full ion-no-margin ion-no-padding'>
          <Label
            {...labelOptions.options}
            className='ion-no-padding ion-no-margin w-full text-base'
            data-testid={labelOptions.options.children}
          />
        </IonRow>
      )}
      <IonRow
        className={`${className} ion-justify-content-end ion-no-margin ion-no-padding border border-gray-300`}
      >
        <IonCol size='12' className='ion-no-margin ion-no-padding'>
          <IonSelect
            className='ion-no-margin ion-no-padding'
            {...ionSelectionProps}
            cancelText='Cancel'
            data-testid={`selected-${String(labelOptions?.options?.children)}`}
          >
            {options.childrens.map((child, index) => (
              <IonSelectOption
                {...options.props}
                key={index}
                value={child}
                className={selectOptionsClass}
              >
                {typeof child === 'string' ? t(child) : child}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonCol>
      </IonRow>
    </IonRow>
  )
}
