import {
  type QueryParamsRetrieve,
  type BankAccountDeduction,
  type BankAccountDeductionCreate
} from '#tackpay-sdk'
import { createContext, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  type UseMutationResult,
  useMutation
} from '@tanstack/react-query'
import sdk from '#utils/sdk'
import useAuthState from '#hooks/useAuthState'

type CreateBankAccountDeductionFunction = UseMutationResult<
  BankAccountDeduction,
  unknown,
  BankAccountDeductionCreate,
  unknown
>

interface BankContextValue {
  bank?: BankAccountDeduction | null
  isLoading: boolean
  error?: any
  createBankAccountDeduction?: CreateBankAccountDeductionFunction
}

const bankInitialValue: BankContextValue = {
  bank: null,
  isLoading: false,
  error: null
}

const BankDeductionContext = createContext<BankContextValue>(bankInitialValue)

export const useBankDeductionContext = (): BankContextValue => {
  const context = useContext(BankDeductionContext)
  if (context == null) {
    throw new Error('useBankContext must be used within a BankProvider')
  }
  return context
}

interface BankContainerProps {
  /**
   * The render prop
   */
  children: React.ReactNode
  /**
   * If automatic fetching is enabled, the component will fetch the data on mount
   * @default true
   */
  fetch?: boolean
  /**
   * If true, the component will fetch the company data
   * @default false
   */
  fetchCompany?: boolean
  /**
   * The jarId to use when fetching the data
   */
  jarId?: string

  /**
   * The query params to use when fetching the data
   */
  params?: QueryParamsRetrieve
}

const handleCreateBankAccountDeduction = async (
  BankAccountDeductionCreate: BankAccountDeductionCreate
): Promise<BankAccountDeduction> => {
  return await sdk.bank_account_deductions.create(BankAccountDeductionCreate)
}

export default function BankDeductionContainer(
  props: BankContainerProps
): JSX.Element {
  const {
    children,
    params,
    fetch = true,
    jarId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchCompany = false
  } = props

  const queryClient = useQueryClient()

  const { getAuthState } = useAuthState()

  const retrieveBankAccountDeductionUser = useQuery({
    queryKey: ['bank-deduction-user', params],
    queryFn: async () => {
      const authInfo = await getAuthState()
      return await sdk.users.bank_account_deduction(
        authInfo?.userId ?? '',
        params
      )
    },
    enabled: fetch && jarId == null
  })

  const retrieveBankAccountDeductionJar = useQuery({
    queryKey: ['bank-deduction-jar', params],
    queryFn: async () => {
      return await sdk.jars.bank_account_deduction(jarId ?? '', params)
    },
    enabled: fetch && jarId != null
  })

  const useBankAccountDeductionCreate = useMutation({
    mutationFn: handleCreateBankAccountDeduction,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['bank-user', params]
      })
      void queryClient.invalidateQueries({
        queryKey: ['bank-deduction-jar', params]
      })
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId, params] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['bank-deduction-user', params]
      })
      void queryClient.invalidateQueries({
        queryKey: ['bank-deduction-jar', params]
      })
      void queryClient.invalidateQueries({ queryKey: ['jar'] })
      void queryClient.invalidateQueries({ queryKey: ['jar', jarId, params] })
      void queryClient.invalidateQueries({ queryKey: ['user'] })
    }
  })

  const bankContextValue: BankContextValue = {
    bank:
      retrieveBankAccountDeductionJar.data ??
      retrieveBankAccountDeductionUser.data,
    isLoading:
      jarId != null
        ? retrieveBankAccountDeductionJar.isLoading
        : retrieveBankAccountDeductionUser.isLoading,
    error:
      jarId != null
        ? retrieveBankAccountDeductionJar.error
        : retrieveBankAccountDeductionUser.error,
    createBankAccountDeduction: useBankAccountDeductionCreate
  }

  return (
    <BankDeductionContext.Provider value={bankContextValue}>
      {children}
    </BankDeductionContext.Provider>
  )
}
