export default function formattedAmount(
  amount?: number,
  currency?: string,
  simple: boolean = false,
  locale: string = 'en',
  minFractionDigit: number = 0,
  maxFractionDigit: number = 2
): string {
  if (simple) {
    return String((amount ?? 0) / 100)
  } else {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency ?? 'USD',
      minimumFractionDigits: minFractionDigit,
      maximumFractionDigits: maxFractionDigit
    })

    const formatterWithoutCurrency = new Intl.NumberFormat(locale, {
      // style: 'decimal',
      minimumFractionDigits: minFractionDigit,
      maximumFractionDigits: maxFractionDigit
    })
    const num = (amount ?? 0) / 100

    return currency != null
      ? formatter.format(num)
      : formatterWithoutCurrency.format(num)
  }
}
