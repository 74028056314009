import Input, { type InputOnChange } from '#components/ui/Input'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { EpsBankElement, useElements, useStripe } from '@stripe/react-stripe-js'
import CheckoutFeeAndTerms from './CheckoutFeeAndTerms'
import Button from '#components/ui/Button'
import { getSymbolCurrency } from '#constants/country'
import { useEffect, useState } from 'react'
import CheckoutMethodInfo from './CheckoutMethodInfo'
import sdk from '#utils/sdk'
import { type FeeSimulator, type Fee } from '#tackpay-sdk'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import { useIonRouter } from '@ionic/react'
import { getPreferences } from 'storage/preferences'

export default function CheckoutEpsForm(): JSX.Element {
  const { checkout } = useCheckoutContext()

  const stripe = useStripe()

  const elements = useElements()

  const { startLoading, stopLoading } = useLoading(
    'checkout.payment.processing'
  )

  const { showToast } = useToast('warning')

  const { push, routeInfo } = useIonRouter()

  const [name, setName] = useState('')

  const [payFee, setPayFee] = useState(false)

  const [terms, setTerms] = useState(false)

  const [amountWithFee, setAmountWithFee] = useState<number | null>(null)

  useEffect(() => {
    const fetchStorageFee = async (): Promise<void> => {
      try {
        const fee = await getPreferences<FeeSimulator>('feeSimulator')
        if (fee != null) {
          if (fee.covered) {
            setAmountWithFee(
              fee.totalFeeAmount + (checkout?.payment?.gross_amount_cents ?? 0)
            )
          } else {
            setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
          }
        } else {
          setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
        }
      } catch (err) {
        console.error('Failed to fetch fee simulator preferences:', err)
        setAmountWithFee(checkout?.payment?.gross_amount_cents ?? 0)
      }
    }

    const intervalId = setInterval(() => {
      void fetchStorageFee()
    }, 100)

    void fetchStorageFee() // Fetch iniziale

    return () => {
      clearInterval(intervalId)
    }
  }, [checkout])

  const handleNameChange: InputOnChange = (e) => {
    const value = e.target.value
    if (typeof value === 'string') setName(value)
  }

  const handleFee = async (): Promise<Fee> => {
    return await sdk.fees.create({
      covered: payFee,
      fee_category: 'payment',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const handlePaymentMethod = async (): Promise<void> => {
    await sdk.payment_methods.create({
      method_type: 'p24',
      payment: { id: checkout?.payment?.id ?? '', type: 'payments' }
    })
  }

  const classInput =
    'focus:ring-0 bg-white block w-full shadow-sm border sm:text-sm border-gray-200 focus:border-gray-500 focus:ring-gray-500 rounded-md p-4 text-xl'

  const handleEpsSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (stripe == null) {
      showToast(undefined, 'checkout.stripe.notLoaded')
    } else if (!terms) {
      showToast(undefined, 'checkout.terms.notAccepted')
    } else {
      const eps = elements?.getElement('epsBank')
      if (eps == null) {
        showToast(undefined, 'checkout.stripe.epsNotLoaded')
      } else {
        startLoading()

        handlePaymentMethod()
          .then(async () => {
            try {
              await handleFee()

              const url = window.location.href + '/handle-confirm'

              await stripe.confirmEpsPayment(
                checkout?.payment?.client_secret_reference ?? '',
                {
                  payment_method: { billing_details: { name }, eps },
                  return_url: url
                }
              )

              stopLoading()
              push(routeInfo.pathname + '/handle-confirm')
            } catch (error) {
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            }
          })
          .catch((error) => {
            stopLoading()
            showToast(undefined, getErrorMessage(error))
          })
      }
    }
  }
  return (
    <form onSubmit={handleEpsSubmit} className='space-y-4'>
      <div className='mt-4'>
        <Input
          name='firstName'
          value={name}
          onIonInput={handleNameChange}
          labelOptions={{ children: 'firstName' }}
        />
      </div>
      <div>
        <EpsBankElement className={classInput} />
      </div>
      <CheckoutFeeAndTerms
        payFee={payFee}
        terms={terms}
        setPayFee={setPayFee}
        setTerms={setTerms}
      />
      <CheckoutMethodInfo />
      <Button
        disabled={!terms}
        type='submit'
        className='w-full mt-3 ion-no-padding'
        translationOptions={{
          translate: 'yes',
          data: {
            amount:
              amountWithFee != null
                ? amountWithFee / 100
                : checkout?.payment?.amount_float ?? 0,
            currency: getSymbolCurrency(checkout?.payment?.currency ?? 'eur')
          }
        }}
      >
        confirmAmount
      </Button>
    </form>
  )
}
