/* eslint-disable prettier/prettier */
import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type Jar, type UserCategory } from '#tackpay-sdk'
import formattedAmount from '#utils/formattedAmount'
import { IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react'

interface Props {
  category: UserCategory
  jar: Jar
}

export default function TeamCardBusiness({
  category,
  jar
}: Props): JSX.Element {
  const baseHref = `/dashboard/${category}/jars`

  console.log(jar)

  const href =
    jar.jar_type === 'private'
      ? `${baseHref}/${jar.id}/settings`
      : `${baseHref}/${jar.id}/settings`

  const getColorCard = (): string => {
    if (jar.jar_type === 'private') {
      if (jar.avatar === DEFAULT_IMAGES.logoTeam) {
        return 'border-r-4 border-yellow-400'
      } else {
        return 'border-r-4 border-green-400'
      }
    } else {
      return 'border-r-4 border-green-400'
    }
  }

  const lengthMembers =
    jar?.members?.filter((m) => m.active).length === 1 ||
    jar?.members?.filter((m) => m.active).length === 2

  const controlAmountTip =
    formattedAmount(jar.balance?.total?.amount_cents).length > 3

  return (
    <Card
      data-testid='team-card-business'
      routerLink={jar.active ? href : undefined}
      routerDirection='root'
      href={jar.active ? href : undefined}
      padding='pl-3 py-3 pr-1'
      className={`cursor-pointer ${getColorCard()} ${jar.active ? '' : 'opacity-50'}`}
    >
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-justify-content-between ion-align-items-center ion-no-padding ion-no-margin'>
          <IonCol size='2.6' className='ion-no-padding ion-no-margin'>
            <Avatar
              avatarOptions={{
                className: 'w-14 h-14'
              }}
              imgOptions={{
                src: jar.avatar ?? DEFAULT_IMAGES.logoTeam,
                alt: jar.name
              }}
            />
          </IonCol>
          <IonCol
            className='ion-align-items-center ion-justify-content-between ion-no-padding ion-no-margin'
            size='6.8'
          >
            <IonRow className='w-full ion-justify-content-start ion-no-padding ion-no-margin'>
              <IonCardTitle
                className='text-lg truncate'
                data-testid={`card-jar-${jar.name}`}
              >
                {jar.name}
              </IonCardTitle>
            </IonRow>

            <IonRow className='ion-no-padding ion-no-margin w-full'>
              <IonRow
                className={`ion-justify-content-start ion-align-items-center ${controlAmountTip ? 'space-x-2' : 'space-x-3'}  mt-1 w-full`}
              >
                <IonRow
                  className={`${controlAmountTip ? 'space-x-1' : 'space-x-2'}`}
                >
                  <Text translationOptions={{ translate: 'no' }} size='text-xs'>
                    💰
                  </Text>
                  <Text
                    translationOptions={{ translate: 'no' }}
                    fontWeight='font-semibold'
                    size='text-xs'
                    data-testid={`amount-${jar.name}`}
                  >
                    {formattedAmount(
                      jar.balance?.total?.amount_cents,
                      jar.currency
                    )}
                  </Text>
                </IonRow>
                <IonRow
                  className={`${controlAmountTip ? 'space-x-1' : 'space-x-2'}`}
                >
                  <Text translationOptions={{ translate: 'no' }} size='text-xs'>
                    🏅
                  </Text>
                  <Text
                    translationOptions={{ translate: 'no' }}
                    fontWeight='font-semibold'
                    size='text-xs'
                  >
                    {jar.total_feedback}
                  </Text>
                </IonRow>
                <IonRow
                  className={`${controlAmountTip ? 'space-x-1' : 'space-x-2'}`}
                >
                  <Text translationOptions={{ translate: 'no' }} size='text-xs'>
                    💬
                  </Text>
                  <Text
                    translationOptions={{ translate: 'no' }}
                    fontWeight='font-semibold'
                    size='text-xs'
                    data-testid={`total_comment-${jar.name}`}
                  >
                    {jar.total_comment}
                  </Text>
                </IonRow>
              </IonRow>
            </IonRow>
          </IonCol>
          <IonCol size='2.6' className='ion-no-padding ion-no-margin'>
            <IonRow
              className={`-space-x-1 overflow-hidden ion-text-center 
              ${lengthMembers
                  ? 'ion-justify-content-center'
                  : 'ion-justify-content-end'
                } ion-no-padding ion-no-margin`}
            >
              {jar.members != null &&
                jar.members.length > 0 &&
                jar?.members
                  ?.filter((m) => m.status === 'invitation_accepted')
                  ?.map((member, i) => {
                    if (i < 2) {
                      return (
                        <Avatar
                          key={member.id}
                          avatarOptions={{
                            className: 'inline-block ring-2 ring-white w-6 h-6'
                          }}
                          imgOptions={{
                            src:
                              member?.user?.avatar ??
                              DEFAULT_IMAGES.avatarTipped,

                            alt: 'Avatar of Jonathan Reinink'
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
              {jar.members != null && jar.members.length >= 3 && (
                <div className='w-6 h-6 rounded-full border border-white bg-gray-300 text-white font-semibold text-[0.5rem] flex justify-center items-center'>{`+ ${jar.members.length - 2
                  }`}</div>
              )}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
