import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  ApiResource,
  type ResourceId,
  type ResourcesConfig
} from 'sdk/resource'
import { type User } from './users'
import { type QueryParamsRetrieve } from 'sdk/query'
import { type Company } from './companies'
import { type Deduction } from './deductions'

type DeductionReportType = 'deduction_reports'
type DeductionReportRel = ResourceRel & { type: DeductionReportType }

type UserRel = ResourceRel & { type: 'users' }
type CompanyRel = ResourceRel & { type: 'companies' }

interface DeductionReport extends Resource {
  readonly type: DeductionReportType
  storage_path?: string
  storage_url?: string
  from_date: string
  to_date: string
  company?: Company
  user?: User
  deductions?: Deduction[]
}

interface DeductionReportCreate extends ResourceCreate {
  from_date: string
  to_date: string
  company?: CompanyRel
  user?: UserRel
}

class DeductionReports extends ApiResource<DeductionReport> {
  static readonly TYPE: DeductionReportType = 'deduction_reports' as const

  async create(
    resource: DeductionReportCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<DeductionReport> {
    return await this.resources.create<DeductionReportCreate, DeductionReport>(
      { ...resource, type: DeductionReports.TYPE },
      params,
      options
    )
  }

  isDeductionReport(resource: any): resource is DeductionReport {
    return resource.type != null && resource.type === DeductionReports.TYPE
  }

  relationship(id: string | ResourceId | null): DeductionReportRel {
    return id === null || typeof id === 'string'
      ? { id, type: DeductionReports.TYPE }
      : { id: id.id, type: DeductionReports.TYPE }
  }

  type(): DeductionReportType {
    return DeductionReports.TYPE
  }
}

export type { DeductionReport, DeductionReportCreate }
export default DeductionReports
