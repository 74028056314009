/* eslint-disable prettier/prettier */
import { IonCol, IonRow } from '@ionic/react'
import { RotatingLines } from 'react-loader-spinner'

export default function ModalLoading(): JSX.Element {
    return (
        <div className='w-full h-full'>
            <IonRow className='w-2/3 h-full mx-auto ion-justify-content-center ion-align-items-center'>
                <IonCol className='ion-no-padding ion-no-margin' size='2'>
                    <RotatingLines
                        visible
                        width='100%'
                        strokeColor='#71e89d'
                        strokeWidth='5'
                        animationDuration='0.75'
                        ariaLabel='rotating-lines-loading'
                    />
                </IonCol>
            </IonRow>
        </div>
    )
}
