import Card from '#components/ui/Card'
import { IonCardHeader, IonCol, IonRow } from '@ionic/react'
import AvatarSkeleton from './AvatarSkeleton'
import Skeleton from '#components/ui/Skeleton'

interface Props {
  /**
   * Has avatar
   * Default: `true`
   */
  hasAvatar?: boolean

  className?: string
}
export default function CardSkeleton(props: Props): JSX.Element {
  const { hasAvatar = true, className } = props
  return (
    <Card className={className}>
      <IonCardHeader className='ion-no-padding'>
        <IonRow className='ion-no-padding ion-justify-content-start ion-align-items-center'>
          {hasAvatar && <AvatarSkeleton />}
          <IonCol className='ml-5'>
            <Skeleton className='w-10' animated />
            <Skeleton className='w-24' animated />
            <Skeleton className='w-28' animated />
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </Card>
  )
}
