import ErrorBox from '#components/errors/ErrorBox'
import { useCheckoutContext } from '#contexts/CheckoutContext'
import { type LinksSocial, type CheckoutSetting } from '#tackpay-sdk'
import { IonCol, IonRow } from '@ionic/react'
import CheckoutSkeleton from '../CheckoutSkeleton'
import Avatar from '#components/ui/Avatar'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import Underlined from '#components/ui/UnderlinedName'
import Text from '#components/ui/Text'
import Button from '#components/ui/Button'
import useLoading from '#hooks/useLoading'
import sdk from '#utils/sdk'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import isWeb from '#utils/isWeb'
import useStorage from '#hooks/useStorage'
import fileSave from 'file-saver'
import { useShare } from '#hooks/useShare'
import CheckoutLinksSocial from './LinksSocial'
import { downloadOutline } from 'ionicons/icons'

export default function CheckoutBillContent(): JSX.Element {
  const TIMEOUT_DOWNLOAD = 1000 * 8 // 8 seconds

  const { isLoading, error, checkout } = useCheckoutContext()

  const { startLoading, stopLoading } = useLoading('checkout.bill.loading')

  const { showToast } = useToast('warning')

  const { showToast: successToast } = useToast('success')

  const { getBlobImage } = useStorage()

  const { shareElement } = useShare()

  if (isLoading) return <CheckoutSkeleton />

  if (error != null) return <ErrorBox error={error} />

  if (checkout == null)
    return (
      <ErrorBox
        error={{
          title: 'Checkout not found',
          detail: 'The checkout you are looking for does not exist',
          code: 'checkout_not_found',
          status: 422
        }}
      />
    )

  const getCheckoutSetting = (): CheckoutSetting | undefined => {
    if (checkout?.jar != null) return checkout.jar.checkout_setting
    return checkout?.user?.checkout_setting
  }

  const getAvatar = (): string => {
    const checkoutSetting = getCheckoutSetting()
    if (checkoutSetting?.avatar != null) return checkoutSetting.avatar
    if (checkout.jar == null) return DEFAULT_IMAGES.avatarTipped
    return DEFAULT_IMAGES.logoTeam
  }

  const getName = (): string => {
    if (checkout?.jar != null)
      return checkout.jar?.checkout_setting?.username ?? checkout.jar.name
    return (
      checkout?.user?.checkout_setting?.username ??
      checkout.user?.person?.first_name ??
      ''
    )
  }

  const getReply = (): string | undefined => {
    const checkoutSetting = getCheckoutSetting()
    if (checkoutSetting?.reply != null) return checkoutSetting.reply.message
    return undefined
  }

  const getLinksSocial = (): LinksSocial | undefined => {
    const checkoutSetting = getCheckoutSetting()
    if (checkoutSetting?.links_social != null)
      return checkoutSetting.links_social
    return undefined
  }

  const handleBill = (): void => {
    startLoading()

    sdk.invoices
      .create({
        category: 'tipper',
        payment: {
          id: checkout?.payment?.id ?? '',
          type: 'payments'
        }
      })
      .then((res) => {
        setTimeout(() => {
          handleDownload(res.storage_path_url ?? '')
        }, TIMEOUT_DOWNLOAD)
      })
      .catch((error) => {
        console.log(error)
        stopLoading()
        showToast(undefined, getErrorMessage(error))
      })
  }

  const handleDownload = (storageUrl: string): void => {
    if (isWeb()) {
      getBlobImage(storageUrl, 'invoice')
        .then((blob) => {
          stopLoading()
          successToast(undefined, 'checkout.bill.success')
          fileSave(blob, 'invoice.pdf')
        })
        .catch((error) => {
          stopLoading()
          console.log('error', error)
          showToast(undefined, getErrorMessage(error))
        })
    } else {
      void shareElement({
        text: 'Checkout my store',
        title: 'Checkout my store',
        url: storageUrl,
        dialogTitle: 'Share with friends'
      })
    }
  }
  return (
    <>
      <IonRow>
        <IonCol>
          <Avatar
            imgOptions={{
              src: getAvatar()
            }}
            avatarOptions={{
              className: ' border-4 mx-auto h-28 w-28 border-green-400'
            }}
          />
          <Underlined name={getName()} />
        </IonCol>
      </IonRow>
      {getReply() != null && (
        <IonRow className='ion-justify-content-center mt-8'>
          <Text>{getReply()}</Text>
        </IonRow>
      )}
      {getLinksSocial() != null && getLinksSocial()?.active === true && (
        <CheckoutLinksSocial linksSocial={getLinksSocial() as LinksSocial} />
      )}
      <IonRow className='mt-20'>
        <Button className='w-full' href='https://tackpay.net' target='_blank'>
          visitWebSite
        </Button>
      </IonRow>
      <IonRow className='mt-40 ion-justify-content-center ion-align-items-center'>
        <Button
          className='underline ion-no-padding'
          onClick={handleBill}
          color='dark'
          fill='clear'
          iconOptions={{ icon: downloadOutline }}
        >
          checkout.bill.title
        </Button>
      </IonRow>
    </>
  )
}
