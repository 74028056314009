import { IonRow } from '@ionic/react'
import Text from '../components/ui/Text'
import Title from '../components/ui/Title'
import Link from '../components/ui/Link'
import AuthLayout from '../components/layouts/auth/AuthLayout'
import LoginForm from '#components/pages/login/LoginForm'
import LoginWithGoogle from '#components/pages/login/LoginWithGoogle'

export default function Login(): JSX.Element {
  return (
    <AuthLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: '/'
        },
        showLanguageSwitcher: true
      }}
    >
      <IonRow className='w-full ion-no-padding mb-10 mt-0 mx-0'>
        <Title className='ion-no-margin ion-no-padding'>login.title</Title>
      </IonRow>
      <LoginForm />
      <IonRow className='ion-justify-content-center ion-align-items-center mt-10'>
        <LoginWithGoogle />
      </IonRow>
      <IonRow className='ion-justify-content-center ion-text-center mt-10 space-x-2'>
        <Text>missingAccount</Text>
        <Link link='/registrations'>
          <Text color='primary' data-testid='go-to-registrations'>
            signUp
          </Text>
        </Link>
      </IonRow>
    </AuthLayout>
  )
}
