import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type Jar } from './jars'
import { type Payment } from './payments'

type DeductionType = 'deductions'
type DeductionRel = ResourceRel & { type: DeductionType }

interface Deduction extends Resource {
  readonly type: DeductionType
  percentage: number
  amount: number
  jar?: Jar
  payment?: Payment
  currency: string
  transferred: boolean
  booked_balance: boolean
}

class Deductions extends ApiResource<Deduction> {
  static readonly TYPE: DeductionType = 'deductions' as const

  isDeduction(resource: any): resource is Deduction {
    return resource.type != null && resource.type === Deductions.TYPE
  }

  relationship(id: string | ResourceId | null): DeductionRel {
    return id === null || typeof id === 'string'
      ? { id, type: Deductions.TYPE }
      : { id: id.id, type: Deductions.TYPE }
  }

  type(): DeductionType {
    return Deductions.TYPE
  }
}

export type { Deduction, DeductionRel }
export default Deductions
