import Text from '#components/ui/Text'
import { IonRow } from '@ionic/react'

interface Props {
  active: boolean
}
export default function MemberActiveChip({ active }: Props): JSX.Element {
  const getColor = (): string => {
    if (active) return 'success'
    return 'danger'
  }

  const getColorBg = (): string => {
    if (active) return 'bg-green-100'
    return 'bg-red-100'
  }

  return (
    <IonRow
      className={`w-full py-1.5 px-1 ion-no-margin rounded-2xl ${getColorBg()} ion-justify-content-center`}
    >
      <Text size='text-sm' className='text-center' color={getColor()}>
        {active ? 'active' : 'inactive'}
      </Text>
    </IonRow>
    // <Chip color={getColor()} className='w-full ion-no-margin'>
    //   {active ? 'active' : 'inactive'}
    // </Chip>
  )
}
