import {
  ApiResource,
  type ResourceId,
  type Resource,
  type ResourceRel
} from 'sdk/resource'
import { type Balance } from './balances'

type BalanceTransactionType = 'balance_transactions'
type BalanceTransactionRel = ResourceRel & { type: BalanceTransactionType }

type BalanceTransactionStatus =
  | 'available'
  | 'pending'
  | 'instant_payout'
  | 'tronc'

type BalanceTransactionCategoryType =
  | 'transfer'
  | 'tranfer_tronc'
  | 'adjustment'
  | 'payout'
  | 'payout_fee'
  | 'payment'
  | 'payout_reversal'
  | 'payout_instant'
  | 'payment_refund'
  | 'transfer_refund'
  | 'other'

type BalanceOperationType = 'debit' | 'credit'

interface BalanceTransaction extends Resource {
  readonly type: BalanceTransactionType
  amount_cents: number
  net_cents: number
  status: BalanceTransactionStatus
  transaction_type: BalanceTransactionCategoryType
  operation_type: BalanceOperationType
  currency: string
  description?: string
  fee_cents?: number
  balance?: Balance
}

class BalanceTransactions extends ApiResource<BalanceTransaction> {
  static readonly TYPE: BalanceTransactionType = 'balance_transactions' as const

  isBalanceTransaction(resource: any): resource is BalanceTransaction {
    return resource.type != null && resource.type === BalanceTransactions.TYPE
  }

  relationship(id: string | ResourceId | null): BalanceTransactionRel {
    return id === null || typeof id === 'string'
      ? { id, type: BalanceTransactions.TYPE }
      : { id: id.id, type: BalanceTransactions.TYPE }
  }

  type(): BalanceTransactionType {
    return BalanceTransactions.TYPE
  }
}

export type { BalanceTransaction }
export default BalanceTransactions
