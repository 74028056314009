import AuthLayout from '#components/layouts/auth/AuthLayout'
import ResetPasswordSection from '#components/pages/resetPassword/ResetPasswordSection'
import Title from '#components/ui/Title'
import TokenContainer from '#contexts/TokenContext'
import getQueryParams from '#utils/getQueryParams'
import { useIonRouter } from '@ionic/react'

export default function ResetPassword(): JSX.Element {
  const { routeInfo } = useIonRouter()

  const queryParams = getQueryParams(routeInfo.search)

  const token = queryParams.get('token')

  return (
    <AuthLayout
      headerOptions={{
        showLanguageSwitcher: true,
        showLogo: true
      }}
    >
      <Title>resetPassword.title</Title>
      <TokenContainer fetch value={token ?? ''}>
        <ResetPasswordSection />
      </TokenContainer>
    </AuthLayout>
  )
}
