import { IonCard } from '@ionic/react'
import classNames from 'classnames'
import { type ComponentProps } from 'react'

interface CardProps extends ComponentProps<typeof IonCard> {
  /**
   * The card padding
   * @default 'ion-padding'
   */
  padding?: string
  /**
   * The card margin
   * @default 'ion-no-margin'
   */
  margin?: string
  ['data-testid']?: string
}

export default function Card(props: CardProps): JSX.Element {
  const { padding, margin, children, ...ionCardProps } = props

  const cardPaddingDefault = padding ?? 'ion-padding'

  const cardMarginDefault = margin ?? 'ion-no-margin'

  const color = ionCardProps?.color ?? 'bg-card'

  const cardClass = classNames(
    ionCardProps.className,
    cardPaddingDefault,
    cardMarginDefault
  )
  return (
    <IonCard {...ionCardProps} className={cardClass} color={color}>
      {children}
    </IonCard>
  )
}
