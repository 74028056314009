import { IonCol, IonProgressBar } from '@ionic/react'
import Text from './Text'
import Chip from './Chip'
import { type ComponentProps } from 'react'

interface Props {
  /**
   * The text to display in the chip
   */
  text: string
  /**
   * The value of the progress bar
   */
  value: number
  /**
   * The description of the progress bar
   */
  description?: string
  /**
   * The color of the chip
   */
  colorChip?: ComponentProps<typeof Chip>['color']
  /**
   * The color of the progress bar
   */
  colorBar?: ComponentProps<typeof IonProgressBar>['color']
  /**
   * data-testid
   */

  testId?: string

  showDescription?: boolean
}
export default function ProgressBar(props: Props): JSX.Element {
  const { text, value, description, colorChip, colorBar, testId } = props
  const chipColor = colorChip ?? 'green-700'
  const barColor = colorBar ?? 'success'

  return (
    <IonCol className='ion-no-padding'>
      <Chip
        color={chipColor}
        className='ion-no-margin rounded-md'
        testId={text}
      >
        {text}
      </Chip>
      <IonProgressBar
        type='determinate'
        value={value}
        className='h-3 rounded-md my-3 '
        color={barColor}
        data-testid={testId}
      />
      {props.showDescription === true ? (
        <Text className='mt-4' data-testid={description}>
          {description}
        </Text>
      ) : null}
    </IonCol>
  )
}
