// export type ApiMode = 'test' | 'live'
const config = {
  default: {
    environment: 'production',
    protocol: process.env.REACT_APP_API_PROTOCOL ?? 'https',
    domain:
      process.env.REACT_APP_ENV === 'development'
        ? `${process.env.REACT_APP_API_HOST}`
        : 'api.tackpay.net',
    pageNumber: 1,
    pageSize: 10
  },
  client: {
    timeout: 15000,
    requiredAttributes: []
  }
} as const

export default config
