import Chip from '#components/ui/Chip'
import Text from '#components/ui/Text'
import { type StatisticFeedback } from '#tackpay-sdk'
import { IonRow } from '@ionic/react'

interface Props {
  statisticFeedback: StatisticFeedback
}

export default function MostFeedbackReceived({
  statisticFeedback
}: Props): JSX.Element {
  return (
    <IonRow className='ion-align-items-center space-x-5 mb-8'>
      <Text color='gray-900' fontWeight='font-semibold'>
        feedbackPage.mostPopular
      </Text>
      <Chip color='green-500' className='capitalize'>
        {`feedback.${statisticFeedback.most_popular_feedback}`}
      </Chip>
    </IonRow>
  )
}
