import Button from '#components/ui/Button'
import Modal from '#components/ui/Modal'
import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonRow,
  IonToolbar
} from '@ionic/react'
import TabCheckout from '../checkouts/TabCheckout'
import { isBusiness } from '#utils/isBusiness'
import { type CheckoutSettingUpdate, type UserCategory } from '#tackpay-sdk'
import { useCheckoutSettingContext } from '#contexts/CheckoutSettingContext'
import SlidesAvatarMember from '../checkouts/SlideAvatarMembers'
import SingleAvatarTipped from '#components/ui/SingleAvatarTipped'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import DefaultTipButton from '#components/ui/DefaultTipButton'
import Text from '#components/ui/Text'
import { useTeamContext } from '#contexts/JarContext'
import { useState } from 'react'
import formattedAmount from '#utils/formattedAmount'
import { getSymbolCurrency } from '#constants/country'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import getErrorMessage from '#utils/getErrorMessage'
import isDesktop from '#utils/isDesktop'

interface Props {
  jarId?: string
  show: boolean
  setShow: (show: boolean) => void
  category: UserCategory
}

export default function CheckoutSettingPreview({
  setShow,
  show,
  jarId,
  category
}: Props): JSX.Element {
  const { checkoutSetting, updateCheckoutSetting, refetch } =
    useCheckoutSettingContext()

  const { jar } = useTeamContext()

  const { startLoading, stopLoading } = useLoading(
    'checkoutSetting.update.loading'
  )

  const { showToast } = useToast('warning')

  const { showToast: showSuccess } = useToast('success')

  const [currentAmount, setCurrentAmount] = useState<number>(0)

  const handleConfirm = (): void => {
    if (updateCheckoutSetting != null) {
      setShow(false)
      startLoading()

      updateCheckoutSetting.mutate(
        {
          checkoutSetting: checkoutSetting as CheckoutSettingUpdate,
          linksSocial: checkoutSetting?.links_social,
          reply: checkoutSetting?.reply
        },
        {
          onSuccess: () => {
            stopLoading()
            showSuccess(undefined, 'checkoutSetting.update.success')
            if (refetch != null) {
              void refetch()
            }
          },
          onError: (error) => {
            const err = error as any
            stopLoading()
            if (
              'code' in err &&
              'detail' in err &&
              err.code === 'checkout_setting_invalid_amount_min'
            ) {
              showToast(undefined, err.detail)
            } else {
              showToast(undefined, getErrorMessage(error))
            }
          }
        }
      )
    }
  }

  const viewDesktop = isDesktop() ? 'm-auto w-5/6' : 'w-full'

  const justifyMobil =
    window.screen.width >= 414
      ? 'ion-justify-content-between'
      : 'ion-justify-content-around'

  return (
    <Modal isOpen={show} setShowModal={setShow}>
      <IonHeader color='green-400'>
        <IonToolbar color='green-400'>
          <IonButtons slot='start'>
            <Button
              onClick={() => {
                setShow(false)
              }}
              color='dark'
            >
              Cancel
            </Button>
          </IonButtons>
          <IonButtons slot='end'>
            <Button strong onClick={handleConfirm}>
              Confirm
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding' scrollY>
        <TabCheckout section='select' />
        <IonRow className='ion-justify-content-center ion-align-items-center mx-auto'>
          <IonCol
            className='md:space-y-4 ion-no-padding md:ion-padding-vertical'
            size='12'
          >
            {isBusiness(category) ? (
              <>
                {checkoutSetting?.show_member === true ? (
                  <SlidesAvatarMember
                    checkoutSetting={checkoutSetting}
                    members={jar?.members}
                  />
                ) : (
                  <SingleAvatarTipped
                    name={checkoutSetting?.username}
                    bio={checkoutSetting?.description}
                    avatar={checkoutSetting?.avatar ?? DEFAULT_IMAGES.logoTeam}
                  />
                )}
              </>
            ) : (
              <SingleAvatarTipped
                name={checkoutSetting?.username}
                bio={checkoutSetting?.description}
                avatar={checkoutSetting?.avatar ?? DEFAULT_IMAGES.logoTeam}
              />
            )}

            <IonRow className='w-full h-5 mt-2' />

            <DefaultTipButton
              tip={checkoutSetting?.fast_amounts?.map((amount) =>
                formattedAmount(amount)
              )}
              amount={currentAmount}
              setAmount={setCurrentAmount}
              currency={checkoutSetting?.currency}
            />

            <IonRow className={`${viewDesktop} ${justifyMobil} pt-5 px-4 mt-3`}>
              <IonCol size='5.5' className='ion-no-padding ion-no-margin'>
                {checkoutSetting?.show_custom_amount === true && (
                  <Button
                    type='button'
                    id='modal-custom'
                    color='white'
                    className='choose-button-preview ion-no-margin rounded-md w-full'
                  >
                    <Text
                      className='text-gray-400 text-lg'
                      fontWeight='font-bold'
                    >
                      {jar?.company?.id ===
                      '9c86881d-23f5-4cf4-9716-c7257ed4a914'
                        ? 'Personalizar'
                        : 'type_amount'}
                    </Text>
                  </Button>
                )}
              </IonCol>
              <IonCol size='5.5' className='ion-no-padding ion-no-margin'>
                {checkoutSetting?.show_percentage_amount === true && (
                  <Button
                    type='button'
                    id='modal-custom'
                    color='white'
                    className='choose-button-preview ion-no-margin rounded-md w-full'
                  >
                    <Text
                      className='text-gray-400 text-lg'
                      fontWeight='font-bold'
                    >
                      %
                    </Text>
                  </Button>
                )}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <Button
          className='w-full mt-12 ion-no-padding cursor-none'
          translationOptions={{
            translate: 'yes',
            data: {
              amount: currentAmount === 0 ? '' : currentAmount,
              currency:
                currentAmount === 0
                  ? ''
                  : getSymbolCurrency(checkoutSetting?.currency ?? 'eur')
            }
          }}
        >
          confirmAmount
        </Button>
      </IonContent>
    </Modal>
  )
}
