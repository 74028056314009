import useAuthState from '#hooks/useAuthState'
import { type QueryParamsRetrieve, type StatisticAmount } from '#tackpay-sdk'
import sdk from '#utils/sdk'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

interface StatisticAmountContextValue {
  statisticAmount?: StatisticAmount | null
  isLoading: boolean
  error?: any
}

const statisticAmountInitialValue: StatisticAmountContextValue = {
  statisticAmount: undefined,
  isLoading: false,
  error: null
}

const StatisticAmountContext = createContext<StatisticAmountContextValue>(
  statisticAmountInitialValue
)

export const useStatisticAmountContext = (): StatisticAmountContextValue => {
  const context = useContext(StatisticAmountContext)
  if (context == null) {
    throw new Error(
      'useStatisticAmountContext must be used within a StatisticAmountProvider'
    )
  }

  return context
}

interface StatisticAmountContainerProps {
  children: React.ReactNode
  /**
   * @default true
   */
  fetch?: boolean

  jarId?: string

  params?: QueryParamsRetrieve
}

export default function StatisticAmountContainer(
  props: StatisticAmountContainerProps
): JSX.Element {
  const { children, fetch = true, params, jarId } = props

  const { getAuthState } = useAuthState()

  const {
    data: statisticAmount,
    isLoading,
    error
  } = useQuery<StatisticAmount | undefined | null>({
    queryKey: ['statisticAmount', jarId, params],
    queryFn: async () => {
      if (jarId != null) {
        return await sdk.jars.statistic_amount(jarId, params)
      } else {
        const authInfo = await getAuthState()
        return await sdk.users.statistic_amount(authInfo?.userId ?? '', params)
      }
    },
    enabled: fetch
  })

  // Aggiorniamo i dati utente se la prop "fetch" cambia
  // useEffect(() => {
  //   if (fetch) {
  //     void queryClient.invalidateQueries(['statisticAmount', jarId, params])
  //     setInitialFetchComplete(true) // Imposta il flag initialFetchComplete a true
  //   }
  //   console.log('Jar statistic', statisticAmount)
  // }, [fetch, queryClient])

  const statisticAmountContextValue: StatisticAmountContextValue = {
    statisticAmount,
    isLoading,
    error
  }

  return (
    <StatisticAmountContext.Provider value={statisticAmountContextValue}>
      {children}
    </StatisticAmountContext.Provider>
  )
}
