import AuthLayout from '#components/layouts/auth/AuthLayout'
import { IonCol, IonImg, IonRow, useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { getPreferences } from 'storage/preferences'
import Text from '#components/ui/Text'
import formattedAmount from '#utils/formattedAmount'
import { getSymbolCurrency } from '#constants/country'
import Label from '#components/ui/Label'
import Button from '#components/ui/Button'
import Input from '#components/ui/Input'
import Or from '#components/common/Or'
import useLoading from '#hooks/useLoading'
import CheckoutFeeAndTerms from '#components/pages/checkouts/methods/CheckoutFeeAndTerms'
import TabCheckout from '#components/pages/checkouts/TabCheckout'
import { useParams } from 'react-router'

const HandleApplePay = ({ category }: { category: string }): JSX.Element => {
  const [payFee, setPayFee] = useState(false)

  const [terms, setTerms] = useState(false)

  const { push } = useIonRouter()

  const { startLoading: handleStartLoading, stopLoading: handleStopLoading } =
    useLoading('checkout.payment.processing')

  const handleClick = (): void => {
    handleStartLoading()
    setTimeout(() => {
      handleStopLoading()
      push(`/demo/usa/${category}/reviews`)
    }, 1000 * 1)
  }
  return (
    <>
      <IonRow className='ion-justify-content-center pt-8'>
        <Button
          className='btn-apple h-12 w-full'
          color='bg-black'
          onClick={handleClick}
        >
          <IonImg src='/assets/img/apple-logo.svg' className='w-14' />
        </Button>
      </IonRow>
      <IonRow className='pt-5'>
        <Button
          className=' h-12 w-full shadow'
          color='bg-white'
          onClick={handleClick}
        >
          <IonImg src='/assets/img/google-pay.svg' className='w-14' />
        </Button>
      </IonRow>
      <IonRow className='pt-5'>
        <CheckoutFeeAndTerms
          payFee={payFee}
          terms={terms}
          setPayFee={setPayFee}
          setTerms={setTerms}
        />
      </IonRow>
      <Or />
    </>
  )
}

const HandleMethods = ({ amount }: { amount: number }): JSX.Element => {
  const [payFee, setPayFee] = useState(false)

  const [terms, setTerms] = useState(false)
  const isSubmittable = (): boolean => {
    return terms && amount > 0
  }

  const classInput =
    'mt-1 focus:ring-0 bg-white block w-full shadow-sm border sm:text-sm border-gray-300 focus:border-gray-500 focus:ring-gray-500 rounded-md p-3 text-xl'
  const { push } = useIonRouter()

  const { startLoading: handleStartLoading, stopLoading: handleStopLoading } =
    useLoading('checkout.payment.processing')

  const handleSubmit = (e: any): void => {
    e.preventDefault()
    handleStartLoading()
    setTimeout(() => {
      handleStopLoading()
      push(`/demo/reviews`)
    }, 1000 * 1)
  }

  return (
    <IonRow className='ion-justify-content-center'>
      <form className='pt-10 space-y-6' onSubmit={handleSubmit}>
        <IonRow className=''>
          <div>
            <Label capitalize={false}>card.number</Label>
          </div>
          <Input value='42424242424242424242' className={classInput} />
        </IonRow>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCol size='6'>
            <IonRow className='ion-no-margin ion-no-padding'>
              <Label capitalize={false}>card.expiration</Label>
              <Input value='12/26' className={classInput} />
            </IonRow>
          </IonCol>
          <IonCol size='6'>
            <IonRow className='ion-no-margin ion-no-padding'>
              <Label capitalize={false}>card.cvc</Label>
              <Input value='123' className={classInput} />
            </IonRow>
          </IonCol>
        </IonRow>
        <CheckoutFeeAndTerms
          payFee={payFee}
          terms={terms}
          setPayFee={setPayFee}
          setTerms={setTerms}
        />
        <Button
          type='submit'
          disabled={!isSubmittable()}
          className='w-full mt-12 ion-no-padding'
          translationOptions={{
            translate: 'yes',
            data: {
              amount: formattedAmount(amount),
              currency: getSymbolCurrency('eur')
            }
          }}
        >
          confirmAmount
        </Button>
      </form>
    </IonRow>
  )
}

export default function DemoMethodUsa(): JSX.Element {
  const { category } = useParams<{ category: string }>()

  const [amount, setAmont] = useState<number>(0)

  const [amountCheckout, setAmountCheckout] = useState<number>(0)

  useEffect(() => {
    getPreferences<number>('amount_checkout')
      .then((value) => {
        if (value != null) {
          setAmountCheckout(value)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    getPreferences<number>('amount_checkout')
      .then((value) => {
        if (value != null) {
          setAmont(value)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true,
        isUsa: true
      }}
    >
      <TabCheckout section='tip' />
      <IonRow className=' ion-justify-content-center py-5'>
        <Text
          className=''
          size='text-6xl'
          color='gray-900'
          fontWeight='font-extrabold'
        >
          {formattedAmount(amount)}
        </Text>
        <Text
          className=' pt-6'
          color='gray-900'
          size='text-2xl'
          fontWeight='font-extrabold'
        >
          {getSymbolCurrency('eur')}
        </Text>
      </IonRow>
      <HandleApplePay category={category} />
      <HandleMethods amount={amountCheckout} />
    </AuthLayout>
  )
}
