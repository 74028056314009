import Label from '#components/ui/Label'
import Text from '#components/ui/Text'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import CollaboratorPendingList from './CollaboratorPendingList'
import { useState } from 'react'
import CollaboratorConfirmList from './CollaboratorConfirmList'

export default function CollaboratorListSection(): JSX.Element {
  const [active, setActive] = useState('active')

  const handleSegmentChange = (e: CustomEvent): void => {
    setActive(e.detail.value)
  }

  return (
    <div className='mt-10'>
      <Text
        color='gray-900'
        fontWeight='font-semibold'
        data-testid='collaborator.your'
      >
        collaborator.your
      </Text>
      <IonSegment
        value={active}
        className='mt-5'
        onIonChange={handleSegmentChange}
        swipeGesture
      >
        <IonSegmentButton value='active' data-testid='active_collaborator'>
          <Label>collaborator.active</Label>
        </IonSegmentButton>
        <IonSegmentButton
          value='invited'
          data-testid='invited_segment_collaborator'
        >
          <Label>collaborator.invited</Label>
        </IonSegmentButton>
      </IonSegment>
      {active === 'active' && <CollaboratorConfirmList />}
      {active === 'invited' && <CollaboratorPendingList />}
    </div>
  )
}
