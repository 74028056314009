import {
  ApiResource,
  type ResourceRel,
  type Resource,
  type ResourceId
} from 'sdk/resource'

import { type Member } from './members'
import { type User } from './users'
import { type Company } from './companies'
import { type Jar } from './jars'

type StatisticFeedbackType = 'statistic_feedbacks'
type StatisticFeedbackRel = ResourceRel & { type: StatisticFeedbackType }

type StatisticFeedbackCategory = 'tipped' | 'jar' | 'member'

interface FeedbackStatistic {
  /**
   * The name of feedback
   */
  name: string
  /**
   * The feedback id
   */
  feedbackId: string

  category: StatisticFeedbackCategory
}

type FeedbackCategory = 'tipped' | 'jar'

interface StatisticFeedback extends Resource {
  readonly type: StatisticFeedbackType
  /**
   * The feedbacks that are related to this statistic.
   */
  feedbacks: FeedbackStatistic[]
  /**
   * The total number of feedbacks that are related to this statistic.
   */
  total_feedbacks: number
  /**
   * The most popular feedback that is related to this statistic.
   */
  most_popular_feedback: string
  /**
   * The category of the most popular feedback that is related to this statistic.
   */
  most_popular_category: FeedbackCategory

  user?: User

  company?: Company

  jar?: Jar

  member?: Member | null
}

class StatisticFeedbacks extends ApiResource<StatisticFeedback> {
  static TYPE: StatisticFeedbackType = 'statistic_feedbacks'

  isStaticFeedback(resource: any): resource is StatisticFeedback {
    return resource.type != null && resource.type === StatisticFeedbacks.TYPE
  }

  relationship(id: string | ResourceId | null): StatisticFeedbackRel {
    return id === null || typeof id === 'string'
      ? { id, type: StatisticFeedbacks.TYPE }
      : { id: id.id, type: StatisticFeedbacks.TYPE }
  }

  type(): StatisticFeedbackType {
    return StatisticFeedbacks.TYPE
  }
}

export default StatisticFeedbacks

export type { StatisticFeedback, StatisticFeedbackRel, StatisticFeedbackType }
