import AppLayout from '#components/layouts/app/AppLayout'
import { ListLinkTerms } from '#components/ui/ListLinkTerms'
import Title from '#components/ui/Title'
import { TERMS } from '#constants/terms'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { useParams } from 'react-router'

export default function TermsAndConditions(): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()
  const termsOptions = TERMS.filter((w) => w.category === category)
  const termsList = termsOptions.map((w) => {
    return {
      label: w.label,
      href: w.link,
      icon: w.icon
    }
  })

  return (
    <AppLayout
      headerOptions={{
        showBackButton: true,
        backButtonProps: {
          defaultHref: `/dashboard/${category}/settings`
        }
      }}
    >
      <Title>terms.title</Title>
      <ListLinkTerms items={termsList} />
    </AppLayout>
  )
}
