import Avatar from '#components/ui/Avatar'
import Card from '#components/ui/Card'
import Text from '#components/ui/Text'
import { DEFAULT_IMAGES } from '#constants/defaultImages'
import { type Member } from '#tackpay-sdk'
import { IonCardHeader } from '@ionic/react'

interface Props {
  member: Member
}
export default function OtherMemberCardInfo({ member }: Props): JSX.Element {
  return (
    <Card className='w-8/12 mx-auto'>
      <IonCardHeader>
        <div className='flex flex-col items-center ion-text-center justify-center space-y-3'>
          <Avatar
            imgOptions={{
              src: member?.user?.avatar ?? DEFAULT_IMAGES.avatarTipped
            }}
            avatarOptions={{
              className: 'w-16 h-16'
            }}
          />
          <Text
            translationOptions={{
              translate: 'no'
            }}
            size='text-base'
            color='gray-900'
            fontWeight='font-semibold'
          >
            {member?.user?.person?.first_name} {member?.user?.person?.last_name}
          </Text>
        </div>
      </IonCardHeader>
    </Card>
  )
}
