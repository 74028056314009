import { IonItem, IonList, IonRow } from '@ionic/react'
import CoverIcon from './CoverIcon'
import Text from './Text'
import { type ComponentProps } from 'react'
import { useUserContext } from '#contexts/UserContext'
import { isInactiveCapabilities } from '#utils/isInactiveCapabilities'
import { useCompanyContext } from '#contexts/CompanyContext'
import cn from 'classnames'

interface ItemProps {
  label: string
  icon: string
  href?: string
}

interface Props {
  items: ItemProps[]
  listOptions?: ComponentProps<typeof IonList>
  itemsOptions?: ComponentProps<typeof IonItem>
}

export default function ListLink({
  items,
  listOptions,
  itemsOptions
}: Props): JSX.Element {
  const { user } = useUserContext()

  const { company } = useCompanyContext()

  const getBullet = (label: string): boolean => {
    return (
      label === 'setting_dashboard.profile' &&
      isInactiveCapabilities(
        user?.stripe_gateway_account,
        user?.category === 'tipped'
      )
    )
  }

  const hiddenDeduction = (title: string): boolean => {
    if (title === 'deduction.side_menu') {
      return ![
        '9c86881d-23f5-4cf4-9716-c7257ed4a914',
        'e3bbf101-5ac8-4f94-b784-18d7d6cee3e0',
        '2865aa15-414d-46da-9b83-7e52a60ce83c'
      ].includes(company?.id ?? '')
    }
    return false
  }

  return (
    <IonList
      {...listOptions}
      lines='full'
      className='space-y-4 mt-8 ion-no-padding bg-transparent'
    >
      {items.map((item, i) => (
        <IonItem
          data-testid='list-link'
          {...itemsOptions}
          className={cn('ion-no-padding w-full', {
            hidden: hiddenDeduction(item.label)
          })}
          detail
          routerLink={item.href}
          href={item.href}
          routerDirection='root'
          key={`${item.label}${i}`}
          lines='none'
          color='transparent'
        >
          <IonRow className='py-3 ion-align-items-center space-x-4 bg-transparent'>
            <CoverIcon iconOptions={{ icon: item.icon }} size='w-9 h-9' />
            <Text
              className='text-gray-800 text-[1rem] font-medium'
              data-testid={`jar-setting-${item.label}`}
            >
              {item.label}
            </Text>
            {getBullet(item.label) && (
              <div className='bg-red-500 p-1 rounded-full w-3 h-3 mt-1' />
            )}
          </IonRow>
        </IonItem>
      ))}
    </IonList>
  )
}
