import textUtil from '#utils/textUtil'
import {
  type IMessage,
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter,
  useSocket
} from '@novu/notification-center'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

function HandleNotification(): JSX.Element {
  const { socket } = useSocket()

  useEffect(() => {
    if (socket != null) {
      socket.on('notification_received', (data) => {
        console.log('Notification Received', data)
      })
    }

    return () => {
      if (socket != null) {
        socket.off('notification_received')
      }
    }
  }, [socket])

  function onNotificationClick(message: IMessage): void {
    // your logic to handle the notification click
    if (!textUtil.isEmpty(message.cta.data.url)) {
      window.location.href = message.cta.data.url as string
    }
  }

  return (
    <PopoverNotificationCenter
      onNotificationClick={onNotificationClick}
      colorScheme='light'
    >
      {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
    </PopoverNotificationCenter>
  )
}

interface Props {
  userId: string
}
export default function AppNotificationHeader({ userId }: Props): JSX.Element {
  const { i18n } = useTranslation()

  return (
    <NovuProvider
      subscriberId={userId}
      applicationIdentifier={
        process.env.REACT_APP_NOVU_API_KEY ?? 'Ypereq-7GEtz'
      }
      backendUrl='https://novu.tackpay.net:3001'
      socketUrl='wss://novu.tackpay.net:3003'
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
        fetchUnseenCount: true
      }}
      i18n={i18n.language as any}
      styles={{
        notifications: {
          listItem: {
            unread: {
              '::before': { background: '#71e89d' }
            }
          }
        }
      }}
    >
      <HandleNotification />
    </NovuProvider>
  )
}
