import Empty from '#components/ui/Empty'

interface Props {
  /**
   * If the jar is tipped or not
   * @default false
   */
  isTipped?: boolean
}
export default function TeamEmpty({ isTipped = false }: Props): JSX.Element {
  return (
    <Empty
      imgOptions={{
        src: `/assets/img/empty-team-${isTipped ? 'tipped' : 'business'}.svg`,
        alt: 'emptyTeam',
        className: 'w-10/12 md:w-5/12 mx-auto'
      }}
      textOptions={{
        text: `teamPage.empty${isTipped ? 'Tipped' : 'Business'}`,
        props: {
          color: 'gray-500',
          size: 'text-base',
          className: 'text-center italic mt-5 font-semibold'
        }
      }}
    />
  )
}
