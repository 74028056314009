import Button from '#components/ui/Button'
import Chip from '#components/ui/Chip'
import { type DashboardRoutesParams } from '#pages/dashboard/DashboardRoutes'
import { IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

interface Props {
  isActive: boolean
}
export default function PlanEnterpriseCard({ isActive }: Props): JSX.Element {
  const { category } = useParams<DashboardRoutesParams>()

  const { t } = useTranslation()

  const features = [
    {
      title: 'plan_3_feature_1',
      has: true
    },
    {
      title: 'plan_3_feature_2',
      has: true
    },
    {
      title: 'plan_3_feature_3',
      has: true
    },
    {
      title: 'plan_3_feature_4',
      has: true
    },
    {
      title: 'plan_3_feature_5',
      has: true
    }
  ]
  return (
    <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8  '>
      <IonRow className='w-full ion-align-items-center ion-justify-content-center space-x-5'>
        <div>
          <h3 className='mb-4 text-2xl font-semibold'>{t('plan_3_title')}</h3>
        </div>
        {isActive && (
          <Chip color='white' className='bg-green-300 mb-4'>
            currentPlan
          </Chip>
        )}
      </IonRow>
      <p className='font-light text-gray-500 sm:text-lg '>
        {t('plan_3_subtitle')}
      </p>
      <div className='flex justify-center items-baseline my-8'>
        <span className='mr-2 text-5xl font-extrabold'>
          {t('plan_3_price_value')}
        </span>
      </div>

      <ul role='list' className='mb-8 space-y-4 text-left'>
        {features.map((feature) => (
          <li key={feature.title} className='flex items-center space-x-3'>
            {feature.has ? (
              <svg
                className='flex-shrink-0 w-5 h-5 text-green-500 '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            ) : (
              <svg
                className='flex-shrink-0 w-5 h-5 text-gray-500 '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            )}
            <span>{t(feature.title)}</span>
          </li>
        ))}
      </ul>
      <Button
        routerLink={`/dashboard/${category}/settings/contact-us`}
        routerDirection='root'
      >
        plan_3_button
      </Button>
    </div>
  )
}
