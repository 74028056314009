export interface ICountry {
  country_name: string
  country_code: string
  phone_prefix: string
  currency_name: string
  currency_code: string
  currency_symbol: string
  continet: string
}

export const COUNTRIES: ICountry[] = [
  {
    country_name: 'Australia',
    country_code: 'AU',
    phone_prefix: '+61',
    currency_name: 'Australian Dollar',
    currency_code: 'AUD',
    currency_symbol: '$',
    continet: 'Oceania'
  },
  {
    country_name: 'Austria',
    country_code: 'AT',
    phone_prefix: '+43',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Belgium',
    country_code: 'BE',
    phone_prefix: '+32',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Brazil',
    country_code: 'BR',
    phone_prefix: '+55',
    currency_name: 'Brazilian Real',
    currency_code: 'BRL',
    currency_symbol: 'R$',
    continet: 'South America'
  },
  {
    country_name: 'Bulgaria',
    country_code: 'BG',
    phone_prefix: '+359',
    currency_name: 'Bulgarian Lev',
    currency_code: 'BGN',
    currency_symbol: 'лв.',
    continet: 'Europe'
  },
  {
    country_name: 'Canada',
    country_code: 'CA',
    phone_prefix: '+1',
    currency_name: 'Canadian Dollar',
    currency_code: 'CAD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Cyprus',
    country_code: 'CY',
    phone_prefix: '+357',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Croatia',
    country_code: 'HR',
    phone_prefix: '+385',
    currency_name: 'Croatian Kuna',
    currency_code: 'HRK',
    currency_symbol: 'kn',
    continet: 'Europe'
  },
  {
    country_name: 'Czech Republic',
    country_code: 'CR',
    phone_prefix: '+420',
    currency_name: 'Czech Koruna',
    currency_code: 'CZK',
    currency_symbol: 'Kč',
    continet: 'Europe'
  },
  {
    country_name: 'Denmark',
    country_code: 'DK',
    phone_prefix: '+45',
    currency_name: 'Danish Krone',
    currency_code: 'DKK',
    currency_symbol: 'kr',
    continet: 'Europe'
  },
  {
    country_name: 'Estonia',
    country_code: 'EE',
    phone_prefix: '+372',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Finland',
    country_code: 'FI',
    phone_prefix: '+358',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'France',
    country_code: 'FR',
    phone_prefix: '+33',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Germany',
    country_code: 'DE',
    phone_prefix: '+49',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Greece',
    country_code: 'GR',
    phone_prefix: '+30',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Hong Kong',
    country_code: 'HK',
    phone_prefix: '+852',
    currency_name: 'Hong Kong Dollar',
    currency_code: 'HKD',
    currency_symbol: 'HK$',
    continet: 'Asia'
  },
  {
    country_name: 'Hungary',
    country_code: 'HU',
    phone_prefix: '+36',
    currency_name: 'Hungarian Forint',
    currency_code: 'HUF',
    currency_symbol: 'Ft',
    continet: 'Europe'
  },
  {
    country_name: 'Ireland',
    country_code: 'IE',
    phone_prefix: '+353',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Italy',
    country_code: 'IT',
    phone_prefix: '+39',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Japan',
    country_code: 'JP',
    phone_prefix: '+81',
    currency_name: 'Japanese Yen',
    currency_code: 'JPY',
    currency_symbol: '¥',
    continet: 'Asia'
  },
  {
    country_name: 'Latvia',
    country_code: 'LV',
    phone_prefix: '+371',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Lithuania',
    country_code: 'LT',
    phone_prefix: '+370',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Luxembourg',
    country_code: 'LU',
    phone_prefix: '+352',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Malta',
    country_code: 'MT',
    phone_prefix: '+356',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Mexico',
    country_code: 'MX',
    phone_prefix: '+52',
    currency_name: 'Mexican Peso',
    currency_code: 'MXN',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Netherlands',
    country_code: 'NL',
    phone_prefix: '+31',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'New Zealand',
    country_code: 'NZ',
    phone_prefix: '+64',
    currency_name: 'New Zealand Dollar',
    currency_code: 'NZD',
    currency_symbol: '$',
    continet: 'Oceania'
  },
  {
    country_name: 'Norway',
    country_code: 'NO',
    phone_prefix: '+47',
    currency_name: 'Norwegian Krone',
    currency_code: 'NOK',
    currency_symbol: 'kr',
    continet: 'Europe'
  },
  {
    country_name: 'Poland',
    country_code: 'PL',
    phone_prefix: '+48',
    currency_name: 'Polish Zloty',
    currency_code: 'PLN',
    currency_symbol: 'zł',
    continet: 'Europe'
  },
  {
    country_name: 'Portugal',
    country_code: 'PT',
    phone_prefix: '+351',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Romania',
    country_code: 'RO',
    phone_prefix: '+40',
    currency_name: 'Romanian Leu',
    currency_code: 'RON',
    currency_symbol: 'lei',
    continet: 'Europe'
  },
  {
    country_name: 'Singapore',
    country_code: 'SG',
    phone_prefix: '+65',
    currency_name: 'Singapore Dollar',
    currency_code: 'SGD',
    currency_symbol: '$',
    continet: 'Asia'
  },
  {
    country_name: 'Slovakia',
    country_code: 'SK',
    phone_prefix: '+421',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Slovenia',
    country_code: 'SI',
    phone_prefix: '+386',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'South Africa',
    country_code: 'ZA',
    phone_prefix: '+27',
    currency_name: 'South African Rand',
    currency_code: 'ZAR',
    currency_symbol: 'R',
    continet: 'Africa'
  },
  {
    country_name: 'South Korea',
    country_code: 'KR',
    phone_prefix: '+82',
    currency_name: 'South Korean Won',
    currency_code: 'KRW',
    currency_symbol: '₩',
    continet: 'Asia'
  },
  {
    country_name: 'Spain',
    country_code: 'ES',
    phone_prefix: '+34',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Sweden',
    country_code: 'SE',
    phone_prefix: '+46',
    currency_name: 'Swedish Krona',
    currency_code: 'SEK',
    currency_symbol: 'kr',
    continet: 'Europe'
  },
  {
    country_name: 'Switzerland',
    country_code: 'CH',
    phone_prefix: '+41',
    currency_name: 'Swiss Franc',
    currency_code: 'CHF',
    currency_symbol: 'CHF',
    continet: 'Europe'
  },
  {
    country_name: 'Taiwan',
    country_code: 'TW',
    phone_prefix: '+886',
    currency_name: 'New Taiwan Dollar',
    currency_code: 'TWD',
    currency_symbol: 'NT$',
    continet: 'Asia'
  },
  {
    country_name: 'Thailand',
    country_code: 'TH',
    phone_prefix: '+66',
    currency_name: 'Thai Baht',
    currency_code: 'THB',
    currency_symbol: '฿',
    continet: 'Asia'
  },
  {
    country_name: 'Turkey',
    country_code: 'TR',
    phone_prefix: '+90',
    currency_name: 'Turkish Lira',
    currency_code: 'TRY',
    currency_symbol: '₺',
    continet: 'Asia'
  },
  {
    country_name: 'United Kingdom',
    country_code: 'GB',
    phone_prefix: '+44',
    currency_name: 'British Pound',
    currency_code: 'GBP',
    currency_symbol: '£',
    continet: 'Europe'
  },
  {
    country_name: 'United States',
    country_code: 'US',
    phone_prefix: '+1',
    currency_name: 'US Dollar',
    currency_code: 'USD',
    currency_symbol: '$',
    continet: 'North America'
  }
]

export const BANK_COUNTRIES_DIFF: ICountry[] = [
  {
    country_name: 'Albania',
    country_code: 'AL',
    phone_prefix: '+355',
    currency_name: 'Albanian Lek',
    currency_code: 'ALL',
    currency_symbol: 'Lek',
    continet: 'Europe'
  },
  {
    country_name: 'Algeria',
    country_code: 'DZ',
    phone_prefix: '+213',
    currency_name: 'Algerian Dinar',
    currency_code: 'DZD',
    currency_symbol: 'د.ج',
    continet: 'Africa'
  },
  {
    country_name: 'Angola',
    country_code: 'AO',
    phone_prefix: '+244',
    currency_name: 'Angolan Kwanza',
    currency_code: 'AOA',
    currency_symbol: 'Kz',
    continet: 'Africa'
  },
  {
    country_name: 'Antigua and Barbuda',
    country_code: 'AG',
    phone_prefix: '+1-268',
    currency_name: 'East Caribbean Dollar',
    currency_code: 'XCD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Arabia Saudita',
    country_code: 'SA',
    phone_prefix: '+966',
    currency_name: 'Saudi Riyal',
    currency_code: 'SAR',
    currency_symbol: 'ر.س',
    continet: 'Asia'
  },
  {
    country_name: 'Argentina',
    country_code: 'AR',
    phone_prefix: '+54',
    currency_name: 'Argentine Peso',
    currency_code: 'ARS',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Armenia',
    country_code: 'AM',
    phone_prefix: '+374',
    currency_name: 'Armenian Dram',
    currency_code: 'AMD',
    currency_symbol: '֏',
    continet: 'Europe'
  },
  // {
  //   country_name: 'Australia',
  //   country_code: 'AU',
  //   phone_prefix: '+61',
  //   currency_name: 'Australian Dollar',
  //   currency_code: 'AUD',
  //   currency_symbol: '$',
  //   continet: 'Oceania'
  // },
  {
    country_name: 'Arzebaijan',
    country_code: 'AZ',
    phone_prefix: '+994',
    currency_name: 'Azerbaijani Manat',
    currency_code: 'AZN',
    currency_symbol: '₼',
    continet: 'Europe'
  },
  {
    country_name: 'Bahamas',
    country_code: 'BS',
    phone_prefix: '+1-242',
    currency_name: 'Bahamian Dollar',
    currency_code: 'BSD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Bahrain',
    country_code: 'BH',
    phone_prefix: '+973',
    currency_name: 'Bahraini Dinar',
    currency_code: 'BHD',
    currency_symbol: '.د.ب',
    continet: 'Asia'
  },
  {
    country_name: 'Bangladesh',
    country_code: 'BD',
    phone_prefix: '+880',
    currency_name: 'Bangladeshi Taka',
    currency_code: 'BDT',
    currency_symbol: '৳',
    continet: 'Asia'
  },
  {
    country_name: 'Benin',
    country_code: 'BJ',
    phone_prefix: '+229',
    currency_name: 'West African CFA Franc',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'Bhutan',
    country_code: 'BT',
    phone_prefix: '+975',
    currency_name: 'Bhutanese Ngultrum',
    currency_code: 'BTN',
    currency_symbol: 'Nu.',
    continet: 'Asia'
  },
  {
    country_name: 'Bolivia',
    country_code: 'BO',
    phone_prefix: '+591',
    currency_name: 'Bolivian Boliviano',
    currency_code: 'BOB',
    currency_symbol: 'Bs.',
    continet: 'South America'
  },
  {
    country_name: 'Bosnia and Herzegovina',
    country_code: 'BA',
    phone_prefix: '+387',
    currency_name: 'Bosnia and Herzegovina Convertible Mark',
    currency_code: 'BAM',
    currency_symbol: 'KM',
    continet: 'Europe'
  },
  {
    country_name: 'Botswana',
    country_code: 'BW',
    phone_prefix: '+267',
    currency_name: 'Botswana Pula',
    currency_code: 'BWP',
    currency_symbol: 'P',
    continet: 'Africa'
  },
  {
    country_name: 'Brunei',
    country_code: 'BN',
    phone_prefix: '+673',
    currency_name: 'Brunei Dollar',
    currency_code: 'BND',
    currency_symbol: '$',
    continet: 'Asia'
  },
  {
    country_name: 'Cambodia',
    country_code: 'KH',
    phone_prefix: '+855',
    currency_name: 'Cambodian Riel',
    currency_code: 'KHR',
    currency_symbol: '៛',
    continet: 'Asia'
  },
  {
    country_name: 'Cile',
    country_code: 'CL',
    phone_prefix: '+56',
    currency_name: 'Chilean Peso',
    currency_code: 'CLP',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Cipro',
    country_code: 'CY',
    phone_prefix: '+357',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Colombia',
    country_code: 'CO',
    phone_prefix: '+57',
    currency_name: 'Colombian Peso',
    currency_code: 'COP',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Corea del Sud',
    country_code: 'KR',
    phone_prefix: '+82',
    currency_name: 'South Korean Won',
    currency_code: 'KRW',
    currency_symbol: '₩',
    continet: 'Asia'
  },
  {
    country_name: 'Costa Rica',
    country_code: 'CR',
    phone_prefix: '+506',
    currency_name: 'Costa Rican Colon',
    currency_code: 'CRC',
    currency_symbol: '₡',
    continet: 'North America'
  },
  {
    country_name: "Costa d'Avorio",
    country_code: 'CI',
    phone_prefix: '+225',
    currency_name: 'West African CFA Franc',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'Equador',
    country_code: 'EC',
    phone_prefix: '+593',
    currency_name: 'US Dollar',
    currency_code: 'USD',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Egitto',
    country_code: 'EG',
    phone_prefix: '+20',
    currency_name: 'Egyptian Pound',
    currency_code: 'EGP',
    currency_symbol: '£',
    continet: 'Africa'
  },
  {
    country_name: 'El Salvador',
    country_code: 'SV',
    phone_prefix: '+503',
    currency_name: 'US Dollar',
    currency_code: 'USD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Ethiopia',
    country_code: 'ET',
    phone_prefix: '+251',
    currency_name: 'Ethiopian Birr',
    currency_code: 'ETB',
    currency_symbol: 'Br',
    continet: 'Africa'
  },
  {
    country_name: 'Gabon',
    country_code: 'GA',
    phone_prefix: '+241',
    currency_name: 'Central African CFA Franc',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'Gambia',
    country_code: 'GM',
    phone_prefix: '+220',
    currency_name: 'Gambian Dalasi',
    currency_code: 'GMD',
    currency_symbol: 'D',
    continet: 'Africa'
  },
  {
    country_name: 'Ghana',
    country_code: 'GH',
    phone_prefix: '+233',
    currency_name: 'Ghanaian Cedi',
    currency_code: 'GHS',
    currency_symbol: '₵',
    continet: 'Africa'
  },
  {
    country_name: 'Gibraltar',
    country_code: 'GI',
    phone_prefix: '+350',
    currency_name: 'Gibraltar Pound',
    currency_code: 'GIP',
    currency_symbol: '£',
    continet: 'Europe'
  },
  {
    country_name: 'Guatemala',
    country_code: 'GT',
    phone_prefix: '+502',
    currency_name: 'Guatemalan Quetzal',
    currency_code: 'GTQ',
    currency_symbol: 'Q',
    continet: 'North America'
  },
  {
    country_name: 'Guyana',
    country_code: 'GY',
    phone_prefix: '+592',
    currency_name: 'Guyanese Dollar',
    currency_code: 'GYD',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Iceland',
    country_code: 'IS',
    phone_prefix: '+354',
    currency_name: 'Icelandic Krona',
    currency_code: 'ISK',
    currency_symbol: 'kr',
    continet: 'Europe'
  },
  {
    country_name: 'Indonesia',
    country_code: 'ID',
    phone_prefix: '+62',
    currency_name: 'Indonesian Rupiah',
    currency_code: 'IDR',
    currency_symbol: 'Rp',
    continet: 'Asia'
  },
  {
    country_name: 'Jamaica',
    country_code: 'JM',
    phone_prefix: '+1-876',
    currency_name: 'Jamaican Dollar',
    currency_code: 'JMD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Jordan',
    country_code: 'JO',
    phone_prefix: '+962',
    currency_name: 'Jordanian Dinar',
    currency_code: 'JOD',
    currency_symbol: 'د.ا',
    continet: 'Asia'
  },
  {
    country_name: 'Kazakhstan',
    country_code: 'KZ',
    phone_prefix: '+7',
    currency_name: 'Kazakhstani Tenge',
    currency_code: 'KZT',
    currency_symbol: '₸',
    continet: 'Asia'
  },
  {
    country_name: 'Kenya',
    country_code: 'KE',
    phone_prefix: '+254',
    currency_name: 'Kenyan Shilling',
    currency_code: 'KES',
    currency_symbol: 'KSh',
    continet: 'Africa'
  },
  {
    country_name: 'Kuwait',
    country_code: 'KW',
    phone_prefix: '+965',
    currency_name: 'Kuwaiti Dinar',
    currency_code: 'KWD',
    currency_symbol: 'د.ك',
    continet: 'Asia'
  },
  {
    country_name: 'Laos',
    country_code: 'LA',
    phone_prefix: '+856',
    currency_name: 'Lao Kip',
    currency_code: 'LAK',
    currency_symbol: '₭',
    continet: 'Asia'
  },
  {
    country_name: 'Lienchtenstein',
    country_code: 'LI',
    phone_prefix: '+423',
    currency_name: 'Swiss Franc',
    currency_code: 'CHF',
    currency_symbol: 'CHF',
    continet: 'Europe'
  },
  {
    country_name: 'Macao',
    country_code: 'MO',
    phone_prefix: '+853',
    currency_name: 'Macanese Pataca',
    currency_code: 'MOP',
    currency_symbol: 'MOP$',
    continet: 'Asia'
  },
  {
    country_name: 'Madagascar',
    country_code: 'MG',
    phone_prefix: '+261',
    currency_name: 'Malagasy Ariary',
    currency_code: 'MGA',
    currency_symbol: 'Ar',
    continet: 'Africa'
  },
  {
    country_name: 'Mauritius',
    country_code: 'MU',
    phone_prefix: '+230',
    currency_name: 'Mauritian Rupee',
    currency_code: 'MUR',
    currency_symbol: '₨',
    continet: 'Africa'
  },
  {
    country_name: 'Moldova',
    country_code: 'MD',
    phone_prefix: '+373',
    currency_name: 'Moldovan Leu',
    currency_code: 'MDL',
    currency_symbol: 'L',
    continet: 'Europe'
  },
  {
    country_name: 'Monaco',
    country_code: 'MC',
    phone_prefix: '+377',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Mongolia',
    country_code: 'MN',
    phone_prefix: '+976',
    currency_name: 'Mongolian Tugrik',
    currency_code: 'MNT',
    currency_symbol: '₮',
    continet: 'Asia'
  },
  {
    country_name: 'Morocco',
    country_code: 'MA',
    phone_prefix: '+212',
    currency_name: 'Moroccan Dirham',
    currency_code: 'MAD',
    currency_symbol: 'د.م.',
    continet: 'Africa'
  },
  {
    country_name: 'Mozambique',
    country_code: 'MZ',
    phone_prefix: '+258',
    currency_name: 'Mozambican Metical',
    currency_code: 'MZN',
    currency_symbol: 'MT',
    continet: 'Africa'
  },
  {
    country_name: 'Namibia',
    country_code: 'NA',
    phone_prefix: '+264',
    currency_name: 'Namibian Dollar',
    currency_code: 'NAD',
    currency_symbol: '$',
    continet: 'Africa'
  },
  {
    country_name: 'Niger',
    country_code: 'NE',
    phone_prefix: '+227',
    currency_name: 'West African CFA Franc',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'Nigeria',
    country_code: 'NG',
    phone_prefix: '+234',
    currency_name: 'Nigerian Naira',
    currency_code: 'NGN',
    currency_symbol: '₦',
    continet: 'Africa'
  },
  {
    country_name: 'North Macedonia',
    country_code: 'MK',
    phone_prefix: '+389',
    currency_name: 'Macedonian Denar',
    currency_code: 'MKD',
    currency_symbol: 'ден',
    continet: 'Europe'
  },
  {
    country_name: 'Oman',
    country_code: 'OM',
    phone_prefix: '+968',
    currency_name: 'Omani Rial',
    currency_code: 'OMR',
    currency_symbol: 'ر.ع.',
    continet: 'Asia'
  },
  {
    country_name: 'Pakistan',
    country_code: 'PK',
    phone_prefix: '+92',
    currency_name: 'Pakistani Rupee',
    currency_code: 'PKR',
    currency_symbol: '₨',
    continet: 'Asia'
  },
  {
    country_name: 'Panama',
    country_code: 'PA',
    phone_prefix: '+507',
    currency_name: 'Panamanian Balboa',
    currency_code: 'PAB',
    currency_symbol: 'B/.',
    continet: 'North America'
  },
  {
    country_name: 'Paraguay',
    country_code: 'PY',
    phone_prefix: '+595',
    currency_name: 'Paraguayan Guarani',
    currency_code: 'PYG',
    currency_symbol: '₲',
    continet: 'South America'
  },
  {
    country_name: 'Peru',
    country_code: 'PE',
    phone_prefix: '+51',
    currency_name: 'Peruvian Sol',
    currency_code: 'PEN',
    currency_symbol: 'S/.',
    continet: 'South America'
  },
  {
    country_name: 'Philippines',
    country_code: 'PH',
    phone_prefix: '+63',
    currency_name: 'Philippine Peso',
    currency_code: 'PHP',
    currency_symbol: '₱',
    continet: 'Asia'
  },
  {
    country_name: 'Qatar',
    country_code: 'QA',
    phone_prefix: '+974',
    currency_name: 'Qatari Riyal',
    currency_code: 'QAR',
    currency_symbol: 'ر.ق',
    continet: 'Asia'
  },
  {
    country_name: 'Rwanda',
    country_code: 'RW',
    phone_prefix: '+250',
    currency_name: 'Rwandan Franc',
    currency_code: 'RWF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'San Marino',
    country_code: 'SM',
    phone_prefix: '+378',
    currency_name: 'Euro',
    currency_code: 'EUR',
    currency_symbol: '€',
    continet: 'Europe'
  },
  {
    country_name: 'Saudi Arabia',
    country_code: 'SA',
    phone_prefix: '+966',
    currency_name: 'Saudi Riyal',
    currency_code: 'SAR',
    currency_symbol: 'ر.س',
    continet: 'Asia'
  },
  {
    country_name: 'Senegal',
    country_code: 'SN',
    phone_prefix: '+221',
    currency_name: 'West African CFA Franc',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    continet: 'Africa'
  },
  {
    country_name: 'Sri Lanka',
    country_code: 'LK',
    phone_prefix: '+94',
    currency_name: 'Sri Lankan Rupee',
    currency_code: 'LKR',
    currency_symbol: 'Rs',
    continet: 'Asia'
  },
  {
    country_name: 'St Lucia',
    country_code: 'LC',
    phone_prefix: '+1-758',
    currency_name: 'East Caribbean Dollar',
    currency_code: 'XCD',
    currency_symbol: '$',
    continet: 'North America'
  },
  // {
  //   country_name: 'Taiwan',
  //   country_code: 'TW',
  //   phone_prefix: '+886',
  //   currency_name: 'New Taiwan Dollar',
  //   currency_code: 'TWD',
  //   currency_symbol: 'NT$',
  //   continet: 'Asia'
  // },
  {
    country_name: 'Tanzania',
    country_code: 'TZ',
    phone_prefix: '+255',
    currency_name: 'Tanzanian Shilling',
    currency_code: 'TZS',
    currency_symbol: 'TSh',
    continet: 'Africa'
  },
  {
    country_name: 'Trinidad and Tobago',
    country_code: 'TT',
    phone_prefix: '+1-868',
    currency_name: 'Trinidad and Tobago Dollar',
    currency_code: 'TTD',
    currency_symbol: '$',
    continet: 'North America'
  },
  {
    country_name: 'Tunisia',
    country_code: 'TN',
    phone_prefix: '+216',
    currency_name: 'Tunisian Dinar',
    currency_code: 'TND',
    currency_symbol: 'د.ت',
    continet: 'Africa'
  },
  {
    country_name: 'United Arab Emirates',
    country_code: 'AE',
    phone_prefix: '+971',
    currency_name: 'United Arab Emirates Dirham',
    currency_code: 'AED',
    currency_symbol: 'د.إ',
    continet: 'Asia'
  },
  {
    country_name: 'Uruguay',
    country_code: 'UY',
    phone_prefix: '+598',
    currency_name: 'Uruguayan Peso',
    currency_code: 'UYU',
    currency_symbol: '$',
    continet: 'South America'
  },
  {
    country_name: 'Uzbekistan',
    country_code: 'UZ',
    phone_prefix: '+998',
    currency_name: 'Uzbekistani Som',
    currency_code: 'UZS',
    currency_symbol: 'лв',
    continet: 'Asia'
  },
  {
    country_name: 'Venezuela',
    country_code: 'VE',
    phone_prefix: '+58',
    currency_name: 'Venezuelan Bolivar',
    currency_code: 'VES',
    currency_symbol: 'Bs.',
    continet: 'South America'
  },
  {
    country_name: 'Vietnam',
    country_code: 'VN',
    phone_prefix: '+84',
    currency_name: 'Vietnamese Dong',
    currency_code: 'VND',
    currency_symbol: '₫',
    continet: 'Asia'
  }
]

export const BANK_COUNTRIES = Array.from(
  new Set(
    [...COUNTRIES, ...BANK_COUNTRIES_DIFF].sort((a, b) =>
      a.country_name.localeCompare(b.country_name)
    )
  )
)

export const EXTRA_BANK_INFORMATION_COUNTRIES = [
  'Australia',
  'Brazil',
  'Canada',
  'Hong Kong',
  'India',
  'Japan',
  'Malaysia',
  'New Zealand',
  'Russia',
  'Serbia',
  'Singapore',
  'United Arab Emirates',
  'United Kingdom',
  'United States'
]

export const ROUTING_NUMBER_COUNTRY = [
  'Australia',
  'Brazil',
  'Canada',
  'Hong Kong',
  'India',
  'Japan',
  'Singapore',
  // 'United Kingdom',
  'United States'
]

export const BIC_COUNTRIES = [
  'Albania',
  'Algeria',
  'Angola',
  'Malaysia',
  'Serbia'
]

export function getCountryByIso(iso: string): string {
  return COUNTRIES.find((c) => c.country_code === iso)?.country_name ?? ''
}

export function getIsoByCountry(country: string): string {
  return COUNTRIES.find((c) => c.country_name === country)?.country_code ?? ''
}

export function getCurrencyByCountryName(country: string): string {
  return COUNTRIES.find((c) => c.country_name === country)?.currency_code ?? ''
}

export function getSymbolCurrency(currency: string): string {
  return (
    COUNTRIES.find((c) => c.currency_code === currency.toUpperCase())
      ?.currency_symbol ?? ''
  )
}

export const EUROPEAN_COUNTRY_CODES = COUNTRIES.filter(
  (c) => c.continet === 'Europe'
).map((c) => c.country_code)
