import {
  type ResourceCreate,
  type Resource,
  type ResourceRel,
  type ResourceUpdate,
  ApiResource,
  type ResourcesConfig
} from 'sdk/resource'
import { type CheckoutSetting } from './checkoutSettings'
import { type QueryParamsRetrieve } from 'sdk/query'

type ReplyType = 'replies'
type ReplyRel = ResourceRel & { type: ReplyType }

type CheckoutSettingRel = ResourceRel & { type: 'checkout_settings' }

interface Reply extends Resource {
  readonly type: ReplyType
  message: string
  active: boolean
  checkout_setting?: CheckoutSetting
}

interface ReplyCreate extends ResourceCreate {
  message: string
  active?: boolean
  checkout_setting: CheckoutSettingRel
}

interface ReplyUpdate extends ResourceUpdate {
  message?: string
  active?: boolean
}

class Replies extends ApiResource<Reply> {
  static readonly TYPE: ReplyType = 'replies'

  async create(
    resource: ReplyCreate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Reply> {
    return await this.resources.create<ReplyCreate, Reply>(
      { ...resource, type: Replies.TYPE },
      params,
      options
    )
  }

  async update(
    resource: ReplyUpdate,
    params?: QueryParamsRetrieve,
    options?: ResourcesConfig
  ): Promise<Reply> {
    return await this.resources.update<ReplyUpdate, Reply>(
      { ...resource, type: Replies.TYPE },
      params,
      options
    )
  }

  isReply(resource: any): resource is Reply {
    return resource.type != null && resource.type === Replies.TYPE
  }

  relationship(id: string | ResourceRel | null): ReplyRel {
    return id === null || typeof id === 'string'
      ? { id, type: Replies.TYPE }
      : { id: id.id, type: Replies.TYPE }
  }

  type(): ReplyType {
    return Replies.TYPE
  }
}

export type { Reply, ReplyCreate, ReplyUpdate }
export default Replies
